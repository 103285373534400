import React, { Component } from 'react';
import './LoadingScreen.css';

class LoadingScreen extends Component {
  render() {
    return (
      <div className="loading-screen" id={'loading-screen-identifier'}>
        <div className={'video'}>
          <img src="images/logo.svg" className="navbar-logo" alt="img"></img>
        </div>
        <div className="loading-dots">
          <div className="dot" />
          <div className="dot" />
          <div className="dot" />
          <div className="dot" />
          <div className="dot" />
        </div>
      </div>
    );
  }
}

export default LoadingScreen;
