import React, { Component } from 'react';

import './AboutCardSection.css';
import './AboutNewCss.css';

import { TiTick } from 'react-icons/ti';

class AboutCardSection extends Component {
  state = {};
  render() {
    return [
      <div>
        <div className="laptop-version">
          <center>
            <div className="wrapper">
              <ul className="steps">
                <li>
                  <p className="steps-p-head font-size-set-18">
                    ELIGE EL DESTINO DE TU PREFERENCIA
                  </p>
                  <p className="steps-p-sub font-size-set-14">
                    Puedes elegir país, ciudad, estado o provincia donde desees
                    vacacionar.
                  </p>
                </li>
                <li>
                  <p className="steps-p-head font-size-set-18">
                    SELECCIONA LA CANTIDAD DE PERSONAS Y FECHAS
                  </p>
                  <p className="steps-p-sub font-size-set-14">
                    Elige la cantidad de personas con quién viajaras y las
                    fechas exactas o estimadas de tu viaje.
                  </p>
                </li>
                <li>
                  <p className="steps-p-head font-size-set-18">
                    ACTIVIDADES Y CATEGORÍA PARA EL ALOJAMIENTO
                  </p>
                  <p className="steps-p-sub font-size-set-14">
                    Déjanos saber las actividades que te gustaría realizar y la
                    categoría que se ajuste a tu presupuesto.
                  </p>
                </li>
                <li>
                  <span className="tick-icon">
                    <TiTick />
                  </span>
                  <p className="steps-p-head font-size-set-18">
                    RECIBE TU COTIZACIÓN A TU EMAIL !
                  </p>
                  <p className="steps-p-sub font-size-set-14">
                    Una vez recibamos tus datos, nuestro sistema te generará tu
                    cotización en solo segundos! Esta, la podrás compartir en
                    tus redes sociales favoritas con tus familiares y amigos!
                  </p>
                </li>
              </ul>
            </div>
          </center>
        </div>
        <div className="mobile-version">
          <div className="wrapper">
            <ul className="steps">
              <li>
                <span className="number-set-mobile">1</span>
                <p className="steps-p-head font-size-set-18 margin-top-about-us-head">
                  ELIGE EL DESTINO DE TU PREFERENCIA
                </p>
                <p className="steps-p-sub font-size-set-14">
                  Puedes elegir país, ciudad, estado o provincia donde desees
                  vacacionar.
                </p>
              </li>
              <li>
                <span className="number-set-mobile">2</span>
                <p className="steps-p-head font-size-set-18 margin-top-about-us-head">
                  SELECCIONA LA CANTIDAD DE PERSONAS Y FECHAS
                </p>
                <p className="steps-p-sub font-size-set-14">
                  Elige la cantidad de personas con quién viajaras y las fechas
                  exactas o estimadas de tu viaje.
                </p>
              </li>
              <li>
                <span className="number-set-mobile">3</span>
                <p className="steps-p-head font-size-set-18 margin-top-about-us-head">
                  ACTIVIDADES Y CATEGORÍA PARA EL ALOJAMIENTO
                </p>
                <p className="steps-p-sub font-size-set-14">
                  Déjanos saber las actividades que te gustaría realizar y la
                  categoría que se ajuste a tu presupuesto.
                </p>
              </li>
              <li>
                <span className="tick-icon">
                  <TiTick />
                </span>
                <p className="steps-p-head font-size-set-18 margin-top-about-us-head">
                  RECIBE TU COTIZACIÓN A TU EMAIL !
                </p>
                <p className="steps-p-sub font-size-set-14">
                  Una vez recibamos tus datos, nuestro sistema te generará tu
                  cotización en solo segundos! Esta, la podrás compartir en tus
                  redes sociales favoritas con tus familiares y amigos!
                </p>
              </li>
            </ul>
          </div>
        </div>
      </div>,
    ];
  }
}

export default AboutCardSection;
