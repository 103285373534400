import React, { Component } from 'react';

import AboutCardSection from './AboutCardSection/AboutCardSection';

import DestinationSearchHome from './DestinationSearchHome/DestinationSearchHome';

import './homePage.css';

import { FaFacebookF, FaPinterestP } from 'react-icons/fa';
import {
  AiFillInstagram,
  AiOutlineTwitter,
  AiFillYoutube,
} from 'react-icons/ai';

import visa from 'payment-icons/min/single/visa.svg';
import master from 'payment-icons/min/single/mastercard.svg';
import maestro from 'payment-icons/min/single/maestro.svg';
import amex from 'payment-icons/min/single/amex.svg';
import paypal from 'payment-icons/min/single/paypal.svg';

import { TRACKING_ID } from '../../Data/constants';
import { EventFunction, initGA } from '../../Tracking/index';

class HomePage extends Component {
  state = {};

  selectedDestination() {
    this.props.changeWhereLikeGo({
      value: 'Miami (Florida), US',
      label: 'Miami (Florida), US',
      code: 'MIA',
    });

    initGA(TRACKING_ID);
    EventFunction('HOME PAGE', 'Click', 'HOME | Vamos con Miami');
  }

  SmoothVerticalScrolling(eTop, time) {
    var eAmt = eTop / 100;
    var curTime = 0;

    while (curTime <= time) {
      window.setTimeout(this.SVS_B, curTime, eAmt);
      curTime += time / 100;
    }
  }

  SVS_B(eAmt) {
    window.scrollBy(0, eAmt);
  }

  scrollToBottom() {
    let fullBodyHeight =
      document.getElementById('full_body_size').offsetHeight +
      document.getElementById('nav_bar_height').offsetHeight;

    this.SmoothVerticalScrolling(fullBodyHeight, 500);

    initGA(TRACKING_ID);
    EventFunction('HOME PAGE', 'Click', 'Conoce más');
  }

  scrollToTop() {
    let fullBodyHeight =
      document.getElementById('full_body_size').offsetHeight +
      document.getElementById('about-us-div').offsetHeight;

    this.SmoothVerticalScrolling(-fullBodyHeight, 500);

    initGA(TRACKING_ID);
    EventFunction('HOME PAGE', 'Click', 'Cotizar ahora');
  }

  render() {
    return (
      <div className="relative">
        <img className="img_1_set" src="images/samolot_6.svg" alt="img" />
        <img className="img_2_set" src="images/samolot_5.svg" alt="img" />
        <div id="full_body_size" className="parallax-img-home">
          <div className="container">
            <div className="row home-padding-top-set d-flex justify-content-center">
              <div className="col-sm-10">
                <p className="header-sub-text-style-home font-size-set-42">
                  ¿Cansado de intentar conseguir las vacaciones perfectas al
                  precio correcto?
                </p>
              </div>
            </div>
            <div className="row d-flex justify-content-center">
              <div className="col-sm-8">
                <p className="header-text-style-home font-size-set-24">
                  Permítanos planificar su viaje ideal a Miami
                </p>
              </div>
            </div>
            <div className="row d-flex justify-content-center">
              <div className="col-sm-12 col-md-8 col-lg-5 col-xl-5 home-search-box-style">
                {/* <p className="search-box-p-text">A dónde quieres ir:</p> */}
                {/* <DestinationSearchHome
                  changeWhereLikeGo={this.props.changeWhereLikeGo}
                  whereLikeGo={this.props.whereLikeGo}
                /> */}
                <p
                  id="no-destination-btn-home"
                  onClick={() => this.selectedDestination()}
                  className="btn-not-destination-select btn-not-destination-select-active"
                >
                  Vamos con Miami
                </p>
              </div>
            </div>
            <div className="row home-btn-padding-top d-flex justify-content-center">
              <div className="col-sm-8">
                <p className="header-text-style-home-sub-p font-size-set-14">
                  Quieres saber como funciona?
                </p>
              </div>
            </div>
            <div className="row d-flex justify-content-center">
              <div className="col-sm-6 col-md-3 col-lg-3">
                <p
                  onClick={() => this.scrollToBottom()}
                  className="btn-about-us-select"
                >
                  Conoce más
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="about-us-img-back">
          <div id="about-us-div" className="container about-us-details-home">
            <div className="row pt-5 d-flex justify-content-center">
              <div className="col-sm-10">
                <p className="about-us-sub-home-text font-size-set-40">
                  RECIBE TU COTIZACIÓN EN TRES SIMPLES PASOS
                </p>
              </div>
            </div>
            <div className="row d-flex justify-content-center  mb-5">
              <div className="col-sm-8">
                <p className="steps-p-sub font-size-set-14 text-align-set">
                  Para nosotros es muy importante entender las necesidades de tu
                  viaje, es por eso que desarrollamos un sistema inteligente de
                  cotizaciones, el cual navega sobre todas las líneas aéreas,
                  cadenas hoteleras y afiliados, generando la mejor opción para
                  tus vacaciones en segundos. aprende más siguiendo estos
                  simples pasos:
                </p>
              </div>
            </div>
            <AboutCardSection />
            <div className="row d-flex justify-content-center">
              <div className="col-sm-3 mb-5 mt-5">
                <p
                  onClick={() => this.scrollToTop()}
                  className="btn-about-us-select-top"
                >
                  Cotizar ahora
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="background-footer">
          <div className="container">
            <div className="row copy-div d-flex justify-content-center">
              <div className="col-md-4 col-sm-4 col-lg-4 col-xl-4">
                <center>
                  <ul className="social-network social-circle">
                    <li>
                      <a
                        href="https://www.facebook.com/ResortGetaway/"
                        target="_blank"
                        className=""
                        rel="noopener noreferrer"
                        title="Facebook"
                      >
                        <FaFacebookF />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.instagram.com/resortgetaway/"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="icoInstagram"
                        title="Instagram"
                      >
                        <AiFillInstagram style={{ fontSize: '20px' }} />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.youtube.com/channel/UCN-Y8E4ZGCRCB-WdYJfm1Aw"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="icoInstagram"
                        title="Youtube"
                      >
                        <AiFillYoutube style={{ fontSize: '20px' }} />
                      </a>
                    </li>
                  </ul>
                </center>
              </div>
              <div className="col-md-4 col-sm-4 col-lg-4 col-xl-4 copy">
                <p className="text-size-16 text-center copy-text-set">
                  © Resortgetway.com 2020 Todos los derechos reservados Política
                  de privacidad
                </p>
              </div>
              <div className="col-md-4 col-sm-4 col-lg-4 col-xl-4 copy">
                <center>
                  <img src={visa} alt="" className="credit-card-icon" />
                  <img src={master} alt="" className="credit-card-icon" />
                  <img src={maestro} alt="" className="credit-card-icon" />
                  <img src={amex} alt="" className="credit-card-icon" />
                  <img src={paypal} alt="" className="credit-card-icon" />
                </center>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default HomePage;
