import React from 'react';
import Async from 'react-select/async';
//import Async from "react-select-virtualized";
import { groupedOptionsCity } from '../../../Data/countryCityList';

import '../BigSearchBox/BigSearchBox.css';

import { TRACKING_ID } from '../../../Data/constants';
import { EventFunction, initGA } from '../../../Tracking/index';

const groupStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
};

const formatGroupLabel = (data) => (
  <div style={groupStyles}>
    <span>{data.label}</span>
    <span style={groupBadgeStyles}>{data.options.length}</span>
  </div>
);

const groupBadgeStyles = {
  backgroundColor: '#EBECF0',
  borderRadius: '2em',
  color: '#172B4D',
  display: 'inline-block',
  fontSize: 12,
  fontWeight: 'normal',
  lineHeight: '1',
  minWidth: 1,
  padding: '0.16666666666667em 0.5em',
  textAlign: 'center',
};

const removeAccents = (value) => {
  return value
    .replace(/á/g, 'a')
    .replace(/é/g, 'e')
    .replace(/í/g, 'i')
    .replace(/ó/g, 'o')
    .replace(/ú/g, 'u');
};

const loadOptions = (input, callback) => {
  if (input.length >= 2) {
    setTimeout(() => {
      const newGroups = groupedOptionsCity.reduce((acc, { label, options }) => {
        const newOps = options.filter(
          ({ label }) =>
            removeAccents(label.toLowerCase()).includes(input.toLowerCase()) ||
            label.toLowerCase().includes(input.toLowerCase())
        );
        if (newOps.length > 0) {
          acc.push({ label, options: newOps });
        }
        return acc;
      }, []);

      callback(newGroups);
    }, 200);
  }
};

class DestinationSearchHome extends React.Component {
  state = {
    selectedOption: this.props.whereLikeGo,
  };

  handleChange = (selectedOption) => {
    if (selectedOption !== null) {
      if (selectedOption.length === 3) {
        this.props.changeWhereLikeGo(selectedOption);

        this.setState(
          { selectedOption },
          () => {}
          //console.log(`Option selected:`, this.state.selectedOption)
        );
      } else {
        this.setState(
          { selectedOption },
          () => {}
          //console.log(`Option selected:`, this.state.selectedOption)
        );
      }

      if (selectedOption.length === 0) {
        document.getElementById('next-btn-home').style.display = 'none';
        document.getElementById('no-destination-btn-home').style.display =
          'block';
      } else {
        document.getElementById('next-btn-home').style.display = 'block';
        document.getElementById('no-destination-btn-home').style.display =
          'none';
      }
    }
  };

  submitDestinations = (selectedOption) => {
    if (selectedOption !== null) {
      this.props.changeWhereLikeGo(selectedOption);

      this.setState({ selectedOption }, () =>
        console.log(`Option selected:`, this.state.selectedOption)
      );
    }
    // console.log(selectedOption);
    initGA(TRACKING_ID);
    EventFunction('HOME PAGE', 'Click', 'Vamos');
  };

  render() {
    const { selectedOption } = this.state;

    return (
      <div>
        <Async
          loadOptions={loadOptions}
          value={selectedOption}
          placeholder="Seleccione: País, Estado o Ciudad"
          formatGroupLabel={formatGroupLabel}
          onChange={this.handleChange}
          cacheOptions={true}
          grouped
          isMulti
        />
        <p
          id="next-btn-home"
          onClick={() => this.submitDestinations(selectedOption)}
          className={
            this.props.whereLikeGo === 'NOT SPECIFY' ||
            this.props.whereLikeGo === null
              ? 'home-next-btn home-next-btn-none'
              : 'home-next-btn'
          }
        >
          Vamos
        </p>
      </div>
    );
  }
}

export default DestinationSearchHome;
