import React, { Suspense, useEffect } from 'react';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';
import ScrollToTop from 'react-router-scroll-top';

import './mediaStyles.css';
import LoadingScreen from './RootPage/LoadingScreen/LoadingScreen';
import RootPage from './RootPage/RootPage';

import { TRACKING_ID } from './Data/constants';
import { PageView, initGA } from './Tracking/index';

function App() {
  const { pathname } = useLocation();

  useEffect(() => {
    initGA(TRACKING_ID);
    PageView();
  });

  return (
    <ScrollToTop>
      <Suspense fallback={<LoadingScreen />}>
        <Switch>
          <Redirect from="/:url*(/+)" to={pathname.slice(0, -1)} />
          <Route component={RootPage} />
        </Switch>
      </Suspense>
    </ScrollToTop>
  );
}

export default App;
