export const countryOptions = [
	{ value: "Afghanistan", label: "Afghanistan", code: "AF" },
	{ value: "Åland Islands", label: "Åland Islands", code: "AX" },
	{ value: "Albania", label: "Albania", code: "AL" },
	{ value: "Algeria", label: "Algeria", code: "DZ" },
	{ value: "American Samoa", label: "American Samoa", code: "AS" },
	{ value: "Andorra", label: "Andorra", code: "AD" },
	{ value: "Angola", label: "Angola", code: "AO" },
	{ value: "Anguilla", label: "Anguilla", code: "AI" },
	{ value: "Antarctica", label: "Antarctica", code: "AQ" },
	{ value: "Antigua and Barbuda", label: "Antigua and Barbuda", code: "AG" },
	{ value: "Argentina", label: "Argentina", code: "AR" },
	{ value: "Armenia", label: "Armenia", code: "AM" },
	{ value: "Aruba", label: "Aruba", code: "AW" },
	{ value: "Australia", label: "Australia", code: "AU" },
	{ value: "Austria", label: "Austria", code: "AT" },
	{ value: "Azerbaijan", label: "Azerbaijan", code: "AZ" },
	{ value: "Bahamas", label: "Bahamas", code: "BS" },
	{ value: "Bahrain", label: "Bahrain", code: "BH" },
	{ value: "Bangladesh", label: "Bangladesh", code: "BD" },
	{ value: "Barbados", label: "Barbados", code: "BB" },
	{ value: "Belarus", label: "Belarus", code: "BY" },
	{ value: "Belgium", label: "Belgium", code: "BE" },
	{ value: "Belize", label: "Belize", code: "BZ" },
	{ value: "Benin", label: "Benin", code: "BJ" },
	{ value: "Bermuda", label: "Bermuda", code: "BM" },
	{ value: "Bhutan", label: "Bhutan", code: "BT" },
	{ value: "Bolivia", label: "Bolivia", code: "BO" },
	{ value: "Bonaire", label: "Bonaire", code: "BQ" },
	{
		value: "Bosnia and Herzegovina",
		label: "Bosnia and Herzegovina",
		code: "BA",
	},
	{ value: "Botswana", label: "Botswana", code: "BW" },
	{ value: "Bouvet Island", label: "Bouvet Island", code: "BV" },
	{ value: "Brazil", label: "Brazil", code: "BR" },
	{
		value: "British Indian Ocean Territory",
		label: "British Indian Ocean Territory",
		code: "IO",
	},
	{ value: "Brunei Darussalam", label: "Brunei Darussalam", code: "BN" },
	{ value: "Bulgaria", label: "Bulgaria", code: "BG" },
	{ value: "Burkina Faso", label: "Burkina Faso", code: "BF" },
	{ value: "Burundi", label: "Burundi", code: "BI" },
	{ value: "Cambodia", label: "Cambodia", code: "KH" },
	{ value: "Cameroon", label: "Cameroon", code: "CM" },
	{ value: "Canada", label: "Canada", code: "CA" },
	{ value: "Cape Verde", label: "Cape Verde", code: "CV" },
	{ value: "Cayman Islands", label: "Cayman Islands", code: "KY" },
	{
		value: "Central African Republic",
		label: "Central African Republic",
		code: "CF",
	},
	{ value: "Chad", label: "Chad", code: "TD" },
	{ value: "Chile", label: "Chile", code: "CL" },
	{ value: "China", label: "China", code: "CN" },
	{ value: "Christmas Island", label: "Christmas Island", code: "CX" },
	{
		value: "Cocos (Keeling) Islands",
		label: "Cocos (Keeling) Islands",
		code: "CC",
	},
	{ value: "Colombia", label: "Colombia", code: "CO" },
	{ value: "Comoros", label: "Comoros", code: "KM" },
	{ value: "Congo", label: "Congo", code: "CG" },
	{ value: "Congo", label: "Congo", code: "CD" },
	{ value: "Cook Islands", label: "Cook Islands", code: "CK" },
	{ value: "Costa Rica", label: "Costa Rica", code: "CR" },
	{ value: "Côte d'Ivoire", label: "Côte d'Ivoire", code: "CI" },
	{ value: "Croatia", label: "Croatia", code: "HR" },
	{ value: "Cuba", label: "Cuba", code: "CU" },
	{ value: "CuraÃ§ao", label: "CuraÃ§ao", code: "CW" },
	{ value: "Cyprus", label: "Cyprus", code: "CY" },
	{ value: "Czech Republic", label: "Czech Republic", code: "CZ" },
	{ value: "Denmark", label: "Denmark", code: "DK" },
	{ value: "Djibouti", label: "Djibouti", code: "DJ" },
	{ value: "Dominica", label: "Dominica", code: "DM" },
	{ value: "Dominican Republic", label: "Dominican Republic", code: "DO" },
	{ value: "Ecuador", label: "Ecuador", code: "EC" },
	{ value: "Egypt", label: "Egypt", code: "EG" },
	{ value: "El Salvador", label: "El Salvador", code: "SV" },
	{ value: "Equatorial Guinea", label: "Equatorial Guinea", code: "GQ" },
	{ value: "Eritrea", label: "Eritrea", code: "ER" },
	{ value: "Estonia", label: "Estonia", code: "EE" },
	{ value: "Ethiopia", label: "Ethiopia", code: "ET" },
	{
		value: "Falkland Islands (Malvinas)",
		label: "Falkland Islands (Malvinas)",
		code: "FK",
	},
	{ value: "Faroe Islands", label: "Faroe Islands", code: "FO" },
	{ value: "Fiji", label: "Fiji", code: "FJ" },
	{ value: "Finland", label: "Finland", code: "FI" },
	{ value: "France", label: "France", code: "FR" },
	{ value: "French Guiana", label: "French Guiana", code: "GF" },
	{ value: "French Polynesia", label: "French Polynesia", code: "PF" },
	{
		value: "French Southern Territories",
		label: "French Southern Territories",
		code: "TF",
	},
	{ value: "Gabon", label: "Gabon", code: "GA" },
	{ value: "Gambia", label: "Gambia", code: "GM" },
	{ value: "Georgia", label: "Georgia", code: "GE" },
	{ value: "Germany", label: "Germany", code: "DE" },
	{ value: "Ghana", label: "Ghana", code: "GH" },
	{ value: "Gibraltar", label: "Gibraltar", code: "GI" },
	{ value: "Greece", label: "Greece", code: "GR" },
	{ value: "Greenland", label: "Greenland", code: "GL" },
	{ value: "Grenada", label: "Grenada", code: "GD" },
	{ value: "Guadeloupe", label: "Guadeloupe", code: "GP" },
	{ value: "Guam", label: "Guam", code: "GU" },
	{ value: "Guatemala", label: "Guatemala", code: "GT" },
	{ value: "Guernsey", label: "Guernsey", code: "GG" },
	{ value: "Guinea", label: "Guinea", code: "GN" },
	{ value: "Guinea-Bissau", label: "Guinea-Bissau", code: "GW" },
	{ value: "Guyana", label: "Guyana", code: "GY" },
	{ value: "Haiti", label: "Haiti", code: "HT" },
	{
		value: "Heard Island and McDonald Islands",
		label: "Heard Island and McDonald Islands",
		code: "HM",
	},
	{
		value: "Holy See (Vatican City State)",
		label: "Holy See (Vatican City State)",
		code: "VA",
	},
	{ value: "Honduras", label: "Honduras", code: "HN" },
	{ value: "Hong Kong", label: "Hong Kong", code: "HK" },
	{ value: "Hungary", label: "Hungary", code: "HU" },
	{ value: "Iceland", label: "Iceland", code: "IS" },
	{ value: "India", label: "India", code: "IN" },
	{ value: "Indonesia", label: "Indonesia", code: "ID" },
	{ value: "Iran", label: "Iran", code: "IR" },
	{ value: "Iraq", label: "Iraq", code: "IQ" },
	{ value: "Ireland", label: "Ireland", code: "IE" },
	{ value: "Isle of Man", label: "Isle of Man", code: "IM" },
	{ value: "Israel", label: "Israel", code: "IL" },
	{ value: "Italy", label: "Italy", code: "IT" },
	{ value: "Jamaica", label: "Jamaica", code: "JM" },
	{ value: "Japan", label: "Japan", code: "JP" },
	{ value: "Jersey", label: "Jersey", code: "JE" },
	{ value: "Jordan", label: "Jordan", code: "JO" },
	{ value: "Kazakhstan", label: "Kazakhstan", code: "KZ" },
	{ value: "Kenya", label: "Kenya", code: "KE" },
	{ value: "Kiribati", label: "Kiribati", code: "KI" },
	{ value: "North Korea", label: "North Korea", code: "KP" },
	{ value: "South Korea", label: "South Korea", code: "KR" },
	{ value: "Kuwait", label: "Kuwait", code: "KW" },
	{ value: "Kyrgyzstan", label: "Kyrgyzstan", code: "KG" },
	{ value: "Laos", label: "Laos", code: "LA" },
	{ value: "Latvia", label: "Latvia", code: "LV" },
	{ value: "Lebanon", label: "Lebanon", code: "LB" },
	{ value: "Lesotho", label: "Lesotho", code: "LS" },
	{ value: "Liberia", label: "Liberia", code: "LR" },
	{ value: "Libya", label: "Libya", code: "LY" },
	{ value: "Liechtenstein", label: "Liechtenstein", code: "LI" },
	{ value: "Lithuania", label: "Lithuania", code: "LT" },
	{ value: "Luxembourg", label: "Luxembourg", code: "LU" },
	{ value: "Macao", label: "Macao", code: "MO" },
	{ value: "Macedonia", label: "Macedonia", code: "MK" },
	{ value: "Madagascar", label: "Madagascar", code: "MG" },
	{ value: "Malawi", label: "Malawi", code: "MW" },
	{ value: "Malaysia", label: "Malaysia", code: "MY" },
	{ value: "Maldives", label: "Maldives", code: "MV" },
	{ value: "Mali", label: "Mali", code: "ML" },
	{ value: "Malta", label: "Malta", code: "MT" },
	{ value: "Marshall Islands", label: "Marshall Islands", code: "MH" },
	{ value: "Martinique", label: "Martinique", code: "MQ" },
	{ value: "Mauritania", label: "Mauritania", code: "MR" },
	{ value: "Mauritius", label: "Mauritius", code: "MU" },
	{ value: "Mayotte", label: "Mayotte", code: "YT" },
	{ value: "Mexico", label: "Mexico", code: "MX" },
	{ value: "Micronesia", label: "Micronesia", code: "FM" },
	{ value: "Moldova", label: "Moldova", code: "MD" },
	{ value: "Monaco", label: "Monaco", code: "MC" },
	{ value: "Mongolia", label: "Mongolia", code: "MN" },
	{ value: "Montenegro", label: "Montenegro", code: "ME" },
	{ value: "Montserrat", label: "Montserrat", code: "MS" },
	{ value: "Morocco", label: "Morocco", code: "MA" },
	{ value: "Mozambique", label: "Mozambique", code: "MZ" },
	{ value: "Myanmar", label: "Myanmar", code: "MM" },
	{ value: "Namibia", label: "Namibia", code: "NA" },
	{ value: "Nauru", label: "Nauru", code: "NR" },
	{ value: "Nepal", label: "Nepal", code: "NP" },
	{ value: "Netherlands", label: "Netherlands", code: "NL" },
	{ value: "New Caledonia", label: "New Caledonia", code: "NC" },
	{ value: "New Zealand", label: "New Zealand", code: "NZ" },
	{ value: "Nicaragua", label: "Nicaragua", code: "NI" },
	{ value: "Niger", label: "Niger", code: "NE" },
	{ value: "Nigeria", label: "Nigeria", code: "NG" },
	{ value: "Niue", label: "Niue", code: "NU" },
	{ value: "Norfolk Island", label: "Norfolk Island", code: "NF" },
	{
		value: "Northern Mariana Islands",
		label: "Northern Mariana Islands",
		code: "MP",
	},
	{ value: "Norway", label: "Norway", code: "NO" },
	{ value: "Oman", label: "Oman", code: "OM" },
	{ value: "Pakistan", label: "Pakistan", code: "PK" },
	{ value: "Palau", label: "Palau", code: "PW" },
	{ value: "Palestine", label: "Palestine", code: "PS" },
	{ value: "Panama", label: "Panama", code: "PA" },
	{ value: "Papua New Guinea", label: "Papua New Guinea", code: "PG" },
	{ value: "Paraguay", label: "Paraguay", code: "PY" },
	{ value: "Peru", label: "Peru", code: "PE" },
	{ value: "Philippines", label: "Philippines", code: "PH" },
	{ value: "Pitcairn", label: "Pitcairn", code: "PN" },
	{ value: "Poland", label: "Poland", code: "PL" },
	{ value: "Portugal", label: "Portugal", code: "PT" },
	{ value: "Puerto Rico", label: "Puerto Rico", code: "PR" },
	{ value: "Qatar", label: "Qatar", code: "QA" },
	{ value: "Réunion", label: "Réunion", code: "RE" },
	{ value: "Romania", label: "Romania", code: "RO" },
	{ value: "Russian Federation", label: "Russian Federation", code: "RU" },
	{ value: "Rwanda", label: "Rwanda", code: "RW" },
	{ value: "Saint BarthÃ©lemy", label: "Saint BarthÃ©lemy", code: "BL" },
	{ value: "Saint Helena", label: "Saint Helena", code: "SH" },
	{
		value: "Saint Kitts and Nevis",
		label: "Saint Kitts and Nevis",
		code: "KN",
	},
	{ value: "Saint Lucia", label: "Saint Lucia", code: "LC" },
	{
		value: "Saint Martin (French part)",
		label: "Saint Martin (French part)",
		code: "MF",
	},
	{
		value: "Saint Pierre and Miquelon",
		label: "Saint Pierre and Miquelon",
		code: "PM",
	},
	{
		value: "Saint Vincent and the Grenadines",
		label: "Saint Vincent and the Grenadines",
		code: "VC",
	},
	{ value: "Samoa", label: "Samoa", code: "WS" },
	{ value: "San Marino", label: "San Marino", code: "SM" },
	{
		value: "Sao Tome and Principe",
		label: "Sao Tome and Principe",
		code: "ST",
	},
	{ value: "Saudi Arabia", label: "Saudi Arabia", code: "SA" },
	{ value: "Senegal", label: "Senegal", code: "SN" },
	{ value: "Serbia", label: "Serbia", code: "RS" },
	{ value: "Seychelles", label: "Seychelles", code: "SC" },
	{ value: "Sierra Leone", label: "Sierra Leone", code: "SL" },
	{ value: "Singapore", label: "Singapore", code: "SG" },
	{
		value: "Sint Maarten (Dutch part)",
		label: "Sint Maarten (Dutch part)",
		code: "SX",
	},
	{ value: "Slovakia", label: "Slovakia", code: "SK" },
	{ value: "Slovenia", label: "Slovenia", code: "SI" },
	{ value: "Solomon Islands", label: "Solomon Islands", code: "SB" },
	{ value: "Somalia", label: "Somalia", code: "SO" },
	{ value: "South Africa", label: "South Africa", code: "ZA" },
	{
		value: "South Georgia and the South Sandwich Islands",
		label: "South Georgia and the South Sandwich Islands",
		code: "GS",
	},
	{ value: "South Sudan", label: "South Sudan", code: "SS" },
	{ value: "Spain", label: "Spain", code: "ES" },
	{ value: "Sri Lanka", label: "Sri Lanka", code: "LK" },
	{ value: "Sudan", label: "Sudan", code: "SD" },
	{ value: "Suriname", label: "Suriname", code: "SR" },
	{
		value: "Svalbard and Jan Mayen",
		label: "Svalbard and Jan Mayen",
		code: "SJ",
	},
	{ value: "Swaziland", label: "Swaziland", code: "SZ" },
	{ value: "Sweden", label: "Sweden", code: "SE" },
	{ value: "Switzerland", label: "Switzerland", code: "CH" },
	{ value: "Syrian Arab Republic", label: "Syrian Arab Republic", code: "SY" },
	{ value: "Taiwan", label: "Taiwan", code: "TW" },
	{ value: "Tajikistan", label: "Tajikistan", code: "TJ" },
	{ value: "Tanzania", label: "Tanzania", code: "TZ" },
	{ value: "Thailand", label: "Thailand", code: "TH" },
	{ value: "Timor-Leste", label: "Timor-Leste", code: "TL" },
	{ value: "Togo", label: "Togo", code: "TG" },
	{ value: "Tokelau", label: "Tokelau", code: "TK" },
	{ value: "Tonga", label: "Tonga", code: "TO" },
	{ value: "Trinidad and Tobago", label: "Trinidad and Tobago", code: "TT" },
	{ value: "Tunisia", label: "Tunisia", code: "TN" },
	{ value: "Turkey", label: "Turkey", code: "TR" },
	{ value: "Turkmenistan", label: "Turkmenistan", code: "TM" },
	{
		value: "Turks and Caicos Islands",
		label: "Turks and Caicos Islands",
		code: "TC",
	},
	{ value: "Tuvalu", label: "Tuvalu", code: "TV" },
	{ value: "Uganda", label: "Uganda", code: "UG" },
	{ value: "Ukraine", label: "Ukraine", code: "UA" },
	{ value: "United Arab Emirates", label: "United Arab Emirates", code: "AE" },
	{ value: "United Kingdom", label: "United Kingdom", code: "GB" },
	{ value: "United States", label: "United States", code: "US" },
	{
		value: "United States Minor Outlying Islands",
		label: "United States Minor Outlying Islands",
		code: "UM",
	},
	{ value: "Uruguay", label: "Uruguay", code: "UY" },
	{ value: "Uzbekistan", label: "Uzbekistan", code: "UZ" },
	{ value: "Vanuatu", label: "Vanuatu", code: "VU" },
	{ value: "Venezuela", label: "Venezuela", code: "VE" },
	{ value: "Viet Nam", label: "Viet Nam", code: "VN" },
	{
		value: "British Virgin Islands",
		label: "British Virgin Islands",
		code: "VG",
	},
	{ value: "U.S. Virgin Islands", label: "U.S. Virgin Islands", code: "VI" },
	{ value: "Wallis and Futuna", label: "Wallis and Futuna", code: "WF" },
	{ value: "Western Sahara", label: "Western Sahara", code: "EH" },
	{ value: "Yemen", label: "Yemen", code: "YE" },
	{ value: "Zambia", label: "Zambia", code: "ZM" },
	{ value: "Zimbabwe", label: "Zimbabwe", code: "ZW" },
];

export const cityOptions = [
	{ value: "Kabul, AF", label: "Kabul, AF", code: "KBL" },
	{ value: "Mazar-e Sharif, AF", label: "Mazar-e Sharif, AF", code: "MZR-87" },
	{
		value: "temporal destination, AF",
		label: "temporal destination, AF",
		code: "TEMP",
	},
	{ value: "Tirana, AL", label: "Tirana, AL", code: "TIA-1" },
	{ value: "Escútari, AL", label: "Escútari, AL", code: "fake-631" },
	{ value: "Durres, AL", label: "Durres, AL", code: "DRR" },
	{ value: "Sarande, AL", label: "Sarande, AL", code: "SDE-1" },
	{ value: "Orikum, AL", label: "Orikum, AL", code: "ORK" },
	{ value: "Vlore, AL", label: "Vlore, AL", code: "VOE-1" },
	{ value: "Berat, AL", label: "Berat, AL", code: "BERT-1" },
	{ value: "Elbasan, AL", label: "Elbasan, AL", code: "ELBA-1" },
	{ value: "Fier, AL", label: "Fier, AL", code: "FIER-1" },
	{ value: "Gjirokastra, AL", label: "Gjirokastra, AL", code: "GJIR-1" },
	{ value: "Korce, AL", label: "Korce, AL", code: "KORC-1" },
	{ value: "Pogradec, AL", label: "Pogradec, AL", code: "POGR-1" },
	{ value: "Kruja, AL", label: "Kruja, AL", code: "KJA-002" },
	{ value: "Argel, DZ", label: "Argel, DZ", code: "ALG-1" },
	{ value: "Orán, DZ", label: "Orán, DZ", code: "TAF" },
	{ value: "Constantina, DZ", label: "Constantina, DZ", code: "CZL" },
	{
		value: "Andorra la Vella, AD",
		label: "Andorra la Vella, AD",
		code: "AND-25",
	},
	{ value: "Andorra la Vella, AD", label: "Andorra la Vella, AD", code: "AND" },
	{ value: "Pas de la Casa, AD", label: "Pas de la Casa, AD", code: "AND-5" },
	{
		value: "L´Aldosa de la Massana, AD",
		label: "L´Aldosa de la Massana, AD",
		code: "and20",
	},
	{ value: "Soldeu, AD", label: "Soldeu, AD", code: "AND-10" },
	{ value: "Canillo, AD", label: "Canillo, AD", code: "AND-20" },
	{ value: "Arinsal, AD", label: "Arinsal, AD", code: "AND-40" },
	{
		value: " Grandvalira , AD",
		label: " Grandvalira , AD",
		code: "fakeGrandvalira",
	},
	{ value: "Vallnord, AD", label: "Vallnord, AD", code: "aaa" },
	{ value: "Las Escaldas, AD", label: "Las Escaldas, AD", code: "AND-2" },
	{ value: "Ordino, AD", label: "Ordino, AD", code: "AND-6" },
	{ value: "El Tarter, AD", label: "El Tarter, AD", code: "AND-55" },
	{ value: "Encamp, AD", label: "Encamp, AD", code: "AND-ENCAMAND6" },
	{ value: "Erts, AD", label: "Erts, AD", code: "ERTS" },
	{ value: "El Serrat, AD", label: "El Serrat, AD", code: "Elserrat" },
	{ value: "Lobito, AO", label: "Lobito, AO", code: "BVG" },
	{ value: "Luanda, AO", label: "Luanda, AO", code: "LAD1" },
	{ value: "Anguilla, AI", label: "Anguilla, AI", code: "AXA" },
	{
		value: "Antigua and Barbuda, AG",
		label: "Antigua and Barbuda, AG",
		code: "ANU",
	},
	{
		value: "Saint John&apos;s, AG",
		label: "Saint John&apos;s, AG",
		code: "fake-618",
	},
	{
		value: "Five Islands Village, AG",
		label: "Five Islands Village, AG",
		code: "fake-619",
	},
	{ value: "Barbuda, AG", label: "Barbuda, AG", code: "FakeBarbuda" },
	{ value: "Liberta, AG", label: "Liberta, AG", code: "fake-614" },
	{
		value: "Potters Village, AG",
		label: "Potters Village, AG",
		code: "fake-615",
	},
	{ value: "Buenos Aires, AR", label: "Buenos Aires, AR", code: "BUE" },
	{ value: "Mar del Plata, AR", label: "Mar del Plata, AR", code: "MDQ" },
	{ value: "Ushuaia, AR", label: "Ushuaia, AR", code: "USH" },
	{ value: "Entre Ríos, AR", label: "Entre Ríos, AR", code: "TOUENT" },
	{
		value: "San Carlos de Bariloche, AR",
		label: "San Carlos de Bariloche, AR",
		code: "BCH",
	},
	{
		value: "San Martín de los Andes, AR",
		label: "San Martín de los Andes, AR",
		code: "CPC",
	},
	{ value: "Cordoba, AR", label: "Cordoba, AR", code: "CRD" },
	{ value: "Puerto Iguazú, AR", label: "Puerto Iguazú, AR", code: "IGU" },
	{ value: "Jujuy, AR", label: "Jujuy, AR", code: "JUJ" },
	{ value: "San Luis, AR", label: "San Luis, AR", code: "LUQ" },
	{ value: "Mendoza, AR", label: "Mendoza, AR", code: "MEZ" },
	{ value: "Puerto Madryn, AR", label: "Puerto Madryn, AR", code: "PMY" },
	{ value: "Rosario, AR", label: "Rosario, AR", code: "RO6" },
	{ value: "Tucumán, AR", label: "Tucumán, AR", code: "TUC" },
	{ value: "San Juan, AR", label: "San Juan, AR", code: "UAQ" },
	{ value: "Bahía Blanca, AR", label: "Bahía Blanca, AR", code: "AR-1" },
	{ value: "Sarmiento, AR", label: "Sarmiento, AR", code: "AR-2" },
	{ value: "Tehuelches, AR", label: "Tehuelches, AR", code: "AR-3" },
	{ value: "El Calafate, AR", label: "El Calafate, AR", code: "CFE-1" },
	{ value: "Río Negro, AR", label: "Río Negro, AR", code: "RNG-99" },
	{ value: "Santa Cruz, AR", label: "Santa Cruz, AR", code: "TOUSAN" },
	{ value: "Santa Fe, AR", label: "Santa Fe, AR", code: "TUR-STF" },
	{
		value: "Villa La Angostura, AR",
		label: "Villa La Angostura, AR",
		code: "NQN",
	},
	{ value: "Salta, AR", label: "Salta, AR", code: "SLT" },
	{ value: "El Chaltén, AR", label: "El Chaltén, AR", code: "CFE-2" },
	{ value: "Cafayate, AR", label: "Cafayate, AR", code: "SLT-2" },
	{
		value: "San Miguel de Tucumán, AR",
		label: "San Miguel de Tucumán, AR",
		code: "SMT-2",
	},
	{
		value: "San Salvador de Jujuy , AR",
		label: "San Salvador de Jujuy , AR",
		code: "SSJ-2",
	},
	{
		value: "Tigre, Buenos Aires, AR",
		label: "Tigre, Buenos Aires, AR",
		code: "TGR-2",
	},
	{ value: "Villa Gesell, AR", label: "Villa Gesell, AR", code: "VGS-2" },
	{ value: "Chubut, AR", label: "Chubut, AR", code: "TOUCHU" },
	{ value: "Corrientes, AR", label: "Corrientes, AR", code: "TOUCOR" },
	{ value: "Misiones, AR", label: "Misiones, AR", code: "TOUMIS" },
	{ value: "La Plata, AR", label: "La Plata, AR", code: "LAP-123" },
	{ value: "Tílcara, AR", label: "Tílcara, AR", code: "Fake-154" },
	{ value: "Cachi, AR", label: "Cachi, AR", code: "Fake-155" },
	{ value: "La Rioja, AR", label: "La Rioja, AR", code: "IRJ" },
	{
		value: "Pilar, Buenos Aires, AR",
		label: "Pilar, Buenos Aires, AR",
		code: "BUE-3",
	},
	{ value: "Colón, AR", label: "Colón, AR", code: "CLO-3" },
	{ value: "Concordia, AR", label: "Concordia, AR", code: "CNC-2" },
	{ value: "Cariló, AR", label: "Cariló, AR", code: "CRL-2" },
	{ value: "Garupá, AR", label: "Garupá, AR", code: "GRP-6" },
	{ value: "Lanús, AR", label: "Lanús, AR", code: "LNS-6" },
	{ value: "San Rafael, AR", label: "San Rafael, AR", code: "MEZ-2" },
	{ value: "Miramar, AR", label: "Miramar, AR", code: "MRM-2" },
	{ value: "Pinamar , AR", label: "Pinamar , AR", code: "PNM-2" },
	{ value: "Paraná, AR", label: "Paraná, AR", code: "PRA-1" },
	{ value: "Posadas, AR", label: "Posadas, AR", code: "PSA-2" },
	{ value: "Purmamarca, AR", label: "Purmamarca, AR", code: "PUM-3" },
	{ value: "Tandil, AR", label: "Tandil, AR", code: "TDL-1" },
	{ value: "Quilmes, AR", label: "Quilmes, AR", code: "QLMS-6" },
	{
		value: "Termas de Río Hondo, AR",
		label: "Termas de Río Hondo, AR",
		code: "TDRH-6",
	},
	{ value: "Plaza Huincul, AR", label: "Plaza Huincul, AR", code: "fake-341" },
	{
		value: "Villa Carlos Paz, AR",
		label: "Villa Carlos Paz, AR",
		code: "fake-762",
	},
	{ value: "Luján, AR", label: "Luján, AR", code: "fake-763" },
	{ value: "Esquel, AR", label: "Esquel, AR", code: "EQS" },
	{ value: "Neuquen, AR", label: "Neuquen, AR", code: "NQ1" },
	{ value: "Ezeiza, AR", label: "Ezeiza, AR", code: "BUE4" },
	{
		value: "San Antonio de Areco, AR",
		label: "San Antonio de Areco, AR",
		code: "BUE5",
	},
	{
		value: "San Fernando del Valle de Catamarca, AR",
		label: "San Fernando del Valle de Catamarca, AR",
		code: "CTC1",
	},
	{ value: "Villa de Merlo, AR", label: "Villa de Merlo, AR", code: "MRLO" },
	{ value: "Cancha Carrera, AR", label: "Cancha Carrera, AR", code: "PNT2" },
	{ value: "Resistencia, AR", label: "Resistencia, AR", code: "RES1" },
	{ value: "Luján de Cuyo, AR", label: "Luján de Cuyo, AR", code: "LUJ-6" },
	{ value: "Maipú, AR", label: "Maipú, AR", code: "MAI-6" },
	{ value: "Malargüe , AR", label: "Malargüe , AR", code: "MAL-6" },
	{
		value: "Mendoza (Provincia), AR",
		label: "Mendoza (Provincia), AR",
		code: "MEN-6",
	},
	{
		value: "Mar de las Pampas, AR",
		label: "Mar de las Pampas, AR",
		code: "PAM-6",
	},
	{ value: "Potrerillos, AR", label: "Potrerillos, AR", code: "POT-6" },
	{ value: "Río Cuarto, AR", label: "Río Cuarto, AR", code: "RCU-1" },
	{ value: "Tupungato, AR", label: "Tupungato, AR", code: "TUP-6" },
	{ value: "Uspallata, AR", label: "Uspallata, AR", code: "UPS-6" },
	{ value: "Villa Mercedes, AR", label: "Villa Mercedes, AR", code: "VLMRC" },
	{
		value: "Belén de Escobar, AR",
		label: "Belén de Escobar, AR",
		code: "BEDE-1",
	},
	{
		value: "Cañuelas, Buenos Aires, AR",
		label: "Cañuelas, Buenos Aires, AR",
		code: "CANU-1",
	},
	{
		value: "Comodoro Rivadavia, AR",
		label: "Comodoro Rivadavia, AR",
		code: "CORI-1",
	},
	{ value: "Formosa, AR", label: "Formosa, AR", code: "FORM-1" },
	{
		value: "Zárate, Buenos Aires, AR",
		label: "Zárate, Buenos Aires, AR",
		code: "ZARA-1",
	},
	{ value: "Campana, AR", label: "Campana, AR", code: "CMP-002" },
	{ value: "Las Grutas, AR", label: "Las Grutas, AR", code: "LGR-002" },
	{ value: "Tunuyán, AR", label: "Tunuyán, AR", code: "TUNUYAN" },
	{ value: "Viedma, AR", label: "Viedma, AR", code: "VDM-002" },
	{ value: "Valle Fértil, AR", label: "Valle Fértil, AR", code: "VFL-002" },
	{
		value: "Villa General Belgrano, AR",
		label: "Villa General Belgrano, AR",
		code: "VILLA-6",
	},
	{
		value: "Valeria del Mar , AR",
		label: "Valeria del Mar , AR",
		code: "VALMAR-6",
	},
	{ value: "Ereván, AM", label: "Ereván, AM", code: "EVN" },
	{ value: "Dilijan, AM", label: "Dilijan, AM", code: "DIL-6" },
	{ value: "Aruba, AW", label: "Aruba, AW", code: "AUA" },
	{ value: "San Nicolaas, AW", label: "San Nicolaas, AW", code: "fake-617" },
	{ value: "Oranjestad, AW", label: "Oranjestad, AW", code: "fake-608" },
	{ value: "Noord, AW", label: "Noord, AW", code: "fake-610" },
	{ value: "Paradera, AW", label: "Paradera, AW", code: "fake-611" },
	{
		value: "Santa Cruz, Aruba, AW",
		label: "Santa Cruz, Aruba, AW",
		code: "fake-612",
	},
	{ value: "Savaneta, AW", label: "Savaneta, AW", code: "fake-616" },
	{ value: "Canberra, AU", label: "Canberra, AU", code: "CBR" },
	{ value: "Sídney, AU", label: "Sídney, AU", code: "SYD" },
	{ value: "Stanley, AU", label: "Stanley, AU", code: "HBA-34" },
	{ value: "Melbourne, AU", label: "Melbourne, AU", code: "MLB" },
	{ value: "Islas Hamilton, AU", label: "Islas Hamilton, AU", code: "AU9-5" },
	{ value: "Adelaida, AU", label: "Adelaida, AU", code: "ADL" },
	{ value: "Alice Springs, AU", label: "Alice Springs, AU", code: "ASP" },
	{ value: "Brisbane, AU", label: "Brisbane, AU", code: "BNE" },
	{ value: "Newcastle, AU", label: "Newcastle, AU", code: "NTL" },
	{ value: "Bunbury, AU", label: "Bunbury, AU", code: "AW2-7" },
	{ value: "Launceston, AU", label: "Launceston, AU", code: "HBA-2" },
	{ value: "Geraldton, AU", label: "Geraldton, AU", code: "AU0" },
	{ value: "Byron Bay, AU", label: "Byron Bay, AU", code: "AU1" },
	{ value: "Rockhampton, AU", label: "Rockhampton, AU", code: "AU8" },
	{ value: "Broome , AU", label: "Broome , AU", code: "BME" },
	{ value: "Cairns, AU", label: "Cairns, AU", code: "CNS" },
	{ value: "Darwin, AU", label: "Darwin, AU", code: "DRW" },
	{ value: "Sunshine Coast, AU", label: "Sunshine Coast, AU", code: "MCY" },
	{ value: "Tweed Heads, AU", label: "Tweed Heads, AU", code: "QLD" },
	{ value: "Wollogong, AU", label: "Wollogong, AU", code: "SC9" },
	{ value: "Townsville, AU", label: "Townsville, AU", code: "TV5" },
	{ value: "Toowoomba, AU", label: "Toowoomba, AU", code: "TWD" },
	{ value: "Exmouth, AU", label: "Exmouth, AU", code: "AU0-1" },
	{ value: "Coral Bay, AU", label: "Coral Bay, AU", code: "AU0-9" },
	{ value: "Port Macquarie, AU", label: "Port Macquarie, AU", code: "AU1-9" },
	{ value: "Coober Pedy, AU", label: "Coober Pedy, AU", code: "AU7-5" },
	{ value: "Port Lincoln, AU", label: "Port Lincoln, AU", code: "AU7-7" },
	{ value: "Mackay, AU", label: "Mackay, AU", code: "AU8-1" },
	{
		value: "La playa de Airlie, AU",
		label: "La playa de Airlie, AU",
		code: "AU9-3",
	},
	{ value: "Cervantes, AU", label: "Cervantes, AU", code: "AUO-6" },
	{ value: "Busselton , AU", label: "Busselton , AU", code: "AW2-8" },
	{ value: "Ayers Rock, AU", label: "Ayers Rock, AU", code: "AYQ-1" },
	{ value: "Port Hedland, AU", label: "Port Hedland, AU", code: "BME-3" },
	{ value: "Kununurra, AU", label: "Kununurra, AU", code: "BME-5" },
	{ value: "Karratha, AU", label: "Karratha, AU", code: "BME-6" },
	{ value: "Derby, AU", label: "Derby, AU", code: "BME-7" },
	{ value: "Halls Creek, AU", label: "Halls Creek, AU", code: "BME-8" },
	{ value: "Traralgon, AU", label: "Traralgon, AU", code: "BSJ-5" },
	{ value: "Forrest, AU", label: "Forrest, AU", code: "CBR-7" },
	{ value: "Bundaberg, AU", label: "Bundaberg, AU", code: "FC5-2" },
	{ value: "Puerto Augusta, AU", label: "Puerto Augusta, AU", code: "FLS-1" },
	{ value: "Geelong, AU", label: "Geelong, AU", code: "GCR-2" },
	{ value: "Devonport, AU", label: "Devonport, AU", code: "HBA-3" },
	{ value: "Wagga Wagga, AU", label: "Wagga Wagga, AU", code: "HC5-2" },
	{ value: "Broken Hill, AU", label: "Broken Hill, AU", code: "HC5-4" },
	{ value: "Bathurst, AU", label: "Bathurst, AU", code: "HC5-7" },
	{ value: "Katherine, AU", label: "Katherine, AU", code: "KT2-1" },
	{ value: "Mataranka, AU", label: "Mataranka, AU", code: "KT2-2" },
	{ value: "Mount Gambier, AU", label: "Mount Gambier, AU", code: "MGB-2" },
	{ value: "Mount Isa, AU", label: "Mount Isa, AU", code: "OQ1-5" },
	{ value: "Albany , AU", label: "Albany , AU", code: "PER-5" },
	{ value: "Mandurah, AU", label: "Mandurah, AU", code: "PER-7" },
	{ value: "Mildura, AU", label: "Mildura, AU", code: "TM1-3" },
	{ value: "Albury-Wodonga, AU", label: "Albury-Wodonga, AU", code: "TM2-2" },
	{ value: "Victoria, AU", label: "Victoria, AU", code: "VCD-1" },
	{ value: "Kaitaia, AU", label: "Kaitaia, AU", code: "WRE-5" },
	{ value: "Hervey Bay, AU", label: "Hervey Bay, AU", code: "fc5-1" },
	{ value: "Coffs Harbour, AU", label: "Coffs Harbour, AU", code: "AU1-12" },
	{ value: "Gladstone, AU", label: "Gladstone, AU", code: "AU8-15" },
	{ value: "Margaret River, AU", label: "Margaret River, AU", code: "AW2-11" },
	{ value: "Port Hedland , AU", label: "Port Hedland , AU", code: "BME-99" },
	{ value: "Strahan, AU", label: "Strahan, AU", code: "HBA-10" },
	{ value: "Port Arthur, AU", label: "Port Arthur, AU", code: "HBA-16" },
	{ value: "Burnie, AU", label: "Burnie, AU", code: "HBA-32" },
	{ value: "Smithton, AU", label: "Smithton, AU", code: "HBA-33" },
	{ value: "Wynyard, AU", label: "Wynyard, AU", code: "HBA-36" },
	{ value: "Hobart, AU", label: "Hobart, AU", code: "HBA-41" },
	{ value: "Triabunna, AU", label: "Triabunna, AU", code: "HBA-44" },
	{ value: "Queanbeyan, AU", label: "Queanbeyan, AU", code: "HC5-14" },
	{ value: "Esperance, AU", label: "Esperance, AU", code: "PER-10" },
	{ value: "Fremantle, AU", label: "Fremantle, AU", code: "PER-12" },
	{ value: "Warburton, AU", label: "Warburton, AU", code: "VCD-30" },
	{ value: "Ballarat, AU", label: "Ballarat, AU", code: "EBS" },
	{ value: "Emerald, AU", label: "Emerald, AU", code: "EMR" },
	{ value: "Isla King, AU", label: "Isla King, AU", code: "KNI" },
	{ value: "Gold Coast, AU", label: "Gold Coast, AU", code: "OOL" },
	{ value: "Perth, AU", label: "Perth, AU", code: "PER" },
	{
		value: "Tamworth, New South Wales, AU",
		label: "Tamworth, New South Wales, AU",
		code: "AU15",
	},
	{
		value: "Ballina, New South Wales, AU",
		label: "Ballina, New South Wales, AU",
		code: "AU129",
	},
	{ value: "Dunsborough, AU", label: "Dunsborough, AU", code: "AW2-9" },
	{ value: "Gawler, AU", label: "Gawler, AU", code: "BV5-5" },
	{ value: "Victor Harbor, AU", label: "Victor Harbor, AU", code: "FPS-2" },
	{ value: "Apollo Bay, AU", label: "Apollo Bay, AU", code: "GCR-1" },
	{
		value: "Parque nacional de los Montes Grampianos, AU",
		label: "Parque nacional de los Montes Grampianos, AU",
		code: "GP5-1",
	},
	{ value: "Hobart, AU", label: "Hobart, AU", code: "HBA-1" },
	{ value: "Jabiru, AU", label: "Jabiru, AU", code: "JAB-1" },
	{ value: "Kangaroo Island, AU", label: "Kangaroo Island, AU", code: "KGC-1" },
	{ value: "Maryborough, AU", label: "Maryborough, AU", code: "MBH-1" },
	{ value: "Maroochydore, AU", label: "Maroochydore, AU", code: "MCY-1" },
	{ value: "Norfolk Island, AU", label: "Norfolk Island, AU", code: "NLK-1" },
	{ value: "Orange, AU", label: "Orange, AU", code: "OAG-1" },
	{
		value: "Surfers Paradise, AU",
		label: "Surfers Paradise, AU",
		code: "OOL-1",
	},
	{ value: "Phillip Island, AU", label: "Phillip Island, AU", code: "PH5-1" },
	{
		value: "Whitsunday Islands, AU",
		label: "Whitsunday Islands, AU",
		code: "QLD-2",
	},
	{ value: "Fraser Island, AU", label: "Fraser Island, AU", code: "QLD-5" },
	{ value: "Stradbroke, AU", label: "Stradbroke, AU", code: "QLD-7" },
	{ value: "Cooktown, AU", label: "Cooktown, AU", code: "QLD-8" },
	{ value: "Rockhampton, AU", label: "Rockhampton, AU", code: "ROK-1" },
	{
		value: "Port Douglas (Craigle), AU",
		label: "Port Douglas (Craigle), AU",
		code: "TN5-2",
	},
	{ value: "Bendigo, AU", label: "Bendigo, AU", code: "VCD-7" },
	{ value: "Halls Gap, AU", label: "Halls Gap, AU", code: "HGAP-6" },
	{ value: "Niue Island, AU", label: "Niue Island, AU", code: "NIS-65" },
	{ value: "Port Campbell, AU", label: "Port Campbell, AU", code: "PCA-11" },
	{ value: "Jervis Bay, AU", label: "Jervis Bay, AU", code: "SC9-12" },
	{ value: "Port Fairy, AU", label: "Port Fairy, AU", code: "FAIRY-6" },
	{ value: "Preston Beach, AU", label: "Preston Beach, AU", code: "fake-357" },
	{ value: "Palm Cove, AU", label: "Palm Cove, AU", code: "fake-647" },
	{ value: "Oak Beach, AU", label: "Oak Beach, AU", code: "fake-648" },
	{ value: "Agnes Water, AU", label: "Agnes Water, AU", code: "fake-649" },
	{
		value: "Newport (Australia), AU",
		label: "Newport (Australia), AU",
		code: "fake-650",
	},
	{ value: "Echuca, AU", label: "Echuca, AU", code: "fake-651" },
	{ value: "Kalgoorlie, AU", label: "Kalgoorlie, AU", code: "fake-701" },
	{ value: "Kalbarri, AU", label: "Kalbarri, AU", code: "fake-702" },
	{ value: "Carnarvon, AU", label: "Carnarvon, AU", code: "fake-704" },
	{ value: "Coral Bay, AU", label: "Coral Bay, AU", code: "fake-705" },
	{ value: "Pemberton, AU", label: "Pemberton, AU", code: "fake-706" },
	{ value: "Glenelg, AU", label: "Glenelg, AU", code: "fake-709" },
	{ value: "Whyalla, AU", label: "Whyalla, AU", code: "fake-710" },
	{ value: "Naracoorte, AU", label: "Naracoorte, AU", code: "fake-711" },
	{ value: "Kingscote, AU", label: "Kingscote, AU", code: "fake-712" },
	{ value: "McLaren Vale, AU", label: "McLaren Vale, AU", code: "fake-713" },
	{ value: "Ceduna, AU", label: "Ceduna, AU", code: "fake-714" },
	{ value: "Penneshaw, AU", label: "Penneshaw, AU", code: "fake-715" },
	{ value: "Lyndoch, AU", label: "Lyndoch, AU", code: "fake-716" },
	{ value: "Port Elliot, AU", label: "Port Elliot, AU", code: "fake-717" },
	{ value: "Gosford, AU", label: "Gosford, AU", code: "fake-718" },
	{ value: "Maitland , AU", label: "Maitland , AU", code: "fake-719" },
	{ value: "Dubbo, AU", label: "Dubbo, AU", code: "fake-720" },
	{ value: "Armidale, AU", label: "Armidale, AU", code: "fake-721" },
	{ value: "Lismore, AU", label: "Lismore, AU", code: "fake-722" },
	{ value: "Goulburn, AU", label: "Goulburn, AU", code: "fake-723" },
	{ value: "Tenterfield, AU", label: "Tenterfield, AU", code: "fake-724" },
	{ value: "Bowral, AU", label: "Bowral, AU", code: "fake-725" },
	{ value: " Katoomba, AU", label: " Katoomba, AU", code: "fake-726" },
	{ value: "Noosa, AU", label: "Noosa, AU", code: "fake-727" },
	{ value: "Mission Beach, AU", label: "Mission Beach, AU", code: "fake-728" },
	{ value: "Atherton, AU", label: "Atherton, AU", code: "fake-729" },
	{ value: "Caloundra , AU", label: "Caloundra , AU", code: "fake-730" },
	{ value: "Rainbow Beach, AU", label: "Rainbow Beach, AU", code: "fake-731" },
	{ value: "Glen Helen, AU", label: "Glen Helen, AU", code: "GLE-1" },
	{ value: "Kings Canyon, AU", label: "Kings Canyon, AU", code: "KCA-1" },
	{
		value: "Parque nacional Kakadu, AU",
		label: "Parque nacional Kakadu, AU",
		code: "KKD-1",
	},
	{ value: "Robe, AU", label: "Robe, AU", code: "ROB-1" },
	{ value: "Warrnambool, AU", label: "Warrnambool, AU", code: "WAR-1" },
	{ value: "Manjimup, AU", label: "Manjimup, AU", code: "MANJ-1" },
	{ value: "Manly, AU", label: "Manly, AU", code: "MANL-1" },
	{ value: "Nannup , AU", label: "Nannup , AU", code: "Nannup" },
	{
		value: "Montañas Azules, AU",
		label: "Montañas Azules, AU",
		code: "blmtnp",
	},
	{
		value: "Port Macquarie-Hastings, AU",
		label: "Port Macquarie-Hastings, AU",
		code: "PQQ-002",
	},
	{
		value: "Roma, Queensland, AU",
		label: "Roma, Queensland, AU",
		code: "RMA-002",
	},
	{ value: "Northampton, AU", label: "Northampton, AU", code: "NARTHTON-6" },
	{
		value: "Port Phillip , AU",
		label: "Port Phillip , AU",
		code: "Portphilip",
	},
	{ value: "Viena, AT", label: "Viena, AT", code: "VIE" },
	{
		value: "Salzkammergut (Región de Lagos), AT",
		label: "Salzkammergut (Región de Lagos), AT",
		code: "BAD",
	},
	{ value: "Carincia, AT", label: "Carincia, AT", code: "CRN" },
	{ value: "Graz, AT", label: "Graz, AT", code: "GRZ" },
	{
		value: "Klagenfurt (Worthersee), AT",
		label: "Klagenfurt (Worthersee), AT",
		code: "KLU",
	},
	{ value: "Linz, AT", label: "Linz, AT", code: "LNZ" },
	{
		value: "Salzburg (Region), AT",
		label: "Salzburg (Region), AT",
		code: "SZG",
	},
	{ value: "Kaprun, AT", label: "Kaprun, AT", code: "AAZ1" },
	{ value: "Kitzbühel, AT", label: "Kitzbühel, AT", code: "AAZ-2" },
	{ value: "Ischgl, AT", label: "Ischgl, AT", code: "AAZ15" },
	{ value: "Innsbruck, AT", label: "Innsbruck, AT", code: "INN-1" },
	{ value: "Salzburgo, AT", label: "Salzburgo, AT", code: "SZG-1" },
	{ value: "Bad Hofgastein, AT", label: "Bad Hofgastein, AT", code: "VIE-1" },
	{ value: "Flachau, AT", label: "Flachau, AT", code: "VIE-3" },
	{ value: "Zell am Ziller, AT", label: "Zell am Ziller, AT", code: "VIE-7" },
	{ value: "Krimml, AT", label: "Krimml, AT", code: "VIE-8" },
	{ value: "Mariazell, AT", label: "Mariazell, AT", code: "ZEL-1" },
	{ value: "Seefeld, AT", label: "Seefeld, AT", code: "AAF-11" },
	{ value: "Mittelberg, AT", label: "Mittelberg, AT", code: "AAZ-10" },
	{ value: "Fugen, AT", label: "Fugen, AT", code: "AAZ-24" },
	{
		value: "Bad Kleinkirchenheim, AT",
		label: "Bad Kleinkirchenheim, AT",
		code: "AAZ-32",
	},
	{ value: "Mittersill, AT", label: "Mittersill, AT", code: "AAZ-76" },
	{ value: "Tirol, AT", label: "Tirol, AT", code: "FAKETirol" },
	{
		value: "Hallstatt (Village), AT",
		label: "Hallstatt (Village), AT",
		code: "AAZ",
	},
	{ value: "Bad Gastein, AT", label: "Bad Gastein, AT", code: "AAZ-8" },
	{ value: "Lech am Arlberg, AT", label: "Lech am Arlberg, AT", code: "AAZ29" },
	{ value: "Schladming, AT", label: "Schladming, AT", code: "AAZ31" },
	{ value: "Westendorf, AT", label: "Westendorf, AT", code: "AAZ85" },
	{ value: "Villach, AT", label: "Villach, AT", code: "CRT-4" },
	{ value: "Baden, AT", label: "Baden, AT", code: "VIE-2" },
	{
		value: "St Anton  am Arlberg, AT",
		label: "St Anton  am Arlberg, AT",
		code: "AAZ-12",
	},
	{ value: "Zell am See, AT", label: "Zell am See, AT", code: "AAZ-18" },
	{ value: "Lech, AT", label: "Lech, AT", code: "AAZ-29" },
	{
		value: "Sankt Anton am Arlberg, AT",
		label: "Sankt Anton am Arlberg, AT",
		code: "AZZ-12",
	},
	{
		value: "St. Wolfgang im Salzkammergut, AT",
		label: "St. Wolfgang im Salzkammergut, AT",
		code: "WLG-59",
	},
	{
		value: "Olympia Skiworld, AT",
		label: "Olympia Skiworld, AT",
		code: "FakeOlympiaskiworld",
	},
	{ value: "Styria, AT", label: "Styria, AT", code: "STY" },
	{ value: "Vorarlberg, AT", label: "Vorarlberg, AT", code: "VOR" },
	{
		value: "Saalbach-Hinterglemm , AT",
		label: "Saalbach-Hinterglemm , AT",
		code: "AAZ7",
	},
	{ value: "Axamer Lizum , AT", label: "Axamer Lizum , AT", code: "AAZ-1" },
	{ value: "Filzmoos , AT", label: "Filzmoos , AT", code: "AAZ-3" },
	{ value: "Gaschurn , AT", label: "Gaschurn , AT", code: "AAZ-4" },
	{ value: "Hintertux, AT", label: "Hintertux, AT", code: "AAZ23" },
	{ value: "Gerlos, AT", label: "Gerlos, AT", code: "AAZ25" },
	{ value: "Mayrhofen, AT", label: "Mayrhofen, AT", code: "AAZ27" },
	{ value: "Kirchberg, AT", label: "Kirchberg, AT", code: "AAZ36" },
	{ value: "Sölden, AT", label: "Sölden, AT", code: "AAZ38" },
	{ value: "Bürserberg , AT", label: "Bürserberg , AT", code: "AAZ61" },
	{ value: "Königsleiten, AT", label: "Königsleiten, AT", code: "AAZ66" },
	{ value: "Soll, AT", label: "Soll, AT", code: "SOL-1" },
	{ value: "Fusch, AT", label: "Fusch, AT", code: "VIE-5" },
	{ value: "Grossarl, AT", label: "Grossarl, AT", code: "VIE-6" },
	{ value: "Mallnitz, AT", label: "Mallnitz, AT", code: "AAZ-19" },
	{ value: "Gleisdorf, AT", label: "Gleisdorf, AT", code: "GLDF-6" },
	{ value: "Kufstein, AT", label: "Kufstein, AT", code: "KFST-6" },
	{ value: "Klamm, AT", label: "Klamm, AT", code: "KLAM-6" },
	{ value: "Zillertal, AT", label: "Zillertal, AT", code: "fak-46" },
	{ value: "Axams , AT", label: "Axams , AT", code: "fake-307" },
	{ value: "Brixen, AT", label: "Brixen, AT", code: "fake-308" },
	{ value: "Obergurgl , AT", label: "Obergurgl , AT", code: "fake-312" },
	{ value: "Stubai, AT", label: "Stubai, AT", code: "FAKEStubai" },
	{ value: "Kitzbühel , AT", label: "Kitzbühel , AT", code: "FAKEKitzbuhel" },
	{ value: "Mutters, AT", label: "Mutters, AT", code: "MUT" },
	{
		value: "Kloesterle am Arlberg, AT",
		label: "Kloesterle am Arlberg, AT",
		code: "1478",
	},
	{
		value: "Pettneu am Arlberg, AT",
		label: "Pettneu am Arlberg, AT",
		code: "8520",
	},
	{
		value: "Stuben am Arlberg, AT",
		label: "Stuben am Arlberg, AT",
		code: "98745",
	},
	{ value: "Angerberg, AT", label: "Angerberg, AT", code: "ANG-6" },
	{
		value: "Bad Kleinkircheim, AT",
		label: "Bad Kleinkircheim, AT",
		code: "BDL-1",
	},
	{ value: "Fieberbrunn, AT", label: "Fieberbrunn, AT", code: "FIE-1" },
	{
		value: "Matrei am Brenner, AT",
		label: "Matrei am Brenner, AT",
		code: "MAB-1",
	},
	{
		value: "St.Christoph am Arlberg, AT",
		label: "St.Christoph am Arlberg, AT",
		code: "321654",
	},
	{ value: "Hall in Tirol, AT", label: "Hall in Tirol, AT", code: "HITL-6" },
	{ value: "Leutasch, AT", label: "Leutasch, AT", code: "LAUT-1" },
	{
		value: "Keutschach am See, AT",
		label: "Keutschach am See, AT",
		code: "KAS-002",
	},
	{ value: "Achenkirch, AT", label: "Achenkirch, AT", code: "AKIRCH-6" },
	{
		value: "Neustift im Stubaital, AT",
		label: "Neustift im Stubaital, AT",
		code: "Neustift",
	},
	{
		value: "Puch bei Hallein, AT",
		label: "Puch bei Hallein, AT",
		code: "PUCHIN-6",
	},
	{
		value: "Ramsau am Dachstein, AT",
		label: "Ramsau am Dachstein, AT",
		code: "RAMSAU-6",
	},
	{ value: "Walchsee , AT", label: "Walchsee , AT", code: "Walchsee" },
	{
		value: "Gerasdorf bei Wien, AT",
		label: "Gerasdorf bei Wien, AT",
		code: "Gerasdorf",
	},
	{ value: "Bakú, AZ", label: "Bakú, AZ", code: "GYD" },
	{ value: "Ganyá , AZ", label: "Ganyá , AZ", code: "GNJ-1" },
	{
		value: "República autónoma de Najicheván, AZ",
		label: "República autónoma de Najicheván, AZ",
		code: "NKV-1",
	},
	{ value: "Nakhchivan, AZ", label: "Nakhchivan, AZ", code: "NKV-97" },
	{ value: "Sheki, AZ", label: "Sheki, AZ", code: "SHEKI-6" },
	{ value: "Gabala, AZ", label: "Gabala, AZ", code: "GABALA-6" },
	{ value: "Bahamas, BS", label: "Bahamas, BS", code: "PID" },
	{ value: "Eleuthera, BS", label: "Eleuthera, BS", code: "ASD-4" },
	{ value: "Nassau, BS", label: "Nassau, BS", code: "fake-609" },
	{ value: "Freeport, BS", label: "Freeport, BS", code: "fake-613" },
	{ value: "Exuma, BS", label: "Exuma, BS", code: "GRGT-6" },
	{
		value: "West Grand Bahama, BS",
		label: "West Grand Bahama, BS",
		code: "WestGrandBahama",
	},
	{ value: "Manama, BH", label: "Manama, BH", code: "BH1_1" },
	{ value: "Baréin, BH", label: "Baréin, BH", code: "BH1" },
	{ value: "Northern, BH", label: "Northern, BH", code: "BHRNNORTHERN" },
	{ value: "Daca, BD", label: "Daca, BD", code: "DAC" },
	{ value: "Sonargaon , BD", label: "Sonargaon , BD", code: "DAC-1" },
	{ value: "Barbados, BB", label: "Barbados, BB", code: "BGI" },
	{ value: "Bridgetown , BB", label: "Bridgetown , BB", code: "BRT-12" },
	{ value: "Christ Chuch, BB", label: "Christ Chuch, BB", code: "BGI-2" },
	{ value: "Saint James, BB", label: "Saint James, BB", code: "BGI-3" },
	{ value: "Saint Peter, BB", label: "Saint Peter, BB", code: "BGI-4" },
	{ value: "Minsk, BY", label: "Minsk, BY", code: "MSQ" },
	{ value: "Brest, BY", label: "Brest, BY", code: "GME" },
	{ value: "Grodno, BY", label: "Grodno, BY", code: "GNA" },
	{ value: "Vitebsk, BY", label: "Vitebsk, BY", code: "VTB3" },
	{ value: "Barysaw, BY", label: "Barysaw, BY", code: "BAR-113" },
	{ value: "Bruselas, BE", label: "Bruselas, BE", code: "BRU" },
	{ value: "Boom, BE", label: "Boom, BE", code: "BOM-1" },
	{ value: "Brujas, BE", label: "Brujas, BE", code: "BRS" },
	{ value: "Lieja, BE", label: "Lieja, BE", code: "LGG" },
	{ value: "Spa, BE", label: "Spa, BE", code: "SPA-1" },
	{ value: "Amberes, BE", label: "Amberes, BE", code: "ANR" },
	{ value: "Gante, BE", label: "Gante, BE", code: "GNE" },
	{ value: "Charleroi , BE", label: "Charleroi , BE", code: "CHA-79" },
	{ value: "Gante, BE", label: "Gante, BE", code: "GNEGBELGICA6" },
	{ value: "Lovaina, BE", label: "Lovaina, BE", code: "LEU" },
	{ value: "Waterloo, BE", label: "Waterloo, BE", code: "WTL" },
	{ value: "Gante, BE", label: "Gante, BE", code: "GHE-1" },
	{ value: "Ostende, BE", label: "Ostende, BE", code: "OST-1" },
	{ value: "Waregem, BE", label: "Waregem, BE", code: "WRGM-6" },
	{ value: "Huy, BE", label: "Huy, BE", code: "HUY" },
	{
		value: "Marche-en-Famenne, BE",
		label: "Marche-en-Famenne, BE",
		code: "MRX",
	},
	{ value: "Namur, BE", label: "Namur, BE", code: "NMR" },
	{ value: "Wellen, BE", label: "Wellen, BE", code: "WLL" },
	{ value: "Arlon, BE", label: "Arlon, BE", code: "AARL" },
	{ value: "Bouillon, BE", label: "Bouillon, BE", code: "BLLN" },
	{ value: "Blankenberge, BE", label: "Blankenberge, BE", code: "BNKR" },
	{ value: "Bredene, BE", label: "Bredene, BE", code: "BRDN" },
	{ value: "Bertrix, BE", label: "Bertrix, BE", code: "BRTX" },
	{ value: "Chaudfontaine, BE", label: "Chaudfontaine, BE", code: "CHFN" },
	{ value: "Ciney, BE", label: "Ciney, BE", code: "CNEY" },
	{ value: "De Haan, BE", label: "De Haan, BE", code: "DHNN" },
	{ value: "Dinant, BE", label: "Dinant, BE", code: "DNNT" },
	{ value: "Diepenbeek, BE", label: "Diepenbeek, BE", code: "DPNB" },
	{ value: "De Panne, BE", label: "De Panne, BE", code: "DPNE" },
	{ value: "Durbuy, BE", label: "Durbuy, BE", code: "DURB" },
	{ value: "Eksel, BE", label: "Eksel, BE", code: "EKSL" },
	{ value: "Gingelom, BE", label: "Gingelom, BE", code: "GNGL" },
	{ value: "Houffalize, BE", label: "Houffalize, BE", code: "HFFL" },
	{ value: "Halen, BE", label: "Halen, BE", code: "HLNN" },
	{ value: "Hoeselt, BE", label: "Hoeselt, BE", code: "HSLT" },
	{ value: "Koksijde, BE", label: "Koksijde, BE", code: "KLSK" },
	{ value: "Knokke-Heist, BE", label: "Knokke-Heist, BE", code: "KNKK" },
	{
		value: "Libramont-Chevigny, BE",
		label: "Libramont-Chevigny, BE",
		code: "LBRM",
	},
	{ value: "As, BE", label: "As, BE", code: "LGG4" },
	{ value: "Lummen, BE", label: "Lummen, BE", code: "LMMN" },
	{ value: "Leopoldsburg, BE", label: "Leopoldsburg, BE", code: "LPLS" },
	{ value: "Malmedy, BE", label: "Malmedy, BE", code: "MLMD" },
	{ value: "Nieuwpoort, BE", label: "Nieuwpoort, BE", code: "NPRT" },
	{ value: "Oudsbergen, BE", label: "Oudsbergen, BE", code: "OUBS" },
	{ value: "Rochefort, BE", label: "Rochefort, BE", code: "RCHF" },
	{ value: "Riemst, BE", label: "Riemst, BE", code: "RMST" },
	{ value: "Sint-Truiden, BE", label: "Sint-Truiden, BE", code: "SNTT" },
	{ value: "Stavelot, BE", label: "Stavelot, BE", code: "STAV" },
	{ value: "Tongeren, BE", label: "Tongeren, BE", code: "TNGR" },
	{ value: "Voeren, BE", label: "Voeren, BE", code: "VRRN" },
	{ value: "Virton, BE", label: "Virton, BE", code: "VRTN" },
	{ value: "Wenduine, BE", label: "Wenduine, BE", code: "WNDN" },
	{ value: "Zonhoven, BE", label: "Zonhoven, BE", code: "ZNVV" },
	{ value: "Aalst, BE", label: "Aalst, BE", code: "Aalst" },
	{ value: "Bastoña , BE", label: "Bastoña , BE", code: "BAS-6" },
	{ value: "Bree, BE", label: "Bree, BE", code: "BRI-6" },
	{ value: "Érezée, BE", label: "Érezée, BE", code: "ERE-6" },
	{ value: "Francorchamps, BE", label: "Francorchamps, BE", code: "FRC-1" },
	{ value: "Ham, BE", label: "Ham, BE", code: "HAM-6" },
	{
		value: "Houthalen-Helchteren, BE",
		label: "Houthalen-Helchteren, BE",
		code: "HOU-6",
	},
	{
		value: "La Roche-en-Ardenne, BE",
		label: "La Roche-en-Ardenne, BE",
		code: "LRCHR",
	},
	{ value: "Middelkerke, BE", label: "Middelkerke, BE", code: "MDLKR" },
	{ value: "Modave, BE", label: "Modave, BE", code: "MOD-6" },
	{ value: "Oostduinkerke, BE", label: "Oostduinkerke, BE", code: "OSTDK" },
	{ value: "Ypres, BE", label: "Ypres, BE", code: "YPR-6" },
	{ value: "Zutendaal, BE", label: "Zutendaal, BE", code: "ZTNDL" },
	{ value: "Ardenas, BE", label: "Ardenas, BE", code: "ARDE-1" },
	{ value: "La Costa Belga, BE", label: "La Costa Belga, BE", code: "BECO-1" },
	{ value: "Beringen, BE", label: "Beringen, BE", code: "BERI-6" },
	{ value: "Bilzen, BE", label: "Bilzen, BE", code: "BILZ-6" },
	{ value: "Bocholt, BE", label: "Bocholt, BE", code: "BOCH-6" },
	{ value: "Borgloon, BE", label: "Borgloon, BE", code: "BORG-6" },
	{ value: "Dilsen-Stokkem, BE", label: "Dilsen-Stokkem, BE", code: "DILS-6" },
	{ value: "Genk, BE", label: "Genk, BE", code: "GENK-6" },
	{ value: "Hamont-Achel, BE", label: "Hamont-Achel, BE", code: "HAMO-6" },
	{ value: "Herk-de-Stad, BE", label: "Herk-de-Stad, BE", code: "HERK-6" },
	{ value: "Heusden-Zolder, BE", label: "Heusden-Zolder, BE", code: "HEUS-6" },
	{ value: "Kinrooi, BE", label: "Kinrooi, BE", code: "KONR-6" },
	{ value: "Kortessem, BE", label: "Kortessem, BE", code: "KORT-6" },
	{ value: "Lanaken, BE", label: "Lanaken, BE", code: "LANA-6" },
	{ value: "Lommel, BE", label: "Lommel, BE", code: "LOMM-6" },
	{ value: "Maaseik, BE", label: "Maaseik, BE", code: "MAAS-6" },
	{ value: "Malinas, BE", label: "Malinas, BE", code: "MECH-1" },
	{ value: "Mons, BE", label: "Mons, BE", code: "MONS-6" },
	{ value: "Neufchâteau, BE", label: "Neufchâteau, BE", code: "NEUF-6" },
	{ value: "Nieuwerkerken, BE", label: "Nieuwerkerken, BE", code: "NIEU-6" },
	{ value: "Nivelles, BE", label: "Nivelles, BE", code: "NIVE-1" },
	{ value: "Peer , BE", label: "Peer , BE", code: "PEER-6" },
	{ value: "Pelt, BE", label: "Pelt, BE", code: "PELT-6" },
	{ value: "Seneffe, BE", label: "Seneffe, BE", code: "SENE-6" },
	{ value: "Tessenderlo, BE", label: "Tessenderlo, BE", code: "TESS-6" },
	{ value: "Westende, BE", label: "Westende, BE", code: "WSTNDE" },
	{ value: "Zeebrugge, BE", label: "Zeebrugge, BE", code: "ZEEBRG" },
	{ value: "Heers, BE", label: "Heers, BE", code: "HEERS-6" },
	{ value: "Hasselt, BE", label: "Hasselt, BE", code: "HST-002" },
	{ value: "Maasmechelen, BE", label: "Maasmechelen, BE", code: "MAASM-6" },
	{ value: "Zaventem, BE", label: "Zaventem, BE", code: "ZVTEM-6" },
	{ value: "Malinas, BE", label: "Malinas, BE", code: "fake-825" },
	{
		value: "Saint-Josse-ten-Noode, BE",
		label: "Saint-Josse-ten-Noode, BE",
		code: "SaintJosse",
	},
	{ value: "Belice, BZ", label: "Belice, BZ", code: "BZE" },
	{ value: "Placencia, BZ", label: "Placencia, BZ", code: "PLJ" },
	{ value: "Ambergris Caye, BZ", label: "Ambergris Caye, BZ", code: "SPR" },
	{ value: "San Ignacio, BZ", label: "San Ignacio, BZ", code: "SQS" },
	{ value: "Belmopan, BZ", label: "Belmopan, BZ", code: "BCV-1" },
	{ value: "Caye Caulker, BZ", label: "Caye Caulker, BZ", code: "623" },
	{ value: "Cotonú, BJ", label: "Cotonú, BJ", code: "COO" },
	{ value: "Bermuda, BM", label: "Bermuda, BM", code: "BDA-3" },
	{ value: "Paget, BM", label: "Paget, BM", code: "Paget" },
	{
		value: "Monasterio Gangtey, BT",
		label: "Monasterio Gangtey, BT",
		code: "GNT-2",
	},
	{ value: "Punakha, BT", label: "Punakha, BT", code: "PNK-2" },
	{ value: "Paro, BT", label: "Paro, BT", code: "PRO-2" },
	{ value: "Thimphu, BT", label: "Thimphu, BT", code: "TMP-2" },
	{ value: "Phuntsholing, BT", label: "Phuntsholing, BT", code: "PHU-6" },
	{ value: "Timbu, BT", label: "Timbu, BT", code: "Timbu" },
	{
		value: "Wangdue Phodrang, BT",
		label: "Wangdue Phodrang, BT",
		code: "WangduePhodrang",
	},
	{ value: "La Paz, BO", label: "La Paz, BO", code: "LPB" },
	{ value: "Cochabamba, BO", label: "Cochabamba, BO", code: "CBB" },
	{ value: "Tarija, BO", label: "Tarija, BO", code: "TJA" },
	{ value: "Sucre, BO", label: "Sucre, BO", code: "US7" },
	{
		value: "Santa Cruz de la Sierra, BO",
		label: "Santa Cruz de la Sierra, BO",
		code: "VVI",
	},
	{ value: "Potosi, BO", label: "Potosi, BO", code: "CAS1" },
	{ value: "Uyuni, BO", label: "Uyuni, BO", code: "UYU123" },
	{ value: "Oruro, BO", label: "Oruro, BO", code: "ORUR" },
	{ value: "Copacabana, BO", label: "Copacabana, BO", code: "CPA-002" },
	{ value: "Isla del Sol, BO", label: "Isla del Sol, BO", code: "ISl-002" },
	{
		value: "Parque nacional Madidi, BO",
		label: "Parque nacional Madidi, BO",
		code: "MNP-002",
	},
	{ value: "Rurrenabaque, BO", label: "Rurrenabaque, BO", code: "RRB-002" },
	{ value: "Colchani, BO", label: "Colchani, BO", code: "fake-810" },
	{ value: "Sarajevo, BA", label: "Sarajevo, BA", code: "SJJ" },
	{ value: "Mostar, BA", label: "Mostar, BA", code: "OMO" },
	{ value: "Medjugorje, BA", label: "Medjugorje, BA", code: "BNX" },
	{ value: "Neum, BA", label: "Neum, BA", code: "NEUM-6" },
	{ value: "Blagaj, BA", label: "Blagaj, BA", code: "123_BLA" },
	{ value: "Jajce, BA", label: "Jajce, BA", code: "Jajce" },
	{ value: "Konjic, BA", label: "Konjic, BA", code: "Konjic" },
	{ value: "Fojnica, BA", label: "Fojnica, BA", code: "Fojnica" },
	{ value: "Travnik, BA", label: "Travnik, BA", code: "Travnik" },
	{ value: "Počitelj, BA", label: "Počitelj, BA", code: "Pocitelj" },
	{ value: "Bijambare, BA", label: "Bijambare, BA", code: "Bijambare" },
	{ value: "Jablanica, BA", label: "Jablanica, BA", code: "Jablanica" },
	{ value: "Srebrenica , BA", label: "Srebrenica , BA", code: "Srebrenica" },
	{
		value: "Bosnia y Herzegovinanota, BA",
		label: "Bosnia y Herzegovinanota, BA",
		code: "BosnaIHercegovina",
	},
	{ value: "Gaborone, BW", label: "Gaborone, BW", code: "GBE" },
	{ value: "Francistown, BW", label: "Francistown, BW", code: "FRW" },
	{ value: "Kasane, BW", label: "Kasane, BW", code: "BBK-1" },
	{
		value: "Chobe National Park, BW",
		label: "Chobe National Park, BW",
		code: "BW1-1",
	},
	{
		value: "Parque Nacional Chobe, BW",
		label: "Parque Nacional Chobe, BW",
		code: "CHOB-6",
	},
	{
		value: "Parque nacional de Nxai Pan, BW",
		label: "Parque nacional de Nxai Pan, BW",
		code: "NXIAP-6",
	},
	{
		value: "Makgadikgadi Pans (Parque Nacional), BW",
		label: "Makgadikgadi Pans (Parque Nacional), BW",
		code: "MKGDKG-6",
	},
	{ value: "Maun, BW", label: "Maun, BW", code: "fake-164" },
	{ value: "Nata, BW", label: "Nata, BW", code: "Nata" },
	{ value: "Palapye, BW", label: "Palapye, BW", code: "PLPY" },
	{ value: "Tuli, BW", label: "Tuli, BW", code: "Tuli" },
	{ value: "Gweta, BW", label: "Gweta, BW", code: "Gweta" },
	{ value: "Khwai , BW", label: "Khwai , BW", code: "Khwai" },
	{ value: "Tshane, BW", label: "Tshane, BW", code: "TSHAN" },
	{ value: "Etsha , BW", label: "Etsha , BW", code: "etsha" },
	{ value: "Ghanzi , BW", label: "Ghanzi , BW", code: "123456" },
	{ value: "Gumare , BW", label: "Gumare , BW", code: "Gumare" },
	{ value: "Moremi, BW", label: "Moremi, BW", code: "Moremi" },
	{ value: "Rakops, BW", label: "Rakops, BW", code: "Rakops" },
	{ value: "Savuti, BW", label: "Savuti, BW", code: "Savuti" },
	{ value: "Serowe, BW", label: "Serowe, BW", code: "serowe" },
	{ value: "Mohembo, BW", label: "Mohembo, BW", code: "Mohembo" },
	{
		value: "Delta del Okavango, BW",
		label: "Delta del Okavango, BW",
		code: "OKD-002",
	},
	{ value: "Shakawe, BW", label: "Shakawe, BW", code: "Shakawe" },
	{ value: "Linyati, BW", label: "Linyati, BW", code: "Linyanti" },
	{ value: "Nokaneng, BW", label: "Nokaneng, BW", code: "Nokaneng" },
	{ value: "Sehithwa, BW", label: "Sehithwa, BW", code: "Sehithwa" },
	{ value: "Río de Janeiro, BR", label: "Río de Janeiro, BR", code: "RIO" },
	{ value: "São Paulo, BR", label: "São Paulo, BR", code: "SAO" },
	{ value: "Salvador, BR", label: "Salvador, BR", code: "SVD-1" },
	{ value: "Brasilia, BR", label: "Brasilia, BR", code: "BSB" },
	{ value: "Aracaju, BR", label: "Aracaju, BR", code: "ARC" },
	{ value: "Araguaína, BR", label: "Araguaína, BR", code: "AUX" },
	{ value: "Belém, BR", label: "Belém, BR", code: "BEL" },
	{ value: "Belo Horizonte, BR", label: "Belo Horizonte, BR", code: "BHZ" },
	{
		value: "Amaçao dos Buzios, BR",
		label: "Amaçao dos Buzios, BR",
		code: "BZI",
	},
	{ value: "Cabo Frio, BR", label: "Cabo Frio, BR", code: "CFB" },
	{ value: "Foz do Iguazú, BR", label: "Foz do Iguazú, BR", code: "FDI" },
	{
		value: "Fernando de Noronha, BR",
		label: "Fernando de Noronha, BR",
		code: "FEN",
	},
	{ value: "Florianópolis, BR", label: "Florianópolis, BR", code: "FLO" },
	{ value: "Fortaleza, BR", label: "Fortaleza, BR", code: "FTZ" },
	{ value: "Goiânia, BR", label: "Goiânia, BR", code: "GOI" },
	{ value: "Ilhéus, BR", label: "Ilhéus, BR", code: "IL1" },
	{ value: "Joao Pessoa, BR", label: "Joao Pessoa, BR", code: "JPA" },
	{ value: "São Luís , BR", label: "São Luís , BR", code: "LUI" },
	{ value: "Maceió, BR", label: "Maceió, BR", code: "MAO" },
	{ value: "Macapá, BR", label: "Macapá, BR", code: "MCP" },
	{ value: "Mossoro, BR", label: "Mossoro, BR", code: "MVF" },
	{ value: "Pipa, BR", label: "Pipa, BR", code: "PDA" },
	{ value: "Parnaiba, BR", label: "Parnaiba, BR", code: "PNB" },
	{ value: "Petrolina, BR", label: "Petrolina, BR", code: "PNZ" },
	{ value: "Recife, BR", label: "Recife, BR", code: "RCF" },
	{ value: "Río Grando, BR", label: "Río Grando, BR", code: "RIG" },
	{
		value: "São José do Rio Preto, BR",
		label: "São José do Rio Preto, BR",
		code: "SJP",
	},
	{ value: "Teresina, BR", label: "Teresina, BR", code: "THE" },
	{ value: "Vitória, BR", label: "Vitória, BR", code: "VIO" },
	{ value: "Curitiba, BR", label: "Curitiba, BR", code: "CUR-1" },
	{ value: "Uberaba, BR", label: "Uberaba, BR", code: "UB1-1" },
	{ value: "Cascavel, BR", label: "Cascavel, BR", code: "CC3" },
	{ value: "Campo Grande, BR", label: "Campo Grande, BR", code: "CPO" },
	{ value: "Campinas, BR", label: "Campinas, BR", code: "CPQ" },
	{ value: "Cuiabá, BR", label: "Cuiabá, BR", code: "CUI" },
	{ value: "Divinópolis, BR", label: "Divinópolis, BR", code: "DIQ" },
	{ value: "Franca, BR", label: "Franca, BR", code: "FRC" },
	{ value: "Gramado, BR", label: "Gramado, BR", code: "GRM" },
	{ value: "Imperatriz, BR", label: "Imperatriz, BR", code: "IMP" },
	{ value: "Jericoacoara, BR", label: "Jericoacoara, BR", code: "JRR" },
	{ value: "Londrina, BR", label: "Londrina, BR", code: "LOD" },
	{ value: "Macaé, BR", label: "Macaé, BR", code: "ME4" },
	{ value: "Manaus, BR", label: "Manaus, BR", code: "MNA" },
	{ value: "Montes Claros, BR", label: "Montes Claros, BR", code: "MOC" },
	{ value: "Maringá, BR", label: "Maringá, BR", code: "MRG" },
	{ value: "Natal, BR", label: "Natal, BR", code: "NAT" },
	{ value: "Maragogi, BR", label: "Maragogi, BR", code: "OGI" },
	{
		value: "Porto de galinhas, BR",
		label: "Porto de galinhas, BR",
		code: "PDG",
	},
	{ value: "Pelotas, BR", label: "Pelotas, BR", code: "PES" },
	{ value: "Porto Alegre, BR", label: "Porto Alegre, BR", code: "POO" },
	{
		value: "Presidente Prudente, BR",
		label: "Presidente Prudente, BR",
		code: "PPD",
	},
	{ value: "Marabá, BR", label: "Marabá, BR", code: "RAB" },
	{ value: "Ribeirão preto , BR", label: "Ribeirão preto , BR", code: "RAO" },
	{ value: "Santarém, BR", label: "Santarém, BR", code: "REM" },
	{ value: "Santa María, BR", label: "Santa María, BR", code: "RIA" },
	{ value: "Rondonópolis, BR", label: "Rondonópolis, BR", code: "ROO" },
	{ value: "Guaruja, BR", label: "Guaruja, BR", code: "RUJ" },
	{ value: "Uberlândia, BR", label: "Uberlândia, BR", code: "UBE" },
	{ value: "Ouro Preto, BR", label: "Ouro Preto, BR", code: "BHZ-3" },
	{ value: "Camboriú , BR", label: "Camboriú , BR", code: "BLN-1" },
	{ value: "Cumbuco, BR", label: "Cumbuco, BR", code: "FTZ-3" },
	{ value: "Tibau do sul, BR", label: "Tibau do sul, BR", code: "PDA-2" },
	{ value: "Olinda, BR", label: "Olinda, BR", code: "RCF-3" },
	{ value: "Caraguatatuba , BR", label: "Caraguatatuba , BR", code: "SJK-3" },
	{ value: "Praia do Forte, BR", label: "Praia do Forte, BR", code: "SVD-3" },
	{ value: "Bonito, BR", label: "Bonito, BR", code: "BTO-22" },
	{ value: "Cuiabá, BR", label: "Cuiabá, BR", code: "CUI-62" },
	{ value: "Araxa, BR", label: "Araxa, BR", code: "AAX" },
	{ value: "Angra Dos Reis, BR", label: "Angra Dos Reis, BR", code: "ADR" },
	{ value: "Anapolis, BR", label: "Anapolis, BR", code: "ANL" },
	{ value: "Araraquara, BR", label: "Araraquara, BR", code: "AQA" },
	{ value: "Aracatuba, BR", label: "Aracatuba, BR", code: "AT6" },
	{ value: "Avare, BR", label: "Avare, BR", code: "AVR" },
	{ value: "Bauru, BR", label: "Bauru, BR", code: "BAU" },
	{ value: "Barbacena, BR", label: "Barbacena, BR", code: "BBO" },
	{
		value: "Balneario de Camboriu, BR",
		label: "Balneario de Camboriu, BR",
		code: "BLN",
	},
	{ value: "Blumenau, BR", label: "Blumenau, BR", code: "BLU" },
	{ value: "Caxias Do Sul, BR", label: "Caxias Do Sul, BR", code: "CIA" },
	{ value: "Caldas Novas, BR", label: "Caldas Novas, BR", code: "CLN" },
	{ value: "Canoa Quebrada, BR", label: "Canoa Quebrada, BR", code: "CQU" },
	{
		value: "Santa Cruz Do Sul, BR",
		label: "Santa Cruz Do Sul, BR",
		code: "CSU",
	},
	{ value: "Guarulhos, BR", label: "Guarulhos, BR", code: "GRU" },
	{ value: "Atibaia, BR", label: "Atibaia, BR", code: "IAI" },
	{ value: "Joinville, BR", label: "Joinville, BR", code: "JOI" },
	{
		value: "Juazeiro do Norte, BR",
		label: "Juazeiro do Norte, BR",
		code: "JZR",
	},
	{ value: "Limeira, BR", label: "Limeira, BR", code: "LRA" },
	{ value: "Marilia, BR", label: "Marilia, BR", code: "MLI" },
	{ value: "Osasco, BR", label: "Osasco, BR", code: "OSO" },
	{ value: "Palmas, BR", label: "Palmas, BR", code: "PMW" },
	{ value: "Porto Seguro, BR", label: "Porto Seguro, BR", code: "PSE" },
	{ value: "Rio Branco, BR", label: "Rio Branco, BR", code: "ROB" },
	{ value: "Rio Verde, BR", label: "Rio Verde, BR", code: "RVD" },
	{ value: "Assis, BR", label: "Assis, BR", code: "SSO" },
	{ value: "Barueri, BR", label: "Barueri, BR", code: "UEI" },
	{ value: "Arraial do Cabo, BR", label: "Arraial do Cabo, BR", code: "ADC-6" },
	{ value: "Americana, BR", label: "Americana, BR", code: "AM3-1" },
	{ value: "Juiz de Fora, BR", label: "Juiz de Fora, BR", code: "BB0-3" },
	{ value: "Bombinhas, BR", label: "Bombinhas, BR", code: "BBH-1" },
	{ value: "Jaguariuna, BR", label: "Jaguariuna, BR", code: "CPQ-2" },
	{ value: "Paranagua, BR", label: "Paranagua, BR", code: "CUR-6" },
	{
		value: "Morro de São Paulo, BR",
		label: "Morro de São Paulo, BR",
		code: "MDS-1",
	},
	{ value: "Bento Gonçalves, BR", label: "Bento Gonçalves, BR", code: "POO-6" },
	{ value: "Paulista, BR", label: "Paulista, BR", code: "RCF-5" },
	{ value: "Linhares, BR", label: "Linhares, BR", code: "SBJ-2" },
	{
		value: "Sao José dos Campos, BR",
		label: "Sao José dos Campos, BR",
		code: "SJK-1",
	},
	{ value: "Sorocaba, BR", label: "Sorocaba, BR", code: "SO5-1" },
	{
		value: "Sao Vicente Santos, BR",
		label: "Sao Vicente Santos, BR",
		code: "SSZ-2",
	},
	{ value: "Resende, BR", label: "Resende, BR", code: "VR0-2" },
	{ value: "Caiobá, BR", label: "Caiobá, BR", code: "CAI-98" },
	{ value: "Goiania, BR", label: "Goiania, BR", code: "GOI-34" },
	{ value: "Guaratuba, BR", label: "Guaratuba, BR", code: "GUA-56" },
	{ value: "Ingleses, BR", label: "Ingleses, BR", code: "IGN-45" },
	{ value: "Ilhéus, BR", label: "Ilhéus, BR", code: "ILH-43" },
	{ value: "Passo Fundo, BR", label: "Passo Fundo, BR", code: "PSF-87" },
	{ value: "Jacarepagua, BR", label: "Jacarepagua, BR", code: "RIO-21" },
	{ value: "Piracicaba, BR", label: "Piracicaba, BR", code: "SAO-25" },
	{ value: "Sao Carlos, BR", label: "Sao Carlos, BR", code: "SAO-36" },
	{ value: "Itu, BR", label: "Itu, BR", code: "SAO-41" },
	{ value: "Sinop, BR", label: "Sinop, BR", code: "SNP-39" },
	{ value: "Jundiai, BR", label: "Jundiai, BR", code: "JUNDIAI" },
	{ value: "Chapecó, BR", label: "Chapecó, BR", code: "CHAPEC-6" },
	{
		value: "Arraial d&apos;Ajuda, BR",
		label: "Arraial d&apos;Ajuda, BR",
		code: "BPS4",
	},
	{
		value: "Miranda, Mato Grosso do Sul, BR",
		label: "Miranda, Mato Grosso do Sul, BR",
		code: "BYO2",
	},
	{
		value: "Anastácio (Mato Grosso do Sul), BR",
		label: "Anastácio (Mato Grosso do Sul), BR",
		code: "BYO3",
	},
	{ value: "Aquidauana, BR", label: "Aquidauana, BR", code: "BYO4" },
	{
		value: "Barão de Melgaço, BR",
		label: "Barão de Melgaço, BR",
		code: "CGB2",
	},
	{
		value: "Cáceres, Mato Grosso, BR",
		label: "Cáceres, Mato Grosso, BR",
		code: "CGB3",
	},
	{ value: "Ladário, BR", label: "Ladário, BR", code: "CMG1" },
	{ value: "Isla Grande, BR", label: "Isla Grande, BR", code: "ILHA" },
	{ value: "Imbassai, BR", label: "Imbassai, BR", code: "IMBS" },
	{ value: "Ipioca, BR", label: "Ipioca, BR", code: "IPCI" },
	{ value: "Novo Airão, BR", label: "Novo Airão, BR", code: "MAO2" },
	{ value: "Japaratinga, BR", label: "Japaratinga, BR", code: "MCZ1" },
	{ value: "Barra da Tijuca, BR", label: "Barra da Tijuca, BR", code: "RIO2" },
	{
		value: "Cabo de Santo Agostinho, BR",
		label: "Cabo de Santo Agostinho, BR",
		code: "SNTG",
	},
	{ value: "Touros, BR", label: "Touros, BR", code: "TOUR" },
	{
		value: "Santa Cruz Cabralia, BR",
		label: "Santa Cruz Cabralia, BR",
		code: "bps1",
	},
	{ value: "Santo Andre, BR", label: "Santo Andre, BR", code: "sao3" },
	{ value: "Varginha, BR", label: "Varginha, BR", code: "BHZ-2" },
	{ value: "Jurerê, BR", label: "Jurerê, BR", code: "BRJUR" },
	{ value: "Celso Ramos, BR", label: "Celso Ramos, BR", code: "CELSR" },
	{ value: "Confins , BR", label: "Confins , BR", code: "CON-6" },
	{ value: "Guarajuba , BR", label: "Guarajuba , BR", code: "GAR-6" },
	{ value: "Ilhabela, BR", label: "Ilhabela, BR", code: "ILH-6" },
	{ value: "Imbituba, BR", label: "Imbituba, BR", code: "IMB-1" },
	{ value: "Imbassaí, BR", label: "Imbassaí, BR", code: "IMB-6" },
	{ value: "Pratagy, BR", label: "Pratagy, BR", code: "PRA-6" },
	{ value: "Serrambi, BR", label: "Serrambi, BR", code: "SRRMB" },
	{ value: "Ubatuba, BR", label: "Ubatuba, BR", code: "UBA-6" },
	{ value: "Aquiraz, BR", label: "Aquiraz, BR", code: "AQUI-6" },
	{ value: "Atins, BR", label: "Atins, BR", code: "ATIN-1" },
	{ value: "Baía, Miranda, BR", label: "Baía, Miranda, BR", code: "BAMI-1" },
	{ value: "Barreirinhas, BR", label: "Barreirinhas, BR", code: "BARR-1" },
	{ value: "Catuçaba, BR", label: "Catuçaba, BR", code: "CATU-1" },
	{ value: "Lajeado, BR", label: "Lajeado, BR", code: "LAJE-1" },
	{ value: "Lençóis, BR", label: "Lençóis, BR", code: "LENC-1" },
	{ value: "Mateiros, BR", label: "Mateiros, BR", code: "MATE-1" },
	{ value: "Mucugê, BR", label: "Mucugê, BR", code: "MUCU-1" },
	{ value: "Paraty, BR", label: "Paraty, BR", code: "PARA-6" },
	{
		value: "Passo do Lontra, BR",
		label: "Passo do Lontra, BR",
		code: "PDLO-1",
	},
	{ value: "Ponte Alta, BR", label: "Ponte Alta, BR", code: "POAL-1" },
	{ value: "Poconé, BR", label: "Poconé, BR", code: "POCO-1" },
	{
		value: "São Félix do Tocantins, BR",
		label: "São Félix do Tocantins, BR",
		code: "SAFE-1",
	},
	{
		value: "Tarumã (Manaus), BR",
		label: "Tarumã (Manaus), BR",
		code: "TMBR-1",
	},
	{ value: "Vespasiano, BR", label: "Vespasiano, BR", code: "VESP-1" },
	{ value: "Corumbá, BR", label: "Corumbá, BR", code: "CBA-002" },
	{
		value: "Costa do Sauípe, BR",
		label: "Costa do Sauípe, BR",
		code: "CDS-002",
	},
	{ value: "Ceará, BR", label: "Ceará, BR", code: "CRA-002" },
	{
		value: "Praia do Frances, BR",
		label: "Praia do Frances, BR",
		code: "PDF-002",
	},
	{ value: "Gamboa, BR", label: "Gamboa, BR", code: "GAMBOA-6" },
	{ value: "Mangaratiba, BR", label: "Mangaratiba, BR", code: "Mangaratiba" },
	{ value: "Brunei, BN", label: "Brunei, BN", code: "BWN" },
	{
		value: "Bandar Seri Begawan, BN",
		label: "Bandar Seri Begawan, BN",
		code: "BWN-1",
	},
	{ value: "Sofia, BG", label: "Sofia, BG", code: "SOF" },
	{ value: "Sunny Beach, BG", label: "Sunny Beach, BG", code: "BOJ" },
	{ value: "Varna, BG", label: "Varna, BG", code: "VAR" },
	{ value: "Troyan, BG", label: "Troyan, BG", code: "BG3-2" },
	{ value: "Pomorie, BG", label: "Pomorie, BG", code: "BOJ-7" },
	{ value: "Sozopol, BG", label: "Sozopol, BG", code: "BOJ-8" },
	{ value: "Lovech, BG", label: "Lovech, BG", code: "RZN-1" },
	{ value: "Stara Zagora, BG", label: "Stara Zagora, BG", code: "SZR-1" },
	{ value: "Veliko Tarnovo, BG", label: "Veliko Tarnovo, BG", code: "VEL-1" },
	{ value: "Pamporovo, BG", label: "Pamporovo, BG", code: "BK1" },
	{ value: "Borovets, BG", label: "Borovets, BG", code: "BK2" },
	{ value: "Devin, BG", label: "Devin, BG", code: "DEV" },
	{ value: "Bansko, BG", label: "Bansko, BG", code: "PBB" },
	{ value: "Plovdiv, BG", label: "Plovdiv, BG", code: "PDV" },
	{ value: "Veliko Tarnovo, BG", label: "Veliko Tarnovo, BG", code: "VEL" },
	{ value: "Velingrad, BG", label: "Velingrad, BG", code: "VLG" },
	{ value: "Nessebar, BG", label: "Nessebar, BG", code: "BOJ-3" },
	{ value: "Sveti Vlas, BG", label: "Sveti Vlas, BG", code: "BOJ-5" },
	{ value: "Primorsko, BG", label: "Primorsko, BG", code: "BOJ-9" },
	{ value: "Balchik, BG", label: "Balchik, BG", code: "VAR-3" },
	{
		value: "Santos Constantino y Helena, BG",
		label: "Santos Constantino y Helena, BG",
		code: "VAR-4",
	},
	{ value: "Albena, BG", label: "Albena, BG", code: "VAR-6" },
	{ value: "Ravda, BG", label: "Ravda, BG", code: "BOJ-11" },
	{ value: "Lozenets, BG", label: "Lozenets, BG", code: "BOJ-14" },
	{ value: "Bourgas, BG", label: "Bourgas, BG", code: "BOJ-1" },
	{ value: "Golden Sands, BG", label: "Golden Sands, BG", code: "CAS-4" },
	{ value: "Belogradchik, BG", label: "Belogradchik, BG", code: "123_BEL" },
	{
		value: "Riviera Búlgara, BG",
		label: "Riviera Búlgara, BG",
		code: "fake-253",
	},
	{ value: "Obzor , BG", label: "Obzor , BG", code: "fake-339" },
	{ value: "Kavarna, BG", label: "Kavarna, BG", code: "fake-340" },
	{ value: "Nesebar, BG", label: "Nesebar, BG", code: "NES-32" },
	{ value: "Rila, BG", label: "Rila, BG", code: "RILA-6" },
	{ value: "Ruse, BG", label: "Ruse, BG", code: "RSE-002" },
	{ value: "Kazanlak, BG", label: "Kazanlak, BG", code: "fake-822" },
	{ value: "Uagadugú, BF", label: "Uagadugú, BF", code: "OUG" },
	{ value: "Lago Inle, BU", label: "Lago Inle, BU", code: "ILK-1" },
	{ value: "Nyaung-U , BU", label: "Nyaung-U , BU", code: "NyaungU" },
	{ value: "Bujumbura, BI", label: "Bujumbura, BI", code: "JMB" },
	{ value: "Siem Reap, KH", label: "Siem Reap, KH", code: "REP-1" },
	{ value: "Sihanoukville , KH", label: "Sihanoukville , KH", code: "KOS" },
	{ value: "Angkor, KH", label: "Angkor, KH", code: "REP" },
	{ value: "Phom Penh, KH", label: "Phom Penh, KH", code: "PHN" },
	{ value: "Battambang, KH", label: "Battambang, KH", code: "BBM-1" },
	{ value: "Kep, KH", label: "Kep, KH", code: "KH1" },
	{ value: "Banlung, KH", label: "Banlung, KH", code: "BANL-6" },
	{ value: "Kompung Cham, KH", label: "Kompung Cham, KH", code: "KOMPU-6" },
	{ value: "Kampot, KH", label: "Kampot, KH", code: "fake-271" },
	{ value: "Koh Rong, KH", label: "Koh Rong, KH", code: "fake-284" },
	{ value: "Bavet, KH", label: "Bavet, KH", code: "BAV-6" },
	{
		value: "Kampong Thom (Provincia), KH",
		label: "Kampong Thom (Provincia), KH",
		code: "KAM-6",
	},
	{ value: "Pailin, KH", label: "Pailin, KH", code: "PAI-6" },
	{ value: "Poipet, KH", label: "Poipet, KH", code: "POI-6" },
	{ value: "Banteay Chhmar, KH", label: "Banteay Chhmar, KH", code: "BANT-6" },
	{
		value: "Mondol Kiri (Provincia), KH",
		label: "Mondol Kiri (Provincia), KH",
		code: "MONDO-6",
	},
	{ value: "Sen Monorom, KH", label: "Sen Monorom, KH", code: "SENMO-6" },
	{ value: "Stung Treng, KH", label: "Stung Treng, KH", code: "STUTRE-6" },
	{ value: "Yaoundé, CM", label: "Yaoundé, CM", code: "YAO" },
	{ value: "Mfoundi, CM", label: "Mfoundi, CM", code: "YAOI" },
	{ value: "Douala, CM", label: "Douala, CM", code: "Douala" },
	{ value: "Toronto, CA", label: "Toronto, CA", code: "ON" },
	{ value: "Ottawa, CA", label: "Ottawa, CA", code: "OT" },
	{ value: "Montreal, CA", label: "Montreal, CA", code: "QC" },
	{ value: "Quebec, CA", label: "Quebec, CA", code: "QU" },
	{ value: "Vancouver, CA", label: "Vancouver, CA", code: "VA" },
	{ value: "Thunder Bay, CA", label: "Thunder Bay, CA", code: "YQT" },
	{ value: "Edmonton, CA", label: "Edmonton, CA", code: "AB" },
	{ value: "Victoria, CA", label: "Victoria, CA", code: "BC" },
	{ value: "Calgary, CA", label: "Calgary, CA", code: "CG" },
	{
		value: "Cataratas del Niágara, CA",
		label: "Cataratas del Niágara, CA",
		code: "NI",
	},
	{ value: "Campbell River, CA", label: "Campbell River, CA", code: "BC-4" },
	{ value: "Banff, CA", label: "Banff, CA", code: "BF-1" },
	{ value: "Sun Peaks, CA", label: "Sun Peaks, CA", code: "CA-1" },
	{ value: "Tadoussac, CA", label: "Tadoussac, CA", code: "QU-3" },
	{ value: "Penticton, CA", label: "Penticton, CA", code: "YYF-1" },
	{ value: "Winnipeg, CA", label: "Winnipeg, CA", code: "MB" },
	{ value: "Mont-Tremblant , CA", label: "Mont-Tremblant , CA", code: "MO" },
	{
		value: "Halifax (Nueva Escocia), CA",
		label: "Halifax (Nueva Escocia), CA",
		code: "NOV",
	},
	{ value: "Markham, CA", label: "Markham, CA", code: "ON8" },
	{ value: "Sunshine Village, CA", label: "Sunshine Village, CA", code: "SUN" },
	{ value: "Burnaby, CA", label: "Burnaby, CA", code: "VA3" },
	{ value: "Kingston, CA", label: "Kingston, CA", code: "YGK" },
	{ value: "Fort McMurray, CA", label: "Fort McMurray, CA", code: "YMM" },
	{ value: "Red Deer, CA", label: "Red Deer, CA", code: "YQF" },
	{ value: "Regina, CA", label: "Regina, CA", code: "YQR" },
	{
		value: "Sydney (Nueva Escocia), CA",
		label: "Sydney (Nueva Escocia), CA",
		code: "YQY",
	},
	{ value: "Trois-Rivières, CA", label: "Trois-Rivières, CA", code: "YRQ" },
	{ value: "Alma , CA", label: "Alma , CA", code: "YTF" },
	{ value: "Vernon, CA", label: "Vernon, CA", code: "YVE" },
	{ value: "Saskatoon, CA", label: "Saskatoon, CA", code: "YXE" },
	{ value: "Courtenay, CA", label: "Courtenay, CA", code: "BC-8" },
	{ value: "Lake Louise, CA", label: "Lake Louise, CA", code: "BF-2" },
	{ value: "Jasper, CA", label: "Jasper, CA", code: "BF-4" },
	{ value: "Kananaskis, CA", label: "Kananaskis, CA", code: "BF-5" },
	{ value: "Barrie, CA", label: "Barrie, CA", code: "BI-1" },
	{ value: "Fernie, CA", label: "Fernie, CA", code: "FR-4" },
	{ value: "Mont Tremblant, CA", label: "Mont Tremblant, CA", code: "MO-1" },
	{ value: "Gatineau , CA", label: "Gatineau , CA", code: "OT-3" },
	{ value: "Saint-Sauveur, CA", label: "Saint-Sauveur, CA", code: "QC-9" },
	{ value: "Saint John, CA", label: "Saint John, CA", code: "SJ-3" },
	{ value: "Kamloops, CA", label: "Kamloops, CA", code: "SU-1" },
	{ value: "Bromont, CA", label: "Bromont, CA", code: "BRO-1" },
	{ value: "Churchill , CA", label: "Churchill , CA", code: "CAN-1" },
	{ value: "Comox, CA", label: "Comox, CA", code: "CMX-1" },
	{ value: "Edmundston, CA", label: "Edmundston, CA", code: "EDM-3" },
	{ value: "Golden, CA", label: "Golden, CA", code: "KHR-2" },
	{ value: "Valemount, CA", label: "Valemount, CA", code: "VL3-1" },
	{ value: "Whistler, CA", label: "Whistler, CA", code: "WHS-1" },
	{ value: "Brandon, CA", label: "Brandon, CA", code: "YBR-3" },
	{ value: "Dawson City, CA", label: "Dawson City, CA", code: "YDA-1" },
	{ value: "Edson, CA", label: "Edson, CA", code: "YET-1" },
	{ value: "Fredericton, CA", label: "Fredericton, CA", code: "YFC-3" },
	{ value: "Dryden, CA", label: "Dryden, CA", code: "YHD-3" },
	{ value: "Charlottetown, CA", label: "Charlottetown, CA", code: "YHG-1" },
	{ value: "Matane, CA", label: "Matane, CA", code: "YME-1" },
	{ value: "Moncton, CA", label: "Moncton, CA", code: "YQM-1" },
	{ value: "Yorkton, CA", label: "Yorkton, CA", code: "YQR-3" },
	{ value: "Revelstoke, CA", label: "Revelstoke, CA", code: "YRV-1" },
	{ value: "Pembroke , CA", label: "Pembroke , CA", code: "YTA-3" },
	{ value: "Thompson, CA", label: "Thompson, CA", code: "YTH-3" },
	{ value: "Timmins, CA", label: "Timmins, CA", code: "YTS-3" },
	{ value: "Terrace, CA", label: "Terrace, CA", code: "YXT-3" },
	{ value: "Whitehorse, CA", label: "Whitehorse, CA", code: "YXY-1" },
	{ value: "North Bay, CA", label: "North Bay, CA", code: "YYB-3" },
	{ value: "Kelowna, CA", label: "Kelowna, CA", code: "YYF-5" },
	{ value: "Rimouski, CA", label: "Rimouski, CA", code: "YYY-3" },
	{ value: "Yellowknife, CA", label: "Yellowknife, CA", code: "YZF-1" },
	{ value: "Sarnia, CA", label: "Sarnia, CA", code: "YZR-2" },
	{
		value: "Vancouver Island, CA",
		label: "Vancouver Island, CA",
		code: "fake-562",
	},
	{ value: "Drummondville, CA", label: "Drummondville, CA", code: "DRU" },
	{ value: "Mississauga , CA", label: "Mississauga , CA", code: "ON1" },
	{ value: "Oakville, CA", label: "Oakville, CA", code: "ON5" },
	{ value: "Maple Ridge, CA", label: "Maple Ridge, CA", code: "PL6" },
	{ value: "Laval, CA", label: "Laval, CA", code: "QC2" },
	{ value: "Sherbrooke, CA", label: "Sherbrooke, CA", code: "QC8" },
	{ value: "Surrey, CA", label: "Surrey, CA", code: "VA7" },
	{ value: "Sault Ste Marie, CA", label: "Sault Ste Marie, CA", code: "YAM" },
	{ value: "Cornwall CA, CA", label: "Cornwall CA, CA", code: "YCC" },
	{ value: "Chilliwack, CA", label: "Chilliwack, CA", code: "YCW" },
	{ value: "Lethbridge, CA", label: "Lethbridge, CA", code: "YQL" },
	{ value: "Grande Prairie, CA", label: "Grande Prairie, CA", code: "YQU" },
	{ value: "Greater Sudbury, CA", label: "Greater Sudbury, CA", code: "YSB" },
	{ value: "Prince George, CA", label: "Prince George, CA", code: "YXS" },
	{ value: "Abbotsford, CA", label: "Abbotsford, CA", code: "YXX" },
	{ value: "Nanaimo, CA", label: "Nanaimo, CA", code: "BC-3" },
	{ value: "Belleville, CA", label: "Belleville, CA", code: "BE-1" },
	{ value: "Canmore, CA", label: "Canmore, CA", code: "BF-3" },
	{ value: "Brampton, CA", label: "Brampton, CA", code: "BP-1" },
	{ value: "Guelph, CA", label: "Guelph, CA", code: "GU-1" },
	{ value: "London, Ontario, CA", label: "London, Ontario, CA", code: "LO-1" },
	{ value: "Hamilton, CA", label: "Hamilton, CA", code: "LT-1" },
	{ value: "Welland, CA", label: "Welland, CA", code: "NI-2" },
	{ value: "St Catharines, CA", label: "St Catharines, CA", code: "NI-5" },
	{ value: "Brossard, CA", label: "Brossard, CA", code: "QC-3" },
	{ value: "Dorval, CA", label: "Dorval, CA", code: "QC-6" },
	{ value: "Longueuil, CA", label: "Longueuil, CA", code: "QX-3" },
	{ value: "Langley, CA", label: "Langley, CA", code: "VA-5" },
	{ value: "Annapolis Royal, CA", label: "Annapolis Royal, CA", code: "ANR-2" },
	{ value: "Duncan, CA", label: "Duncan, CA", code: "DUQ-1" },
	{ value: "Burlington, CA", label: "Burlington, CA", code: "ON-10" },
	{ value: "Milton, CA", label: "Milton, CA", code: "ON-17" },
	{ value: "Granby, CA", label: "Granby, CA", code: "QC-11" },
	{ value: "Cambridge, CA", label: "Cambridge, CA", code: "YCB-1" },
	{ value: "Corner Brook, CA", label: "Corner Brook, CA", code: "YNF-1" },
	{ value: "Oshawa / Whitby, CA", label: "Oshawa / Whitby, CA", code: "YOO-2" },
	{ value: "Port Alberni , CA", label: "Port Alberni , CA", code: "YPB-1" },
	{ value: "Windsor,Ontario, CA", label: "Windsor,Ontario, CA", code: "YQG-1" },
	{ value: "Chatham-Kent, CA", label: "Chatham-Kent, CA", code: "YQG-2" },
	{ value: "Shawinigan, CA", label: "Shawinigan, CA", code: "YRQ-2" },
	{ value: "Fort St. John, CA", label: "Fort St. John, CA", code: "YXJ-1" },
	{ value: "osoyoos, CA", label: "osoyoos, CA", code: "YYF-3" },
	{ value: "Sept-Îles, CA", label: "Sept-Îles, CA", code: "YZV-1" },
	{ value: "Trail, CA", label: "Trail, CA", code: "YZZ-1" },
	{ value: "Beaupre Coast, CA", label: "Beaupre Coast, CA", code: "CSB-11" },
	{ value: "La Mauricie, CA", label: "La Mauricie, CA", code: "LMC-58" },
	{ value: "Port Hardy, CA", label: "Port Hardy, CA", code: "PHAR-6" },
	{ value: "Prince Rupert, CA", label: "Prince Rupert, CA", code: "PRUP-6" },
	{ value: "Mil Islas, CA", label: "Mil Islas, CA", code: "THI-67" },
	{ value: "Wells Gray, CA", label: "Wells Gray, CA", code: "WGP-12" },
	{ value: "Kitchener, CA", label: "Kitchener, CA", code: "YCB-41" },
	{ value: "Vaughan, CA", label: "Vaughan, CA", code: "fake-215" },
	{ value: "Richmond Hill, CA", label: "Richmond Hill, CA", code: "fake-221" },
	{ value: "Ajax, CA", label: "Ajax, CA", code: "fake-243" },
	{
		value: "The Blue Mountains, CA",
		label: "The Blue Mountains, CA",
		code: "fake-316",
	},
	{ value: "Tofino, CA", label: "Tofino, CA", code: "fake-690" },
	{
		value: "New Westminster, CA",
		label: "New Westminster, CA",
		code: "fake-691",
	},
	{ value: "Coquitlam, CA", label: "Coquitlam, CA", code: "fake-692" },
	{ value: "Squamish, CA", label: "Squamish, CA", code: "fake-693" },
	{ value: "Mission, CA", label: "Mission, CA", code: "fake-694" },
	{ value: "Cranbrook, CA", label: "Cranbrook, CA", code: "fake-695" },
	{ value: "Lévis, CA", label: "Lévis, CA", code: "fake-696" },
	{ value: "Pickering, CA", label: "Pickering, CA", code: "fake-697" },
	{ value: "Waterloo, CA", label: "Waterloo, CA", code: "fake-698" },
	{ value: "Stratford, CA", label: "Stratford, CA", code: "fake-699" },
	{ value: "Newmarket, CA", label: "Newmarket, CA", code: "fake-700" },
	{ value: "Blue River, CA", label: "Blue River, CA", code: "fake-803" },
	{ value: "Blainville, CA", label: "Blainville, CA", code: "Blainville" },
	{ value: "Deer Lake, CA", label: "Deer Lake, CA", code: "YDF" },
	{ value: "Gaspé, CA", label: "Gaspé, CA", code: "YGP" },
	{
		value: "Islas de la Magdalena, CA",
		label: "Islas de la Magdalena, CA",
		code: "YGR",
	},
	{ value: "Gander, CA", label: "Gander, CA", code: "YQX" },
	{ value: "Rouyn-Noranda, CA", label: "Rouyn-Noranda, CA", code: "YUY" },
	{ value: "Val-d&apos;Or, CA", label: "Val-d&apos;Or, CA", code: "YVO" },
	{ value: "Wabush, CA", label: "Wabush, CA", code: "YWK" },
	{ value: "Medicine Hat, CA", label: "Medicine Hat, CA", code: "YXH" },
	{
		value: "Happy Valley - Goose Bay, CA",
		label: "Happy Valley - Goose Bay, CA",
		code: "YYR",
	},
	{
		value: "San Juan de Terranova, CA",
		label: "San Juan de Terranova, CA",
		code: "YYT",
	},
	{ value: "Sandspit, CA", label: "Sandspit, CA", code: "YZP" },
	{
		value: "Bathurst (Nuevo Brunswick), CA",
		label: "Bathurst (Nuevo Brunswick), CA",
		code: "ZBF",
	},
	{ value: "Mont-Laurier, CA", label: "Mont-Laurier, CA", code: "Mont" },
	{ value: "Bruce Peninsula, CA", label: "Bruce Peninsula, CA", code: "BRU-1" },
	{
		value: "Digby Nuena Escocia , CA",
		label: "Digby Nuena Escocia , CA",
		code: "Digby",
	},
	{ value: "Mont-Joli, CA", label: "Mont-Joli, CA", code: "MJL-1" },
	{ value: "Percé, CA", label: "Percé, CA", code: "Perce" },
	{
		value: "Whistler Blackcomb, CA",
		label: "Whistler Blackcomb, CA",
		code: "WHI-6",
	},
	{ value: "Clearwater, CA", label: "Clearwater, CA", code: "852741" },
	{ value: "Fort Macleod, CA", label: "Fort Macleod, CA", code: "FMCA-1" },
	{
		value: "Haines Junction, CA",
		label: "Haines Junction, CA",
		code: "HAJU-1",
	},
	{ value: "100 Mile House, CA", label: "100 Mile House, CA", code: "MHO-02" },
	{ value: "Princeton, CA", label: "Princeton, CA", code: "PRI-22" },
	{ value: "Roberval, CA", label: "Roberval, CA", code: "ROBE-1" },
	{
		value: "Saint-Alexis-des-Monts, CA",
		label: "Saint-Alexis-des-Monts, CA",
		code: "SADM-6",
	},
	{
		value: "Saint-Hyacinthe, CA",
		label: "Saint-Hyacinthe, CA",
		code: "SAHY-1",
	},
	{ value: "Smithers, CA", label: "Smithers, CA", code: "SMI-02" },
	{ value: "Lago Shuswap, CA", label: "Lago Shuswap, CA", code: "SULC-1" },
	{
		value: "Rivière-du-Loup, CA",
		label: "Rivière-du-Loup, CA",
		code: "rdl123",
	},
	{ value: "Antigonish, CA", label: "Antigonish, CA", code: "ATG-002" },
	{ value: "Baddeck , CA", label: "Baddeck , CA", code: "BADDECK" },
	{
		value: "Improvement District No. 9, CA",
		label: "Improvement District No. 9, CA",
		code: "IPT-002",
	},
	{ value: "Lunenburg, CA", label: "Lunenburg, CA", code: "LNB-002" },
	{ value: "Miramichi, CA", label: "Miramichi, CA", code: "MMH-002" },
	{
		value: "Niagara-on-the-Lake, CA",
		label: "Niagara-on-the-Lake, CA",
		code: "NDGJILC",
	},
	{ value: "Parksville , CA", label: "Parksville , CA", code: "PKS-002" },
	{
		value: "Truro (Nueva Escocia), CA",
		label: "Truro (Nueva Escocia), CA",
		code: "TRO-002",
	},
	{ value: "Ucluelet, CA", label: "Ucluelet, CA", code: "UCLET-6" },
	{ value: "Wendake, CA", label: "Wendake, CA", code: "Wendake" },
	{ value: "Baie-Comeau, CA", label: "Baie-Comeau, CA", code: "YBC-002" },
	{ value: "Castlegar, CA", label: "Castlegar, CA", code: "YCG-002" },
	{ value: "Hinton, CA", label: "Hinton, CA", code: "HINTON-6" },
	{
		value: "Saint-Félicien, CA",
		label: "Saint-Félicien, CA",
		code: "fake-770",
	},
	{ value: "Saguenay, CA", label: "Saguenay, CA", code: "fake-771" },
	{
		value: "L&apos;Isle-aux-Coudres, CA",
		label: "L&apos;Isle-aux-Coudres, CA",
		code: "fake-772",
	},
	{
		value: "Mont-Tremblant, CA",
		label: "Mont-Tremblant, CA",
		code: "fake-773",
	},
	{ value: "Gananoque , CA", label: "Gananoque , CA", code: "fake-814" },
	{
		value: "Chéticamp, Nueva Escocia , CA",
		label: "Chéticamp, Nueva Escocia , CA",
		code: "Cheticamp",
	},
	{ value: "La Malbaie, CA", label: "La Malbaie, CA", code: "LaMalbaie" },
	{
		value: "Parque nacional Kejimkujik, CA",
		label: "Parque nacional Kejimkujik, CA",
		code: "Kejimkujik",
	},
	{
		value: "Saint-Michel-des-Saints, CA",
		label: "Saint-Michel-des-Saints, CA",
		code: "SaintMichel",
	},
	{
		value: "Liscomb Mills, Nueva Escocia , CA",
		label: "Liscomb Mills, Nueva Escocia , CA",
		code: "LiscombMills",
	},
	{
		value: "Ingonish Beach , CA",
		label: "Ingonish Beach , CA",
		code: "IngonishBeach",
	},
	{
		value: "Huntsville, CA",
		label: "Huntsville, CA",
		code: "Huntsvillecanada",
	},
	{ value: "Ilha do Fogo, CV", label: "Ilha do Fogo, CV", code: "SI13" },
	{
		value: "Praia (Isla de Santiago), CV",
		label: "Praia (Isla de Santiago), CV",
		code: "SI1-11",
	},
	{ value: "Cabo verde, CV", label: "Cabo verde, CV", code: "SI1" },
	{
		value: "Isla de Boa Vista, CV",
		label: "Isla de Boa Vista, CV",
		code: "BVB",
	},
	{ value: "Cabo Verde, CV", label: "Cabo Verde, CV", code: "CV-1" },
	{ value: "Sal, CV", label: "Sal, CV", code: "SI1-1" },
	{
		value: "Mindelo (Isla de Sao Vicente), CV",
		label: "Mindelo (Isla de Sao Vicente), CV",
		code: "SI1-12",
	},
	{ value: "Maio, CV", label: "Maio, CV", code: "103" },
	{ value: "Sao Filipe, CV", label: "Sao Filipe, CV", code: "104" },
	{ value: "Sao Nicolau, CV", label: "Sao Nicolau, CV", code: "105" },
	{ value: "Bonaire, BQ", label: "Bonaire, BQ", code: "BON" },
	{ value: "San Martin, BQ", label: "San Martin, BQ", code: "SXM" },
	{ value: "Islas Caimán, KY", label: "Islas Caimán, KY", code: "GCM-4" },
	{ value: "N´Djamena, TD", label: "N´Djamena, TD", code: "NDJ" },
	{ value: "Yamena, TD", label: "Yamena, TD", code: "YMN-57" },
	{
		value: "Santiago de Chile, CL",
		label: "Santiago de Chile, CL",
		code: "SCL",
	},
	{ value: "Antofagasta, CL", label: "Antofagasta, CL", code: "ANF" },
	{ value: "Arica, CL", label: "Arica, CL", code: "ARI" },
	{
		value: "San Pedro de Atacama, CL",
		label: "San Pedro de Atacama, CL",
		code: "AT4",
	},
	{ value: "Concepción, CL", label: "Concepción, CL", code: "CCP" },
	{ value: "Calama, CL", label: "Calama, CL", code: "CJC" },
	{ value: "Chiloé, CL", label: "Chiloé, CL", code: "HLE" },
	{ value: "Viña del Mar, CL", label: "Viña del Mar, CL", code: "KNA" },
	{ value: "Los Ángeles, CL", label: "Los Ángeles, CL", code: "LGS" },
	{ value: "Puerto Natales, CL", label: "Puerto Natales, CL", code: "PNT" },
	{ value: "Punta Arenas, CL", label: "Punta Arenas, CL", code: "PUQ" },
	{ value: "Puerto Varas, CL", label: "Puerto Varas, CL", code: "PUX" },
	{ value: "Temuco, CL", label: "Temuco, CL", code: "ZCO" },
	{ value: "Osorno, CL", label: "Osorno, CL", code: "ZOS" },
	{ value: "Pucón, CL", label: "Pucón, CL", code: "ZPC" },
	{ value: "La Serena, CL", label: "La Serena, CL", code: "LSC-1" },
	{ value: "Curico, CL", label: "Curico, CL", code: "CU1" },
	{ value: "Isla de Pascua, CL", label: "Isla de Pascua, CL", code: "IP1" },
	{ value: "Iquique, CL", label: "Iquique, CL", code: "IQQ" },
	{ value: "Puerto Montt, CL", label: "Puerto Montt, CL", code: "PMC" },
	{ value: "Valparaiso, CL", label: "Valparaiso, CL", code: "VAP" },
	{ value: "Ancud, CL", label: "Ancud, CL", code: "ZUD" },
	{ value: "Talca, CL", label: "Talca, CL", code: "ICA10" },
	{ value: "Castro, CL", label: "Castro, CL", code: "HLE-27" },
	{ value: "Rancagua, CL", label: "Rancagua, CL", code: "RNA" },
	{ value: "Coyhaique, CL", label: "Coyhaique, CL", code: "GXQ-1" },
	{ value: "Valdivia, CL", label: "Valdivia, CL", code: "ZAL-1" },
	{ value: "Balmaceda, CL", label: "Balmaceda, CL", code: "BBA1" },
	{
		value: "Puerto Rio Tranquilo, CL",
		label: "Puerto Rio Tranquilo, CL",
		code: "CCH1",
	},
	{ value: "Cerro Castillo, CL", label: "Cerro Castillo, CL", code: "PNT3" },
	{
		value: "Santa Cruz, Colchagua, CL",
		label: "Santa Cruz, Colchagua, CL",
		code: "SCL2",
	},
	{ value: "Copiapó, CL", label: "Copiapó, CL", code: "CPO-1" },
	{ value: "Hanga Roa, CL", label: "Hanga Roa, CL", code: "HARO-1" },
	{ value: "Reñaca, CL", label: "Reñaca, CL", code: "Renaca" },
	{
		value: "Parque nacional Torres del Paine, CL",
		label: "Parque nacional Torres del Paine, CL",
		code: "TPNP-1",
	},
	{ value: "Chillán, CL", label: "Chillán, CL", code: "fake-764" },
	{ value: "Providencia, CL", label: "Providencia, CL", code: "fake-765" },
	{ value: "Lianyungang, CN", label: "Lianyungang, CN", code: "LYG" },
	{ value: "Pekín, CN", label: "Pekín, CN", code: "PEK" },
	{ value: "Tíbet, CN", label: "Tíbet, CN", code: "KTM-1" },
	{ value: "Kunming, CN", label: "Kunming, CN", code: "KMH" },
	{ value: "Chengdú, CN", label: "Chengdú, CN", code: "CTU" },
	{ value: "Guilin, CN", label: "Guilin, CN", code: "KWL" },
	{ value: "Lijian, CN", label: "Lijian, CN", code: "LJG" },
	{ value: "Shanghái, CN", label: "Shanghái, CN", code: "PVG" },
	{ value: "Xi&apos;an, CN", label: "Xi&apos;an, CN", code: "XIY" },
	{ value: "Huangshan, CN", label: "Huangshan, CN", code: "YZH" },
	{ value: "Mianyang, CN", label: "Mianyang, CN", code: "MYG-1" },
	{ value: "Zhengzhou, CN", label: "Zhengzhou, CN", code: "CGO" },
	{ value: "Changchun, CN", label: "Changchun, CN", code: "CGQ" },
	{ value: "Chongqing, CN", label: "Chongqing, CN", code: "CKG" },
	{
		value: "Canton (Guangzhou), CN",
		label: "Canton (Guangzhou), CN",
		code: "CN1",
	},
	{ value: "Qingdao, CN", label: "Qingdao, CN", code: "CN6" },
	{ value: "Changsha, CN", label: "Changsha, CN", code: "CSX" },
	{ value: "Dongguan, CN", label: "Dongguan, CN", code: "DGM" },
	{ value: "Hangzhou, CN", label: "Hangzhou, CN", code: "HGH" },
	{ value: "Jiayuguan, CN", label: "Jiayuguan, CN", code: "JIY" },
	{ value: "Kashgar, CN", label: "Kashgar, CN", code: "KSH" },
	{ value: "Lanzhou, CN", label: "Lanzhou, CN", code: "LZD" },
	{ value: "Nanjing, CN", label: "Nanjing, CN", code: "NKG" },
	{ value: "Shenzhen, CN", label: "Shenzhen, CN", code: "SZX" },
	{ value: "Tianjin, CN", label: "Tianjin, CN", code: "TSN" },
	{ value: "Wuhan, CN", label: "Wuhan, CN", code: "WUH" },
	{ value: "Wuhai, CN", label: "Wuhai, CN", code: "WHI-1" },
	{ value: "Fuzhou , CN", label: "Fuzhou , CN", code: "FUZ-67" },
	{ value: "Jiuzhaigou, CN", label: "Jiuzhaigou, CN", code: "JZH-76" },
	{ value: "Yangshuo, CN", label: "Yangshuo, CN", code: "2" },
	{ value: "Hefei, CN", label: "Hefei, CN", code: "HFE" },
	{ value: "Lhasa, CN", label: "Lhasa, CN", code: "LXA" },
	{ value: "Macao, CN", label: "Macao, CN", code: "MFM" },
	{ value: "Shenyang, CN", label: "Shenyang, CN", code: "SHE" },
	{ value: "Wenzhou, CN", label: "Wenzhou, CN", code: "WNZ" },
	{ value: "Dali, CN", label: "Dali, CN", code: "DLU3" },
	{ value: "Xiamen, CN", label: "Xiamen, CN", code: "XMN3" },
	{ value: "Suzhou, CN", label: "Suzhou, CN", code: "szv3" },
	{ value: "Linfén, CN", label: "Linfén, CN", code: "LFN-1" },
	{ value: "Changzhou, CN", label: "Changzhou, CN", code: "WUX-2" },
	{ value: "Yiwu, CN", label: "Yiwu, CN", code: "YIW-1" },
	{ value: "Yining, CN", label: "Yining, CN", code: "YNG-2" },
	{ value: "Zhuhai, Macao, CN", label: "Zhuhai, Macao, CN", code: "ZUH-1" },
	{ value: "Hohhot, CN", label: "Hohhot, CN", code: "fake-157" },
	{ value: "Anshan, CN", label: "Anshan, CN", code: "AOG" },
	{ value: "Beihai, CN", label: "Beihai, CN", code: "BHY" },
	{ value: "Shaoxing, CN", label: "Shaoxing, CN", code: "CN0" },
	{ value: "Xinxiang, CN", label: "Xinxiang, CN", code: "CN2" },
	{ value: "Quanzhou, CN", label: "Quanzhou, CN", code: "CN4" },
	{ value: "Chaozhou, CN", label: "Chaozhou, CN", code: "CXX" },
	{ value: "Dalian, CN", label: "Dalian, CN", code: "DLC" },
	{ value: "Dandong, CN", label: "Dandong, CN", code: "DNA" },
	{ value: "Chuzhou, CN", label: "Chuzhou, CN", code: "HCU" },
	{ value: "Harbin, CN", label: "Harbin, CN", code: "HRB" },
	{ value: "Zhoushan, CN", label: "Zhoushan, CN", code: "HSN" },
	{ value: "Huizhou, CN", label: "Huizhou, CN", code: "HUZ" },
	{ value: "Kaifeng, CN", label: "Kaifeng, CN", code: "KFN" },
	{ value: "Nanchang, CN", label: "Nanchang, CN", code: "KHN" },
	{ value: "Guiyang, CN", label: "Guiyang, CN", code: "KWE" },
	{ value: "Linyi, CN", label: "Linyi, CN", code: "LYI" },
	{ value: "Liuzhou, CN", label: "Liuzhou, CN", code: "LZH" },
	{ value: "Ningbo, CN", label: "Ningbo, CN", code: "NGB" },
	{ value: "Nanning, CN", label: "Nanning, CN", code: "NNG" },
	{ value: "Nantong, CN", label: "Nantong, CN", code: "NTG" },
	{ value: "Qinhuangdao, CN", label: "Qinhuangdao, CN", code: "QIH" },
	{ value: "Shijiazhuang, CN", label: "Shijiazhuang, CN", code: "SJW" },
	{ value: "Shantou, CN", label: "Shantou, CN", code: "SW2" },
	{ value: "Tangshan, CN", label: "Tangshan, CN", code: "TGH" },
	{ value: "Jinan, CN", label: "Jinan, CN", code: "TNA" },
	{ value: "Taiyuan, CN", label: "Taiyuan, CN", code: "TYN" },
	{ value: "Taizhou-Jiangsu, CN", label: "Taizhou-Jiangsu, CN", code: "TZJ" },
	{ value: "Huainan, CN", label: "Huainan, CN", code: "UAH" },
	{ value: "Urumqi , CN", label: "Urumqi , CN", code: "URC" },
	{ value: "Wuhu, CN", label: "Wuhu, CN", code: "WHU" },
	{ value: "Nanping, CN", label: "Nanping, CN", code: "WUS" },
	{ value: "Xining, CN", label: "Xining, CN", code: "XNG" },
	{ value: "Xuzhou, CN", label: "Xuzhou, CN", code: "XUZ" },
	{ value: "Yantai, CN", label: "Yantai, CN", code: "YNT" },
	{ value: "Bayannur, CN", label: "Bayannur, CN", code: "BYN-3" },
	{ value: "Chamdo, CN", label: "Chamdo, CN", code: "CHM-2" },
	{ value: "Dunhuang, CN", label: "Dunhuang, CN", code: "DUN-2" },
	{ value: "Huai&apos;an, CN", label: "Huai&apos;an, CN", code: "HIA-2" },
	{ value: "Anyang, CN", label: "Anyang, CN", code: "HN0-1" },
	{ value: "Hubei, CN", label: "Hubei, CN", code: "Hubei" },
	{ value: "Karamay, CN", label: "Karamay, CN", code: "KRM-2" },
	{ value: "Liupanshui, CN", label: "Liupanshui, CN", code: "LPS-1" },
	{ value: "Tonghua, CN", label: "Tonghua, CN", code: "TNG-2" },
	{ value: "Turfán, CN", label: "Turfán, CN", code: "TPN-1" },
	{ value: "Ulanqab, CN", label: "Ulanqab, CN", code: "ULA-2" },
	{ value: "Wuxi, CN", label: "Wuxi, CN", code: "WUX-1" },
	{ value: "Yangzhou, CN", label: "Yangzhou, CN", code: "YZH-1" },
	{ value: "Zhenjiang, CN", label: "Zhenjiang, CN", code: "YZH-2" },
	{ value: "Zhanjiang, CN", label: "Zhanjiang, CN", code: "ZJG-1" },
	{ value: "Zhongshan, CN", label: "Zhongshan, CN", code: "ZUH-2" },
	{ value: "Aksu, CN", label: "Aksu, CN", code: "AKS-34" },
	{ value: "Baotou, CN", label: "Baotou, CN", code: "BAO-64" },
	{ value: "Chizhou, CN", label: "Chizhou, CN", code: "CHI-57" },
	{ value: "Chaoyang, CN", label: "Chaoyang, CN", code: "CHY-99" },
	{ value: "Daqing, CN", label: "Daqing, CN", code: "DAQ-67" },
	{ value: "Fukushima, CN", label: "Fukushima, CN", code: "FKA-77" },
	{ value: "Fuyang, CN", label: "Fuyang, CN", code: "Fuy-98" },
	{ value: "Guangyuan, CN", label: "Guangyuan, CN", code: "GGY-88" },
	{ value: "Ganzhou, CN", label: "Ganzhou, CN", code: "GHZ-58" },
	{ value: "Haikou, CN", label: "Haikou, CN", code: "HAI-76" },
	{ value: "Hami, CN", label: "Hami, CN", code: "HAM-76" },
	{ value: "Hailar, CN", label: "Hailar, CN", code: "HLR-55" },
	{ value: "Hotan, CN", label: "Hotan, CN", code: "HOT-34" },
	{ value: "Jingdezhen, CN", label: "Jingdezhen, CN", code: "JGD-99" },
	{ value: "Ji&apos;an, CN", label: "Ji&apos;an, CN", code: "JIA-45" },
	{ value: "Jiamusi, CN", label: "Jiamusi, CN", code: "JSM-86" },
	{ value: "Korla, CN", label: "Korla, CN", code: "KOR-60" },
	{ value: "Mudanjiang, CN", label: "Mudanjiang, CN", code: "MNG-51" },
	{ value: "Ordos, CN", label: "Ordos, CN", code: "ORD-60" },
	{ value: "Panzhihua, CN", label: "Panzhihua, CN", code: "PHH-70" },
	{ value: "Qiqihar, CN", label: "Qiqihar, CN", code: "QQR-43" },
	{ value: "Quzhou, CN", label: "Quzhou, CN", code: "QUZ-31" },
	{ value: "Shihezi, CN", label: "Shihezi, CN", code: "SHHZ-6" },
	{ value: "Tongliao, CN", label: "Tongliao, CN", code: "TONG-6" },
	{ value: "Ulanhot, CN", label: "Ulanhot, CN", code: "ULA-71" },
	{ value: "Yaodu, CN", label: "Yaodu, CN", code: "YADU-6" },
	{ value: "Zhangjiajie, CN", label: "Zhangjiajie, CN", code: "ZHA-45" },
	{ value: "Zunyi, CN", label: "Zunyi, CN", code: "ZNYI-6" },
	{ value: "Sanya, CN", label: "Sanya, CN", code: "SAN-123" },
	{ value: "Weihai, CN", label: "Weihai, CN", code: "WEIHA-6" },
	{ value: "Xichang, CN", label: "Xichang, CN", code: "XICHA-6" },
	{ value: "Tengchong, CN", label: "Tengchong, CN", code: "TCHONG-6" },
	{ value: "Wanzhou, CN", label: "Wanzhou, CN", code: "WANZHO-6" },
	{ value: "Xiangyang, CN", label: "Xiangyang, CN", code: "XIYANG-6" },
	{ value: "Zibo, CN", label: "Zibo, CN", code: "fake-156" },
	{ value: "Yinchuan, CN", label: "Yinchuan, CN", code: "fake-158" },
	{ value: "Taiyuan, CN", label: "Taiyuan, CN", code: "fake-837" },
	{ value: "Dazhou, CN", label: "Dazhou, CN", code: "DAX" },
	{ value: "Luoyang, CN", label: "Luoyang, CN", code: "LYA" },
	{ value: "Yuncheng, CN", label: "Yuncheng, CN", code: "YCU" },
	{ value: "Yichang, CN", label: "Yichang, CN", code: "YIH" },
	{ value: "Gulja, CN", label: "Gulja, CN", code: "YIN" },
	{ value: "Yanji, CN", label: "Yanji, CN", code: "YNJ" },
	{ value: "Yancheng, CN", label: "Yancheng, CN", code: "YNZ" },
	{ value: "Zhaotong, CN", label: "Zhaotong, CN", code: "ZAT" },
	{ value: "Chifeng, CN", label: "Chifeng, CN", code: "CHF-2" },
	{ value: "Datong , CN", label: "Datong , CN", code: "DAT21" },
	{ value: "Quzhou, CN", label: "Quzhou, CN", code: "JUZ-1" },
	{ value: "Luzhóu, CN", label: "Luzhóu, CN", code: "LZH-1" },
	{ value: "Nyingchi, CN", label: "Nyingchi, CN", code: "NYI-1" },
	{ value: "Manzhouli, CN", label: "Manzhouli, CN", code: "NZH-1" },
	{ value: "Shunyi, CN", label: "Shunyi, CN", code: "SHU-1" },
	{ value: "Xinyang, CN", label: "Xinyang, CN", code: "XIN-6" },
	{ value: "Dongyuan, CN", label: "Dongyuan, CN", code: "DONG-1" },
	{ value: "Emeishan, CN", label: "Emeishan, CN", code: "EMEI-6" },
	{ value: "Longji, CN", label: "Longji, CN", code: "LONGJI" },
	{ value: "Pudong , CN", label: "Pudong , CN", code: "Pudong" },
	{ value: "Foshán, CN", label: "Foshán, CN", code: "FHN-002" },
	{ value: "Huangyan , CN", label: "Huangyan , CN", code: "HUANG-6" },
	{ value: "Jiangxi , CN", label: "Jiangxi , CN", code: "JXI-002" },
	{ value: "Laoshan, CN", label: "Laoshan, CN", code: "LAOSHAN" },
	{ value: "Minhang, CN", label: "Minhang, CN", code: "Minhang" },
	{ value: "Xilinhot, CN", label: "Xilinhot, CN", code: "XHT-002" },
	{ value: "Yibin, CN", label: "Yibin, CN", code: "YBP-002" },
	{ value: "Zhejiang , CN", label: "Zhejiang , CN", code: "ZJG-002" },
	{ value: "Pingyao, CN", label: "Pingyao, CN", code: "fake-830" },
	{ value: "Sai Kung, CN", label: "Sai Kung, CN", code: "SAIKUNG-6" },
	{
		value: "Gubei Water Town, CN",
		label: "Gubei Water Town, CN",
		code: "GUBEIWATERTOWN",
	},
	{ value: "Barranquilla, CO", label: "Barranquilla, CO", code: "BAQ" },
	{ value: "Medellín, CO", label: "Medellín, CO", code: "MDE" },
	{ value: "Cali, CO", label: "Cali, CO", code: "CLO" },
	{
		value: "Cartagena de Indias, CO",
		label: "Cartagena de Indias, CO",
		code: "CTG",
	},
	{ value: "Cúcuta, CO", label: "Cúcuta, CO", code: "CUC" },
	{ value: "Ibagué, CO", label: "Ibagué, CO", code: "IBE" },
	{ value: "Montería, CO", label: "Montería, CO", code: "MTI" },
	{ value: "Manizales, CO", label: "Manizales, CO", code: "MZL" },
	{ value: "Neiva, CO", label: "Neiva, CO", code: "NEI" },
	{ value: "Pasto, CO", label: "Pasto, CO", code: "PSO" },
	{ value: "Santa Marta, CO", label: "Santa Marta, CO", code: "SMC" },
	{ value: "Villavicencio, CO", label: "Villavicencio, CO", code: "VVC" },
	{ value: "Armenia, CO", label: "Armenia, CO", code: "AXM" },
	{ value: "Bucaramanga, CO", label: "Bucaramanga, CO", code: "BGA" },
	{ value: "Bogotá, CO", label: "Bogotá, CO", code: "BOG" },
	{ value: "Pereira, CO", label: "Pereira, CO", code: "PEI" },
	{ value: "Paipa, CO", label: "Paipa, CO", code: "CAS3" },
	{ value: "La Guajira, CO", label: "La Guajira, CO", code: "ABL-3" },
	{ value: "Salento, CO", label: "Salento, CO", code: "AXM-3" },
	{ value: "Barú, CO", label: "Barú, CO", code: "CTG-3" },
	{ value: "Popayán, CO", label: "Popayán, CO", code: "PPN-1" },
	{
		value: "San Gil,Colombia, CO",
		label: "San Gil,Colombia, CO",
		code: "SG1-1",
	},
	{
		value: "Isla de San Andrés, CO",
		label: "Isla de San Andrés, CO",
		code: "SAI-123",
	},
	{ value: "Yopal, CO", label: "Yopal, CO", code: "EYP-1" },
	{
		value: "Florencia, Caquetá, CO",
		label: "Florencia, Caquetá, CO",
		code: "FLA-2",
	},
	{ value: "Leticia, CO", label: "Leticia, CO", code: "LET-1" },
	{ value: "Barichara, CO", label: "Barichara, CO", code: "SG1-2" },
	{ value: "Villa de Leyva, CO", label: "Villa de Leyva, CO", code: "VDL-1" },
	{
		value: "Barrancabermeja, CO",
		label: "Barrancabermeja, CO",
		code: "BRR-36",
	},
	{ value: "Ibague, CO", label: "Ibague, CO", code: "IBA-34" },
	{ value: "Riohacha, CO", label: "Riohacha, CO", code: "RIH-87" },
	{
		value: "Manzanillo Del Mar, CO",
		label: "Manzanillo Del Mar, CO",
		code: "MAN-1234",
	},
	{ value: "Nuquí, CO", label: "Nuquí, CO", code: "fake-664" },
	{ value: "Aracataca, CO", label: "Aracataca, CO", code: "ATC" },
	{
		value: "San Basilio de Palenque, CO",
		label: "San Basilio de Palenque, CO",
		code: "PLQ",
	},
	{ value: "Raquira, CO", label: "Raquira, CO", code: "RAQ" },
	{ value: "Sogamoso, CO", label: "Sogamoso, CO", code: "SOG" },
	{ value: "Tunja, CO", label: "Tunja, CO", code: "TNJ" },
	{
		value: "Turbo (Antioquia), CO",
		label: "Turbo (Antioquia), CO",
		code: "TRB",
	},
	{ value: "Valledupar, CO", label: "Valledupar, CO", code: "VUP" },
	{ value: "Zipaquirá, CO", label: "Zipaquirá, CO", code: "ZPQ" },
	{ value: "Acacías , CO", label: "Acacías , CO", code: "ACAC" },
	{ value: "Bahia Solano, CO", label: "Bahia Solano, CO", code: "BSOL" },
	{ value: "Cajicá, CO", label: "Cajicá, CO", code: "CAJC" },
	{ value: "Chia, CO", label: "Chia, CO", code: "CHIA" },
	{ value: "Cúcuta, CO", label: "Cúcuta, CO", code: "CUC6" },
	{ value: "Cumaral, CO", label: "Cumaral, CO", code: "CUMR" },
	{ value: "Duitama, CO", label: "Duitama, CO", code: "DUIT" },
	{
		value: "Santa Fé de Antioquia, CO",
		label: "Santa Fé de Antioquia, CO",
		code: "FE-6",
	},
	{ value: "Guatavita, CO", label: "Guatavita, CO", code: "GUAT" },
	{ value: "Isla Grande, CO", label: "Isla Grande, CO", code: "ISLG" },
	{ value: "Nemocón, CO", label: "Nemocón, CO", code: "NEMC" },
	{ value: "Oiba, CO", label: "Oiba, CO", code: "Oiba" },
	{ value: "Pitalito, CO", label: "Pitalito, CO", code: "PITl" },
	{ value: "Puerto López, CO", label: "Puerto López, CO", code: "PLPZ" },
	{ value: "Providencia, CO", label: "Providencia, CO", code: "PRVC" },
	{ value: "Quibdó, CO", label: "Quibdó, CO", code: "QUIB" },
	{
		value: "Rionegro (Antioquia), CO",
		label: "Rionegro (Antioquia), CO",
		code: "RION",
	},
	{
		value: "Trinidad (Casanare), CO",
		label: "Trinidad (Casanare), CO",
		code: "TRND",
	},
	{ value: "Ciénaga, CO", label: "Ciénaga, CO", code: "CIENG" },
	{ value: "Circasia, CO", label: "Circasia, CO", code: "CIR-1" },
	{ value: "Calarca, CO", label: "Calarca, CO", code: "CLC-1" },
	{ value: "Córdoba, CO", label: "Córdoba, CO", code: "COR-6" },
	{
		value: "El Poblado (Medellín), CO",
		label: "El Poblado (Medellín), CO",
		code: "ELPBL",
	},
	{ value: "Floridablanca, CO", label: "Floridablanca, CO", code: "FBC-1" },
	{ value: "Filandia, CO", label: "Filandia, CO", code: "FIL-1" },
	{ value: "Girardot, CO", label: "Girardot, CO", code: "GIR-6" },
	{
		value: "San Juan de Girón, CO",
		label: "San Juan de Girón, CO",
		code: "Giron",
	},
	{ value: "Honda, CO", label: "Honda, CO", code: "Honda" },
	{ value: "Isla Mucura, CO", label: "Isla Mucura, CO", code: "IMC-1" },
	{ value: "Ipiales, CO", label: "Ipiales, CO", code: "IPI-1" },
	{ value: "Jardín, CO", label: "Jardín, CO", code: "JAR-6" },
	{ value: "Jericó, CO", label: "Jericó, CO", code: "JER-6" },
	{ value: "La Tebaida, CO", label: "La Tebaida, CO", code: "LTC-1" },
	{ value: "Montenegro, CO", label: "Montenegro, CO", code: "MTN-1" },
	{ value: "Palomino, CO", label: "Palomino, CO", code: "PAL-6" },
	{ value: "Pueblo Tapao, CO", label: "Pueblo Tapao, CO", code: "PTC-1" },
	{ value: "Quimbaya, CO", label: "Quimbaya, CO", code: "QUI-1" },
	{
		value: "San Agustín (Huila), CO",
		label: "San Agustín (Huila), CO",
		code: "SNAGS",
	},
	{
		value: "Parque Nacional Natural Tayrona, CO",
		label: "Parque Nacional Natural Tayrona, CO",
		code: "TAY-6",
	},
	{ value: "Tenjo, CO", label: "Tenjo, CO", code: "TEN-6" },
	{ value: "Tumaco, CO", label: "Tumaco, CO", code: "TNC-1" },
	{ value: "Ubaté, CO", label: "Ubaté, CO", code: "Ubate" },
	{ value: "Vélez, CO", label: "Vélez, CO", code: "Velez" },
	{ value: "Apulo , CO", label: "Apulo , CO", code: "apulo" },
	{ value: "Nobsa, CO", label: "Nobsa, CO", code: "nobsa" },
	{ value: "Bojacá , CO", label: "Bojacá , CO", code: "Bojaca" },
	{ value: "Garzón , CO", label: "Garzón , CO", code: "Garzon" },
	{ value: "Melgar, CO", label: "Melgar, CO", code: "Melgar" },
	{ value: "Puerto Gaitán, CO", label: "Puerto Gaitán, CO", code: "PGAI-6" },
	{ value: "Tota, CO", label: "Tota, CO", code: "TOTA-6" },
	{ value: "La Mesa, CO", label: "La Mesa, CO", code: "lamesa" },
	{ value: "Amazonas, CO", label: "Amazonas, CO", code: "AMZ-002" },
	{ value: "Arauca, CO", label: "Arauca, CO", code: "ARC-002" },
	{ value: "Aguazul, CO", label: "Aguazul, CO", code: "Aguazul" },
	{ value: "Buga, CO", label: "Buga, CO", code: "BGA-002" },
	{ value: "Bocagrande, CO", label: "Bocagrande, CO", code: "BGE-002" },
	{ value: "Buenaventura, CO", label: "Buenaventura, CO", code: "BVR-002" },
	{ value: "Boyacá, CO", label: "Boyacá, CO", code: "BYC-002" },
	{ value: "Barbosa , CO", label: "Barbosa , CO", code: "Barbosa" },
	{
		value: "Córdoba (Montería), CO",
		label: "Córdoba (Montería), CO",
		code: "CDB-002",
	},
	{ value: "Chocó, CO", label: "Chocó, CO", code: "CHC-002" },
	{
		value: "https://en.wikipedia.org/wiki/Casanare_Department, CO",
		label: "https://en.wikipedia.org/wiki/Casanare_Department, CO",
		code: "CSE-002",
	},
	{
		value: "Puerto Triunfo (Doradal), CO",
		label: "Puerto Triunfo (Doradal), CO",
		code: "Doradal",
	},
	{ value: "Guaviare, CO", label: "Guaviare, CO", code: "GVR-002" },
	{ value: "El Peñol, CO", label: "El Peñol, CO", code: "PNL-002" },
	{
		value: "Islas Corales del Rosario, CO",
		label: "Islas Corales del Rosario, CO",
		code: "ROSIS-1",
	},
	{ value: "Sucre, CO", label: "Sucre, CO", code: "SCE-002" },
	{ value: "San Luis, CO", label: "San Luis, CO", code: "SLS-002" },
	{
		value: "Santander (State), CO",
		label: "Santander (State), CO",
		code: "STR-002",
	},
	{ value: "Sasaima, CO", label: "Sasaima, CO", code: "Sasaima" },
	{ value: "Socorro, CO", label: "Socorro, CO", code: "Socorro" },
	{ value: "Yumbo, CO", label: "Yumbo, CO", code: "YBO-002" },
	{ value: "Fúquene , CO", label: "Fúquene , CO", code: "fuquene" },
	{ value: "Villeta, CO", label: "Villeta, CO", code: "villeta" },
	{ value: "Anapoima, CO", label: "Anapoima, CO", code: "Anapoima" },
	{ value: "Corrales, CO", label: "Corrales, CO", code: "Corrales" },
	{ value: "La Dorada, CO", label: "La Dorada, CO", code: "LaDorada" },
	{ value: "Pajarito, CO", label: "Pajarito, CO", code: "Pajarito" },
	{ value: "Tibasosa, CO", label: "Tibasosa, CO", code: "Tibasosa" },
	{ value: "Chinchiná, CO", label: "Chinchiná, CO", code: "Chinchina" },
	{ value: "Espinal , CO", label: "Espinal , CO", code: "ElEspinal" },
	{ value: "Tocancipá, CO", label: "Tocancipá, CO", code: "Tocancipa" },
	{ value: "Facatativá, CO", label: "Facatativá, CO", code: "Facatativa" },
	{ value: "Campoalegre, CO", label: "Campoalegre, CO", code: "Campoalegre" },
	{ value: "Sutamarchán , CO", label: "Sutamarchán , CO", code: "Sutamarchan" },
	{
		value: "Chiquinquirá, CO",
		label: "Chiquinquirá, CO",
		code: "Chiquinquira",
	},
	{
		value: "Puerto Berrío, CO",
		label: "Puerto Berrío, CO",
		code: "PuertoBerrio",
	},
	{
		value: "Carmen de Apicalá, CO",
		label: "Carmen de Apicalá, CO",
		code: "CarmenApicala",
	},
	{
		value: "Puente Nacional (Santander), CO",
		label: "Puente Nacional (Santander), CO",
		code: "PuenteNacional",
	},
	{ value: "Cajamarca, CO", label: "Cajamarca, CO", code: "CajamarcaTolima" },
	{
		value: "Santa Rosa de Cabal, CO",
		label: "Santa Rosa de Cabal, CO",
		code: "SantaRosadecabal",
	},
	{
		value: "Guadalupe, CO",
		label: "Guadalupe, CO",
		code: "GuadalupeSantander",
	},
	{ value: "Gran Comora, KM", label: "Gran Comora, KM", code: "YVA" },
	{ value: "Moroni, KM", label: "Moroni, KM", code: "MRN-1" },
	{ value: "Kisangani, CG", label: "Kisangani, CG", code: "KSG-2" },
	{ value: "Brazzaville, CG", label: "Brazzaville, CG", code: "BRZ-98" },
	{ value: "Pointe-Noire, CG", label: "Pointe-Noire, CG", code: "PTN-65" },
	{ value: "Kinsasa, CD", label: "Kinsasa, CD", code: "FIH" },
	{ value: "Goma, CD", label: "Goma, CD", code: "GOM-87" },
	{ value: "Lubumbashi, CD", label: "Lubumbashi, CD", code: "LUB-80" },
	{ value: "Mbuji-Mayi, CD", label: "Mbuji-Mayi, CD", code: "MBJM-6" },
	{
		value: "Rarotonga Cook Islands, CK",
		label: "Rarotonga Cook Islands, CK",
		code: "COK-11",
	},
	{ value: "San José, CR", label: "San José, CR", code: "SJO-10" },
	{ value: "Limon, CR", label: "Limon, CR", code: "510" },
	{ value: "Carate, CR", label: "Carate, CR", code: "890" },
	{ value: "Monteverde, CR", label: "Monteverde, CR", code: "MNT" },
	{ value: "Tortuguero, CR", label: "Tortuguero, CR", code: "PVI" },
	{ value: "Liberia, CR", label: "Liberia, CR", code: "GAN40" },
	{ value: "Tamarindo, CR", label: "Tamarindo, CR", code: "GAN_20" },
	{ value: "Manuel Antonio, CR", label: "Manuel Antonio, CR", code: "JCO_40" },
	{
		value: "Puerto Viejo De Talamanca, CR",
		label: "Puerto Viejo De Talamanca, CR",
		code: "PVI-10",
	},
	{ value: "Jacó, CR", label: "Jacó, CR", code: "140" },
	{ value: "Puerto Jimenez, CR", label: "Puerto Jimenez, CR", code: "287" },
	{ value: "Volcán Arenal, CR", label: "Volcán Arenal, CR", code: "ARE" },
	{ value: "Guanacaste, CR", label: "Guanacaste, CR", code: "GAN" },
	{ value: "Cahuita, CR", label: "Cahuita, CR", code: "PVI-1" },
	{
		value: "Peninsula de Osa, CR",
		label: "Peninsula de Osa, CR",
		code: "TMU-3",
	},
	{ value: "Dominical, CR", label: "Dominical, CR", code: "TMU-4" },
	{ value: "Uvita, CR", label: "Uvita, CR", code: "TMU-5" },
	{ value: "Golfito, CR", label: "Golfito, CR", code: "TMU-6" },
	{ value: "Perez Zeledon, CR", label: "Perez Zeledon, CR", code: "TMU-7" },
	{
		value: "Rincon de la Vieja, CR",
		label: "Rincon de la Vieja, CR",
		code: "GAN-40",
	},
	{ value: "Alajuela, CR", label: "Alajuela, CR", code: "300" },
	{ value: "Quepos, CR", label: "Quepos, CR", code: "328" },
	{ value: "Conchal, CR", label: "Conchal, CR", code: "418" },
	{ value: "Sixaola, CR", label: "Sixaola, CR", code: "650" },
	{ value: "Peñas Blancas, CR", label: "Peñas Blancas, CR", code: "888" },
	{ value: "Guapiles, CR", label: "Guapiles, CR", code: "900" },
	{ value: "Esterillos, CR", label: "Esterillos, CR", code: "JCO-3" },
	{ value: "Puntarenas, CR", label: "Puntarenas, CR", code: "JCO20" },
	{ value: "La Cruz, CR", label: "La Cruz, CR", code: "CRU123" },
	{ value: "Manzanillo, CR", label: "Manzanillo, CR", code: "MAN334" },
	{ value: "La Fortuna, CR", label: "La Fortuna, CR", code: "123-FOR" },
	{ value: "Sámara, CR", label: "Sámara, CR", code: "123-SAM" },
	{ value: "Peñas Blancas, CR", label: "Peñas Blancas, CR", code: "PEN1232" },
	{ value: "Nicoya, CR", label: "Nicoya, CR", code: "Nicoya" },
	{
		value: "Puerto Viejo Sarapiqui, CR",
		label: "Puerto Viejo Sarapiqui, CR",
		code: "PUE123",
	},
	{
		value: "Cantón de San Carlos, CR",
		label: "Cantón de San Carlos, CR",
		code: "SCCR-1",
	},
	{ value: "Santa Teresa, CR", label: "Santa Teresa, CR", code: "STCR-1" },
	{ value: "Caño Negro, CR", label: "Caño Negro, CR", code: "CNN-002" },
	{ value: "Pococí , CR", label: "Pococí , CR", code: "PCI-002" },
	{
		value: "El Tejar (Costa Rica), CR",
		label: "El Tejar (Costa Rica), CR",
		code: "TJR-002",
	},
	{ value: "Zagreb, HR", label: "Zagreb, HR", code: "ZAG" },
	{ value: "Dalmatia, HR", label: "Dalmatia, HR", code: "DAL" },
	{ value: "Dubrovnik, HR", label: "Dubrovnik, HR", code: "DBV-1" },
	{ value: "Korcula, HR", label: "Korcula, HR", code: "DBV-2" },
	{ value: "Rovinj, HR", label: "Rovinj, HR", code: "PUY-6" },
	{ value: "Rijeka, HR", label: "Rijeka, HR", code: "RJK-6" },
	{ value: "Isla de Brac, HR", label: "Isla de Brac, HR", code: "SPU-6" },
	{ value: "Zadar, HR", label: "Zadar, HR", code: "ZAD-1" },
	{ value: "Umag , HR", label: "Umag , HR", code: "PUY" },
	{ value: "Opatija, HR", label: "Opatija, HR", code: "RJK" },
	{ value: "Biograd, HR", label: "Biograd, HR", code: "ZAD2" },
	{ value: "Pula, HR", label: "Pula, HR", code: "PUY-5" },
	{ value: "Isla Krk, HR", label: "Isla Krk, HR", code: "RJK-1" },
	{ value: "Rab Island, HR", label: "Rab Island, HR", code: "RJK-3" },
	{ value: "Split, HR", label: "Split, HR", code: "SPU-1" },
	{ value: "Isla de Hvar, HR", label: "Isla de Hvar, HR", code: "SPU-7" },
	{ value: "Sibenik, HR", label: "Sibenik, HR", code: "SPU-8" },
	{ value: "Trogir, HR", label: "Trogir, HR", code: "SPU-12" },
	{ value: "Bol, HR", label: "Bol, HR", code: "BOL-2" },
	{ value: "Brsečine, HR", label: "Brsečine, HR", code: "BRS-2" },
	{ value: "Hvar, HR", label: "Hvar, HR", code: "HVR-2" },
	{ value: "Isla Vis, HR", label: "Isla Vis, HR", code: "IVI-1" },
	{ value: "Jelsa, HR", label: "Jelsa, HR", code: "JLS-2" },
	{ value: "Isla Koločep, HR", label: "Isla Koločep, HR", code: "KLC-2" },
	{ value: "Korčula, HR", label: "Korčula, HR", code: "KRC-2" },
	{ value: "Lumbarda, HR", label: "Lumbarda, HR", code: "LMB-2" },
	{ value: "Lopud eiland, HR", label: "Lopud eiland, HR", code: "LPD-2" },
	{ value: "Isla Lastovo, HR", label: "Isla Lastovo, HR", code: "LST-2" },
	{ value: "Isla Mljet, HR", label: "Isla Mljet, HR", code: "MLJ-2" },
	{ value: "Osijek, HR", label: "Osijek, HR", code: "OSJ-4" },
	{ value: "Postira, HR", label: "Postira, HR", code: "PST-2" },
	{ value: "Istria, HR", label: "Istria, HR", code: "PUY-0" },
	{ value: "Rabac, HR", label: "Rabac, HR", code: "PUY-1" },
	{ value: "Porec, HR", label: "Porec, HR", code: "PUY-3" },
	{
		value: "Lagos de Plitvice, HR",
		label: "Lagos de Plitvice, HR",
		code: "RJK-7",
	},
	{ value: "Lovran, HR", label: "Lovran, HR", code: "RJK-9" },
	{ value: "Stari Grad, HR", label: "Stari Grad, HR", code: "SGR-2" },
	{ value: "Smokvica, HR", label: "Smokvica, HR", code: "SMK-2" },
	{ value: "Isla Šipan, HR", label: "Isla Šipan, HR", code: "SPN-2" },
	{ value: "Makarska, HR", label: "Makarska, HR", code: "SPU-3" },
	{ value: "Supetar, HR", label: "Supetar, HR", code: "STR-2" },
	{ value: "Vis, HR", label: "Vis, HR", code: "VIS-2" },
	{ value: "Vela Luka, HR", label: "Vela Luka, HR", code: "VLU-2" },
	{ value: "Podstrana, HR", label: "Podstrana, HR", code: "SPU-11" },
	{ value: "Omis, HR", label: "Omis, HR", code: "SPU-13" },
	{ value: "Mali Losinj, HR", label: "Mali Losinj, HR", code: "123-Mali" },
	{ value: "Pag, HR", label: "Pag, HR", code: "fake-251" },
	{ value: "Nin, HR", label: "Nin, HR", code: "Nin" },
	{ value: "Pojezerje, HR", label: "Pojezerje, HR", code: "POJ" },
	{ value: "Brtonigla, HR", label: "Brtonigla, HR", code: "BRTG" },
	{ value: "Crikvenica, HR", label: "Crikvenica, HR", code: "CRKV" },
	{ value: "Rogoznika, HR", label: "Rogoznika, HR", code: "RGNZ" },
	{ value: "Lopar, HR", label: "Lopar, HR", code: "RJK8" },
	{ value: "Malinska, HR", label: "Malinska, HR", code: "RJK9" },
	{ value: "Baska Voda, HR", label: "Baska Voda, HR", code: "VSC2" },
	{ value: "Zagorska Sela, HR", label: "Zagorska Sela, HR", code: "ZGS1" },
	{ value: "Pakostane, HR", label: "Pakostane, HR", code: "PKSTN" },
	{ value: "Pag, HR", label: "Pag, HR", code: "Pag-1" },
	{ value: "Novi Vinodolski, HR", label: "Novi Vinodolski, HR", code: "RJK11" },
	{ value: "Vrsar, HR", label: "Vrsar, HR", code: "Vrsar" },
	{ value: "Velika Gorica, HR", label: "Velika Gorica, HR", code: "ZAG33" },
	{ value: "Milna, HR", label: "Milna, HR", code: "milna" },
	{
		value: "Dubrovacko Primorje , HR",
		label: "Dubrovacko Primorje , HR",
		code: "DUPR-1",
	},
	{ value: "Gradac, HR", label: "Gradac, HR", code: "GRAD-1" },
	{ value: "Njivice, HR", label: "Njivice, HR", code: "NJIV-1" },
	{ value: "Novalja, HR", label: "Novalja, HR", code: "NOVA-1" },
	{ value: "Obrovac, HR", label: "Obrovac, HR", code: "OBRO-1" },
	{ value: "Oprtalj, HR", label: "Oprtalj, HR", code: "OPRT-6" },
	{ value: "Orebić, HR", label: "Orebić, HR", code: "Orebic" },
	{ value: "Sinj, HR", label: "Sinj, HR", code: "SINJ-6" },
	{ value: "Cres, HR", label: "Cres, HR", code: "CRS-002" },
	{ value: "Ilok, HR", label: "Ilok, HR", code: "ILK-002" },
	{ value: "Kali, HR", label: "Kali, HR", code: "KLI-002" },
	{ value: "Klis, HR", label: "Klis, HR", code: "KLS-002" },
	{ value: "Labin, HR", label: "Labin, HR", code: "LABIN-6" },
	{
		value: "Mošćenička Draga, HR",
		label: "Mošćenička Draga, HR",
		code: "MKD-002",
	},
	{ value: "Okrug Donji, HR", label: "Okrug Donji, HR", code: "OKRUG-6" },
	{ value: "Punat, HR", label: "Punat, HR", code: "PUNAT-6" },
	{ value: "Seget, HR", label: "Seget, HR", code: "SEGET-6" },
	{ value: "Sali, HR", label: "Sali, HR", code: "SLI-002" },
	{ value: "Senj, HR", label: "Senj, HR", code: "SNJ-002" },
	{ value: "Solin, HR", label: "Solin, HR", code: "SOLIN-6" },
	{ value: "Solta, HR", label: "Solta, HR", code: "SOLTA-6" },
	{ value: "Ston, HR", label: "Ston, HR", code: "STN-002" },
	{ value: "Vir, HR", label: "Vir, HR", code: "VIR-002" },
	{ value: "Kastav, HR", label: "Kastav, HR", code: "KASTAV-6" },
	{ value: "Murter, HR", label: "Murter, HR", code: "MURTER-6" },
	{ value: "Tucepi, HR", label: "Tucepi, HR", code: "TUCEPI-6" },
	{ value: "Vodice, HR", label: "Vodice, HR", code: "VODICE-6" },
	{ value: "Konavle, HR", label: "Konavle, HR", code: "KONAVLE-6" },
	{ value: "Primošten, HR", label: "Primošten, HR", code: "Primosten" },
	{ value: "Premantura, HR", label: "Premantura, HR", code: "Premantura" },
	{
		value: "Župa Dubrovačka , HR",
		label: "Župa Dubrovačka , HR",
		code: "ZupaDubrovacka",
	},
	{ value: "La Habana, CU", label: "La Habana, CU", code: "HAV" },
	{ value: "Camagüey, CU", label: "Camagüey, CU", code: "CMG" },
	{ value: "Baracoa, CU", label: "Baracoa, CU", code: "BAA" },
	{ value: "Cayo Guillermo, CU", label: "Cayo Guillermo, CU", code: "CAG" },
	{ value: "Cayo Coco, CU", label: "Cayo Coco, CU", code: "CCO" },
	{ value: "Cienfuegos, CU", label: "Cienfuegos, CU", code: "CIE" },
	{ value: "Holguín, CU", label: "Holguín, CU", code: "HOG" },
	{ value: "Cayo Santa María, CU", label: "Cayo Santa María, CU", code: "SMA" },
	{ value: "Santiago de Cuba, CU", label: "Santiago de Cuba, CU", code: "STG" },
	{ value: "Trinidad , CU", label: "Trinidad , CU", code: "TND" },
	{ value: "Santa Clara, CU", label: "Santa Clara, CU", code: "VCL" },
	{ value: "Varadero, CU", label: "Varadero, CU", code: "VRA" },
	{ value: "Viñales, CU", label: "Viñales, CU", code: "PDR-3" },
	{ value: "Matanzas, CU", label: "Matanzas, CU", code: "VRA-6" },
	{ value: "Cayo Largo, CU", label: "Cayo Largo, CU", code: "LAR" },
	{ value: "Ciego de Ávila, CU", label: "Ciego de Ávila, CU", code: "CDA-2" },
	{ value: "Pinar del Río, CU", label: "Pinar del Río, CU", code: "PDR-2" },
	{ value: "Holguin, CU", label: "Holguin, CU", code: "HOL-98" },
	{ value: "Guamá, CU", label: "Guamá, CU", code: "GUAMA-6" },
	{ value: "Guardalavaca, CU", label: "Guardalavaca, CU", code: "fake-603" },
	{
		value: "Sancti Spiritus, CU",
		label: "Sancti Spiritus, CU",
		code: "SSPIRITUS-6",
	},
	{ value: "Mayari, CU", label: "Mayari, CU", code: "MYRI" },
	{ value: "Remedios, CU", label: "Remedios, CU", code: "RMDS" },
	{ value: "Playa Jibacoa, CU", label: "Playa Jibacoa, CU", code: "JIB-1" },
	{ value: "Las Tunas, CU", label: "Las Tunas, CU", code: "LTN-2" },
	{ value: "Mayari Arriba, CU", label: "Mayari Arriba, CU", code: "MYRIA" },
	{ value: "Playa Pesquero, CU", label: "Playa Pesquero, CU", code: "PES-6" },
	{ value: "Morón, CU", label: "Morón, CU", code: "MORO-1" },
	{
		value: "Rafael Freyre, CU",
		label: "Rafael Freyre, CU",
		code: "RafaelFreyre",
	},
	{ value: "Curazao, CW", label: "Curazao, CW", code: "CUA" },
	{ value: "Limassol, CY", label: "Limassol, CY", code: "CY" },
	{ value: "Larnaca, CY", label: "Larnaca, CY", code: "LA" },
	{
		value: "Nicosia (Lefkosia), CY",
		label: "Nicosia (Lefkosia), CY",
		code: "NIC",
	},
	{ value: "Ayia Napa, CY", label: "Ayia Napa, CY", code: "AG" },
	{ value: "Paphos, CY", label: "Paphos, CY", code: "PA" },
	{ value: "Kyrenia, CY", label: "Kyrenia, CY", code: "NC-1" },
	{ value: "Protaras, CY", label: "Protaras, CY", code: "PR" },
	{
		value: "Chipre del Norte, CY",
		label: "Chipre del Norte, CY",
		code: "ECN3",
	},
	{ value: "Latsi, CY", label: "Latsi, CY", code: "LAT-6" },
	{ value: "Coral Bay, CY", label: "Coral Bay, CY", code: "CORA-6" },
	{ value: "Famagusta, CY", label: "Famagusta, CY", code: "FAMA-6" },
	{ value: "Platres, CY", label: "Platres, CY", code: "PLAT-6" },
	{ value: "Trikomo, CY", label: "Trikomo, CY", code: "TRIK-6" },
	{ value: "Vokolida, CY", label: "Vokolida, CY", code: "BAFRA-6" },
	{ value: "Chipre, CY", label: "Chipre, CY", code: "CYP-002" },
	{ value: "Kouklia , CY", label: "Kouklia , CY", code: "Kouklia" },
	{ value: "Lapithos, CY", label: "Lapithos, CY", code: "LAPTA-6" },
	{ value: "Trykomo, CY", label: "Trykomo, CY", code: "TRIKO-6" },
	{ value: "Praga, CZ", label: "Praga, CZ", code: "PRG" },
	{ value: "Ostrava, CZ", label: "Ostrava, CZ", code: "OTV" },
	{ value: "Bohemian Forest, CZ", label: "Bohemian Forest, CZ", code: "BHF" },
	{ value: "Brno, CZ", label: "Brno, CZ", code: "BNO" },
	{ value: "Giant Mountains, CZ", label: "Giant Mountains, CZ", code: "GIA" },
	{ value: "Karlovy Vary, CZ", label: "Karlovy Vary, CZ", code: "KLV" },
	{ value: "Plzen (Pilsen), CZ", label: "Plzen (Pilsen), CZ", code: "PZE" },
	{
		value: "Ceské Budejovice, CZ",
		label: "Ceské Budejovice, CZ",
		code: "CES-1",
	},
	{ value: "Olomouc, CZ", label: "Olomouc, CZ", code: "CZE-1" },
	{ value: "Harrachov, CZ", label: "Harrachov, CZ", code: "CHO" },
	{ value: "Marianske Lanze, CZ", label: "Marianske Lanze, CZ", code: "MKA" },
	{ value: "Pec Pod Snezkou, CZ", label: "Pec Pod Snezkou, CZ", code: "PPS" },
	{ value: "Spindleruv Mlyn, CZ", label: "Spindleruv Mlyn, CZ", code: "SML" },
	{
		value: "Lipno nad Vltavou, CZ",
		label: "Lipno nad Vltavou, CZ",
		code: "CES-3",
	},
	{ value: "Český Krumlov, CZ", label: "Český Krumlov, CZ", code: "CES" },
	{ value: "Frýdek-Místek, CZ", label: "Frýdek-Místek, CZ", code: "FRMI-1" },
	{ value: "Náchod , CZ", label: "Náchod , CZ", code: "Nachod" },
	{ value: "Abidjan , CI", label: "Abidjan , CI", code: "ABJ" },
	{ value: "Copenhagen, DK", label: "Copenhagen, DK", code: "CPH" },
	{ value: "Aalborg, DK", label: "Aalborg, DK", code: "AAL" },
	{ value: "Esbjerg, DK", label: "Esbjerg, DK", code: "ESB" },
	{ value: "Frederikshavn, DK", label: "Frederikshavn, DK", code: "FRE" },
	{ value: "Kolding, DK", label: "Kolding, DK", code: "KOD" },
	{ value: "Odense, DK", label: "Odense, DK", code: "ODE" },
	{ value: "Roskilde, DK", label: "Roskilde, DK", code: "CPH4" },
	{ value: "Ribe, DK", label: "Ribe, DK", code: "Ribe" },
	{ value: "Billund, DK", label: "Billund, DK", code: "VEJ-2" },
	{ value: "Aarhus, DK", label: "Aarhus, DK", code: "AAR" },
	{ value: "Islas Feroe, DK", label: "Islas Feroe, DK", code: "FRI-12" },
	{ value: "Gedser, DK", label: "Gedser, DK", code: "GDS-2" },
	{ value: "Hirtshals, DK", label: "Hirtshals, DK", code: "HSH-2" },
	{
		value: "LEGOLAND® Billund, DK",
		label: "LEGOLAND® Billund, DK",
		code: "LEG-123",
	},
	{ value: "Rødby, DK", label: "Rødby, DK", code: "Rdby" },
	{ value: "Vejle, DK", label: "Vejle, DK", code: "VJLE" },
	{ value: "Sonderborg, DK", label: "Sonderborg, DK", code: "SON-6" },
	{ value: "Fredericia, DK", label: "Fredericia, DK", code: "FDC-002" },
	{
		value: "Nykøbing Falster, DK",
		label: "Nykøbing Falster, DK",
		code: "nykobing",
	},
	{ value: "Ciudad de Yibuti, DJ", label: "Ciudad de Yibuti, DJ", code: "JIB" },
	{ value: "Dominica isla , DM", label: "Dominica isla , DM", code: "fake-73" },
	{ value: "Santo Domigo, DO", label: "Santo Domigo, DO", code: "DOM" },
	{ value: "Puerto Plata, DO", label: "Puerto Plata, DO", code: "POP" },
	{ value: "Punta Cana, DO", label: "Punta Cana, DO", code: "PUJ" },
	{ value: "La Romana, DO", label: "La Romana, DO", code: "ROM" },
	{ value: "Bayahíbe, DO", label: "Bayahíbe, DO", code: "BAY" },
	{ value: "Boca Chica, DO", label: "Boca Chica, DO", code: "BOC" },
	{ value: "Juan Dolio, DO", label: "Juan Dolio, DO", code: "JDO" },
	{ value: "Samaná, DO", label: "Samaná, DO", code: "SAM" },
	{
		value: "Santiago De Los Caballeros, DO",
		label: "Santiago De Los Caballeros, DO",
		code: "SDC",
	},
	{ value: "Cabarete, DO", label: "Cabarete, DO", code: "POP-10" },
	{ value: "Sosúa, DO", label: "Sosúa, DO", code: "POP-20" },
	{ value: "Bávaro, DO", label: "Bávaro, DO", code: "PUJ-20" },
	{ value: "Cabrera, DO", label: "Cabrera, DO", code: "RPD-2" },
	{ value: "Las Terrenas, DO", label: "Las Terrenas, DO", code: "fake-743" },
	{ value: "Monte Cristi, DO", label: "Monte Cristi, DO", code: "mntcr" },
	{ value: "Higüey, DO", label: "Higüey, DO", code: "HIGU-1" },
	{ value: "Samaná , DO", label: "Samaná , DO", code: "SAM-88" },
	{ value: "Jarabacoa, DO", label: "Jarabacoa, DO", code: "JBC-002" },
	{ value: "Ramón Santana, DO", label: "Ramón Santana, DO", code: "SANTANA-6" },
	{ value: "Guayacanes, DO", label: "Guayacanes, DO", code: "Guayacanes" },
	{ value: "Río San Juan, DO", label: "Río San Juan, DO", code: "Riosanjuan" },
	{ value: "Quito, EC", label: "Quito, EC", code: "UIO" },
	{
		value: "Isla de San Cristóbal, EC",
		label: "Isla de San Cristóbal, EC",
		code: "SCB-1",
	},
	{ value: "Cuenca, EC", label: "Cuenca, EC", code: "CCN" },
	{ value: "Esmeraldas, EC", label: "Esmeraldas, EC", code: "ESM" },
	{ value: "Guayaquil, EC", label: "Guayaquil, EC", code: "GYE" },
	{ value: "loja, EC", label: "loja, EC", code: "LOH" },
	{ value: "Manta, EC", label: "Manta, EC", code: "MT5" },
	{ value: "Salinas, EC", label: "Salinas, EC", code: "SNC" },
	{ value: "Puerto López, EC", label: "Puerto López, EC", code: "TOUPLZ" },
	{
		value: "Las Islas Galápagos , EC",
		label: "Las Islas Galápagos , EC",
		code: "GPS",
	},
	{ value: "Machachi, EC", label: "Machachi, EC", code: "MHA" },
	{ value: "Isla Santa Cruz, EC", label: "Isla Santa Cruz, EC", code: "SCT-3" },
	{ value: "Baños, EC", label: "Baños, EC", code: "ANB-1" },
	{ value: "Otavalo, EC", label: "Otavalo, EC", code: "OTA-87" },
	{
		value: "Francisco de Orellana, EC",
		label: "Francisco de Orellana, EC",
		code: "ORELL-6",
	},
	{ value: "Montañita, EC", label: "Montañita, EC", code: "fake-663" },
	{ value: "Cotopaxi, EC", label: "Cotopaxi, EC", code: "fake-688" },
	{ value: "Lago Agrio, EC", label: "Lago Agrio, EC", code: "LAGIO" },
	{ value: "Isla Baltra, EC", label: "Isla Baltra, EC", code: "BIS-98" },
	{ value: "Cosanga, EC", label: "Cosanga, EC", code: "COSA-6" },
	{ value: "Isla Isabela, EC", label: "Isla Isabela, EC", code: "ISIS-1" },
	{ value: "Misahualli, EC", label: "Misahualli, EC", code: "MISA-6" },
	{
		value: "Puerto Villamil, EC",
		label: "Puerto Villamil, EC",
		code: "PUVI-1",
	},
	{ value: "Riobamba, EC", label: "Riobamba, EC", code: "BAMBA-6" },
	{ value: "Bucay, EC", label: "Bucay, EC", code: "BUCAY-6" },
	{ value: "Alausí, EC", label: "Alausí, EC", code: "Alaus123" },
	{ value: "Mashpi, EC", label: "Mashpi, EC", code: "MASHPI-6" },
	{ value: "El Cairo, EG", label: "El Cairo, EG", code: "CAI" },
	{ value: "Luxor, EG", label: "Luxor, EG", code: "LXR" },
	{ value: "Alejandría, EG", label: "Alejandría, EG", code: "ALY" },
	{ value: "Asuán, EG", label: "Asuán, EG", code: "ASW" },
	{ value: "Sharm el-Sheikh , EG", label: "Sharm el-Sheikh , EG", code: "SSH" },
	{ value: "Hurghada, EG", label: "Hurghada, EG", code: "HRG-1" },
	{ value: "Marsa Alam, EG", label: "Marsa Alam, EG", code: "RMF" },
	{ value: "Ghiza, EG", label: "Ghiza, EG", code: "CAI-2" },
	{ value: "El Gouna, EG", label: "El Gouna, EG", code: "HRG-4" },
	{ value: "Abu Simbel, EG", label: "Abu Simbel, EG", code: "074" },
	{ value: "Taba, EG", label: "Taba, EG", code: "TAB" },
	{ value: "Abydos, EG", label: "Abydos, EG", code: "ABY-1" },
	{ value: "Menia, EG", label: "Menia, EG", code: "ALM-1" },
	{ value: "Marsa Matruh, EG", label: "Marsa Matruh, EG", code: "ALY-3" },
	{ value: "Bahariya Oasis, EG", label: "Bahariya Oasis, EG", code: "BAO-1" },
	{ value: "Esna, EG", label: "Esna, EG", code: "ESN-1" },
	{ value: "Faiyum, EG", label: "Faiyum, EG", code: "FYM-1" },
	{ value: "Makadi Bay, EG", label: "Makadi Bay, EG", code: "HRG-2" },
	{ value: "Puerto Said, EG", label: "Puerto Said, EG", code: "PSD-1" },
	{ value: "Santa Catalina, EG", label: "Santa Catalina, EG", code: "SKV-1" },
	{ value: "Suez, EG", label: "Suez, EG", code: "SUZ-1" },
	{ value: "Siwa Oasis, EG", label: "Siwa Oasis, EG", code: "SWO-1" },
	{ value: "Asyut, EG", label: "Asyut, EG", code: "ASY-34" },
	{ value: "Sohag, EG", label: "Sohag, EG", code: "SOH-98" },
	{
		value: "Hermópolis Magna, EG",
		label: "Hermópolis Magna, EG",
		code: "HRMPL-6",
	},
	{
		value: "Tell el-Amarna, EG",
		label: "Tell el-Amarna, EG",
		code: "TELLEL-6",
	},
	{ value: "Safaga, EG", label: "Safaga, EG", code: "fake-329" },
	{ value: "Soma Bay, EG", label: "Soma Bay, EG", code: "fake-330" },
	{ value: "Dahab, EG", label: "Dahab, EG", code: "fake-331" },
	{ value: "Nuweiba, EG", label: "Nuweiba, EG", code: "fake-332" },
	{ value: "El Quseir, EG", label: "El Quseir, EG", code: "fake-333" },
	{ value: "Port Ghalib, EG", label: "Port Ghalib, EG", code: "fake-334" },
	{ value: "Sahl Hasheesh, EG", label: "Sahl Hasheesh, EG", code: "fake-335" },
	{ value: "Ismailia, EG", label: "Ismailia, EG", code: "PSD" },
	{ value: "El Qoseir, EG", label: "El Qoseir, EG", code: "ALQS" },
	{ value: "Berenice, EG", label: "Berenice, EG", code: "BRNC" },
	{ value: "Qena, EG", label: "Qena, EG", code: "Qina" },
	{ value: "Brayka Bay, EG", label: "Brayka Bay, EG", code: "BBE-1" },
	{ value: "Dendera, EG", label: "Dendera, EG", code: "DND-1" },
	{ value: "Edfu, EG", label: "Edfu, EG", code: "EDF-2" },
	{ value: "Fyoum, EG", label: "Fyoum, EG", code: "FOM-1" },
	{ value: "Mallawi, EG", label: "Mallawi, EG", code: "MALL-1" },
	{ value: "Ras Sedr, EG", label: "Ras Sedr, EG", code: "RasSedr" },
	{ value: "Idfu, EG", label: "Idfu, EG", code: "fake-812" },
	{ value: "Kom Ombo, EG", label: "Kom Ombo, EG", code: "fake-821" },
	{ value: "Ain Sokhna, EG", label: "Ain Sokhna, EG", code: "AinSokhna" },
	{ value: "Zaafarana, EG", label: "Zaafarana, EG", code: "Zaafarana" },
	{ value: "San Salvador, SV", label: "San Salvador, SV", code: "SA6" },
	{ value: "Ahuachapan, SV", label: "Ahuachapan, SV", code: "ACP-3" },
	{ value: "La Paz, SV", label: "La Paz, SV", code: "SA6-3" },
	{ value: "La Libertad, SV", label: "La Libertad, SV", code: "SA6-4" },
	{ value: "Salinitas, SV", label: "Salinitas, SV", code: "SA6-6" },
	{ value: "San Miguel, SV", label: "San Miguel, SV", code: "SMN-3" },
	{ value: "La Union, SV", label: "La Union, SV", code: "UNI-3" },
	{ value: "Malabo, GQ", label: "Malabo, GQ", code: "MALAB-6" },
	{ value: "Asmara, ER", label: "Asmara, ER", code: "ASM-34" },
	{ value: "Tallinn, EE", label: "Tallinn, EE", code: "TLL" },
	{ value: "Kuressaare, EE", label: "Kuressaare, EE", code: "KUR" },
	{ value: "Narva, EE", label: "Narva, EE", code: "NAJ" },
	{ value: "Pärnu, EE", label: "Pärnu, EE", code: "EPU" },
	{ value: "Tartu, EE", label: "Tartu, EE", code: "TAY" },
	{ value: "Narva-Jõesuu, EE", label: "Narva-Jõesuu, EE", code: "NarvaJoesuu" },
	{ value: "Adís Abeba, ET", label: "Adís Abeba, ET", code: "ADD" },
	{ value: "Arba Minch, ET", label: "Arba Minch, ET", code: "ARM-54" },
	{ value: "Bahir Dar, ET", label: "Bahir Dar, ET", code: "BHD-45" },
	{ value: "Jimma, ET", label: "Jimma, ET", code: "Jimma" },
	{ value: "Adís Ababa, ET", label: "Adís Ababa, ET", code: "ADB-77" },
	{ value: "Assosa, ET", label: "Assosa, ET", code: "ASO-98" },
	{ value: "Awasa, ET", label: "Awasa, ET", code: "AWA-64" },
	{ value: "Bahir Dar, ET", label: "Bahir Dar, ET", code: "BHD-87" },
	{ value: "Dese, ET", label: "Dese, ET", code: "DES-64" },
	{ value: "Gondar, ET", label: "Gondar, ET", code: "GND-66" },
	{ value: "Gondar, ET", label: "Gondar, ET", code: "GNR-36" },
	{ value: "Gode, ET", label: "Gode, ET", code: "GOD-98" },
	{ value: "Jijiga, ET", label: "Jijiga, ET", code: "JLI-89" },
	{ value: "Lalibela , ET", label: "Lalibela , ET", code: "LAL-54" },
	{ value: "Mekele, ET", label: "Mekele, ET", code: "MKL-76" },
	{ value: "Semera, ET", label: "Semera, ET", code: "SMRA-6" },
	{ value: "Dambi Dolo, ET", label: "Dambi Dolo, ET", code: "dmdl" },
	{ value: "Gonder, ET", label: "Gonder, ET", code: "GDR-1" },
	{ value: "Humera, ET", label: "Humera, ET", code: "HMR-2" },
	{ value: "Arba Minch, ET", label: "Arba Minch, ET", code: "ARB-65" },
	{ value: "Axum, ET", label: "Axum, ET", code: "AXU-43" },
	{ value: "Gambela, ET", label: "Gambela, ET", code: "GMB-56" },
	{ value: "Jinka, ET", label: "Jinka, ET", code: "JIN-97" },
	{ value: "Kebri Dehar, ET", label: "Kebri Dehar, ET", code: "KDH-66" },
	{ value: "Konso , ET", label: "Konso , ET", code: "KOM-44" },
	{ value: "Turmi, ET", label: "Turmi, ET", code: "TUR-98" },
	{ value: "Dire Dawa, ET", label: "Dire Dawa, ET", code: "diredawa" },
	{
		value: "Torshavn, FO",
		label: "Torshavn, FO",
		code: "____NEW____1439976196117",
	},
	{ value: "Fiji, FJ", label: "Fiji, FJ", code: "FJ1" },
	{ value: "Islas Yasawa , FJ", label: "Islas Yasawa , FJ", code: "291" },
	{ value: "Taveuni , FJ", label: "Taveuni , FJ", code: "252" },
	{ value: "Levuka , FJ", label: "Levuka , FJ", code: "290" },
	{ value: "Savusavu, FJ", label: "Savusavu, FJ", code: "292" },
	{ value: "Vatulele , FJ", label: "Vatulele , FJ", code: "293" },
	{
		value: "Isla Beachcomber, FJ",
		label: "Isla Beachcomber, FJ",
		code: "BCH-2",
	},
	{ value: "Suva, FJ", label: "Suva, FJ", code: "FJ1-6" },
	{ value: "Likuliku Lagoon, FJ", label: "Likuliku Lagoon, FJ", code: "LKL-6" },
	{ value: "Isla Mana, FJ", label: "Isla Mana, FJ", code: "MAI-2" },
	{ value: "Nanuya Lailai, FJ", label: "Nanuya Lailai, FJ", code: "NNL-3" },
	{ value: "Nanuya Balavu, FJ", label: "Nanuya Balavu, FJ", code: "NNY-3" },
	{ value: "Naviti Island, FJ", label: "Naviti Island, FJ", code: "NVT-3" },
	{ value: "Isla Castaway, FJ", label: "Isla Castaway, FJ", code: "QLT-2" },
	{
		value: "Rakiraki Sun Coast área (Distrito Raikiki), FJ",
		label: "Rakiraki Sun Coast área (Distrito Raikiki), FJ",
		code: "RKR-2",
	},
	{ value: "Isla Serenity, FJ", label: "Isla Serenity, FJ", code: "SRT-2" },
	{
		value: "South Sea Island, FJ",
		label: "South Sea Island, FJ",
		code: "SSI-6",
	},
	{ value: "Isla Tokoriki, FJ", label: "Isla Tokoriki, FJ", code: "TKI-6" },
	{ value: "Isla Treasure, FJ", label: "Isla Treasure, FJ", code: "TRE-3" },
	{ value: "Tropical Beach, FJ", label: "Tropical Beach, FJ", code: "TRI-6" },
	{ value: "Isla Turtle, FJ", label: "Isla Turtle, FJ", code: "TTISL" },
	{ value: "Isla Vomo, FJ", label: "Isla Vomo, FJ", code: "VOM-6" },
	{
		value: "Vuda Point (Lautoka), FJ",
		label: "Vuda Point (Lautoka), FJ",
		code: "VPO--",
	},
	{ value: "Isla Nacula, FJ", label: "Isla Nacula, FJ", code: "NACISL-6" },
	{
		value: "Isla Matacawa levu, FJ",
		label: "Isla Matacawa levu, FJ",
		code: "MatacawalevuIsland",
	},
	{ value: "Nadi, FJ", label: "Nadi, FJ", code: "NAN1" },
	{
		value: "Matamanoa Island Resort, FJ",
		label: "Matamanoa Island Resort, FJ",
		code: "NAN3",
	},
	{ value: "Malolo Islands, FJ", label: "Malolo Islands, FJ", code: "NAN5" },
	{ value: "Isla Viwa, FJ", label: "Isla Viwa, FJ", code: "Viwa" },
	{ value: "Waya Island, FJ", label: "Waya Island, FJ", code: "waya" },
	{ value: "Isla Kuata, FJ", label: "Isla Kuata, FJ", code: "kuata" },
	{ value: "Isla Tavewa, FJ", label: "Isla Tavewa, FJ", code: "Tavewa" },
	{ value: "Nadroga-Navosa, FJ", label: "Nadroga-Navosa, FJ", code: "NA-NA-6" },
	{
		value: "Isla Naukacuvu , FJ",
		label: "Isla Naukacuvu , FJ",
		code: "Naukacuv",
	},
	{
		value: " Pacific Harbour, FJ",
		label: " Pacific Harbour, FJ",
		code: "PHarbour",
	},
	{
		value: "Yanggeta Island, FJ",
		label: "Yanggeta Island, FJ",
		code: "Yanggeta",
	},
	{
		value: "Isala Waya Lailai, FJ",
		label: "Isala Waya Lailai, FJ",
		code: "WayaLailai",
	},
	{
		value: "Drawaqa Island, FJ",
		label: "Drawaqa Island, FJ",
		code: "DrawaqaIsland",
	},
	{ value: "Helsinki, FI", label: "Helsinki, FI", code: "HEL-1" },
	{ value: "Joensuu, FI", label: "Joensuu, FI", code: "JNS" },
	{ value: "Kuusamo, FI", label: "Kuusamo, FI", code: "KAO" },
	{ value: "Kuopio, FI", label: "Kuopio, FI", code: "KUO" },
	{ value: "Kouvola, FI", label: "Kouvola, FI", code: "KVL" },
	{ value: "Mikkeli, FI", label: "Mikkeli, FI", code: "MKL" },
	{ value: "Kajaani, FI", label: "Kajaani, FI", code: "KAJ-1" },
	{ value: "Kotka, FI", label: "Kotka, FI", code: "KOT-1" },
	{ value: "Pori, FI", label: "Pori, FI", code: "ORI-1" },
	{ value: "Rauma, FI", label: "Rauma, FI", code: "RAU-1" },
	{ value: "Kittilä, FI", label: "Kittilä, FI", code: "RVN-7" },
	{ value: "Sirkka, FI", label: "Sirkka, FI", code: "SIK-1" },
	{ value: "Espoo, FI", label: "Espoo, FI", code: "ESP" },
	{ value: "Vantaa, FI", label: "Vantaa, FI", code: "HEL-2" },
	{ value: "Kemi, FI", label: "Kemi, FI", code: "KEM-1" },
	{ value: "Kilpisjärvi, FI", label: "Kilpisjärvi, FI", code: "KIL-1" },
	{ value: "Leppäjärvi, FI", label: "Leppäjärvi, FI", code: "LEP-1" },
	{ value: "Muonio, FI", label: "Muonio, FI", code: "MUN-1" },
	{ value: "Jyvaskyla, FI", label: "Jyvaskyla, FI", code: "JYV" },
	{ value: "Lahti, FI", label: "Lahti, FI", code: "LAH" },
	{ value: "Lappeenranta, FI", label: "Lappeenranta, FI", code: "LPP" },
	{ value: "Oulu, FI", label: "Oulu, FI", code: "OUL" },
	{ value: "Savonlinna, FI", label: "Savonlinna, FI", code: "SVN" },
	{ value: "Tampere, FI", label: "Tampere, FI", code: "TMP" },
	{ value: "Turku, FI", label: "Turku, FI", code: "TUR" },
	{ value: "Vaasa, FI", label: "Vaasa, FI", code: "VAA" },
	{ value: "Kakslauttanen, FI", label: "Kakslauttanen, FI", code: "KAK-1" },
	{ value: "Inari, FI", label: "Inari, FI", code: "INR-1" },
	{ value: "Luosto, FI", label: "Luosto, FI", code: "LST-1" },
	{ value: "Rovaniemi, FI", label: "Rovaniemi, FI", code: "RVN-1" },
	{ value: "Ivalo, FI", label: "Ivalo, FI", code: "IVA-123" },
	{ value: "Saariselka, FI", label: "Saariselka, FI", code: "fake-522" },
	{ value: "Nellim, FI", label: "Nellim, FI", code: "NLLM" },
	{ value: "Harriniva, FI", label: "Harriniva, FI", code: "HRR-1" },
	{ value: "Iso Syöte, FI", label: "Iso Syöte, FI", code: "ISO-1" },
	{ value: "Kiilopää, FI", label: "Kiilopää, FI", code: "KII-1" },
	{
		value: "Región de los 1000 lagos, FI",
		label: "Región de los 1000 lagos, FI",
		code: "LGS-2",
	},
	{ value: "Kukkola, FI", label: "Kukkola, FI", code: "KKKL-6" },
	{ value: "Porvoo, FI", label: "Porvoo, FI", code: "PORV-6" },
	{
		value: "Hofn (Hornafjörður), FI",
		label: "Hofn (Hornafjörður), FI",
		code: "RARO-6",
	},
	{ value: "Pello, FI", label: "Pello, FI", code: "PELLO-6" },
	{ value: "Salla, FI", label: "Salla, FI", code: "SALLA-6" },
	{ value: "Aavasaksa, FI", label: "Aavasaksa, FI", code: "AVSKSA-6" },
	{ value: "Tornio, FI", label: "Tornio, FI", code: "TORNIO-6" },
	{ value: "Mariehamn, FI", label: "Mariehamn, FI", code: "Mariehamn" },
	{ value: "París, FR", label: "París, FR", code: "PAR" },
	{ value: "Guadalupe, FR", label: "Guadalupe, FR", code: "UPE" },
	{ value: "La Toussuire, FR", label: "La Toussuire, FR", code: "fake-79" },
	{ value: "Bretagne, FR", label: "Bretagne, FR", code: "BRT" },
	{ value: "Marsella, FR", label: "Marsella, FR", code: "MRS" },
	{ value: "Normandy, FR", label: "Normandy, FR", code: "NORM" },
	{ value: "Alsacia, FR", label: "Alsacia, FR", code: "ALS" },
	{ value: "Auxerre, FR", label: "Auxerre, FR", code: "AUF" },
	{ value: "Avignon, FR", label: "Avignon, FR", code: "AVN" },
	{ value: "Brest, FR", label: "Brest, FR", code: "BES" },
	{ value: "Burdeos, FR", label: "Burdeos, FR", code: "BOD" },
	{ value: "Caen , FR", label: "Caen , FR", code: "CAE" },
	{ value: "Carcassone, FR", label: "Carcassone, FR", code: "CCF" },
	{ value: "Chartres, FR", label: "Chartres, FR", code: "CTE" },
	{ value: "Dijon, FR", label: "Dijon, FR", code: "DIJ" },
	{ value: "Orleans, FR", label: "Orleans, FR", code: "FR1" },
	{ value: "Grenoble, FR", label: "Grenoble, FR", code: "GRN" },
	{ value: "Lourdes, FR", label: "Lourdes, FR", code: "LDE" },
	{ value: "Le Mans, FR", label: "Le Mans, FR", code: "LEM" },
	{ value: "Limoges, FR", label: "Limoges, FR", code: "LGE" },
	{ value: "Le Puy en Velay, FR", label: "Le Puy en Velay, FR", code: "LPV" },
	{ value: "La Rochelle, FR", label: "La Rochelle, FR", code: "LRH" },
	{
		value: "Languedoc-Rosellón, FR",
		label: "Languedoc-Rosellón, FR",
		code: "LRS",
	},
	{ value: "Lyon, FR", label: "Lyon, FR", code: "LYS" },
	{ value: "Montpellier, FR", label: "Montpellier, FR", code: "MPL" },
	{ value: "Nantes, FR", label: "Nantes, FR", code: "NAN" },
	{ value: "Niza, FR", label: "Niza, FR", code: "NCE" },
	{ value: "Poitiers, FR", label: "Poitiers, FR", code: "POT" },
	{ value: "Rennes, FR", label: "Rennes, FR", code: "REN" },
	{ value: "Reims, FR", label: "Reims, FR", code: "RHE" },
	{ value: "Perpignan, FR", label: "Perpignan, FR", code: "RPI" },
	{ value: "Saintes, FR", label: "Saintes, FR", code: "TES" },
	{ value: "Toulouse, FR", label: "Toulouse, FR", code: "TLS" },
	{ value: "Toulon, FR", label: "Toulon, FR", code: "TOU" },
	{ value: "Saint tropez, FR", label: "Saint tropez, FR", code: "TRP" },
	{ value: "Troyes, FR", label: "Troyes, FR", code: "TRY" },
	{ value: "Tours, FR", label: "Tours, FR", code: "TUF" },
	{ value: "Vannes, FR", label: "Vannes, FR", code: "UIP" },
	{ value: "Rouen, FR", label: "Rouen, FR", code: "URO" },
	{ value: "Vosges, FR", label: "Vosges, FR", code: "VOS" },
	{ value: "Champagne, FR", label: "Champagne, FR", code: "CHAM" },
	{ value: "Ajaccio, FR", label: "Ajaccio, FR", code: "AJA-1" },
	{ value: "Bastia, FR", label: "Bastia, FR", code: "AJA-2" },
	{ value: "Valloire, FR", label: "Valloire, FR", code: "CHX9X" },
	{ value: "Bergerac, FR", label: "Bergerac, FR", code: "DOG_2" },
	{
		value: "Mont Saint Michel, FR",
		label: "Mont Saint Michel, FR",
		code: "MSM-1",
	},
	{ value: "Les Houches, FR", label: "Les Houches, FR", code: "CHX-25" },
	{ value: "Les Menuires, FR", label: "Les Menuires, FR", code: "CHX-29" },
	{ value: "Costa Azul, FR", label: "Costa Azul, FR", code: "Fake-142" },
	{ value: "Arcachon, FR", label: "Arcachon, FR", code: "AC2" },
	{ value: "Alenzón, FR", label: "Alenzón, FR", code: "AEC" },
	{ value: "Angoulême , FR", label: "Angoulême , FR", code: "AG2" },
	{ value: "Aix-en-Provence, FR", label: "Aix-en-Provence, FR", code: "AIX" },
	{ value: "Angers, FR", label: "Angers, FR", code: "ANG" },
	{ value: "Arles, FR", label: "Arles, FR", code: "ARL" },
	{ value: "Aurillac, FR", label: "Aurillac, FR", code: "AUR" },
	{ value: "Besanzón, FR", label: "Besanzón, FR", code: "BE2" },
	{ value: "Biarritz, FR", label: "Biarritz, FR", code: "BIQ" },
	{ value: "Bourges, FR", label: "Bourges, FR", code: "BR3" },
	{ value: "Beauvais, FR", label: "Beauvais, FR", code: "BVA" },
	{ value: "Calais, FR", label: "Calais, FR", code: "CAL" },
	{ value: "Cannes, FR", label: "Cannes, FR", code: "CAN" },
	{ value: "Cholet, FR", label: "Cholet, FR", code: "CEH" },
	{
		value: "Clermont-Ferrand , FR",
		label: "Clermont-Ferrand , FR",
		code: "CLR",
	},
	{ value: "Colmar, FR", label: "Colmar, FR", code: "CMR" },
	{ value: "Châteauroux, FR", label: "Châteauroux, FR", code: "CTX" },
	{ value: "Dunkerque, FR", label: "Dunkerque, FR", code: "DKQ" },
	{ value: "Deauville, FR", label: "Deauville, FR", code: "DVL" },
	{ value: "Nancy, FR", label: "Nancy, FR", code: "ENC" },
	{ value: "Épinal, FR", label: "Épinal, FR", code: "EPL" },
	{ value: "Évreux, FR", label: "Évreux, FR", code: "EVX" },
	{ value: "Nimes, FR", label: "Nimes, FR", code: "FNI" },
	{ value: "Albi, FR", label: "Albi, FR", code: "LBI" },
	{ value: "Lille, FR", label: "Lille, FR", code: "LIL" },
	{ value: "Lens, FR", label: "Lens, FR", code: "LNS" },
	{ value: "Le Havre, FR", label: "Le Havre, FR", code: "LVR" },
	{ value: "Mont-de-Marsan, FR", label: "Mont-de-Marsan, FR", code: "MDM" },
	{ value: "Metz, FR", label: "Metz, FR", code: "MET" },
	{ value: "Mulhouse, FR", label: "Mulhouse, FR", code: "MUH" },
	{ value: "Annecy, FR", label: "Annecy, FR", code: "NCY" },
	{ value: "Pau, FR", label: "Pau, FR", code: "PUF" },
	{ value: "Provence, FR", label: "Provence, FR", code: "PVZ" },
	{ value: "Amiens, FR", label: "Amiens, FR", code: "QAM" },
	{ value: "Quimper, FR", label: "Quimper, FR", code: "QUI" },
	{ value: "Roubaix, FR", label: "Roubaix, FR", code: "RBX" },
	{ value: "Rocamadour, FR", label: "Rocamadour, FR", code: "RCM" },
	{ value: "Rodez , FR", label: "Rodez , FR", code: "RDZ" },
	{ value: "Estrasburgo, FR", label: "Estrasburgo, FR", code: "SXB" },
	{ value: "Auch, FR", label: "Auch, FR", code: "UCH" },
	{ value: "Valence, FR", label: "Valence, FR", code: "VAF" },
	{ value: "Saint-Malo, FR", label: "Saint-Malo, FR", code: "XSB" },
	{ value: "La Plagne, FR", label: "La Plagne, FR", code: "CHX4" },
	{ value: "Saint Lary  , FR", label: "Saint Lary  , FR", code: "FR23" },
	{ value: "Font Romeu, FR", label: "Font Romeu, FR", code: "FR24" },
	{
		value: "Disneyland París, FR",
		label: "Disneyland París, FR",
		code: "PAR9",
	},
	{ value: "Carnac, FR", label: "Carnac, FR", code: "QUI4" },
	{ value: "Porto Vevvhio, FR", label: "Porto Vevvhio, FR", code: "AJA-3" },
	{ value: "Calvi, FR", label: "Calvi, FR", code: "AJA-4" },
	{
		value: "Boulogne Sur Mer, FR",
		label: "Boulogne Sur Mer, FR",
		code: "BLG-1",
	},
	{ value: "Cheverny, FR", label: "Cheverny, FR", code: "BLO-5" },
	{ value: "Narbona, FR", label: "Narbona, FR", code: "BZR-8" },
	{ value: "Chamonix, FR", label: "Chamonix, FR", code: "CHX-1" },
	{ value: "Serre Chevalier, FR", label: "Serre Chevalier, FR", code: "CHX-2" },
	{ value: "Chambery, FR", label: "Chambery, FR", code: "CHX-3" },
	{ value: "Courchevel, FR", label: "Courchevel, FR", code: "CHX-9" },
	{ value: "Vichy, FR", label: "Vichy, FR", code: "CLR-4" },
	{
		value: "Val D&apos;Isere , FR",
		label: "Val D&apos;Isere , FR",
		code: "CNX-2",
	},
	{
		value: "Brive-la-Gallairde, FR",
		label: "Brive-la-Gallairde, FR",
		code: "DOG-3",
	},
	{ value: "Cahors, FR", label: "Cahors, FR", code: "DOG-4" },
	{
		value: "Sarlat-la-caneda, FR",
		label: "Sarlat-la-caneda, FR",
		code: "DOG-7",
	},
	{ value: "Guéret, FR", label: "Guéret, FR", code: "MCU-2" },
	{ value: "Saint-Quentin, FR", label: "Saint-Quentin, FR", code: "QAM-4" },
	{ value: "Saint Etienne, FR", label: "Saint Etienne, FR", code: "SET-2" },
	{ value: "Montauban, FR", label: "Montauban, FR", code: "TLS-3" },
	{ value: "Chaumont, FR", label: "Chaumont, FR", code: "TRY-4" },
	{ value: "Lorient , FR", label: "Lorient , FR", code: "UIP-3" },
	{
		value: "Chalon-sur-saone, FR",
		label: "Chalon-sur-saone, FR",
		code: "XBV-2",
	},
	{ value: "Propriano, FR", label: "Propriano, FR", code: "AJA-11" },
	{ value: "Megève, FR", label: "Megève, FR", code: "CHX-10" },
	{ value: "Tignes, FR", label: "Tignes, FR", code: "CHX-15" },
	{ value: "Les Deux Alpes, FR", label: "Les Deux Alpes, FR", code: "CHX-18" },
	{ value: "Les Sybelles, FR", label: "Les Sybelles, FR", code: "CHX-22" },
	{ value: "Morillon, FR", label: "Morillon, FR", code: "CHX-34" },
	{
		value: "Alpe d&apos;Huez, FR",
		label: "Alpe d&apos;Huez, FR",
		code: "CHX-40",
	},
	{ value: "Samoëns, FR", label: "Samoëns, FR", code: "CHX-46" },
	{ value: "Flaine, FR", label: "Flaine, FR", code: "CHX-47" },
	{ value: "Morzine, FR", label: "Morzine, FR", code: "CHX-50" },
	{ value: "Le Corbier, FR", label: "Le Corbier, FR", code: "CHX-64" },
	{ value: "Gap, FR", label: "Gap, FR", code: "CHX-65" },
	{ value: "Châtel, FR", label: "Châtel, FR", code: "CHX-66" },
	{ value: "Mont Blanc, FR", label: "Mont Blanc, FR", code: "CHX-68" },
	{ value: "Belle Plagne, FR", label: "Belle Plagne, FR", code: "CHX-80" },
	{ value: "Aussois, FR", label: "Aussois, FR", code: "CHX-82" },
	{ value: "Les Deux Alpes, FR", label: "Les Deux Alpes, FR", code: "CNX-18" },
	{
		value: "Bourg-en-bresse, FR",
		label: "Bourg-en-bresse, FR",
		code: "LYS-10",
	},
	{ value: "Étampes, FR", label: "Étampes, FR", code: "PAR-43" },
	{ value: "Aime la Plagne, FR", label: "Aime la Plagne, FR", code: "fake-76" },
	{
		value: "La Chapelle-d&apos;Abondance, FR",
		label: "La Chapelle-d&apos;Abondance, FR",
		code: "fake-77",
	},
	{
		value: "St. Jean d&apos;Arves, FR",
		label: "St. Jean d&apos;Arves, FR",
		code: "fake-80",
	},
	{
		value: "St. Sorlin d&apos;Arves, FR",
		label: "St. Sorlin d&apos;Arves, FR",
		code: "fake-81",
	},
	{ value: "Orange, FR", label: "Orange, FR", code: "OR" },
	{ value: "Agen, FR", label: "Agen, FR", code: "AGF" },
	{ value: "Aix les Bains, FR", label: "Aix les Bains, FR", code: "AXB" },
	{ value: "Bayeux, FR", label: "Bayeux, FR", code: "AYE" },
	{ value: "Portes du Soleil, FR", label: "Portes du Soleil, FR", code: "CHX" },
	{ value: "Corsica, FR", label: "Corsica, FR", code: "COR" },
	{ value: "Castres, FR", label: "Castres, FR", code: "DCM" },
	{ value: "Belfort, FR", label: "Belfort, FR", code: "ELF" },
	{ value: "Figeac, FR", label: "Figeac, FR", code: "FIG" },
	{ value: "Chatellerault, FR", label: "Chatellerault, FR", code: "HTL" },
	{ value: "Millau, FR", label: "Millau, FR", code: "ILL" },
	{ value: "La Baule, FR", label: "La Baule, FR", code: "LBE" },
	{
		value: "Les Sables D&apos;olonne, FR",
		label: "Les Sables D&apos;olonne, FR",
		code: "LES",
	},
	{ value: "Le Touquet, FR", label: "Le Touquet, FR", code: "LTQ" },
	{ value: "Menton, FR", label: "Menton, FR", code: "MEN" },
	{ value: "Montargis, FR", label: "Montargis, FR", code: "MG3" },
	{ value: "Saint-Nazaire, FR", label: "Saint-Nazaire, FR", code: "NAZ" },
	{
		value: "Salon De Provence, FR",
		label: "Salon De Provence, FR",
		code: "SDP",
	},
	{ value: "Saint Lo, FR", label: "Saint Lo, FR", code: "SNL" },
	{ value: "Saint Omer, FR", label: "Saint Omer, FR", code: "SOM" },
	{ value: "Les Angles , FR", label: "Les Angles , FR", code: "FR26" },
	{
		value: "Luchon-Superbagneres, FR",
		label: "Luchon-Superbagneres, FR",
		code: "FR27",
	},
	{ value: "La Mongie, FR", label: "La Mongie, FR", code: "FR29" },
	{ value: "Grand Massif, FR", label: "Grand Massif, FR", code: "USE1" },
	{ value: "Cavaillon, FR", label: "Cavaillon, FR", code: "VN-2" },
	{ value: "Manosque, FR", label: "Manosque, FR", code: "AIX-5" },
	{ value: "Bonifacio, FR", label: "Bonifacio, FR", code: "AJA-6" },
	{ value: "Avallon, FR", label: "Avallon, FR", code: "AUF-6" },
	{ value: "Lons Le Saunier, FR", label: "Lons Le Saunier, FR", code: "BE2-4" },
	{ value: "Blois, FR", label: "Blois, FR", code: "BLS-2" },
	{ value: "Biscarrosse, FR", label: "Biscarrosse, FR", code: "BOD-8" },
	{ value: "Béziers, FR", label: "Béziers, FR", code: "BZR-2" },
	{
		value: "Saint Pierre La Mer, FR",
		label: "Saint Pierre La Mer, FR",
		code: "BZR-9",
	},
	{ value: "Antibes, FR", label: "Antibes, FR", code: "CAN-5" },
	{ value: "Grasse, FR", label: "Grasse, FR", code: "CAN-7" },
	{ value: "Les Herbiers, FR", label: "Les Herbiers, FR", code: "CEH-3" },
	{
		value: "Champagny en Vanoise, FR",
		label: "Champagny en Vanoise, FR",
		code: "CHX-7",
	},
	{ value: "Megève , FR", label: "Megève , FR", code: "CHX10" },
	{
		value: "Brides-les-Bains, FR",
		label: "Brides-les-Bains, FR",
		code: "CHX42",
	},
	{ value: "Val Thorens, FR", label: "Val Thorens, FR", code: "CHX48" },
	{ value: "Les Arcs, FR", label: "Les Arcs, FR", code: "CHX49" },
	{
		value: "Meribel - Mottaret, FR",
		label: "Meribel - Mottaret, FR",
		code: "CHX61",
	},
	{ value: "Cilaos, FR", label: "Cilaos, FR", code: "CLO-2" },
	{ value: "Cabourg, FR", label: "Cabourg, FR", code: "DVL-4" },
	{
		value: "La Chapelle St Mesmin, FR",
		label: "La Chapelle St Mesmin, FR",
		code: "FR1-2",
	},
	{ value: "Hell-Bourg, FR", label: "Hell-Bourg, FR", code: "HLB-2" },
	{ value: "Mimizan, FR", label: "Mimizan, FR", code: "LDS-4" },
	{
		value: "L&apos;Ermitage-Les-Bains, FR",
		label: "L&apos;Ermitage-Les-Bains, FR",
		code: "LER-2",
	},
	{
		value: "La Roche Sur Yon, FR",
		label: "La Roche Sur Yon, FR",
		code: "LES-4",
	},
	{ value: "Dardilly, FR", label: "Dardilly, FR", code: "LYS-6" },
	{ value: "Saint Avold, FR", label: "Saint Avold, FR", code: "MET-2" },
	{ value: "Roscoff, FR", label: "Roscoff, FR", code: "MRL-6" },
	{ value: "Vitrolles, FR", label: "Vitrolles, FR", code: "MRS-6" },
	{ value: "Pornic, FR", label: "Pornic, FR", code: "NAN-3" },
	{ value: "Saint-Denis, FR", label: "Saint-Denis, FR", code: "SDE-2" },
	{
		value: "Saint-Gilles-Les-Bains, FR",
		label: "Saint-Gilles-Les-Bains, FR",
		code: "SGI-2",
	},
	{ value: "Saint-Pierre, FR", label: "Saint-Pierre, FR", code: "SPR-2" },
	{ value: "Haguenau, FR", label: "Haguenau, FR", code: "SXB-4" },
	{ value: "Blagnac, FR", label: "Blagnac, FR", code: "TLS-2" },
	{ value: "Draguignan, FR", label: "Draguignan, FR", code: "TRP-5" },
	{ value: "Frejus, FR", label: "Frejus, FR", code: "TRP-6" },
	{ value: "Trois Vallées, FR", label: "Trois Vallées, FR", code: "TRP-9" },
	{ value: "Dinard, FR", label: "Dinard, FR", code: "XSB-4" },
	{ value: "Dinan, FR", label: "Dinan, FR", code: "XSB-6" },
	{ value: "Beaune, FR", label: "Beaune, FR", code: "XVB-1" },
	{ value: "Pertuis, FR", label: "Pertuis, FR", code: "AIX-14" },
	{ value: "Corte, FR", label: "Corte, FR", code: "AJA-15" },
	{ value: "Belgodère, FR", label: "Belgodère, FR", code: "AJA-17" },
	{ value: "Arromanches, FR", label: "Arromanches, FR", code: "ARRO-6" },
	{ value: "Anglet, FR", label: "Anglet, FR", code: "BIQ-11" },
	{ value: "Les Coches, FR", label: "Les Coches, FR", code: "CHX-14" },
	{
		value: "Valmorel - St Francois Longchamp, FR",
		label: "Valmorel - St Francois Longchamp, FR",
		code: "CHX-37",
	},
	{ value: "Pra Loup, FR", label: "Pra Loup, FR", code: "CHX-43" },
	{
		value: "Saint Martin de Belleville, FR",
		label: "Saint Martin de Belleville, FR",
		code: "CHX-54",
	},
	{ value: "La Clusaz, FR", label: "La Clusaz, FR", code: "CHX-71" },
	{ value: "Val Cenis, FR", label: "Val Cenis, FR", code: "CHX-85" },
	{ value: "Montgenevre, FR", label: "Montgenevre, FR", code: "CHX-96" },
	{ value: "Hyères, FR", label: "Hyères, FR", code: "HYER-6" },
	{
		value: "Villefranche Sur Saone, FR",
		label: "Villefranche Sur Saone, FR",
		code: "LYS-16",
	},
	{ value: "Meaux, FR", label: "Meaux, FR", code: "PAR-44" },
	{ value: "Concarneau, FR", label: "Concarneau, FR", code: "QUI-12" },
	{ value: "Brignoles, FR", label: "Brignoles, FR", code: "TRP-20" },
	{
		value: "La Grande Motte, FR",
		label: "La Grande Motte, FR",
		code: "TRP-27",
	},
	{
		value: "Saint Cast le Guildo, FR",
		label: "Saint Cast le Guildo, FR",
		code: "XSB-10",
	},
	{ value: "Chenonceau, FR", label: "Chenonceau, FR", code: "CHENO-6" },
	{ value: "Le Lavandou, FR", label: "Le Lavandou, FR", code: "LELAV-6" },
	{ value: "Saint Malo, FR", label: "Saint Malo, FR", code: "SMALO-6" },
	{ value: "Les Gets, FR", label: "Les Gets, FR", code: "fake-88" },
	{ value: "Vars, FR", label: "Vars, FR", code: "FAKEVars" },
	{ value: "Epernay, FR", label: "Epernay, FR", code: "PERNAY-6" },
	{ value: "Ruan, FR", label: "Ruan, FR", code: "fake-104" },
	{ value: "Ardent, FR", label: "Ardent, FR", code: "fake-311" },
	{
		value: "L&apos;Alpe du Grand Serre, FR",
		label: "L&apos;Alpe du Grand Serre, FR",
		code: "fake-317",
	},
	{ value: "Argentière, FR", label: "Argentière, FR", code: "fake-318" },
	{ value: "Châtel, FR", label: "Châtel, FR", code: "fake-321" },
	{ value: "Torcy, FR", label: "Torcy, FR", code: "fake-383" },
	{ value: "Étretat, FR", label: "Étretat, FR", code: "fake-747" },
	{ value: "Chantilly, FR", label: "Chantilly, FR", code: "fake-749" },
	{ value: "Risoul, FR", label: "Risoul, FR", code: "FAKERisoul" },
	{ value: "Quiberon, FR", label: "Quiberon, FR", code: "QUIBERON-6" },
	{ value: "Avoriaz, FR", label: "Avoriaz, FR", code: "FAKEAvoriaz" },
	{
		value: "Charleville-Mézières, FR",
		label: "Charleville-Mézières, FR",
		code: "C-MEZIERES-6",
	},
	{ value: "Cauterets, FR", label: "Cauterets, FR", code: "FR2Cauterets" },
	{ value: "Luz Ardiden, FR", label: "Luz Ardiden, FR", code: "FR2LUZARDIDEN" },
	{ value: "Peyragudes , FR", label: "Peyragudes , FR", code: "FR2Peyragudes" },
	{
		value: "Piau Engaly , FR",
		label: "Piau Engaly , FR",
		code: "FAKEPiauEngaly",
	},
	{ value: "Oz , FR", label: "Oz , FR", code: "Oz" },
	{
		value: "Cherbourg-Octeville, FR",
		label: "Cherbourg-Octeville, FR",
		code: "CHB",
	},
	{ value: "Moutiers, FR", label: "Moutiers, FR", code: "MOU" },
	{ value: "Pyrenees, FR", label: "Pyrenees, FR", code: "PYR" },
	{ value: "Roanne, FR", label: "Roanne, FR", code: "ROA" },
	{ value: "Souillac, FR", label: "Souillac, FR", code: "SOU" },
	{ value: "Saint Nectaire, FR", label: "Saint Nectaire, FR", code: "CFE1" },
	{ value: "Thiers, FR", label: "Thiers, FR", code: "CFE2" },
	{
		value: "Saint-Dier-d´Auvergne, FR",
		label: "Saint-Dier-d´Auvergne, FR",
		code: "CFE3",
	},
	{
		value: "Besse et Saint Anastaise, FR",
		label: "Besse et Saint Anastaise, FR",
		code: "CFE5",
	},
	{ value: "Puy de Sancy, FR", label: "Puy de Sancy, FR", code: "CFE7" },
	{
		value: "Saint Pierre le Chastel, FR",
		label: "Saint Pierre le Chastel, FR",
		code: "CFE8",
	},
	{ value: "Ambert, FR", label: "Ambert, FR", code: "EBU1" },
	{
		value: "L&apos;Île-Rousse, FR",
		label: "L&apos;Île-Rousse, FR",
		code: "Ille",
	},
	{ value: "Montpeyroux, FR", label: "Montpeyroux, FR", code: "MPL1" },
	{ value: "Morlaix, FR", label: "Morlaix, FR", code: "MRLX" },
	{ value: "Morangis, FR", label: "Morangis, FR", code: "MRNG" },
	{ value: "Meudon, FR", label: "Meudon, FR", code: "MUDN" },
	{ value: "Versalles, FR", label: "Versalles, FR", code: "PAR2" },
	{ value: "Lascaux, FR", label: "Lascaux, FR", code: "PGX1" },
	{ value: "Puy de Dôme, FR", label: "Puy de Dôme, FR", code: "PUYD" },
	{ value: "Conques, FR", label: "Conques, FR", code: "RDZ1" },
	{ value: "Dieppe, FR", label: "Dieppe, FR", code: "URO2" },
	{ value: "Ardèche, FR", label: "Ardèche, FR", code: "ARD-1" },
	{ value: "Isla Brehat, FR", label: "Isla Brehat, FR", code: "BRE-1" },
	{ value: "Fontainebleau, FR", label: "Fontainebleau, FR", code: "FONTB" },
	{ value: "Isla Groix, FR", label: "Isla Groix, FR", code: "GRO-1" },
	{ value: "Valle del Loira, FR", label: "Valle del Loira, FR", code: "LOI-6" },
	{ value: "Lombez, FR", label: "Lombez, FR", code: "LOM-6" },
	{ value: "Sainte-Maxime, FR", label: "Sainte-Maxime, FR", code: "MAX-6" },
	{ value: "Montévrain, FR", label: "Montévrain, FR", code: "MOT-1" },
	{ value: "Nevers, FR", label: "Nevers, FR", code: "NEV-1" },
	{ value: "Paimpol, FR", label: "Paimpol, FR", code: "PAI-1" },
	{ value: "Plan-de-la-Tour, FR", label: "Plan-de-la-Tour, FR", code: "PLA-6" },
	{
		value: "Roz-sur-Couesnon, FR",
		label: "Roz-sur-Couesnon, FR",
		code: "RSC-6",
	},
	{ value: "Sainte-Anne, FR", label: "Sainte-Anne, FR", code: "SAE-2" },
	{ value: "Rosny-sous-Bois, FR", label: "Rosny-sous-Bois, FR", code: "rosny" },
	{
		value: "Montbrun-les-Bains, FR",
		label: "Montbrun-les-Bains, FR",
		code: "BAIN-6",
	},
	{ value: "Dieffenthal, FR", label: "Dieffenthal, FR", code: "DIEF-1" },
	{ value: "Épone, FR", label: "Épone, FR", code: "EPON-1" },
	{ value: "Étoges, FR", label: "Étoges, FR", code: "ETOG-1" },
	{
		value: "Alpes Franceses, FR",
		label: "Alpes Franceses, FR",
		code: "FRAL-1",
	},
	{ value: "Fécamp , FR", label: "Fécamp , FR", code: "Fecamp" },
	{ value: "La Chevalerie, FR", label: "La Chevalerie, FR", code: "LACH-1" },
	{ value: "La Ricamarie, FR", label: "La Ricamarie, FR", code: "LARI-1" },
	{ value: "La Richardais, FR", label: "La Richardais, FR", code: "LARI-6" },
	{ value: "Le Grau-du-Roi, FR", label: "Le Grau-du-Roi, FR", code: "LGDR-1" },
	{ value: "Lisieux, FR", label: "Lisieux, FR", code: "LISI-1" },
	{ value: "Locronan, FR", label: "Locronan, FR", code: "LOCR-6" },
	{ value: "Maubeuge, FR", label: "Maubeuge, FR", code: "MAUB-1" },
	{ value: "Mauriac, FR", label: "Mauriac, FR", code: "MAUR-1" },
	{
		value: "Minihy-Tréguier, FR",
		label: "Minihy-Tréguier, FR",
		code: "MITR-6",
	},
	{
		value: "Monte Saint-Michel, FR",
		label: "Monte Saint-Michel, FR",
		code: "MONT-6",
	},
	{ value: "Perros-Guirec, FR", label: "Perros-Guirec, FR", code: "PEGU-1" },
	{ value: "Pleyben, FR", label: "Pleyben, FR", code: "PLEY-6" },
	{
		value: "Plonéour-Lanvern, FR",
		label: "Plonéour-Lanvern, FR",
		code: "PLLA-1",
	},
	{
		value: "Plouezoc&apos;h, FR",
		label: "Plouezoc&apos;h, FR",
		code: "PLOU-1",
	},
	{ value: "Pontarlier, FR", label: "Pontarlier, FR", code: "PNTA-1" },
	{ value: "Poissy, FR", label: "Poissy, FR", code: "POIS-1" },
	{ value: "Pontorson, FR", label: "Pontorson, FR", code: "PONT-1" },
	{ value: "Riom, FR", label: "Riom, FR", code: "RIOM-1" },
	{
		value: "Roissy-en-France, FR",
		label: "Roissy-en-France, FR",
		code: "Roissy",
	},
	{
		value: "Sainte-Anne-d&apos;Auray, FR",
		label: "Sainte-Anne-d&apos;Auray, FR",
		code: "SAAD-1",
	},
	{ value: "Saint-Brieuc, FR", label: "Saint-Brieuc, FR", code: "SABR-1" },
	{
		value: "Saint-Bon-Tarentaise, FR",
		label: "Saint-Bon-Tarentaise, FR",
		code: "SABT-1",
	},
	{
		value: "Saumane-de-Vaucluse, FR",
		label: "Saumane-de-Vaucluse, FR",
		code: "SADV-1",
	},
	{ value: "Saint-Florent, FR", label: "Saint-Florent, FR", code: "SAFL-1" },
	{
		value: "Saint-Andéol-le-Château, FR",
		label: "Saint-Andéol-le-Château, FR",
		code: "SALC-1",
	},
	{ value: "San Martín, FR", label: "San Martín, FR", code: "SAMA-1" },
	{ value: "Saint-Paul, FR", label: "Saint-Paul, FR", code: "SAPA-1" },
	{ value: "Saint-Witz, FR", label: "Saint-Witz, FR", code: "SAWI-1" },
	{
		value: "Saintes-Maries-de-la-Mer, FR",
		label: "Saintes-Maries-de-la-Mer, FR",
		code: "SMDL-1",
	},
	{ value: "Serris, FR", label: "Serris, FR", code: "Serris" },
	{ value: "Mayotte, FR", label: "Mayotte, FR", code: "ndet-5" },
	{ value: "Alesia, FR", label: "Alesia, FR", code: "ALA-002" },
	{
		value: "Areches Beaufort, FR",
		label: "Areches Beaufort, FR",
		code: "ARCHSBF",
	},
	{
		value: "Gréoux-les-Bains, FR",
		label: "Gréoux-les-Bains, FR",
		code: "BAINS-6",
	},
	{ value: "Époisses, FR", label: "Époisses, FR", code: "EPS-002" },
	{ value: "La Gacilly, FR", label: "La Gacilly, FR", code: "GCY-002" },
	{
		value: "Saint-Genis-Pouilly, FR",
		label: "Saint-Genis-Pouilly, FR",
		code: "GENIS-6",
	},
	{ value: "Ghisonaccia, FR", label: "Ghisonaccia, FR", code: "GHISO-6" },
	{ value: "Guadeloupe, FR, FR", label: "Guadeloupe, FR, FR", code: "GUAPE-6" },
	{ value: "Joucas, FR", label: "Joucas, FR", code: "JCS-002" },
	{
		value: "Saint-Jouan-des-Guérets, FR",
		label: "Saint-Jouan-des-Guérets, FR",
		code: "JOUAN-6",
	},
	{
		value: "Moissac-Bellevue, FR",
		label: "Moissac-Bellevue, FR",
		code: "MSB-002",
	},
	{ value: "Margaux, FR", label: "Margaux, FR", code: "Margaux" },
	{
		value: "Ota (Córcega del Sur), FR",
		label: "Ota (Córcega del Sur), FR",
		code: "OTA-002",
	},
	{ value: "Puteaux, FR", label: "Puteaux, FR", code: "Puteaux" },
	{ value: "Rouvignies, FR", label: "Rouvignies, FR", code: "RGS-002" },
	{ value: "Saint-Émilion, FR", label: "Saint-Émilion, FR", code: "SANTE-6" },
	{ value: "Donchery, FR", label: "Donchery, FR", code: "DCHERY-6" },
	{
		value: "Ferney-Voltaire, FR",
		label: "Ferney-Voltaire, FR",
		code: "FERNEY-6",
	},
	{ value: "Honfleur , FR", label: "Honfleur , FR", code: "Honfleur" },
	{ value: "Monéteau , FR", label: "Monéteau , FR", code: "Moneteau" },
	{
		value: "Montigny-le-Bretonneux, FR",
		label: "Montigny-le-Bretonneux, FR",
		code: "Montigny",
	},
	{ value: "Oppède, FR", label: "Oppède, FR", code: "OPPEDE-6" },
	{ value: "Palasca, FR", label: "Palasca, FR", code: "PALASK-6" },
	{
		value: "Penta-di-Casinca, FR",
		label: "Penta-di-Casinca, FR",
		code: "PEDICA-6",
	},
	{
		value: "Prévessin-Moëns, FR",
		label: "Prévessin-Moëns, FR",
		code: "PRE-MO-6",
	},
	{
		value: "Saint-Rémy-de-Provence, FR",
		label: "Saint-Rémy-de-Provence, FR",
		code: "REMYDE-6",
	},
	{ value: "Voiron , FR", label: "Voiron , FR", code: "VOIRON-6" },
	{ value: "Amboise, FR", label: "Amboise, FR", code: "fake-789" },
	{ value: "Blois, FR", label: "Blois, FR", code: "fake-802" },
	{
		value: "Lacroix-Saint-Ouen, FR",
		label: "Lacroix-Saint-Ouen, FR",
		code: "LACROIX-6",
	},
	{ value: "Lannion, FR", label: "Lannion, FR", code: "LANNION-6" },
	{
		value: "Saint-Quentin-sur-le-Homme, FR",
		label: "Saint-Quentin-sur-le-Homme, FR",
		code: "LEHOMME-6",
	},
	{ value: "Martigues, FR", label: "Martigues, FR", code: "Martigues" },
	{
		value: "Montagnac-Montpezat, FR",
		label: "Montagnac-Montpezat, FR",
		code: "Montagnac",
	},
	{ value: "Montagnat, FR", label: "Montagnat, FR", code: "Montagnat" },
	{ value: "Montrouge , FR", label: "Montrouge , FR", code: "Montrouge" },
	{ value: "Ploemel , FR", label: "Ploemel , FR", code: "PLOEMEL-6" },
	{ value: "Pont-Audemer, FR", label: "Pont-Audemer, FR", code: "PONTMER-6" },
	{ value: "Pont-Aven, FR", label: "Pont-Aven, FR", code: "PONTVEN-6" },
	{ value: "Rolleboise , FR", label: "Rolleboise , FR", code: "ROBOISE-6" },
	{
		value: "Saint-Georges-sur-Cher, FR",
		label: "Saint-Georges-sur-Cher, FR",
		code: "SURCHER-6",
	},
	{
		value: "San Juan Pie de Puerto, FR",
		label: "San Juan Pie de Puerto, FR",
		code: "Saintjean",
	},
	{
		value: "Saint-Vaast-la-Hougue, FR",
		label: "Saint-Vaast-la-Hougue, FR",
		code: "VAHOUGE-6",
	},
	{ value: "Saint-Louis, FR", label: "Saint-Louis, FR", code: "SaintLouis" },
	{
		value: "Saint-François-Longchamp, FR",
		label: "Saint-François-Longchamp, FR",
		code: "LONGCHAMP-6",
	},
	{
		value: "Saint-Raphaël, FR",
		label: "Saint-Raphaël, FR",
		code: "SaintRaphael",
	},
	{
		value: "Petite-Forêt, FR",
		label: "Petite-Forêt, FR",
		code: "PETITFLORIT-6",
	},
	{
		value: "Châlons-en-Champagne, FR",
		label: "Châlons-en-Champagne, FR",
		code: "CH-CHAMPAGNE-6",
	},
	{
		value: "Gif-sur-Yvette, FR",
		label: "Gif-sur-Yvette, FR",
		code: "Gif-Sur-Yvette",
	},
	{
		value: "Gréoux-les-Bains, FR",
		label: "Gréoux-les-Bains, FR",
		code: "GreouxlesBains",
	},
	{
		value: "Prévessin-Moëns , FR",
		label: "Prévessin-Moëns , FR",
		code: "PrevessinMoens",
	},
	{
		value: "Montbonnot-Saint-Martin, FR",
		label: "Montbonnot-Saint-Martin, FR",
		code: "MontbonnotSaintMartin",
	},
	{ value: "Bora Bora, PF", label: "Bora Bora, PF", code: "BOB" },
	{ value: "Moorea, PF", label: "Moorea, PF", code: "MOZ" },
	{ value: "Tahití, PF", label: "Tahití, PF", code: "PPT" },
	{ value: "Papeete, PF", label: "Papeete, PF", code: "PAP-4" },
	{ value: "Raiatea, PF", label: "Raiatea, PF", code: "RTEA-6" },
	{ value: "Huahine, PF", label: "Huahine, PF", code: "HUAHI-6" },
	{ value: "Tikehau, PF", label: "Tikehau, PF", code: "TIKEH-6" },
	{ value: "Rangiroa, PF", label: "Rangiroa, PF", code: "RANGIR-6" },
	{ value: "Fakarava, PF", label: "Fakarava, PF", code: "FAKAR-6" },
	{ value: "Tahaa, PF", label: "Tahaa, PF", code: "TAHAA-6" },
	{ value: "Libreville, GA", label: "Libreville, GA", code: "GA3" },
	{ value: "Banjul, GM", label: "Banjul, GM", code: "BJL-1" },
	{ value: "Tiflis, GE", label: "Tiflis, GE", code: "TBS-1" },
	{ value: "Bordzhomi, GE", label: "Bordzhomi, GE", code: "fake-506" },
	{ value: "Kvareli, GE", label: "Kvareli, GE", code: "fake-507" },
	{ value: "Kobuleti, GE", label: "Kobuleti, GE", code: "fake-508" },
	{ value: "Kutaisi City, GE", label: "Kutaisi City, GE", code: "fake-509" },
	{ value: "Gudauri, GE", label: "Gudauri, GE", code: "fake-510" },
	{ value: "Batumi, GE", label: "Batumi, GE", code: "BUM-1" },
	{ value: "Berlin, DE", label: "Berlin, DE", code: "BER" },
	{ value: "Berlín, DE", label: "Berlín, DE", code: "BER-1" },
	{ value: "Frankfurt, DE", label: "Frankfurt, DE", code: "FRA-1" },
	{
		value: "BERLIN, DE",
		label: "BERLIN, DE",
		code: "____NEW____1464612349030",
	},
	{ value: "Munich, DE", label: "Munich, DE", code: "MUC" },
	{ value: "Bremen, DE", label: "Bremen, DE", code: "BRE" },
	{ value: "Bayreuth, DE", label: "Bayreuth, DE", code: "BYR" },
	{ value: "Cottbus, DE", label: "Cottbus, DE", code: "CBU" },
	{ value: "Dortmund, DE", label: "Dortmund, DE", code: "DOR" },
	{ value: "Eifel, DE", label: "Eifel, DE", code: "EIF" },
	{ value: "Fulda, DE", label: "Fulda, DE", code: "FUL" },
	{ value: "Ingolstadt, DE", label: "Ingolstadt, DE", code: "ING" },
	{ value: "Karlsruhe, DE", label: "Karlsruhe, DE", code: "KAR" },
	{ value: "Konstanz, DE", label: "Konstanz, DE", code: "KON" },
	{ value: "Kassel, DE", label: "Kassel, DE", code: "KSF" },
	{ value: "Koblenz, DE", label: "Koblenz, DE", code: "KZB" },
	{ value: "Lubeca, DE", label: "Lubeca, DE", code: "LUE" },
	{ value: "Münster, DE", label: "Münster, DE", code: "MNS" },
	{ value: "Núremberg, DE", label: "Núremberg, DE", code: "NUE" },
	{ value: "Passau, DE", label: "Passau, DE", code: "PAS" },
	{ value: "Maguncia, DE", label: "Maguncia, DE", code: "QMZ" },
	{ value: "Regensburg, DE", label: "Regensburg, DE", code: "REG" },
	{ value: "Rhine, DE", label: "Rhine, DE", code: "RHI" },
	{ value: "Rostock, DE", label: "Rostock, DE", code: "RLG" },
	{ value: "Sauerland, DE", label: "Sauerland, DE", code: "SAU" },
	{ value: "Tréveris, DE", label: "Tréveris, DE", code: "TRI" },
	{ value: "Ulm, DE", label: "Ulm, DE", code: "ULM" },
	{ value: "Weimar, DE", label: "Weimar, DE", code: "WEI" },
	{ value: "Wurzburgo, DE", label: "Wurzburgo, DE", code: "WUR" },
	{ value: "Magdeburgo, DE", label: "Magdeburgo, DE", code: "ZMG" },
	{ value: "Alpes Bávaros, DE", label: "Alpes Bávaros, DE", code: "ALPB" },
	{ value: "Paderborn, DE", label: "Paderborn, DE", code: "BIE-3" },
	{
		value: "Freiburg im Breisgau, DE",
		label: "Freiburg im Breisgau, DE",
		code: "QFB-1",
	},
	{ value: "Rugen Island, DE", label: "Rugen Island, DE", code: "RUG-1" },
	{ value: "Wolfsburgo, DE", label: "Wolfsburgo, DE", code: "WOL-1" },
	{ value: "Hockenheim, DE", label: "Hockenheim, DE", code: "HCK-10" },
	{ value: "Herne, DE", label: "Herne, DE", code: "fake-359" },
	{ value: "Aquisgrán, DE", label: "Aquisgrán, DE", code: "AAC" },
	{ value: "Augsburg, DE", label: "Augsburg, DE", code: "AGB" },
	{ value: "Dresde, DE", label: "Dresde, DE", code: "DRS" },
	{ value: "Düsseldorf, DE", label: "Düsseldorf, DE", code: "DUS" },
	{ value: "Erfurt, DE", label: "Erfurt, DE", code: "EFU" },
	{ value: "Essen, DE", label: "Essen, DE", code: "ESE" },
	{ value: "Memmingen, DE", label: "Memmingen, DE", code: "FMM" },
	{ value: "Füssen, DE", label: "Füssen, DE", code: "FUS" },
	{
		value: "Garmisch-Partenkirchen , DE",
		label: "Garmisch-Partenkirchen , DE",
		code: "GAR",
	},
	{ value: "Hanover, DE", label: "Hanover, DE", code: "HAJ" },
	{ value: "Hamburgo, DE", label: "Hamburgo, DE", code: "HAM" },
	{ value: "Leipzig, DE", label: "Leipzig, DE", code: "LEJ" },
	{ value: "Mannheim, DE", label: "Mannheim, DE", code: "MHG" },
	{ value: "Sarrebruck, DE", label: "Sarrebruck, DE", code: "SAB" },
	{ value: "Stuttgart, DE", label: "Stuttgart, DE", code: "STR" },
	{ value: "Wittenberg, DE", label: "Wittenberg, DE", code: "WIT" },
	{ value: "Baden-Baden, DE", label: "Baden-Baden, DE", code: "ZCC" },
	{
		value: "Garmisch- Partenkirchen, DE",
		label: "Garmisch- Partenkirchen, DE",
		code: "GAR1",
	},
	{ value: "Göttingen, DE", label: "Göttingen, DE", code: "KSF1" },
	{ value: "Travemünde, DE", label: "Travemünde, DE", code: "LUE1" },
	{ value: "Osnabrük, DE", label: "Osnabrük, DE", code: "MSN4" },
	{ value: "Potsdam, DE", label: "Potsdam, DE", code: "BER-3" },
	{ value: "Colonia, DE", label: "Colonia, DE", code: "CGN-1" },
	{ value: "Bonn, DE", label: "Bonn, DE", code: "CGN-2" },
	{ value: "Oberhausen, DE", label: "Oberhausen, DE", code: "ESE-2" },
	{ value: "Heidelberg, DE", label: "Heidelberg, DE", code: "HEI-1" },
	{ value: "Leverkusen, DE", label: "Leverkusen, DE", code: "LEV-1" },
	{
		value: "Schoneck Vogtland, DE",
		label: "Schoneck Vogtland, DE",
		code: "SCH-1",
	},
	{ value: "Fehmarn, DE", label: "Fehmarn, DE", code: "FHM-12" },
	{ value: "Emden, DE", label: "Emden, DE", code: "fake-577" },
	{ value: "Zirndorf, DE", label: "Zirndorf, DE", code: "520" },
	{ value: "Bielefeld, DE", label: "Bielefeld, DE", code: "BIE" },
	{ value: "Chemnitz, DE", label: "Chemnitz, DE", code: "CHM" },
	{ value: "Dessau, DE", label: "Dessau, DE", code: "DSS" },
	{ value: "Duisburg, DE", label: "Duisburg, DE", code: "DUI" },
	{ value: "Friedrichshafen, DE", label: "Friedrichshafen, DE", code: "FDH" },
	{ value: "Rheinsberg, DE", label: "Rheinsberg, DE", code: "FNB" },
	{ value: "Espira, DE", label: "Espira, DE", code: "HEI" },
	{ value: "Kiel, DE", label: "Kiel, DE", code: "KIE" },
	{ value: "Kaiserlautern, DE", label: "Kaiserlautern, DE", code: "KLT" },
	{ value: "Lindau, DE", label: "Lindau, DE", code: "LID" },
	{ value: "Lüneburg, DE", label: "Lüneburg, DE", code: "LNE" },
	{ value: "Marburg, DE", label: "Marburg, DE", code: "MBG" },
	{ value: "Cuxhaven, DE", label: "Cuxhaven, DE", code: "NDZ" },
	{ value: "Aalen, DE", label: "Aalen, DE", code: "NOD" },
	{ value: "Offenburg, DE", label: "Offenburg, DE", code: "OFF" },
	{ value: "Pforzheim, DE", label: "Pforzheim, DE", code: "PFZ" },
	{ value: "Plauen, DE", label: "Plauen, DE", code: "PLU" },
	{ value: "Husum, DE", label: "Husum, DE", code: "QHU" },
	{ value: "Rosenheim, DE", label: "Rosenheim, DE", code: "RSH" },
	{ value: "Siegen, DE", label: "Siegen, DE", code: "SIE" },
	{ value: "Stralsund, DE", label: "Stralsund, DE", code: "SLS" },
	{ value: "Weserbergland, DE", label: "Weserbergland, DE", code: "SOD" },
	{ value: "Schwerin, DE", label: "Schwerin, DE", code: "WER" },
	{ value: "Winterberg, DE", label: "Winterberg, DE", code: "WNT" },
	{ value: "Wismar, DE", label: "Wismar, DE", code: "WSR" },
	{ value: "Wilhelmshaven, DE", label: "Wilhelmshaven, DE", code: "WVN" },
	{ value: "Greifswald, DE", label: "Greifswald, DE", code: "ZGW" },
	{ value: "Jena, DE", label: "Jena, DE", code: "ZJS" },
	{ value: "Bad Salzuflen, DE", label: "Bad Salzuflen, DE", code: "BIE-4" },
	{ value: "Bremerhaven, DE", label: "Bremerhaven, DE", code: "BRE-4" },
	{ value: "Hof, DE", label: "Hof, DE", code: "BYR-3" },
	{
		value: "Bergisch Gladbach, DE",
		label: "Bergisch Gladbach, DE",
		code: "CGN-4",
	},
	{ value: "Euskirchen, DE", label: "Euskirchen, DE", code: "CGN-6" },
	{ value: "Zwickau, DE", label: "Zwickau, DE", code: "CHM-3" },
	{ value: "Soest, DE", label: "Soest, DE", code: "DOR-1" },
	{ value: "Bochum, DE", label: "Bochum, DE", code: "DOR-3" },
	{ value: "Bautzen, DE", label: "Bautzen, DE", code: "DRS-4" },
	{ value: "Moers, DE", label: "Moers, DE", code: "DUI-3" },
	{ value: "Wesel, DE", label: "Wesel, DE", code: "DUI-4" },
	{ value: "Rüsselsheim, DE", label: "Rüsselsheim, DE", code: "DUI-5" },
	{ value: "Nordhausen, DE", label: "Nordhausen, DE", code: "DUS-1" },
	{ value: "Mettmann, DE", label: "Mettmann, DE", code: "DUS-9" },
	{ value: "Eisenach, DE", label: "Eisenach, DE", code: "EFU-2" },
	{ value: "Gotha, DE", label: "Gotha, DE", code: "EFU-3" },
	{ value: "Suhl, DE", label: "Suhl, DE", code: "EFU-4" },
	{ value: "Bottrop, DE", label: "Bottrop, DE", code: "ESE-4" },
	{ value: "Recklinghausen, DE", label: "Recklinghausen, DE", code: "ESE-6" },
	{ value: "Neubrandenburg, DE", label: "Neubrandenburg, DE", code: "FNB-1" },
	{
		value: "Offenbach del Meno, DE",
		label: "Offenbach del Meno, DE",
		code: "FRA-8",
	},
	{ value: "Kempten, DE", label: "Kempten, DE", code: "FUS-5" },
	{ value: "Graal-Muritz, DE", label: "Graal-Muritz, DE", code: "GRA-1" },
	{ value: "Celle, DE", label: "Celle, DE", code: "HAJ-4" },
	{ value: "Itzehoe, DE", label: "Itzehoe, DE", code: "HAM-4" },
	{ value: "Heilbronn, DE", label: "Heilbronn, DE", code: "HEI-6" },
	{ value: "Ettlingen, DE", label: "Ettlingen, DE", code: "KAR-4" },
	{ value: "Plön, DE", label: "Plön, DE", code: "KIE-3" },
	{ value: "Singen, DE", label: "Singen, DE", code: "KON-3" },
	{ value: "Frankenthal, DE", label: "Frankenthal, DE", code: "MHG-4" },
	{ value: "Erlangen, DE", label: "Erlangen, DE", code: "NUE-2" },
	{ value: "Fürth, DE", label: "Fürth, DE", code: "NUE-3" },
	{ value: "Puttgarden, DE", label: "Puttgarden, DE", code: "PTG-2" },
	{ value: "Filderstadt, DE", label: "Filderstadt, DE", code: "STR-4" },
	{ value: "Böblingen, DE", label: "Böblingen, DE", code: "STR-6" },
	{ value: "Tubinga, DE", label: "Tubinga, DE", code: "TBG-2" },
	{ value: "Bitburg, DE", label: "Bitburg, DE", code: "TRI-2" },
	{ value: "Usedom, DE", label: "Usedom, DE", code: "UDM-1" },
	{ value: "Wolfenbüttel, DE", label: "Wolfenbüttel, DE", code: "WOL-3" },
	{ value: "Schweinfurt, DE", label: "Schweinfurt, DE", code: "WUR-3" },
	{ value: "Pankow, DE", label: "Pankow, DE", code: "BER-21" },
	{ value: "Wedding, DE", label: "Wedding, DE", code: "BER-33" },
	{ value: "Krefeld, DE", label: "Krefeld, DE", code: "DUS-10" },
	{ value: "Maintal, DE", label: "Maintal, DE", code: "FRA-24" },
	{ value: "Halle, DE", label: "Halle, DE", code: "HAL-11" },
	{ value: "Lingen, DE", label: "Lingen, DE", code: "LIN-24" },
	{ value: "Freising, DE", label: "Freising, DE", code: "MUC-12" },
	{ value: "Starnberg, DE", label: "Starnberg, DE", code: "MUC-15" },
	{ value: "Landshut , DE", label: "Landshut , DE", code: "MUC-18" },
	{ value: "Bamberg, DE", label: "Bamberg, DE", code: "NUE-10" },
	{ value: "Ansbach, DE", label: "Ansbach, DE", code: "NUE-13" },
	{ value: "Sindelfingen, DE", label: "Sindelfingen, DE", code: "STR-13" },
	{ value: "Westerland, DE", label: "Westerland, DE", code: "WST-87" },
	{ value: "Goslar, DE", label: "Goslar, DE", code: "ZRA-10" },
	{ value: "Inzell, DE", label: "Inzell, DE", code: "inzell" },
	{
		value: "LEGOLAND® Alemania, DE",
		label: "LEGOLAND® Alemania, DE",
		code: "LEG-321",
	},
	{ value: "Tegernsee, DE", label: "Tegernsee, DE", code: "TEG-123" },
	{
		value: "Annaberg-Buchholz, DE",
		label: "Annaberg-Buchholz, DE",
		code: "fake-151",
	},
	{ value: "Hildesheim, DE", label: "Hildesheim, DE", code: "fake-358" },
	{ value: "Brunswick, DE", label: "Brunswick, DE", code: "fake-361" },
	{
		value: "Monchengladbach, DE",
		label: "Monchengladbach, DE",
		code: "fake-362",
	},
	{ value: "Gelsenkirchen, DE", label: "Gelsenkirchen, DE", code: "fake-363" },
	{ value: "Wiesbaden, DE", label: "Wiesbaden, DE", code: "fake-364" },
	{
		value: "Villingen-Schwenningen, DE",
		label: "Villingen-Schwenningen, DE",
		code: "fake-365",
	},
	{ value: "Flensburgo, DE", label: "Flensburgo, DE", code: "fake-366" },
	{ value: "Hanau, DE", label: "Hanau, DE", code: "fake-367" },
	{ value: "Luisburgo, DE", label: "Luisburgo, DE", code: "fake-368" },
	{ value: "Ratingen, DE", label: "Ratingen, DE", code: "fake-370" },
	{ value: "Gutersloh, DE", label: "Gutersloh, DE", code: "fake-372" },
	{ value: "Gera, DE", label: "Gera, DE", code: "fake-374" },
	{ value: "Worms, DE", label: "Worms, DE", code: "fake-375" },
	{ value: "Neumünster, DE", label: "Neumünster, DE", code: "fake-376" },
	{
		value: "Castrop-Rauxel, DE",
		label: "Castrop-Rauxel, DE",
		code: "fake-377",
	},
	{
		value: "Arnsberg (Estado), DE",
		label: "Arnsberg (Estado), DE",
		code: "fake-378",
	},
	{ value: "Gladbeck, DE", label: "Gladbeck, DE", code: "fake-380" },
	{ value: "Viersen, DE", label: "Viersen, DE", code: "fake-381" },
	{ value: "Rheine, DE", label: "Rheine, DE", code: "fake-382" },
	{
		value: "Brandenburg Ciudad , DE",
		label: "Brandenburg Ciudad , DE",
		code: "fake-385",
	},
	{ value: "Detmold, DE", label: "Detmold, DE", code: "fake-386" },
	{ value: "Norderstedt, DE", label: "Norderstedt, DE", code: "fake-387" },
	{ value: "Giessen, DE", label: "Giessen, DE", code: "fake-388" },
	{ value: "Unna, DE", label: "Unna, DE", code: "fake-390" },
	{ value: "Aschaffenburg, DE", label: "Aschaffenburg, DE", code: "fake-391" },
	{ value: "Herten, DE", label: "Herten, DE", code: "fake-392" },
	{ value: "Neuwied, DE", label: "Neuwied, DE", code: "fake-393" },
	{ value: "Lippstadt, DE", label: "Lippstadt, DE", code: "fake-394" },
	{ value: "Göppingen, DE", label: "Göppingen, DE", code: "fake-395" },
	{ value: "Bad Homburg, DE", label: "Bad Homburg, DE", code: "fake-396" },
	{ value: "Eschweiler, DE", label: "Eschweiler, DE", code: "fake-397" },
	{ value: "Gummersbach, DE", label: "Gummersbach, DE", code: "fake-398" },
	{
		value: "Heidenheim an der Brenz, DE",
		label: "Heidenheim an der Brenz, DE",
		code: "fake-399",
	},
	{ value: "Langenhagen, DE", label: "Langenhagen, DE", code: "fake-400" },
	{ value: "Wetzlar, DE", label: "Wetzlar, DE", code: "fake-401" },
	{ value: "Hoyerswerda, DE", label: "Hoyerswerda, DE", code: "fake-402" },
	{
		value: "Bad Oeynhausen, DE",
		label: "Bad Oeynhausen, DE",
		code: "fake-403",
	},
	{ value: "Peine, DE", label: "Peine, DE", code: "fake-404" },
	{ value: "Frechen, DE", label: "Frechen, DE", code: "fake-405" },
	{ value: "Rastatt, DE", label: "Rastatt, DE", code: "fake-406" },
	{ value: "Pirmasens, DE", label: "Pirmasens, DE", code: "fake-407" },
	{ value: "Bad Kreuznach, DE", label: "Bad Kreuznach, DE", code: "fake-408" },
	{ value: "Bruhl, DE", label: "Bruhl, DE", code: "fake-409" },
	{ value: "Amberg, DE", label: "Amberg, DE", code: "fake-410" },
	{ value: "Aurich, DE", label: "Aurich, DE", code: "fake-411" },
	{ value: "Coburgo, DE", label: "Coburgo, DE", code: "fake-412" },
	{ value: "Fellbach, DE", label: "Fellbach, DE", code: "fake-413" },
	{ value: "Weinheim, DE", label: "Weinheim, DE", code: "fake-414" },
	{ value: "Monheim, DE", label: "Monheim, DE", code: "fake-415" },
	{ value: "Oberursel, DE", label: "Oberursel, DE", code: "fake-416" },
	{ value: "Bruchsal, DE", label: "Bruchsal, DE", code: "fake-417" },
	{ value: "Rotemburgo, DE", label: "Rotemburgo, DE", code: "fake-418" },
	{ value: "Kaufbeuren, DE", label: "Kaufbeuren, DE", code: "fake-419" },
	{ value: "Dreieich, DE", label: "Dreieich, DE", code: "fake-420" },
	{ value: "Dachau, DE", label: "Dachau, DE", code: "fake-421" },
	{ value: "Nuertingen, DE", label: "Nuertingen, DE", code: "fake-422" },
	{ value: "Kirchheim, DE", label: "Kirchheim, DE", code: "fake-423" },
	{ value: "Kerpen, DE", label: "Kerpen, DE", code: "fake-633" },
	{ value: "Weidenbach, DE", label: "Weidenbach, DE", code: "weidenbach" },
	{
		value: "Hinterzarten, DE",
		label: "Hinterzarten, DE",
		code: "hinterzarten",
	},
	{ value: "Mosel, DE", label: "Mosel, DE", code: "MOS" },
	{ value: "Rottweil, DE", label: "Rottweil, DE", code: "ROT" },
	{
		value: "Giessen (Province), DE",
		label: "Giessen (Province), DE",
		code: "GSSN",
	},
	{ value: "Gunzburgo, DE", label: "Gunzburgo, DE", code: "Guia" },
	{
		value: "Kassel (Provincia), DE",
		label: "Kassel (Provincia), DE",
		code: "KSSL",
	},
	{ value: "Baviera, DE", label: "Baviera, DE", code: "MUC3" },
	{ value: "Selva Negra, DE", label: "Selva Negra, DE", code: "SELV" },
	{
		value: "Baden-Wurtemberg, DE",
		label: "Baden-Wurtemberg, DE",
		code: "STR3",
	},
	{ value: "Berlín (Estado), DE", label: "Berlín (Estado), DE", code: "BER11" },
	{ value: "Brandeburgo, DE", label: "Brandeburgo, DE", code: "BER13" },
	{ value: "Darmstadt, DE", label: "Darmstadt, DE", code: "DAM-1" },
	{
		value: "Detmold (Provincia), DE",
		label: "Detmold (Provincia), DE",
		code: "DET-6",
	},
	{ value: "Wuppertal, DE", label: "Wuppertal, DE", code: "DUS-5" },
	{ value: "Remscheid, DE", label: "Remscheid, DE", code: "DUS-6" },
	{ value: "Erding, DE", label: "Erding, DE", code: "ERD-1" },
	{ value: "Darmstadt, DE", label: "Darmstadt, DE", code: "FRA11" },
	{ value: "Lübben, DE", label: "Lübben, DE", code: "LUB-1" },
	{ value: "Meersburg, DE", label: "Meersburg, DE", code: "MEE-6" },
	{ value: "Merseburg, DE", label: "Merseburg, DE", code: "MRSBG" },
	{ value: "Muhldorf, DE", label: "Muhldorf, DE", code: "MUH-1" },
	{ value: "Neuss, DE", label: "Neuss, DE", code: "NEU-1" },
	{ value: "Rust, DE", label: "Rust, DE", code: "RUS-1" },
	{ value: "Rhein-Hunsrück, DE", label: "Rhein-Hunsrück, DE", code: "Rhein" },
	{ value: "Arnsberg , DE", label: "Arnsberg , DE", code: "ARNS-6" },
	{
		value: "Bremen (Estado), DE",
		label: "Bremen (Estado), DE",
		code: "BRST-1",
	},
	{ value: "Chorin, DE", label: "Chorin, DE", code: "CHOR-1" },
	{ value: "Cochem, DE", label: "Cochem, DE", code: "COCH-1" },
	{
		value: "Düsseldorf (Provincia), DE",
		label: "Düsseldorf (Provincia), DE",
		code: "DUSS-6",
	},
	{
		value: "Friburgo (Provincia), DE",
		label: "Friburgo (Provincia), DE",
		code: "FREP-1",
	},
	{
		value: "Fürstenfeldbruck, DE",
		label: "Fürstenfeldbruck, DE",
		code: "FURS-1",
	},
	{ value: "Hagen, DE", label: "Hagen, DE", code: "HAG-22" },
	{ value: "Hamm, DE", label: "Hamm, DE", code: "HAM-88" },
	{ value: "Hesse, DE", label: "Hesse, DE", code: "HESS-1" },
	{
		value: "Karlsruhe (Provincia), DE",
		label: "Karlsruhe (Provincia), DE",
		code: "KARP-1",
	},
	{
		value: "Colongne (Provincia), DE",
		label: "Colongne (Provincia), DE",
		code: "KOLN-6",
	},
	{
		value: "Ludwigshafen am Rhein, DE",
		label: "Ludwigshafen am Rhein, DE",
		code: "LAR-21",
	},
	{ value: "Lorrach, DE", label: "Lorrach, DE", code: "LORR-1" },
	{ value: "Baja Sajonia, DE", label: "Baja Sajonia, DE", code: "LOSA-1" },
	{
		value: "Mülheim an der Ruhr, DE",
		label: "Mülheim an der Ruhr, DE",
		code: "MAD-77",
	},
	{
		value: "Main-Tauber-Kreis, DE",
		label: "Main-Tauber-Kreis, DE",
		code: "MATK-1",
	},
	{ value: "Metzingen, DE", label: "Metzingen, DE", code: "METZ-6" },
	{ value: "Saarland, DE", label: "Saarland, DE", code: "SAAR-6" },
	{ value: "Sankt Wendel, DE", label: "Sankt Wendel, DE", code: "SAWE-1" },
	{ value: "Segeberg, DE", label: "Segeberg, DE", code: "SEGB-1" },
	{ value: "Stormarn, DE", label: "Stormarn, DE", code: "STOR-1" },
	{
		value: "Stuttgart (Provincia), DE",
		label: "Stuttgart (Provincia), DE",
		code: "STRP-1",
	},
	{
		value: "Tubinga (Provincia), DE",
		label: "Tubinga (Provincia), DE",
		code: "TUBP-1",
	},
	{ value: "Borkum, DE", label: "Borkum, DE", code: "BKM-002" },
	{ value: "Freudenstadt, DE", label: "Freudenstadt, DE", code: "FREUN-6" },
	{ value: "Groß-Gerau, DE", label: "Groß-Gerau, DE", code: "GROSS-6" },
	{ value: "Güstrow, DE", label: "Güstrow, DE", code: "GUSTO-6" },
	{ value: "Haldensleben, DE", label: "Haldensleben, DE", code: "HNB-002" },
	{ value: "Harburg, DE", label: "Harburg, DE", code: "Harburg" },
	{ value: "Juist, DE", label: "Juist, DE", code: "JST-002" },
	{
		value: "Main-Taunus-Kreis, DE",
		label: "Main-Taunus-Kreis, DE",
		code: "KREIS-6",
	},
	{
		value: "Limburg an der Lahn, DE",
		label: "Limburg an der Lahn, DE",
		code: "LDL-002",
	},
	{ value: "Langeoog, DE", label: "Langeoog, DE", code: "LGG-002" },
	{ value: "Baja Baviera, DE", label: "Baja Baviera, DE", code: "LWB-002" },
	{ value: "Baja Franconia, DE", label: "Baja Franconia, DE", code: "LWF-002" },
	{
		value: "Franconia Media, DE",
		label: "Franconia Media, DE",
		code: "MDF-002",
	},
	{
		value: "Minden-Lübbecke, DE",
		label: "Minden-Lübbecke, DE",
		code: "MNDNLBK",
	},
	{
		value: "Münster (Provincia), DE",
		label: "Münster (Provincia), DE",
		code: "MUSNT-6",
	},
	{ value: "Norderney, DE", label: "Norderney, DE", code: "NDY-002" },
	{ value: "Oldenburg, DE", label: "Oldenburg, DE", code: "OLD-143" },
	{ value: "Saarlouis, DE", label: "Saarlouis, DE", code: "SAARS-6" },
	{ value: "Sajonia-Anhalt, DE", label: "Sajonia-Anhalt, DE", code: "SAH-002" },
	{
		value: "Schleswig-Holstein, DE",
		label: "Schleswig-Holstein, DE",
		code: "SHN-002",
	},
	{ value: "Spiekeroog, DE", label: "Spiekeroog, DE", code: "SPG-002" },
	{ value: "Suabia, DE", label: "Suabia, DE", code: "SWB-002" },
	{ value: "Sajonia, DE", label: "Sajonia, DE", code: "SXN-002" },
	{ value: "Turingia, DE", label: "Turingia, DE", code: "THG-002" },
	{ value: "Alta Baviera, DE", label: "Alta Baviera, DE", code: "UPB-002" },
	{ value: "Alta Franconia, DE", label: "Alta Franconia, DE", code: "UPF-002" },
	{
		value: "Alto Palatinado, DE",
		label: "Alto Palatinado, DE",
		code: "UPP-002",
	},
	{ value: "Willingen, DE", label: "Willingen, DE", code: "WLG-002" },
	{
		value: "Landsberg am Lech, DE",
		label: "Landsberg am Lech, DE",
		code: "LANDLE-6",
	},
	{ value: "Quedlinburg, DE", label: "Quedlinburg, DE", code: "QEDLIN-6" },
	{ value: "Sassnitz, DE", label: "Sassnitz, DE", code: "Sassnitz" },
	{ value: "Velbert, DE", label: "Velbert, DE", code: "fake-371" },
	{ value: "Boppard, DE", label: "Boppard, DE", code: "fake-804" },
	{
		value: "Rothenburg ob der Tauber, DE",
		label: "Rothenburg ob der Tauber, DE",
		code: "fake-832",
	},
	{ value: "Sankt Goar , DE", label: "Sankt Goar , DE", code: "fake-834" },
	{ value: "Groß-Gerau, DE", label: "Groß-Gerau, DE", code: "GroBGerau" },
	{
		value: "North Rhine-Westphalia, DE",
		label: "North Rhine-Westphalia, DE",
		code: "NORHINE-6",
	},
	{ value: "Traunstein, DE", label: "Traunstein, DE", code: "TRASTEN-6" },
	{
		value: "Mecklenburg-Vorpommern, DE",
		label: "Mecklenburg-Vorpommern, DE",
		code: "MECKBURG-6",
	},
	{ value: "Hoppegarten, DE", label: "Hoppegarten, DE", code: "Hoppegarten" },
	{
		value: "Rhineland-Palatinate, DE",
		label: "Rhineland-Palatinate, DE",
		code: "PALATINADO-6",
	},
	{
		value: "Distrito de Algovia Oriental, DE",
		label: "Distrito de Algovia Oriental, DE",
		code: "ALGORIENTAL-6",
	},
	{ value: "Acra, GH", label: "Acra, GH", code: "ACC" },
	{ value: "Atenas, GR", label: "Atenas, GR", code: "ATH" },
	{ value: "Creta, GR", label: "Creta, GR", code: "ATE" },
	{ value: "Miconos, GR", label: "Miconos, GR", code: "MKS" },
	{ value: "Santorini, GR", label: "Santorini, GR", code: "SAT" },
	{ value: "Samos, GR", label: "Samos, GR", code: "AEG" },
	{ value: "Corfu, GR", label: "Corfu, GR", code: "CFU" },
	{ value: "Ithaca, GR", label: "Ithaca, GR", code: "IT1" },
	{ value: "Ikaria, GR", label: "Ikaria, GR", code: "JIK" },
	{ value: "Patmos, GR", label: "Patmos, GR", code: "PTM" },
	{ value: "Rodas, GR", label: "Rodas, GR", code: "RHO" },
	{
		value: "Zante (Zakynthos), GR",
		label: "Zante (Zakynthos), GR",
		code: "ZTE",
	},
	{ value: "Rétino, GR", label: "Rétino, GR", code: "ATE-3" },
	{ value: "Agia Pelagia, GR", label: "Agia Pelagia, GR", code: "KYT-2" },
	{ value: "Nafplio, GR", label: "Nafplio, GR", code: "PEL-3" },
	{ value: "Kalamata, GR", label: "Kalamata, GR", code: "PEL-4" },
	{ value: "Esparta, GR", label: "Esparta, GR", code: "PEL-5" },
	{ value: "Poros, GR", label: "Poros, GR", code: "SAR-1" },
	{ value: "Escopelos, GR", label: "Escopelos, GR", code: "SPO-2" },
	{ value: "Thessaloniki, GR", label: "Thessaloniki, GR", code: "THS-1" },
	{ value: "Heraklion, GR", label: "Heraklion, GR", code: "ATE-10" },
	{ value: "Ioannina, GR", label: "Ioannina, GR", code: "CNG-13" },
	{ value: "Katerini, GR", label: "Katerini, GR", code: "CNG-34" },
	{ value: "Volos, GR", label: "Volos, GR", code: "CNG-43" },
	{ value: "Agrinio, GR", label: "Agrinio, GR", code: "CNG-46" },
	{ value: "Trikala, GR", label: "Trikala, GR", code: "CNG-53" },
	{ value: "Kea, GR", label: "Kea, GR", code: "CYC-18" },
	{ value: "Patras, GR", label: "Patras, GR", code: "PEL-10" },
	{ value: "Nafplio, GR", label: "Nafplio, GR", code: "PEL-31" },
	{
		value: "Tholos (Theologos), GR",
		label: "Tholos (Theologos), GR",
		code: "RHO-14",
	},
	{ value: "Paros, GR", label: "Paros, GR", code: "ARO" },
	{ value: "Milos, GR", label: "Milos, GR", code: "CYC" },
	{ value: "Folegandros, GR", label: "Folegandros, GR", code: "FOE" },
	{ value: "Kefalonia, GR", label: "Kefalonia, GR", code: "ION" },
	{ value: "Cos, GR", label: "Cos, GR", code: "KSS" },
	{ value: "Kythira (CItera), GR", label: "Kythira (CItera), GR", code: "KYT" },
	{ value: "Lemnos, GR", label: "Lemnos, GR", code: "LG1" },
	{ value: "Naxos, GR", label: "Naxos, GR", code: "NAX" },
	{ value: "Islas Sarónicas, GR", label: "Islas Sarónicas, GR", code: "SAR" },
	{ value: "Sciathos, GR", label: "Sciathos, GR", code: "SKI" },
	{ value: "Serifos, GR", label: "Serifos, GR", code: "SRF" },
	{ value: "Tesalónica, GR", label: "Tesalónica, GR", code: "THS" },
	{ value: "Chania, GR", label: "Chania, GR", code: "ATE20" },
	{ value: "Quíos, GR", label: "Quíos, GR", code: "CG1-1" },
	{ value: "Kalambaka, GR", label: "Kalambaka, GR", code: "CNG-2" },
	{ value: "kavala, GR", label: "kavala, GR", code: "CNG-4" },
	{ value: "Eubea, GR", label: "Eubea, GR", code: "CNG-5" },
	{ value: "Sifnos, GR", label: "Sifnos, GR", code: "CYC-3" },
	{ value: "Andros, GR", label: "Andros, GR", code: "CYC-4" },
	{ value: "Siros, GR", label: "Siros, GR", code: "CYC-5" },
	{ value: "Tinos, GR", label: "Tinos, GR", code: "CYC-6" },
	{ value: "Leros, GR", label: "Leros, GR", code: "DOD-1" },
	{ value: "Kálimnos, GR", label: "Kálimnos, GR", code: "DOD-4" },
	{ value: "Lesbos, GR", label: "Lesbos, GR", code: "ESV-1" },
	{ value: "Calcídica, GR", label: "Calcídica, GR", code: "HLK-1" },
	{ value: "Marmari, GR", label: "Marmari, GR", code: "KSS-5" },
	{ value: "Olympia , GR", label: "Olympia , GR", code: "PEL-2" },
	{ value: "Tolo, GR", label: "Tolo, GR", code: "PEL-6" },
	{ value: "Faliraki, GR", label: "Faliraki, GR", code: "RHO-2" },
	{ value: "Spetses, GR", label: "Spetses, GR", code: "SAR-4" },
	{ value: "SITIA, GR", label: "SITIA, GR", code: "SITIA" },
	{ value: "Scíathos, GR", label: "Scíathos, GR", code: "SKI-1" },
	{ value: "Eciros, GR", label: "Eciros, GR", code: "SPO-1" },
	{ value: "PAROS, GR", label: "PAROS, GR", code: "aro-1" },
	{ value: "Agios Nikolaos, GR", label: "Agios Nikolaos, GR", code: "ATE-24" },
	{
		value: "Naupacto (Nafpaktos), GR",
		label: "Naupacto (Nafpaktos), GR",
		code: "CNG-26",
	},
	{ value: "Parga, GR", label: "Parga, GR", code: "CNG-29" },
	{ value: "Lamia, GR", label: "Lamia, GR", code: "CNG-32" },
	{ value: "Edipsos, GR", label: "Edipsos, GR", code: "CNG-55" },
	{ value: "Sivota, GR", label: "Sivota, GR", code: "CNG-56" },
	{
		value: "Pieria (Paralia), GR",
		label: "Pieria (Paralia), GR",
		code: "CNG-57",
	},
	{
		value: "Gerakini (Yerakini), GR",
		label: "Gerakini (Yerakini), GR",
		code: "HLK-10",
	},
	{ value: "Pilos , GR", label: "Pilos , GR", code: "PEL-16" },
	{ value: "Mistra, GR", label: "Mistra, GR", code: "PEL-24" },
	{ value: "Trípoli, GR", label: "Trípoli, GR", code: "PEL-28" },
	{ value: "Arcadia, GR", label: "Arcadia, GR", code: "PEL-51" },
	{ value: "Argos, GR", label: "Argos, GR", code: "PEL-56" },
	{ value: "Lindos, GR", label: "Lindos, GR", code: "RHO-15" },
	{ value: "Pefki , GR", label: "Pefki , GR", code: "fake-102" },
	{ value: "Pefki Eubea , GR", label: "Pefki Eubea , GR", code: "fake-103" },
	{ value: "Kastelorizo, GR", label: "Kastelorizo, GR", code: "007" },
	{ value: "Kárpatos, GR", label: "Kárpatos, GR", code: "AOK-1" },
	{ value: "Delfos, GR", label: "Delfos, GR", code: "CNG-1" },
	{ value: "El Pireo, GR", label: "El Pireo, GR", code: "PRS-2" },
	{ value: "Kalithea, GR", label: "Kalithea, GR", code: "RHO-8" },
	{ value: "Fira, GR", label: "Fira, GR", code: "SAT-2" },
	{ value: "Kamari, GR", label: "Kamari, GR", code: "SAT-6" },
	{ value: "Tsilivi, GR", label: "Tsilivi, GR", code: "ZTE-8" },
	{ value: "Quersoneso, GR", label: "Quersoneso, GR", code: "ATE-36" },
	{ value: "Malia, GR", label: "Malia, GR", code: "ATE-42" },
	{ value: "Epidauro, GR", label: "Epidauro, GR", code: "EPID-6" },
	{ value: "Heraklion, GR", label: "Heraklion, GR", code: "HER-66" },
	{ value: "Metsovo, GR", label: "Metsovo, GR", code: "METS-6" },
	{ value: "Pyrgos, GR", label: "Pyrgos, GR", code: "SAT-11" },
	{ value: "Sunión, GR", label: "Sunión, GR", code: "SUNI-6" },
	{ value: "Nauplia, GR", label: "Nauplia, GR", code: "NAUPL-6" },
	{ value: "ios, GR", label: "ios, GR", code: "fake-389" },
	{ value: "Knidos, GR", label: "Knidos, GR", code: "KNS" },
	{ value: "Amorgos, GR", label: "Amorgos, GR", code: "AMRG" },
	{ value: "Gyali, GR", label: "Gyali, GR", code: "GYAL" },
	{ value: "Hydra, GR", label: "Hydra, GR", code: "HYDR" },
	{ value: "Nisyros, GR", label: "Nisyros, GR", code: "NYSR" },
	{ value: "Fócida, GR", label: "Fócida, GR", code: "PHOC" },
	{ value: "Symi, GR", label: "Symi, GR", code: "SYMI" },
	{ value: "Arkadi, GR", label: "Arkadi, GR", code: "ARK-6" },
	{ value: "Elunda, GR", label: "Elunda, GR", code: "ELO-6" },
	{ value: "Georgioupoli, GR", label: "Georgioupoli, GR", code: "GEO-6" },
	{ value: "Ialisos, GR", label: "Ialisos, GR", code: "IAL-6" },
	{ value: "Kournas, GR", label: "Kournas, GR", code: "KOU-6" },
	{ value: "Lappa, GR", label: "Lappa, GR", code: "LAP-6" },
	{ value: "Léucade, GR", label: "Léucade, GR", code: "LFD-1" },
	{ value: "Melidoni, GR", label: "Melidoni, GR", code: "MEL-6" },
	{ value: "Monemvasía, GR", label: "Monemvasía, GR", code: "MONgr" },
	{ value: "Myrtia, GR", label: "Myrtia, GR", code: "MYR-6" },
	{ value: "Petaloudes, GR", label: "Petaloudes, GR", code: "PET-6" },
	{ value: "Préveza, GR", label: "Préveza, GR", code: "PVK-1" },
	{ value: "Skafidia, GR", label: "Skafidia, GR", code: "SKF-1" },
	{ value: "Tesprotia, GR", label: "Tesprotia, GR", code: "THG-1" },
	{ value: "Vai, GR", label: "Vai, GR", code: "VAI-6" },
	{ value: "Yerapetra, GR", label: "Yerapetra, GR", code: "YER-6" },
	{ value: "Zakro, GR", label: "Zakro, GR", code: "ZAK-6" },
	{ value: "Argiroupoli, GR", label: "Argiroupoli, GR", code: "ARGI-6" },
	{ value: "Acaya, GR", label: "Acaya, GR", code: "Achaea" },
	{ value: "Calcídica, GR", label: "Calcídica, GR", code: "CHAL-1" },
	{ value: "Gitión, GR", label: "Gitión, GR", code: "GYTH-1" },
	{ value: "Élide, GR", label: "Élide, GR", code: "ILIA-1" },
	{ value: "Kufonisia, GR", label: "Kufonisia, GR", code: "KOUF-1" },
	{ value: "Lasithi, GR", label: "Lasithi, GR", code: "LASI-1" },
	{ value: "Lipsí, GR", label: "Lipsí, GR", code: "LEIP-1" },
	{ value: "Máleme, GR", label: "Máleme, GR", code: "MALE-1" },
	{ value: "Margarites, GR", label: "Margarites, GR", code: "MARG-6" },
	{ value: "Mesenia, GR", label: "Mesenia, GR", code: "MESS-1" },
	{ value: "Monolithos, GR", label: "Monolithos, GR", code: "MONO-6" },
	{ value: "El Pireo, GR", label: "El Pireo, GR", code: "PIRA-1" },
	{ value: "Selinitsa, GR", label: "Selinitsa, GR", code: "SELI-1" },
	{ value: "Antikyra, GR", label: "Antikyra, GR", code: "ATK-002" },
	{ value: "Corinto , GR", label: "Corinto , GR", code: "Corinto" },
	{ value: "Igumenitsa , GR", label: "Igumenitsa , GR", code: "ITS-002" },
	{ value: "Elafónisos, GR", label: "Elafónisos, GR", code: "KLF-002" },
	{
		value: "Kassandra (Calcídica), GR",
		label: "Kassandra (Calcídica), GR",
		code: "KSD-002",
	},
	{ value: "Kolymbia, GR", label: "Kolymbia, GR", code: "KYA-002" },
	{ value: "Rhodes, GR", label: "Rhodes, GR", code: "RDS-002" },
	{ value: "Sitonia , GR", label: "Sitonia , GR", code: "STH-002" },
	{ value: "Tsambika, GR", label: "Tsambika, GR", code: "TBK-002" },
	{ value: "Tasos, GR", label: "Tasos, GR", code: "THS-002" },
	{ value: "Megalópolis , GR", label: "Megalópolis , GR", code: "fake-744" },
	{ value: "Agia Galini, GR", label: "Agia Galini, GR", code: "AgiaGalini" },
	{
		value: "restel hotel test, GL",
		label: "restel hotel test, GL",
		code: "fake-119",
	},
	{ value: "Ilulissat, GL", label: "Ilulissat, GL", code: "ILLU-1" },
	{ value: "Kangerlussuaq, GL", label: "Kangerlussuaq, GL", code: "KANG-1" },
	{ value: "Nuuk, GL", label: "Nuuk, GL", code: "NUUK-1" },
	{ value: "Granada, GD", label: "Granada, GD", code: "GND" },
	{ value: "Cariacoa, GD", label: "Cariacoa, GD", code: "Carriacou" },
	{
		value: "Saint George , GD",
		label: "Saint George , GD",
		code: "SaintGeorge",
	},
	{ value: "Pointe a Pitre, GP", label: "Pointe a Pitre, GP", code: "PAP-42" },
	{ value: "Guam, GU", label: "Guam, GU", code: "NRV-1" },
	{
		value: "Ciudad de Guatemala, GT",
		label: "Ciudad de Guatemala, GT",
		code: "MGG",
	},
	{ value: "Lago Atitlan, GT", label: "Lago Atitlan, GT", code: "ATI" },
	{ value: "Tikal, GT", label: "Tikal, GT", code: "FRS" },
	{
		value: "Antigua Guatemala, GT",
		label: "Antigua Guatemala, GT",
		code: "TKM",
	},
	{
		value: "Chichicastenango, GT",
		label: "Chichicastenango, GT",
		code: "HIC-3",
	},
	{ value: "Izabal, GT", label: "Izabal, GT", code: "IZA-3" },
	{ value: "Flores GMT, GT", label: "Flores GMT, GT", code: "FLG-1" },
	{ value: "Livingston, GT", label: "Livingston, GT", code: "LVG-1" },
	{ value: "Panajachel, GT", label: "Panajachel, GT", code: "PANAJ-6" },
	{ value: "Guernsey, GG", label: "Guernsey, GG", code: "CIS-3" },
	{ value: "Conakry, GN", label: "Conakry, GN", code: "CKY" },
	{ value: "Bissau , GW", label: "Bissau , GW", code: "OXB" },
	{ value: "Islas Bijagos, GW", label: "Islas Bijagos, GW", code: "BIJAGOS-6" },
	{ value: "Georgetown, GY", label: "Georgetown, GY", code: "820" },
	{ value: "Cayena, GY", label: "Cayena, GY", code: "CAYENA-6" },
	{ value: "Puerto Príncipe, HT", label: "Puerto Príncipe, HT", code: "PAP-1" },
	{
		value: "Puerto Príncipe, HT",
		label: "Puerto Príncipe, HT",
		code: "PAP-166",
	},
	{ value: "Tegucigalpa, HN", label: "Tegucigalpa, HN", code: "TGU" },
	{ value: "Roatán, HN", label: "Roatán, HN", code: "RTB" },
	{ value: "San Pedro Sula, HN", label: "San Pedro Sula, HN", code: "SAP" },
	{ value: "Tela, HN", label: "Tela, HN", code: "TAA" },
	{ value: "Copan, HN", label: "Copan, HN", code: "TOUCOP" },
	{ value: "La Ceiba, HN", label: "La Ceiba, HN", code: "LC2-1" },
	{ value: "Hong Kong, HK", label: "Hong Kong, HK", code: "HKG" },
	{ value: "Kowloon, HK", label: "Kowloon, HK", code: "HKG-2" },
	{
		value: "Isla de Hong Kong, HK",
		label: "Isla de Hong Kong, HK",
		code: "HKG-3",
	},
	{ value: "Tsuen Wan, HK", label: "Tsuen Wan, HK", code: "TSW-1" },
	{
		value: "Distrito de las Islas, HK",
		label: "Distrito de las Islas, HK",
		code: "IDT-002",
	},
	{ value: "Wan Chai , HK", label: "Wan Chai , HK", code: "WanChai" },
	{ value: "Budapest, HU", label: "Budapest, HU", code: "BUD" },
	{ value: "Debrecen, HU", label: "Debrecen, HU", code: "DEB" },
	{ value: "Eger, HU", label: "Eger, HU", code: "EGR" },
	{ value: "Gödöllő, HU", label: "Gödöllő, HU", code: "GOD" },
	{ value: "Pécs, HU", label: "Pécs, HU", code: "PEV" },
	{ value: "Sopron, HU", label: "Sopron, HU", code: "SRO" },
	{ value: "Sárvár, HU", label: "Sárvár, HU", code: "SVR" },
	{ value: "Pecs, HU", label: "Pecs, HU", code: "BUD-1" },
	{ value: "Gyor, HU", label: "Gyor, HU", code: "GYO-1" },
	{ value: "Siófok, HU", label: "Siófok, HU", code: "HUN-1" },
	{ value: "Sarvar, HU", label: "Sarvar, HU", code: "HUN-2" },
	{ value: "Siófok, HU", label: "Siófok, HU", code: "SZK-2" },
	{ value: "Győr, HU", label: "Győr, HU", code: "GYO" },
	{ value: "Hévíz, HU", label: "Hévíz, HU", code: "HVZ" },
	{ value: "Lago Balaton, HU", label: "Lago Balaton, HU", code: "LBT" },
	{ value: "Szeged, HU", label: "Szeged, HU", code: "QZD" },
	{ value: "Szekesfehervar, HU", label: "Szekesfehervar, HU", code: "SZK" },
	{ value: "Visegrád, HU", label: "Visegrád, HU", code: "VGR" },
	{ value: "Hajdúszoboszló, HU", label: "Hajdúszoboszló, HU", code: "DEB-2" },
	{ value: "Buek, HU", label: "Buek, HU", code: "SRO-2" },
	{ value: "Esztergom, HU", label: "Esztergom, HU", code: "ESZTER-6" },
	{ value: "Mátra, HU", label: "Mátra, HU", code: "BUD3" },
	{ value: "Szilvásvárad, HU", label: "Szilvásvárad, HU", code: "BUD5" },
	{ value: "Hungría Central, HU", label: "Hungría Central, HU", code: "BUD6" },
	{ value: "Szolnok, HU", label: "Szolnok, HU", code: "BUD8" },
	{ value: "Lago Tisza, HU", label: "Lago Tisza, HU", code: "DEB1" },
	{ value: "Tokaj, HU", label: "Tokaj, HU", code: "DEB2" },
	{ value: "Bükk, HU", label: "Bükk, HU", code: "DEB3" },
	{ value: "Sárospatak, HU", label: "Sárospatak, HU", code: "DEB4" },
	{ value: "Hajdú-Bihar, HU", label: "Hajdú-Bihar, HU", code: "DEB7" },
	{
		value: "Jász-Nagykun-Szolnok, HU",
		label: "Jász-Nagykun-Szolnok, HU",
		code: "DEB8",
	},
	{ value: "Fejér, HU", label: "Fejér, HU", code: "FEJR" },
	{
		value: "Győr-Moson-Sopron, HU",
		label: "Győr-Moson-Sopron, HU",
		code: "GYOR",
	},
	{ value: "Heves, HU", label: "Heves, HU", code: "HEVS" },
	{ value: "Szentendre, HU", label: "Szentendre, HU", code: "BUD11" },
	{
		value: "Gran Llanura Meridional, HU",
		label: "Gran Llanura Meridional, HU",
		code: "DLALF",
	},
	{ value: "Heves (condado), HU", label: "Heves (condado), HU", code: "HEVES" },
	{ value: "Bács-Kiskun, HU", label: "Bács-Kiskun, HU", code: "BACS-6" },
	{ value: "Baja, HU", label: "Baja, HU", code: "BAJA-6" },
	{ value: "Bánk, HU", label: "Bánk, HU", code: "BANK-1" },
	{ value: "Békéscsaba, HU", label: "Békéscsaba, HU", code: "BEKE-6" },
	{ value: "Közép-Dunántúl, HU", label: "Közép-Dunántúl, HU", code: "CETR-1" },
	{ value: "Csongrád , HU", label: "Csongrád , HU", code: "CSON-6" },
	{ value: "Hollókő, HU", label: "Hollókő, HU", code: "HOLL-1" },
	{ value: "Kecskemét, HU", label: "Kecskemét, HU", code: "KECS-6" },
	{
		value: "Komárom-Esztergom, HU",
		label: "Komárom-Esztergom, HU",
		code: "KOES-1",
	},
	{ value: "Miskolc , HU", label: "Miskolc , HU", code: "MISK-6" },
	{ value: "Nógrád, HU", label: "Nógrád, HU", code: "NOGR-1" },
	{ value: "Somogy, HU", label: "Somogy, HU", code: "SOMO-1" },
	{
		value: "Szabolcs-Szatmár-Bereg, HU",
		label: "Szabolcs-Szatmár-Bereg, HU",
		code: "SSBE-1",
	},
	{
		value: "Transdanubio Occidental, HU",
		label: "Transdanubio Occidental, HU",
		code: "WETR-1",
	},
	{
		value: "Zemplén Montes , HU",
		label: "Zemplén Montes , HU",
		code: "ZEMO-1",
	},
	{ value: "Baranya, HU", label: "Baranya, HU", code: "BARAN-6" },
	{
		value: "Békés (Condado), HU",
		label: "Békés (Condado), HU",
		code: "BEKES-6",
	},
	{
		value: "Parque nacional Tierras Altas de Balaton, HU",
		label: "Parque nacional Tierras Altas de Balaton, HU",
		code: "BUN-002",
	},
	{ value: "Gyula, HU", label: "Gyula, HU", code: "GYULA-6" },
	{ value: "Harkány, HU", label: "Harkány, HU", code: "HARKA-6" },
	{ value: "Kalocsa, HU", label: "Kalocsa, HU", code: "KALOC-6" },
	{ value: "Koszeg, HU", label: "Koszeg, HU", code: "KSG-002" },
	{ value: "Keszthely, HU", label: "Keszthely, HU", code: "KTH-002" },
	{ value: "Mohács, HU", label: "Mohács, HU", code: "MOHAC-6" },
	{
		value: "Lago Neusiedl HU, HU",
		label: "Lago Neusiedl HU, HU",
		code: "NEUHU-1",
	},
	{
		value: "Gran Llanura Septentrional, HU",
		label: "Gran Llanura Septentrional, HU",
		code: "NGP-002",
	},
	{
		value: "Hungría Septentrional, HU",
		label: "Hungría Septentrional, HU",
		code: "NHG-002",
	},
	{ value: "Szombathely, HU", label: "Szombathely, HU", code: "SBH-002" },
	{
		value: "Tolna (Condado), HU",
		label: "Tolna (Condado), HU",
		code: "TLC-002",
	},
	{
		value: "Lago de Velence, HU",
		label: "Lago de Velence, HU",
		code: "VELEN-6",
	},
	{ value: "Villany, HU", label: "Villany, HU", code: "VLY-002" },
	{
		value: "Veszprém (Condado), HU",
		label: "Veszprém (Condado), HU",
		code: "VPC-002",
	},
	{ value: "Vas, HU", label: "Vas, HU", code: "VSC-002" },
	{ value: "Veszprém, HU", label: "Veszprém, HU", code: "VZM-002" },
	{ value: "Zalakaros, HU", label: "Zalakaros, HU", code: "ZKR-002" },
	{ value: "Zala , HU", label: "Zala , HU", code: "ZLC-002" },
	{ value: "Zalaegerszeg, HU", label: "Zalaegerszeg, HU", code: "ZLG-002" },
	{
		value: "Borsod-Abaúj-Zemplén, HU",
		label: "Borsod-Abaúj-Zemplén, HU",
		code: "BORSOD-6",
	},
	{ value: "Reykjavik, IS", label: "Reykjavik, IS", code: "REK" },
	{ value: "South Iceland, IS", label: "South Iceland, IS", code: "3" },
	{ value: "Höfn, IS", label: "Höfn, IS", code: "HFJ" },
	{ value: "Hvammstangi, IS", label: "Hvammstangi, IS", code: "ICD" },
	{ value: "Patreksfjordur, IS", label: "Patreksfjordur, IS", code: "WTF" },
	{ value: "Blonduos, IS", label: "Blonduos, IS", code: "ICD-3" },
	{ value: "Hella, IS", label: "Hella, IS", code: "OUT-3" },
	{ value: "Hveragerði, IS", label: "Hveragerði, IS", code: "OUT-4" },
	{ value: "Laugarvatn, IS", label: "Laugarvatn, IS", code: "OUT-8" },
	{ value: "Borgarnes, IS", label: "Borgarnes, IS", code: "WSI-1" },
	{ value: "South Iceland, IS", label: "South Iceland, IS", code: "Fake-123" },
	{ value: "Akureyri , IS", label: "Akureyri , IS", code: "ICD-6" },
	{ value: "Reykjahlíð, IS", label: "Reykjahlíð, IS", code: "RIK-2" },
	{ value: "Isafjordur, IS", label: "Isafjordur, IS", code: "WTF-2" },
	{ value: "Selfoss, IS", label: "Selfoss, IS", code: "OUT" },
	{ value: "Borgarnes, IS", label: "Borgarnes, IS", code: "WSI" },
	{ value: "Egilsstadir, IS", label: "Egilsstadir, IS", code: "HFJ-2" },
	{ value: "Faskryasfjoraur, IS", label: "Faskryasfjoraur, IS", code: "HFJ-3" },
	{ value: "Husavick , IS", label: "Husavick , IS", code: "ICD-5" },
	{ value: "Reykjanes, IS", label: "Reykjanes, IS", code: "IS3-1" },
	{ value: "Garður, IS", label: "Garður, IS", code: "IS3-2" },
	{ value: "Laugarbakki, IS", label: "Laugarbakki, IS", code: "LGR-1" },
	{ value: "Laugar, IS", label: "Laugar, IS", code: "LGR-6" },
	{ value: "Fludir, IS", label: "Fludir, IS", code: "OUT-2" },
	{ value: "Hvolsvollur, IS", label: "Hvolsvollur, IS", code: "OUT-7" },
	{ value: "Skaftafell, IS", label: "Skaftafell, IS", code: "OUT-9" },
	{ value: "Mosfellsbær, IS", label: "Mosfellsbær, IS", code: "REK-6" },
	{ value: "Hafnarfjörður, IS", label: "Hafnarfjörður, IS", code: "REK-9" },
	{ value: "Vík í Mýrdal, IS", label: "Vík í Mýrdal, IS", code: "VIM-1" },
	{ value: "Hellnar, IS", label: "Hellnar, IS", code: "WSI-9" },
	{ value: "Budardalur, IS", label: "Budardalur, IS", code: "BDAR-6" },
	{ value: "Djúpivogur, IS", label: "Djúpivogur, IS", code: "DJPV-6" },
	{ value: "Drangsnes, IS", label: "Drangsnes, IS", code: "DRNG-6" },
	{ value: "Hestheimar, IS", label: "Hestheimar, IS", code: "HSTH-6" },
	{ value: "Geysir, IS", label: "Geysir, IS", code: "OUT-12" },
	{ value: "Vik, IS", label: "Vik, IS", code: "OUT-14" },
	{ value: "Vestmannaeyjar, IS", label: "Vestmannaeyjar, IS", code: "VSTM-6" },
	{ value: "Breiddalsvik, IS", label: "Breiddalsvik, IS", code: "BREID-6" },
	{
		value: "Hallormsstadhaskogur, IS",
		label: "Hallormsstadhaskogur, IS",
		code: "HLLRM-6",
	},
	{ value: "Reyholt, IS", label: "Reyholt, IS", code: "REYKH-6" },
	{ value: "Reydarfjordur, IS", label: "Reydarfjordur, IS", code: "RYDRF-6" },
	{ value: "Stykkishólmur, IS", label: "Stykkishólmur, IS", code: "STYKK-6" },
	{ value: "Seydisfjourdur, IS", label: "Seydisfjourdur, IS", code: "SYDSF-6" },
	{ value: "Snæfellsbær, IS", label: "Snæfellsbær, IS", code: "Snae" },
	{ value: "Grindavík, IS", label: "Grindavík, IS", code: "GRK-1" },
	{ value: "Olfushreppur, IS", label: "Olfushreppur, IS", code: "OLF-1" },
	{ value: "Reykjanesbær, IS", label: "Reykjanesbær, IS", code: "REY-1" },
	{
		value: "Kirkjubæjarklaustur, IS",
		label: "Kirkjubæjarklaustur, IS",
		code: "ASDF-6",
	},
	{ value: " Árborg , IS", label: " Árborg , IS", code: "Arborg" },
	{
		value: "Eyjafjarðarsveit, IS",
		label: "Eyjafjarðarsveit, IS",
		code: "EYJA-1",
	},
	{ value: "Mýrdalshreppur, IS", label: "Mýrdalshreppur, IS", code: "MYRD-1" },
	{
		value: "Rangárvallahreppur, IS",
		label: "Rangárvallahreppur, IS",
		code: "RANG-1",
	},
	{ value: "Seyðisfjörður, IS", label: "Seyðisfjörður, IS", code: "SEYD-1" },
	{ value: "Siglufjörður, IS", label: "Siglufjörður, IS", code: "2558878" },
	{ value: "Hofsós, IS", label: "Hofsós, IS", code: "5454481" },
	{ value: "Sandgerði, IS", label: "Sandgerði, IS", code: "SANDG-6" },
	{ value: "Dalvík, IS", label: "Dalvík, IS", code: "44841325" },
	{ value: "Ólafsvík, IS", label: "Ólafsvík, IS", code: "45488141" },
	{ value: "Hólmavík, IS", label: "Hólmavík, IS", code: "45884412" },
	{ value: "Grímsey, IS", label: "Grímsey, IS", code: "48845458" },
	{ value: "Skagafjörður, IS", label: "Skagafjörður, IS", code: "BLABLA-6" },
	{ value: "Bolungarvík, IS", label: "Bolungarvík, IS", code: "448124878" },
	{ value: "Arnarstapi, IS", label: "Arnarstapi, IS", code: "588445115" },
	{ value: " Hesteyri, IS", label: " Hesteyri, IS", code: "845118484" },
	{ value: "Ísafjarðarbær, IS", label: "Ísafjarðarbær, IS", code: "BLABLAB-6" },
	{
		value: "Grundarfjörður, IS",
		label: "Grundarfjörður, IS",
		code: "4544812115",
	},
	{ value: "New Delhi, IN", label: "New Delhi, IN", code: "DEL" },
	{ value: "Bangalore, IN", label: "Bangalore, IN", code: "BLR" },
	{ value: "Mumbai (Bombay), IN", label: "Mumbai (Bombay), IN", code: "BOM" },
	{
		value: "Kolkata (Calcuta), IN",
		label: "Kolkata (Calcuta), IN",
		code: "CCU",
	},
	{ value: "Goa, IN", label: "Goa, IN", code: "GOO" },
	{ value: "Hyderabad, IN", label: "Hyderabad, IN", code: "HYD" },
	{ value: "Jaipur, IN", label: "Jaipur, IN", code: "JAI" },
	{ value: "Jodhpur, IN", label: "Jodhpur, IN", code: "JDH" },
	{
		value: "Thiruvananthapuram (Trivandrum), IN",
		label: "Thiruvananthapuram (Trivandrum), IN",
		code: "TV1",
	},
	{ value: "Belur, IN", label: "Belur, IN", code: "BELU" },
	{ value: "Agra, IN", label: "Agra, IN", code: "AGR" },
	{ value: "Ahmedabad, IN", label: "Ahmedabad, IN", code: "AMD" },
	{ value: "Chennai (Madras), IN", label: "Chennai (Madras), IN", code: "CNN" },
	{ value: "Madurai, IN", label: "Madurai, IN", code: "IXM" },
	{ value: "Kanpur, IN", label: "Kanpur, IN", code: "KNU" },
	{ value: "Lucknow, IN", label: "Lucknow, IN", code: "LKO" },
	{ value: "Pune, IN", label: "Pune, IN", code: "PNQ" },
	{ value: "Vadodara, IN", label: "Vadodara, IN", code: "VAD-33" },
	{ value: "Amritsar, IN", label: "Amritsar, IN", code: "ATQ" },
	{ value: "Munnar, IN", label: "Munnar, IN", code: "AZQ" },
	{ value: "Kochi (Cochín), IN", label: "Kochi (Cochín), IN", code: "COK" },
	{ value: "Kumarakom, IN", label: "Kumarakom, IN", code: "KK1" },
	{ value: "Pondicherry, IN", label: "Pondicherry, IN", code: "PNY" },
	{ value: "Surat, IN", label: "Surat, IN", code: "STV" },
	{ value: "Udaipur, IN", label: "Udaipur, IN", code: "UDA" },
	{
		value: "Varanasi (Benarés), IN",
		label: "Varanasi (Benarés), IN",
		code: "VNS",
	},
	{
		value: "Visakhapatnam (Waltair), IN",
		label: "Visakhapatnam (Waltair), IN",
		code: "VTZ",
	},
	{ value: "Lonavala, IN", label: "Lonavala, IN", code: "LONA" },
	{ value: "Ramnagar, IN", label: "Ramnagar, IN", code: "RAMN" },
	{ value: "Calcuta, IN", label: "Calcuta, IN", code: "CCU-1" },
	{ value: "Aurangabad, IN", label: "Aurangabad, IN", code: "ACC-86" },
	{
		value: "Varanasi / Benares, IN",
		label: "Varanasi / Benares, IN",
		code: "CAS-10",
	},
	{ value: "Coimbatore, IN", label: "Coimbatore, IN", code: "COI-98" },
	{
		value: "Kozhikode (Calicut), IN",
		label: "Kozhikode (Calicut), IN",
		code: "KOZ-46",
	},
	{ value: "Leh, IN", label: "Leh, IN", code: "LEH-23" },
	{ value: "Raipur, IN", label: "Raipur, IN", code: "RAI-12" },
	{ value: "Aihole, IN", label: "Aihole, IN", code: "AIH" },
	{ value: "Allahabad, IN", label: "Allahabad, IN", code: "ALL" },
	{ value: "Alwar, IN", label: "Alwar, IN", code: "ALW" },
	{
		value: "Parque Nacional Bandipur, IN",
		label: "Parque Nacional Bandipur, IN",
		code: "BDP",
	},
	{ value: "Bikaner, IN", label: "Bikaner, IN", code: "BKB" },
	{ value: "Darjeeling, IN", label: "Darjeeling, IN", code: "DAI" },
	{ value: "Dehradun, IN", label: "Dehradun, IN", code: "DEH" },
	{ value: "Gangtok, IN", label: "Gangtok, IN", code: "GAG" },
	{ value: "Halebidu, IN", label: "Halebidu, IN", code: "HAL" },
	{ value: "Hassan, IN", label: "Hassan, IN", code: "HAS" },
	{ value: "Khajuraho, IN", label: "Khajuraho, IN", code: "HJR" },
	{ value: "Manali, IN", label: "Manali, IN", code: "IN6" },
	{ value: "Jabalpur, IN", label: "Jabalpur, IN", code: "JBP" },
	{ value: "Jhansi, IN", label: "Jhansi, IN", code: "JHA" },
	{ value: "Jorhat, IN", label: "Jorhat, IN", code: "JOR" },
	{ value: "Jaisalmer, IN", label: "Jaisalmer, IN", code: "JSA" },
	{ value: "Junagadh, IN", label: "Junagadh, IN", code: "JUN" },
	{ value: "Mawlynnong, IN", label: "Mawlynnong, IN", code: "MAW" },
	{ value: "Modhera, IN", label: "Modhera, IN", code: "MDR" },
	{ value: "Mawphlang, IN", label: "Mawphlang, IN", code: "MWP" },
	{ value: "Mysuru (Mysore), IN", label: "Mysuru (Mysore), IN", code: "MYQ" },
	{ value: "Nubra Valley, IN", label: "Nubra Valley, IN", code: "NBR" },
	{ value: "Neemrana, IN", label: "Neemrana, IN", code: "NEE" },
	{ value: "Ooty, IN", label: "Ooty, IN", code: "OOT" },
	{ value: "Osian, IN", label: "Osian, IN", code: "OSI" },
	{ value: "Pang, IN", label: "Pang, IN", code: "PNG" },
	{ value: "Kovalam, IN", label: "Kovalam, IN", code: "PQP" },
	{
		value: "Alappuzha (Alleppey), IN",
		label: "Alappuzha (Alleppey), IN",
		code: "PYP",
	},
	{ value: "Shravanabelagola, IN", label: "Shravanabelagola, IN", code: "SHR" },
	{ value: "Silchar, IN", label: "Silchar, IN", code: "SIL" },
	{ value: "Shimla, IN", label: "Shimla, IN", code: "SLV" },
	{
		value: "Somanathapura (Somanthpur), IN",
		label: "Somanathapura (Somanthpur), IN",
		code: "SMT",
	},
	{ value: "Srinagar, IN", label: "Srinagar, IN", code: "SXR" },
	{ value: "Thanjavur, IN", label: "Thanjavur, IN", code: "THA" },
	{ value: "Ujjain, IN", label: "Ujjain, IN", code: "UJJ" },
	{
		value: "Vrindavan (Brindavan), IN",
		label: "Vrindavan (Brindavan), IN",
		code: "VRI",
	},
	{ value: "Wayanad, IN", label: "Wayanad, IN", code: "WAY" },
	{ value: "Anantapur, IN", label: "Anantapur, IN", code: "ANAN" },
	{ value: "Badami, IN", label: "Badami, IN", code: "BADA" },
	{
		value: "Bengaluru (Bangalore), IN",
		label: "Bengaluru (Bangalore), IN",
		code: "BANG",
	},
	{ value: "Barli, IN", label: "Barli, IN", code: "BARL" },
	{ value: "Chandrapur, IN", label: "Chandrapur, IN", code: "CHAN" },
	{ value: "Cherrapunji, IN", label: "Cherrapunji, IN", code: "CHER" },
	{
		value: "Chettinadu (Chettinad), IN",
		label: "Chettinadu (Chettinad), IN",
		code: "CHET",
	},
	{ value: "Chittorgarh, IN", label: "Chittorgarh, IN", code: "CHIT" },
	{ value: "Lahaul, IN", label: "Lahaul, IN", code: "LAHA" },
	{ value: "Mathura, IN", label: "Mathura, IN", code: "MATH" },
	{ value: "Muttukadu, IN", label: "Muttukadu, IN", code: "MUTT" },
	{ value: "Pattadakal, IN", label: "Pattadakal, IN", code: "PATT" },
	{ value: "Samode, IN", label: "Samode, IN", code: "SAMO" },
	{ value: "Satna, IN", label: "Satna, IN", code: "SATN" },
	{ value: "Somanathapura, IN", label: "Somanathapura, IN", code: "SOMA" },
	{
		value: "Srirangapatna (Seringapatam), IN",
		label: "Srirangapatna (Seringapatam), IN",
		code: "SRIR",
	},
	{ value: "Abhaneri, IN", label: "Abhaneri, IN", code: "ABH-2" },
	{ value: "Gurgaon, IN", label: "Gurgaon, IN", code: "DEL-2" },
	{ value: "Durgapur, IN", label: "Durgapur, IN", code: "DRP-8" },
	{ value: "Fatehpur Sikri, IN", label: "Fatehpur Sikri, IN", code: "FSK-2" },
	{ value: "Mandawa, IN", label: "Mandawa, IN", code: "IN8-1" },
	{ value: "Chandigarh, IN", label: "Chandigarh, IN", code: "IXC-1" },
	{ value: "Amer, IN", label: "Amer, IN", code: "JAI-1" },
	{ value: "Kerala, IN", label: "Kerala, IN", code: "KML-6" },
	{
		value: "Thanjavur (Tanjore), IN",
		label: "Thanjavur (Tanjore), IN",
		code: "THNJV",
	},
	{ value: "Tirupati , IN", label: "Tirupati , IN", code: "TPT-1" },
	{ value: "Varkala, IN", label: "Varkala, IN", code: "TV1-2" },
	{ value: "Bagdogra, IN", label: "Bagdogra, IN", code: "BDG-87" },
	{ value: "Port Blair, IN", label: "Port Blair, IN", code: "BLA123" },
	{ value: "Gokarna, IN", label: "Gokarna, IN", code: "GKRN-6" },
	{ value: "Guwahati, IN", label: "Guwahati, IN", code: "GUW-45" },
	{ value: "Gaya, IN", label: "Gaya, IN", code: "Gay-56" },
	{ value: "Pushkar, IN", label: "Pushkar, IN", code: "IN9-63" },
	{ value: "Indore, IN", label: "Indore, IN", code: "IND-12" },
	{ value: "Imfal, IN", label: "Imfal, IN", code: "IPH-65" },
	{ value: "Jamnagar, IN", label: "Jamnagar, IN", code: "JMG-55" },
	{ value: "Jammu, IN", label: "Jammu, IN", code: "JMM-19" },
	{
		value: "Mamallapuram (Mahabalipuram), IN",
		label: "Mamallapuram (Mahabalipuram), IN",
		code: "LMA1-1",
	},
	{ value: "Patna, IN", label: "Patna, IN", code: "PTA-13" },
	{ value: "Ranchi, IN", label: "Ranchi, IN", code: "RCH-81" },
	{ value: "Rajkot, IN", label: "Rajkot, IN", code: "RJK-24" },
	{ value: "Anand, IN", label: "Anand, IN", code: "ANAND-6" },
	{ value: "Andal, IN", label: "Andal, IN", code: "ANDAL-6" },
	{ value: "Dharamsala, IN", label: "Dharamsala, IN", code: "DHARM-6" },
	{ value: "Havelock, IN", label: "Havelock, IN", code: "HAVE123" },
	{ value: "Mararikulam, IN", label: "Mararikulam, IN", code: "MARA123" },
	{ value: "Vijayawada, IN", label: "Vijayawada, IN", code: "VIJAY-6" },
	{ value: "Ranthambore, IN", label: "Ranthambore, IN", code: "RANTHA-6" },
	{ value: "Rishikesh, IN", label: "Rishikesh, IN", code: "fake-159" },
	{ value: "Ashvem Beach, IN", label: "Ashvem Beach, IN", code: "fake-708" },
	{
		value: "Puducherry (Pondicherry), IN",
		label: "Puducherry (Pondicherry), IN",
		code: "fake-759",
	},
	{ value: "Panjim , IN", label: "Panjim , IN", code: "fake-761" },
	{ value: "Bandhavgarh, IN", label: "Bandhavgarh, IN", code: "BAn" },
	{
		value: "Jaggannantha Puri, IN",
		label: "Jaggannantha Puri, IN",
		code: "BBI",
	},
	{ value: "Gir, IN", label: "Gir, IN", code: "GIR" },
	{ value: "Jim Corbett, IN", label: "Jim Corbett, IN", code: "JIM" },
	{ value: "Kanha, IN", label: "Kanha, IN", code: "KAN" },
	{ value: "Nagarhole, IN", label: "Nagarhole, IN", code: "NAG" },
	{ value: "Nileshwaram, IN", label: "Nileshwaram, IN", code: "NIL" },
	{ value: "Pachmarhi, IN", label: "Pachmarhi, IN", code: "PAC" },
	{ value: "Rohat, IN", label: "Rohat, IN", code: "RHT" },
	{ value: "Shillong, IN", label: "Shillong, IN", code: "SHI" },
	{
		value: "Shivanasamudra (Shivasamudram), IN",
		label: "Shivanasamudra (Shivasamudram), IN",
		code: "SHN",
	},
	{ value: "Ranakpur, IN", label: "Ranakpur, IN", code: "UDR" },
	{ value: "Padmanabhapuram, IN", label: "Padmanabhapuram, IN", code: "VIG" },
	{ value: "Palitana, IN", label: "Palitana, IN", code: "BHU1" },
	{ value: "Dilwara, IN", label: "Dilwara, IN", code: "DILW" },
	{ value: "Somnath, IN", label: "Somnath, IN", code: "IXK1" },
	{
		value: "Khardung La Pass, IN",
		label: "Khardung La Pass, IN",
		code: "KHDL",
	},
	{ value: "Matheran, IN", label: "Matheran, IN", code: "MAT1" },
	{ value: "Shirdi, IN", label: "Shirdi, IN", code: "SHRD" },
	{
		value: "Parque nacional de Satpura, IN",
		label: "Parque nacional de Satpura, IN",
		code: "SPTR",
	},
	{
		value: "Parque Nacional Tadoba, IN",
		label: "Parque Nacional Tadoba, IN",
		code: "TDBA",
	},
	{ value: "Vellore, IN", label: "Vellore, IN", code: "VLLR" },
	{ value: "Sanchi, IN", label: "Sanchi, IN", code: "snch" },
	{ value: "Mount Abu , IN", label: "Mount Abu , IN", code: "ABU-1" },
	{ value: "Auroville, IN", label: "Auroville, IN", code: "AUR-6" },
	{ value: "Bhubaneswar, IN", label: "Bhubaneswar, IN", code: "BBI-1" },
	{ value: "Bhavnagar, IN", label: "Bhavnagar, IN", code: "BHA-6" },
	{ value: "Bhopal, IN", label: "Bhopal, IN", code: "BHO-1" },
	{ value: "Diu, IN", label: "Diu, IN", code: "DIU-6" },
	{ value: "Gwalior, IN", label: "Gwalior, IN", code: "GWA-1" },
	{ value: "Jowai, IN", label: "Jowai, IN", code: "JOW-6" },
	{ value: "Kalimpong, IN", label: "Kalimpong, IN", code: "KAL-6" },
	{
		value: "Parque Nacional Kaziranga, IN",
		label: "Parque Nacional Kaziranga, IN",
		code: "KAZRG",
	},
	{ value: "Coorg, IN", label: "Coorg, IN", code: "KOD-6" },
	{ value: "Nagpur, IN", label: "Nagpur, IN", code: "NAG-1" },
	{ value: "Narlai, IN", label: "Narlai, IN", code: "NAR-1" },
	{ value: "Nimaaj, IN", label: "Nimaaj, IN", code: "NIM-6" },
	{ value: "Pench, IN", label: "Pench, IN", code: "PEN-1" },
	{ value: "Porbandar, IN", label: "Porbandar, IN", code: "POR-6" },
	{ value: "Periyar, IN", label: "Periyar, IN", code: "PRY-1" },
	{
		value: "Pata, Gujarat (Patan, Guyarat), IN",
		label: "Pata, Gujarat (Patan, Guyarat), IN",
		code: "Patan",
	},
	{ value: "Shilimb, IN", label: "Shilimb, IN", code: "SHI-6" },
	{ value: "Sarchu, IN", label: "Sarchu, IN", code: "SRCHR" },
	{ value: "Thekkady, IN", label: "Thekkady, IN", code: "TEK-6" },
	{
		value: "Tirunelveli (Tinnevelly), IN",
		label: "Tirunelveli (Tinnevelly), IN",
		code: "THRNV",
	},
	{ value: "Aizawl, IN", label: "Aizawl, IN", code: "AIZ-89" },
	{ value: "Bengaluru, IN", label: "Bengaluru, IN", code: "BEN-87" },
	{ value: "Bera, IN", label: "Bera, IN", code: "BERA-6" },
	{ value: "Bharatpur, IN", label: "Bharatpur, IN", code: "BHAR-6" },
	{ value: "Bhubaneswar, IN", label: "Bhubaneswar, IN", code: "BHU-98" },
	{ value: "Chikmagalur, IN", label: "Chikmagalur, IN", code: "CHIK-1" },
	{ value: "Coonoor, IN", label: "Coonoor, IN", code: "COON-1" },
	{ value: "Dibrugarh, IN", label: "Dibrugarh, IN", code: "DIBU23" },
	{ value: "Greater Noida, IN", label: "Greater Noida, IN", code: "GRNO-1" },
	{ value: "Hampi, IN", label: "Hampi, IN", code: "HAMP-1" },
	{ value: "Haridwar, IN", label: "Haridwar, IN", code: "HARI-1" },
	{ value: "Jojawar, IN", label: "Jojawar, IN", code: "JOJA-1" },
	{ value: "Kanchipuram, IN", label: "Kanchipuram, IN", code: "KANC-1" },
	{
		value: "Kanyakumari (Cabo Comorin), IN",
		label: "Kanyakumari (Cabo Comorin), IN",
		code: "KANY-1",
	},
	{ value: "Khimsar, IN", label: "Khimsar, IN", code: "KHIM-1" },
	{ value: "Kollam, IN", label: "Kollam, IN", code: "KOLL-1" },
	{ value: "Kottayam, IN", label: "Kottayam, IN", code: "KOTT-1" },
	{ value: "Kumbakonam, IN", label: "Kumbakonam, IN", code: "KUMB-1" },
	{ value: "Kumbhalgarh, IN", label: "Kumbhalgarh, IN", code: "KUMB-2" },
	{ value: "Madikeri, IN", label: "Madikeri, IN", code: "MADI-1" },
	{ value: "Maheshwar, IN", label: "Maheshwar, IN", code: "MAHE-1" },
	{
		value: "Mangaluru (Mangalore), IN",
		label: "Mangaluru (Mangalore), IN",
		code: "MNG-39",
	},
	{ value: "Orchha, IN", label: "Orchha, IN", code: "Orchha" },
	{ value: "Rewa, IN", label: "Rewa, IN", code: "REWA-6" },
	{ value: "Rajgir , IN", label: "Rajgir , IN", code: "Rajgir" },
	{
		value: "Tiruchirappalli (Trichy), IN",
		label: "Tiruchirappalli (Trichy), IN",
		code: "TRCHPL",
	},
	{ value: "Orchha, IN", label: "Orchha, IN", code: "orccha" },
	{ value: "Alsisar, IN", label: "Alsisar, IN", code: "Alsisar" },
	{ value: "Bali, IN", label: "Bali, IN", code: "BALIN-6" },
	{ value: "Bekal, IN", label: "Bekal, IN", code: "BEKAL-6" },
	{ value: "Bishangarh, IN", label: "Bishangarh, IN", code: "BGARH-6" },
	{ value: "Bodhgaya, IN", label: "Bodhgaya, IN", code: "BGAYA-6" },
	{ value: "Dimapur, IN", label: "Dimapur, IN", code: "Dimapur" },
	{ value: "Jharsuguda, IN", label: "Jharsuguda, IN", code: "JGA-002" },
	{ value: "Mahabaleshwar, IN", label: "Mahabaleshwar, IN", code: "MHB-002" },
	{ value: "Mussoorie, IN", label: "Mussoorie, IN", code: "MSE-002" },
	{ value: "Neill Island, IN", label: "Neill Island, IN", code: "NLD-002" },
	{ value: "Nalanda , IN", label: "Nalanda , IN", code: "Nalanda" },
	{
		value: "Parque Nacional Panna, IN",
		label: "Parque Nacional Panna, IN",
		code: "PANNA-6",
	},
	{ value: " Lago Pangong, IN", label: " Lago Pangong, IN", code: "PGT-002" },
	{ value: "Wayanad, IN", label: "Wayanad, IN", code: "WYD-002" },
	{ value: "Anegundi, IN", label: "Anegundi, IN", code: "ANGUDI-6" },
	{ value: "Agartala, IN", label: "Agartala, IN", code: "Agart286" },
	{ value: "Bateshwar, IN", label: "Bateshwar, IN", code: "BSHWAR-6" },
	{ value: "Chidambaram, IN", label: "Chidambaram, IN", code: "CHIRAM-6" },
	{
		value: "Tiruvannamalai (Trinomali), IN",
		label: "Tiruvannamalai (Trinomali), IN",
		code: "THRNNVML",
	},
	{ value: "Yercaud, IN", label: "Yercaud, IN", code: "YERCUD-6" },
	{ value: "Ajmer, IN", label: "Ajmer, IN", code: "fake-758" },
	{ value: "Bundi, IN", label: "Bundi, IN", code: "fake-760" },
	{ value: "Kodaikanal, IN", label: "Kodaikanal, IN", code: "KOKANAL-6" },
	{ value: "Kushinagar , IN", label: "Kushinagar , IN", code: "Kushinagar" },
	{ value: "Bali, ID", label: "Bali, ID", code: "BAI" },
	{ value: "Makassar, ID", label: "Makassar, ID", code: "ID4" },
	{ value: "Yakarta, ID", label: "Yakarta, ID", code: "JAV" },
	{ value: "Padang, ID", label: "Padang, ID", code: "PD1" },
	{ value: "Pontianak, ID", label: "Pontianak, ID", code: "PNK" },
	{ value: "Manokwari, ID", label: "Manokwari, ID", code: "ID2-3" },
	{ value: "Denpasar, ID", label: "Denpasar, ID", code: "BAI-22" },
	{ value: "Bengkulu, ID", label: "Bengkulu, ID", code: "ID7" },
	{ value: "Pangkalan Bun, ID", label: "Pangkalan Bun, ID", code: "PKN" },
	{ value: "Semarang, ID", label: "Semarang, ID", code: "SRG" },
	{ value: "Pekanbaru, ID", label: "Pekanbaru, ID", code: "PKU-1" },
	{ value: "Sur de Bali, ID", label: "Sur de Bali, ID", code: "fake-140" },
	{ value: "Bandung , ID", label: "Bandung , ID", code: "BDO" },
	{ value: "Banda Aceh, ID", label: "Banda Aceh, ID", code: "BTJ" },
	{ value: "Jambi, ID", label: "Jambi, ID", code: "DJB" },
	{ value: "Palembang, ID", label: "Palembang, ID", code: "ID6" },
	{ value: "Cirebon, ID", label: "Cirebon, ID", code: "ID8" },
	{ value: "Yogyakarta, ID", label: "Yogyakarta, ID", code: "JOG" },
	{ value: "Palu, ID", label: "Palu, ID", code: "PLW" },
	{ value: "Surabaya, ID", label: "Surabaya, ID", code: "SUB" },
	{ value: "Bandar Lampung, ID", label: "Bandar Lampung, ID", code: "TKG" },
	{ value: "Amed, Bali, ID", label: "Amed, Bali, ID", code: "BAI3" },
	{ value: "Bogor, Java, ID", label: "Bogor, Java, ID", code: "ID53" },
	{ value: "Tasikmalaya, ID", label: "Tasikmalaya, ID", code: "ID83" },
	{ value: "Solo, ID", label: "Solo, ID", code: "SOC3" },
	{ value: "Mataram , ID", label: "Mataram , ID", code: "AMI-3" },
	{ value: "Kuta, ID", label: "Kuta, ID", code: "BAI-2" },
	{ value: "Legian, ID", label: "Legian, ID", code: "BAI-3" },
	{ value: "Bekasi, Jakarta, ID", label: "Bekasi, Jakarta, ID", code: "BEK-I" },
	{ value: "Malang, ID", label: "Malang, ID", code: "MLG-1" },
	{ value: "Canggu, ID", label: "Canggu, ID", code: "BAI-20" },
	{ value: "Lombok, ID", label: "Lombok, ID", code: "AMI" },
	{ value: "Ambon, ID", label: "Ambon, ID", code: "AMQ" },
	{ value: "Banjarmasin, ID", label: "Banjarmasin, ID", code: "BDJ" },
	{ value: "Kendari, ID", label: "Kendari, ID", code: "KDI" },
	{ value: "Kupang, ID", label: "Kupang, ID", code: "KOE" },
	{ value: "Manado, ID", label: "Manado, ID", code: "MDC" },
	{ value: "Palangkaraya, ID", label: "Palangkaraya, ID", code: "PKY" },
	{ value: "Samarinda, ID", label: "Samarinda, ID", code: "SRI" },
	{ value: "Batam, ID", label: "Batam, ID", code: "BTH1" },
	{ value: "Bangka, ID", label: "Bangka, ID", code: "ID91" },
	{ value: "Gili Trawangan, ID", label: "Gili Trawangan, ID", code: "AMI-2" },
	{ value: "Senggigi, ID", label: "Senggigi, ID", code: "AMI-5" },
	{ value: "Candidasa, ID", label: "Candidasa, ID", code: "BAI-1" },
	{ value: "Sanur, Bali, ID", label: "Sanur, Bali, ID", code: "BAI-4" },
	{ value: "Tanjung Benoa, ID", label: "Tanjung Benoa, ID", code: "BAI-5" },
	{ value: "Ubud, Bali, ID", label: "Ubud, Bali, ID", code: "BAI-6" },
	{ value: "Jimbaran, ID", label: "Jimbaran, ID", code: "BAI-7" },
	{ value: "Sambirenteng, ID", label: "Sambirenteng, ID", code: "BAI-8" },
	{ value: "Nusa Dua, ID", label: "Nusa Dua, ID", code: "BAI-9" },
	{ value: "Islas Gili, ID", label: "Islas Gili, ID", code: "GIL-1" },
	{ value: "Jayapura, ID", label: "Jayapura, ID", code: "ID2-1" },
	{ value: "Lovina, Bali, ID", label: "Lovina, Bali, ID", code: "VAI-9" },
	{ value: "Pemuteran, ID", label: "Pemuteran, ID", code: "BAI-10" },
	{ value: "Seminyak, ID", label: "Seminyak, ID", code: "BAI-11" },
	{ value: "Tuban, ID", label: "Tuban, ID", code: "BAI-12" },
	{ value: "Uluwatu, ID", label: "Uluwatu, ID", code: "BAI-21" },
	{ value: "Kerobokan, ID", label: "Kerobokan, ID", code: "BAI-23" },
	{ value: "Kubu, Bali, ID", label: "Kubu, Bali, ID", code: "VAI-10" },
	{ value: "Bintan, ID", label: "Bintan, ID", code: "BINTAN-6" },
	{ value: "Labuan Bajo, ID", label: "Labuan Bajo, ID", code: "fake-137" },
	{ value: "Batu, ID", label: "Batu, ID", code: "fake-272" },
	{ value: "Nongsapura, ID", label: "Nongsapura, ID", code: "fake-748" },
	{ value: "Medan, ID", label: "Medan, ID", code: "MES" },
	{ value: "Bedugul, ID", label: "Bedugul, ID", code: "BDGL" },
	{ value: "Tanah Lot, ID", label: "Tanah Lot, ID", code: "TNLT" },
	{ value: "Sukawati, ID", label: "Sukawati, ID", code: "skwt" },
	{ value: "Batam, ID", label: "Batam, ID", code: "BAT-I" },
	{ value: "Papua, ID", label: "Papua, ID", code: "ID2-4" },
	{
		value: "Papúa Occidental, ID",
		label: "Papúa Occidental, ID",
		code: "ID2-5",
	},
	{ value: "Isla Komodo, ID", label: "Isla Komodo, ID", code: "LBJ-2" },
	{
		value: "Manggarai Occidental, ID",
		label: "Manggarai Occidental, ID",
		code: "LBJ-3",
	},
	{ value: "Raja Ampat, ID", label: "Raja Ampat, ID", code: "RJAMP" },
	{ value: "Tulamben, ID", label: "Tulamben, ID", code: "TLMBN" },
	{ value: "Kintamani, ID", label: "Kintamani, ID", code: "kntmn" },
	{
		value: "Baturiti (Province), ID",
		label: "Baturiti (Province), ID",
		code: "BATU-1",
	},
	{ value: "Nusa Lembongan, ID", label: "Nusa Lembongan, ID", code: "LMNBGN" },
	{ value: "Payangan, ID", label: "Payangan, ID", code: "PAYA-6" },
	{
		value: "Pelaga (Province), ID",
		label: "Pelaga (Province), ID",
		code: "PELA-1",
	},
	{
		value: "Penebel (Province), ID",
		label: "Penebel (Province), ID",
		code: "PENE-1",
	},
	{ value: "Sebatu, ID", label: "Sebatu, ID", code: "SEBA-6" },
	{ value: "Sleman, ID", label: "Sleman, ID", code: "Sleman" },
	{ value: "Tabanan, ID", label: "Tabanan, ID", code: "TABA-1" },
	{ value: "Taro, ID", label: "Taro, ID", code: "TARO-1" },
	{ value: "Ungasan, ID", label: "Ungasan, ID", code: "UNGA-1" },
	{ value: "Banjarangkan, ID", label: "Banjarangkan, ID", code: "BJN-002" },
	{ value: "Gobleg, ID", label: "Gobleg, ID", code: "GBG-002" },
	{ value: "Gili Air, ID", label: "Gili Air, ID", code: "GILIA-6" },
	{ value: "Gili Meno, ID", label: "Gili Meno, ID", code: "GILIM-6" },
	{ value: "Gianyar , ID", label: "Gianyar , ID", code: "Gianyar" },
	{
		value: "Provincia de las Islas Riau, ID",
		label: "Provincia de las Islas Riau, ID",
		code: "KEPRIAU",
	},
	{ value: "Nusa Penida, ID", label: "Nusa Penida, ID", code: "NAP-002" },
	{ value: "Norte de Bali, ID", label: "Norte de Bali, ID", code: "NBI-002" },
	{ value: "Pecatu, ID", label: "Pecatu, ID", code: "PCU-002" },
	{ value: "Pekutatan, ID", label: "Pekutatan, ID", code: "PKT-002" },
	{ value: "Puncak, ID", label: "Puncak, ID", code: "PUNCAK-6" },
	{
		value: "Tanjung Pinang, ID",
		label: "Tanjung Pinang, ID",
		code: "TPINANG-6",
	},
	{ value: "Ahvaz, IR", label: "Ahvaz, IR", code: "AVZ-2" },
	{ value: "Isfahan, IR", label: "Isfahan, IR", code: "IFN-2" },
	{
		value: "Mashad (Mashhad), IR",
		label: "Mashad (Mashhad), IR",
		code: "MHD-1",
	},
	{ value: "Qom, IR", label: "Qom, IR", code: "QOM-1" },
	{ value: "Shiraz, IR", label: "Shiraz, IR", code: "SYZ-1" },
	{ value: "Tabriz , IR", label: "Tabriz , IR", code: "TBZ-2" },
	{ value: "Dasht-e Kavir, IR", label: "Dasht-e Kavir, IR", code: "IRA" },
	{ value: "Kordestan, IR", label: "Kordestan, IR", code: "KUR3" },
	{
		value: "Teherán (Tehrán), IR",
		label: "Teherán (Tehrán), IR",
		code: "THR-1",
	},
	{ value: "Esfahan, IR", label: "Esfahan, IR", code: "ESF-17" },
	{ value: "Yazd, IR", label: "Yazd, IR", code: "AZD" },
	{ value: "Kermán, IR", label: "Kermán, IR", code: "KE4" },
	{ value: "Jolfa, IR", label: "Jolfa, IR", code: "JLF-6" },
	{ value: "Khoy, IR", label: "Khoy, IR", code: "KHY-6" },
	{ value: "Urmia, IR", label: "Urmia, IR", code: "URMIA" },
	{ value: "Ahvaz, IR", label: "Ahvaz, IR", code: "AHW-74" },
	{ value: "Azna, IR", label: "Azna, IR", code: "AZNA-6" },
	{
		value: "Damavand (Teherán), IR",
		label: "Damavand (Teherán), IR",
		code: "DAMV-6",
	},
	{ value: "Kelachay, IR", label: "Kelachay, IR", code: "KALA-6" },
	{ value: "Khvansar, IR", label: "Khvansar, IR", code: "KHAV-6" },
	{ value: "Kaleybar, IR", label: "Kaleybar, IR", code: "KLYB-6" },
	{ value: "Kandovan, IR", label: "Kandovan, IR", code: "KNDV-6" },
	{ value: "Desierto Mesr, IR", label: "Desierto Mesr, IR", code: "MESR-6" },
	{ value: "Maraghe, IR", label: "Maraghe, IR", code: "MRGH-6" },
	{ value: "Marand, IR", label: "Marand, IR", code: "MRND-6" },
	{ value: "Natanz, IR", label: "Natanz, IR", code: "NTNZ-6" },
	{ value: "Varzaneh, IR", label: "Varzaneh, IR", code: "VRZN-6" },
	{ value: "Aligudarz, IR", label: "Aligudarz, IR", code: "ALIGU-6" },
	{ value: "Babol, IR", label: "Babol, IR", code: "BABOL-6" },
	{ value: "Daran, IR", label: "Daran, IR", code: "DARAN-6" },
	{ value: "Dorud, IR", label: "Dorud, IR", code: "DORUD-6" },
	{ value: "Kashan, IR", label: "Kashan, IR", code: "KAS-123" },
	{ value: "Chamkhaleh, IR", label: "Chamkhaleh, IR", code: "CHAMKH-6" },
	{ value: "Jorramabad, IR", label: "Jorramabad, IR", code: "JORRAM-6" },
	{ value: "Langarud, IR", label: "Langarud, IR", code: "LANGAR-6" },
	{ value: "Rezvanshahr , IR", label: "Rezvanshahr , IR", code: "REZVAN-6" },
	{ value: "Rudbar, IR", label: "Rudbar, IR", code: "RUDBAR-6" },
	{ value: "Shahin Shahr, IR", label: "Shahin Shahr, IR", code: "SHISHA-6" },
	{ value: "Siahkal, IR", label: "Siahkal, IR", code: "SIAHKA-6" },
	{ value: "Talesh, IR", label: "Talesh, IR", code: "TALESH-6" },
	{
		value: "Bandar-e Anzali, IR",
		label: "Bandar-e Anzali, IR",
		code: "BANZALI-6",
	},
	{ value: "Golpayegan, IR", label: "Golpayegan, IR", code: "GLP" },
	{ value: "Ardestan, IR", label: "Ardestan, IR", code: "IFN" },
	{ value: "Lalejin, IR", label: "Lalejin, IR", code: "LJN" },
	{ value: " Rey, IR", label: " Rey, IR", code: "Rey" },
	{ value: "Tus, IR", label: "Tus, IR", code: "Tus" },
	{ value: "Anar, IR", label: "Anar, IR", code: "Anar" },
	{ value: "Borazjan, IR", label: "Borazjan, IR", code: "BN-I" },
	{ value: "Firuzkuh, IR", label: "Firuzkuh, IR", code: "FRKH" },
	{ value: "Karaj ( کرج ), IR", label: "Karaj ( کرج ), IR", code: "KJ-I" },
	{
		value: "Khalkhal ( خلخال ), IR",
		label: "Khalkhal ( خلخال ), IR",
		code: "KL-I",
	},
	{ value: "Kangan, IR", label: "Kangan, IR", code: "KN-I" },
	{ value: "Lavasan, IR", label: "Lavasan, IR", code: "LVSN" },
	{ value: "Masal, IR", label: "Masal, IR", code: "ML-I" },
	{ value: "Maku, Iran , IR", label: "Maku, Iran , IR", code: "Maku" },
	{ value: "Shush, IR", label: "Shush, IR", code: "SH-I" },
	{
		value: "Sareyn ( سرعین ), IR",
		label: "Sareyn ( سرعین ), IR",
		code: "SN-I",
	},
	{ value: "Tarom, IR", label: "Tarom, IR", code: "TM-I" },
	{ value: "Shahreza, IR", label: "Shahreza, IR", code: "shrz" },
	{ value: "Abadan, IR", label: "Abadan, IR", code: "ABA-I" },
	{ value: "Abadeh, IR", label: "Abadeh, IR", code: "ABD-I" },
	{ value: "Abyaneh, IR", label: "Abyaneh, IR", code: "ABY-6" },
	{ value: "Hamadán, IR", label: "Hamadán, IR", code: "ADH-1" },
	{ value: "Amol, IR", label: "Amol, IR", code: "AML-I" },
	{ value: "Azadshahr , IR", label: "Azadshahr , IR", code: "AZA-I" },
	{ value: "Abhar, IR", label: "Abhar, IR", code: "Abhar" },
	{ value: "Baft, IR", label: "Baft, IR", code: "BAF-I" },
	{ value: "Bam , IR", label: "Bam , IR", code: "BAM-I" },
	{ value: "Bastam , IR", label: "Bastam , IR", code: "BAS-I" },
	{ value: "Bandar Ganaveh, IR", label: "Bandar Ganaveh, IR", code: "BGN-I" },
	{ value: "Boshruyeh, IR", label: "Boshruyeh, IR", code: "BHH-I" },
	{ value: "Behbahan, IR", label: "Behbahan, IR", code: "BHN-I" },
	{ value: "Birjand, IR", label: "Birjand, IR", code: "BIR-I" },
	{ value: "Bojnord, IR", label: "Bojnord, IR", code: "BND-I" },
	{ value: "Baneh, IR", label: "Baneh, IR", code: "BNH-I" },
	{
		value: "Bandar-e Mahshahr, IR",
		label: "Bandar-e Mahshahr, IR",
		code: "BNM-I",
	},
	{ value: "Borujen, IR", label: "Borujen, IR", code: "BRJ-I" },
	{ value: "Bushehr, IR", label: "Bushehr, IR", code: "BSR-I" },
	{ value: "Chabahar, IR", label: "Chabahar, IR", code: "CBR-I" },
	{ value: "Darab, IR", label: "Darab, IR", code: "DAR-I" },
	{ value: "Delijan, IR", label: "Delijan, IR", code: "DLA-I" },
	{
		value: "Abdanan ( بدانان ), IR",
		label: "Abdanan ( بدانان ), IR",
		code: "DLN-I",
	},
	{ value: "Damghan, IR", label: "Damghan, IR", code: "DNG-I" },
	{ value: "Darreh Shahr, IR", label: "Darreh Shahr, IR", code: "DSR-I" },
	{ value: "Deylam, IR", label: "Deylam, IR", code: "DYM-I" },
	{ value: "Dezful, IR", label: "Dezful, IR", code: "DZF-I" },
	{ value: "Estahban, IR", label: "Estahban, IR", code: "EST-I" },
	{ value: "Eyvan, IR", label: "Eyvan, IR", code: "EYN-I" },
	{ value: "Fasa, IR", label: "Fasa, IR", code: "FAS-I" },
	{ value: "Ferdows, IR", label: "Ferdows, IR", code: "FRS-I" },
	{ value: "Fuman, IR", label: "Fuman, IR", code: "FUN-I" },
	{ value: "Firuzabad, IR", label: "Firuzabad, IR", code: "FZD-I" },
	{ value: "Gachsar, IR", label: "Gachsar, IR", code: "GCS-I" },
	{ value: "Garmeh, IR", label: "Garmeh, IR", code: "GMH-I" },
	{ value: "Gonabad, IR", label: "Gonabad, IR", code: "GNB-I" },
	{ value: "Rasht, IR", label: "Rasht, IR", code: "IGA-1" },
	{ value: "Ilam ( یلام ), IR", label: "Ilam ( یلام ), IR", code: "ILM-I" },
	{ value: "Iranshahr , IR", label: "Iranshahr , IR", code: "IRA-I" },
	{ value: "Ijrud, IR", label: "Ijrud, IR", code: "Ijrud" },
	{ value: "Jahrom, IR", label: "Jahrom, IR", code: "JAH-I" },
	{ value: "Kazerun, IR", label: "Kazerun, IR", code: "KAZ-I" },
	{ value: "Kordkuy, IR", label: "Kordkuy, IR", code: "KOR-I" },
	{ value: "Kermanshah, IR", label: "Kermanshah, IR", code: "KRE-1" },
	{ value: "Kashmar, IR", label: "Kashmar, IR", code: "KSR-I" },
	{ value: "Khash, IR", label: "Khash, IR", code: "Khash" },
	{ value: "Lar, IR", label: "Lar, IR", code: "LAR-I" },
	{ value: "Mehdishahr, IR", label: "Mehdishahr, IR", code: "MAH-I" },
	{ value: "Marivan, IR", label: "Marivan, IR", code: "MAR-I" },
	{ value: "Meshginshahr, IR", label: "Meshginshahr, IR", code: "MGR-I" },
	{ value: "Mahan, IR", label: "Mahan, IR", code: "MHN-I" },
	{ value: "Minudasht, IR", label: "Minudasht, IR", code: "MIN-I" },
	{ value: "Sarí, IR", label: "Sarí, IR", code: "MZA-1" },
	{ value: "Mahan, IR", label: "Mahan, IR", code: "Mahan" },
	{ value: "Neyshabur , IR", label: "Neyshabur , IR", code: "NIS-I" },
	{ value: "Namakabrood, IR", label: "Namakabrood, IR", code: "NMK-1" },
	{ value: "Qazvin , IR", label: "Qazvin , IR", code: "QAZ-I" },
	{ value: "Qeshm, IR", label: "Qeshm, IR", code: "QSM-I" },
	{ value: "Ramhormoz, IR", label: "Ramhormoz, IR", code: "RAM-I" },
	{ value: "Ravar, IR", label: "Ravar, IR", code: "RAV-I" },
	{ value: "Rabor, IR", label: "Rabor, IR", code: "Rabor" },
	{ value: "Sanandaj , IR", label: "Sanandaj , IR", code: "SAN-I" },
	{ value: "Sarayan, IR", label: "Sarayan, IR", code: "SAR-I" },
	{ value: "Shahr-e Kord , IR", label: "Shahr-e Kord , IR", code: "SEK-I" },
	{ value: "Shahrud, IR", label: "Shahrud, IR", code: "SHA-I" },
	{ value: "Shirvan, IR", label: "Shirvan, IR", code: "SHN-I" },
	{ value: "Shushtar, IR", label: "Shushtar, IR", code: "SHR-I" },
	{ value: "Sabzevar, IR", label: "Sabzevar, IR", code: "SZR-I" },
	{ value: "Saman, IR", label: "Saman, IR", code: "Saman" },
	{ value: "Tabas, IR", label: "Tabas, IR", code: "TAB-I" },
	{
		value: "Taleqan ( طالقان ) , IR",
		label: "Taleqan ( طالقان ) , IR",
		code: "TLN-I",
	},
	{ value: "Takab, IR", label: "Takab, IR", code: "Takab" },
	{ value: "Zabol , IR", label: "Zabol , IR", code: "Zabol" },
	{ value: "Zehak , IR", label: "Zehak , IR", code: "Zehak" },
	{
		value: "Ardebil ( اردبیل ), IR",
		label: "Ardebil ( اردبیل ), IR",
		code: "ARDE-I",
	},
	{ value: "Aradan, IR", label: "Aradan, IR", code: "Aradan" },
	{ value: "Astara, IR", label: "Astara, IR", code: "Astara" },
	{ value: "Bedreh, IR", label: "Bedreh, IR", code: "Bedreh" },
	{ value: "Chalus, IR", label: "Chalus, IR", code: "Chalus" },
	{ value: "Gorgán, IR", label: "Gorgán, IR", code: "Gorgan" },
	{ value: "Jam, IR", label: "Jam, IR", code: "JAM-IR" },
	{ value: "Jiroft , IR", label: "Jiroft , IR", code: "Jiroft" },
	{ value: "Khomein, IR", label: "Khomein, IR", code: "KHIN-I" },
	{ value: "Kuhsar, IR", label: "Kuhsar, IR", code: "Kuhsar" },
	{ value: "Mobarakeh, IR", label: "Mobarakeh, IR", code: "MBRK-6" },
	{ value: "Ramian, IR", label: "Ramian, IR", code: "Ramian" },
	{ value: "Sahneh, IR", label: "Sahneh, IR", code: "Sahneh" },
	{ value: "Sirjan , IR", label: "Sirjan , IR", code: "Sirjan" },
	{ value: "Zanyán , IR", label: "Zanyán , IR", code: "Zanjan" },
	{ value: "Zarand , IR", label: "Zarand , IR", code: "Zarand" },
	{ value: "Abarkuh, IR", label: "Abarkuh, IR", code: "ABK-002" },
	{ value: "Amol, IR", label: "Amol, IR", code: "AML-002" },
	{ value: "Abu Musa, IR", label: "Abu Musa, IR", code: "AMU-002" },
	{ value: "Arak, IR", label: "Arak, IR", code: "ARA-002" },
	{ value: "Ardakan, IR", label: "Ardakan, IR", code: "ARK-002" },
	{ value: "Bafq, IR", label: "Bafq, IR", code: "BFQ-002" },
	{ value: "Bandar Lengeh, IR", label: "Bandar Lengeh, IR", code: "BLH-002" },
	{
		value: "Bandar Torkaman, IR",
		label: "Bandar Torkaman, IR",
		code: "BTK-002",
	},
	{ value: "Asad Abad, IR", label: "Asad Abad, IR", code: "DBD-002" },
	{ value: "Famenin, IR", label: "Famenin, IR", code: "FMN-002" },
	{ value: "Garmsar, IR", label: "Garmsar, IR", code: "Garmsar" },
	{ value: "Ormuz, IR", label: "Ormuz, IR", code: "HMZ-002" },
	{ value: "Izadshahr, IR", label: "Izadshahr, IR", code: "IDS-002" },
	{ value: "Kabudarahang, IR", label: "Kabudarahang, IR", code: "KHG-002" },
	{ value: "Kis, IR", label: "Kis, IR", code: "KSH-002" },
	{ value: "Mehriz, IR", label: "Mehriz, IR", code: "MHZ-002" },
	{ value: "Mahallat, IR", label: "Mahallat, IR", code: "MLT-002" },
	{ value: "Minab, IR", label: "Minab, IR", code: "MNB-002" },
	{ value: "Meybod, IR", label: "Meybod, IR", code: "MYD-002" },
	{ value: "Malayer, IR", label: "Malayer, IR", code: "MYR-002" },
	{ value: "Mahabad, IR", label: "Mahabad, IR", code: "Mahabad" },
	{ value: "Nahavand, IR", label: "Nahavand, IR", code: "NHD-002" },
	{ value: "Naqadeh, IR", label: "Naqadeh, IR", code: "Naqadeh" },
	{ value: "Qaem Shahr, IR", label: "Qaem Shahr, IR", code: "QSR-002" },
	{ value: "Shahdad, IR", label: "Shahdad, IR", code: "SHD-002" },
	{ value: "Saveh, IR", label: "Saveh, IR", code: "SVH-002" },
	{ value: "Sepidan , IR", label: "Sepidan , IR", code: "Sepidan" },
	{ value: "Taft, IR", label: "Taft, IR", code: "TFT-002" },
	{ value: "Tuyserkan, IR", label: "Tuyserkan, IR", code: "TKN-002" },
	{ value: "Yasooj, IR", label: "Yasooj, IR", code: "YSJ-002" },
	{ value: "Zahedán, IR", label: "Zahedán, IR", code: "Zahedan" },
	{ value: "Babolsar, IR", label: "Babolsar, IR", code: "Babolsar" },
	{ value: "Behshahr, IR", label: "Behshahr, IR", code: "Behshahr" },
	{ value: "Dehloran, IR", label: "Dehloran, IR", code: "Dehloran" },
	{ value: "Galikash, IR", label: "Galikash, IR", code: "Galikash" },
	{ value: "Kangavar, IR", label: "Kangavar, IR", code: "Kangavar" },
	{ value: "Lahijan, IR", label: "Lahijan, IR", code: "LAHIJA-6" },
	{ value: "Miandoab , IR", label: "Miandoab , IR", code: "Miandoab" },
	{ value: "Nowshahr, IR", label: "Nowshahr, IR", code: "Nowshahr" },
	{ value: "Rudsar, IR", label: "Rudsar, IR", code: "RUDSAR-6" },
	{ value: "Ramsar, IR", label: "Ramsar, IR", code: "fake-774" },
	{ value: "Bandar Abbás, IR", label: "Bandar Abbás, IR", code: "fake-775" },
	{
		value: "https://es.wikipedia.org/wiki/Semnán, IR",
		label: "https://es.wikipedia.org/wiki/Semnán, IR",
		code: "fake-776",
	},
	{ value: "Abbasabad, IR", label: "Abbasabad, IR", code: "AbbasAbad" },
	{ value: "Andimeshk, IR", label: "Andimeshk, IR", code: "Andimeshk" },
	{ value: "Azadshahr, IR", label: "Azadshahr, IR", code: "Azadshahr" },
	{ value: "Qaleh Ganj, IR", label: "Qaleh Ganj, IR", code: "GhaleGanj" },
	{ value: "Mah Neshan, IR", label: "Mah Neshan, IR", code: "Mahneshan" },
	{ value: "Marvdasht, IR", label: "Marvdasht, IR", code: "Marvdasht" },
	{ value: "Rafsanjan, IR", label: "Rafsanjan, IR", code: "Rafsanjan" },
	{ value: "Tonekabon, IR", label: "Tonekabon, IR", code: "Tonekabon" },
	{ value: "Dashtestan, IR", label: "Dashtestan, IR", code: "Dashtestan" },
	{ value: "Kelardasht, IR", label: "Kelardasht, IR", code: "Kelardasht" },
	{ value: "Mahmudabad, IR", label: "Mahmudabad, IR", code: "Mahmudabad" },
	{ value: "Soltaniyeh, IR", label: "Soltaniyeh, IR", code: "Soltaniyeh" },
	{
		value: "Darreh Shahr , IR",
		label: "Darreh Shahr , IR",
		code: "DarrehShahr",
	},
	{ value: "Khodabandeh, IR", label: "Khodabandeh, IR", code: "Khodabandeh" },
	{
		value: "Shahr-e Babak, IR",
		label: "Shahr-e Babak, IR",
		code: "ShahreBabak",
	},
	{ value: "Torbat-e-Jam, IR", label: "Torbat-e-Jam, IR", code: "Torbat-eJam" },
	{
		value: "Gonbad-e Kavus, IR",
		label: "Gonbad-e Kavus, IR",
		code: "GonbadeKavus",
	},
	{ value: "Jorramchar, IR", label: "Jorramchar, IR", code: "Khorramshahr" },
	{
		value: "Sarpol-e Zahab, IR",
		label: "Sarpol-e Zahab, IR",
		code: "SarpoleZahab",
	},
	{
		value: "Aliabad-e Katul, IR",
		label: "Aliabad-e Katul, IR",
		code: "AliabadeKatul",
	},
	{
		value: "Fereydunkenar, IR",
		label: "Fereydunkenar, IR",
		code: "Fereydunkenar",
	},
	{
		value: "Khorramdarreh, IR",
		label: "Khorramdarreh, IR",
		code: "Khorramdarreh",
	},
	{
		value: "Maneh Va Samalqan, IR",
		label: "Maneh Va Samalqan, IR",
		code: "ManehVaSamalqan",
	},
	{ value: "Bagdad, IQ", label: "Bagdad, IQ", code: "BGW" },
	{ value: "Nayaf, IQ", label: "Nayaf, IQ", code: "NYF-6" },
	{ value: "Basra, IQ", label: "Basra, IQ", code: "BSR-87" },
	{ value: "Erbil, IQ", label: "Erbil, IQ", code: "fake-268" },
	{ value: "Sulaymaniyah, IQ", label: "Sulaymaniyah, IQ", code: "fake-269" },
	{ value: "Caldea, IQ", label: "Caldea, IQ", code: "Chalderan" },
	{ value: "Dublin, IE", label: "Dublin, IE", code: "DUB" },
	{ value: "Cork, IE", label: "Cork, IE", code: "ORK-1" },
	{ value: "Waterford, IE", label: "Waterford, IE", code: "WAT" },
	{ value: "Galway, IE", label: "Galway, IE", code: "GWY-1" },
	{ value: "Killarney, IE", label: "Killarney, IE", code: "KER-7" },
	{ value: "Limerick, IE", label: "Limerick, IE", code: "LMK-1" },
	{ value: "Donegal, IE", label: "Donegal, IE", code: "CDO" },
	{ value: "Kilkenny, IE", label: "Kilkenny, IE", code: "KIL" },
	{ value: "Sligo, IE", label: "Sligo, IE", code: "SLI" },
	{ value: "Wexford, IE", label: "Wexford, IE", code: "WEX" },
	{ value: "Dingle, IE", label: "Dingle, IE", code: "KER8" },
	{ value: "Castlebar, IE", label: "Castlebar, IE", code: "MAY2" },
	{ value: "Kinsale, IE", label: "Kinsale, IE", code: "ORK2" },
	{ value: "Shannon, IE", label: "Shannon, IE", code: "CLA-10" },
	{ value: "Athlone, IE", label: "Athlone, IE", code: "AHL" },
	{ value: "Conty Cavan, IE", label: "Conty Cavan, IE", code: "CCV" },
	{ value: "Portlaoise, IE", label: "Portlaoise, IE", code: "CLS" },
	{ value: "Kenmare, IE", label: "Kenmare, IE", code: "KER" },
	{ value: "Condado de Mayo, IE", label: "Condado de Mayo, IE", code: "MAY" },
	{ value: "Tipperary, IE", label: "Tipperary, IE", code: "TPY" },
	{ value: "Wicklow, IE", label: "Wicklow, IE", code: "WIK" },
	{ value: "Rossnowlagh, IE", label: "Rossnowlagh, IE", code: "CDO-1" },
	{ value: "Letterkenny, IE", label: "Letterkenny, IE", code: "CDO-7" },
	{ value: "Ennis, IE", label: "Ennis, IE", code: "CLA-2" },
	{ value: "Oughterard, IE", label: "Oughterard, IE", code: "GWY-3" },
	{ value: "Tralee, IE", label: "Tralee, IE", code: "KER-2" },
	{
		value: "Waterville Ring of Kerry, IE",
		label: "Waterville Ring of Kerry, IE",
		code: "KER-3",
	},
	{ value: "Urlingford, IE", label: "Urlingford, IE", code: "KIL-4" },
	{ value: "Ballina (Mayo), IE", label: "Ballina (Mayo), IE", code: "MAY-3" },
	{ value: "Westport, IE", label: "Westport, IE", code: "MAY-4" },
	{ value: "Cong, IE", label: "Cong, IE", code: "MAY-5" },
	{ value: "Trim, IE", label: "Trim, IE", code: "MTH-1" },
	{ value: "Macroom, IE", label: "Macroom, IE", code: "ORK-7" },
	{ value: "Rosslare, IE", label: "Rosslare, IE", code: "RSL-2" },
	{ value: "Tubbercurry, IE", label: "Tubbercurry, IE", code: "SLI-4" },
	{ value: "Enniscorthy, IE", label: "Enniscorthy, IE", code: "WEX-3" },
	{
		value: "Rosslare Harbour, IE",
		label: "Rosslare Harbour, IE",
		code: "WEX-5",
	},
	{ value: "Greystones, IE", label: "Greystones, IE", code: "WIK-4" },
	{ value: "Louisburgh, IE", label: "Louisburgh, IE", code: "MAY-10" },
	{ value: "Bunratty, IE", label: "Bunratty, IE", code: "fake-806" },
	{ value: "Mayo, IE", label: "Mayo, IE", code: "myo" },
	{ value: "Cleggan, IE", label: "Cleggan, IE", code: "CLG-1" },
	{
		value: "Galway (Condado), IE",
		label: "Galway (Condado), IE",
		code: "GAL-6",
	},
	{
		value: "Condado de Meath, IE",
		label: "Condado de Meath, IE",
		code: "COME-1",
	},
	{ value: "Roscommon, IE", label: "Roscommon, IE", code: "Roscommon" },
	{ value: "Isle of Man, IM", label: "Isle of Man, IM", code: "IOM" },
	{ value: "Douglas, IM", label: "Douglas, IM", code: "Douglas" },
	{ value: "Tel Aviv, IL", label: "Tel Aviv, IL", code: "TLV" },
	{ value: "Haifa, IL", label: "Haifa, IL", code: "HFA" },
	{ value: "Tiberias, IL", label: "Tiberias, IL", code: "GLE1" },
	{ value: "Nazaret, IL", label: "Nazaret, IL", code: "GLE2" },
	{ value: "Jerusalén, IL", label: "Jerusalén, IL", code: "JRS-1" },
	{ value: "Arad, IL", label: "Arad, IL", code: "SRE-5" },
	{ value: "Zichron Yaakov, IL", label: "Zichron Yaakov, IL", code: "CRG" },
	{ value: "Nahariya, IL", label: "Nahariya, IL", code: "NAY" },
	{ value: "Netanya, IL", label: "Netanya, IL", code: "CRG-3" },
	{ value: "Ein Gedi, IL", label: "Ein Gedi, IL", code: "DDS-1" },
	{ value: "Eilat, IL", label: "Eilat, IL", code: "ETH-1" },
	{ value: "Ashdod, IL", label: "Ashdod, IL", code: "SRE-2" },
	{ value: "Beerseba, IL", label: "Beerseba, IL", code: "BRSB-6" },
	{ value: "Bat Yam, IL", label: "Bat Yam, IL", code: "BTYM-6" },
	{ value: "Hadera, IL", label: "Hadera, IL", code: "HDRA-6" },
	{ value: "Nain, IL", label: "Nain, IL", code: "NAIN-6" },
	{ value: "Ramala, IL", label: "Ramala, IL", code: "RMLA-6" },
	{ value: "Herzliya, IL", label: "Herzliya, IL", code: "HRZLY-6" },
	{ value: "Ramat Gan, IL", label: "Ramat Gan, IL", code: "RMTGN-6" },
	{ value: "Ascalón, IL", label: "Ascalón, IL", code: "SCLON-6" },
	{ value: "Tabgha, IL", label: "Tabgha, IL", code: "TABGH-6" },
	{ value: "Mitzpe Ramon, IL", label: "Mitzpe Ramon, IL", code: "MTZPRA-6" },
	{ value: "Karmiel NO HOTELS, IL", label: "Karmiel NO HOTELS, IL", code: "5" },
	{ value: "Rahat, IL", label: "Rahat, IL", code: "BEV1" },
	{
		value: "Ein Ovdat National Park and Canyon, IL",
		label: "Ein Ovdat National Park and Canyon, IL",
		code: "BEV2",
	},
	{
		value: "Dimona NO HOTELS, IL",
		label: "Dimona NO HOTELS, IL",
		code: "BEV3",
	},
	{ value: "Séforis, IL", label: "Séforis, IL", code: "HFA1" },
	{ value: "Afula, IL", label: "Afula, IL", code: "HFA3" },
	{
		value: "Arraba NO DA HOTELS, IL",
		label: "Arraba NO DA HOTELS, IL",
		code: "HFA4",
	},
	{ value: "Kibbutz Ein Gev, IL", label: "Kibbutz Ein Gev, IL", code: "RPN1" },
	{
		value: "Baqa al-Gharbiyye NO HOTELS, IL",
		label: "Baqa al-Gharbiyye NO HOTELS, IL",
		code: "SDV2",
	},
	{ value: "Bnei Brak, IL", label: "Bnei Brak, IL", code: "SDV3" },
	{ value: "Ein Karem, IL", label: "Ein Karem, IL", code: "TLV3" },
	{ value: "Elad, IL", label: "Elad, IL", code: "TLV4" },
	{ value: "Guivat Shmuel, IL", label: "Guivat Shmuel, IL", code: "TLV5" },
	{
		value: "Beit Shemesh NO DA HOTELS, IL",
		label: "Beit Shemesh NO DA HOTELS, IL",
		code: "TLV6",
	},
	{ value: "Beit Shean, IL", label: "Beit Shean, IL", code: "BSH-1" },
	{ value: "Cesarea, IL", label: "Cesarea, IL", code: "CAE-1" },
	{
		value: "Mar Muerto, Israel, IL",
		label: "Mar Muerto, Israel, IL",
		code: "DDSEA",
	},
	{ value: "Galilea, IL", label: "Galilea, IL", code: "GAL-2" },
	{ value: "Masada, IL", label: "Masada, IL", code: "MSD-1" },
	{ value: "Cafarnaúm, IL", label: "Cafarnaúm, IL", code: "CFRN-1" },
	{ value: "Jaffa, IL", label: "Jaffa, IL", code: "JAFF-1" },
	{
		value: "Altos del Golán, IL",
		label: "Altos del Golán, IL",
		code: "GOLAN-6",
	},
	{ value: "Acre, IL", label: "Acre, IL", code: "fake-790" },
	{ value: "Belén, IL", label: "Belén, IL", code: "fake-800" },
	{ value: "Safed, IL", label: "Safed, IL", code: "fake-833" },
	{ value: "Gan HaDaron, IL", label: "Gan HaDaron, IL", code: "GANDARON-6" },
	{ value: "Roma, IT", label: "Roma, IT", code: "ROE" },
	{ value: "Sicilia, IT", label: "Sicilia, IT", code: "SIC-1a" },
	{ value: "Milan, IT", label: "Milan, IT", code: "MIL" },
	{ value: "Salento, IT", label: "Salento, IT", code: "SLE-2" },
	{ value: "Pescara, IT", label: "Pescara, IT", code: "ABR" },
	{ value: "Ancona, IT", label: "Ancona, IT", code: "AOI" },
	{ value: "Arezzo, IT", label: "Arezzo, IT", code: "AZZ" },
	{ value: "Taranto, IT", label: "Taranto, IT", code: "BAR" },
	{ value: "Bergamo, IT", label: "Bergamo, IT", code: "BEG" },
	{ value: "Bolonia, IT", label: "Bolonia, IT", code: "BLQ" },
	{ value: "Cremona, IT", label: "Cremona, IT", code: "CRM" },
	{ value: "Florencia, IT", label: "Florencia, IT", code: "FLR" },
	{ value: "Ferrara, IT", label: "Ferrara, IT", code: "FRR" },
	{ value: "Génova, IT", label: "Génova, IT", code: "GOA" },
	{ value: "Grosseto, IT", label: "Grosseto, IT", code: "GRS" },
	{ value: "Matera, IT", label: "Matera, IT", code: "MTR" },
	{ value: "Perugia, IT", label: "Perugia, IT", code: "PEG" },
	{ value: "Pisa, IT", label: "Pisa, IT", code: "PSA" },
	{ value: "Ravenna, IT", label: "Ravenna, IT", code: "RAN" },
	{ value: "Siena, IT", label: "Siena, IT", code: "SAY" },
	{ value: "Latina, IT", label: "Latina, IT", code: "SPE" },
	{ value: "Turín, IT", label: "Turín, IT", code: "TRN" },
	{ value: "Verona, IT", label: "Verona, IT", code: "VRN" },
	{ value: "Cinque Terre, IT", label: "Cinque Terre, IT", code: "GOA4" },
	{ value: "Foggia, IT", label: "Foggia, IT", code: "BAR-1" },
	{ value: "Brindisi, IT", label: "Brindisi, IT", code: "BAR-3" },
	{ value: "Ostuni , IT", label: "Ostuni , IT", code: "BAR-6" },
	{ value: "Otranto, Puglia, IT", label: "Otranto, Puglia, IT", code: "BAR-8" },
	{ value: "Reggio Calabria, IT", label: "Reggio Calabria, IT", code: "DRC-1" },
	{ value: "La Spezia, IT", label: "La Spezia, IT", code: "GOA-4" },
	{
		value: "Breuil Cervinia-Valtournenche, Valle de Aosta, IT",
		label: "Breuil Cervinia-Valtournenche, Valle de Aosta, IT",
		code: "IHM-5",
	},
	{ value: "Isla de Elba, IT", label: "Isla de Elba, IT", code: "LIV-2" },
	{ value: "Nápoles, IT", label: "Nápoles, IT", code: "NAP-1" },
	{ value: "Orvieto, IT", label: "Orvieto, IT", code: "PEG-7" },
	{ value: "Cosenza, IT", label: "Cosenza, IT", code: "RDC-2" },
	{ value: "Catanzaro, IT", label: "Catanzaro, IT", code: "RDC-3" },
	{ value: "Sorrento, IT", label: "Sorrento, IT", code: "RNP-3" },
	{ value: "Isquia, IT", label: "Isquia, IT", code: "RNP-7" },
	{ value: "Siracusa, IT", label: "Siracusa, IT", code: "SIC-1" },
	{ value: "Agrigento, IT", label: "Agrigento, IT", code: "SIC-3" },
	{ value: "Palermo, IT", label: "Palermo, IT", code: "SIC-4" },
	{ value: "Taormina, IT", label: "Taormina, IT", code: "SIC-7" },
	{ value: "Cagliari, IT", label: "Cagliari, IT", code: "SSR-5" },
	{ value: "Venecia, IT", label: "Venecia, IT", code: "VCE-1" },
	{ value: "Bari, IT", label: "Bari, IT", code: "BAR-10" },
	{ value: "Ugento, IT", label: "Ugento, IT", code: "BAR-16" },
	{
		value: "San Pellegrino Pass, IT",
		label: "San Pellegrino Pass, IT",
		code: "IHM-31",
	},
	{
		value: "Bormio, Lombardía, IT",
		label: "Bormio, Lombardía, IT",
		code: "IHM77J",
	},
	{ value: "Monza, IT", label: "Monza, IT", code: "MIL-21" },
	{ value: "Palau, Cerdeña, IT", label: "Palau, Cerdeña, IT", code: "NSR-11" },
	{ value: "Alghero, IT", label: "Alghero, IT", code: "NSR-12" },
	{ value: "Madesimo, IT", label: "Madesimo, IT", code: "IHM77AB" },
	{
		value: "Presolana - Monte Pora, IT",
		label: "Presolana - Monte Pora, IT",
		code: "IHM77AC",
	},
	{ value: "Toscana, IT", label: "Toscana, IT", code: "Fake-122" },
	{
		value: "Zafferana Etnea, IT",
		label: "Zafferana Etnea, IT",
		code: "fake-129",
	},
	{ value: "Gallipoli, IT", label: "Gallipoli, IT", code: "gallipoli" },
	{ value: "Lago Garda, IT", label: "Lago Garda, IT", code: "LG" },
	{ value: "Gioiosa Marea, IT", label: "Gioiosa Marea, IT", code: "918" },
	{ value: "Asís, IT", label: "Asís, IT", code: "ASS" },
	{ value: "Brescia, IT", label: "Brescia, IT", code: "BSC" },
	{ value: "Civitavecchia , IT", label: "Civitavecchia , IT", code: "CIV" },
	{ value: "Terni, IT", label: "Terni, IT", code: "ERN" },
	{ value: "Lucca, IT", label: "Lucca, IT", code: "LCV" },
	{ value: "Lago Mayor, IT", label: "Lago Mayor, IT", code: "LGM" },
	{ value: "Livorno, IT", label: "Livorno, IT", code: "LIV" },
	{ value: "Módena, IT", label: "Módena, IT", code: "MOD" },
	{ value: "Montecatini, IT", label: "Montecatini, IT", code: "MOT" },
	{ value: "Cerdeña norte, IT", label: "Cerdeña norte, IT", code: "NSR" },
	{ value: "Parma, IT", label: "Parma, IT", code: "PAM" },
	{ value: "Pistoia, IT", label: "Pistoia, IT", code: "PIS" },
	{ value: "Capri, IT", label: "Capri, IT", code: "PRJ" },
	{ value: "Pavia, IT", label: "Pavia, IT", code: "PVA" },
	{ value: "Lago Como, IT", label: "Lago Como, IT", code: "QCM" },
	{ value: "Padova, IT", label: "Padova, IT", code: "QPA" },
	{
		value: "Rimini, Emilia Romagna, IT",
		label: "Rimini, Emilia Romagna, IT",
		code: "RMI",
	},
	{ value: "Rovigo, IT", label: "Rovigo, IT", code: "ROG" },
	{ value: "San Remo, IT", label: "San Remo, IT", code: "SAN" },
	{ value: "Salerno, IT", label: "Salerno, IT", code: "SEN" },
	{ value: "Sur Cerdeña, IT", label: "Sur Cerdeña, IT", code: "SSR" },
	{ value: "Stresa, IT", label: "Stresa, IT", code: "STE" },
	{ value: "Trieste, IT", label: "Trieste, IT", code: "TRS" },
	{ value: "Treviso, IT", label: "Treviso, IT", code: "TRV" },
	{ value: "Varese, IT", label: "Varese, IT", code: "VAE" },
	{ value: "Vicenza, IT", label: "Vicenza, IT", code: "VIC" },
	{ value: "Como, IT", label: "Como, IT", code: "QCM2" },
	{ value: "Savona , IT", label: "Savona , IT", code: "SVN2" },
	{ value: "L´Aquila, IT", label: "L´Aquila, IT", code: "ABR-3" },
	{ value: "Aosta, IT", label: "Aosta, IT", code: "AOS-1" },
	{ value: "Cortona, IT", label: "Cortona, IT", code: "AZZ-2" },
	{ value: "Lecce, IT", label: "Lecce, IT", code: "BAR-2" },
	{
		value: "Ponte di Legno, Lombardia, IT",
		label: "Ponte di Legno, Lombardia, IT",
		code: "BSC-6",
	},
	{ value: "Potenza, IT", label: "Potenza, IT", code: "BSI-1" },
	{ value: "Trento, IT", label: "Trento, IT", code: "BZO-1" },
	{ value: "Bolzano, IT", label: "Bolzano, IT", code: "BZO-2" },
	{ value: "Cesena, IT", label: "Cesena, IT", code: "FRL-3" },
	{ value: "Portofino, IT", label: "Portofino, IT", code: "GOA-2" },
	{ value: "Rapallo, IT", label: "Rapallo, IT", code: "GOA-6" },
	{
		value: "Cortina d&apos;Ampezzo, Veneto, IT",
		label: "Cortina d&apos;Ampezzo, Veneto, IT",
		code: "IHM-3",
	},
	{
		value: "La Thuile, Valle de Aosta, IT",
		label: "La Thuile, Valle de Aosta, IT",
		code: "IHM-4",
	},
	{ value: "Val Gardena, IT", label: "Val Gardena, IT", code: "IHM30" },
	{ value: "Riva del Garda, IT", label: "Riva del Garda, IT", code: "LG-16" },
	{ value: "Campobasso, IT", label: "Campobasso, IT", code: "MLS-2" },
	{ value: "Caserta, IT", label: "Caserta, IT", code: "NAP-2" },
	{ value: "Pompei, IT", label: "Pompei, IT", code: "NAP-6" },
	{ value: "Arzachena, IT", label: "Arzachena, IT", code: "NSR-1" },
	{
		value: "Santa teresa di gallura, IT",
		label: "Santa teresa di gallura, IT",
		code: "NSR-7",
	},
	{ value: "Olbia, IT", label: "Olbia, IT", code: "NSR-9" },
	{ value: "Gubbio, IT", label: "Gubbio, IT", code: "PEG-2" },
	{ value: "Spoleto, IT", label: "Spoleto, IT", code: "PEG-3" },
	{ value: "Volterra, IT", label: "Volterra, IT", code: "PSA-4" },
	{ value: "Lecco, IT", label: "Lecco, IT", code: "QCM-2" },
	{ value: "Lamezia terme, IT", label: "Lamezia terme, IT", code: "RDC-6" },
	{ value: "Pesaro, Marche, IT", label: "Pesaro, Marche, IT", code: "RMI-6" },
	{ value: "Massa, IT", label: "Massa, IT", code: "RNP-2" },
	{
		value: "Costa Amalfitana, IT",
		label: "Costa Amalfitana, IT",
		code: "RNP-5",
	},
	{ value: "Positano, IT", label: "Positano, IT", code: "RNP-6" },
	{ value: "San gimignano, IT", label: "San gimignano, IT", code: "SAY-6" },
	{ value: "Montepulciano, IT", label: "Montepulciano, IT", code: "SAY27" },
	{ value: "Catania, IT", label: "Catania, IT", code: "SIC-2" },
	{ value: "Trapani, IT", label: "Trapani, IT", code: "SIC-5" },
	{ value: "Oristano, IT", label: "Oristano, IT", code: "SSR-8" },
	{ value: "Novara, IT", label: "Novara, IT", code: "TRN-3" },
	{ value: "Cuneo, IT", label: "Cuneo, IT", code: "TRN-4" },
	{ value: "Mestre, IT", label: "Mestre, IT", code: "VCE-3" },
	{ value: "Abruzos, IT", label: "Abruzos, IT", code: "ABR-10" },
	{ value: "Alimini, IT", label: "Alimini, IT", code: "ALI-01" },
	{
		value: "San Giovanni Rotondo, IT",
		label: "San Giovanni Rotondo, IT",
		code: "BAR-12",
	},
	{
		value: "Borgagne frazione di Melendugno, IT",
		label: "Borgagne frazione di Melendugno, IT",
		code: "BFM-01",
	},
	{ value: "Cerdeña (Isla), IT", label: "Cerdeña (Isla), IT", code: "CER-99" },
	{ value: "Carpignano, IT", label: "Carpignano, IT", code: "CGO-01" },
	{
		value: "Cavallino-Treporti, IT",
		label: "Cavallino-Treporti, IT",
		code: "CVT-01",
	},
	{ value: "Imperia, IT", label: "Imperia, IT", code: "GOA-11" },
	{ value: "Val di Fassa, IT", label: "Val di Fassa, IT", code: "IHM-19" },
	{ value: "Valle Gardena , IT", label: "Valle Gardena , IT", code: "IHM-30" },
	{ value: "Lago de Garda, IT", label: "Lago de Garda, IT", code: "LAG-99" },
	{ value: "Lecce, IT", label: "Lecce, IT", code: "LEC-01" },
	{ value: "Melendugno, IT", label: "Melendugno, IT", code: "MDN-01" },
	{ value: "Abbiategrasso , IT", label: "Abbiategrasso , IT", code: "MIL-22" },
	{ value: "Martano, IT", label: "Martano, IT", code: "MRT-01" },
	{ value: "Porto Cervo, IT", label: "Porto Cervo, IT", code: "NSR-13" },
	{ value: "Sassari, IT", label: "Sassari, IT", code: "NSR-17" },
	{ value: "La maddalena, IT", label: "La maddalena, IT", code: "NSR-19" },
	{ value: "Aglientu , IT", label: "Aglientu , IT", code: "NSR-24" },
	{ value: "Orosei, IT", label: "Orosei, IT", code: "NSR-26" },
	{ value: "Nettuno, IT", label: "Nettuno, IT", code: "NTT-01" },
	{ value: "Porto Balisco, IT", label: "Porto Balisco, IT", code: "PBO-01" },
	{
		value: "Roca marina di Melendugno, IT",
		label: "Roca marina di Melendugno, IT",
		code: "RCM-01",
	},
	{ value: "Procida, IT", label: "Procida, IT", code: "RNP-20" },
	{ value: "Messina, IT", label: "Messina, IT", code: "SIC-10" },
	{ value: "Marsala, IT", label: "Marsala, IT", code: "SIC-11" },
	{ value: "Ragusa, IT", label: "Ragusa, IT", code: "SIC-15" },
	{ value: "Favignana, IT", label: "Favignana, IT", code: "SIC-20" },
	{
		value: "San Foca marina di Melendugno, IT",
		label: "San Foca marina di Melendugno, IT",
		code: "SNF-01",
	},
	{ value: "Torre Saracena, IT", label: "Torre Saracena, IT", code: "TCA-01" },
	{
		value: "Torre Chianca marina di Lecce, IT",
		label: "Torre Chianca marina di Lecce, IT",
		code: "TRR-01",
	},
	{
		value: "Torre Sant&apos;Andrea, IT",
		label: "Torre Sant&apos;Andrea, IT",
		code: "TSA-01",
	},
	{
		value: "Lido di Jesolo, Veneto, IT",
		label: "Lido di Jesolo, Veneto, IT",
		code: "VCE-22",
	},
	{ value: "Zollino, IT", label: "Zollino, IT", code: "ZLL-01" },
	{ value: "Cogolo di Pejo, IT", label: "Cogolo di Pejo, IT", code: "Fake-65" },
	{
		value: "Courmayeur, Valle de Aosta, IT",
		label: "Courmayeur, Valle de Aosta, IT",
		code: "IHM-77G",
	},
	{ value: "Pantelaria, IT", label: "Pantelaria, IT", code: "PAN-121" },
	{ value: "Porto Cesareo, IT", label: "Porto Cesareo, IT", code: "Fake-150" },
	{ value: "Basilicata, IT", label: "Basilicata, IT", code: "fake-434" },
	{ value: "San Ginesio, IT", label: "San Ginesio, IT", code: "fake-565" },
	{ value: "San Teodoro, IT", label: "San Teodoro, IT", code: "fake-566" },
	{
		value: "Sant&apos;Antioco, IT",
		label: "Sant&apos;Antioco, IT",
		code: "fake-567",
	},
	{
		value: "Santa Caterina di Valfurva, IT",
		label: "Santa Caterina di Valfurva, IT",
		code: "fake-569",
	},
	{ value: "Melpignano, IT", label: "Melpignano, IT", code: "fake-738" },
	{ value: "Maranello, IT", label: "Maranello, IT", code: "003" },
	{ value: "Welsberg-Taisten, IT", label: "Welsberg-Taisten, IT", code: "009" },
	{
		value: "Tonnara di Bonagia, IT",
		label: "Tonnara di Bonagia, IT",
		code: "097",
	},
	{ value: "Riva di tures, IT", label: "Riva di tures, IT", code: "149" },
	{ value: "Monte Cavallo, IT", label: "Monte Cavallo, IT", code: "190" },
	{ value: "Casal sul Sile, IT", label: "Casal sul Sile, IT", code: "239" },
	{ value: "Sassuolo, IT", label: "Sassuolo, IT", code: "321" },
	{
		value: "Maso Corto Trentino Alto Adigio, IT",
		label: "Maso Corto Trentino Alto Adigio, IT",
		code: "341",
	},
	{
		value: "Rasun Anterselva, Trentino-Alto Adige, IT",
		label: "Rasun Anterselva, Trentino-Alto Adige, IT",
		code: "548",
	},
	{ value: "Valderice , IT", label: "Valderice , IT", code: "572" },
	{ value: "Marebbe, IT", label: "Marebbe, IT", code: "690" },
	{ value: "Ortisei, IT", label: "Ortisei, IT", code: "789" },
	{ value: "Erice sul mare, IT", label: "Erice sul mare, IT", code: "901" },
	{ value: "Pinzolo, IT", label: "Pinzolo, IT", code: "909" },
	{ value: "Porto Ottiolu, IT", label: "Porto Ottiolu, IT", code: "912" },
	{ value: "San Pantaleo, IT", label: "San Pantaleo, IT", code: "913" },
	{
		value: "Santa Margherita di Pula, IT",
		label: "Santa Margherita di Pula, IT",
		code: "914",
	},
	{
		value: "Stintino, Sardegna, IT",
		label: "Stintino, Sardegna, IT",
		code: "915",
	},
	{
		value: "Campofelice di Roccella, Sicilia, IT",
		label: "Campofelice di Roccella, Sicilia, IT",
		code: "917",
	},
	{ value: "Letojanni, IT", label: "Letojanni, IT", code: "919" },
	{ value: "Licata, Sicilia, IT", label: "Licata, Sicilia, IT", code: "920" },
	{ value: "Menfi, IT", label: "Menfi, IT", code: "921" },
	{ value: "Sampieri, IT", label: "Sampieri, IT", code: "922" },
	{ value: "Scicli, IT", label: "Scicli, IT", code: "924" },
	{ value: "Santa Flavia, IT", label: "Santa Flavia, IT", code: "925" },
	{ value: "Terrasini, IT", label: "Terrasini, IT", code: "926" },
	{ value: "Lido Marini, IT", label: "Lido Marini, IT", code: "927" },
	{ value: "Rodi Garganico, IT", label: "Rodi Garganico, IT", code: "928" },
	{
		value: "San Candito, Trentino Alto Adige, IT",
		label: "San Candito, Trentino Alto Adige, IT",
		code: "990",
	},
	{ value: "Ascoli, IT", label: "Ascoli, IT", code: "ACO" },
	{ value: "Aquileia, IT", label: "Aquileia, IT", code: "AQU" },
	{ value: "Dimaro, IT", label: "Dimaro, IT", code: "DIM" },
	{ value: "Apiro, IT", label: "Apiro, IT", code: "MCR" },
	{ value: "Isernia, IT", label: "Isernia, IT", code: "MLS" },
	{ value: "Maratea, IT", label: "Maratea, IT", code: "MTA" },
	{ value: "Pienza, IT", label: "Pienza, IT", code: "PIE" },
	{ value: "Radda in Chianti, IT", label: "Radda in Chianti, IT", code: "RDA" },
	{ value: "Tropea, IT", label: "Tropea, IT", code: "RDC" },
	{ value: "Rocca di Papa, IT", label: "Rocca di Papa, IT", code: "RDP" },
	{
		value: "Capo Vaticano, Calabria, IT",
		label: "Capo Vaticano, Calabria, IT",
		code: "RIC",
	},
	{
		value: "San Quirico d’Orcia, IT",
		label: "San Quirico d’Orcia, IT",
		code: "SQO",
	},
	{ value: "Udine, IT", label: "Udine, IT", code: "UDN" },
	{
		value: "Alba di Canazei, Trentino Alto Adigio, IT",
		label: "Alba di Canazei, Trentino Alto Adigio, IT",
		code: "tnt",
	},
	{ value: "Andria, IT", label: "Andria, IT", code: "ANDR" },
	{ value: "Monteriggioni, IT", label: "Monteriggioni, IT", code: "SAY7" },
	{ value: "Tarquinia, IT", label: "Tarquinia, IT", code: "TARQ" },
	{ value: "murlo, IT", label: "murlo, IT", code: "53016" },
	{ value: "Teramo, IT", label: "Teramo, IT", code: "ABR-2" },
	{
		value: "San Benedetto del Tronto, IT",
		label: "San Benedetto del Tronto, IT",
		code: "AOI-2",
	},
	{ value: "Senigallia, IT", label: "Senigallia, IT", code: "AOI-3" },
	{ value: "Portonovo, IT", label: "Portonovo, IT", code: "AOI-7" },
	{ value: "Torre Ovo, IT", label: "Torre Ovo, IT", code: "BAR-4" },
	{
		value: "Costa Valle Imagna, IT",
		label: "Costa Valle Imagna, IT",
		code: "BEG-4",
	},
	{ value: "Tole, IT", label: "Tole, IT", code: "BLQ-4" },
	{ value: "Varignana, IT", label: "Varignana, IT", code: "BLQ-8" },
	{
		value: "Vandoies, Trentino Alto Adigio, IT",
		label: "Vandoies, Trentino Alto Adigio, IT",
		code: "BZO-7",
	},
	{ value: "Prato, IT", label: "Prato, IT", code: "CAS-3" },
	{ value: "Cassino, IT", label: "Cassino, IT", code: "CSN-3" },
	{ value: "Baia Domizia, IT", label: "Baia Domizia, IT", code: "ECS-3" },
	{ value: "Forio, IT", label: "Forio, IT", code: "FRO-3" },
	{ value: "Arenzano, IT", label: "Arenzano, IT", code: "GOA-8" },
	{ value: "Chiavari, IT", label: "Chiavari, IT", code: "GOA-9" },
	{ value: "Sorano, IT", label: "Sorano, IT", code: "GRS-3" },
	{ value: "Talamone, IT", label: "Talamone, IT", code: "GRS-4" },
	{
		value: "Canazei, Trentino Alto-Adige, IT",
		label: "Canazei, Trentino Alto-Adige, IT",
		code: "IHM-6",
	},
	{
		value: "Madonna di Campiglio, Trentino alto Adige, IT",
		label: "Madonna di Campiglio, Trentino alto Adige, IT",
		code: "IHM-8",
	},
	{
		value: "Passo del Tonale, IT",
		label: "Passo del Tonale, IT",
		code: "IHM-9",
	},
	{ value: "Viareggio, IT", label: "Viareggio, IT", code: "LCV-2" },
	{
		value: "Marina di Pietrasanta, IT",
		label: "Marina di Pietrasanta, IT",
		code: "LCV-5",
	},
	{ value: "Tolentino, IT", label: "Tolentino, IT", code: "MCR-1" },
	{
		value: "Civitanova Marche, IT",
		label: "Civitanova Marche, IT",
		code: "MCR-5",
	},
	{ value: "Modica, IT", label: "Modica, IT", code: "MDC-2" },
	{
		value: "Cinisello Balsamo, IT",
		label: "Cinisello Balsamo, IT",
		code: "MIL-5",
	},
	{ value: "Carpi, IT", label: "Carpi, IT", code: "MOD-2" },
	{
		value: "Fiorano Modenese, IT",
		label: "Fiorano Modenese, IT",
		code: "MOD-3",
	},
	{
		value: "Monsummano Terme, IT",
		label: "Monsummano Terme, IT",
		code: "MOT-3",
	},
	{
		value: "Buggiano Castello, IT",
		label: "Buggiano Castello, IT",
		code: "MOT-4",
	},
	{ value: "Larciano, IT", label: "Larciano, IT", code: "MOT-5" },
	{
		value: "Giugliano in Campania, IT",
		label: "Giugliano in Campania, IT",
		code: "NAP-4",
	},
	{ value: "Marigliano, IT", label: "Marigliano, IT", code: "NAP-9" },
	{ value: "Tanaunella, IT", label: "Tanaunella, IT", code: "NSR-5" },
	{ value: "Urbino, IT", label: "Urbino, IT", code: "PEA-2" },
	{ value: "fake-534, IT", label: "fake-534, IT", code: "Palau" },
	{ value: "Faenza, IT", label: "Faenza, IT", code: "RAN-2" },
	{ value: "Vibo Valentia, IT", label: "Vibo Valentia, IT", code: "RDC-5" },
	{ value: "Riccione, IT", label: "Riccione, IT", code: "RMI-3" },
	{ value: "Cattolica, IT", label: "Cattolica, IT", code: "RMI-5" },
	{
		value: "Cervia, Emilia Romagna, IT",
		label: "Cervia, Emilia Romagna, IT",
		code: "RMI-9",
	},
	{ value: "Ravello, IT", label: "Ravello, IT", code: "RNP-9" },
	{
		value: "Colle di Val d&apos;Elsa, IT",
		label: "Colle di Val d&apos;Elsa, IT",
		code: "SAY-4",
	},
	{ value: "Chianciano, IT", label: "Chianciano, IT", code: "SAY-5" },
	{ value: "Gambassi Terme, IT", label: "Gambassi Terme, IT", code: "SAY-8" },
	{
		value: "Monteroni d&apos;Arbia, IT",
		label: "Monteroni d&apos;Arbia, IT",
		code: "SAY26",
	},
	{ value: "Paestum, IT", label: "Paestum, IT", code: "SEN-2" },
	{ value: "Palinuro, IT", label: "Palinuro, IT", code: "SEN-3" },
	{
		value: "Santa Maria de Castellabate, IT",
		label: "Santa Maria de Castellabate, IT",
		code: "SEN-6",
	},
	{ value: "Eolie Islands, IT", label: "Eolie Islands, IT", code: "SIC-6" },
	{ value: "Sondrio, IT", label: "Sondrio, IT", code: "SND-2" },
	{ value: "Sperlonga, IT", label: "Sperlonga, IT", code: "SPE-5" },
	{ value: "Terracina, IT", label: "Terracina, IT", code: "SPE-6" },
	{ value: "Arbus, IT", label: "Arbus, IT", code: "SSR-6" },
	{ value: "Tortoli, IT", label: "Tortoli, IT", code: "SSR-7" },
	{ value: "Conegliano, IT", label: "Conegliano, IT", code: "TRV-5" },
	{ value: "Fiesso dÁrtico, IT", label: "Fiesso dÁrtico, IT", code: "VCE-4" },
	{ value: "Viterbo, IT", label: "Viterbo, IT", code: "VIB-1" },
	{ value: "Ventotene, IT", label: "Ventotene, IT", code: "VNT-2" },
	{ value: "Alberobello, IT", label: "Alberobello, IT", code: "ALB-21" },
	{
		value: "Civitella in Val di Chiana, IT",
		label: "Civitella in Val di Chiana, IT",
		code: "AZZ-10",
	},
	{
		value: "Terranuova Bracciolini, IT",
		label: "Terranuova Bracciolini, IT",
		code: "AZZ-11",
	},
	{ value: "Fasano, IT", label: "Fasano, IT", code: "BAR-13" },
	{ value: "Gargano, IT", label: "Gargano, IT", code: "BAR-14" },
	{
		value: "Margarita de Savoia, IT",
		label: "Margarita de Savoia, IT",
		code: "BAR-20",
	},
	{ value: "Trani, IT", label: "Trani, IT", code: "BAR-21" },
	{ value: "Islas Tremiti, IT", label: "Islas Tremiti, IT", code: "BAR-22" },
	{
		value: "Polignano a Mare, Puglia, IT",
		label: "Polignano a Mare, Puglia, IT",
		code: "BAR-26",
	},
	{ value: "Monopoli, IT", label: "Monopoli, IT", code: "BAR-28" },
	{
		value: "Corigliano d&apos;Otranto, IT",
		label: "Corigliano d&apos;Otranto, IT",
		code: "CAST-1",
	},
	{ value: "Fiano Romano , IT", label: "Fiano Romano , IT", code: "FIA-32" },
	{ value: "Figari, IT", label: "Figari, IT", code: "FIG-75" },
	{ value: "Vicchio, IT", label: "Vicchio, IT", code: "FLR-11" },
	{
		value: "Tavarnelle Val di Pesa, IT",
		label: "Tavarnelle Val di Pesa, IT",
		code: "FLR-24",
	},
	{ value: "Montaione, IT", label: "Montaione, IT", code: "FLR-25" },
	{ value: "Spotorno, IT", label: "Spotorno, IT", code: "GOA-10" },
	{
		value: "Campitello di Fassa, Trentino Alto-Adige, IT",
		label: "Campitello di Fassa, Trentino Alto-Adige, IT",
		code: "IHM-10",
	},
	{ value: "Mezzana, IT", label: "Mezzana, IT", code: "IHM-12" },
	{ value: "Selva, IT", label: "Selva, IT", code: "IHM-18" },
	{ value: "Cavalese, IT", label: "Cavalese, IT", code: "IHM-21" },
	{ value: "Folgarida, IT", label: "Folgarida, IT", code: "IHM-22" },
	{
		value: "Livigno, Lombardía, IT",
		label: "Livigno, Lombardía, IT",
		code: "IHM-25",
	},
	{ value: "Andalo, IT", label: "Andalo, IT", code: "LCV-13" },
	{
		value: "Massa - Carrara, IT",
		label: "Massa - Carrara, IT",
		code: "LCV-16",
	},
	{ value: "Cesano Maderno, IT", label: "Cesano Maderno, IT", code: "MIL-14" },
	{ value: "Milán-Malpensa, IT", label: "Milán-Malpensa, IT", code: "MIL-16" },
	{
		value: "Trezzo sull&apos;Adda, IT",
		label: "Trezzo sull&apos;Adda, IT",
		code: "MIL-17",
	},
	{
		value: "Trezzano sul Naviglio, IT",
		label: "Trezzano sul Naviglio, IT",
		code: "MIL-18",
	},
	{
		value: "Cologno Monzese, IT",
		label: "Cologno Monzese, IT",
		code: "MIL-19",
	},
	{ value: "Usmate Velate, IT", label: "Usmate Velate, IT", code: "MIL-35" },
	{ value: "Tremezzo, IT", label: "Tremezzo, IT", code: "MIL-52" },
	{ value: "Bayas, IT", label: "Bayas, IT", code: "NAP-10" },
	{ value: "Fuorigrotta, IT", label: "Fuorigrotta, IT", code: "NAP-13" },
	{ value: "Nola, IT", label: "Nola, IT", code: "NAP-14" },
	{
		value: "Castellammare di Stabia, IT",
		label: "Castellammare di Stabia, IT",
		code: "NAP-30",
	},
	{ value: "Valledoria, IT", label: "Valledoria, IT", code: "NSR-20" },
	{ value: "Tergu, IT", label: "Tergu, IT", code: "NSR-22" },
	{ value: "Pachino, IT", label: "Pachino, IT", code: "PCHN-6" },
	{
		value: "Uggiate Trevano, IT",
		label: "Uggiate Trevano, IT",
		code: "QCM-10",
	},
	{ value: "Varenna, IT", label: "Varenna, IT", code: "QCM-11" },
	{
		value: "Milano Marittima, Emilia Romagna, IT",
		label: "Milano Marittima, Emilia Romagna, IT",
		code: "RMI-11",
	},
	{ value: "Montegridolfo, IT", label: "Montegridolfo, IT", code: "RMI-12" },
	{ value: "Gabicce Mare, IT", label: "Gabicce Mare, IT", code: "RMI-17" },
	{
		value: "Cesenatico, Emilia Romagna, IT",
		label: "Cesenatico, Emilia Romagna, IT",
		code: "RMI-18",
	},
	{ value: "Gatteo a Mare, IT", label: "Gatteo a Mare, IT", code: "RMI-19" },
	{ value: "Saló, IT", label: "Saló, IT", code: "SAL-22" },
	{
		value: "Gaiole in Chianti, IT",
		label: "Gaiole in Chianti, IT",
		code: "SAY-13",
	},
	{
		value: "Castelnuovo Berardenga, IT",
		label: "Castelnuovo Berardenga, IT",
		code: "SAY-16",
	},
	{ value: "Scalea, IT", label: "Scalea, IT", code: "SCA-21" },
	{
		value: "Mercato San Severino, IT",
		label: "Mercato San Severino, IT",
		code: "SEN-10",
	},
	{ value: "Ascea Marina, IT", label: "Ascea Marina, IT", code: "SEN-12" },
	{ value: "Acciaroli, IT", label: "Acciaroli, IT", code: "SEN-15" },
	{ value: "San Giustino, IT", label: "San Giustino, IT", code: "SGI-12" },
	{
		value: "Cefalù, Sicilia, IT",
		label: "Cefalù, Sicilia, IT",
		code: "SIC-06",
	},
	{ value: "Monreale, IT", label: "Monreale, IT", code: "SIC-12" },
	{
		value: "Balestrate, Sicilia, IT",
		label: "Balestrate, Sicilia, IT",
		code: "SIC-18",
	},
	{ value: "Giardini Naxos, IT", label: "Giardini Naxos, IT", code: "SIC-27" },
	{
		value: "San Martino di Castrozza, IT",
		label: "San Martino di Castrozza, IT",
		code: "SMA-33",
	},
	{ value: "San Savino , IT", label: "San Savino , IT", code: "SSV-12" },
	{ value: "Soverato, IT", label: "Soverato, IT", code: "SVRT-6" },
	{
		value: "Trentino-Alto Adigio, IT",
		label: "Trentino-Alto Adigio, IT",
		code: "TRAA-1",
	},
	{ value: "Bibione, IT", label: "Bibione, IT", code: "VCE-19" },
	{ value: "Passo di Sella, IT", label: "Passo di Sella, IT", code: "fake-6" },
	{
		value: "Lido di Camaiore, IT",
		label: "Lido di Camaiore, IT",
		code: "lcv-11",
	},
	{
		value: "Lido di Savio, Emilia Romagna, IT",
		label: "Lido di Savio, Emilia Romagna, IT",
		code: "rmi-22",
	},
	{ value: "Alessandria, IT", label: "Alessandria, IT", code: "123-ALE" },
	{ value: "Aradeo , IT", label: "Aradeo , IT", code: "ARA-123" },
	{ value: "Arnesano, IT", label: "Arnesano, IT", code: "ARN-123" },
	{ value: "Agerola, IT", label: "Agerola, IT", code: "Agerola" },
	{ value: "Scansano, IT", label: "Scansano, IT", code: "SCA-212" },
	{ value: "Badia, IT", label: "Badia, IT", code: "fake-64" },
	{ value: "Comezzadura, IT", label: "Comezzadura, IT", code: "fake-66" },
	{
		value: "Dobbiaco, Trentino Alto Adige, IT",
		label: "Dobbiaco, Trentino Alto Adige, IT",
		code: "fake-90",
	},
	{ value: "La Villa, IT", label: "La Villa, IT", code: "fake-91" },
	{
		value: "Prato allo Stelvio, IT",
		label: "Prato allo Stelvio, IT",
		code: "fake-92",
	},
	{ value: "Sulden, IT", label: "Sulden, IT", code: "fake-93" },
	{ value: "Ortisei, IT", label: "Ortisei, IT", code: "fake-95" },
	{
		value: "Santa Cristina Valgardena, IT",
		label: "Santa Cristina Valgardena, IT",
		code: "fake-97",
	},
	{
		value: "Cesana Toriense, IT",
		label: "Cesana Toriense, IT",
		code: "CTORIN-6",
	},
	{
		value: "San Vito Lo Capo, IT",
		label: "San Vito Lo Capo, IT",
		code: "FAKE-162",
	},
	{ value: "Lampedusa, IT", label: "Lampedusa, IT", code: "Fake-149" },
	{ value: "Capo Ceraso, IT", label: "Capo Ceraso, IT", code: "Fake-901" },
	{ value: "Castelsardo, IT", label: "Castelsardo, IT", code: "Fake-902" },
	{
		value: "Costa Paradiso, IT",
		label: "Costa Paradiso, IT",
		code: "Fake-903",
	},
	{ value: "Costa Rei, IT", label: "Costa Rei, IT", code: "Fake-904" },
	{ value: "Geremeas, IT", label: "Geremeas, IT", code: "Fake-905" },
	{ value: "Cugnana, IT", label: "Cugnana, IT", code: "Fake-906" },
	{ value: "Isola Rosa, IT", label: "Isola Rosa, IT", code: "Fake-907" },
	{ value: "Cardedu , IT", label: "Cardedu , IT", code: "Fake-909" },
	{
		value: "Cala Ginepro, Cerdeña Norte, IT",
		label: "Cala Ginepro, Cerdeña Norte, IT",
		code: "Fake-910",
	},
	{ value: "Pittulongu, IT", label: "Pittulongu, IT", code: "Fake_911" },
	{
		value: "Torre Castiglione, IT",
		label: "Torre Castiglione, IT",
		code: "cast-123",
	},
	{
		value: "Santa Margherita Ligure, IT",
		label: "Santa Margherita Ligure, IT",
		code: "fake-113",
	},
	{
		value: "Castelbuono, Sicilia, IT",
		label: "Castelbuono, Sicilia, IT",
		code: "fake-114",
	},
	{
		value: "Somma Lombardo, IT",
		label: "Somma Lombardo, IT",
		code: "fake-115",
	},
	{ value: "Fano, IT", label: "Fano, IT", code: "fake-160" },
	{
		value: "Monte Argentario, IT",
		label: "Monte Argentario, IT",
		code: "fake-161",
	},
	{
		value: "Villa San Giovanni, IT",
		label: "Villa San Giovanni, IT",
		code: "fake-250",
	},
	{ value: "Bardonecchia, IT", label: "Bardonecchia, IT", code: "fake-322" },
	{
		value: "Corvara, Trentino Alto Adige, IT",
		label: "Corvara, Trentino Alto Adige, IT",
		code: "fake-323",
	},
	{
		value: "Oulx, Piamonte, IT",
		label: "Oulx, Piamonte, IT",
		code: "fake-324",
	},
	{
		value: "Sestriere Piedmont, IT",
		label: "Sestriere Piedmont, IT",
		code: "fake-325",
	},
	{ value: "Bresanona, IT", label: "Bresanona, IT", code: "fake-342" },
	{ value: "Merano, IT", label: "Merano, IT", code: "fake-343" },
	{ value: "Vipiteno, IT", label: "Vipiteno, IT", code: "fake-344" },
	{ value: "Alassio, IT", label: "Alassio, IT", code: "fake-425" },
	{ value: "Alpe di Suise, IT", label: "Alpe di Suise, IT", code: "fake-426" },
	{ value: "Altopascio, IT", label: "Altopascio, IT", code: "fake-427" },
	{ value: "Badesi, IT", label: "Badesi, IT", code: "fake-428" },
	{
		value: "Bagno a Ripoli, IT",
		label: "Bagno a Ripoli, IT",
		code: "fake-429",
	},
	{ value: "Balbano, IT", label: "Balbano, IT", code: "fake-430" },
	{
		value: "Barberino Val d&apos;Elsa, IT",
		label: "Barberino Val d&apos;Elsa, IT",
		code: "fake-431",
	},
	{ value: "Bardolino, IT", label: "Bardolino, IT", code: "fake-432" },
	{ value: "Basiglio, IT", label: "Basiglio, IT", code: "fake-433" },
	{ value: "Bellagio, IT", label: "Bellagio, IT", code: "fake-435" },
	{ value: "Benevento, IT", label: "Benevento, IT", code: "fake-436" },
	{
		value: "Biniasco-Casarile, IT",
		label: "Biniasco-Casarile, IT",
		code: "fake-437",
	},
	{ value: "Boario Terme, IT", label: "Boario Terme, IT", code: "fake-438" },
	{ value: "Cannigione, IT", label: "Cannigione, IT", code: "fake-439" },
	{ value: "Assago Milano, IT", label: "Assago Milano, IT", code: "fake-440" },
	{ value: "Avellino, IT", label: "Avellino, IT", code: "fake-441" },
	{ value: "Bollate, IT", label: "Bollate, IT", code: "fake-442" },
	{
		value: "Borgo San Lorenzo, IT",
		label: "Borgo San Lorenzo, IT",
		code: "fake-443",
	},
	{ value: "Bosa , IT", label: "Bosa , IT", code: "fake-444" },
	{ value: "Brembate, IT", label: "Brembate, IT", code: "fake-445" },
	{ value: "Budoni, IT", label: "Budoni, IT", code: "fake-446" },
	{ value: "Busto Arsizio, IT", label: "Busto Arsizio, IT", code: "fake-447" },
	{ value: "Cadenabbia, IT", label: "Cadenabbia, IT", code: "fake-448" },
	{ value: "Cadore, IT", label: "Cadore, IT", code: "fake-449" },
	{ value: "Cala Gonone, IT", label: "Cala Gonone, IT", code: "fake-450" },
	{ value: "Cambiago, IT", label: "Cambiago, IT", code: "fake-451" },
	{ value: "Calenzano, IT", label: "Calenzano, IT", code: "fake-452" },
	{
		value: "Campi bisenzio, IT",
		label: "Campi bisenzio, IT",
		code: "fake-453",
	},
	{ value: "Capannori, IT", label: "Capannori, IT", code: "fake-454" },
	{ value: "Carloforte, IT", label: "Carloforte, IT", code: "fake-455" },
	{
		value: "Casalecchio di Reno, IT",
		label: "Casalecchio di Reno, IT",
		code: "fake-456",
	},
	{ value: "Castel Mella, IT", label: "Castel Mella, IT", code: "fake-457" },
	{
		value: "Castelnuovo del Garda, IT",
		label: "Castelnuovo del Garda, IT",
		code: "fake-458",
	},
	{
		value: "Castelvecchio Pascoli, IT",
		label: "Castelvecchio Pascoli, IT",
		code: "fake-459",
	},
	{ value: "Castenaso, IT", label: "Castenaso, IT", code: "fake-460" },
	{
		value: "Castiadas, Cerdeña, IT",
		label: "Castiadas, Cerdeña, IT",
		code: "fake-461",
	},
	{
		value: "Castiglioncello, IT",
		label: "Castiglioncello, IT",
		code: "fake-462",
	},
	{
		value: "Castiglione dei Pepoli, IT",
		label: "Castiglione dei Pepoli, IT",
		code: "fake-463",
	},
	{
		value: "Castiglione della Pescaia, IT",
		label: "Castiglione della Pescaia, IT",
		code: "fake-464",
	},
	{
		value: "Castrocaro Terme e Terra del Sole, IT",
		label: "Castrocaro Terme e Terra del Sole, IT",
		code: "fake-465",
	},
	{ value: "Castrocielo, IT", label: "Castrocielo, IT", code: "fake-466" },
	{ value: "Chieti, IT", label: "Chieti, IT", code: "fake-467" },
	{ value: "Certaldo, IT", label: "Certaldo, IT", code: "fake-469" },
	{
		value: "Cavenago di Brianza , IT",
		label: "Cavenago di Brianza , IT",
		code: "fake-470",
	},
	{ value: "Cornaredo, IT", label: "Cornaredo, IT", code: "fake-480" },
	{ value: "Corsico, IT", label: "Corsico, IT", code: "fake-481" },
	{
		value: "Cosenza (Provincia), IT",
		label: "Cosenza (Provincia), IT",
		code: "fake-482",
	},
	{ value: "Crotona, IT", label: "Crotona, IT", code: "fake-483" },
	{
		value: "Cupra Marittima, IT",
		label: "Cupra Marittima, IT",
		code: "fake-484",
	},
	{ value: "Cusago, IT", label: "Cusago, IT", code: "fake-485" },
	{
		value: "Desenzano del Garda, IT",
		label: "Desenzano del Garda, IT",
		code: "fake-486",
	},
	{ value: "Donnini, IT", label: "Donnini, IT", code: "fake-487" },
	{ value: "Dorgali, IT", label: "Dorgali, IT", code: "fake-488" },
	{ value: "Ferentino, IT", label: "Ferentino, IT", code: "fake-489" },
	{
		value: "Figline Valdarno, IT",
		label: "Figline Valdarno, IT",
		code: "fake-490",
	},
	{ value: "Fiumana, IT", label: "Fiumana, IT", code: "fake-491" },
	{
		value: "Follonica, Toscana, IT",
		label: "Follonica, Toscana, IT",
		code: "fake-492",
	},
	{ value: "Forli, IT", label: "Forli, IT", code: "fake-493" },
	{
		value: "Forte dei Marmi, IT",
		label: "Forte dei Marmi, IT",
		code: "fake-494",
	},
	{ value: "Gaeta, IT", label: "Gaeta, IT", code: "fake-495" },
	{ value: "Garda, IT", label: "Garda, IT", code: "fake-496" },
	{
		value: "Gardone Riviera, IT",
		label: "Gardone Riviera, IT",
		code: "fake-497",
	},
	{ value: "Gargnano, IT", label: "Gargnano, IT", code: "fake-498" },
	{ value: "Gerenzano, IT", label: "Gerenzano, IT", code: "fake-499" },
	{ value: "Giulianova, IT", label: "Giulianova, IT", code: "fake-500" },
	{ value: "Gorizia, IT", label: "Gorizia, IT", code: "fake-502" },
	{ value: "Gravedona, IT", label: "Gravedona, IT", code: "fake-503" },
	{ value: "Grottammare, IT", label: "Grottammare, IT", code: "fake-504" },
	{ value: "Guspini, IT", label: "Guspini, IT", code: "fake-505" },
	{ value: "Imola, IT", label: "Imola, IT", code: "fake-513" },
	{ value: "Loano, IT", label: "Loano, IT", code: "fake-514" },
	{ value: "Lomazzo, IT", label: "Lomazzo, IT", code: "fake-518" },
	{
		value: "Loro Ciuffenna, IT",
		label: "Loro Ciuffenna, IT",
		code: "fake-519",
	},
	{ value: "Luino, IT", label: "Luino, IT", code: "fake-520" },
	{ value: "Alba, IT", label: "Alba, IT", code: "fake-521" },
	{ value: "Mantua, IT", label: "Mantua, IT", code: "fake-523" },
	{
		value: "Massa Marittima, IT",
		label: "Massa Marittima, IT",
		code: "fake-524",
	},
	{ value: "Menaggio, IT", label: "Menaggio, IT", code: "fake-525" },
	{ value: "Montefano, IT", label: "Montefano, IT", code: "fake-526" },
	{ value: "Mordano, IT", label: "Mordano, IT", code: "fake-527" },
	{ value: "Nuoro, IT", label: "Nuoro, IT", code: "fake-528" },
	{ value: "Arbatax, IT", label: "Arbatax, IT", code: "fake-529" },
	{ value: "Olbia Tempio, IT", label: "Olbia Tempio, IT", code: "fake-530" },
	{ value: "Orbetello, IT", label: "Orbetello, IT", code: "fake-531" },
	{
		value: "Orta San Giulio, IT",
		label: "Orta San Giulio, IT",
		code: "fake-532",
	},
	{
		value: "Ospedaletto Lodigiano, IT",
		label: "Ospedaletto Lodigiano, IT",
		code: "fake-533",
	},
	{
		value: "Pescasseroli, Abruzos, IT",
		label: "Pescasseroli, Abruzos, IT",
		code: "fake-536",
	},
	{
		value: "Peschiera del Garda, IT",
		label: "Peschiera del Garda, IT",
		code: "fake-537",
	},
	{
		value: "Pieve di Cento, IT",
		label: "Pieve di Cento, IT",
		code: "fake-538",
	},
	{
		value: "Pieve Emanuele, IT",
		label: "Pieve Emanuele, IT",
		code: "fake-539",
	},
	{ value: "Poggibonsi, IT", label: "Poggibonsi, IT", code: "fake-540" },
	{ value: "Porto Ercole, IT", label: "Porto Ercole, IT", code: "fake-541" },
	{
		value: "Porto Recanati, IT",
		label: "Porto Recanati, IT",
		code: "fake-542",
	},
	{
		value: "Porto San Paolo, IT",
		label: "Porto San Paolo, IT",
		code: "fake-543",
	},
	{ value: "Ricadi, IT", label: "Ricadi, IT", code: "fake-544" },
	{
		value: "Sant&apos;Omobono Terme, IT",
		label: "Sant&apos;Omobono Terme, IT",
		code: "fake-568",
	},
	{
		value: "Villasimius, Sardegna, IT",
		label: "Villasimius, Sardegna, IT",
		code: "fake-579",
	},
	{ value: "Campogalliano, IT", label: "Campogalliano, IT", code: "fake-580" },
	{ value: "Arzano, IT", label: "Arzano, IT", code: "fake-581" },
	{ value: "Bacoli, IT", label: "Bacoli, IT", code: "fake-582" },
	{
		value: "Casalnuovo di Napoli, IT",
		label: "Casalnuovo di Napoli, IT",
		code: "fake-583",
	},
	{
		value: "Castel Volturno, IT",
		label: "Castel Volturno, IT",
		code: "fake-584",
	},
	{ value: "Ercolano, IT", label: "Ercolano, IT", code: "fake-585" },
	{ value: "Pozzuoli, IT", label: "Pozzuoli, IT", code: "fake-586" },
	{
		value: "Torre del Greco, IT",
		label: "Torre del Greco, IT",
		code: "fake-587",
	},
	{ value: "Abano Terme, IT", label: "Abano Terme, IT", code: "fake-588" },
	{ value: "Cittadella, IT", label: "Cittadella, IT", code: "fake-589" },
	{
		value: "Montegrotto Terme, IT",
		label: "Montegrotto Terme, IT",
		code: "fake-590",
	},
	{
		value: "Salsomaggiore Terme, IT",
		label: "Salsomaggiore Terme, IT",
		code: "fake-591",
	},
	{
		value: "Citta di Castello, IT",
		label: "Citta di Castello, IT",
		code: "fake-592",
	},
	{
		value: "Passignano sul Trasimeno, IT",
		label: "Passignano sul Trasimeno, IT",
		code: "fake-593",
	},
	{ value: "Barletta, IT", label: "Barletta, IT", code: "fake-595" },
	{ value: "Casetta, IT", label: "Casetta, IT", code: "fake-605" },
	{
		value: "Casole D&apos;Elsa, IT",
		label: "Casole D&apos;Elsa, IT",
		code: "fake-606",
	},
	{
		value: "Castellina In Chianti, IT",
		label: "Castellina In Chianti, IT",
		code: "fake-607",
	},
	{ value: "Roccaraso, IT", label: "Roccaraso, IT", code: "fake-640" },
	{ value: "Sauris, IT", label: "Sauris, IT", code: "fake-645" },
	{
		value: "Monastier di Treviso, IT",
		label: "Monastier di Treviso, IT",
		code: "fake-653",
	},
	{ value: "Carovigno, IT", label: "Carovigno, IT", code: "fake-657" },
	{ value: "Tortoreto, IT", label: "Tortoreto, IT", code: "fake-659" },
	{ value: "Vasto, IT", label: "Vasto, IT", code: "fake-660" },
	{ value: "Policoro, IT", label: "Policoro, IT", code: "fake-662" },
	{ value: "Fossano, IT", label: "Fossano, IT", code: "fake-666" },
	{ value: "Moncalieri, IT", label: "Moncalieri, IT", code: "fake-667" },
	{ value: "Rivoli, IT", label: "Rivoli, IT", code: "fake-668" },
	{ value: "Pordenone, IT", label: "Pordenone, IT", code: "fake-669" },
	{ value: "San Vicente, IT", label: "San Vicente, IT", code: "fake-670" },
	{ value: "Caorle, IT", label: "Caorle, IT", code: "fake-671" },
	{ value: "Dolo, IT", label: "Dolo, IT", code: "fake-672" },
	{ value: "Lido, IT", label: "Lido, IT", code: "fake-673" },
	{ value: "Mirano, IT", label: "Mirano, IT", code: "fake-674" },
	{
		value: "Mogliano Veneto, IT",
		label: "Mogliano Veneto, IT",
		code: "fake-675",
	},
	{ value: "Murano, IT", label: "Murano, IT", code: "fake-676" },
	{
		value: "Quarto d&apos;Altino, IT",
		label: "Quarto d&apos;Altino, IT",
		code: "fake-677",
	},
	{ value: "San Bonifacio, IT", label: "San Bonifacio, IT", code: "fake-679" },
	{
		value: "Marina di Pisticci, IT",
		label: "Marina di Pisticci, IT",
		code: "fake-703",
	},
	{
		value: "Cala Liberotto, IT",
		label: "Cala Liberotto, IT",
		code: "fake-900",
	},
	{ value: "Torre Lapillo, IT", label: "Torre Lapillo, IT", code: "lapi-123" },
	{ value: "Frassanito, IT", label: "Frassanito, IT", code: "Frassanito" },
	{
		value: "Montefiridolfi, IT",
		label: "Montefiridolfi, IT",
		code: "SienaMontefiridolfi",
	},
	{
		value: "Fai della Paganella, IT",
		label: "Fai della Paganella, IT",
		code: "417",
	},
	{ value: "Alicudi, IT", label: "Alicudi, IT", code: "ALI" },
	{ value: "Amalfi, IT", label: "Amalfi, IT", code: "AMF" },
	{ value: "Barolo, IT", label: "Barolo, IT", code: "BRL" },
	{ value: "Como (Provincia), IT", label: "Como (Provincia), IT", code: "CMO" },
	{ value: "Noto, IT", label: "Noto, IT", code: "CTA" },
	{ value: "Grado, IT", label: "Grado, IT", code: "GRA" },
	{ value: "Lodi, IT", label: "Lodi, IT", code: "LDI" },
	{ value: "Mulazzo, IT", label: "Mulazzo, IT", code: "MUL" },
	{ value: "Pizzo, IT", label: "Pizzo, IT", code: "PIZ" },
	{ value: "Recco, IT", label: "Recco, IT", code: "REC" },
	{ value: "Rieti, IT", label: "Rieti, IT", code: "RTT" },
	{ value: "Albenga, IT", label: "Albenga, IT", code: "ALL1" },
	{ value: "Ceriale, IT", label: "Ceriale, IT", code: "ALL2" },
	{
		value: "Provincia de Alessandria, IT",
		label: "Provincia de Alessandria, IT",
		code: "ALSS",
	},
	{ value: "Marotta, IT", label: "Marotta, IT", code: "AOI1" },
	{
		value: "Bérgamo (Provincia), IT",
		label: "Bérgamo (Provincia), IT",
		code: "BGY3",
	},
	{ value: "Bolonia, IT", label: "Bolonia, IT", code: "BLGN" },
	{ value: "Baveno, IT", label: "Baveno, IT", code: "BVNO" },
	{
		value: "Vals, Trentino Alto Adige, IT",
		label: "Vals, Trentino Alto Adige, IT",
		code: "BZO2",
	},
	{
		value: "Valle Anterselva, Trentino Alto Adige, IT",
		label: "Valle Anterselva, Trentino Alto Adige, IT",
		code: "BZO3",
	},
	{ value: "Cannobio, IT", label: "Cannobio, IT", code: "CNNB" },
	{
		value: "Cremona (Provincia), IT",
		label: "Cremona (Provincia), IT",
		code: "CRMN",
	},
	{
		value: "Ferrara (Provincia), IT",
		label: "Ferrara (Provincia), IT",
		code: "FRRR",
	},
	{
		value: "Albissola Marina, IT",
		label: "Albissola Marina, IT",
		code: "GOA1",
	},
	{ value: "Celle Ligure, IT", label: "Celle Ligure, IT", code: "GOA5" },
	{
		value: "Génova (Provincia), IT",
		label: "Génova (Provincia), IT",
		code: "GOA7",
	},
	{
		value: "Imperia (Provincia), IT",
		label: "Imperia (Provincia), IT",
		code: "GOA8",
	},
	{
		value: "Lecco (Provincia), IT",
		label: "Lecco (Provincia), IT",
		code: "LCCO",
	},
	{ value: "La Morra, IT", label: "La Morra, IT", code: "LMRR" },
	{
		value: "Latina (Provincia), IT",
		label: "Latina (Provincia), IT",
		code: "LTNA",
	},
	{
		value: "Travedona-Monate, IT",
		label: "Travedona-Monate, IT",
		code: "LUG3",
	},
	{
		value: "Brovello-Carpugnino, IT",
		label: "Brovello-Carpugnino, IT",
		code: "LUG4",
	},
	{
		value: "Módena (Provincia), IT",
		label: "Módena (Provincia), IT",
		code: "MDNA",
	},
	{ value: "Crema, IT", label: "Crema, IT", code: "MIL2" },
	{ value: "Milo, IT", label: "Milo, IT", code: "MILO" },
	{ value: "Marcon, IT", label: "Marcon, IT", code: "MRCN" },
	{ value: "Mascali, IT", label: "Mascali, IT", code: "MSCL" },
	{ value: "Mira, IT", label: "Mira, IT", code: "Mira" },
	{ value: "Novi Ligure, IT", label: "Novi Ligure, IT", code: "NVLG" },
	{
		value: "Ozzano dell&apos;Emilia, IT",
		label: "Ozzano dell&apos;Emilia, IT",
		code: "ODEi",
	},
	{ value: "Marana, IT", label: "Marana, IT", code: "OLB3" },
	{ value: "Cala di Volpe, IT", label: "Cala di Volpe, IT", code: "OLB4" },
	{
		value: "Golfo di Marinella, IT",
		label: "Golfo di Marinella, IT",
		code: "OLB5",
	},
	{
		value: "Porto Rotondo, Cerdeña, IT",
		label: "Porto Rotondo, Cerdeña, IT",
		code: "OLB6",
	},
	{
		value: "Pordenone (Provincia), IT",
		label: "Pordenone (Provincia), IT",
		code: "PRDN",
	},
	{
		value: "Parma (Provincia), IT",
		label: "Parma (Provincia), IT",
		code: "PRMA",
	},
	{ value: "Riomaggiore, IT", label: "Riomaggiore, IT", code: "PSA1" },
	{
		value: "Roseto degli Abruzzi, IT",
		label: "Roseto degli Abruzzi, IT",
		code: "PSR1",
	},
	{ value: "Alba Adriatica, IT", label: "Alba Adriatica, IT", code: "PSR2" },
	{ value: "Silvi, IT", label: "Silvi, IT", code: "PSR3" },
	{
		value: "Plasencia (Provincia), IT",
		label: "Plasencia (Provincia), IT",
		code: "PZNC",
	},
	{
		value: "Rieti (Provincia), IT",
		label: "Rieti (Provincia), IT",
		code: "RIET",
	},
	{
		value: "Bellaria-Igea Marina, IT",
		label: "Bellaria-Igea Marina, IT",
		code: "RMI2",
	},
	{
		value: "Roma (Provincia), IT",
		label: "Roma (Provincia), IT",
		code: "ROM9",
	},
	{
		value: "Rávena (Provincia), IT",
		label: "Rávena (Provincia), IT",
		code: "RVNA",
	},
	{
		value: "San Genesio Ed Uniti, IT",
		label: "San Genesio Ed Uniti, IT",
		code: "SNGU",
	},
	{
		value: "Sant&apos;Antonio sullo Ionio, IT",
		label: "Sant&apos;Antonio sullo Ionio, IT",
		code: "SUF2",
	},
	{ value: "Tortona, IT", label: "Tortona, IT", code: "TORT" },
	{ value: "Asti, IT", label: "Asti, IT", code: "TRN1" },
	{
		value: "Udine (Provincia), IT",
		label: "Udine (Provincia), IT",
		code: "UDNE",
	},
	{
		value: "Brescia (Provincia), IT",
		label: "Brescia (Provincia), IT",
		code: "VBS3",
	},
	{ value: "Vercelli, IT", label: "Vercelli, IT", code: "VERC" },
	{ value: "Voghera, IT", label: "Voghera, IT", code: "VGHR" },
	{ value: "Vigevano, IT", label: "Vigevano, IT", code: "VIGB" },
	{ value: "Negrar, IT", label: "Negrar, IT", code: "VRN2" },
	{
		value: "Viterbo (Provincia), IT",
		label: "Viterbo (Provincia), IT",
		code: "VTRB",
	},
	{ value: "Ruvo di Puglia, IT", label: "Ruvo di Puglia, IT", code: "ruvo" },
	{
		value: "Trieste (Provincia), IT",
		label: "Trieste (Provincia), IT",
		code: "tRST",
	},
	{ value: "Acqui Terme, IT", label: "Acqui Terme, IT", code: "ACQ-6" },
	{ value: "Albarella, IT", label: "Albarella, IT", code: "ALBRL" },
	{ value: "Anzio, IT", label: "Anzio, IT", code: "ANZ-1" },
	{ value: "Arona, IT", label: "Arona, IT", code: "ARONA" },
	{ value: "Avigliana, IT", label: "Avigliana, IT", code: "AVI-6" },
	{
		value: "Bassano del Grappa, IT",
		label: "Bassano del Grappa, IT",
		code: "BDG-1",
	},
	{ value: "Bra, IT", label: "Bra, IT", code: "BRA-6" },
	{
		value: "Casale Monferrato, IT",
		label: "Casale Monferrato, IT",
		code: "CAS-6",
	},
	{ value: "Ceva, IT", label: "Ceva, IT", code: "CEV-6" },
	{ value: "Chivasso, IT", label: "Chivasso, IT", code: "CHI-6" },
	{ value: "Domodossola, IT", label: "Domodossola, IT", code: "DOM-6" },
	{ value: "Foligno, IT", label: "Foligno, IT", code: "FOL-1" },
	{ value: "Forlì-Cesena, IT", label: "Forlì-Cesena, IT", code: "FRLCS" },
	{
		value: "La Spezia (Provincia), IT",
		label: "La Spezia (Provincia), IT",
		code: "GOA10",
	},
	{
		value: "Savona (Provincia), IT",
		label: "Savona (Provincia), IT",
		code: "GOA11",
	},
	{ value: "Gorizia, IT", label: "Gorizia, IT", code: "GRZIA" },
	{ value: "Ivrea, IT", label: "Ivrea, IT", code: "IVR-6" },
	{ value: "Lanzo Torinese, IT", label: "Lanzo Torinese, IT", code: "LAN-6" },
	{ value: "Li Cuncheddi, IT", label: "Li Cuncheddi, IT", code: "LIC-6" },
	{ value: "Meina, IT", label: "Meina, IT", code: "MEINA" },
	{ value: "Minori, IT", label: "Minori, IT", code: "MIN-1" },
	{
		value: "Montecchio Emilia, IT",
		label: "Montecchio Emilia, IT",
		code: "MON-1",
	},
	{ value: "Montefiascone, IT", label: "Montefiascone, IT", code: "MON-2" },
	{ value: "Montegranaro, IT", label: "Montegranaro, IT", code: "MON-3" },
	{ value: "Montepaone, IT", label: "Montepaone, IT", code: "MON-4" },
	{ value: "Monterotondo, IT", label: "Monterotondo, IT", code: "MON-5" },
	{ value: "Montevarchi, IT", label: "Montevarchi, IT", code: "MON-6" },
	{
		value: "Motta Sant &apos;Anastasia, IT",
		label: "Motta Sant &apos;Anastasia, IT",
		code: "MOT-2",
	},
	{ value: "Mozzo, IT", label: "Mozzo, IT", code: "MOZ-1" },
	{ value: "Marta, IT", label: "Marta, IT", code: "MRT-1" },
	{ value: "Mascalucia, IT", label: "Mascalucia, IT", code: "MSCLC" },
	{
		value: "Monte Sant&apos;Angelo, IT",
		label: "Monte Sant&apos;Angelo, IT",
		code: "Monte",
	},
	{ value: "Nova Levante, IT", label: "Nova Levante, IT", code: "NVLVT" },
	{ value: "Narni , IT", label: "Narni , IT", code: "Narni" },
	{ value: "Neive, IT", label: "Neive, IT", code: "Neive" },
	{ value: "Ome, IT", label: "Ome, IT", code: "OME-6" },
	{ value: "Ortona, IT", label: "Ortona, IT", code: "ORT-1" },
	{ value: "Pescantina, IT", label: "Pescantina, IT", code: "PESCA" },
	{ value: "Rho, IT", label: "Rho, IT", code: "PHO-1" },
	{ value: "Piombino, IT", label: "Piombino, IT", code: "PIO-1" },
	{ value: "Pisciotta, IT", label: "Pisciotta, IT", code: "PIS-1" },
	{
		value: "Portopalo Di Capo Passero, IT",
		label: "Portopalo Di Capo Passero, IT",
		code: "POR-1",
	},
	{ value: "Posada, IT", label: "Posada, IT", code: "POS-1" },
	{ value: "Paula , IT", label: "Paula , IT", code: "Paula" },
	{ value: "Reggello, IT", label: "Reggello, IT", code: "REG-1" },
	{
		value: "Reggio Emilia (Provincia), IT",
		label: "Reggio Emilia (Provincia), IT",
		code: "REGML",
	},
	{
		value: "Rimini (Provincia), IT",
		label: "Rimini (Provincia), IT",
		code: "RMNPR",
	},
	{ value: "Frosinone, IT", label: "Frosinone, IT", code: "ROM11" },
	{
		value: "Frosinone (Provincia), IT",
		label: "Frosinone (Provincia), IT",
		code: "ROM22",
	},
	{
		value: "Ronchi Dei Legionari, IT",
		label: "Ronchi Dei Legionari, IT",
		code: "RON-1",
	},
	{ value: "Rossano, IT", label: "Rossano, IT", code: "ROS-2" },
	{ value: "Ritten, IT", label: "Ritten, IT", code: "Renon" },
	{
		value: "Rocca San Giovanni, IT",
		label: "Rocca San Giovanni, IT",
		code: "Rocca",
	},
	{ value: "Sabaudia, IT", label: "Sabaudia, IT", code: "SAB-1" },
	{
		value: "Salento (Oriental), IT",
		label: "Salento (Oriental), IT",
		code: "SAL-1",
	},
	{
		value: "Salento (Occidental), IT",
		label: "Salento (Occidental), IT",
		code: "SAL-2",
	},
	{
		value: "San Giuliano Terme, IT",
		label: "San Giuliano Terme, IT",
		code: "SGT-1",
	},
	{ value: "Simeri Crichi, IT", label: "Simeri Crichi, IT", code: "SIMER" },
	{ value: "Sirolo, IT", label: "Sirolo, IT", code: "SRL-1" },
	{
		value: "Serralunga d&apos;Alba, IT",
		label: "Serralunga d&apos;Alba, IT",
		code: "SRRLG",
	},
	{
		value: "Torri del Benaco, IT",
		label: "Torri del Benaco, IT",
		code: "TDB-1",
	},
	{ value: "Terontola, IT", label: "Terontola, IT", code: "TER-1" },
	{ value: "Valle Senales, IT", label: "Valle Senales, IT", code: "VAL-6" },
	{ value: "Verbania, IT", label: "Verbania, IT", code: "VRB-1" },
	{
		value: "Agrigento (Provincia), IT",
		label: "Agrigento (Provincia), IT",
		code: "AGRP-1",
	},
	{ value: "Anagni, IT", label: "Anagni, IT", code: "ANAG-6" },
	{ value: "Valle de Aosta, IT", label: "Valle de Aosta, IT", code: "AOVA-1" },
	{ value: "Apulia, IT", label: "Apulia, IT", code: "APUL-1" },
	{
		value: "Avellino (Provincia), IT",
		label: "Avellino (Provincia), IT",
		code: "AVEp-1",
	},
	{ value: "Bahia Verde, IT", label: "Bahia Verde, IT", code: "BAH-76" },
	{ value: "Baja Sardinia, IT", label: "Baja Sardinia, IT", code: "BAJS-1" },
	{
		value: "Bari (Provincia), IT",
		label: "Bari (Provincia), IT",
		code: "BAPR-1",
	},
	{ value: "Bari Sardo, IT", label: "Bari Sardo, IT", code: "BARS-1" },
	{ value: "Bassiano, IT", label: "Bassiano, IT", code: "BASS-1" },
	{
		value: "Barletta-Andria-Trani (Provincia), IT",
		label: "Barletta-Andria-Trani (Provincia), IT",
		code: "BATP-1",
	},
	{ value: "Battipaglia, IT", label: "Battipaglia, IT", code: "BATT-1" },
	{ value: "Belluno, IT", label: "Belluno, IT", code: "BEL-54" },
	{
		value: "Benevento (Provincia), IT",
		label: "Benevento (Provincia), IT",
		code: "BENP-1",
	},
	{ value: "Biancavilla, IT", label: "Biancavilla, IT", code: "BIAN-1" },
	{ value: "Bogogno, IT", label: "Bogogno, IT", code: "BOGO-1" },
	{ value: "Bonifati, IT", label: "Bonifati, IT", code: "BONI-6" },
	{
		value: "Brindisi (Provincia), IT",
		label: "Brindisi (Provincia), IT",
		code: "BRIP-1",
	},
	{ value: "Butera, IT", label: "Butera, IT", code: "But-34" },
	{ value: "Caltagirone, IT", label: "Caltagirone, IT", code: "CAG-43" },
	{
		value: "Cagliari (Province), IT",
		label: "Cagliari (Province), IT",
		code: "CAGP-1",
	},
	{
		value: "Caltanissetta (Provincia), IT",
		label: "Caltanissetta (Provincia), IT",
		code: "CALP-1",
	},
	{
		value: "Campione d&apos;Italia, IT",
		label: "Campione d&apos;Italia, IT",
		code: "CAM-88",
	},
	{
		value: "Castellaneta Marina, IT",
		label: "Castellaneta Marina, IT",
		code: "CAMA-1",
	},
	{ value: "Campania, IT", label: "Campania, IT", code: "CAMP-1" },
	{ value: "Capoterra, IT", label: "Capoterra, IT", code: "CAPO-1" },
	{ value: "Capoliveri, IT", label: "Capoliveri, IT", code: "CAPO-2" },
	{
		value: "Catania (Provincia), IT",
		label: "Catania (Provincia), IT",
		code: "CAPR-1",
	},
	{
		value: "Caserta (Provincia), IT",
		label: "Caserta (Provincia), IT",
		code: "CASP-1",
	},
	{
		value: "Catanzaro (Provincia), IT",
		label: "Catanzaro (Provincia), IT",
		code: "CATP-1",
	},
	{ value: "Caltanissetta, IT", label: "Caltanissetta, IT", code: "CAl-43" },
	{
		value: "Cecina, Toscana, IT",
		label: "Cecina, Toscana, IT",
		code: "CECI-6",
	},
	{
		value: "Chia, Sardegna Sud, IT",
		label: "Chia, Sardegna Sud, IT",
		code: "CHIA-6",
	},
	{
		value: "Chieti (Provincia), IT",
		label: "Chieti (Provincia), IT",
		code: "CHPR-1",
	},
	{
		value: "Civitella Paganico, IT",
		label: "Civitella Paganico, IT",
		code: "CIPA-1",
	},
	{ value: "Calabria, IT", label: "Calabria, IT", code: "CLBR-1" },
	{ value: "Clusane, IT", label: "Clusane, IT", code: "CLUS-1" },
	{ value: "Coazzolo, IT", label: "Coazzolo, IT", code: "COAZ-1" },
	{
		value: "Contrada Rais Gerbi, IT",
		label: "Contrada Rais Gerbi, IT",
		code: "CORG-1",
	},
	{
		value: "Crotona (Provincia), IT",
		label: "Crotona (Provincia), IT",
		code: "CROP-1",
	},
	{ value: "Diamante, IT", label: "Diamante, IT", code: "DIAM-1" },
	{ value: "Dolomitas, IT", label: "Dolomitas, IT", code: "DOLO-1" },
	{
		value: "Porto Empedocle, IT",
		label: "Porto Empedocle, IT",
		code: "EMP-11",
	},
	{ value: "Empoli, IT", label: "Empoli, IT", code: "EMPO-1" },
	{ value: "Emilia-Romaña, IT", label: "Emilia-Romaña, IT", code: "EMRO-1" },
	{ value: "Enna, IT", label: "Enna, IT", code: "ENA-21" },
	{
		value: "Enna (Provincia), IT",
		label: "Enna (Provincia), IT",
		code: "ENNP-1",
	},
	{ value: "Filandari, IT", label: "Filandari, IT", code: "FILA-1" },
	{ value: "Fiuggi, IT", label: "Fiuggi, IT", code: "FIUG-1" },
	{
		value: "Florencia (Provincia), IT",
		label: "Florencia (Provincia), IT",
		code: "FLOR-6",
	},
	{ value: "Fiesole, IT", label: "Fiesole, IT", code: "FLR-10" },
	{
		value: "Foggia (Provincia), IT",
		label: "Foggia (Provincia), IT",
		code: "FOGP-1",
	},
	{ value: "Folgaria, IT", label: "Folgaria, IT", code: "FOLG-6" },
	{
		value: "Friul-Venecia Julia, IT",
		label: "Friul-Venecia Julia, IT",
		code: "FRVG-1",
	},
	{ value: "Gallarate, IT", label: "Gallarate, IT", code: "GALL-1" },
	{ value: "Gasperina, IT", label: "Gasperina, IT", code: "GASP-6" },
	{
		value: "Gressoney-Saint-Jean, IT",
		label: "Gressoney-Saint-Jean, IT",
		code: "GRES-1",
	},
	{ value: "Giarre , IT", label: "Giarre , IT", code: "Giarre" },
	{ value: "Ginosa, IT", label: "Ginosa, IT", code: "Ginosa" },
	{ value: "Ladispoli, IT", label: "Ladispoli, IT", code: "LAD-21" },
	{ value: "Lamporecchio, IT", label: "Lamporecchio, IT", code: "LAM-21" },
	{
		value: "L&apos;Aquila (Provincia), IT",
		label: "L&apos;Aquila (Provincia), IT",
		code: "LAPR-1",
	},
	{ value: "Lastra a Signa, IT", label: "Lastra a Signa, IT", code: "LAS-99" },
	{ value: "Lavarone, IT", label: "Lavarone, IT", code: "LAVA-1" },
	{ value: "Lazise, IT", label: "Lazise, IT", code: "LAZ-76" },
	{ value: "Lacio, IT", label: "Lacio, IT", code: "LAZI-1" },
	{
		value: "Lecce (Provincia), IT",
		label: "Lecce (Provincia), IT",
		code: "LECP-1",
	},
	{ value: "Levanto, IT", label: "Levanto, IT", code: "LEVA-1" },
	{
		value: "Lido delle Nazioni, IT",
		label: "Lido delle Nazioni, IT",
		code: "LIDO-6",
	},
	{ value: "Liguria, IT", label: "Liguria, IT", code: "LIGU-1" },
	{ value: "Limbiate, IT", label: "Limbiate, IT", code: "LIM-66" },
	{ value: "Linguaglossa, IT", label: "Linguaglossa, IT", code: "LIN-33" },
	{
		value: "Livorno (Provincia), IT",
		label: "Livorno (Provincia), IT",
		code: "LIVO-6",
	},
	{ value: "Lombardía, IT", label: "Lombardía, IT", code: "LOMB-1" },
	{ value: "Lotzorai, IT", label: "Lotzorai, IT", code: "LOTZ-1" },
	{ value: "Lago Iseo, IT", label: "Lago Iseo, IT", code: "LSEO-1" },
	{
		value: "Limone sul Garda, IT",
		label: "Limone sul Garda, IT",
		code: "LSGA-1",
	},
	{ value: "Luvinate, IT", label: "Luvinate, IT", code: "LUVI-1" },
	{ value: "Maiori, IT", label: "Maiori, IT", code: "MAIO-1" },
	{ value: "Manciano, IT", label: "Manciano, IT", code: "MANC-1" },
	{ value: "Manfredonia, IT", label: "Manfredonia, IT", code: "MANF-1" },
	{
		value: "Marciana Marina, IT",
		label: "Marciana Marina, IT",
		code: "MARC-1",
	},
	{ value: "Martina Franca, IT", label: "Martina Franca, IT", code: "MARF-1" },
	{
		value: "Marina di Sibari, Calabria, IT",
		label: "Marina di Sibari, Calabria, IT",
		code: "MARI-6",
	},
	{
		value: "Matera (Provincia), IT",
		label: "Matera (Provincia), IT",
		code: "MATP-1",
	},
	{ value: "Mazzin, IT", label: "Mazzin, IT", code: "MAZZ-1" },
	{
		value: "Marina di Mancaversa, IT",
		label: "Marina di Mancaversa, IT",
		code: "MDM-99",
	},
	{
		value: "Marina di Ugento, Puglia, IT",
		label: "Marina di Ugento, Puglia, IT",
		code: "MDUP-1",
	},
	{
		value: "Mazara del Vallo, IT",
		label: "Mazara del Vallo, IT",
		code: "MDV-54",
	},
	{ value: "Milazzo, IT", label: "Milazzo, IT", code: "MIL-77" },
	{ value: "Montesilvano, IT", label: "Montesilvano, IT", code: "MNTS-1" },
	{
		value: "Monterosso al Mare, IT",
		label: "Monterosso al Mare, IT",
		code: "MOAM-1",
	},
	{ value: "Morano Cálabro, IT", label: "Morano Cálabro, IT", code: "MOCA-1" },
	{ value: "Molise, IT", label: "Molise, IT", code: "MOLI-1" },
	{ value: "Molveno, IT", label: "Molveno, IT", code: "MOLV-1" },
	{
		value: "Montecchio Maggiore, IT",
		label: "Montecchio Maggiore, IT",
		code: "MOMA-1",
	},
	{ value: "Mondovi, IT", label: "Mondovi, IT", code: "MOND-6" },
	{ value: "Mesagne, IT", label: "Mesagne, IT", code: "MSGN-6" },
	{ value: "Morgex, IT", label: "Morgex, IT", code: "Morgex" },
	{
		value: "Nápoles (Provincia), IT",
		label: "Nápoles (Provincia), IT",
		code: "NAPP-1",
	},
	{ value: "Naregno, IT", label: "Naregno, IT", code: "NARE-1" },
	{ value: "Noicàttaro, IT", label: "Noicàttaro, IT", code: "NOIC-1" },
	{ value: "Nova Siri, IT", label: "Nova Siri, IT", code: "NOSI-1" },
	{
		value: "Nocera Terinese, IT",
		label: "Nocera Terinese, IT",
		code: "NOTE-1",
	},
	{ value: "Numana, IT", label: "Numana, IT", code: "NUMA-1" },
	{
		value: "Nuoro (Provincia), IT",
		label: "Nuoro (Provincia), IT",
		code: "NUOP-1",
	},
	{ value: "Valdaora, IT", label: "Valdaora, IT", code: "OLAN-1" },
	{
		value: "Oristán (Provincia), IT",
		label: "Oristán (Provincia), IT",
		code: "ORIP-1",
	},
	{ value: "Ossona, IT", label: "Ossona, IT", code: "OSSO-1" },
	{ value: "Oderzo, IT", label: "Oderzo, IT", code: "Oderzo" },
	{ value: "Oliena , IT", label: "Oliena , IT", code: "Oliena" },
	{ value: "Palaia, IT", label: "Palaia, IT", code: "PAL-11" },
	{ value: "Panarea, IT", label: "Panarea, IT", code: "PANA-1" },
	{ value: "Parghelia, IT", label: "Parghelia, IT", code: "PARG-1" },
	{ value: "Patti, IT", label: "Patti, IT", code: "PATT-1" },
	{
		value: "Peschiera Borromeo, IT",
		label: "Peschiera Borromeo, IT",
		code: "PEBO-1",
	},
	{
		value: "Pescara (Provincia), IT",
		label: "Pescara (Provincia), IT",
		code: "PEPR-1",
	},
	{ value: "Piacenza, IT", label: "Piacenza, IT", code: "PIA-22" },
	{
		value: "Piazza Armerina, IT",
		label: "Piazza Armerina, IT",
		code: "PIA-55",
	},
	{ value: "Piamonte, IT", label: "Piamonte, IT", code: "PIED-1" },
	{
		value: "Piamonte (Provincia), IT",
		label: "Piamonte (Provincia), IT",
		code: "PIEM-6",
	},
	{
		value: "Pila, Valle d’Aosta, IT",
		label: "Pila, Valle d’Aosta, IT",
		code: "PILA-1",
	},
	{ value: "Pietra Ligure, IT", label: "Pietra Ligure, IT", code: "PILI-1" },
	{ value: "v, IT", label: "v, IT", code: "PIMO-1" },
	{
		value: "Pisa (Provincia), IT",
		label: "Pisa (Provincia), IT",
		code: "PISA-6",
	},
	{ value: "Policastro, IT", label: "Policastro, IT", code: "POLI-1" },
	{ value: "Pomezia, IT", label: "Pomezia, IT", code: "POMZ-1" },
	{ value: "Ponza, IT", label: "Ponza, IT", code: "PONZ-1" },
	{ value: "Porto Conte, IT", label: "Porto Conte, IT", code: "PORC-1" },
	{ value: "Porto Pollo, IT", label: "Porto Pollo, IT", code: "PORP-1" },
	{
		value: "Potenza (Provincia), IT",
		label: "Potenza (Provincia), IT",
		code: "POTP-1",
	},
	{ value: "Pratolino, IT", label: "Pratolino, IT", code: "PRA-12" },
	{ value: "Pregnana, IT", label: "Pregnana, IT", code: "PRE-11" },
	{ value: "Preganziol, IT", label: "Preganziol, IT", code: "PRE-55" },
	{ value: "Presezzo, IT", label: "Presezzo, IT", code: "PRE-77" },
	{ value: "Peschici, IT", label: "Peschici, IT", code: "PSCH-1" },
	{
		value: "Porto San Giorgio, IT",
		label: "Porto San Giorgio, IT",
		code: "PSG-12",
	},
	{
		value: "Pula, Sardegna Sud, IT",
		label: "Pula, Sardegna Sud, IT",
		code: "PUL-22",
	},
	{
		value: "Val Pusteria, Trentino Alto Adige, IT",
		label: "Val Pusteria, Trentino Alto Adige, IT",
		code: "PUST-6",
	},
	{ value: "Punta Ala, IT", label: "Punta Ala, IT", code: "PUT-21" },
	{ value: "Paceco, IT", label: "Paceco, IT", code: "Paceco" },
	{ value: "Popoli , IT", label: "Popoli , IT", code: "Popoli" },
	{
		value: "Rasun Anterselva, IT",
		label: "Rasun Anterselva, IT",
		code: "RAAN-1",
	},
	{ value: "Racale, IT", label: "Racale, IT", code: "RACA-6" },
	{
		value: "Regio de Calabria (Provincia), IT",
		label: "Regio de Calabria (Provincia), IT",
		code: "RCAP-1",
	},
	{ value: "Rocca di Mezzo, IT", label: "Rocca di Mezzo, IT", code: "RDIM-1" },
	{ value: "Reggio Emilia, IT", label: "Reggio Emilia, IT", code: "REG-12" },
	{ value: "Rende, IT", label: "Rende, IT", code: "REND-1" },
	{ value: "Riolunato, IT", label: "Riolunato, IT", code: "RIOL-1" },
	{
		value: "Rio nell&apos;Elba, IT",
		label: "Rio nell&apos;Elba, IT",
		code: "RNEE-1",
	},
	{
		value: "Rocca Pietore, Véneto, IT",
		label: "Rocca Pietore, Véneto, IT",
		code: "ROC-12",
	},
	{ value: "Roccalumera, IT", label: "Roccalumera, IT", code: "ROCC-1" },
	{
		value: "Rosignano Marittimo, IT",
		label: "Rosignano Marittimo, IT",
		code: "ROMA-1",
	},
	{ value: "Rosarno, IT", label: "Rosarno, IT", code: "ROSA-1" },
	{ value: "Rovereto, IT", label: "Rovereto, IT", code: "ROVE-1" },
	{
		value: "Sant&apos;Agata de&apos; Goti, IT",
		label: "Sant&apos;Agata de&apos; Goti, IT",
		code: "SADG-1",
	},
	{
		value: "San Felice Circeo, IT",
		label: "San Felice Circeo, IT",
		code: "SAFC-1",
	},
	{ value: "Isla de Salina, IT", label: "Isla de Salina, IT", code: "SALI-1" },
	{
		value: "Salerno (Provincia), IT",
		label: "Salerno (Provincia), IT",
		code: "SALP-1",
	},
	{
		value: "Sant&apos;Alessio Siculo, IT",
		label: "Sant&apos;Alessio Siculo, IT",
		code: "SALS-1",
	},
	{ value: "San Sicario, IT", label: "San Sicario, IT", code: "SANS-1" },
	{ value: "Sapri, IT", label: "Sapri, IT", code: "SAPR-1" },
	{ value: "Saronno, IT", label: "Saronno, IT", code: "SAR-44" },
	{
		value: "Sácer (Provincia), IT",
		label: "Sácer (Provincia), IT",
		code: "SASP-1",
	},
	{ value: "San Vincenzo, IT", label: "San Vincenzo, IT", code: "SAVI-1" },
	{
		value: "San Bartolomeo al Mare, IT",
		label: "San Bartolomeo al Mare, IT",
		code: "SBAM-1",
	},
	{ value: "Scanno, IT", label: "Scanno, IT", code: "SCA-22" },
	{ value: "Scandicci, IT", label: "Scandicci, IT", code: "SCA-32" },
	{ value: "Scafati, IT", label: "Scafati, IT", code: "SCFT-1" },
	{
		value: "Sciacca, Sicilia, IT",
		label: "Sciacca, Sicilia, IT",
		code: "SCI-22",
	},
	{ value: "Schignano, IT", label: "Schignano, IT", code: "SCI-66" },
	{
		value: "San Casciano in Val di Pesa, IT",
		label: "San Casciano in Val di Pesa, IT",
		code: "SCIV-1",
	},
	{
		value: "Sauze d&apos;Oulx, IT",
		label: "Sauze d&apos;Oulx, IT",
		code: "SDX-21",
	},
	{ value: "Sestri Levante, IT", label: "Sestri Levante, IT", code: "SES-44" },
	{
		value: "San Giovanni la Punta, IT",
		label: "San Giovanni la Punta, IT",
		code: "SGLP-1",
	},
	{ value: "Signa, IT", label: "Signa, IT", code: "SIG-55" },
	{ value: "Siniscola, IT", label: "Siniscola, IT", code: "SINI-1" },
	{ value: "Sirmione, IT", label: "Sirmione, IT", code: "SIRM-1" },
	{
		value: "San Michele al Tagliamento, IT",
		label: "San Michele al Tagliamento, IT",
		code: "SMAT-1",
	},
	{
		value: "San Martino Buon Albergo, IT",
		label: "San Martino Buon Albergo, IT",
		code: "SMBA-1",
	},
	{
		value: "São Martinho do Porto, IT",
		label: "São Martinho do Porto, IT",
		code: "SMDP-1",
	},
	{
		value: "Santa Marinella, IT",
		label: "Santa Marinella, IT",
		code: "SMR-21",
	},
	{ value: "San Cataldo, IT", label: "San Cataldo, IT", code: "SNC-01" },
	{
		value: "Cerdeña del Sur (Provincia), IT",
		label: "Cerdeña del Sur (Provincia), IT",
		code: "SOSP-1",
	},
	{ value: "Esquilache, IT", label: "Esquilache, IT", code: "SQUI-6" },
	{
		value: "Sesto San Giovanni, IT",
		label: "Sesto San Giovanni, IT",
		code: "SSG-77",
	},
	{
		value: "Santo Stefano di Sessanio, IT",
		label: "Santo Stefano di Sessanio, IT",
		code: "SST-22",
	},
	{
		value: "Santo Stefano di Camastra, IT",
		label: "Santo Stefano di Camastra, IT",
		code: "STF-54",
	},
	{ value: "Estrómboli, IT", label: "Estrómboli, IT", code: "STRO-1" },
	{ value: "Susa, IT", label: "Susa, IT", code: "SUSA-6" },
	{
		value: "Tarento (Provincia), IT",
		label: "Tarento (Provincia), IT",
		code: "TARP-1",
	},
	{
		value: "Tempio Pausania, IT",
		label: "Tempio Pausania, IT",
		code: "TEPA-1",
	},
	{
		value: "Teramo (Provincia), IT",
		label: "Teramo (Provincia), IT",
		code: "TEPR-1",
	},
	{
		value: "Terento, Trentino Alto Adige, IT",
		label: "Terento, Trentino Alto Adige, IT",
		code: "TERT-6",
	},
	{ value: "Telese Terme, IT", label: "Telese Terme, IT", code: "TETE-1" },
	{ value: "Tirrenia, IT", label: "Tirrenia, IT", code: "TIR-54" },
	{
		value: "Toscolano-Maderno, IT",
		label: "Toscolano-Maderno, IT",
		code: "TOMA-1",
	},
	{
		value: "Marina di Bibbona, Toscana, IT",
		label: "Marina di Bibbona, Toscana, IT",
		code: "TOSC-6",
	},
	{ value: "Umbría, IT", label: "Umbría, IT", code: "UMBR-1" },
	{ value: "Ustica, IT", label: "Ustica, IT", code: "Ustica" },
	{ value: "Val di Zoldo, IT", label: "Val di Zoldo, IT", code: "VADZ-1" },
	{
		value: "Valeggio sul Mincio, IT",
		label: "Valeggio sul Mincio, IT",
		code: "VASM-1",
	},
	{ value: "Véneto, IT", label: "Véneto, IT", code: "VENE-1" },
	{ value: "Venosa, IT", label: "Venosa, IT", code: "VENOSA" },
	{ value: "Viagrande, IT", label: "Viagrande, IT", code: "VIAG-1" },
	{ value: "Vimercate, IT", label: "Vimercate, IT", code: "VIME-1" },
	{
		value: "Vibo Valentia (Provincia), IT",
		label: "Vibo Valentia (Provincia), IT",
		code: "VIVP-1",
	},
	{
		value: "Vietri sul Mare , IT",
		label: "Vietri sul Mare , IT",
		code: "Vietri",
	},
	{ value: "Castelvetrano, IT", label: "Castelvetrano, IT", code: "XCA-54" },
	{ value: "Torre Vado, IT", label: "Torre Vado, IT", code: "sdfsdf" },
	{
		value: "Ancona (Provincia), IT",
		label: "Ancona (Provincia), IT",
		code: "ACN-002",
	},
	{ value: "Aci Castello, IT", label: "Aci Castello, IT", code: "ACOST-6" },
	{
		value: "Ascoli Piceno (Provincia), IT",
		label: "Ascoli Piceno (Provincia), IT",
		code: "ALP-002",
	},
	{ value: "Ascoli Piceno, IT", label: "Ascoli Piceno, IT", code: "APO-002" },
	{ value: "Asiago, Veneto, IT", label: "Asiago, Veneto, IT", code: "ASIGO-6" },
	{
		value: "Asti (Provincia), IT",
		label: "Asti (Provincia), IT",
		code: "ATI-002",
	},
	{ value: "Abetone, IT", label: "Abetone, IT", code: "Abetone" },
	{
		value: "Barberino di Mugello, IT",
		label: "Barberino di Mugello, IT",
		code: "BARBE-6",
	},
	{
		value: "Belluno (Provincia), IT",
		label: "Belluno (Provincia), IT",
		code: "BELLU-6",
	},
	{
		value: "Biella (Provincia), IT",
		label: "Biella (Provincia), IT",
		code: "BLA-002",
	},
	{
		value: "Bolzano (Provincia), IT",
		label: "Bolzano (Provincia), IT",
		code: "BOLZA-6",
	},
	{
		value: "Islas Borromeas, IT",
		label: "Islas Borromeas, IT",
		code: "BORRO-6",
	},
	{ value: "Brunico, IT", label: "Brunico, IT", code: "BRC-002" },
	{
		value: "Castione della Presolana, IT",
		label: "Castione della Presolana, IT",
		code: "CDPNA-6",
	},
	{ value: "Cerdeña, IT", label: "Cerdeña, IT", code: "CERDA-6" },
	{ value: "Chioggia, IT", label: "Chioggia, IT", code: "CHG-002" },
	{
		value: "Cúneo (Provincia), IT",
		label: "Cúneo (Provincia), IT",
		code: "CNO-002",
	},
	{
		value: "Corigliano d&apos;Otranto, IT",
		label: "Corigliano d&apos;Otranto, IT",
		code: "COR-193",
	},
	{
		value: "Castione della Presolana, IT",
		label: "Castione della Presolana, IT",
		code: "CPL-002",
	},
	{
		value: "Campobasso (Provincia), IT",
		label: "Campobasso (Provincia), IT",
		code: "CPS-002",
	},
	{ value: "Cirò, IT", label: "Cirò, IT", code: "CRO-002" },
	{
		value: "Costa Esmeralda, Sardinia, IT",
		label: "Costa Esmeralda, Sardinia, IT",
		code: "CSM-002",
	},
	{ value: "Conversano, IT", label: "Conversano, IT", code: "CSN-002" },
	{ value: "Cariati, IT", label: "Cariati, IT", code: "CTI-002" },
	{ value: "Curinga, IT", label: "Curinga, IT", code: "CURIN-6" },
	{ value: "Donnalucata, IT", label: "Donnalucata, IT", code: "DLC-002" },
	{ value: "Éboli , IT", label: "Éboli , IT", code: "EBOLI-6" },
	{ value: "Filicudi, IT", label: "Filicudi, IT", code: "FCD-002" },
	{ value: "Fermo, IT", label: "Fermo, IT", code: "FERMO-6" },
	{
		value: "Fermo (Provincia), IT",
		label: "Fermo (Provincia), IT",
		code: "FMO-002",
	},
	{ value: "Favara, Sicily, IT", label: "Favara, Sicily, IT", code: "FVA-002" },
	{ value: "Galatone , IT", label: "Galatone , IT", code: "GAL-123" },
	{ value: "Gizzeria, IT", label: "Gizzeria, IT", code: "GZR-002" },
	{ value: "Gradara , IT", label: "Gradara , IT", code: "Gradara" },
	{ value: "Impruneta, IT", label: "Impruneta, IT", code: "IMPRU-6" },
	{
		value: "Isernia (Provincia), IT",
		label: "Isernia (Provincia), IT",
		code: "INA-002",
	},
	{ value: "Ispica, IT", label: "Ispica, IT", code: "IPA-002" },
	{ value: "Isla Marettimo, IT", label: "Isla Marettimo, IT", code: "ITO-002" },
	{ value: "Laglio, IT", label: "Laglio, IT", code: "LAG-132" },
	{ value: "Locorotondo, IT", label: "Locorotondo, IT", code: "LCD-002" },
	{ value: "Le Castella, IT", label: "Le Castella, IT", code: "LCT-002" },
	{
		value: "Lodi (Provincia), IT",
		label: "Lodi (Provincia), IT",
		code: "LDI-002",
	},
	{ value: "Lipari, IT", label: "Lipari, IT", code: "LPI-002" },
	{ value: "Lonate Pozzolo, IT", label: "Lonate Pozzolo, IT", code: "LPO-002" },
	{ value: "Linosa, IT", label: "Linosa, IT", code: "LSO-002" },
	{
		value: "Lucca (Provincia), IT",
		label: "Lucca (Provincia), IT",
		code: "LUCCA-6",
	},
	{ value: "Lovere, IT", label: "Lovere, IT", code: "LVR-002" },
	{ value: "Marcas, IT", label: "Marcas, IT", code: "MARCH-1" },
	{
		value: "Marina di Ginosa, Puglia, IT",
		label: "Marina di Ginosa, Puglia, IT",
		code: "MARIN-6",
	},
	{ value: "Massa Lubrense, IT", label: "Massa Lubrense, IT", code: "MASSA-6" },
	{
		value: "Monza y Brianza (Provincia), IT",
		label: "Monza y Brianza (Provincia), IT",
		code: "MBZ-002",
	},
	{ value: "Mocònesi , IT", label: "Mocònesi , IT", code: "MCN-002" },
	{
		value: "Macerata (Provincia), IT",
		label: "Macerata (Provincia), IT",
		code: "MCT-002",
	},
	{ value: "Mirandola, IT", label: "Mirandola, IT", code: "MDL-002" },
	{
		value: "Minervino di Lecce, IT",
		label: "Minervino di Lecce, IT",
		code: "MLC-002",
	},
	{
		value: "Milán (Provincia), IT",
		label: "Milán (Provincia), IT",
		code: "MLN-002",
	},
	{
		value: "Marmolada, Trentino Alto-Adige, IT",
		label: "Marmolada, Trentino Alto-Adige, IT",
		code: "MML-002",
	},
	{ value: "Moltrasio, IT", label: "Moltrasio, IT", code: "MOSIO-6" },
	{ value: "Mesina, IT", label: "Mesina, IT", code: "MSA-002" },
	{ value: "Mistretta, IT", label: "Mistretta, IT", code: "MST-002" },
	{
		value: "Mantua (Provincia), IT",
		label: "Mantua (Provincia), IT",
		code: "MTA-002",
	},
	{ value: "Minturno, IT", label: "Minturno, IT", code: "MTO-002" },
	{
		value: "Manerba del Garda, IT",
		label: "Manerba del Garda, IT",
		code: "Manerba",
	},
	{
		value: "Novara (Provincia), IT",
		label: "Novara (Provincia), IT",
		code: "NRA-002",
	},
	{
		value: "Ogliastro Cilento, IT",
		label: "Ogliastro Cilento, IT",
		code: "OCT-002",
	},
	{ value: "Opera, IT", label: "Opera, IT", code: "OPERA-6" },
	{ value: "Osimo, IT", label: "Osimo, IT", code: "OSM-002" },
	{ value: "Oleggio, IT", label: "Oleggio, IT", code: "Oleggio" },
	{ value: "Olgiate Olona, IT", label: "Olgiate Olona, IT", code: "Olgiate" },
	{
		value: "Padua (Province), IT",
		label: "Padua (Province), IT",
		code: "PADUA-6",
	},
	{
		value: "Paderno Dugnano, IT",
		label: "Paderno Dugnano, IT",
		code: "PADUG-6",
	},
	{
		value: "Palermo (Province), IT",
		label: "Palermo (Province), IT",
		code: "PALER-6",
	},
	{ value: "Palmi, IT", label: "Palmi, IT", code: "PALMI-6" },
	{ value: "Presicce, IT", label: "Presicce, IT", code: "PCE-002" },
	{
		value: "Pergine Valdarno, IT",
		label: "Pergine Valdarno, IT",
		code: "PGV-002",
	},
	{ value: "Peio, IT", label: "Peio, IT", code: "PIO-002" },
	{
		value: "Pistoia (Provincia), IT",
		label: "Pistoia (Provincia), IT",
		code: "PISTO-6",
	},
	{ value: "Pellezzano, IT", label: "Pellezzano, IT", code: "PLLZN-6" },
	{ value: "Porlezza, IT", label: "Porlezza, IT", code: "PLZ-002" },
	{ value: "Premia, IT", label: "Premia, IT", code: "PMA-002" },
	{ value: "Penango, IT", label: "Penango, IT", code: "PNNGO-6" },
	{ value: "Prato Nevoso, IT", label: "Prato Nevoso, IT", code: "PNV-002" },
	{ value: "Pontassieve, IT", label: "Pontassieve, IT", code: "PONTA-6" },
	{ value: "Poppi, IT", label: "Poppi, IT", code: "POPPI-6" },
	{
		value: "Pratola Peligna, IT",
		label: "Pratola Peligna, IT",
		code: "PPN-002",
	},
	{ value: "Praiano, IT", label: "Praiano, IT", code: "PRAIA-1" },
	{
		value: "Prato (Provincia), IT",
		label: "Prato (Provincia), IT",
		code: "PRATO-6",
	},
	{ value: "Aprica, IT", label: "Aprica, IT", code: "PRICA-6" },
	{ value: "Pero, IT", label: "Pero, IT", code: "PRO-002" },
	{ value: "Partinico, IT", label: "Partinico, IT", code: "PTC-002" },
	{ value: "Porto Torres, IT", label: "Porto Torres, IT", code: "PTR-002" },
	{ value: "Portoscuso, IT", label: "Portoscuso, IT", code: "PTS-002" },
	{ value: "Patù, IT", label: "Patù, IT", code: "PTU-002" },
	{
		value: "Pavía (Provincia), IT",
		label: "Pavía (Provincia), IT",
		code: "PVA-002",
	},
	{
		value: "Pesaro y Urbino (Provincia), IT",
		label: "Pesaro y Urbino (Provincia), IT",
		code: "PYU-002",
	},
	{ value: "Premeno , IT", label: "Premeno , IT", code: "Premeno" },
	{
		value: "Quartu Sant Elena, IT",
		label: "Quartu Sant Elena, IT",
		code: "QSE-002",
	},
	{
		value: "Ragusa (Province), IT",
		label: "Ragusa (Province), IT",
		code: "RAGUS-6",
	},
	{ value: "Rozzano, IT", label: "Rozzano, IT", code: "ROZZA-6" },
	{ value: "Rosolini, IT", label: "Rosolini, IT", code: "RSI-002" },
	{ value: "Rosolina, IT", label: "Rosolina, IT", code: "RSL-002" },
	{ value: "Roure, IT", label: "Roure, IT", code: "RUR-002" },
	{ value: "Russi, IT", label: "Russi, IT", code: "RUSSI-6" },
	{ value: "Ronzone, IT", label: "Ronzone, IT", code: "RZE-002" },
	{
		value: "Steccato di Cutro, IT",
		label: "Steccato di Cutro, IT",
		code: "SDC-002",
	},
	{
		value: "Sondrio (Provincia), IT",
		label: "Sondrio (Provincia), IT",
		code: "SDR-002",
	},
	{ value: "Sangineto, IT", label: "Sangineto, IT", code: "SGT-002" },
	{
		value: "Siena (Provincia), IT",
		label: "Siena (Provincia), IT",
		code: "SIENA-6",
	},
	{
		value: "Siracusa (Province), IT",
		label: "Siracusa (Province), IT",
		code: "SIRAC-6",
	},
	{ value: "Simeri Mare, IT", label: "Simeri Mare, IT", code: "SMR-002" },
	{
		value: "Sant&apos;Agnello, IT",
		label: "Sant&apos;Agnello, IT",
		code: "STANG-6",
	},
	{ value: "Saluzzo, IT", label: "Saluzzo, IT", code: "Saluzzo" },
	{ value: "Sarzana, IT", label: "Sarzana, IT", code: "Sarzana" },
	{ value: "Segrate , IT", label: "Segrate , IT", code: "Segrate" },
	{
		value: "Galzignano Terme, IT",
		label: "Galzignano Terme, IT",
		code: "TERME-6",
	},
	{
		value: "Terni (Provincia), IT",
		label: "Terni (Provincia), IT",
		code: "TERNI-6",
	},
	{
		value: "Torre del Lago Puccini, IT",
		label: "Torre del Lago Puccini, IT",
		code: "TLG-002",
	},
	{
		value: "Trapani (Province), IT",
		label: "Trapani (Province), IT",
		code: "TRAPA-6",
	},
	{
		value: "Turín (Provincia), IT",
		label: "Turín (Provincia), IT",
		code: "TRN-002",
	},
	{ value: "Térmoli, IT", label: "Térmoli, IT", code: "Termoli" },
	{
		value: "Trinità d&apos;Agultu e Vignola, IT",
		label: "Trinità d&apos;Agultu e Vignola, IT",
		code: "Trinita",
	},
	{
		value: "Vercelli (Provincia), IT",
		label: "Vercelli (Provincia), IT",
		code: "VCL-002",
	},
	{
		value: "Verbano-Cusio-Ossola (Provincia), IT",
		label: "Verbano-Cusio-Ossola (Provincia), IT",
		code: "VCO-002",
	},
	{ value: "Valdobbiadene, IT", label: "Valdobbiadene, IT", code: "VDB-002" },
	{ value: "Isla Vulcano, IT", label: "Isla Vulcano, IT", code: "VLN-002" },
	{
		value: "Varese (Provincia), IT",
		label: "Varese (Provincia), IT",
		code: "VRS-002",
	},
	{ value: "Viverone, IT", label: "Viverone, IT", code: "VVE-002" },
	{ value: "Varazze, IT", label: "Varazze, IT", code: "Varazze" },
	{ value: "Moena, IT", label: "Moena, IT", code: "fake-94" },
	{ value: "Acireale, IT", label: "Acireale, IT", code: "ACREAL-6" },
	{
		value: "Francavilla al Mare, IT",
		label: "Francavilla al Mare, IT",
		code: "ALMARE-6",
	},
	{
		value: "Arezzo (Province), IT",
		label: "Arezzo (Province), IT",
		code: "AREZZO-6",
	},
	{ value: "Aversa, IT", label: "Aversa, IT", code: "AVERSA-6" },
	{ value: "Agropoli, IT", label: "Agropoli, IT", code: "Agropoli" },
	{ value: "Finale Ligure, IT", label: "Finale Ligure, IT", code: "FINALE-6" },
	{ value: "Formia, IT", label: "Formia, IT", code: "FORMIA-6" },
	{ value: "Frascati, IT", label: "Frascati, IT", code: "FRASCA-6" },
	{ value: "San Gemini, IT", label: "San Gemini, IT", code: "GEMINI-6" },
	{ value: "Glorenza, IT", label: "Glorenza, IT", code: "GLOREN-6" },
	{
		value: "Grosseto (Provincia), IT",
		label: "Grosseto (Provincia), IT",
		code: "GROSSE-6",
	},
	{ value: "Levico Terme, IT", label: "Levico Terme, IT", code: "LEVICO-6" },
	{ value: "Lucera, IT", label: "Lucera, IT", code: "LUCERA-6" },
	{
		value: "Mesina (Province), IT",
		label: "Mesina (Province), IT",
		code: "MESINA-6",
	},
	{ value: "Manduria, IT", label: "Manduria, IT", code: "Manduria" },
	{
		value: "Marciano della Chiana, IT",
		label: "Marciano della Chiana, IT",
		code: "Marciano",
	},
	{ value: "Maruggio, IT", label: "Maruggio, IT", code: "Maruggio" },
	{ value: "Molfetta, IT", label: "Molfetta, IT", code: "Molfetta" },
	{
		value: "Morciano di Leuca, IT",
		label: "Morciano di Leuca, IT",
		code: "Morciano",
	},
	{
		value: "Mosciano Sant&apos;Angelo, IT",
		label: "Mosciano Sant&apos;Angelo, IT",
		code: "Mosciano",
	},
	{ value: "Muravera , IT", label: "Muravera , IT", code: "Muravera" },
	{ value: "Narbolia , IT", label: "Narbolia , IT", code: "Narbolia" },
	{ value: "Nicotera, IT", label: "Nicotera, IT", code: "Nicotera" },
	{ value: "Oggebbio, IT", label: "Oggebbio, IT", code: "Oggebbio" },
	{ value: "Parabiago, IT", label: "Parabiago, IT", code: "PARAGO-6" },
	{
		value: "Perusa (Provincia), IT",
		label: "Perusa (Provincia), IT",
		code: "PERUSA-6",
	},
	{ value: "Petrosino, IT", label: "Petrosino, IT", code: "PETROS-6" },
	{ value: "Pineto, IT", label: "Pineto, IT", code: "PINETO-6" },
	{ value: "Pinerolo, IT", label: "Pinerolo, IT", code: "Pinerolo" },
	{ value: "Predazzo, IT", label: "Predazzo, IT", code: "Predazzo" },
	{ value: "Qualiano, IT", label: "Qualiano, IT", code: "Qualiano" },
	{
		value: "Rovigo (Provincia), IT",
		label: "Rovigo (Provincia), IT",
		code: "ROVIGO-6",
	},
	{ value: "Recanati, IT", label: "Recanati, IT", code: "Recanati" },
	{ value: "Ripollet, IT", label: "Ripollet, IT", code: "Ripollet" },
	{ value: "Rogliano, IT", label: "Rogliano, IT", code: "Rogliano" },
	{
		value: "San Giovanni, Elba Isla, IT",
		label: "San Giovanni, Elba Isla, IT",
		code: "SANGIO-6",
	},
	{
		value: "Santa Croce Camerina, IT",
		label: "Santa Croce Camerina, IT",
		code: "SCROCE-6",
	},
	{ value: "San Leona, IT", label: "San Leona, IT", code: "SanLeone" },
	{ value: "Tirano, IT", label: "Tirano, IT", code: "TIRANO-6" },
	{ value: "Tivoli, IT", label: "Tivoli, IT", code: "TIVOLI-6" },
	{
		value: "Trento (Provincia), IT",
		label: "Trento (Provincia), IT",
		code: "TRENTO-6",
	},
	{
		value: "Venecia (Provincia), IT",
		label: "Venecia (Provincia), IT",
		code: "VENICE-6",
	},
	{
		value: "Verona (Provincia), IT",
		label: "Verona (Provincia), IT",
		code: "VERONA-6",
	},
	{ value: "Vico Equense, IT", label: "Vico Equense, IT", code: "VICOSE-6" },
	{ value: "Golfo Aranci, IT", label: "Golfo Aranci, IT", code: "fake-501" },
	{
		value: "Isla de Capo Rizzoto, IT",
		label: "Isla de Capo Rizzoto, IT",
		code: "fake-661",
	},
	{
		value: "Vieste, Puglia, IT",
		label: "Vieste, Puglia, IT",
		code: "fake-680",
	},
	{ value: "Nardó, IT", label: "Nardó, IT", code: "fake-681" },
	{
		value: "Santa Maria di Leuca, IT",
		label: "Santa Maria di Leuca, IT",
		code: "fake-682",
	},
	{
		value: "Castrignano del Capo, IT",
		label: "Castrignano del Capo, IT",
		code: "fake-683",
	},
	{ value: "Tricase, IT", label: "Tricase, IT", code: "fake-684" },
	{ value: "Salve, IT", label: "Salve, IT", code: "fake-732" },
	{ value: "Ruffano, IT", label: "Ruffano, IT", code: "fake-733" },
	{ value: "Castro, IT", label: "Castro, IT", code: "fake-734" },
	{ value: "Poggiardo, IT", label: "Poggiardo, IT", code: "fake-735" },
	{ value: "Taurisano, IT", label: "Taurisano, IT", code: "fake-736" },
	{ value: "Maglie, IT", label: "Maglie, IT", code: "fake-737" },
	{ value: "Casarano, IT", label: "Casarano, IT", code: "fake-739" },
	{ value: "Galatina, IT", label: "Galatina, IT", code: "fake-741" },
	{
		value: "Torre dell’Orso, IT",
		label: "Torre dell’Orso, IT",
		code: "fake-745",
	},
	{ value: "Novoli, IT", label: "Novoli, IT", code: "fake-785" },
	{ value: "Pozzallo, IT", label: "Pozzallo, IT", code: "fake-787" },
	{
		value: "Santa Cesarea Terme, IT",
		label: "Santa Cesarea Terme, IT",
		code: "fake-791",
	},
	{ value: "Baia Verde, IT", label: "Baia Verde, IT", code: "fake-792" },
	{
		value: "Santa Maria Al Bagno, IT",
		label: "Santa Maria Al Bagno, IT",
		code: "fake-793",
	},
	{
		value: "Porto Selvaggio, IT",
		label: "Porto Selvaggio, IT",
		code: "fake-795",
	},
	{
		value: "Santa Caterina, IT",
		label: "Santa Caterina, IT",
		code: "fake-796",
	},
	{ value: "Botricello, IT", label: "Botricello, IT", code: "BOCELLO-6" },
	{
		value: "Cassano all&apos;Ionio, IT",
		label: "Cassano all&apos;Ionio, IT",
		code: "CASSANO-6",
	},
	{ value: "Griante, IT", label: "Griante, IT", code: "GRIANTE-6" },
	{
		value: "Lignano Sabbiadoro, IT",
		label: "Lignano Sabbiadoro, IT",
		code: "LIGNANO-6",
	},
	{
		value: "Marinella di Cutro, IT",
		label: "Marinella di Cutro, IT",
		code: "MARINEL-6",
	},
	{
		value: "Massa y Carrara (Provincia), IT",
		label: "Massa y Carrara (Provincia), IT",
		code: "MASSACA-6",
	},
	{ value: "Ottaviano, IT", label: "Ottaviano, IT", code: "Ottaviano" },
	{ value: "Panchià, IT", label: "Panchià, IT", code: "PANCHIA-6" },
	{ value: "Pianoro, IT", label: "Pianoro, IT", code: "PIANORO-6" },
	{ value: "Picerno, IT", label: "Picerno, IT", code: "PICERNO-6" },
	{
		value: "Pieve di Cadore, IT",
		label: "Pieve di Cadore, IT",
		code: "PIEVERE-6",
	},
	{ value: "Pontedera , IT", label: "Pontedera , IT", code: "PONTERA-6" },
	{ value: "Pulsano, IT", label: "Pulsano, IT", code: "PULSANO-6" },
	{ value: "Putignano , IT", label: "Putignano , IT", code: "Putignano" },
	{ value: "Realmonte , IT", label: "Realmonte , IT", code: "Realmonte" },
	{ value: "Regalbuto, IT", label: "Regalbuto, IT", code: "Regalbuto" },
	{
		value: "Selva di Val Gardena, IT",
		label: "Selva di Val Gardena, IT",
		code: "SELVADG-6",
	},
	{
		value: "San Pellegrino Terme, IT",
		label: "San Pellegrino Terme, IT",
		code: "SPTERME-6",
	},
	{
		value: "Treviso (Provincia), IT",
		label: "Treviso (Provincia), IT",
		code: "TREVISO-6",
	},
	{
		value: "Vicenza (Provincia), IT",
		label: "Vicenza (Provincia), IT",
		code: "VICENZA-6",
	},
	{ value: "Diano Marina, IT", label: "Diano Marina, IT", code: "DIMARINA-6" },
	{ value: "Giovinazzo , IT", label: "Giovinazzo , IT", code: "Giovinazzo" },
	{ value: "Grassobbio, IT", label: "Grassobbio, IT", code: "Grassobbio" },
	{ value: "Monfalcone , IT", label: "Monfalcone , IT", code: "Monfalcone" },
	{ value: "Montalcino, IT", label: "Montalcino, IT", code: "Montalcino" },
	{ value: "Montecarlo , IT", label: "Montecarlo , IT", code: "Montecarlo" },
	{
		value: "Pietrapertosa, IT",
		label: "Pietrapertosa, IT",
		code: "PIETROSA-6",
	},
	{ value: "Pitigliano, IT", label: "Pitigliano, IT", code: "Pitigliano" },
	{ value: "Pozzolengo, IT", label: "Pozzolengo, IT", code: "Pozzolengo" },
	{ value: "Praia a Mare, IT", label: "Praia a Mare, IT", code: "PraiaAMare" },
	{ value: "Radicofani , IT", label: "Radicofani , IT", code: "Radicofani" },
	{ value: "Valmontone, IT", label: "Valmontone, IT", code: "Valmontone" },
	{ value: "Montichiari , IT", label: "Montichiari , IT", code: "Montichiari" },
	{ value: "Ospedaletti , IT", label: "Ospedaletti , IT", code: "Ospedaletti" },
	{ value: "Porto Venere, IT", label: "Porto Venere, IT", code: "PortoVenere" },
	{ value: "Portogruaro , IT", label: "Portogruaro , IT", code: "Portogruaro" },
	{ value: "Rivisondoli, IT", label: "Rivisondoli, IT", code: "Rivisondoli" },
	{ value: "Sala Baganza, IT", label: "Sala Baganza, IT", code: "SalaBaganza" },
	{
		value: "Villafranca di Verona, IT",
		label: "Villafranca di Verona, IT",
		code: "Villafranca",
	},
	{
		value: "Agliano Terme, IT",
		label: "Agliano Terme, IT",
		code: "AglianoTerme",
	},
	{
		value: "Guardistallo , IT",
		label: "Guardistallo , IT",
		code: "Guardistallo",
	},
	{
		value: "Montebelluna, IT",
		label: "Montebelluna, IT",
		code: "Montebelluna",
	},
	{
		value: "Montesarchio, IT",
		label: "Montesarchio, IT",
		code: "Montesarchio",
	},
	{
		value: "Mosciano Sant’Angelo, IT",
		label: "Mosciano Sant’Angelo, IT",
		code: "MoscianoSant",
	},
	{
		value: "Porto Azzurro, IT",
		label: "Porto Azzurro, IT",
		code: "PortoAzzurro",
	},
	{
		value: "Portoferraio, IT",
		label: "Portoferraio, IT",
		code: "Portoferraio",
	},
	{
		value: "Mandatoriccio, IT",
		label: "Mandatoriccio, IT",
		code: "Mandatoriccio",
	},
	{
		value: "Massa Lombarda, IT",
		label: "Massa Lombarda, IT",
		code: "MassaLombarda",
	},
	{
		value: "Sommacampagna, IT",
		label: "Sommacampagna, IT",
		code: "Sommacampagna",
	},
	{
		value: "Greve in Chianti , IT",
		label: "Greve in Chianti , IT",
		code: "GreveInChianti",
	},
	{
		value: "Pré-Saint-Didier, Valle de Aosta, IT",
		label: "Pré-Saint-Didier, Valle de Aosta, IT",
		code: "PreSaintDidier",
	},
	{
		value: "Serrara Fontana, IT",
		label: "Serrara Fontana, IT",
		code: "SerraraFontana",
	},
	{
		value: "Quartu Sant’Elena, Cerdeña, IT",
		label: "Quartu Sant’Elena, Cerdeña, IT",
		code: "QuartuSantElena",
	},
	{
		value: "Salice Salentino, IT",
		label: "Salice Salentino, IT",
		code: "SaliceSalentino",
	},
	{
		value: "Gravina di Catania, IT",
		label: "Gravina di Catania, IT",
		code: "GravinadiCatania",
	},
	{
		value: "Rionero in Vulture, IT",
		label: "Rionero in Vulture, IT",
		code: "RioneroInVulture",
	},
	{
		value: "Montalbano Elicona, IT",
		label: "Montalbano Elicona, IT",
		code: "MontalbanoElicona",
	},
	{
		value: "Monte Porzio Catone, IT",
		label: "Monte Porzio Catone, IT",
		code: "MontePorzioCatone",
	},
	{ value: "Jamaica, JM", label: "Jamaica, JM", code: "JM1" },
	{
		value: "Montego Bay (Bahía Montego), JM",
		label: "Montego Bay (Bahía Montego), JM",
		code: "MBJ-1",
	},
	{ value: "Kingston, JM", label: "Kingston, JM", code: "KTP" },
	{ value: "Negril, JM", label: "Negril, JM", code: "NEG" },
	{ value: "Ocho Ríos, JM", label: "Ocho Ríos, JM", code: "OCJ" },
	{ value: "Porto Antonio, JM", label: "Porto Antonio, JM", code: "POA" },
	{ value: "Runaway Bay, JM", label: "Runaway Bay, JM", code: "RUN" },
	{ value: "Tokio, JP", label: "Tokio, JP", code: "NRT-1" },
	{ value: "Kobe, JP", label: "Kobe, JP", code: "UKB" },
	{ value: "Hiroshima, JP", label: "Hiroshima, JP", code: "HIJ" },
	{ value: "Osaka, JP", label: "Osaka, JP", code: "ITM" },
	{ value: "Kioto, JP", label: "Kioto, JP", code: "KIX" },
	{ value: "Naha, JP", label: "Naha, JP", code: "AHA-1" },
	{ value: "Takamatsu , JP", label: "Takamatsu , JP", code: "TKM-1" },
	{ value: "Akita, JP", label: "Akita, JP", code: "AXT" },
	{ value: "Fukuoka, JP", label: "Fukuoka, JP", code: "FUK" },
	{ value: "Hakodate, JP", label: "Hakodate, JP", code: "HKD" },
	{ value: "Odawara, JP", label: "Odawara, JP", code: "JP4" },
	{ value: "Nara, JP", label: "Nara, JP", code: "JP8" },
	{ value: "Niigata, JP", label: "Niigata, JP", code: "KIJ" },
	{ value: "Kagoshima, JP", label: "Kagoshima, JP", code: "KOJ" },
	{ value: "Kitami, JP", label: "Kitami, JP", code: "KT3" },
	{ value: "Nagasaki, JP", label: "Nagasaki, JP", code: "NGS" },
	{ value: "Niseko, JP", label: "Niseko, JP", code: "NIS" },
	{ value: "Nagoya, JP", label: "Nagoya, JP", code: "NKM" },
	{ value: "Sapporo, JP", label: "Sapporo, JP", code: "OKD" },
	{ value: "Kanazawa, JP", label: "Kanazawa, JP", code: "QKW" },
	{ value: "Sendai, JP", label: "Sendai, JP", code: "SDJ" },
	{ value: "Yokohama, JP", label: "Yokohama, JP", code: "YOK" },
	{ value: "Miyazaki, JP", label: "Miyazaki, JP", code: "ZKI" },
	{ value: "Hakone, JP", label: "Hakone, JP", code: "JP5-1" },
	{ value: "Shōnai , JP", label: "Shōnai , JP", code: "SNI-1" },
	{ value: "Suzuka, JP", label: "Suzuka, JP", code: "SZK-1" },
	{
		value: "Isla Hachijojima, JP",
		label: "Isla Hachijojima, JP",
		code: "HCJ-10",
	},
	{ value: "Rishiri Island, JP", label: "Rishiri Island, JP", code: "RSH-23" },
	{ value: "Ishigaki, JP", label: "Ishigaki, JP", code: "SHG-91" },
	{ value: "Miyajima, JP", label: "Miyajima, JP", code: "320" },
	{ value: "Abashiri, JP", label: "Abashiri, JP", code: "ABH" },
	{ value: "Abuta , JP", label: "Abuta , JP", code: "ABU" },
	{ value: "Aichi, JP", label: "Aichi, JP", code: "ACH" },
	{ value: "Asahidake Onsen, JP", label: "Asahidake Onsen, JP", code: "AH1" },
	{
		value: "Akan National Park, JP",
		label: "Akan National Park, JP",
		code: "AKA",
	},
	{ value: "Asahikawa, JP", label: "Asahikawa, JP", code: "AKJ" },
	{ value: "Akaigawa, JP", label: "Akaigawa, JP", code: "AKW" },
	{ value: "Aomori, JP", label: "Aomori, JP", code: "AOJ" },
	{ value: "Taketa, JP", label: "Taketa, JP", code: "ATK" },
	{ value: "Biei, JP", label: "Biei, JP", code: "BII" },
	{ value: "Chitose, JP", label: "Chitose, JP", code: "CS4" },
	{ value: "Date, JP", label: "Date, JP", code: "DT1" },
	{ value: "Kazuno, JP", label: "Kazuno, JP", code: "EAL" },
	{ value: "Shizuoka, JP", label: "Shizuoka, JP", code: "FSZ" },
	{ value: "Furano, JP", label: "Furano, JP", code: "FU1" },
	{ value: "Yamagata, JP", label: "Yamagata, JP", code: "GAJ" },
	{ value: "Otsu, JP", label: "Otsu, JP", code: "HG2" },
	{ value: "Shari, JP", label: "Shari, JP", code: "HR2" },
	{ value: "Shima, JP", label: "Shima, JP", code: "IE2" },
	{ value: "Isla Iki, JP", label: "Isla Iki, JP", code: "IKL" },
	{ value: "Mito, JP", label: "Mito, JP", code: "IT2" },
	{ value: "Chiba, JP", label: "Chiba, JP", code: "JP0" },
	{ value: "Yamaguchi, JP", label: "Yamaguchi, JP", code: "JP2" },
	{ value: "Himeji, JP", label: "Himeji, JP", code: "JP6" },
	{ value: "Gifu, JP", label: "Gifu, JP", code: "JP9" },
	{ value: "Saga, JP", label: "Saga, JP", code: "JPB" },
	{ value: "Wakayama, JP", label: "Wakayama, JP", code: "JPF" },
	{ value: "Saitama, JP", label: "Saitama, JP", code: "JPX" },
	{ value: "Kamikawa, JP", label: "Kamikawa, JP", code: "KK2" },
	{ value: "Kumamoto, JP", label: "Kumamoto, JP", code: "KMJ" },
	{ value: "Komatsu, JP", label: "Komatsu, JP", code: "KMQ" },
	{ value: "Kushiro, JP", label: "Kushiro, JP", code: "KUH" },
	{ value: "Kussharo, JP", label: "Kussharo, JP", code: "KUS" },
	{ value: "Kyotango, JP", label: "Kyotango, JP", code: "KYO" },
	{ value: "Monbetsu, JP", label: "Monbetsu, JP", code: "MBE" },
	{ value: "Noboribetsu, JP", label: "Noboribetsu, JP", code: "NB3" },
	{ value: "Obihiro, JP", label: "Obihiro, JP", code: "OBO" },
	{ value: "Oita, JP", label: "Oita, JP", code: "OIT" },
	{ value: "Okayama, JP", label: "Okayama, JP", code: "OKJ" },
	{ value: "Otaru, JP", label: "Otaru, JP", code: "OT1" },
	{ value: "Tochigi, JP", label: "Tochigi, JP", code: "TC2" },
	{ value: "Tsukuba, JP", label: "Tsukuba, JP", code: "TK2" },
	{ value: "Tokushima, JP", label: "Tokushima, JP", code: "TKS" },
	{ value: "Toyama, JP", label: "Toyama, JP", code: "TOY" },
	{ value: "Tottori, JP", label: "Tottori, JP", code: "TTJ" },
	{ value: "Toyako, JP", label: "Toyako, JP", code: "TYK" },
	{ value: "Kumejima, JP", label: "Kumejima, JP", code: "UEO" },
	{ value: "Wakkanai, JP", label: "Wakkanai, JP", code: "WAK" },
	{ value: "Kamakura, JP", label: "Kamakura, JP", code: "JPG5" },
	{ value: "Takayama, JP", label: "Takayama, JP", code: "JP9-2" },
	{ value: "Nagano, JP", label: "Nagano, JP", code: "JPE-1" },
	{ value: "Matsumoto, JP", label: "Matsumoto, JP", code: "JPE-6" },
	{ value: "Kitakyushu, JP", label: "Kitakyushu, JP", code: "KKJ-1" },
	{ value: "Kōchi, JP", label: "Kōchi, JP", code: "KOC-2" },
	{ value: "Koya, JP", label: "Koya, JP", code: "KYS-1" },
	{
		value: "Itsukushima (isla), JP",
		label: "Itsukushima (isla), JP",
		code: "MIY-1",
	},
	{ value: "Hokkaido, JP", label: "Hokkaido, JP", code: "OBO-1" },
	{ value: "Tsushima, JP", label: "Tsushima, JP", code: "THM-1" },
	{ value: "Wajima, JP", label: "Wajima, JP", code: "WJM-3" },
	{ value: "Beppu, JP", label: "Beppu, JP", code: "BEPP-6" },
	{ value: "Fukue, JP", label: "Fukue, JP", code: "FUI-77" },
	{ value: "Iwakuni, JP", label: "Iwakuni, JP", code: "IWK-65" },
	{ value: "Kawayu Onsen, JP", label: "Kawayu Onsen, JP", code: "KYO-71" },
	{ value: "Memanbetsu, JP", label: "Memanbetsu, JP", code: "MBT-76" },
	{ value: "Masuda, JP", label: "Masuda, JP", code: "MSD-44" },
	{ value: "Matsuyama, JP", label: "Matsuyama, JP", code: "MYA-71" },
	{
		value: "LEGOLAND® Japon, JP",
		label: "LEGOLAND® Japon, JP",
		code: "LEG-323",
	},
	{ value: "Setouchi, JP", label: "Setouchi, JP", code: "fake-535" },
	{ value: "Yamanashi , JP", label: "Yamanashi , JP", code: "fake-755" },
	{ value: "Kawasaki, JP", label: "Kawasaki, JP", code: "fake-756" },
	{ value: "Tsumago, JP", label: "Tsumago, JP", code: "fake-842" },
	{
		value: "Higashi-Hiroshima, JP",
		label: "Higashi-Hiroshima, JP",
		code: "BLABLABLA-6",
	},
	{ value: "Narita, JP", label: "Narita, JP", code: "NAR" },
	{ value: "Miho, JP", label: "Miho, JP", code: "YGJ" },
	{ value: "Gero, JP", label: "Gero, JP", code: "Gero" },
	{ value: "Hirosaki, JP", label: "Hirosaki, JP", code: "HRSK" },
	{ value: "Minakami, JP", label: "Minakami, JP", code: "MNKM" },
	{ value: "Ise, JP", label: "Ise, JP", code: "ISE-6" },
	{ value: "Monte Iwaki, JP", label: "Monte Iwaki, JP", code: "IWAKI" },
	{ value: "Izu, JP", label: "Izu, JP", code: "IZU-6" },
	{ value: "Nachikatsuura, JP", label: "Nachikatsuura, JP", code: "NAC-2" },
	{ value: "Nikko, JP", label: "Nikko, JP", code: "TC2-5" },
	{ value: "Yudanaka, JP", label: "Yudanaka, JP", code: "YDK-1" },
	{
		value: "Fujikawaguchiko, JP",
		label: "Fujikawaguchiko, JP",
		code: "FUJI-1",
	},
	{ value: "Matsue, JP", label: "Matsue, JP", code: "MATS-1" },
	{ value: " Isla Miyako, JP", label: " Isla Miyako, JP", code: "MKY-90" },
	{ value: "Naha, JP", label: "Naha, JP", code: "NAHA-6" },
	{ value: "Nagiso, JP", label: "Nagiso, JP", code: "Nagiso" },
	{ value: "Okinawa, JP", label: "Okinawa, JP", code: "OKW-16" },
	{ value: "Toba, JP", label: "Toba, JP", code: "TOBA-6" },
	{ value: "Izumisano, JP", label: "Izumisano, JP", code: "IZUMI-6" },
	{ value: "Kawaguchiko, JP", label: "Kawaguchiko, JP", code: "KWGTK-6" },
	{ value: "Fukaura, JP", label: "Fukaura, JP", code: "fukaura" },
	{ value: "Okuhida, JP", label: "Okuhida, JP", code: "okuhida" },
	{ value: "Magome, JP", label: "Magome, JP", code: "58484488" },
	{ value: "Toyooka, JP", label: "Toyooka, JP", code: "TYOOKA-6" },
	{ value: " Shinjuku, JP", label: " Shinjuku, JP", code: "fake-752" },
	{ value: "Shibukawa, JP", label: "Shibukawa, JP", code: "fake-753" },
	{ value: "Shibuya, JP", label: "Shibuya, JP", code: "fake-754" },
	{ value: "Nerima, JP", label: "Nerima, JP", code: "fake-757" },
	{ value: "Shirakawa, JP", label: "Shirakawa, JP", code: "fake-839" },
	{ value: "Narashino  , JP", label: "Narashino  , JP", code: "Narashino" },
	{ value: "Hatsukaichi, JP", label: "Hatsukaichi, JP", code: "Hatsukaichi" },
	{ value: "Jersey, JE", label: "Jersey, JE", code: "CIS-4" },
	{ value: "Amman, JO", label: "Amman, JO", code: "AMM" },
	{ value: "Aqaba, JO", label: "Aqaba, JO", code: "AQJ" },
	{ value: "Petra, JO", label: "Petra, JO", code: "PET" },
	{ value: "Mar Muerto, JO", label: "Mar Muerto, JO", code: "DDS" },
	{ value: "Wadi Rum, JO", label: "Wadi Rum, JO", code: "WDR" },
	{ value: "Monte Nebo, JO", label: "Monte Nebo, JO", code: "MNEBO-6" },
	{ value: "Sweimeh, JO", label: "Sweimeh, JO", code: "fake-163" },
	{ value: "Betania, JO", label: "Betania, JO", code: "BET-6" },
	{ value: "Dana, JO", label: "Dana, JO", code: "DAN-6" },
	{ value: "Irbid, JO", label: "Irbid, JO", code: "IRB-6" },
	{ value: "Um er-Rasas, JO", label: "Um er-Rasas, JO", code: "UMR6-" },
	{ value: "Wadi AlMijub, JO", label: "Wadi AlMijub, JO", code: "WAD-6" },
	{ value: "Ajlun, JO", label: "Ajlun, JO", code: "AJLO-1" },
	{ value: "Beidha, JO", label: "Beidha, JO", code: "ALBE-1" },
	{ value: "Shawbak, JO", label: "Shawbak, JO", code: "SHAW-6" },
	{ value: "Wadi Mujib, JO", label: "Wadi Mujib, JO", code: "WADI-6" },
	{ value: "Wadi Musa, JO", label: "Wadi Musa, JO", code: "WADM-1" },
	{ value: "Azraq, JO", label: "Azraq, JO", code: "AZRAQ-6" },
	{ value: "Al Karak, JO", label: "Al Karak, JO", code: "KARAK-6" },
	{ value: "Sakib, JO", label: "Sakib, JO", code: "SAKIB-6" },
	{ value: "At-Tafilah, JO", label: "At-Tafilah, JO", code: "ATALIF-6" },
	{ value: "Feynan, JO", label: "Feynan, JO", code: "FEYNAN-6" },
	{ value: "Al-Salt, JO", label: "Al-Salt, JO", code: "PEPPER-6" },
	{ value: "Jerash, JO", label: "Jerash, JO", code: "fake-816" },
	{ value: "Madaba, JO", label: "Madaba, JO", code: "fake-824" },
	{ value: "Astana, KZ", label: "Astana, KZ", code: "TSE" },
	{ value: "Almaty, KZ", label: "Almaty, KZ", code: "AMY" },
	{ value: "Taldykorgan, KZ", label: "Taldykorgan, KZ", code: "TDK" },
	{ value: "Aktobe, KZ", label: "Aktobe, KZ", code: "AKX" },
	{ value: "Atyrau, KZ", label: "Atyrau, KZ", code: "GUW" },
	{ value: "Aktau, KZ", label: "Aktau, KZ", code: "SCO" },
	{ value: "Ust-Kamenogorsk, KZ", label: "Ust-Kamenogorsk, KZ", code: "ukk" },
	{ value: "Shymkent, KZ", label: "Shymkent, KZ", code: "SHY-5" },
	{ value: "Mombasa, KE", label: "Mombasa, KE", code: "MBA" },
	{ value: "Nairobi, KE", label: "Nairobi, KE", code: "NAI" },
	{ value: "Nakuru, KE", label: "Nakuru, KE", code: "LEK" },
	{ value: "Naivasha, KE", label: "Naivasha, KE", code: "LAV" },
	{ value: "Masái Mara, KE", label: "Masái Mara, KE", code: "MAS" },
	{ value: "Watamu, KE", label: "Watamu, KE", code: "WAM" },
	{ value: "Chale Island, KE", label: "Chale Island, KE", code: "CIS-63" },
	{ value: "Diani Beach, KE", label: "Diani Beach, KE", code: "DNB-63" },
	{ value: "Malindi, KE", label: "Malindi, KE", code: "MLI-67" },
	{ value: "Ol Pejeta, KE", label: "Ol Pejeta, KE", code: "OLPE-6" },
	{
		value: "Parque nacional de Aberdare, KE",
		label: "Parque nacional de Aberdare, KE",
		code: "ABEK",
	},
	{
		value: "Parque nacional de Amboseli, KE",
		label: "Parque nacional de Amboseli, KE",
		code: "AMBK",
	},
	{
		value: "Reserva nacional de Samburu, KE",
		label: "Reserva nacional de Samburu, KE",
		code: "SAMK",
	},
	{ value: "Playa Tiwi, KE", label: "Playa Tiwi, KE", code: "TIWK" },
	{
		value: "Tsavo East National Park, KE",
		label: "Tsavo East National Park, KE",
		code: "TSAK",
	},
	{ value: "Namanga, KE", label: "Namanga, KE", code: "NAM-6" },
	{ value: "Nyali, KE", label: "Nyali, KE", code: "Nyali" },
	{ value: "Kilifi, KE", label: "Kilifi, KE", code: "Kilifi" },
	{ value: "Mtwapa, KE", label: "Mtwapa, KE", code: "Mtwapa" },
	{ value: "Bamburi, KE", label: "Bamburi, KE", code: "Bamburi" },
	{
		value: "Parque Nacional del Monte Kenia, KE",
		label: "Parque Nacional del Monte Kenia, KE",
		code: "MTkenya",
	},
	{ value: "Kikambala, KE", label: "Kikambala, KE", code: "Kikambala" },
	{ value: "Ciudad de Kuwait, KW", label: "Ciudad de Kuwait, KW", code: "KW" },
	{ value: "Bishkek, KG", label: "Bishkek, KG", code: "FRU" },
	{ value: "Osh, KG", label: "Osh, KG", code: "KG3" },
	{ value: "Karakol, KG", label: "Karakol, KG", code: "isy" },
	{ value: "Luang Prabang, LA", label: "Luang Prabang, LA", code: "LPQ" },
	{ value: "Pakse, LA", label: "Pakse, LA", code: "PKS-1" },
	{ value: "Vang Vieng, LA", label: "Vang Vieng, LA", code: "fake-112" },
	{ value: "Vientiane, LA", label: "Vientiane, LA", code: "VTE" },
	{ value: "Champasak, LA", label: "Champasak, LA", code: "CHA-6" },
	{ value: "Phonsavan, LA", label: "Phonsavan, LA", code: "PHO-6" },
	{
		value: "Muang Ngoi Neua, LA",
		label: "Muang Ngoi Neua, LA",
		code: "MUNN-1",
	},
	{ value: "Riga, LV", label: "Riga, LV", code: "RIX" },
	{ value: "Liepaja, LV", label: "Liepaja, LV", code: "LPX" },
	{ value: "Sigulda, LV", label: "Sigulda, LV", code: "UL1" },
	{ value: "Cesis, LV", label: "Cesis, LV", code: "CSI" },
	{ value: "Daugavpils, LV", label: "Daugavpils, LV", code: "DGP" },
	{ value: "Beirut, LB", label: "Beirut, LB", code: "BEY" },
	{ value: "Jounieh, LB", label: "Jounieh, LB", code: "JUH" },
	{ value: "Biblos, LB", label: "Biblos, LB", code: "BLB" },
	{ value: "Maseru, LS", label: "Maseru, LS", code: "MSR-54" },
	{ value: "Monrovia, LR", label: "Monrovia, LR", code: "MRV-38" },
	{ value: "Trípoli, LY", label: "Trípoli, LY", code: "TYP" },
	{ value: "Tukrah, LY", label: "Tukrah, LY", code: "TUK-6" },
	{ value: "Vaduz, LI", label: "Vaduz, LI", code: "VIE1" },
	{ value: "Vilnius, LT", label: "Vilnius, LT", code: "VNO" },
	{ value: "Kaunas, LT", label: "Kaunas, LT", code: "KUN" },
	{ value: "Druskininkai, LT", label: "Druskininkai, LT", code: "DRK" },
	{ value: "Klaipėda, LT", label: "Klaipėda, LT", code: "KLJ" },
	{ value: "Siauliai, LT", label: "Siauliai, LT", code: "123SIA" },
	{ value: "Ignalina, LT", label: "Ignalina, LT", code: "IGNA-6" },
	{ value: "Trakai, LT", label: "Trakai, LT", code: "TRA123" },
	{ value: "Rundale, LT", label: "Rundale, LT", code: "RUN-6" },
	{ value: "Neringa, LT", label: "Neringa, LT", code: "Neringos" },
	{ value: "Palanga, LT", label: "Palanga, LT", code: "PALANGA-6" },
	{ value: "Marijampolė , LT", label: "Marijampolė , LT", code: "Marijampole" },
	{ value: "Luxemburgo, LU", label: "Luxemburgo, LU", code: "LUX" },
	{ value: "Clervaux, LU", label: "Clervaux, LU", code: "CLE-6" },
	{ value: "Echternach, LU", label: "Echternach, LU", code: "ECH-6" },
	{ value: "Esch-sur-Sûre, LU", label: "Esch-sur-Sûre, LU", code: "ESC-6" },
	{ value: "Weiswampach, LU", label: "Weiswampach, LU", code: "WEI-6" },
	{ value: "Roeser, LU", label: "Roeser, LU", code: "Roeser" },
	{ value: "Niederanven, LU", label: "Niederanven, LU", code: "Niederanven" },
	{ value: "Skopje, MK", label: "Skopje, MK", code: "SKP" },
	{ value: "Ohrid, MK", label: "Ohrid, MK", code: "OHR-1" },
	{ value: "Kavadarci, MK", label: "Kavadarci, MK", code: "KAV-1" },
	{ value: "Morondava, MG", label: "Morondava, MG", code: "MDV" },
	{ value: "Nosy Be, MG", label: "Nosy Be, MG", code: "NOS" },
	{ value: "Antananarivo, MG", label: "Antananarivo, MG", code: "TNR" },
	{ value: "Toamasina, MG", label: "Toamasina, MG", code: "TOA" },
	{ value: "Miandrivazo, MG", label: "Miandrivazo, MG", code: "MDN" },
	{ value: "Ranohira, MG", label: "Ranohira, MG", code: "MJA" },
	{ value: "Toliara, MG", label: "Toliara, MG", code: "TLE" },
	{ value: "Fianarantsoa, MG", label: "Fianarantsoa, MG", code: "WFI" },
	{ value: "Antsiranana, MG", label: "Antsiranana, MG", code: "ANTS" },
	{ value: "Anivorano Nord, MG", label: "Anivorano Nord, MG", code: "ANVN" },
	{ value: "Bekopaka, MG", label: "Bekopaka, MG", code: "BKPk" },
	{ value: "Ranomafana, MG", label: "Ranomafana, MG", code: "RMFN" },
	{ value: "Antsirabe, MG", label: "Antsirabe, MG", code: "ANTSR" },
	{ value: "Lago Ampitabe, MG", label: "Lago Ampitabe, MG", code: "AMPI-1" },
	{ value: "Andasibe, MG", label: "Andasibe, MG", code: "ANDA-1" },
	{ value: "Ankify, MG", label: "Ankify, MG", code: "ANKI-1" },
	{
		value: "Nosy Boraha (Île Sainte-Marie), MG",
		label: "Nosy Boraha (Île Sainte-Marie), MG",
		code: "NOSY-6",
	},
	{ value: "Lilongüe, MW", label: "Lilongüe, MW", code: "LLW" },
	{ value: "Blantyre, MW", label: "Blantyre, MW", code: "BLA-87" },
	{ value: "Kuala Lumpur, MY", label: "Kuala Lumpur, MY", code: "KUL" },
	{ value: "Kota Kinabalu, MY", label: "Kota Kinabalu, MY", code: "BKI" },
	{ value: "Malacca, MY", label: "Malacca, MY", code: "MKZ" },
	{ value: "Pangkor Island, MY", label: "Pangkor Island, MY", code: "IPH" },
	{ value: "Kuching, MY", label: "Kuching, MY", code: "KCH" },
	{ value: "Kuantan, MY", label: "Kuantan, MY", code: "Kua" },
	{ value: "Langkawi, MY", label: "Langkawi, MY", code: "LGK" },
	{ value: "Penang, MY", label: "Penang, MY", code: "PEN" },
	{ value: "Ipoh, MY", label: "Ipoh, MY", code: "IPH3" },
	{
		value: "Cameron Highlands, MY",
		label: "Cameron Highlands, MY",
		code: "KUA2",
	},
	{
		value: "Kuala Terengganu, MY",
		label: "Kuala Terengganu, MY",
		code: "TGG2",
	},
	{ value: "Sandakan, MY", label: "Sandakan, MY", code: "BKI-2" },
	{ value: "Bintulu, MY", label: "Bintulu, MY", code: "KCH-5" },
	{ value: "Taman Negara, MY", label: "Taman Negara, MY", code: "KUA-3" },
	{ value: "Tioman, MY", label: "Tioman, MY", code: "MKZ-3" },
	{ value: "Kota Bharu, MY", label: "Kota Bharu, MY", code: "TGG-3" },
	{
		value: "Islas Perhentian, MY",
		label: "Islas Perhentian, MY",
		code: "TGG-4",
	},
	{ value: "Miri, MY", label: "Miri, MY", code: "fake-282" },
	{ value: "George Town, MY", label: "George Town, MY", code: "AS-3" },
	{ value: "Johor Baru, MY", label: "Johor Baru, MY", code: "MKZ2" },
	{ value: "Sepang, MY", label: "Sepang, MY", code: "KUL-1" },
	{ value: "Mulu, MY", label: "Mulu, MY", code: "MULU-6" },
	{ value: "Isla Banding, MY", label: "Isla Banding, MY", code: "BANDI-6" },
	{ value: "Batang Ai, MY", label: "Batang Ai, MY", code: "BATAN-6" },
	{ value: "Lumut, MY", label: "Lumut, MY", code: "LUMUT-6" },
	{ value: "Mabul Isla, MY", label: "Mabul Isla, MY", code: "MABUL-6" },
	{ value: "Malaca, MY", label: "Malaca, MY", code: "MAL-123" },
	{ value: "Isla Redang, MY", label: "Isla Redang, MY", code: "REDAN-6" },
	{ value: "Selingan Isla, MY", label: "Selingan Isla, MY", code: "SLGAN-6" },
	{ value: "Sukau, MY", label: "Sukau, MY", code: "SUKAU-6" },
	{
		value: "LEGOLAN Malasia, MY",
		label: "LEGOLAN Malasia, MY",
		code: "leg-333",
	},
	{
		value: "parque nacional de Bako, MY",
		label: "parque nacional de Bako, MY",
		code: "BAKONP-6",
	},
	{ value: "Kuala Besut, MY", label: "Kuala Besut, MY", code: "KBESUT-6" },
	{ value: "Kuala Tahan, MY", label: "Kuala Tahan, MY", code: "KTAHAN-6" },
	{ value: "Labuan, MY", label: "Labuan, MY", code: "LABUAN-6" },
	{ value: "Layang Layang, MY", label: "Layang Layang, MY", code: "LAYLAY-6" },
	{ value: "Danum Valley, MY", label: "Danum Valley, MY", code: "LDANUM-6" },
	{ value: "Mersing, MY", label: "Mersing, MY", code: "MRSING-6" },
	{ value: "Shah Alam, MY", label: "Shah Alam, MY", code: "fake-274" },
	{ value: "Petaling Jaya, MY", label: "Petaling Jaya, MY", code: "fake-275" },
	{
		value: "Batu Ferringhi, MY",
		label: "Batu Ferringhi, MY",
		code: "fake-276",
	},
	{ value: "Putrajaya, MY", label: "Putrajaya, MY", code: "fake-277" },
	{ value: "Port Dickson, MY", label: "Port Dickson, MY", code: "fake-278" },
	{ value: "Cyberjaya, MY", label: "Cyberjaya, MY", code: "fake-279" },
	{ value: "Brinchang, MY", label: "Brinchang, MY", code: "fake-280" },
	{ value: "Tanah Rata, MY", label: "Tanah Rata, MY", code: "fake-281" },
	{ value: "Sabah, MY", label: "Sabah, MY", code: "fake-786" },
	{ value: "Kuala Pahang, MY", label: "Kuala Pahang, MY", code: "KPAHANG-6" },
	{ value: "Sipadan, MY", label: "Sipadan, MY", code: "SIPADAN-6" },
	{ value: "Isla Gaya , MY", label: "Isla Gaya , MY", code: "Gayaisland" },
	{ value: "Sarawak, MY", label: "Sarawak, MY", code: "KCH-MALASIA8A" },
	{
		value: "Genting Highlands, MY",
		label: "Genting Highlands, MY",
		code: "GNTG",
	},
	{
		value: "Parque Nacional Kinabalu, MY",
		label: "Parque Nacional Kinabalu, MY",
		code: "KNP-6",
	},
	{ value: "Hulu Langat, MY", label: "Hulu Langat, MY", code: "HULU-6" },
	{
		value: "Aeropuerto Internacional de Kuala Lumpur, MY",
		label: "Aeropuerto Internacional de Kuala Lumpur, MY",
		code: "KLA-002",
	},
	{ value: "Kundasang, MY", label: "Kundasang, MY", code: "KSANG-6" },
	{ value: "Ranau, MY", label: "Ranau, MY", code: "RANAU-6" },
	{ value: "Setiu, MY", label: "Setiu, MY", code: "SETIU-6" },
	{ value: "Tanjung Gemuk, MY", label: "Tanjung Gemuk, MY", code: "TJG-002" },
	{ value: "Maldivas, MV", label: "Maldivas, MV", code: "MV" },
	{ value: "Atolón Malé Sur, MV", label: "Atolón Malé Sur, MV", code: "MV-5" },
	{
		value: "Gaafu Alif Atoll, MV",
		label: "Gaafu Alif Atoll, MV",
		code: "MV-9",
	},
	{ value: "Kuredu, MV", label: "Kuredu, MV", code: "MV-18" },
	{
		value: "Atolón Malé Norte, MV",
		label: "Atolón Malé Norte, MV",
		code: "fake-108",
	},
	{ value: "Veligandu, MV", label: "Veligandu, MV", code: "MV2" },
	{ value: "Hudhuranfushi, MV", label: "Hudhuranfushi, MV", code: "MV4" },
	{ value: "Baa Atoll, MV", label: "Baa Atoll, MV", code: "MV-1" },
	{ value: "Ari Atoll, MV", label: "Ari Atoll, MV", code: "MV-2" },
	{ value: "Male, MV", label: "Male, MV", code: "MV-3" },
	{ value: "Ihuru (Angsana), MV", label: "Ihuru (Angsana), MV", code: "MV-4" },
	{ value: "Ukulhas, MV", label: "Ukulhas, MV", code: "MV18" },
	{ value: "Hulhumalé, MV", label: "Hulhumalé, MV", code: "MV20" },
	{ value: "Faafu Atoll, MV", label: "Faafu Atoll, MV", code: "MV-12" },
	{ value: "Atolón Meemu, MV", label: "Atolón Meemu, MV", code: "MV-13" },
	{
		value: "Haa Dhaalu Atoll, MV",
		label: "Haa Dhaalu Atoll, MV",
		code: "MV-16",
	},
	{
		value: "North Ari Atoll (Alif Alif), MV",
		label: "North Ari Atoll (Alif Alif), MV",
		code: "MV-17",
	},
	{ value: "Tulusdhoo, MV", label: "Tulusdhoo, MV", code: "fake-105" },
	{ value: "Thoddoo, MV", label: "Thoddoo, MV", code: "fake-106" },
	{ value: "Rasdhoo, MV", label: "Rasdhoo, MV", code: "fake-107" },
	{
		value: "Lhaviyani Atoll, MV",
		label: "Lhaviyani Atoll, MV",
		code: "fake-110",
	},
	{ value: "Baros Maldives, MV", label: "Baros Maldives, MV", code: "BMD" },
	{ value: "Hanimaadhoo, MV", label: "Hanimaadhoo, MV", code: "HDO-002" },
	{ value: "Atolón Raa, MV", label: "Atolón Raa, MV", code: "RAAATOLL" },
	{ value: "Thinadhoo , MV", label: "Thinadhoo , MV", code: "ThinadhooIsland" },
	{
		value: "Thinadhoo (Vaavu Atoll), MV",
		label: "Thinadhoo (Vaavu Atoll), MV",
		code: "ThinadhooVaavuAtoll",
	},
	{ value: "Bamako, ML", label: "Bamako, ML", code: "BKO" },
	{ value: "Valletta, MT", label: "Valletta, MT", code: "MLA-6" },
	{ value: "Mosta, MT", label: "Mosta, MT", code: "MOA-002" },
	{ value: "Gozo, MT", label: "Gozo, MT", code: "MLA-1" },
	{ value: "Bugibba, MT", label: "Bugibba, MT", code: "MLA-2" },
	{ value: "St. Jullians, MT", label: "St. Jullians, MT", code: "MLA-4" },
	{ value: "Sliema, MT", label: "Sliema, MT", code: "MLA-5" },
	{ value: "Malta, MT", label: "Malta, MT", code: "MLA-1a" },
	{
		value: "St. Paul&apos;s Bay, MT",
		label: "St. Paul&apos;s Bay, MT",
		code: "MLA-50",
	},
	{ value: "Comino , MT", label: "Comino , MT", code: "Comino" },
	{ value: "Mdina, MT", label: "Mdina, MT", code: "MDIN-1" },
	{ value: "Rabat, Malta, MT", label: "Rabat, Malta, MT", code: "RBTM-1" },
	{ value: "Birgu, MT", label: "Birgu, MT", code: "BGU-002" },
	{ value: "Birgu, MT", label: "Birgu, MT", code: "BIRGU-6" },
	{ value: "Cospicua, MT", label: "Cospicua, MT", code: "CCU-002" },
	{ value: "Dingli, MT", label: "Dingli, MT", code: "DGL-002" },
	{ value: "Mellieha, MT", label: "Mellieha, MT", code: "MLH-002" },
	{ value: "Marsaxlokk, MT", label: "Marsaxlokk, MT", code: "MRX-002" },
	{ value: "Siggiewi, MT", label: "Siggiewi, MT", code: "SGG-002" },
	{ value: "Senglea, MT", label: "Senglea, MT", code: "SGL-002" },
	{ value: "Zebbug, MT", label: "Zebbug, MT", code: "ZBG-002" },
	{ value: "Zabbar, MT", label: "Zabbar, MT", code: "ZBR-002" },
	{
		value: "Islas Kwajalein, MH",
		label: "Islas Kwajalein, MH",
		code: "KWJ-25",
	},
	{ value: "Isla Majuro, MH", label: "Isla Majuro, MH", code: "MJR-23" },
	{ value: "Martinica, MQ", label: "Martinica, MQ", code: "FDF-6" },
	{ value: "Trinité, MQ", label: "Trinité, MQ", code: "FDF-2" },
	{ value: "Les Trois-Îlets, MQ", label: "Les Trois-Îlets, MQ", code: "FDF-3" },
	{ value: "Fort de France, MQ", label: "Fort de France, MQ", code: "FDF-78" },
	{ value: "Sainte -Luce, MQ", label: "Sainte -Luce, MQ", code: "FDF-1" },
	{ value: "Nuakchot, MR", label: "Nuakchot, MR", code: "NKC" },
	{ value: "Mauricio, MU", label: "Mauricio, MU", code: "MRU" },
	{ value: "Mauricio, MU", label: "Mauricio, MU", code: "MRU-1" },
	{ value: "Grand Gaube, MU", label: "Grand Gaube, MU", code: "GAU-6" },
	{ value: "Souillac, MU", label: "Souillac, MU", code: "SOU-1" },
	{ value: "Albion, MU", label: "Albion, MU", code: "ALBI-6" },
	{ value: "Grand Baie, MU", label: "Grand Baie, MU", code: "BAIE-6" },
	{ value: "Balaclava , MU", label: "Balaclava , MU", code: "BALA-6" },
	{
		value: "Trou-aux-Biches, MU",
		label: "Trou-aux-Biches, MU",
		code: "BICH-6",
	},
	{ value: "Black River, MU", label: "Black River, MU", code: "BLRI-6" },
	{ value: "Bois Cheri, MU", label: "Bois Cheri, MU", code: "BOIS-6" },
	{ value: "Chamarel, MU", label: "Chamarel, MU", code: "CHAM-6" },
	{ value: "Deux Frères, MU", label: "Deux Frères, MU", code: "DEUX-6" },
	{ value: "Flacq, MU", label: "Flacq, MU", code: "FLAQ-6" },
	{
		value: "Grand River South East, MU",
		label: "Grand River South East, MU",
		code: "GRSE-6",
	},
	{
		value: "Poste Lafayette, MU",
		label: "Poste Lafayette, MU",
		code: "LAFA-6",
	},
	{ value: "Mahebourg, MU", label: "Mahebourg, MU", code: "MAHE-6" },
	{ value: "Cap Malheureux, MU", label: "Cap Malheureux, MU", code: "MALH-6" },
	{ value: "Belle Mare, MU", label: "Belle Mare, MU", code: "MARE-6" },
	{ value: "Le Morne, MU", label: "Le Morne, MU", code: "MORN-6" },
	{ value: "Pamplemousses, MU", label: "Pamplemousses, MU", code: "PALM-6" },
	{ value: "Blue Bay, MU", label: "Blue Bay, MU", code: "BBY-002" },
	{ value: "Bel Ombre, MU", label: "Bel Ombre, MU", code: "OMBRE-6" },
	{ value: "Isla Rodrigues, MU", label: "Isla Rodrigues, MU", code: "RODRI-6" },
	{ value: "Flic en Flac, MU", label: "Flic en Flac, MU", code: "FLIFLA-6" },
	{
		value: "Pointe aux piments, MU",
		label: "Pointe aux piments, MU",
		code: "PIMENT-6",
	},
	{ value: "Grand Port, MU", label: "Grand Port, MU", code: "GrandPort" },
	{ value: "Port Louis, MU", label: "Port Louis, MU", code: "PortLouis" },
	{
		value: "Rivière du Rempart, MU",
		label: "Rivière du Rempart, MU",
		code: "RiviereduRempart",
	},
	{
		value: "México (Ciudad de), MX",
		label: "México (Ciudad de), MX",
		code: "091",
	},
	{ value: "Acapulco, MX", label: "Acapulco, MX", code: "ACA" },
	{ value: "Ciudad de México, MX", label: "Ciudad de México, MX", code: "MDF" },
	{ value: "Monterrey, MX", label: "Monterrey, MX", code: "MON" },
	{ value: "Aguascalientes, MX", label: "Aguascalientes, MX", code: "AGU" },
	{
		value: "Campeche (Estado), MX",
		label: "Campeche (Estado), MX",
		code: "CAM",
	},
	{ value: "Cancún, MX", label: "Cancún, MX", code: "CUN" },
	{ value: "Cuernavaca, MX", label: "Cuernavaca, MX", code: "CVJ" },
	{ value: "Ensenada, MX", label: "Ensenada, MX", code: "ENS" },
	{ value: "Puerto Escondido, MX", label: "Puerto Escondido, MX", code: "ESC" },
	{ value: "Guanajuato, MX", label: "Guanajuato, MX", code: "GUO" },
	{ value: "Chihuahua, MX", label: "Chihuahua, MX", code: "JUA" },
	{ value: "Lázaro Cárdenas, MX", label: "Lázaro Cárdenas, MX", code: "LAZ" },
	{ value: "Los Mochis, MX", label: "Los Mochis, MX", code: "LMM" },
	{ value: "Loreto, MX", label: "Loreto, MX", code: "LOR" },
	{ value: "Morelia, MX", label: "Morelia, MX", code: "MOR" },
	{ value: "Oaxaca, MX", label: "Oaxaca, MX", code: "OAX" },
	{ value: "Riviera Maya, MX", label: "Riviera Maya, MX", code: "PCM" },
	{ value: "Puerto Peñasco, MX", label: "Puerto Peñasco, MX", code: "PPE" },
	{ value: "Puebla, MX", label: "Puebla, MX", code: "PUE" },
	{ value: "Puerto Vallarta, MX", label: "Puerto Vallarta, MX", code: "PVR" },
	{ value: "Querétaro, MX", label: "Querétaro, MX", code: "QUE" },
	{ value: "Saltillo, MX", label: "Saltillo, MX", code: "SAI" },
	{ value: "Tampico, MX", label: "Tampico, MX", code: "TAM" },
	{ value: "Toluca, MX", label: "Toluca, MX", code: "TCA" },
	{ value: "Torreón, MX", label: "Torreón, MX", code: "TOO" },
	{ value: "Veracruz, MX", label: "Veracruz, MX", code: "VER" },
	{ value: "Villahermosa, MX", label: "Villahermosa, MX", code: "VIA" },
	{ value: "Ciudad Victoria, MX", label: "Ciudad Victoria, MX", code: "VII" },
	{ value: "Zacatecas, MX", label: "Zacatecas, MX", code: "ZAC" },
	{
		value: "Tulancingo (Tulancingo de Bravo) , MX",
		label: "Tulancingo (Tulancingo de Bravo) , MX",
		code: "PCH4",
	},
	{ value: "Ciudad Obregón, MX", label: "Ciudad Obregón, MX", code: "CE2-1" },
	{ value: "Navojoa, MX", label: "Navojoa, MX", code: "CE2-2" },
	{ value: "Palenque, MX", label: "Palenque, MX", code: "CIP-4" },
	{ value: "Apaseo el Alto, MX", label: "Apaseo el Alto, MX", code: "FAKE2" },
	{ value: "Orizaba, MX", label: "Orizaba, MX", code: "OR2-1" },
	{ value: "Tijuana, MX", label: "Tijuana, MX", code: "TIJ-1" },
	{ value: "Guadalajara, MX", label: "Guadalajara, MX", code: "GUA-10" },
	{ value: "Valladolid, MX", label: "Valladolid, MX", code: "MER-60" },
	{ value: "Los Cabos, MX", label: "Los Cabos, MX", code: "CAB" },
	{ value: "Chetumal, MX", label: "Chetumal, MX", code: "CHE" },
	{ value: "Chiapas, MX", label: "Chiapas, MX", code: "CIP" },
	{ value: "Colima, MX", label: "Colima, MX", code: "CLQ" },
	{ value: "Coatzacoalcos, MX", label: "Coatzacoalcos, MX", code: "COA" },
	{ value: "Culiacan, MX", label: "Culiacan, MX", code: "CUL" },
	{ value: "Durango, MX", label: "Durango, MX", code: "DGO" },
	{ value: "Hermosillo, MX", label: "Hermosillo, MX", code: "HMO" },
	{ value: "Huatulco, MX", label: "Huatulco, MX", code: "HUA" },
	{ value: "Ixtapa, MX", label: "Ixtapa, MX", code: "IXT" },
	{ value: "Leon, MX", label: "Leon, MX", code: "LEN" },
	{ value: "Manzanillo, MX", label: "Manzanillo, MX", code: "MAN" },
	{ value: "Matamoros, MX", label: "Matamoros, MX", code: "MAT" },
	{ value: "Mazatlán, MX", label: "Mazatlán, MX", code: "MAZ" },
	{
		value: "San Miguel de Allende, MX",
		label: "San Miguel de Allende, MX",
		code: "MIG",
	},
	{ value: "Mexicali, MX", label: "Mexicali, MX", code: "MXL" },
	{ value: "Nogales, MX", label: "Nogales, MX", code: "NG1" },
	{ value: "Cozumel, MX", label: "Cozumel, MX", code: "PAV" },
	{
		value: "La paz (Baja California), MX",
		label: "La paz (Baja California), MX",
		code: "PAZ",
	},
	{ value: "Piedras Negras, MX", label: "Piedras Negras, MX", code: "PDS" },
	{ value: "Poza Rica, MX", label: "Poza Rica, MX", code: "PZR" },
	{ value: "San Luis Potosi, MX", label: "San Luis Potosi, MX", code: "SLP" },
	{ value: "Taxco, MX", label: "Taxco, MX", code: "TAX" },
	{ value: "Xalapa, MX", label: "Xalapa, MX", code: "XAL" },
	{
		value: "Tuxtla Gutierrez, MX",
		label: "Tuxtla Gutierrez, MX",
		code: "CIP6",
	},
	{ value: "Monterrey, MX", label: "Monterrey, MX", code: "MON2" },
	{ value: "Jalpan de serra, MX", label: "Jalpan de serra, MX", code: "QUE3" },
	{ value: "Barra Vieja, MX", label: "Barra Vieja, MX", code: "ACA-4" },
	{ value: "Calvillo, MX", label: "Calvillo, MX", code: "AGU-2" },
	{ value: "El fuerte, MX", label: "El fuerte, MX", code: "BDC-5" },
	{ value: "Todos Santos, MX", label: "Todos Santos, MX", code: "CAB-1" },
	{
		value: "Ciudad del Carmen, MX",
		label: "Ciudad del Carmen, MX",
		code: "CAM-2",
	},
	{ value: "Xpujil, MX", label: "Xpujil, MX", code: "CAM-3" },
	{ value: "Champotón , MX", label: "Champotón , MX", code: "CAM-4" },
	{ value: "Calakmul, MX", label: "Calakmul, MX", code: "CAM-5" },
	{ value: "Bacalar, MX", label: "Bacalar, MX", code: "CHE-2" },
	{ value: "Mahahual, MX", label: "Mahahual, MX", code: "CHE-3" },
	{ value: "Tapachula, MX", label: "Tapachula, MX", code: "CIP-1" },
	{
		value: "San Cristobal de las casas, MX",
		label: "San Cristobal de las casas, MX",
		code: "CIP-5",
	},
	{ value: "Ciudad Juarez, MX", label: "Ciudad Juarez, MX", code: "CJS-1" },
	{ value: "Comitán, MX", label: "Comitán, MX", code: "CMT-3" },
	{ value: "Cordoba, MX", label: "Cordoba, MX", code: "COR-1" },
	{ value: "Huatusco, MX", label: "Huatusco, MX", code: "COR-3" },
	{ value: "Tlaltizapan, MX", label: "Tlaltizapan, MX", code: "CVJ-3" },
	{ value: "Oaxtepec, MX", label: "Oaxtepec, MX", code: "CVJ-4" },
	{
		value: "San Felipe (Baja California), MX",
		label: "San Felipe (Baja California), MX",
		code: "ENS-3",
	},
	{ value: "Tecate, MX", label: "Tecate, MX", code: "ETC-1" },
	{ value: "Abasolo, MX", label: "Abasolo, MX", code: "FAKE1" },
	{ value: "Atarjea, MX", label: "Atarjea, MX", code: "Fake3" },
	{
		value: "Ixtapan de la Sal, MX",
		label: "Ixtapan de la Sal, MX",
		code: "GUA-3",
	},
	{
		value: "San Juan De Los Lagos, MX",
		label: "San Juan De Los Lagos, MX",
		code: "GUA-4",
	},
	{ value: "Tequila, MX", label: "Tequila, MX", code: "GUA-5" },
	{ value: "Chapala, MX", label: "Chapala, MX", code: "GUA-6" },
	{ value: "Teuchitlan, MX", label: "Teuchitlan, MX", code: "GUA-7" },
	{ value: "Tepatitlan, MX", label: "Tepatitlan, MX", code: "GUA-8" },
	{ value: "Tapalpa, MX", label: "Tapalpa, MX", code: "GUA-9" },
	{
		value: "Apaseo el Grande, MX",
		label: "Apaseo el Grande, MX",
		code: "GUO-1",
	},
	{ value: "Irapuato, MX", label: "Irapuato, MX", code: "GUO-2" },
	{ value: "Guaymas, MX", label: "Guaymas, MX", code: "GYM-3" },
	{
		value: "Hidalgo Del Parral, MX",
		label: "Hidalgo Del Parral, MX",
		code: "HID-1",
	},
	{ value: "Zihuatanejo, MX", label: "Zihuatanejo, MX", code: "IXT-1" },
	{ value: "Cuyutlán, MX", label: "Cuyutlán, MX", code: "MAN-1" },
	{ value: "Río Lagartos, MX", label: "Río Lagartos, MX", code: "MER-2" },
	{ value: "Pachuca de Soto, MX", label: "Pachuca de Soto, MX", code: "PCH-1" },
	{
		value: " Tula de Allende, MX",
		label: " Tula de Allende, MX",
		code: "PCH-2",
	},
	{
		value: "Poza rica de hidalgo, MX",
		label: "Poza rica de hidalgo, MX",
		code: "PZR-1",
	},
	{
		value: "San Juan Del Rio, MX",
		label: "San Juan Del Rio, MX",
		code: "QUE-1",
	},
	{ value: "Conca, MX", label: "Conca, MX", code: "QUE-3" },
	{ value: "Tequisquiapan, MX", label: "Tequisquiapan, MX", code: "QUE-4" },
	{ value: "Reynosa, MX", label: "Reynosa, MX", code: "REX-3" },
	{ value: "Valle de Bravo, MX", label: "Valle de Bravo, MX", code: "TCA-2" },
	{
		value: "Ixtapan de la sal, MX",
		label: "Ixtapan de la sal, MX",
		code: "TCA-3",
	},
	{ value: "Tepic, MX", label: "Tepic, MX", code: "TPQ-1" },
	{ value: "Cuauhtemoc, MX", label: "Cuauhtemoc, MX", code: "UAC-1" },
	{ value: "Paraiso, MX", label: "Paraiso, MX", code: "VIA-3" },
	{ value: "Tuxpan, MX", label: "Tuxpan, MX", code: "VRA-1" },
	{ value: "Xalapa, MX", label: "Xalapa, MX", code: "XAL-1" },
	{ value: "Tenacatita, MX", label: "Tenacatita, MX", code: "ZLO-4" },
	{ value: "Careyes, MX", label: "Careyes, MX", code: "ZLO-5" },
	{ value: "Comonfort, MX", label: "Comonfort, MX", code: "fake4" },
	{ value: "Cuerámaro, MX", label: "Cuerámaro, MX", code: "fake5" },
	{ value: "Doctor Mora, MX", label: "Doctor Mora, MX", code: "fake6" },
	{ value: "Dolores Hidalgo, MX", label: "Dolores Hidalgo, MX", code: "fake7" },
	{ value: "Ocampo, MX", label: "Ocampo, MX", code: "fake8" },
	{ value: "Pénjamo, MX", label: "Pénjamo, MX", code: "fake9" },
	{
		value: "Barra de navidad, MX",
		label: "Barra de navidad, MX",
		code: "zlo-3",
	},
	{
		value: "San Jose del Cabo, MX",
		label: "San Jose del Cabo, MX",
		code: "CAB-20",
	},
	{ value: "Corredor, MX", label: "Corredor, MX", code: "CAB-30" },
	{ value: "Isla Mujeres, MX", label: "Isla Mujeres, MX", code: "CUN-50" },
	{ value: "Holbox, MX", label: "Holbox, MX", code: "CUN-60" },
	{ value: "Zapopan, MX", label: "Zapopan, MX", code: "GUA-12" },
	{ value: "Mazatmila, MX", label: "Mazatmila, MX", code: "GUA-13" },
	{ value: "Silao, MX", label: "Silao, MX", code: "GUO-10" },
	{ value: "Salamanca, MX", label: "Salamanca, MX", code: "GUO-30" },
	{ value: "Celaya, MX", label: "Celaya, MX", code: "GUO-40" },
	{ value: "Metepec , MX", label: "Metepec , MX", code: "MDF-10" },
	{ value: "Tepotzotlán, MX", label: "Tepotzotlán, MX", code: "MDF-30" },
	{
		value: "San Juan Teothuacan, MX",
		label: "San Juan Teothuacan, MX",
		code: "MDF-40",
	},
	{ value: "Santa Fé, MX", label: "Santa Fé, MX", code: "MDF-60" },
	{ value: "Mérida, MX", label: "Mérida, MX", code: "MER-10" },
	{ value: "Chichén-Itzá, MX", label: "Chichén-Itzá, MX", code: "MER-20" },
	{ value: "Telchac Puerto, MX", label: "Telchac Puerto, MX", code: "MER-40" },
	{ value: "Celestún, MX", label: "Celestún, MX", code: "MER-50" },
	{ value: "Tecoh, MX", label: "Tecoh, MX", code: "MER-80" },
	{ value: "Cuautitlán , MX", label: "Cuautitlán , MX", code: "MEX-77" },
	{ value: "Patzcuaro, MX", label: "Patzcuaro, MX", code: "MOR-20" },
	{
		value: "Playa del Carmen, MX",
		label: "Playa del Carmen, MX",
		code: "PCM-10",
	},
	{ value: "Akumal, MX", label: "Akumal, MX", code: "PCM-20" },
	{ value: "Tulum, MX", label: "Tulum, MX", code: "PCM-30" },
	{
		value: "Puerto Vallarta, MX",
		label: "Puerto Vallarta, MX",
		code: "pvr-20",
	},
	{ value: "Salvatierra, MX", label: "Salvatierra, MX", code: "Fake-11" },
	{
		value: "San Felipe Torres Mochas, MX",
		label: "San Felipe Torres Mochas, MX",
		code: "Fake-12",
	},
	{
		value: "Purísima del Rincón, MX",
		label: "Purísima del Rincón, MX",
		code: "fake-10",
	},
	{
		value: "San Francisco del Rincón, MX",
		label: "San Francisco del Rincón, MX",
		code: "fake-13",
	},
	{
		value: "San José Iturbide, MX",
		label: "San José Iturbide, MX",
		code: "fake-14",
	},
	{
		value: "San Luis de la Paz, MX",
		label: "San Luis de la Paz, MX",
		code: "fake-15",
	},
	{ value: "Tarimoro, MX", label: "Tarimoro, MX", code: "fake-16" },
	{ value: "Tierra Blanca, MX", label: "Tierra Blanca, MX", code: "fake-17" },
	{
		value: "Valle de Santiago, MX",
		label: "Valle de Santiago, MX",
		code: "fake-18",
	},
	{ value: "Victoria, MX", label: "Victoria, MX", code: "fake-19" },
	{ value: "Villagrán, MX", label: "Villagrán, MX", code: "fake-20" },
	{ value: "Xichú, MX", label: "Xichú, MX", code: "fake-21" },
	{ value: "Yuriria, MX", label: "Yuriria, MX", code: "fake-22" },
	{ value: "Apaseo el Alto, MX", label: "Apaseo el Alto, MX", code: "fake-23" },
	{ value: "Apaseo el Alto, MX", label: "Apaseo el Alto, MX", code: "fake-24" },
	{ value: "Nayarit, MX", label: "Nayarit, MX", code: "pvr-201" },
	{
		value: "Aeropuerto de San Jose del Cabo, MX",
		label: "Aeropuerto de San Jose del Cabo, MX",
		code: "309",
	},
	{
		value: "Iguala de la Independencia, MX",
		label: "Iguala de la Independencia, MX",
		code: "IGI",
	},
	{ value: "Nuevo Laredo, MX", label: "Nuevo Laredo, MX", code: "NLD" },
	{ value: "Guerrero Negro, MX", label: "Guerrero Negro, MX", code: "GRN-2" },
	{ value: "Monclova, MX", label: "Monclova, MX", code: "LOV-3" },
	{ value: "Puerto Progreso, MX", label: "Puerto Progreso, MX", code: "PPR-1" },
	{ value: "Pátzcuaro, MX", label: "Pátzcuaro, MX", code: "PTZ-2" },
	{
		value: "San Miguel de Allende, MX",
		label: "San Miguel de Allende, MX",
		code: "SMA-1",
	},
	{ value: "Cabo San Lucas, MX", label: "Cabo San Lucas, MX", code: "CAB-40" },
	{ value: "Poniente, MX", label: "Poniente, MX", code: "MON-30" },
	{ value: "Norte, MX", label: "Norte, MX", code: "MON-40" },
	{
		value: "El valle de México, MX",
		label: "El valle de México, MX",
		code: "MON-60",
	},
	{ value: "Nuevo Vallarta, MX", label: "Nuevo Vallarta, MX", code: "NVRT-1" },
	{
		value: "Riviera Nayarit, MX",
		label: "Riviera Nayarit, MX",
		code: "RVRN-6",
	},
	{ value: "Tehuantepec, MX", label: "Tehuantepec, MX", code: "TEH-77" },
	{
		value: "Guerrero (Estado), MX",
		label: "Guerrero (Estado), MX",
		code: "GRR-002",
	},
	{ value: "Kolem Jaa, MX", label: "Kolem Jaa, MX", code: "KOL-123" },
	{ value: "Tlaxcala, MX", label: "Tlaxcala, MX", code: "TLXCL-6" },
	{
		value: "Lagos de Moreno, MX",
		label: "Lagos de Moreno, MX",
		code: "LDMORE-6",
	},
	{ value: "Armería, MX", label: "Armería, MX", code: "fake-621" },
	{ value: "Comala, MX", label: "Comala, MX", code: "fake-622" },
	{ value: "Coquimatlán, MX", label: "Coquimatlán, MX", code: "fake-623" },
	{ value: "Ixtlahuacán, MX", label: "Ixtlahuacán, MX", code: "fake-625" },
	{ value: "Minatitlán, MX", label: "Minatitlán, MX", code: "fake-626" },
	{ value: "Tecomán, MX", label: "Tecomán, MX", code: "fake-627" },
	{
		value: "Villa de Álvarez , MX",
		label: "Villa de Álvarez , MX",
		code: "fake-628",
	},
	{ value: "Uxmal, MX", label: "Uxmal, MX", code: "fake-843" },
	{ value: "Zacatlán, MX", label: "Zacatlán, MX", code: "fake-845" },
	{ value: "Uruapan, MX", label: "Uruapan, MX", code: "URUAPAN-6" },
	{ value: "Viesca, MX", label: "Viesca, MX", code: "vsc" },
	{ value: "Xico (Veracruz), MX", label: "Xico (Veracruz), MX", code: "xco" },
	{ value: "Bahía la Choya, MX", label: "Bahía la Choya, MX", code: "CHYA" },
	{ value: "Costa Mujeres, MX", label: "Costa Mujeres, MX", code: "CMUJ" },
	{ value: "Quintana Roo, MX", label: "Quintana Roo, MX", code: "CTM1" },
	{ value: "La Hacienda, MX", label: "La Hacienda, MX", code: "HCND" },
	{ value: "Sinaloa, MX", label: "Sinaloa, MX", code: "LMM2" },
	{ value: "Nuevo León, MX", label: "Nuevo León, MX", code: "MTY2" },
	{ value: "Oaxaca, MX", label: "Oaxaca, MX", code: "OAX2" },
	{ value: "Puebla (Estado), MX", label: "Puebla (Estado), MX", code: "PBC3" },
	{
		value: "Querétaro (Estado), MX",
		label: "Querétaro (Estado), MX",
		code: "QRO1",
	},
	{ value: "San Luis Potosí, MX", label: "San Luis Potosí, MX", code: "SLP2" },
	{ value: "Tacámbaro, MX", label: "Tacámbaro, MX", code: "TCMB" },
	{ value: "Tlayacapan, MX", label: "Tlayacapan, MX", code: "TLYC" },
	{ value: "Tepoztlán, MX", label: "Tepoztlán, MX", code: "TPZT" },
	{ value: "Tzintzuntzan, MX", label: "Tzintzuntzan, MX", code: "TZNT" },
	{ value: "Xilitla, MX", label: "Xilitla, MX", code: "XLTL" },
	{ value: "Aculco, MX", label: "Aculco, MX", code: "ACU-1" },
	{ value: "Álamos, MX", label: "Álamos, MX", code: "ALA-1" },
	{ value: "Alisitos, MX", label: "Alisitos, MX", code: "ALI-6" },
	{ value: "Arteaga, MX", label: "Arteaga, MX", code: "ART-1" },
	{ value: "Batopilas, MX", label: "Batopilas, MX", code: "BAT-1" },
	{
		value: "Bahía de los Ángeles, MX",
		label: "Bahía de los Ángeles, MX",
		code: "BHANG",
	},
	{ value: "Coatepec, MX", label: "Coatepec, MX", code: "COA-1" },
	{ value: "Ensenada Blanca, MX", label: "Ensenada Blanca, MX", code: "ENSDB" },
	{ value: "Costa Esmeralda, MX", label: "Costa Esmeralda, MX", code: "ESM-6" },
	{ value: "Huichapan, MX", label: "Huichapan, MX", code: "HUI-1" },
	{ value: "Jala, MX", label: "Jala, MX", code: "JAL-1" },
	{ value: "La Joya, MX", label: "La Joya, MX", code: "JOY-6" },
	{
		value: "San Antonio del Mar, MX",
		label: "San Antonio del Mar, MX",
		code: "MAR-6",
	},
	{ value: "Mazunte, MX", label: "Mazunte, MX", code: "MAZ-1" },
	{ value: "Nautla, MX", label: "Nautla, MX", code: "NAU-6" },
	{ value: "El Refugio, MX", label: "El Refugio, MX", code: "REF-6" },
	{ value: "La Ribera, MX", label: "La Ribera, MX", code: "RIB-6" },
	{ value: "Rosarito, MX", label: "Rosarito, MX", code: "ROS-6" },
	{ value: "La Salina, MX", label: "La Salina, MX", code: "SAL-6" },
	{ value: "Sombrerete, MX", label: "Sombrerete, MX", code: "SMBRT" },
	{ value: "Sonora, MX", label: "Sonora, MX", code: "SON-1" },
	{
		value: "San Sebastián del Oeste, MX",
		label: "San Sebastián del Oeste, MX",
		code: "SSBTO",
	},
	{
		value: "Santa Clara del Cobre, MX",
		label: "Santa Clara del Cobre, MX",
		code: "STCLR",
	},
	{ value: "Tabasco, MX", label: "Tabasco, MX", code: "TAB-1" },
	{
		value: "Teúl de González Ortega, MX",
		label: "Teúl de González Ortega, MX",
		code: "TLLPJ",
	},
	{ value: "Tlatlauquitepec, MX", label: "Tlatlauquitepec, MX", code: "TLTQP" },
	{ value: "Todos Santos, MX", label: "Todos Santos, MX", code: "TOD-6" },
	{ value: "Bahía Tortugas, MX", label: "Bahía Tortugas, MX", code: "TOR-6" },
	{ value: "Tapijulapa, MX", label: "Tapijulapa, MX", code: "TPJLP" },
	{ value: "Xicotepec, MX", label: "Xicotepec, MX", code: "XCTPC" },
	{ value: "Yucatán, MX", label: "Yucatán, MX", code: "YUC-1" },
	{ value: "Zipolite, MX", label: "Zipolite, MX", code: "ZIP-1" },
	{ value: "Bahía Asunción, MX", label: "Bahía Asunción, MX", code: "ASUN-6" },
	{ value: "Los Barriles, MX", label: "Los Barriles, MX", code: "BARR-6" },
	{ value: "Bernal, MX", label: "Bernal, MX", code: "BERN-1" },
	{ value: "Buenavista, MX", label: "Buenavista, MX", code: "BUEN-6" },
	{
		value: "Cadereyta de Montes, MX",
		label: "Cadereyta de Montes, MX",
		code: "CADM-1",
	},
	{
		value: "Capulálpam de Méndez, MX",
		label: "Capulálpam de Méndez, MX",
		code: "CADM-2",
	},
	{ value: "Casas Grandes, MX", label: "Casas Grandes, MX", code: "CAGR-1" },
	{ value: "Candela, MX", label: "Candela, MX", code: "CAND-1" },
	{
		value: "Puerto San Carlos, MX",
		label: "Puerto San Carlos, MX",
		code: "CARL-6",
	},
	{ value: "Ciudad Tula, MX", label: "Ciudad Tula, MX", code: "CDTULA" },
	{
		value: "Chiapa de Corzo, MX",
		label: "Chiapa de Corzo, MX",
		code: "CHDC-1",
	},
	{ value: "Cosalá, MX", label: "Cosalá, MX", code: "COSA-1" },
	{ value: "Creel, MX", label: "Creel, MX", code: "CREE-1" },
	{
		value: "Cuitzeo del Porvenir, MX",
		label: "Cuitzeo del Porvenir, MX",
		code: "CUIT-1",
	},
	{ value: "El Rosario, MX", label: "El Rosario, MX", code: "ELRO-1" },
	{
		value: "El Oro de Hidalgo, MX",
		label: "El Oro de Hidalgo, MX",
		code: "EODH-1",
	},
	{ value: "Huamantla, MX", label: "Huamantla, MX", code: "HUAM-1" },
	{
		value: "Juchitán de Zaragoza, MX",
		label: "Juchitán de Zaragoza, MX",
		code: "JUDZ-1",
	},
	{
		value: "Aguascalientes (Estado), MX",
		label: "Aguascalientes (Estado), MX",
		code: "MEXI-6",
	},
	{
		value: "San Luis Río Colorado, MX",
		label: "San Luis Río Colorado, MX",
		code: "MURO-6",
	},
	{ value: "El Pescadero, MX", label: "El Pescadero, MX", code: "PEIX-6" },
	{
		value: "San Juan Ixhuatepec, MX",
		label: "San Juan Ixhuatepec, MX",
		code: "SANJ-6",
	},
	{ value: "Sayulita, MX", label: "Sayulita, MX", code: "SAYU-6" },
	{ value: "El Sargento, MX", label: "El Sargento, MX", code: "SGTO-6" },
	{ value: "Tamaulipas, MX", label: "Tamaulipas, MX", code: "TAMA-1" },
	{ value: "Tecolutla, MX", label: "Tecolutla, MX", code: "TECO-6" },
	{
		value: "Tlaxcala (Estado), MX",
		label: "Tlaxcala (Estado), MX",
		code: "TLAS-1",
	},
	{
		value: "Teúl de González Ortega, MX",
		label: "Teúl de González Ortega, MX",
		code: "TLGNZO",
	},
	{
		value: "Veracruz (Estado), MX",
		label: "Veracruz (Estado), MX",
		code: "VERS-1",
	},
	{
		value: "Zacatecas (Estado), MX",
		label: "Zacatecas (Estado), MX",
		code: "ZACS-1",
	},
	{ value: "Apizaco, MX", label: "Apizaco, MX", code: "Apizaco" },
	{ value: "Coahuila , MX", label: "Coahuila , MX", code: "COAHI-6" },
	{
		value: "Cuautla de Morelos, MX",
		label: "Cuautla de Morelos, MX",
		code: "Cuautla",
	},
	{
		value: "Durango (Estado), MX",
		label: "Durango (Estado), MX",
		code: "DGO-002",
	},
	{
		value: "Guanajuato (Estado), MX",
		label: "Guanajuato (Estado), MX",
		code: "GNJ-002",
	},
	{ value: "Guasave, MX", label: "Guasave, MX", code: "GUASA-6" },
	{
		value: "General Zaragoza, MX",
		label: "General Zaragoza, MX",
		code: "GZA-002",
	},
	{ value: "Iturbide, MX", label: "Iturbide, MX", code: "ITB-002" },
	{
		value: "Jalpa de Canovas, MX",
		label: "Jalpa de Canovas, MX",
		code: "JPC-002",
	},
	{
		value: "Jiquilpan de Juárez, MX",
		label: "Jiquilpan de Juárez, MX",
		code: "JQP-002",
	},
	{ value: "Jerez, MX", label: "Jerez, MX", code: "JRZ-002" },
	{ value: "Linares, MX", label: "Linares, MX", code: "LNR-002" },
	{
		value: "Mineral de Angangueo, MX",
		label: "Mineral de Angangueo, MX",
		code: "MAG-002",
	},
	{
		value: "Mineral del Chico, MX",
		label: "Mineral del Chico, MX",
		code: "MCH-002",
	},
	{ value: "Mier, MX", label: "Mier, MX", code: "MER-002" },
	{
		value: "México (Estado), MX",
		label: "México (Estado), MX",
		code: "MEXIC-6",
	},
	{ value: "Malinalco, MX", label: "Malinalco, MX", code: "MLO-002" },
	{ value: "Montemorelos, MX", label: "Montemorelos, MX", code: "MMS-002" },
	{ value: "Mapimí, MX", label: "Mapimí, MX", code: "MPI-002" },
	{
		value: "Mineral de Pozos, MX",
		label: "Mineral de Pozos, MX",
		code: "MPZ-002",
	},
	{
		value: "Nochistlán de Mejía, MX",
		label: "Nochistlán de Mejía, MX",
		code: "NCT-002",
	},
	{ value: "Peña de Bernal, MX", label: "Peña de Bernal, MX", code: "PBL-002" },
	{ value: "Pahuatlán, MX", label: "Pahuatlán, MX", code: "PHN-002" },
	{ value: "Pinos, MX", label: "Pinos, MX", code: "PNS-002" },
	{
		value: "Papantla de Olarte, MX",
		label: "Papantla de Olarte, MX",
		code: "PPT-002",
	},
	{
		value: "Parras de la Fuente, MX",
		label: "Parras de la Fuente, MX",
		code: "PRF-002",
	},
	{ value: "Palizada, MX", label: "Palizada, MX", code: "PZD-002" },
	{
		value: "Real de Asientos, MX",
		label: "Real de Asientos, MX",
		code: "RAS-002",
	},
	{
		value: "Real de Catorce, MX",
		label: "Real de Catorce, MX",
		code: "RCC-002",
	},
	{ value: "Primo Tapia, MX", label: "Primo Tapia, MX", code: "TAPIA-6" },
	{
		value: "Baja California, MX",
		label: "Baja California, MX",
		code: "BAJACA-6",
	},
	{ value: "Chiapas , MX", label: "Chiapas , MX", code: "CHIAPA-6" },
	{
		value: "Colima (Estado), MX",
		label: "Colima (Estado), MX",
		code: "COLIMA-6",
	},
	{ value: "Guasave, MX", label: "Guasave, MX", code: "GUASA-66" },
	{ value: "Morelos, MX", label: "Morelos, MX", code: "MORELO-6" },
	{
		value: "Puerto Aventuras, MX",
		label: "Puerto Aventuras, MX",
		code: "PUERTOAV",
	},
	{
		value: "Tequesquitengo, Morelos, MX",
		label: "Tequesquitengo, Morelos, MX",
		code: "TKMor-50",
	},
	{ value: "Atlixco, MX", label: "Atlixco, MX", code: "fake-797" },
	{ value: "Chignahuapan, MX", label: "Chignahuapan, MX", code: "fake-807" },
	{ value: "Cholula, MX", label: "Cholula, MX", code: "fake-808" },
	{
		value: "Cuatrociénegas de Carranza, MX",
		label: "Cuatrociénegas de Carranza, MX",
		code: "fake-811",
	},
	{ value: "Edzná, MX", label: "Edzná, MX", code: "fake-813" },
	{ value: "Garcia, MX", label: "Garcia, MX", code: "fake-815" },
	{
		value: "Huasca de Ocampo, MX",
		label: "Huasca de Ocampo, MX",
		code: "fake-818",
	},
	{ value: "Izamal, MX", label: "Izamal, MX", code: "fake-819" },
	{ value: "Kabah, MX", label: "Kabah, MX", code: "fake-820" },
	{ value: "Mayapan, MX", label: "Mayapan, MX", code: "fake-826" },
	{ value: "Mitla, MX", label: "Mitla, MX", code: "fake-828" },
	{ value: "Monte Alban , MX", label: "Monte Alban , MX", code: "fake-829" },
	{
		value: "Real del Monte, MX",
		label: "Real del Monte, MX",
		code: "fake-831",
	},
	{
		value: "Santa Maria del Tule, MX",
		label: "Santa Maria del Tule, MX",
		code: "fake-835",
	},
	{
		value: "Santiago (Nuevo León), MX",
		label: "Santiago (Nuevo León), MX",
		code: "fake-838",
	},
	{ value: "Siho Playa, MX", label: "Siho Playa, MX", code: "fake-840" },
	{
		value: "Baja California Sur , MX",
		label: "Baja California Sur , MX",
		code: "BAJASUR-6",
	},
	{ value: "Chihuahua , MX", label: "Chihuahua , MX", code: "CANICHE-6" },
	{ value: "Cuetzalan, MX", label: "Cuetzalan, MX", code: "Cuetzalan" },
	{ value: "Hidalgo , MX", label: "Hidalgo , MX", code: "HIDALGO-6" },
	{ value: "Jalisco , MX", label: "Jalisco , MX", code: "JALISCO-6" },
	{
		value: "Magdalena de Kino, MX",
		label: "Magdalena de Kino, MX",
		code: "MDEKINO-6",
	},
	{ value: "Punta Mita , MX", label: "Punta Mita , MX", code: "Puntamita" },
	{ value: "Campeche, MX", label: "Campeche, MX", code: "CAMPECHE-6" },
	{
		value: "Michoacán de Ocampo, MX",
		label: "Michoacán de Ocampo, MX",
		code: "MICHOCAN-6",
	},
	{
		value: "San Juan Bautista Tuxtepec, MX",
		label: "San Juan Bautista Tuxtepec, MX",
		code: "SJBTuxtepec",
	},
	{
		value: "Chilpancingo de los Bravo, MX",
		label: "Chilpancingo de los Bravo, MX",
		code: "Chilpancingo",
	},
	{
		value: "Ciudad Valles, MX",
		label: "Ciudad Valles, MX",
		code: "CiudadValles",
	},
	{
		value: "Zamora de Hidalgo, MX",
		label: "Zamora de Hidalgo, MX",
		code: "ZamoradeHidalgo",
	},
	{ value: "Isla Kosrae, FM", label: "Isla Kosrae, FM", code: "KOS-45" },
	{ value: "Chuuk, FM", label: "Chuuk, FM", code: "CHUUK-6" },
	{ value: "Pohnpei, FM", label: "Pohnpei, FM", code: "POHNP-6" },
	{ value: "Wa&apos;ab, FM", label: "Wa&apos;ab, FM", code: "YAP-002" },
	{ value: "Chisinau, MD", label: "Chisinau, MD", code: "KIV" },
	{ value: "Monaco, MC", label: "Monaco, MC", code: "MCM" },
	{ value: "Montecarlo, MC", label: "Montecarlo, MC", code: "MNTC-1" },
	{ value: "Ulán Bator, MN", label: "Ulán Bator, MN", code: "ULN" },
	{
		value: "Budva (Svetti Stefan), ME",
		label: "Budva (Svetti Stefan), ME",
		code: "TIV-1",
	},
	{ value: "Ulcinj, ME", label: "Ulcinj, ME", code: "TIV-4" },
	{ value: "Petrovac, ME", label: "Petrovac, ME", code: "TIV-5" },
	{ value: "Tívat, ME", label: "Tívat, ME", code: "TIV-10" },
	{ value: "Herceg Novi, ME", label: "Herceg Novi, ME", code: "TIV-2" },
	{ value: "Bar, ME", label: "Bar, ME", code: "TIV-8" },
	{ value: "Kotor, ME", label: "Kotor, ME", code: "TIV-11" },
	{ value: "Podgorica, ME", label: "Podgorica, ME", code: "TIV-7" },
	{ value: "Njeguši, ME", label: "Njeguši, ME", code: "NJE-6" },
	{ value: "Risan, ME", label: "Risan, ME", code: "RIS-6" },
	{ value: "Zabljak, ME", label: "Zabljak, ME", code: "ZAB-6" },
	{ value: "Durmitor, ME", label: "Durmitor, ME", code: "DUNP-1" },
	{ value: "Kolasin, ME", label: "Kolasin, ME", code: "KOLA-1" },
	{ value: "Prokletije, ME", label: "Prokletije, ME", code: "PRNP-1" },
	{ value: "Virpazar, ME", label: "Virpazar, ME", code: "VIRP-1" },
	{ value: "Rabat, MA", label: "Rabat, MA", code: "RBA" },
	{ value: "Casablanca, MA", label: "Casablanca, MA", code: "CAA" },
	{ value: "Marrakech, MA", label: "Marrakech, MA", code: "RAK" },
	{ value: "Merzouga, MA", label: "Merzouga, MA", code: "ERF1" },
	{ value: "Agadir, MA", label: "Agadir, MA", code: "AGA" },
	{ value: "Esauira, MA", label: "Esauira, MA", code: "ESS" },
	{ value: "El Yadida, MA", label: "El Yadida, MA", code: "MA2" },
	{ value: "Tetuán, MA", label: "Tetuán, MA", code: "TET" },
	{ value: "Saïdia, MA", label: "Saïdia, MA", code: "TT1" },
	{ value: "Zagora, MA", label: "Zagora, MA", code: "ZGO1" },
	{ value: "Tarudant, MA", label: "Tarudant, MA", code: "AGA-2" },
	{ value: "Fez, MA", label: "Fez, MA", code: "FEZ-1" },
	{
		value: "Meknes ( Mequinez ), MA",
		label: "Meknes ( Mequinez ), MA",
		code: "MEK-1",
	},
	{ value: "Oualidía , MA", label: "Oualidía , MA", code: "OLD-1" },
	{ value: "Tamuda Bay, MA", label: "Tamuda Bay, MA", code: "TNG-3" },
	{ value: "Chauen, MA", label: "Chauen, MA", code: "CHH" },
	{ value: "Dajla, MA", label: "Dajla, MA", code: "DAK" },
	{ value: "Ifrane, MA", label: "Ifrane, MA", code: "IFR" },
	{ value: "Zagora, MA", label: "Zagora, MA", code: "ZGO" },
	{ value: "Temara, MA", label: "Temara, MA", code: "FAKE" },
	{ value: "Taghazout, MA", label: "Taghazout, MA", code: "AGA-5" },
	{ value: "Beni Melal, MA", label: "Beni Melal, MA", code: "BEM-1" },
	{ value: "Er-rachidia, MA", label: "Er-rachidia, MA", code: "ERH-1" },
	{ value: "Tánger, MA", label: "Tánger, MA", code: "TNG-1" },
	{ value: "Arcila, MA", label: "Arcila, MA", code: "TNG-4" },
	{ value: "Erfoud, MA", label: "Erfoud, MA", code: "ERF-10" },
	{ value: "Alhucemas, MA", label: "Alhucemas, MA", code: "AHU" },
	{ value: "Oujda, MA", label: "Oujda, MA", code: "MA1" },
	{ value: "Nabeul, MA", label: "Nabeul, MA", code: "NAB" },
	{ value: "Nador, MA", label: "Nador, MA", code: "NAD" },
	{ value: "Uarzazate, MA", label: "Uarzazate, MA", code: "OUA" },
	{ value: "Ouzina, MA", label: "Ouzina, MA", code: "OUZ-6" },
	{ value: "Imlil, MA", label: "Imlil, MA", code: "RAK-2" },
	{ value: "Ourika, MA", label: "Ourika, MA", code: "RAK-6" },
	{
		value: "Castillejos Fnideq, MA",
		label: "Castillejos Fnideq, MA",
		code: "TNG-5",
	},
	{ value: "Beni Mellal, MA", label: "Beni Mellal, MA", code: "fake-801" },
	{ value: "Ait Ben Haddou, MA", label: "Ait Ben Haddou, MA", code: "AIT" },
	{ value: "Rissani, MA", label: "Rissani, MA", code: "RIS" },
	{ value: "Tinerhir, MA", label: "Tinerhir, MA", code: "TIN" },
	{ value: "Alnif, MA", label: "Alnif, MA", code: "ALN-1" },
	{ value: "Midelt, MA", label: "Midelt, MA", code: "MID-6" },
	{ value: "Rincón, MA", label: "Rincón, MA", code: "MDIQ-1" },
	{ value: "Boumalne Dades, MA", label: "Boumalne Dades, MA", code: "BOU-002" },
	{ value: "Larache, MA", label: "Larache, MA", code: "LCH-002" },
	{ value: "Nouaceur, MA", label: "Nouaceur, MA", code: "Nouaceur" },
	{ value: "Beira, MZ", label: "Beira, MZ", code: "BIA" },
	{ value: "Gorongosa, MZ", label: "Gorongosa, MZ", code: "OGR-1" },
	{ value: "Tete, MZ", label: "Tete, MZ", code: "TET-1" },
	{ value: "Inhaca, MZ", label: "Inhaca, MZ", code: "153" },
	{ value: "Vilanculo, MZ", label: "Vilanculo, MZ", code: "327" },
	{ value: "Inhambane, MZ", label: "Inhambane, MZ", code: "720" },
	{ value: "Maputo, MZ", label: "Maputo, MZ", code: "MPT" },
	{ value: "Vilanculos, MZ", label: "Vilanculos, MZ", code: "INH-3" },
	{ value: "Pemba, MZ", label: "Pemba, MZ", code: "PEM-123" },
	{ value: "Vilanculos, MZ", label: "Vilanculos, MZ", code: "Vil-123" },
	{ value: "Quelimane, MZ", label: "Quelimane, MZ", code: "que-002" },
	{ value: "Xai-Xai, MZ", label: "Xai-Xai, MZ", code: "fake-132" },
	{ value: "Lichinga, MZ", label: "Lichinga, MZ", code: "VXC1" },
	{ value: "Lago Niassa, MZ", label: "Lago Niassa, MZ", code: "VXC2" },
	{
		value: "Isla de Benguerra, MZ",
		label: "Isla de Benguerra, MZ",
		code: "BEN-1",
	},
	{ value: "Isla de Ibo, MZ", label: "Isla de Ibo, MZ", code: "IDI-1" },
	{
		value: "Isla de Mozambique, MZ",
		label: "Isla de Mozambique, MZ",
		code: "IDM-1",
	},
	{ value: "Mecúfi, MZ", label: "Mecúfi, MZ", code: "MEC-1" },
	{ value: "Islas Quirimbas, MZ", label: "Islas Quirimbas, MZ", code: "MZB-2" },
	{ value: "Nacala, MZ", label: "Nacala, MZ", code: "NAC-1" },
	{ value: "Ponta do Ouro, MZ", label: "Ponta do Ouro, MZ", code: "PDO-1" },
	{ value: "Ponta Mamoli, MZ", label: "Ponta Mamoli, MZ", code: "PON-6" },
	{ value: "Playa de Tofo, MZ", label: "Playa de Tofo, MZ", code: "TBM-1" },
	{
		value: "Archipiélago de Bazaruto, MZ",
		label: "Archipiélago de Bazaruto, MZ",
		code: "BZTA-1",
	},
	{
		value: "Isla de Bazaruto, MZ",
		label: "Isla de Bazaruto, MZ",
		code: "BZTI-1",
	},
	{ value: "Chidenguele, MZ", label: "Chidenguele, MZ", code: "CHID-1" },
	{ value: "Massinga, MZ", label: "Massinga, MZ", code: "MASS-6" },
	{ value: "Isla Medjumbe, MZ", label: "Isla Medjumbe, MZ", code: "MEDJ-6" },
	{ value: "Mossuril, MZ", label: "Mossuril, MZ", code: "MOSS-1" },
	{ value: "Nampula, MZ", label: "Nampula, MZ", code: "NAM-99" },
	{ value: "Chimoio, MZ", label: "Chimoio, MZ", code: "CHO-002" },
	{
		value: "Playa de Maneta, MZ",
		label: "Playa de Maneta, MZ",
		code: "MTB-002",
	},
	{
		value: "Praia do Bilene, MZ",
		label: "Praia do Bilene, MZ",
		code: "PBE-002",
	},
	{ value: "Bagan, MM", label: "Bagan, MM", code: "BPE" },
	{ value: "Rangún, MM", label: "Rangún, MM", code: "RGN" },
	{ value: "Naipyidó, MM", label: "Naipyidó, MM", code: "NYW" },
	{ value: "Mandalay, MM", label: "Mandalay, MM", code: "MDL" },
	{ value: "Lago Inle, MM", label: "Lago Inle, MM", code: "MDL-2" },
	{ value: "Ngapali, MM", label: "Ngapali, MM", code: "Fake-133" },
	{ value: "Ngwe Saung, MM", label: "Ngwe Saung, MM", code: "Fake-134" },
	{ value: "Loikaw, MM", label: "Loikaw, MM", code: "MDL-4" },
	{ value: "Nyaung Oo Phee, MM", label: "Nyaung Oo Phee, MM", code: "NYUNG" },
	{ value: "He Hoe, MM", label: "He Hoe, MM", code: "HEHO-6" },
	{ value: "Monywa, MM", label: "Monywa, MM", code: "Monywa" },
	{ value: "Windhoek, NA", label: "Windhoek, NA", code: "ERS" },
	{ value: "Swakopmund, NA", label: "Swakopmund, NA", code: "SWP" },
	{ value: "Lüderitz, NA", label: "Lüderitz, NA", code: "KMP-2" },
	{ value: "Walvis Bay, NA", label: "Walvis Bay, NA", code: "SWP-3" },
	{
		value: "Etosha: Parque Nacional, NA",
		label: "Etosha: Parque Nacional, NA",
		code: "TSB",
	},
	{
		value: "Fish River Canyon, NA",
		label: "Fish River Canyon, NA",
		code: "KMP-3",
	},
	{ value: "Opuwo, NA", label: "Opuwo, NA", code: "TSB-4" },
	{ value: "Otjiwarongo, NA", label: "Otjiwarongo, NA", code: "OTJIW-6" },
	{ value: "Walvis Bay, NA", label: "Walvis Bay, NA", code: "WAL-123" },
	{ value: "Mariental, NA", label: "Mariental, NA", code: "MARIEN-6" },
	{ value: "Sossusvlei, NA", label: "Sossusvlei, NA", code: "SOSSUS-6" },
	{ value: "Twyfelfontein, NA", label: "Twyfelfontein, NA", code: "TWY" },
	{ value: "Okahandja, NA", label: "Okahandja, NA", code: "OKAH" },
	{ value: "Okazizi, NA", label: "Okazizi, NA", code: "OKZZ" },
	{ value: "Omitara, NA", label: "Omitara, NA", code: "OMIT" },
	{
		value: "Waterberg Plateau National Park, NA",
		label: "Waterberg Plateau National Park, NA",
		code: "WTBG",
	},
	{ value: "Outjo , NA", label: "Outjo , NA", code: "Outjo" },
	{ value: "Rundu, NA", label: "Rundu, NA", code: "Rundu" },
	{ value: "Kaokolandia, NA", label: "Kaokolandia, NA", code: "KAOK-1" },
	{ value: "Mudumu, NA", label: "Mudumu, NA", code: "Mudumu" },
	{
		value: "Área de concesión Naye-Naye, NA",
		label: "Área de concesión Naye-Naye, NA",
		code: "NNCA-1",
	},
	{
		value: "Otjihavera Mountains, NA",
		label: "Otjihavera Mountains, NA",
		code: "OTMO-1",
	},
	{ value: "Onguma, NA", label: "Onguma, NA", code: "Onguma" },
	{ value: "Palmwag, NA", label: "Palmwag, NA", code: "PLMW-1" },
	{ value: "Tsumeb, NA", label: "Tsumeb, NA", code: "Tsumeb" },
	{ value: "Aus, NA", label: "Aus, NA", code: "asdefg" },
	{ value: "Hochfeld, NA", label: "Hochfeld, NA", code: "hochfN" },
	{ value: "Divundu, NA", label: "Divundu, NA", code: "Divundu" },
	{ value: "Gobabis, NA", label: "Gobabis, NA", code: "Gobabis" },
	{
		value: "Parque nacional de Khaudom, NA",
		label: "Parque nacional de Khaudom, NA",
		code: "Khaudum",
	},
	{ value: "Omaruru, NA", label: "Omaruru, NA", code: "Omaruru" },
	{ value: "Tsumkwe , NA", label: "Tsumkwe , NA", code: "Tsumkwe" },
	{
		value: "Sesriem Naukluft, NA",
		label: "Sesriem Naukluft, NA",
		code: "sesriem",
	},
	{ value: "Kamanjab, NA", label: "Kamanjab, NA", code: "Kamanjab" },
	{ value: "Khorixas, NA", label: "Khorixas, NA", code: "Khorixas" },
	{ value: "Namutoni, NA", label: "Namutoni, NA", code: "Namutoni" },
	{ value: "Okonjima, NA", label: "Okonjima, NA", code: "Okonjima" },
	{
		value: "Cataratas Epupa, NA",
		label: "Cataratas Epupa, NA",
		code: "pdggdhnf",
	},
	{ value: "Okaukuejo, NA", label: "Okaukuejo, NA", code: "Okaukuejo" },
	{ value: "Popa Falls, NA", label: "Popa Falls, NA", code: "PopaFalls" },
	{ value: "Solitaire, NA", label: "Solitaire, NA", code: "Solitaire" },
	{ value: "Stampriet, NA", label: "Stampriet, NA", code: "Stampriet" },
	{ value: "Sesfontein , NA", label: "Sesfontein , NA", code: "Sesfontein" },
	{ value: "Spitzkoppe, NA", label: "Spitzkoppe, NA", code: "Spitzkoppe" },
	{ value: "Nkasa Rupara, NA", label: "Nkasa Rupara, NA", code: "NkasaRupara" },
	{
		value: "Katima Mulilo, NA",
		label: "Katima Mulilo, NA",
		code: "KatimaMulilo",
	},
	{
		value: "Keetmanshoop, NA",
		label: "Keetmanshoop, NA",
		code: "Keetmanshoop",
	},
	{ value: "Katmandú, NP", label: "Katmandú, NP", code: "KTM" },
	{ value: "Pokhara, NP", label: "Pokhara, NP", code: "PKR-1" },
	{ value: "Chitwan, NP", label: "Chitwan, NP", code: "CTW-1" },
	{ value: "Patan, NP", label: "Patan, NP", code: "PTN-1" },
	{ value: "Bhaktapur, NP", label: "Bhaktapur, NP", code: "fake-109" },
	{ value: "Lumbini , NP", label: "Lumbini , NP", code: "Lumbini" },
	{ value: "Bandipur , NP", label: "Bandipur , NP", code: "Bandipur" },
	{ value: "Nagarkot , NP", label: "Nagarkot , NP", code: "Nagarkot" },
	{ value: "Dhulikhel , NP", label: "Dhulikhel , NP", code: "Dhulikhel" },
	{ value: "Kralendijk, AN", label: "Kralendijk, AN", code: "KRA-123" },
	{ value: "Frisian Islands, AN", label: "Frisian Islands, AN", code: "FRI" },
	{
		value: "Hoek van Holland, AN",
		label: "Hoek van Holland, AN",
		code: "Hoek",
	},
	{ value: "Limburg, AN", label: "Limburg, AN", code: "LMO-B" },
	{ value: "Roermond, AN", label: "Roermond, AN", code: "Roermond" },
	{ value: "Oegstgeest, AN", label: "Oegstgeest, AN", code: "Oegstgeest" },
	{ value: "Nueva Caledonia, NC", label: "Nueva Caledonia, NC", code: "NLK" },
	{ value: "Ouvéa, NC", label: "Ouvéa, NC", code: "Ouvea" },
	{ value: "Noumea, NC", label: "Noumea, NC", code: "123-nou" },
	{
		value: "Isla de los Pinos, NC",
		label: "Isla de los Pinos, NC",
		code: "PINES-6",
	},
	{ value: "Auckland, NZ", label: "Auckland, NZ", code: "AKL" },
	{ value: "Queenstown, NZ", label: "Queenstown, NZ", code: "ZQN" },
	{ value: "Christchurch, NZ", label: "Christchurch, NZ", code: "CHC-1" },
	{ value: "Kaikoura, NZ", label: "Kaikoura, NZ", code: "CHC" },
	{ value: "Nelson, NZ", label: "Nelson, NZ", code: "NSN" },
	{ value: "Rotorua, NZ", label: "Rotorua, NZ", code: "RO1" },
	{ value: "Dunedin, NZ", label: "Dunedin, NZ", code: "ZQN-5" },
	{ value: "Hamilton, NZ", label: "Hamilton, NZ", code: "HLZ" },
	{ value: "New Plymouth, NZ", label: "New Plymouth, NZ", code: "NPL" },
	{ value: "Southland, NZ", label: "Southland, NZ", code: "NZ1" },
	{ value: "Invercargill, NZ", label: "Invercargill, NZ", code: "NZ2" },
	{ value: "Fiordland, NZ", label: "Fiordland, NZ", code: "TEU" },
	{ value: "Wellington, NZ", label: "Wellington, NZ", code: "WLG" },
	{ value: "Franz Josef, NZ", label: "Franz Josef, NZ", code: "GMN1" },
	{ value: "Coromandel, NZ", label: "Coromandel, NZ", code: "AKL-2" },
	{ value: "Gisborne , NZ", label: "Gisborne , NZ", code: "GIS-1" },
	{ value: "Paraparaumu, NZ", label: "Paraparaumu, NZ", code: "PPQ-5" },
	{ value: "Tauranga, NZ", label: "Tauranga, NZ", code: "RO1-5" },
	{
		value: "Palmerston north, NZ",
		label: "Palmerston north, NZ",
		code: "WAG-2",
	},
	{ value: "Paihia, NZ", label: "Paihia, NZ", code: "WRE-4" },
	{ value: "Napier, NZ", label: "Napier, NZ", code: "npe-3" },
	{ value: "Kerikeri, NZ", label: "Kerikeri, NZ", code: "KKR-12" },
	{ value: "Hawkes Bay, NZ", label: "Hawkes Bay, NZ", code: "fake-570" },
	{
		value: "Manawatu- Wanganui, NZ",
		label: "Manawatu- Wanganui, NZ",
		code: "fake-571",
	},
	{ value: "Marlborough, NZ", label: "Marlborough, NZ", code: "fake-572" },
	{ value: "Taupo, NZ", label: "Taupo, NZ", code: "CAS" },
	{ value: "Whangarei, NZ", label: "Whangarei, NZ", code: "WHG-1" },
	{ value: "Hokitika, NZ", label: "Hokitika, NZ", code: "HOK-45" },
	{ value: "Aitutaki, NZ", label: "Aitutaki, NZ", code: "AITTK-6" },
	{ value: "Hahei, NZ", label: "Hahei, NZ", code: "HAHEI-6" },
	{ value: "Ohakune, NZ", label: "Ohakune, NZ", code: "HKUNE-6" },
	{ value: "Waitomo, NZ", label: "Waitomo, NZ", code: "WTOMO-6" },
	{ value: "Akaroa, NZ", label: "Akaroa, NZ", code: "AKAROA-6" },
	{ value: "Hastings, NZ, NZ", label: "Hastings, NZ, NZ", code: "HSTNGS-6" },
	{ value: "Ashburton, NZ", label: "Ashburton, NZ", code: "fake-338" },
	{ value: "Whakatane, NZ", label: "Whakatane, NZ", code: "WHKTANE-6" },
	{ value: "Omapere, NZ", label: "Omapere, NZ", code: "AKL2" },
	{ value: "Lago Tékapo, NZ", label: "Lago Tékapo, NZ", code: "56214" },
	{
		value: "Omarama (Nueva Zelanda), NZ",
		label: "Omarama (Nueva Zelanda), NZ",
		code: "OMA-1",
	},
	{ value: "Hanmer Springs, NZ", label: "Hanmer Springs, NZ", code: "525412" },
	{ value: "Wanaka, NZ", label: "Wanaka, NZ", code: "845127" },
	{ value: "Blenheim, NZ", label: "Blenheim, NZ", code: "BLE-88" },
	{ value: "Greymouth, NZ", label: "Greymouth, NZ", code: "GREY-6" },
	{ value: "Russell, NZ", label: "Russell, NZ", code: "RUSS-1" },
	{ value: "Timaru, NZ", label: "Timaru, NZ", code: "TIRU-6" },
	{ value: "Te Anau, NZ", label: "Te Anau, NZ", code: "TeAnau" },
	{ value: "Punakaiki, NZ", label: "Punakaiki, NZ", code: "8541269" },
	{
		value: "Parque nacional Abel Tasman, NZ",
		label: "Parque nacional Abel Tasman, NZ",
		code: "8542156",
	},
	{ value: "Glaciar Fox, NZ", label: "Glaciar Fox, NZ", code: "FGR-002" },
	{ value: "Matamata, NZ", label: "Matamata, NZ", code: "MATAM-6" },
	{ value: "Monte Cook , NZ", label: "Monte Cook , NZ", code: "Moun123" },
	{ value: "Picton, NZ", label: "Picton, NZ", code: "PTN-002" },
	{ value: "Westport, NZ", label: "Westport, NZ", code: "WTP-002" },
	{ value: "Hokitika, NZ", label: "Hokitika, NZ", code: "45521128" },
	{ value: "Tongariro , NZ", label: "Tongariro , NZ", code: "Tongariro235" },
	{ value: "Managua, NI", label: "Managua, NI", code: "MG1" },
	{ value: "Granada, NI", label: "Granada, NI", code: "GR6" },
	{ value: "León, NI", label: "León, NI", code: "LN2-3" },
	{ value: "Montelimar, NI", label: "Montelimar, NI", code: "MNM-3" },
	{ value: "Masaya, NI", label: "Masaya, NI", code: "MYA-3" },
	{
		value: "San Juan del Sur, NI",
		label: "San Juan del Sur, NI",
		code: "SJD-3",
	},
	{ value: "Tola Rivas, NI", label: "Tola Rivas, NI", code: "SJD-4" },
	{ value: "Bluefields, NI", label: "Bluefields, NI", code: "BLU123" },
	{ value: "Calala Island, NI", label: "Calala Island, NI", code: "CAL123" },
	{ value: "Islas del Maiz, NI", label: "Islas del Maiz, NI", code: "COR123" },
	{ value: "Playa Maderas, NI", label: "Playa Maderas, NI", code: "MADE123" },
	{ value: "Niamey, NE", label: "Niamey, NE", code: "NIM" },
	{ value: "Lagos, NG", label: "Lagos, NG", code: "LOS" },
	{ value: "Abuja, NG", label: "Abuja, NG", code: "ABV" },
	{ value: "Enugu, NG", label: "Enugu, NG", code: "ENU-96" },
	{ value: "Kano, NG", label: "Kano, NG", code: "KAN-92" },
	{ value: "Port Harcourt, NG", label: "Port Harcourt, NG", code: "PHC-82" },
	{ value: "Pyongyang, KP", label: "Pyongyang, KP", code: "PYN-2" },
	{
		value: "Islas Marianas del Norte, MP",
		label: "Islas Marianas del Norte, MP",
		code: "SPN-1",
	},
	{ value: "Bergen, NO", label: "Bergen, NO", code: "BGO" },
	{ value: "Oslo, NO", label: "Oslo, NO", code: "OSL" },
	{ value: "Larvik, NO", label: "Larvik, NO", code: "VE1" },
	{ value: "Islas Lofoten, NO", label: "Islas Lofoten, NO", code: "fake-141" },
	{ value: "Alesund, NO", label: "Alesund, NO", code: "ALE" },
	{ value: "Alta, NO", label: "Alta, NO", code: "ALT" },
	{ value: "Balestrand, NO", label: "Balestrand, NO", code: "BAL" },
	{ value: "Bodo, NO", label: "Bodo, NO", code: "BOO" },
	{ value: "Forde, NO", label: "Forde, NO", code: "FDE" },
	{ value: "Floro, NO", label: "Floro, NO", code: "FRO" },
	{ value: "Geiranger, NO", label: "Geiranger, NO", code: "GEI" },
	{ value: "Haugesund, NO", label: "Haugesund, NO", code: "HA1" },
	{ value: "Kristiansund, NO", label: "Kristiansund, NO", code: "KRI" },
	{ value: "Kristiansand, NO", label: "Kristiansand, NO", code: "KRS" },
	{ value: "Leknes, NO", label: "Leknes, NO", code: "LKN" },
	{ value: "Molde, NO", label: "Molde, NO", code: "MOL" },
	{ value: "Stavanger, NO", label: "Stavanger, NO", code: "SVG" },
	{ value: "Lillehammer, NO", label: "Lillehammer, NO", code: "XXL" },
	{ value: "Sogndal, NO", label: "Sogndal, NO", code: "SOG-1" },
	{ value: "Arendal, NO", label: "Arendal, NO", code: "AR1" },
	{ value: "Geilo, NO", label: "Geilo, NO", code: "GLO" },
	{ value: "Horten, NO", label: "Horten, NO", code: "HTN" },
	{ value: "Gjøvik, NO", label: "Gjøvik, NO", code: "JG1" },
	{ value: "Kongsberg, NO", label: "Kongsberg, NO", code: "KO1" },
	{ value: "Sarpsborg, NO", label: "Sarpsborg, NO", code: "SA1" },
	{ value: "Skien, NO", label: "Skien, NO", code: "SK2" },
	{ value: "Steinkjer, NO", label: "Steinkjer, NO", code: "ST1" },
	{ value: "Trondheim, NO", label: "Trondheim, NO", code: "TRD" },
	{ value: "Voss, NO", label: "Voss, NO", code: "XVK" },
	{ value: "Baerum, NO", label: "Baerum, NO", code: "OSL2" },
	{ value: "Asker, NO", label: "Asker, NO", code: "OSL5" },
	{ value: "Drammen, NO", label: "Drammen, NO", code: "OSL9" },
	{ value: "Sandnes, NO", label: "Sandnes, NO", code: "SVG2" },
	{ value: "Sandefjord, NO", label: "Sandefjord, NO", code: "VE11" },
	{ value: "Tonsberg, NO", label: "Tonsberg, NO", code: "VE13" },
	{ value: "Hamar, NO", label: "Hamar, NO", code: "HAA-1" },
	{ value: "Longyearbyen, NO", label: "Longyearbyen, NO", code: "LYN-8" },
	{ value: "Stryn, NO", label: "Stryn, NO", code: "STR-1" },
	{ value: "Vossavangen, NO", label: "Vossavangen, NO", code: "XVK-1" },
	{ value: "Øyer, NO", label: "Øyer, NO", code: "fake-68" },
	{ value: "Hemsedal, NO", label: "Hemsedal, NO", code: "fake-69" },
	{ value: "Hyllestad, NO", label: "Hyllestad, NO", code: "fake-99" },
	{ value: "Eidfjord, NO", label: "Eidfjord, NO", code: "EI1" },
	{ value: "Fredrikstad, NO", label: "Fredrikstad, NO", code: "FDK" },
	{ value: "Svolvaer, NO", label: "Svolvaer, NO", code: "SVO" },
	{ value: "Tromso, NO", label: "Tromso, NO", code: "TRO" },
	{ value: "Trysil, NO", label: "Trysil, NO", code: "TYS" },
	{ value: "Fagernes, NO", label: "Fagernes, NO", code: "VDB" },
	{ value: "Finnsnes, NO", label: "Finnsnes, NO", code: "FNS-1" },
	{ value: "Gjevaer, NO", label: "Gjevaer, NO", code: "GJS-2" },
	{ value: "Hammerfest, NO", label: "Hammerfest, NO", code: "HMF-2" },
	{ value: "Hamn, Skaland, NO", label: "Hamn, Skaland, NO", code: "HMN-1" },
	{ value: "Honningsvag, NO", label: "Honningsvag, NO", code: "HNG-2" },
	{ value: "Havoysund, NO", label: "Havoysund, NO", code: "HVY-2" },
	{ value: "Farsund, NO", label: "Farsund, NO", code: "KRS-2" },
	{ value: "Mefjord, NO", label: "Mefjord, NO", code: "MFJ-1" },
	{ value: "Honefoss, NO", label: "Honefoss, NO", code: "OSL-7" },
	{ value: "Mortsund Veien, NO", label: "Mortsund Veien, NO", code: "OUT-5" },
	{ value: "Skarsvag, NO", label: "Skarsvag, NO", code: "SKG-2" },
	{ value: "Telemark, NO", label: "Telemark, NO", code: "TLMARK" },
	{ value: "Gryllefjord, NO", label: "Gryllefjord, NO", code: "gyllfd" },
	{ value: "Andalsnes, NO", label: "Andalsnes, NO", code: "AND-123" },
	{ value: "Sognefjord, NO", label: "Sognefjord, NO", code: "SFORD-6" },
	{ value: "Beitostolen, NO", label: "Beitostolen, NO", code: "fake-67" },
	{ value: "Loen, NO", label: "Loen, NO", code: "loen123" },
	{ value: "Dombas, NO", label: "Dombas, NO", code: "fake-291" },
	{ value: "Holmsbu, NO", label: "Holmsbu, NO", code: "fake-292" },
	{ value: "Hardanger, NO", label: "Hardanger, NO", code: "fake-293" },
	{ value: "Honningsvag, NO", label: "Honningsvag, NO", code: "fake-294" },
	{ value: "Flam, NO", label: "Flam, NO", code: "fake-1009" },
	{ value: "Harstad, NO", label: "Harstad, NO", code: "HAR" },
	{ value: "Narvik, NO", label: "Narvik, NO", code: "NVK" },
	{ value: "Andenes , NO", label: "Andenes , NO", code: "nds" },
	{ value: "Notodden, NO", label: "Notodden, NO", code: "NTDD" },
	{ value: "Lodingen , NO", label: "Lodingen , NO", code: "lfbj" },
	{ value: "Beitostølen, NO", label: "Beitostølen, NO", code: "BTO-1" },
	{ value: "Fåvang, NO", label: "Fåvang, NO", code: "FAV-1" },
	{ value: "Karasjok, NO", label: "Karasjok, NO", code: "KAR-6" },
	{ value: "Kaupanger, NO", label: "Kaupanger, NO", code: "KAU-6" },
	{ value: "Kirkenes, NO", label: "Kirkenes, NO", code: "KIR-1" },
	{ value: "Magerøya, NO", label: "Magerøya, NO", code: "MAG-6" },
	{ value: "Skei, NO", label: "Skei, NO", code: "SKE-1" },
	{ value: "Svalbard, NO", label: "Svalbard, NO", code: "SVA-6" },
	{ value: "Nordkjosbotn , NO", label: "Nordkjosbotn , NO", code: "ndrok" },
	{ value: "Ålesund, NO", label: "Ålesund, NO", code: "ALE-65" },
	{ value: "Ålesund, NO", label: "Ålesund, NO", code: "ASD-67" },
	{ value: "Bodø, NO", label: "Bodø, NO", code: "BOD-92" },
	{ value: "Grimstad, NO", label: "Grimstad, NO", code: "GRIM-6" },
	{
		value: "Møre og Romsdal (Condado), NO",
		label: "Møre og Romsdal (Condado), NO",
		code: "MORE-6",
	},
	{ value: "Moss, NO", label: "Moss, NO", code: "MOSS-6" },
	{ value: "Nusfjord, NO", label: "Nusfjord, NO", code: "NUS-78" },
	{ value: "Oppdal, NO", label: "Oppdal, NO", code: "OPD-32" },
	{ value: "Porsgrunn, NO", label: "Porsgrunn, NO", code: "POR-91" },
	{ value: "Mo i Rana, NO", label: "Mo i Rana, NO", code: "RANA-6" },
	{ value: "Reine, NO", label: "Reine, NO", code: "REI-77" },
	{ value: "Røros , NO", label: "Røros , NO", code: "ROR-33" },
	{ value: "Stamsund, NO", label: "Stamsund, NO", code: "STA-32" },
	{ value: "Stranda, NO", label: "Stranda, NO", code: "STRA-1" },
	{ value: "Tønsberg, NO", label: "Tønsberg, NO", code: "TON-84" },
	{ value: "Trondelag, NO", label: "Trondelag, NO", code: "TRON-6" },
	{ value: "Vestland, NO", label: "Vestland, NO", code: "VEST-6" },
	{ value: "Vradal, NO", label: "Vradal, NO", code: "VLD-42" },
	{ value: "Lakselv, NO", label: "Lakselv, NO", code: "hfvgjk" },
	{ value: "Innhavet , NO", label: "Innhavet , NO", code: "innhvt" },
	{
		value: "Agder (Condado), NO",
		label: "Agder (Condado), NO",
		code: "AGDER-6",
	},
	{ value: "Laerdal, NO", label: "Laerdal, NO", code: "DAL-002" },
	{ value: "Fosnavag, NO", label: "Fosnavag, NO", code: "FSG-002" },
	{ value: "Nordland, NO", label: "Nordland, NO", code: "NORDL-6" },
	{ value: "Rogaland, NO", label: "Rogaland, NO", code: "ROGAL-6" },
	{ value: "Sommaroy, NO", label: "Sommaroy, NO", code: "SMY-002" },
	{ value: "Svingvoll, NO", label: "Svingvoll, NO", code: "SVG-002" },
	{ value: "Sekkemo, NO", label: "Sekkemo, NO", code: "Sekkemo" },
	{
		value: "Troms og Finnmark, NO",
		label: "Troms og Finnmark, NO",
		code: "TROMS-6",
	},
	{
		value: "Viken (Condado), NO",
		label: "Viken (Condado), NO",
		code: "VIKEN-6",
	},
	{ value: "Innlandet, NO", label: "Innlandet, NO", code: "INNLAN-6" },
	{ value: "Meistervik, NO", label: "Meistervik, NO", code: "MSTVIK-6" },
	{
		value: "Vestfold og Telemark, NO",
		label: "Vestfold og Telemark, NO",
		code: "VESFOL-6",
	},
	{ value: "Evenskjer, NO", label: "Evenskjer, NO", code: "evisnker" },
	{ value: "Mascate, OM", label: "Mascate, OM", code: "MCT" },
	{ value: "Al Wusta, OM", label: "Al Wusta, OM", code: "AWT-1" },
	{ value: "Dhofar, OM", label: "Dhofar, OM", code: "DHR-93" },
	{ value: "Musandam, OM", label: "Musandam, OM", code: "MSD-93" },
	{
		value: "A&apos;Sharqiyah, OM",
		label: "A&apos;Sharqiyah, OM",
		code: "SHA-93",
	},
	{ value: "Salalah, OM", label: "Salalah, OM", code: "SLL" },
	{ value: "Al Buraimi, OM", label: "Al Buraimi, OM", code: "ABR-93" },
	{ value: "Nizwa, OM", label: "Nizwa, OM", code: "OM1" },
	{ value: "Sohar, OM", label: "Sohar, OM", code: "SH6" },
	{ value: "Sur City, OM", label: "Sur City, OM", code: "SR3-1" },
	{ value: "Barka, OM", label: "Barka, OM", code: "BARK-6" },
	{ value: "Haima, OM", label: "Haima, OM", code: "HIM-93" },
	{ value: "Ibra, OM", label: "Ibra, OM", code: "IBA-93" },
	{ value: "Ibiri, OM", label: "Ibiri, OM", code: "IBR-93" },
	{ value: "Jebel Shams, OM", label: "Jebel Shams, OM", code: "JEBS-6" },
	{ value: "Khasab, OM", label: "Khasab, OM", code: "Khasab" },
	{ value: "Rustaq, OM", label: "Rustaq, OM", code: "Rustaq" },
	{ value: "Tiwi, OM", label: "Tiwi, OM", code: "TIWI-6" },
	{ value: "Bahla, OM", label: "Bahla, OM", code: "BAHLA-6" },
	{ value: "Ras Al-Jinz, OM", label: "Ras Al-Jinz, OM", code: "RASAJ-6" },
	{ value: "Wadi Shab, OM", label: "Wadi Shab, OM", code: "WADIS-6" },
	{ value: "Al Hamra, OM", label: "Al Hamra, OM", code: "ALHAMR-6" },
	{ value: "Wahiba Sands, OM", label: "Wahiba Sands, OM", code: "WAHIBA-6" },
	{ value: "Zighy Bay, OM", label: "Zighy Bay, OM", code: "fake-125" },
	{
		value: "Wadi Bani Khalid, OM",
		label: "Wadi Bani Khalid, OM",
		code: "WADIBANI-6",
	},
	{ value: "Islamabad, PK", label: "Islamabad, PK", code: "ISB" },
	{ value: "Karachi, PK", label: "Karachi, PK", code: "KHI" },
	{ value: "Lahore, PK", label: "Lahore, PK", code: "LHE" },
	{ value: "Abbottabad, PK", label: "Abbottabad, PK", code: "PK" },
	{ value: "Bahawalpur, PK", label: "Bahawalpur, PK", code: "fake-263" },
	{ value: "Koror, PW", label: "Koror, PW", code: "KOR" },
	{ value: "Jericó, PS", label: "Jericó, PS", code: "JRCO-6" },
	{
		value: "Ciudad de Panamá, PA",
		label: "Ciudad de Panamá, PA",
		code: "PAN-1",
	},
	{ value: "Playa Bonita, PA", label: "Playa Bonita, PA", code: "049" },
	{ value: "Almirante, PA", label: "Almirante, PA", code: "671" },
	{ value: "David, PA", label: "David, PA", code: "DAW-1" },
	{ value: "Colon, PA", label: "Colon, PA", code: "TOUCOL" },
	{ value: "Bocas del Toro, PA", label: "Bocas del Toro, PA", code: "BTO" },
	{ value: "Boquete, PA", label: "Boquete, PA", code: "DAW" },
	{ value: "Chiriquí, PA", label: "Chiriquí, PA", code: "dav3" },
	{ value: "Chitre, PA", label: "Chitre, PA", code: "CTD-3" },
	{ value: "Gamboa, PA", label: "Gamboa, PA", code: "PTY-3" },
	{ value: "San Blas, PA", label: "San Blas, PA", code: "501" },
	{ value: "Farallon, PA", label: "Farallon, PA", code: "AML1" },
	{ value: "Isla Colon, PA", label: "Isla Colon, PA", code: "COL122" },
	{
		value: "Aeropuerto de Tocumen, PA",
		label: "Aeropuerto de Tocumen, PA",
		code: "TOC123",
	},
	{ value: "Playa Bluff, PA", label: "Playa Bluff, PA", code: "BLUFF123" },
	{ value: "Paso Canoas, PA", label: "Paso Canoas, PA", code: "fake-604" },
	{
		value: "Cocle,Playa Blanca, PA",
		label: "Cocle,Playa Blanca, PA",
		code: "AMLPANAMA5",
	},
	{ value: "Río Hato, PA", label: "Río Hato, PA", code: "Hato" },
	{ value: "Guabito, PA", label: "Guabito, PA", code: "GUA123" },
	{
		value: "Papua Nueva Guinea, PG",
		label: "Papua Nueva Guinea, PG",
		code: "ID2",
	},
	{ value: "Asunción, PY", label: "Asunción, PY", code: "ASU" },
	{ value: "Ciudad del Este, PY", label: "Ciudad del Este, PY", code: "AGT-1" },
	{ value: "Encarnación, PY", label: "Encarnación, PY", code: "ENO" },
	{ value: "Concepción , PY", label: "Concepción , PY", code: "PJC1" },
	{ value: "Luque, PY", label: "Luque, PY", code: "LUQE-1" },
	{ value: "ica, PE", label: "ica, PE", code: "ICA" },
	{ value: "Lima, PE", label: "Lima, PE", code: "LIM" },
	{ value: "Jaén , PE", label: "Jaén , PE", code: "809" },
	{ value: "Cuzco/Cusco, PE", label: "Cuzco/Cusco, PE", code: "CUZ" },
	{ value: "Tarma, PE", label: "Tarma, PE", code: "TAR-1" },
	{ value: "Jauja, PE", label: "Jauja, PE", code: "923" },
	{ value: "Arequipa, PE", label: "Arequipa, PE", code: "AQP" },
	{ value: "Chiclayo, PE", label: "Chiclayo, PE", code: "CIX" },
	{ value: "Cajamarca, PE", label: "Cajamarca, PE", code: "CJA" },
	{ value: "Iquitos, PE", label: "Iquitos, PE", code: "IQT" },
	{ value: "Machu Picchu, PE", label: "Machu Picchu, PE", code: "MFT" },
	{ value: "Piura, PE", label: "Piura, PE", code: "PIU" },
	{ value: "Tumbes, PE", label: "Tumbes, PE", code: "TBP" },
	{ value: "Tacna, PE", label: "Tacna, PE", code: "TCQ" },
	{ value: "Tarapoto, PE", label: "Tarapoto, PE", code: "TPP" },
	{ value: "Trujillo, PE", label: "Trujillo, PE", code: "TRU" },
	{
		value: "Aguas Calientes, PE",
		label: "Aguas Calientes, PE",
		code: "AGC-13",
	},
	{ value: "Satipo, PE", label: "Satipo, PE", code: "SAT-12" },
	{
		value: "Playas del Norte, PE",
		label: "Playas del Norte, PE",
		code: "fake-558",
	},
	{ value: "Selva Central, PE", label: "Selva Central, PE", code: "fake-559" },
	{ value: "Mancora, PE", label: "Mancora, PE", code: "MC4" },
	{ value: "Nasca, PE", label: "Nasca, PE", code: "NAC" },
	{ value: "Paracas, PE", label: "Paracas, PE", code: "PCS" },
	{ value: "Puno, PE", label: "Puno, PE", code: "PUN" },
	{ value: "Urubamba, PE", label: "Urubamba, PE", code: "AYP-1" },
	{ value: "Pucallpa, PE", label: "Pucallpa, PE", code: "LLP-1" },
	{
		value: "Puerto Maldonado, PE",
		label: "Puerto Maldonado, PE",
		code: "fake-100",
	},
	{ value: "Chanchamayo, PE", label: "Chanchamayo, PE", code: "fake-546" },
	{ value: "Oxapampa, PE", label: "Oxapampa, PE", code: "fake-547" },
	{ value: "Apurimac, PE", label: "Apurimac, PE", code: "fake-548" },
	{ value: "Ayacucho, PE", label: "Ayacucho, PE", code: "fake-549" },
	{ value: "Huanuco, PE", label: "Huanuco, PE", code: "fake-553" },
	{ value: "Manu, PE", label: "Manu, PE", code: "fake-555" },
	{ value: "Pasco, PE", label: "Pasco, PE", code: "fake-557" },
	{ value: "Tambopata, PE", label: "Tambopata, PE", code: "fake-560" },
	{ value: " Colca, PE", label: " Colca, PE", code: "402" },
	{ value: "Pisac, PE", label: "Pisac, PE", code: "AYP" },
	{ value: "Poroy, PE", label: "Poroy, PE", code: "CUZ-3" },
	{ value: "Juliaca, PE", label: "Juliaca, PE", code: "JUL-1" },
	{ value: "Lago Titicaca, PE", label: "Lago Titicaca, PE", code: "LTI-1" },
	{ value: "Nazca, PE", label: "Nazca, PE", code: "NZA-1" },
	{ value: "Tarapoto, PE", label: "Tarapoto, PE", code: "TPP-1" },
	{ value: "Valle Sagrado, PE", label: "Valle Sagrado, PE", code: "VSA-2" },
	{ value: "Chivay, PE", label: "Chivay, PE", code: "CHV-87" },
	{ value: "Pisco, PE", label: "Pisco, PE", code: "PIS-33" },
	{ value: "Churin, PE", label: "Churin, PE", code: "fake-550" },
	{ value: "Huancavelica, PE", label: "Huancavelica, PE", code: "fake-551" },
	{ value: "Huancayo, PE", label: "Huancayo, PE", code: "fake-552" },
	{ value: "Huaraz, PE", label: "Huaraz, PE", code: "fake-554" },
	{ value: "Moquegua, PE", label: "Moquegua, PE", code: "fake-556" },
	{ value: "Chachapoyas, PE", label: "Chachapoyas, PE", code: "fake-561" },
	{ value: "Tortugas, PE", label: "Tortugas, PE", code: "fake-654" },
	{ value: "Puerto Eten, PE", label: "Puerto Eten, PE", code: "fake-655" },
	{ value: "Guadalupe, PE", label: "Guadalupe, PE", code: "fake-686" },
	{ value: "Tingo María, PE", label: "Tingo María, PE", code: "fake-767" },
	{ value: "Ollantaytambo, PE", label: "Ollantaytambo, PE", code: "CUZ2" },
	{ value: "Los Órganos, PE", label: "Los Órganos, PE", code: "LOO-44" },
	{ value: "Punta Sal, PE", label: "Punta Sal, PE", code: "PUN-33" },
	{ value: "Vichayito, PE", label: "Vichayito, PE", code: "VIC-21" },
	{ value: "Zorritos, PE", label: "Zorritos, PE", code: "ZOR-22" },
	{ value: "Sauce, PE", label: "Sauce, PE", code: "fake-685" },
	{ value: "Concepción, PE", label: "Concepción, PE", code: "fake-746" },
	{ value: "Ica, PE", label: "Ica, PE", code: "fake-750" },
	{ value: "La Victoria, PE", label: "La Victoria, PE", code: "fake-751" },
	{ value: "La Merced, PE", label: "La Merced, PE", code: "fake-766" },
	{ value: "Cieneguilla, PE", label: "Cieneguilla, PE", code: "fake-809" },
	{ value: "Manila, PH", label: "Manila, PH", code: "MNL" },
	{ value: "Tacloban, PH", label: "Tacloban, PH", code: "TAC-1" },
	{ value: "Naga, PH", label: "Naga, PH", code: "fake-51" },
	{ value: "Laogag, PH", label: "Laogag, PH", code: "PHB-2" },
	{ value: "Cebu, PH", label: "Cebu, PH", code: "CEB" },
	{ value: "Cagayan Valley, PH", label: "Cagayan Valley, PH", code: "CGY" },
	{ value: "Davao, PH", label: "Davao, PH", code: "DVO" },
	{ value: "Palawan, PH", label: "Palawan, PH", code: "PH1" },
	{ value: "Bohol, PH", label: "Bohol, PH", code: "PH4" },
	{ value: "Lapu - Lapu, PH", label: "Lapu - Lapu, PH", code: "CEB-2" },
	{ value: "Puerto Princesa, PH", label: "Puerto Princesa, PH", code: "PH1-1" },
	{ value: "Coron, PH", label: "Coron, PH", code: "PH1-3" },
	{ value: "Panglao, PH", label: "Panglao, PH", code: "PH4-1" },
	{ value: "Tagbilaran, PH", label: "Tagbilaran, PH", code: "PH4-2" },
	{ value: "Masbate, PH", label: "Masbate, PH", code: "fake-50" },
	{
		value: "San José de Labangán, PH",
		label: "San José de Labangán, PH",
		code: "fake-52",
	},
	{ value: "Siargao, PH", label: "Siargao, PH", code: "fake-53" },
	{ value: "Surigao, PH", label: "Surigao, PH", code: "fake-54" },
	{ value: "Tugdan, PH", label: "Tugdan, PH", code: "fake-55" },
	{ value: "Tandag, PH", label: "Tandag, PH", code: "fake-56" },
	{ value: "Tawi-Tawi, PH", label: "Tawi-Tawi, PH", code: "fake-57" },
	{ value: "Tuguegarao, PH", label: "Tuguegarao, PH", code: "fake-58" },
	{ value: "Caticlan, PH", label: "Caticlan, PH", code: "fake-63" },
	{ value: "Batangas, PH", label: "Batangas, PH", code: "BTG" },
	{ value: "Cotabato, PH", label: "Cotabato, PH", code: "CBO" },
	{ value: "General Santo, PH", label: "General Santo, PH", code: "GES" },
	{ value: "Iloílo, PH", label: "Iloílo, PH", code: "ILO" },
	{ value: "Kalibo, PH", label: "Kalibo, PH", code: "KLO" },
	{ value: "Legazpi, PH", label: "Legazpi, PH", code: "LGP" },
	{ value: "Ozamiz, PH", label: "Ozamiz, PH", code: "OZC" },
	{ value: "Pagadían, PH", label: "Pagadían, PH", code: "PAG" },
	{ value: "Bacólod, PH", label: "Bacólod, PH", code: "BCA-1" },
	{
		value: "Negros Occidental, PH",
		label: "Negros Occidental, PH",
		code: "BCA-3",
	},
	{ value: "Baguio, PH", label: "Baguio, PH", code: "CAS-2" },
	{ value: "Bantayan, PH", label: "Bantayan, PH", code: "CEB-3" },
	{ value: "Dumaguete, PH", label: "Dumaguete, PH", code: "DGT-1" },
	{ value: "El Nido, PH", label: "El Nido, PH", code: "PH1-4" },
	{ value: "Clark, PH", label: "Clark, PH", code: "PPG-1" },
	{ value: "Butuan, PH", label: "Butuan, PH", code: "BCA-68" },
	{ value: "Cauayán, PH", label: "Cauayán, PH", code: "Cauayan" },
	{ value: "Tablas, PH", label: "Tablas, PH", code: "fake-59" },
	{ value: "Zamboanga, PH", label: "Zamboanga, PH", code: "fake-60" },
	{ value: "Basco, PH", label: "Basco, PH", code: "fake-61" },
	{ value: "Catarman, PH", label: "Catarman, PH", code: "fake-62" },
	{ value: "Boracay, PH", label: "Boracay, PH", code: "MPH" },
	{ value: "Puerto Galera, PH", label: "Puerto Galera, PH", code: "PG1" },
	{ value: "Vigan, PH", label: "Vigan, PH", code: "VGN" },
	{ value: "Camiguin, PH", label: "Camiguin, PH", code: "CGY1" },
	{
		value: "Intramuros (Manila), PH",
		label: "Intramuros (Manila), PH",
		code: "MNL1",
	},
	{ value: "Luzon, PH", label: "Luzon, PH", code: "MNL3" },
	{ value: "Busuanga, PH", label: "Busuanga, PH", code: "PH13" },
	{ value: "Cagayan de oro, PH", label: "Cagayan de oro, PH", code: "CGY-1" },
	{ value: "Quezon City, PH", label: "Quezon City, PH", code: "MNL-5" },
	{ value: "Calbayog, PH", label: "Calbayog, PH", code: "fak47" },
	{ value: "Isla Bantayan, PH", label: "Isla Bantayan, PH", code: "BTYN-6" },
	{ value: "Catarman, PH", label: "Catarman, PH", code: "fake48" },
	{
		value: "Isla Mala Pascua, PH",
		label: "Isla Mala Pascua, PH",
		code: "MALAP-6",
	},
	{ value: "Banaue, PH", label: "Banaue, PH", code: "BAN-6" },
	{ value: "Oslob, PH", label: "Oslob, PH", code: "OSL-6" },
	{ value: "Socorro, PH", label: "Socorro, PH", code: "SOS-6" },
	{ value: "Tagaytay, PH", label: "Tagaytay, PH", code: "TAG-6" },
	{ value: "Cordovan, PH", label: "Cordovan, PH", code: "CRDV-6" },
	{ value: "Siquijor, PH", label: "Siquijor, PH", code: "SQJR-6" },
	{ value: "Sabang, PH", label: "Sabang, PH", code: "SABNG-6" },
	{ value: "San Lorenzo, PH", label: "San Lorenzo, PH", code: "SLOLO-6" },
	{ value: "Varsovia, PL", label: "Varsovia, PL", code: "WAW" },
	{ value: "Gdansk, PL", label: "Gdansk, PL", code: "GDN" },
	{ value: "Cracovia, PL", label: "Cracovia, PL", code: "KRK" },
	{ value: "Lublin, PL", label: "Lublin, PL", code: "LUB" },
	{ value: "Poznan, PL", label: "Poznan, PL", code: "POZ" },
	{ value: "Rzeszów, PL", label: "Rzeszów, PL", code: "RZE" },
	{ value: "Szczecin, PL", label: "Szczecin, PL", code: "SZC" },
	{ value: "Breslavia, PL", label: "Breslavia, PL", code: "WRO" },
	{ value: "Zakopane, PL", label: "Zakopane, PL", code: "ZAK" },
	{ value: "Olsztyn, PL", label: "Olsztyn, PL", code: "MSR2" },
	{ value: "Leba, PL", label: "Leba, PL", code: "BAC-10" },
	{ value: "Bialystok, PL", label: "Bialystok, PL", code: "BAC-11" },
	{ value: "Białystok, PL", label: "Białystok, PL", code: "BSK" },
	{ value: "Częstochowa, PL", label: "Częstochowa, PL", code: "CZE" },
	{ value: "Katowice, PL", label: "Katowice, PL", code: "KAT" },
	{ value: "Lodz, PL", label: "Lodz, PL", code: "LCJ" },
	{ value: "Kołobrzeg, PL", label: "Kołobrzeg, PL", code: "BAC-3" },
	{ value: "Darłowo, PL", label: "Darłowo, PL", code: "DLW-2" },
	{ value: "Miedzyzdroje, PL", label: "Miedzyzdroje, PL", code: "BAC" },
	{ value: "Bielsko Biala, PL", label: "Bielsko Biala, PL", code: "BBA" },
	{ value: "Podlaska, PL", label: "Podlaska, PL", code: "BLP" },
	{ value: "Bydgoszcz, PL", label: "Bydgoszcz, PL", code: "BZG" },
	{ value: "Cieszyn, PL", label: "Cieszyn, PL", code: "CIZ" },
	{
		value: "Gorzow Wielkopolski, PL",
		label: "Gorzow Wielkopolski, PL",
		code: "GOR",
	},
	{ value: "Zielona Gora, PL", label: "Zielona Gora, PL", code: "IEG" },
	{ value: "Jelenia Gora, PL", label: "Jelenia Gora, PL", code: "JEL" },
	{ value: "Kazimierz Dolny, PL", label: "Kazimierz Dolny, PL", code: "KAZ" },
	{ value: "Kielce, PL", label: "Kielce, PL", code: "KLC" },
	{ value: "Krynica Zdroj, PL", label: "Krynica Zdroj, PL", code: "KRY" },
	{ value: "Opole, PL", label: "Opole, PL", code: "PLE" },
	{ value: "Rawa Mazowiecka, PL", label: "Rawa Mazowiecka, PL", code: "RMZ" },
	{ value: "Grudziądz, PL", label: "Grudziądz, PL", code: "RUG" },
	{ value: "Torun, PL", label: "Torun, PL", code: "TOR" },
	{ value: "Augustow, PL", label: "Augustow, PL", code: "TOW" },
	{ value: "Wałbrzych, PL", label: "Wałbrzych, PL", code: "WBZ" },
	{ value: "Inowroclaw, PL", label: "Inowroclaw, PL", code: "WR4" },
	{ value: "Zamosc, PL", label: "Zamosc, PL", code: "ZMS" },
	{ value: "Gliwice, PL", label: "Gliwice, PL", code: "KAT3" },
	{ value: "Legnica, PL", label: "Legnica, PL", code: "PL-8" },
	{ value: "Glogow, PL", label: "Glogow, PL", code: "PL-9" },
	{ value: "Swinoujscie, PL", label: "Swinoujscie, PL", code: "BAC-4" },
	{ value: "Jarosławiec, PL", label: "Jarosławiec, PL", code: "JRS-2" },
	{ value: "Jastrzębia Góra, PL", label: "Jastrzębia Góra, PL", code: "JSG-2" },
	{ value: "Konin, PL", label: "Konin, PL", code: "KNI-2" },
	{ value: "Krynica-Zdrój, PL", label: "Krynica-Zdrój, PL", code: "KRY-2" },
	{ value: "Karpacz, PL", label: "Karpacz, PL", code: "KRZ-2" },
	{ value: "Mikołajki, PL", label: "Mikołajki, PL", code: "MJK-2" },
	{ value: "Malbork, PL", label: "Malbork, PL", code: "MLK-2" },
	{ value: "Mielno, PL", label: "Mielno, PL", code: "MLN-2" },
	{ value: "Mragowo, PL", label: "Mragowo, PL", code: "MSR-6" },
	{ value: "Niechorze, PL", label: "Niechorze, PL", code: "NCH-2" },
	{ value: "Płock, PL", label: "Płock, PL", code: "PCK-2" },
	{ value: "Przemysl, PL", label: "Przemysl, PL", code: "PLO-1" },
	{ value: "Rowy, PL", label: "Rowy, PL", code: "RWY-2" },
	{ value: "Szczytno, PL", label: "Szczytno, PL", code: "SCZ-2" },
	{ value: "Siedlce, PL", label: "Siedlce, PL", code: "SLC-2" },
	{ value: "Sopot, PL", label: "Sopot, PL", code: "SPT-2" },
	{
		value: "Szklarska Poreba, PL",
		label: "Szklarska Poreba, PL",
		code: "STY-1",
	},
	{ value: "Świnoujście, PL", label: "Świnoujście, PL", code: "SWJ-2" },
	{ value: "Tarnów, PL", label: "Tarnów, PL", code: "TRW-2" },
	{ value: "Ustka, PL", label: "Ustka, PL", code: "UTK-2" },
	{ value: "Wroclaw, PL", label: "Wroclaw, PL", code: "WCL-2" },
	{ value: "Wisła, PL", label: "Wisła, PL", code: "WLA-2" },
	{ value: "Władysławowo, PL", label: "Władysławowo, PL", code: "WLY-2" },
	{ value: "Rzeszów, PL", label: "Rzeszów, PL", code: "RZW-32" },
	{
		value: "Voivodato de Mazovia, PL",
		label: "Voivodato de Mazovia, PL",
		code: "NDM",
	},
	{ value: "Nowy Targ, PL", label: "Nowy Targ, PL", code: "NWO" },
	{ value: "Wieliczka, PL", label: "Wieliczka, PL", code: "WIEL" },
	{ value: "Gizycko, PL", label: "Gizycko, PL", code: "GIZ-6" },
	{ value: "Kalisz, PL", label: "Kalisz, PL", code: "KLZ-1" },
	{ value: "Rewal, PL", label: "Rewal, PL", code: "RWL-2" },
	{ value: "Świeradów-Zdrój, PL", label: "Świeradów-Zdrój, PL", code: "SWZ-2" },
	{ value: "Szczyrk, PL", label: "Szczyrk, PL", code: "SYK-2" },
	{ value: "Sroda Śląska , PL", label: "Sroda Śląska , PL", code: "Sroda" },
	{ value: "Gdynia, PL", label: "Gdynia, PL", code: "GDYN-1" },
	{ value: "Grójec, PL", label: "Grójec, PL", code: "GROC-1" },
	{ value: "Grójec, PL", label: "Grójec, PL", code: "GROJ-1" },
	{ value: "Nowy Sącz, PL", label: "Nowy Sącz, PL", code: "NWZ-002" },
	{ value: "Tychy, PL", label: "Tychy, PL", code: "TYC-002" },
	{ value: "Radom, PL", label: "Radom, PL", code: "RANDOM-6" },
	{ value: "Auschwitz, PL", label: "Auschwitz, PL", code: "fake-798" },
	{ value: "Lisboa, PT", label: "Lisboa, PT", code: "LIS" },
	{ value: "Açores, PT", label: "Açores, PT", code: "PDL" },
	{
		value: "Ilha Porto Santo , PT",
		label: "Ilha Porto Santo , PT",
		code: "FNC-6",
	},
	{
		value: "Oliveira do Hospital, PT",
		label: "Oliveira do Hospital, PT",
		code: "CEN-31",
	},
	{ value: "Oporto, PT", label: "Oporto, PT", code: "OPO-99" },
	{ value: "Aveiro, PT", label: "Aveiro, PT", code: "CEN" },
	{ value: "Algarve, PT", label: "Algarve, PT", code: "FAO" },
	{ value: "Anadia, PT", label: "Anadia, PT", code: "CEN48" },
	{ value: "Madeira, PT", label: "Madeira, PT", code: "FNC-1" },
	{ value: "Coimbra, PT", label: "Coimbra, PT", code: "CEN-30" },
	{ value: "Fátima, PT", label: "Fátima, PT", code: "CEN-35" },
	{ value: "Faro, PT", label: "Faro, PT", code: "FAO-72" },
	{ value: "Bragança, PT", label: "Bragança, PT", code: "NOR-16" },
	{ value: "Isla Flores, PT", label: "Isla Flores, PT", code: "PDL-15" },
	{ value: "Évora, PT", label: "Évora, PT", code: "SUL-13" },
	{ value: "Estoril, PT", label: "Estoril, PT", code: "COS" },
	{ value: "Peñafiel, PT", label: "Peñafiel, PT", code: "OPO3" },
	{ value: "Sesimbra, PT", label: "Sesimbra, PT", code: "CA1-4" },
	{ value: "Setubal, PT", label: "Setubal, PT", code: "CA1-5" },
	{
		value: "Caldas da Rainha , PT",
		label: "Caldas da Rainha , PT",
		code: "CEN-2",
	},
	{ value: "Peniche, PT", label: "Peniche, PT", code: "CEN-5" },
	{ value: "Covilha, PT", label: "Covilha, PT", code: "CEN-7" },
	{ value: "Covilha, PT", label: "Covilha, PT", code: "CEN-8" },
	{ value: "Monte Real, PT", label: "Monte Real, PT", code: "CEN39" },
	{ value: "Oeiras, PT", label: "Oeiras, PT", code: "COS-1" },
	{ value: "Calheta, PT", label: "Calheta, PT", code: "FNC-3" },
	{ value: "Porto Moniz, PT", label: "Porto Moniz, PT", code: "FNC-5" },
	{ value: "Amadora, PT", label: "Amadora, PT", code: "LIS14" },
	{ value: "Azores, PT", label: "Azores, PT", code: "PDL-0" },
	{ value: "Isla Terceira, PT", label: "Isla Terceira, PT", code: "PDL-2" },
	{ value: "Ilha Faial, PT", label: "Ilha Faial, PT", code: "PDL-3" },
	{ value: "Ilha Do Pico, PT", label: "Ilha Do Pico, PT", code: "PDL-4" },
	{ value: "Alentejo, PT", label: "Alentejo, PT", code: "SUL-1" },
	{ value: "Beja , PT", label: "Beja , PT", code: "SUL-2" },
	{ value: "Almada, PT", label: "Almada, PT", code: "CA1-12" },
	{ value: "Batalha, PT", label: "Batalha, PT", code: "CEN-14" },
	{ value: "Luso, PT", label: "Luso, PT", code: "CEN-15" },
	{ value: "Leiria , PT", label: "Leiria , PT", code: "CEN-18" },
	{ value: "Viseu, PT", label: "Viseu, PT", code: "CEN-22" },
	{ value: "Obidos, PT", label: "Obidos, PT", code: "CEN-25" },
	{ value: "Ovar, PT", label: "Ovar, PT", code: "CEN-27" },
	{
		value: "Figueira da Foz, PT",
		label: "Figueira da Foz, PT",
		code: "CEN-38",
	},
	{ value: "Guarda, PT", label: "Guarda, PT", code: "CEN-55" },
	{ value: "Aveiro, PT", label: "Aveiro, PT", code: "CEN-60" },
	{ value: "Alcobaça, PT", label: "Alcobaça, PT", code: "CEN-61" },
	{ value: "Pombal, PT", label: "Pombal, PT", code: "CEN-62" },
	{ value: "Castelo Branco, PT", label: "Castelo Branco, PT", code: "CEN-70" },
	{ value: "Tomar, PT", label: "Tomar, PT", code: "CEN-80" },
	{ value: "Santarém, PT", label: "Santarém, PT", code: "CEN-87" },
	{ value: "Nazaré, PT", label: "Nazaré, PT", code: "CEN-95" },
	{ value: "Sintra, PT", label: "Sintra, PT", code: "COS-10" },
	{ value: "Cascais, PT", label: "Cascais, PT", code: "COS-30" },
	{ value: "Ericeira, PT", label: "Ericeira, PT", code: "COS-40" },
	{ value: "Sagres, PT", label: "Sagres, PT", code: "FAO-13" },
	{ value: "Altura, PT", label: "Altura, PT", code: "FAO-32" },
	{ value: "Tavira, PT", label: "Tavira, PT", code: "FAO-34" },
	{
		value: "Armacao de Pera, PT",
		label: "Armacao de Pera, PT",
		code: "FAO-42",
	},
	{ value: "Alvor, PT", label: "Alvor, PT", code: "FAO-46" },
	{ value: "Lagos, PT", label: "Lagos, PT", code: "FAO-60" },
	{ value: "Carvoeiro, PT", label: "Carvoeiro, PT", code: "FAO-70" },
	{ value: "Ferragudo, PT", label: "Ferragudo, PT", code: "FAO-77" },
	{ value: "Albufeira, PT", label: "Albufeira, PT", code: "FAO-80" },
	{ value: "Portimão, PT", label: "Portimão, PT", code: "FAO-85" },
	{ value: "Quarteira, PT", label: "Quarteira, PT", code: "FAO-90" },
	{ value: "Machico, PT", label: "Machico, PT", code: "FNC-10" },
	{ value: "Camacha, PT", label: "Camacha, PT", code: "FNC-11" },
	{
		value: "São Vicente Ferreira (Azores), PT",
		label: "São Vicente Ferreira (Azores), PT",
		code: "FNC-80",
	},
	{ value: "Funchal, PT", label: "Funchal, PT", code: "FNC-99" },
	{
		value: "Vila Franca de Xira, PT",
		label: "Vila Franca de Xira, PT",
		code: "LIS-13",
	},
	{ value: "Chaves, PT", label: "Chaves, PT", code: "OPO-28" },
	{ value: "Braga, PT", label: "Braga, PT", code: "OPO-31" },
	{ value: "Geres, PT", label: "Geres, PT", code: "OPO-41" },
	{ value: "Guimarães, PT", label: "Guimarães, PT", code: "OPO-42" },
	{ value: "Lamego, PT", label: "Lamego, PT", code: "OPO-52" },
	{ value: "Baiao, PT", label: "Baiao, PT", code: "OPO-60" },
	{ value: "Vila do porto, PT", label: "Vila do porto, PT", code: "PDL-14" },
	{ value: "Ilha graciosa, PT", label: "Ilha graciosa, PT", code: "PDL-16" },
	{ value: "São Jorge, PT", label: "São Jorge, PT", code: "PDL-17" },
	{ value: "Mértola, PT", label: "Mértola, PT", code: "SUL-14" },
	{ value: "Moura , PT", label: "Moura , PT", code: "SUL-18" },
	{ value: "Elvas, PT", label: "Elvas, PT", code: "SUL-31" },
	{
		value: "Castelo de Paiva, PT",
		label: "Castelo de Paiva, PT",
		code: "fake49",
	},
	{ value: "Monte, PT", label: "Monte, PT", code: "5454684132" },
	{
		value: "Setúbal , PT",
		label: "Setúbal , PT",
		code: "____NEW____1459761045473",
	},
	{ value: "Punta Delgada, PT", label: "Punta Delgada, PT", code: "205" },
	{ value: "Alcácer do Sal, PT", label: "Alcácer do Sal, PT", code: "CA16" },
	{
		value: "Vila Nova de Santo André, PT",
		label: "Vila Nova de Santo André, PT",
		code: "CA18",
	},
	{
		value: "Costa da Caparica, PT",
		label: "Costa da Caparica, PT",
		code: "CAI1",
	},
	{
		value: "Isla de Sao Miguel, PT",
		label: "Isla de Sao Miguel, PT",
		code: "PDL5",
	},
	{ value: "Vimioso, PT", label: "Vimioso, PT", code: "VO-P" },
	{ value: "Barcelos, PT", label: "Barcelos, PT", code: "BAS-3" },
	{ value: "Sines, PT", label: "Sines, PT", code: "CA1-7" },
	{ value: " Troia, PT", label: " Troia, PT", code: "CA1-9" },
	{
		value: "Celorico De Basto, PT",
		label: "Celorico De Basto, PT",
		code: "CDB-6",
	},
	{ value: "Unhais da Serra, PT", label: "Unhais da Serra, PT", code: "CEN16" },
	{ value: "Abrantes, PT", label: "Abrantes, PT", code: "CEN23" },
	{ value: "Ourém, PT", label: "Ourém, PT", code: "CEN32" },
	{ value: "Gouveia, PT", label: "Gouveia, PT", code: "CEN36" },
	{ value: "Entroncamento, PT", label: "Entroncamento, PT", code: "ENT-3" },
	{ value: "Estarreja, PT", label: "Estarreja, PT", code: "EST-2" },
	{ value: "Estremoz, PT", label: "Estremoz, PT", code: "ESZ-2" },
	{ value: "Monte Gordo, PT", label: "Monte Gordo, PT", code: "FAO30" },
	{ value: "Loulé, PT", label: "Loulé, PT", code: "FAO92" },
	{ value: "Grândola, PT", label: "Grândola, PT", code: "GDL-2" },
	{ value: "Golegã, PT", label: "Golegã, PT", code: "GLG-2" },
	{ value: "Gondomar, PT", label: "Gondomar, PT", code: "GND-2" },
	{ value: "Góis, PT", label: "Góis, PT", code: "GOS-3" },
	{ value: "Gavião, PT", label: "Gavião, PT", code: "GVI-2" },
	{ value: "Horta, PT", label: "Horta, PT", code: "HRT-2" },
	{ value: "Idanha-a-Nova, PT", label: "Idanha-a-Nova, PT", code: "IAN-3" },
	{ value: "Ílhavo, PT", label: "Ílhavo, PT", code: "ILH-3" },
	{ value: "Lagoa, Azores, PT", label: "Lagoa, Azores, PT", code: "LGA-3" },
	{ value: "Torres Vedras , PT", label: "Torres Vedras , PT", code: "LIS-7" },
	{ value: "Loures, PT", label: "Loures, PT", code: "LRS-2" },
	{ value: "Lousã, PT", label: "Lousã, PT", code: "LSA-2" },
	{ value: "Lourinhã, PT", label: "Lourinhã, PT", code: "LUR-3" },
	{ value: "Matosinhos, PT", label: "Matosinhos, PT", code: "OPO12" },
	{ value: "Moncao, PT", label: "Moncao, PT", code: "OPO39" },
	{ value: "Mirandela, PT", label: "Mirandela, PT", code: "OPO57" },
	{
		value: "Isla de Santa María, PT",
		label: "Isla de Santa María, PT",
		code: "PDL14",
	},
	{ value: "Montargil, PT", label: "Montargil, PT", code: "SUL12" },
	{ value: "Porto Covo, PT", label: "Porto Covo, PT", code: "SUL35" },
	{ value: "Portalegre, PT", label: "Portalegre, PT", code: "SUL71" },
	{ value: "Montemor-o-novo, PT", label: "Montemor-o-novo, PT", code: "SUL79" },
	{ value: "Vizela, PT", label: "Vizela, PT", code: "VZL-6" },
	{
		value: "Zambujeira do Mar, PT",
		label: "Zambujeira do Mar, PT",
		code: "CA1-13",
	},
	{
		value: "Castelo de Vide, PT",
		label: "Castelo de Vide, PT",
		code: "CDVI-6",
	},
	{ value: "Cadaval, PT", label: "Cadaval, PT", code: "CDVL-6" },
	{
		value: "Calheta, Azores, PT",
		label: "Calheta, Azores, PT",
		code: "CLHT-6",
	},
	{ value: "Caminha, PT", label: "Caminha, PT", code: "CMNH-6" },
	{ value: "Campo Maior, PT", label: "Campo Maior, PT", code: "CMPM-6" },
	{ value: "Coruche, PT", label: "Coruche, PT", code: "CRCH-6" },
	{ value: "Cartaxo, PT", label: "Cartaxo, PT", code: "CRTX-6" },
	{ value: "Vilamoura , PT", label: "Vilamoura , PT", code: "FAO-76" },
	{ value: "Quinta lo Lago, PT", label: "Quinta lo Lago, PT", code: "FAO-79" },
	{ value: "Vaie do Lobo, PT", label: "Vaie do Lobo, PT", code: "FAO-88" },
	{ value: "Santana , PT", label: "Santana , PT", code: "FNC-30" },
	{
		value: "Santa Cruz (Madeira), PT",
		label: "Santa Cruz (Madeira), PT",
		code: "FNC-81",
	},
	{
		value: "Vila Nova de Gaia, PT",
		label: "Vila Nova de Gaia, PT",
		code: "OPO-10",
	},
	{ value: "Vila Real, PT", label: "Vila Real, PT", code: "OPO-43" },
	{
		value: "Viana do Castelo , PT",
		label: "Viana do Castelo , PT",
		code: "OPO-45",
	},
	{ value: "Redondo, PT", label: "Redondo, PT", code: "RDND-6" },
	{ value: "Resende, PT", label: "Resende, PT", code: "RSND-6" },
	{
		value: "Vila Nova de Milfontes, PT",
		label: "Vila Nova de Milfontes, PT",
		code: "SUL-25",
	},
	{ value: "Valença, PT, PT", label: "Valença, PT, PT", code: "VALN-6" },
	{ value: "Valongo, PT", label: "Valongo, PT", code: "VLNG-6" },
	{
		value: "Vila Velha de Rodao, PT",
		label: "Vila Velha de Rodao, PT",
		code: "VVDR-6",
	},
	{ value: "Vouzela, PT", label: "Vouzela, PT", code: "VZLA-6" },
	{
		value: "Santiago do cacem, PT",
		label: "Santiago do cacem, PT",
		code: "fake74",
	},
	{
		value: "Condeixa-a-Nova, PT",
		label: "Condeixa-a-Nova, PT",
		code: "ANOVA-6",
	},
	{
		value: "Oliveira de Azemeis, PT",
		label: "Oliveira de Azemeis, PT",
		code: "AZEME-6",
	},
	{
		value: "Celorico da Beira, PT",
		label: "Celorico da Beira, PT",
		code: "BEIRA-6",
	},
	{ value: "Vila do Bispo, PT", label: "Vila do Bispo, PT", code: "BISPO-6" },
	{ value: "Ribeira Brava, PT", label: "Ribeira Brava, PT", code: "BRAVA-6" },
	{ value: "Chamusca, PT", label: "Chamusca, PT", code: "CHMSC-6" },
	{ value: "Constancia, PT", label: "Constancia, PT", code: "CNSTN-6" },
	{ value: "Crato, PT", label: "Crato, PT", code: "CRATO-6" },
	{
		value: "Cuba (Portugal), PT",
		label: "Cuba (Portugal), PT",
		code: "CUBAP-6",
	},
	{ value: "Castro Daire, PT", label: "Castro Daire, PT", code: "DAIRE-6" },
	{ value: "Vila de Rei, PT", label: "Vila de Rei, PT", code: "DEREI-6" },
	{
		value: "Carregal do Sal, PT",
		label: "Carregal do Sal, PT",
		code: "DOSAL-6",
	},
	{ value: "Rio Maior, PT", label: "Rio Maior, PT", code: "MAIOR-6" },
	{ value: "Castro Marim, PT", label: "Castro Marim, PT", code: "MARIM-6" },
	{ value: "Marvao, PT", label: "Marvao, PT", code: "MARVA-6" },
	{
		value: "Vieira do Minho, PT",
		label: "Vieira do Minho, PT",
		code: "MINHO-6",
	},
	{ value: "Oleiros, PT", label: "Oleiros, PT", code: "OLEIR-6" },
	{ value: "Ourique, PT", label: "Ourique, PT", code: "OURIQ-6" },
	{
		value: "Vila Nova de Praiva, PT",
		label: "Vila Nova de Praiva, PT",
		code: "PAIVA-6",
	},
	{ value: "Pinhão, PT", label: "Pinhão, PT", code: "PNHAO-6" },
	{
		value: "Vila Pouca de Aguiar, PT",
		label: "Vila Pouca de Aguiar, PT",
		code: "POUCA-6",
	},
	{
		value: "Reguengos de Monsaraz, PT",
		label: "Reguengos de Monsaraz, PT",
		code: "REGUE-6",
	},
	{ value: "Vagos, PT", label: "Vagos, PT", code: "VAGOS-6" },
	{ value: "Vidigueira, PT", label: "Vidigueira, PT", code: "VDGRA-6" },
	{ value: "Velas, PT", label: "Velas, PT", code: "VELAS-6" },
	{ value: "Castro Verde, PT", label: "Castro Verde, PT", code: "VERDE-6" },
	{ value: "Vila Flor, PT", label: "Vila Flor, PT", code: "VFLOR-6" },
	{
		value: "Viana do Alentejo, PT",
		label: "Viana do Alentejo, PT",
		code: "VIEJO-6",
	},
	{ value: "Valpaços, PT", label: "Valpaços, PT", code: "VLPAC-6" },
	{
		value: "Vila Nova de Foz Coa, PT",
		label: "Vila Nova de Foz Coa, PT",
		code: "VNDFC-6",
	},
	{
		value: "Vila Real de Santo António, PT",
		label: "Vila Real de Santo António, PT",
		code: "VRDSA-6",
	},
	{ value: "Almeirim, PT", label: "Almeirim, PT", code: "fake-82" },
	{ value: "Barreiro, PT", label: "Barreiro, PT", code: "fake-83" },
	{
		value: "Paços de Ferreira, PT",
		label: "Paços de Ferreira, PT",
		code: "fake-84",
	},
	{
		value: "Punta Delgada (Azores), PT",
		label: "Punta Delgada (Azores), PT",
		code: "fake-85",
	},
	{ value: "Ponte de Sor, PT", label: "Ponte de Sor, PT", code: "fake-86" },
	{ value: "Seixal, PT", label: "Seixal, PT", code: "fake-87" },
	{
		value: "Vila Nova de Famalicao, PT",
		label: "Vila Nova de Famalicao, PT",
		code: "fake-89",
	},
	{
		value: "Vale de Cambra, PT",
		label: "Vale de Cambra, PT",
		code: "CAMBRA-6",
	},
	{ value: "Cantanhede, PT", label: "Cantanhede, PT", code: "CNTNHD-6" },
	{
		value: "Ribeira de Pena, PT",
		label: "Ribeira de Pena, PT",
		code: "DEPENA-6",
	},
	{
		value: "Castanheira de Pera, PT",
		label: "Castanheira de Pera, PT",
		code: "DEPERA-6",
	},
	{
		value: "Oliveira de Frades, PT",
		label: "Oliveira de Frades, PT",
		code: "FRADES-6",
	},
	{
		value: "Ribeira Grande, PT",
		label: "Ribeira Grande, PT",
		code: "GRANDE-6",
	},
	{ value: "Vila Viciosa, PT", label: "Vila Viciosa, PT", code: "VICIOS-6" },
	{
		value: "Vila Nova da Barquinha, PT",
		label: "Vila Nova da Barquinha, PT",
		code: "VNDBAR-6",
	},
	{ value: "Vendas Novas, PT", label: "Vendas Novas, PT", code: "VNOVAS-6" },
	{ value: "Vila Verde, PT", label: "Vila Verde, PT", code: "VVERDE-6" },
	{ value: "Lousada, PT", label: "Lousada, PT", code: "fake-101" },
	{
		value: "Praia da Vieira, PT",
		label: "Praia da Vieira, PT",
		code: "fake-118",
	},
	{ value: "Olhao, PT", label: "Olhao, PT", code: "fake-345" },
	{ value: "Lagoa, PT", label: "Lagoa, PT", code: "fake-347" },
	{ value: "Esposende, PT", label: "Esposende, PT", code: "fake-349" },
	{
		value: "Póvoa de Varzim, PT",
		label: "Póvoa de Varzim, PT",
		code: "fake-350",
	},
	{ value: "Espinho, PT", label: "Espinho, PT", code: "fake-351" },
	{
		value: "Ponta do Pargo, PT",
		label: "Ponta do Pargo, PT",
		code: "fake-352",
	},
	{ value: "Ponta do Sol, PT", label: "Ponta do Sol, PT", code: "fake-353" },
	{ value: "Prazeres, PT", label: "Prazeres, PT", code: "fake-354" },
	{
		value: "Câmara de Lobos, PT",
		label: "Câmara de Lobos, PT",
		code: "fake-355",
	},
	{ value: "Torres Novas, PT", label: "Torres Novas, PT", code: "fake-384" },
	{ value: "Isla de Corvo, PT", label: "Isla de Corvo, PT", code: "fake-634" },
	{ value: "Alcochete, PT", label: "Alcochete, PT", code: "fake-643" },
	{ value: "Ílhavo, PT", label: "Ílhavo, PT", code: "fake-644" },
	{
		value: "Carrazeda de Ansiaes, PT",
		label: "Carrazeda de Ansiaes, PT",
		code: "ANSIAES-6",
	},
	{
		value: "Cabeceiras de Basto, PT",
		label: "Cabeceiras de Basto, PT",
		code: "DEBASTO-6",
	},
	{
		value: "Vila Nova de Poiares, PT",
		label: "Vila Nova de Poiares, PT",
		code: "POIARES-6",
	},
	{ value: "Fundão, PT", label: "Fundão, PT", code: "FND" },
	{
		value: "Freixo de Espada à Cinta, PT",
		label: "Freixo de Espada à Cinta, PT",
		code: "FRX",
	},
	{ value: "Caria, PT", label: "Caria, PT", code: "VRL" },
	{ value: "Avis, PT", label: "Avis, PT", code: "Avis" },
	{ value: "Fafe, PT", label: "Fafe, PT", code: "FAFE" },
	{ value: "São Teotónio, PT", label: "São Teotónio, PT", code: "FAO3" },
	{ value: "Albernoa, PT", label: "Albernoa, PT", code: "FAO4" },
	{
		value: "Ferreira do Zêzere, PT",
		label: "Ferreira do Zêzere, PT",
		code: "FFRZ",
	},
	{
		value: "Figueira de Castelo Rodrigo, PT",
		label: "Figueira de Castelo Rodrigo, PT",
		code: "FGRA",
	},
	{
		value: "Figueiró dos Vinhos, PT",
		label: "Figueiró dos Vinhos, PT",
		code: "FGRD",
	},
	{ value: "Felgueiras, PT", label: "Felgueiras, PT", code: "FLGR" },
	{
		value: "Fornos de Algodres, PT",
		label: "Fornos de Algodres, PT",
		code: "FRNS",
	},
	{ value: "Fronteira, PT", label: "Fronteira, PT", code: "FRNT" },
	{
		value: "Ferreira do Alentejo, PT",
		label: "Ferreira do Alentejo, PT",
		code: "FRRE",
	},
	{
		value: "Pedrogao Pequeno , PT",
		label: "Pedrogao Pequeno , PT",
		code: "LIS6",
	},
	{ value: "Pedrógão Grande, PT", label: "Pedrógão Grande, PT", code: "PDRG" },
	{ value: "Penacova, PT", label: "Penacova, PT", code: "PNCV" },
	{ value: "Penedono, PT", label: "Penedono, PT", code: "PNDN" },
	{ value: "Pinhel, PT", label: "Pinhel, PT", code: "PNHL" },
	{ value: "Penela, PT", label: "Penela, PT", code: "PNLA" },
	{ value: "Penamacor, PT", label: "Penamacor, PT", code: "PNMC" },
	{ value: "Ponte de Lima, PT", label: "Ponte de Lima, PT", code: "PNTL" },
	{ value: "Paredes , PT", label: "Paredes , PT", code: "PRDS" },
	{ value: "Peso da Régua, PT", label: "Peso da Régua, PT", code: "PSDR" },
	{ value: "Seia, PT", label: "Seia, PT", code: "Seia" },
	{ value: "Monsanto, PT", label: "Monsanto, PT", code: "VRL1" },
	{ value: "Monfortinho, PT", label: "Monfortinho, PT", code: "VRL3" },
	{ value: "Alijó, PT", label: "Alijó, PT", code: "Alijo" },
	{ value: "Benfica, PT", label: "Benfica, PT", code: "BNA-P" },
	{ value: "Chaves, PT", label: "Chaves, PT", code: "CHS-P" },
	{ value: "Funchal, PT", label: "Funchal, PT", code: "FUL-P" },
	{ value: "Amora, PT", label: "Amora, PT", code: "LIS11" },
	{ value: "Lajes, PT", label: "Lajes, PT", code: "Lajes" },
	{ value: "Macao, PT", label: "Macao, PT", code: "MCO-1" },
	{
		value: "Macedo de Cavaleiros, PT",
		label: "Macedo de Cavaleiros, PT",
		code: "MDC-1",
	},
	{
		value: "Moreira de Cónegos, PT",
		label: "Moreira de Cónegos, PT",
		code: "MDC-P",
	},
	{
		value: "Pampilhosa da Serra, PT",
		label: "Pampilhosa da Serra, PT",
		code: "PMPLS",
	},
	{ value: "Ponte da Barca, PT", label: "Ponte da Barca, PT", code: "PNTDB" },
	{
		value: "Penalva do Castelo, PT",
		label: "Penalva do Castelo, PT",
		code: "PNVLS",
	},
	{
		value: "Paredes de Coura, PT",
		label: "Paredes de Coura, PT",
		code: "PRDSC",
	},
	{ value: "Prior Velho, PT", label: "Prior Velho, PT", code: "PRI-2" },
	{ value: "Portimão, PT", label: "Portimão, PT", code: "PRO-P" },
	{ value: "Sátão, PT", label: "Sátão, PT", code: "Satao" },
	{ value: "Serpa, PT", label: "Serpa, PT", code: "Serpa" },
	{ value: "Sertã, PT", label: "Sertã, PT", code: "Serta" },
	{ value: "Soure , PT", label: "Soure , PT", code: "Soure" },
	{ value: "Tondela, PT", label: "Tondela, PT", code: "TDA-P" },
	{ value: "Vila das Aves, PT", label: "Vila das Aves, PT", code: "VDA-P" },
	{ value: "Vila do Conde, PT", label: "Vila do Conde, PT", code: "VDC-P" },
	{ value: "Vila Maior, PT", label: "Vila Maior, PT", code: "VMR-P" },
	{ value: "Vinhais, PT", label: "Vinhais, PT", code: "VNS-P" },
	{ value: "Furnas, PT", label: "Furnas, PT", code: "454748" },
	{ value: "Caloura, PT", label: "Caloura, PT", code: "458421" },
	{ value: "Alvito, PT", label: "Alvito, PT", code: "Alvito" },
	{ value: "Amares, PT", label: "Amares, PT", code: "Amares" },
	{ value: "Ansião, PT", label: "Ansião, PT", code: "Ansiao" },
	{ value: "Arouca, PT", label: "Arouca, PT", code: "Arouca" },
	{ value: "Caramulo, PT", label: "Caramulo, PT", code: "CARA-6" },
	{ value: "Cinfaes, PT", label: "Cinfaes, PT", code: "CNFS-6" },
	{ value: "Comporta, PT", label: "Comporta, PT", code: "COMP-1" },
	{ value: "Curia, PT", label: "Curia, PT", code: "CURI-6" },
	{ value: "Douro, PT", label: "Douro, PT", code: "DOUR-1" },
	{ value: "Gloria, PT", label: "Gloria, PT", code: "GLORIA" },
	{ value: "Guia, PT", label: "Guia, PT", code: "GUIA-6" },
	{ value: "Mafra, PT", label: "Mafra, PT", code: "MAFR-1" },
	{ value: "Maia, PT", label: "Maia, PT", code: "MAIA-1" },
	{ value: "Mangualde, PT", label: "Mangualde, PT", code: "MANG-1" },
	{ value: "Marinha Grande, PT", label: "Marinha Grande, PT", code: "MARG-1" },
	{
		value: "Moimenta da Beira, PT",
		label: "Moimenta da Beira, PT",
		code: "MDBE-1",
	},
	{
		value: "Marco de Canaveses, PT",
		label: "Marco de Canaveses, PT",
		code: "MDCA-1",
	},
	{
		value: "Miranda do Corvo, PT",
		label: "Miranda do Corvo, PT",
		code: "MDCO-1",
	},
	{ value: "Mealhada, PT", label: "Mealhada, PT", code: "MEAL-1" },
	{ value: "Meda, PT", label: "Meda, PT", code: "MEDA-1" },
	{ value: "Melgaco, PT", label: "Melgaco, PT", code: "MELG-1" },
	{ value: "Mesao Frio, PT", label: "Mesao Frio, PT", code: "MESF-1" },
	{
		value: "Miranda del Duero, PT",
		label: "Miranda del Duero, PT",
		code: "MIDD-1",
	},
	{ value: "Playa de Mira, PT", label: "Playa de Mira, PT", code: "MIR-99" },
	{ value: "Mira, PT", label: "Mira, PT", code: "MIRA-1" },
	{ value: "Manteigas, PT", label: "Manteigas, PT", code: "MNTG-1" },
	{ value: "Montijo, PT", label: "Montijo, PT", code: "MNTJ-1" },
	{
		value: "Mondim de Basto, PT",
		label: "Mondim de Basto, PT",
		code: "MODB-1",
	},
	{ value: "Mogadouro, PT", label: "Mogadouro, PT", code: "MOGA-1" },
	{ value: "Moita, PT", label: "Moita, PT", code: "MOIT-1" },
	{ value: "Monforte, PT", label: "Monforte, PT", code: "MONF-1" },
	{ value: "Montalegre, PT", label: "Montalegre, PT", code: "MONT-1" },
	{ value: "Mora, PT", label: "Mora, PT", code: "MORA-1" },
	{ value: "Moreira, PT", label: "Moreira, PT", code: "MORE-1" },
	{ value: "Mourão, PT", label: "Mourão, PT", code: "MOUR-1" },
	{
		value: "Montemor-o-Velho, PT",
		label: "Montemor-o-Velho, PT",
		code: "MOVE-1",
	},
	{ value: "Murça, PT", label: "Murça, PT", code: "MURC-1" },
	{ value: "Murtosa, PT", label: "Murtosa, PT", code: "MURT-1" },
	{ value: "Amarante, PT", label: "Amarante, PT", code: "OPO-25" },
	{
		value: "Povoa de Lanhoso, PT",
		label: "Povoa de Lanhoso, PT",
		code: "PODL-1",
	},
	{ value: "Porto de Mós, PT", label: "Porto de Mós, PT", code: "PODM-1" },
	{ value: "Portel, PT", label: "Portel, PT", code: "PORTEL" },
	{ value: "Povoação, PT", label: "Povoação, PT", code: "POVO-1" },
	{ value: "Playa de Mira, PT", label: "Playa de Mira, PT", code: "PRA-11" },
	{
		value: "Praia da Vitória, PT",
		label: "Praia da Vitória, PT",
		code: "PRAI-1",
	},
	{ value: "Proenca-a-Nova, PT", label: "Proenca-a-Nova, PT", code: "PROE-1" },
	{ value: "Silves, PT", label: "Silves, PT", code: "Silves" },
	{ value: "Sousel, PT", label: "Sousel, PT", code: "Sousel" },
	{
		value: "Águeda (Portugal), PT",
		label: "Águeda (Portugal), PT",
		code: "agueda",
	},
	{ value: "Aljezur, PT", label: "Aljezur, PT", code: "Aljezur" },
	{ value: "Almeida , PT", label: "Almeida , PT", code: "Almeida" },
	{ value: "Arganil, PT", label: "Arganil, PT", code: "Arganil" },
	{ value: "Armamar, PT", label: "Armamar, PT", code: "Armamar" },
	{ value: "Borba, PT", label: "Borba, PT", code: "BBA-002" },
	{ value: "Bombarral, PT", label: "Bombarral, PT", code: "BBL-002" },
	{ value: "Belmonte, PT", label: "Belmonte, PT", code: "BLM-002" },
	{ value: "Boticas, PT", label: "Boticas, PT", code: "BTC-002" },
	{ value: "Benavente, PT", label: "Benavente, PT", code: "BVT-002" },
	{
		value: "Sierra de la Estrella, PT",
		label: "Sierra de la Estrella, PT",
		code: "ESA-002",
	},
	{ value: "Monchique, PT", label: "Monchique, PT", code: "MONCH-1" },
	{ value: "Mortágua, PT", label: "Mortágua, PT", code: "MORTA-1" },
	{ value: "Nelas, PT", label: "Nelas, PT", code: "NLS-002" },
	{ value: "Nisa, PT", label: "Nisa, PT", code: "NSA-002" },
	{ value: "Relva, PT", label: "Relva, PT", code: "RELVA-6" },
	{ value: "Ramalde, PT", label: "Ramalde, PT", code: "Ramalde" },
	{
		value: "Santa Maria de Belém, PT",
		label: "Santa Maria de Belém, PT",
		code: "STMDB-P",
	},
	{ value: "Sabrosa, PT", label: "Sabrosa, PT", code: "Sabrosa" },
	{ value: "Sabugal, PT", label: "Sabugal, PT", code: "Sabugal" },
	{ value: "Sardoal, PT", label: "Sardoal, PT", code: "Sardoal" },
	{ value: "Tábua, PT", label: "Tábua, PT", code: "TBA-002" },
	{ value: "Tabuaço, PT", label: "Tabuaço, PT", code: "TBO-002" },
	{
		value: "Terras de Bouro, PT",
		label: "Terras de Bouro, PT",
		code: "TBU-002",
	},
	{ value: "Tarouca, PT", label: "Tarouca, PT", code: "TCA-002" },
	{ value: "Trofa, PT", label: "Trofa, PT", code: "TFA-002" },
	{
		value: "Torre de Moncorvo, PT",
		label: "Torre de Moncorvo, PT",
		code: "TMV-002",
	},
	{ value: "Trancoso, PT", label: "Trancoso, PT", code: "TSO-002" },
	{
		value: "São Vicente (Madeira), PT",
		label: "São Vicente (Madeira), PT",
		code: "45448121",
	},
	{ value: "Alcanena, PT", label: "Alcanena, PT", code: "Alcanena" },
	{ value: "Alcoutim, PT", label: "Alcoutim, PT", code: "Alcoutim" },
	{ value: "Alenquer, PT", label: "Alenquer, PT", code: "Alenquer" },
	{ value: "Alpiarça, PT", label: "Alpiarça, PT", code: "Alpiarca" },
	{ value: "Azambuja , PT", label: "Azambuja , PT", code: "Azambuja" },
	{ value: "Odemira, PT", label: "Odemira, PT", code: "DEMIRA-6" },
	{
		value: "Nordeste (Azores), PT",
		label: "Nordeste (Azores), PT",
		code: "Nordeste",
	},
	{
		value: "Santa Maria da Feira, PT",
		label: "Santa Maria da Feira, PT",
		code: "SNTMDF-P",
	},
	{
		value: "Madalena (Azores), PT",
		label: "Madalena (Azores), PT",
		code: "madalena",
	},
	{ value: "Sao Roque, PT", label: "Sao Roque, PT", code: "saoroque" },
	{
		value: "Vila Franca do Campo, PT",
		label: "Vila Franca do Campo, PT",
		code: "548454648",
	},
	{ value: "Caniçal, PT", label: "Caniçal, PT", code: "548742514" },
	{ value: "Alandroal, PT", label: "Alandroal, PT", code: "Alandroal" },
	{ value: "Aljustrel, PT", label: "Aljustrel, PT", code: "Aljustrel" },
	{ value: "Almodôvar , PT", label: "Almodôvar , PT", code: "Almodovar" },
	{ value: "Arraiolos, PT", label: "Arraiolos, PT", code: "Arraiolos" },
	{ value: "Arronches, PT", label: "Arronches, PT", code: "Arronches" },
	{
		value: "Curral das Freiras, PT",
		label: "Curral das Freiras, PT",
		code: "4578415488",
	},
	{
		value: "São Jorge (Madeira), PT",
		label: "São Jorge (Madeira), PT",
		code: "4987418745",
	},
	{ value: "Alvaiázere, PT", label: "Alvaiázere, PT", code: "Alvaiazere" },
	{
		value: "Santa Cruz da Graciosa, PT",
		label: "Santa Cruz da Graciosa, PT",
		code: "SCGraciosa",
	},
	{ value: "Santo Tirso, PT", label: "Santo Tirso, PT", code: "SantoTirso" },
	{
		value: "Porto da Cruz, PT",
		label: "Porto da Cruz, PT",
		code: "54878432516",
	},
	{
		value: "Alter do Chão, PT",
		label: "Alter do Chão, PT",
		code: "AlterdoChao",
	},
	{
		value: "Paço de Arcos, PT",
		label: "Paço de Arcos, PT",
		code: "Pacodearcos",
	},
	{ value: "Sernancelhe, PT", label: "Sernancelhe, PT", code: "Sernancelhe" },
	{
		value: "Ponta Delgada (Madeira), PT",
		label: "Ponta Delgada (Madeira), PT",
		code: "484811255415",
	},
	{
		value: "Sever do Vouga, PT",
		label: "Sever do Vouga, PT",
		code: "SeverdoVouga",
	},
	{
		value: "Aguiar da Beira, PT",
		label: "Aguiar da Beira, PT",
		code: "AguiardaBeira",
	},
	{
		value: "Alfandega da Fe , PT",
		label: "Alfandega da Fe , PT",
		code: "Alfandegadafe",
	},
	{
		value: "Santa Comba Dão, PT",
		label: "Santa Comba Dão, PT",
		code: "SantaCombaDao",
	},
	{
		value: "São Pedro do Sul , PT",
		label: "São Pedro do Sul , PT",
		code: "SaoPedrodoSul",
	},
	{
		value: "São Roque do Pico, PT",
		label: "São Roque do Pico, PT",
		code: "SaoRoquedoPico",
	},
	{
		value: "Angra do Heroísmo, PT",
		label: "Angra do Heroísmo, PT",
		code: "AngradoHeroismo",
	},
	{
		value: "Arruda dos Vinhos, PT",
		label: "Arruda dos Vinhos, PT",
		code: "ArrudadosVinhos",
	},
	{
		value: "São João da Madeira, PT",
		label: "São João da Madeira, PT",
		code: "SaooaodaMadeira",
	},
	{
		value: "Albergaria-a-Velha, PT",
		label: "Albergaria-a-Velha, PT",
		code: "AlbergariaaVelha",
	},
	{
		value: "Arcos de Valdevez, PT",
		label: "Arcos de Valdevez, PT",
		code: "ArcossdeValdevez",
	},
	{
		value: "Salvaterra de Magos, PT",
		label: "Salvaterra de Magos, PT",
		code: "SalvaterradeMagos",
	},
	{
		value: "São Brás de Alportel, PT",
		label: "São Brás de Alportel, PT",
		code: "SaoBrasdeAlportel",
	},
	{
		value: "Santa Cruz das Flores, PT",
		label: "Santa Cruz das Flores, PT",
		code: "SantaCruzdasFlores",
	},
	{
		value: "São João da Pesqueira, PT",
		label: "São João da Pesqueira, PT",
		code: "SaoJoaodaPesqueira",
	},
	{
		value: "Sobral de Monte Agraço, PT",
		label: "Sobral de Monte Agraço, PT",
		code: "SobraldeMonteAgraco",
	},
	{
		value: "Santa Marta de Penaguião, PT",
		label: "Santa Marta de Penaguião, PT",
		code: "SantaMartadePenaguiao",
	},
	{ value: "San Juan, PR", label: "San Juan, PR", code: "SJU-17" },
	{ value: "Ponce, PR", label: "Ponce, PR", code: "SJU" },
	{ value: "Humacao, PR", label: "Humacao, PR", code: "SJU-6" },
	{ value: "Rio grande, PR", label: "Rio grande, PR", code: "SJU-7" },
	{ value: "Mayagüez, PR", label: "Mayagüez, PR", code: "SJU11" },
	{ value: "Aguadilla, PR", label: "Aguadilla, PR", code: "AGU-32" },
	{ value: "Isla Vieques, PR", label: "Isla Vieques, PR", code: "58451215" },
	{ value: "Isla Culebra, PR", label: "Isla Culebra, PR", code: "854561158" },
	{ value: "Fajardo, PR", label: "Fajardo, PR", code: "454884" },
	{ value: "Doha, QA", label: "Doha, QA", code: "DOH" },
	{ value: "Al Khor, QA", label: "Al Khor, QA", code: "JOR-6" },
	{ value: "Doha, QA", label: "Doha, QA", code: "AdDawhah" },
	{ value: "Reunion, RE", label: "Reunion, RE", code: "STD" },
	{ value: "Bucarest, RO", label: "Bucarest, RO", code: "BUH-2" },
	{ value: "Piatra Neamt, RO", label: "Piatra Neamt, RO", code: "ATR" },
	{ value: "Brasov, RO", label: "Brasov, RO", code: "BRA" },
	{ value: "Constanta, RO", label: "Constanta, RO", code: "CND" },
	{ value: "Deva, RO", label: "Deva, RO", code: "DVA" },
	{ value: "Sighișoara, RO", label: "Sighișoara, RO", code: "GHI" },
	{ value: "Oradea, RO", label: "Oradea, RO", code: "SIA" },
	{ value: "Satu Mare, RO", label: "Satu Mare, RO", code: "SUJ" },
	{ value: "Tulcea, RO", label: "Tulcea, RO", code: "TCE" },
	{ value: "Timisoara, RO", label: "Timisoara, RO", code: "TSR" },
	{ value: "Arad, RO", label: "Arad, RO", code: "ARD" },
	{ value: "Băile Felix, RO", label: "Băile Felix, RO", code: "BAF" },
	{ value: "Cluj Napoca, RO", label: "Cluj Napoca, RO", code: "CLU" },
	{ value: "Suceava, RO", label: "Suceava, RO", code: "SUC" },
	{ value: "Târgu-Mureş, RO", label: "Târgu-Mureş, RO", code: "TAR" },
	{ value: "Poiana Brasov, RO", label: "Poiana Brasov, RO", code: "BRA-2" },
	{ value: "Neptun, RO", label: "Neptun, RO", code: "CND-3" },
	{ value: "Eforie, RO", label: "Eforie, RO", code: "CND-5" },
	{ value: "Buzau, RO", label: "Buzau, RO", code: "202" },
	{ value: "Braila, RO", label: "Braila, RO", code: "AIL" },
	{ value: "Baia Mare, RO", label: "Baia Mare, RO", code: "BAM" },
	{ value: "Iasi, RO", label: "Iasi, RO", code: "IAS" },
	{ value: "Craiova, RO", label: "Craiova, RO", code: "IOV" },
	{ value: "Botosani, RO", label: "Botosani, RO", code: "OBT" },
	{ value: "Ploiesti, RO", label: "Ploiesti, RO", code: "PLO" },
	{ value: "Sibiu, RO", label: "Sibiu, RO", code: "SBZ" },
	{ value: "Pitesti, RO", label: "Pitesti, RO", code: "TTI" },
	{ value: "Mangalia, RO", label: "Mangalia, RO", code: "MGN-2" },
	{ value: "Bacau, RO", label: "Bacau, RO", code: "fake-799" },
	{ value: "Sinaia, RO", label: "Sinaia, RO", code: "fake-836" },
	{ value: "Targu Neamt, RO", label: "Targu Neamt, RO", code: "fake-841" },
	{ value: "Galați , RO", label: "Galați , RO", code: "960" },
	{ value: "Bazna, RO", label: "Bazna, RO", code: "BAZ-1" },
	{ value: "Gheorgheni, RO", label: "Gheorgheni, RO", code: "GHE-6" },
	{ value: "Bistrita, RO", label: "Bistrita, RO", code: "BIST-6" },
	{ value: "Miercurea Ciuc, RO", label: "Miercurea Ciuc, RO", code: "CIUC-6" },
	{ value: "Biertan, RO", label: "Biertan, RO", code: "BTN-002" },
	{ value: "Campulung, RO", label: "Campulung, RO", code: "CAMPU-1" },
	{ value: "Harman, RO", label: "Harman, RO", code: "HRM-002" },
	{ value: "Predeal, RO", label: "Predeal, RO", code: "PDL-002" },
	{ value: "Sighisoara, RO", label: "Sighisoara, RO", code: "SGR-002" },
	{ value: "Bran, RO", label: "Bran, RO", code: "fake-805" },
	{
		value: "Gura Humorului, RO",
		label: "Gura Humorului, RO",
		code: "fake-817",
	},
	{ value: "Moscú, RU", label: "Moscú, RU", code: "MOW" },
	{ value: "Krasnoyarsk, RU", label: "Krasnoyarsk, RU", code: "kra3" },
	{ value: "Irkutsk, RU", label: "Irkutsk, RU", code: "IKT" },
	{ value: "Omsk, RU", label: "Omsk, RU", code: "OMS" },
	{ value: "San Petersburgo, RU", label: "San Petersburgo, RU", code: "LED" },
	{ value: "Sochi, RU", label: "Sochi, RU", code: "AER" },
	{ value: "Smolenskaya, RU", label: "Smolenskaya, RU", code: "RU-1" },
	{ value: "Abakán, RU", label: "Abakán, RU", code: "ABK-1" },
	{ value: "Staraya, RU", label: "Staraya, RU", code: "AER-1" },
	{ value: "Ivanovo, RU", label: "Ivanovo, RU", code: "IVN-1" },
	{ value: "Vladimir, RU", label: "Vladimir, RU", code: "VLI-1" },
	{ value: "Novgorod, RU", label: "Novgorod, RU", code: "GOJ" },
	{ value: "Chita, RU", label: "Chita, RU", code: "HTA" },
	{ value: "Krasnodar, RU", label: "Krasnodar, RU", code: "KDR" },
	{ value: "Kaliningrado, RU", label: "Kaliningrado, RU", code: "KGD" },
	{ value: "Khabarovsk, RU", label: "Khabarovsk, RU", code: "KHV" },
	{ value: "Samara, RU", label: "Samara, RU", code: "KUF" },
	{ value: "Makhachkala, RU", label: "Makhachkala, RU", code: "MCX" },
	{ value: "Novy Urengoy, RU", label: "Novy Urengoy, RU", code: "NYY" },
	{ value: "Novosibirsk, RU", label: "Novosibirsk, RU", code: "OVB" },
	{ value: "Perm, RU", label: "Perm, RU", code: "PE1" },
	{ value: "Kazan, RU", label: "Kazan, RU", code: "RU1" },
	{ value: "Ekaterinburgo, RU", label: "Ekaterinburgo, RU", code: "RU2" },
	{ value: "Orenburg, RU", label: "Orenburg, RU", code: "RU4" },
	{ value: "Surgut, RU", label: "Surgut, RU", code: "SGC" },
	{ value: "Tambov, RU", label: "Tambov, RU", code: "TBW" },
	{ value: "Tyumen, RU", label: "Tyumen, RU", code: "TJM" },
	{ value: "Ufa, RU", label: "Ufa, RU", code: "UFA" },
	{ value: "Volgodonsk, RU", label: "Volgodonsk, RU", code: "VLK" },
	{ value: "Volgograd, RU", label: "Volgograd, RU", code: "VOG" },
	{ value: "Voronezh, RU", label: "Voronezh, RU", code: "VOZ" },
	{ value: "Astrakhan, RU", label: "Astrakhan, RU", code: "ASF3" },
	{ value: "Barnaul, RU", label: "Barnaul, RU", code: "BAX3" },
	{ value: "Belgorod, RU", label: "Belgorod, RU", code: "EGO3" },
	{ value: "Nizhni Nóvgorod, RU", label: "Nizhni Nóvgorod, RU", code: "GOJ3" },
	{ value: "Yaroslavl, RU", label: "Yaroslavl, RU", code: "IAR3" },
	{ value: "Kaluga, RU", label: "Kaluga, RU", code: "KLF3" },
	{ value: "Kirov, RU", label: "Kirov, RU", code: "KVX3" },
	{ value: "Murmansk, RU", label: "Murmansk, RU", code: "MMK3" },
	{ value: "Penza, RU", label: "Penza, RU", code: "PEZ3" },
	{ value: "Saratov, RU", label: "Saratov, RU", code: "RTW3" },
	{ value: "Ryazan, RU", label: "Ryazan, RU", code: "RZN3" },
	{ value: "Tula, RU", label: "Tula, RU", code: "TTY3" },
	{ value: "Kursk, RU", label: "Kursk, RU", code: "URS3" },
	{ value: "Tomsk, RU", label: "Tomsk, RU", code: "tof3" },
	{ value: "Yakutsk, RU", label: "Yakutsk, RU", code: "yks3" },
	{ value: "Chelyabinsk, RU", label: "Chelyabinsk, RU", code: "CEK10" },
	{ value: "Cheboksary, RU", label: "Cheboksary, RU", code: "CSY" },
	{ value: "Izhevsk, RU", label: "Izhevsk, RU", code: "IJK" },
	{ value: "Kostroma, RU", label: "Kostroma, RU", code: "KMW" },
	{
		value: "Naberezhnye Chelny, RU",
		label: "Naberezhnye Chelny, RU",
		code: "NB2",
	},
	{ value: "Saransk, RU", label: "Saransk, RU", code: "SKX" },
	{ value: "Taganrog, RU", label: "Taganrog, RU", code: "TOG" },
	{ value: "Tolyatti, RU", label: "Tolyatti, RU", code: "TYA" },
	{ value: "Ulyanovsk, RU", label: "Ulyanovsk, RU", code: "ULY" },
	{
		value: "Velikiy  Novgorod, RU",
		label: "Velikiy  Novgorod, RU",
		code: "VEY",
	},
	{ value: "Volzhsky, RU", label: "Volzhsky, RU", code: "VLZ" },
	{ value: "Kemerovo, RU", label: "Kemerovo, RU", code: "KEJ3" },
	{ value: "Petrozavodsk, RU", label: "Petrozavodsk, RU", code: "PVD3" },
	{ value: "Stavropol, RU", label: "Stavropol, RU", code: "TAV3" },
	{ value: "Bryansk, RU", label: "Bryansk, RU", code: "BYK-1" },
	{ value: "Grozny, RU", label: "Grozny, RU", code: "GOZ-1" },
	{ value: "Lipetsk, RU", label: "Lipetsk, RU", code: "LPK-1" },
	{ value: "Pskov, RU", label: "Pskov, RU", code: "PKV-1" },
	{ value: "Rostov-on-Don, RU", label: "Rostov-on-Don, RU", code: "ROV-1" },
	{ value: "Sérguiev Posad, RU", label: "Sérguiev Posad, RU", code: "SER-2" },
	{ value: "Vologda, RU", label: "Vologda, RU", code: "VGD-1" },
	{ value: "Vladivostok, RU", label: "Vladivostok, RU", code: "VVO-1" },
	{ value: "Podolsk, RU", label: "Podolsk, RU", code: "MOW-14" },
	{ value: "Rostov Veliki, RU", label: "Rostov Veliki, RU", code: "ROSTV-6" },
	{ value: "Ulan-Ude, RU", label: "Ulan-Ude, RU", code: "FAKE-264" },
	{ value: "Khimki, RU", label: "Khimki, RU", code: "fake-270" },
	{ value: "Anapa, RU", label: "Anapa, RU", code: "AAQ" },
	{ value: "Paratunka, RU", label: "Paratunka, RU", code: "PKC1" },
	{
		value: "Petropávlovsk-Kamchatski, RU",
		label: "Petropávlovsk-Kamchatski, RU",
		code: "PKC2",
	},
	{ value: "Aktash, RU", label: "Aktash, RU", code: "AKT-1" },
	{ value: "Chemal, RU", label: "Chemal, RU", code: "CHE-1" },
	{ value: "Katun, RU", label: "Katun, RU", code: "KAT-1" },
	{ value: "Isla Kizhi, RU", label: "Isla Kizhi, RU", code: "KIZ-1" },
	{ value: "Mandrogi, RU", label: "Mandrogi, RU", code: "MND-1" },
	{ value: "Svirstroy, RU", label: "Svirstroy, RU", code: "SVI-1" },
	{ value: "Svirstroy, RU", label: "Svirstroy, RU", code: "SVI-2" },
	{ value: "SÚZDAL, RU", label: "SÚZDAL, RU", code: "SZL-2" },
	{ value: "Úglich, RU", label: "Úglich, RU", code: "UGL-1" },
	{ value: "Iogach, RU", label: "Iogach, RU", code: "IOGA-1" },
	{ value: "Kosh-Agach, RU", label: "Kosh-Agach, RU", code: "KOAG-1" },
	{ value: "Kuray, RU", label: "Kuray, RU", code: "KURA-1" },
	{ value: "Listvianka, RU", label: "Listvianka, RU", code: "LIST-1" },
	{ value: "Isla de Oljón, RU", label: "Isla de Oljón, RU", code: "OLKH-1" },
	{ value: "Uch-Enmek, RU", label: "Uch-Enmek, RU", code: "UCHE-1" },
	{
		value: "República de Altái, RU",
		label: "República de Altái, RU",
		code: "RAI-002",
	},
	{
		value: "Yuzhno-Sajalinsk, RU",
		label: "Yuzhno-Sajalinsk, RU",
		code: "YUS-002",
	},
	{ value: "Enguels, RU", label: "Enguels, RU", code: "ENGELS-6" },
	{ value: "Piatigorsk, RU", label: "Piatigorsk, RU", code: "Pyatigorsk" },
	{ value: "Kigali, RW", label: "Kigali, RW", code: "KGL" },
	{ value: "Ruhengeri, RW", label: "Ruhengeri, RW", code: "RGI-002" },
	{
		value: "Gustavia (San Bartolomé), BL",
		label: "Gustavia (San Bartolomé), BL",
		code: "SBH",
	},
	{
		value: "San Cristóbal y Nieves, KN",
		label: "San Cristóbal y Nieves, KN",
		code: "SKB",
	},
	{ value: "Santa Lucía, LC", label: "Santa Lucía, LC", code: "UVF-1" },
	{ value: "Gros Islet, LC", label: "Gros Islet, LC", code: "GrosIslet" },
	{
		value: "San Vicente y las Granadinas , VC",
		label: "San Vicente y las Granadinas , VC",
		code: "SV1",
	},
	{ value: "Apia, Samoa, WS", label: "Apia, Samoa, WS", code: "API-21" },
	{
		value: "Area Manase (Savaii), WS",
		label: "Area Manase (Savaii), WS",
		code: "MANASE",
	},
	{
		value: "Salelologa (Savaii), WS",
		label: "Salelologa (Savaii), WS",
		code: "SALE-6",
	},
	{ value: "Maninoa, WS", label: "Maninoa, WS", code: "FGI" },
	{ value: "Salamumu, WS", label: "Salamumu, WS", code: "UPL" },
	{ value: "Upolu, WS", label: "Upolu, WS", code: "UPO" },
	{ value: "San Marino, SM", label: "San Marino, SM", code: "RSM" },
	{
		value: "Santo Tomé y Principe, ST",
		label: "Santo Tomé y Principe, ST",
		code: "TMS",
	},
	{
		value: "Santo Antonio ( Neves Ferreira), ST",
		label: "Santo Antonio ( Neves Ferreira), ST",
		code: "IPE",
	},
	{ value: "Sao Tome , ST", label: "Sao Tome , ST", code: "saotome" },
	{ value: "Riyadh, SA", label: "Riyadh, SA", code: "RUH" },
	{ value: "Medina, SA", label: "Medina, SA", code: "MED" },
	{ value: "Meca, SA", label: "Meca, SA", code: "QCA" },
	{ value: "Jeddah, SA", label: "Jeddah, SA", code: "JED-1" },
	{ value: "Abha, SA", label: "Abha, SA", code: "AHB" },
	{ value: "Dammam, SA", label: "Dammam, SA", code: "DMM" },
	{ value: "Haíl, SA", label: "Haíl, SA", code: "HHL" },
	{ value: "Tabuk, SA", label: "Tabuk, SA", code: "TBA" },
	{ value: "Taif, SA", label: "Taif, SA", code: "TIF" },
	{ value: "Al Khobar, SA", label: "Al Khobar, SA", code: "DMM-3" },
	{ value: "Medina, SA", label: "Medina, SA", code: "MED-1" },
	{ value: "Yanbu, SA", label: "Yanbu, SA", code: "YNB-1" },
	{ value: "Jizán, SA", label: "Jizán, SA", code: "fake-260" },
	{
		value: "Hafar Al Batin, SA",
		label: "Hafar Al Batin, SA",
		code: "fake-261",
	},
	{ value: "Buraydah, SA", label: "Buraydah, SA", code: "fake-262" },
	{ value: "Al Jubayl, SA", label: "Al Jubayl, SA", code: "JUBAIL-6" },
	{ value: "Dakar, SN", label: "Dakar, SN", code: "DKR" },
	{ value: "Lompoul, SN", label: "Lompoul, SN", code: "LMP-4" },
	{ value: "Saloum, SN", label: "Saloum, SN", code: "SLM-5" },
	{ value: "Saly, SN", label: "Saly, SN", code: "SAL-98" },
	{ value: "Saint Louis, SN", label: "Saint Louis, SN", code: "STL-89" },
	{ value: "Ngor, SN", label: "Ngor, SN", code: "Ngor" },
	{ value: "Caraban, SN", label: "Caraban, SN", code: "CAR-1" },
	{ value: "Cabo Skirring, SN", label: "Cabo Skirring, SN", code: "CSK-1" },
	{
		value: "Gandiol (Ndiében), SN",
		label: "Gandiol (Ndiében), SN",
		code: "GAN-1",
	},
	{ value: "Isla de Gorea, SN", label: "Isla de Gorea, SN", code: "GOR-1" },
	{ value: "Kedougou, SN", label: "Kedougou, SN", code: "KED-1" },
	{
		value: "Somone (Senegal), SN",
		label: "Somone (Senegal), SN",
		code: "SOM-1",
	},
	{ value: "Tambacounda, SN", label: "Tambacounda, SN", code: "TAM-1" },
	{ value: "Touba, SN", label: "Touba, SN", code: "TOU-1" },
	{ value: "Ziguinchor, SN", label: "Ziguinchor, SN", code: "ZIG-1" },
	{ value: "Kaolack, SN", label: "Kaolack, SN", code: "Kaolack" },
	{ value: "Nguekhokhe, SN", label: "Nguekhokhe, SN", code: "NUKOK-6" },
	{ value: "Toubacouta, SN", label: "Toubacouta, SN", code: "TBKTA-6" },
	{ value: "Wassadou, SN", label: "Wassadou, SN", code: "Wassadou" },
	{ value: "Belgrado, RS", label: "Belgrado, RS", code: "BJY" },
	{ value: "Novi Sad, RS", label: "Novi Sad, RS", code: "NVS" },
	{ value: "Pristina, RS", label: "Pristina, RS", code: "PRN" },
	{ value: "Mokra Gora, RS", label: "Mokra Gora, RS", code: "123_MOK" },
	{ value: "Paraćin, RS", label: "Paraćin, RS", code: "fake-511" },
	{ value: "Zlatibor, RS", label: "Zlatibor, RS", code: "fake-512" },
	{ value: "Niš, RS", label: "Niš, RS", code: "NSI-1" },
	{ value: "Palilula, RS", label: "Palilula, RS", code: "PALI-1" },
	{ value: "Rakovica, RS", label: "Rakovica, RS", code: "Rakovica" },
	{
		value: "Islas Seychelles , SC",
		label: "Islas Seychelles , SC",
		code: "PRI",
	},
	{ value: "Praslin, SC", label: "Praslin, SC", code: "PRI2" },
	{ value: "La Digue, SC", label: "La Digue, SC", code: "PRI3" },
	{ value: "Mahé, SC", label: "Mahé, SC", code: "PRI-1" },
	{ value: "Isla Félicité, SC", label: "Isla Félicité, SC", code: "Felicite" },
	{
		value: "Isla de Silhouette, SC",
		label: "Isla de Silhouette, SC",
		code: "Silhouettesland",
	},
	{ value: "Freetown, SL", label: "Freetown, SL", code: "FNA" },
	{ value: "Singapur, SG", label: "Singapur, SG", code: "SIN" },
	{ value: "Košice, SK", label: "Košice, SK", code: "KSC" },
	{ value: "Liptov, SK", label: "Liptov, SK", code: "SK5" },
	{ value: "Alto Tatras, SK", label: "Alto Tatras, SK", code: "TAT-1" },
	{
		value: "Tatranská Lomnica, SK",
		label: "Tatranská Lomnica, SK",
		code: "TAT-3",
	},
	{ value: "Velky Slavkov, SK", label: "Velky Slavkov, SK", code: "TAT-6" },
	{ value: "Low Tatras, SK", label: "Low Tatras, SK", code: "TAT-9" },
	{ value: "Bratislava, SK", label: "Bratislava, SK", code: "BTS" },
	{ value: "Zilina, SK", label: "Zilina, SK", code: "ILZ-1" },
	{
		value: "Tatranská Lomnica, SK",
		label: "Tatranská Lomnica, SK",
		code: "fake-252",
	},
	{ value: "Nitra, SK", label: "Nitra, SK", code: "Nitra" },
	{ value: "Strbske Pleso, SK", label: "Strbske Pleso, SK", code: "STB-1" },
	{ value: "Poprad, SK", label: "Poprad, SK", code: "POPRAD" },
	{ value: "Ljubljana, SI", label: "Ljubljana, SI", code: "LJU-1" },
	{ value: "Maribor, SI", label: "Maribor, SI", code: "MBX" },
	{ value: "Alpes Eslovenos, SI", label: "Alpes Eslovenos, SI", code: "SLA" },
	{ value: "Bled, SI", label: "Bled, SI", code: "BLE-1" },
	{ value: "Kobarid, SI", label: "Kobarid, SI", code: "SVN-6" },
	{ value: "Velika planina, SI", label: "Velika planina, SI", code: "SVN-7" },
	{ value: "Bovec, SI", label: "Bovec, SI", code: "LJU" },
	{ value: "Celje, SI", label: "Celje, SI", code: "TOP" },
	{ value: "Piran, SI", label: "Piran, SI", code: "PIN-1" },
	{ value: "Goriska Brda, SI", label: "Goriska Brda, SI", code: "SVN-1" },
	{ value: "Brezice, SI", label: "Brezice, SI", code: "SVN-3" },
	{ value: "Trbovlje, SI", label: "Trbovlje, SI", code: "SVN-4" },
	{ value: "Kamnik, SI", label: "Kamnik, SI", code: "SVN-5" },
	{ value: "Berjac, SI", label: "Berjac, SI", code: "Berjac" },
	{
		value: "Dolenjske Toplice, SI",
		label: "Dolenjske Toplice, SI",
		code: "fake-516",
	},
	{
		value: "Grutas de Skocjan, SI",
		label: "Grutas de Skocjan, SI",
		code: "fake-517",
	},
	{ value: "Kranjska Gora, SI", label: "Kranjska Gora, SI", code: "KRJ" },
	{ value: "Novo Mesto, SI", label: "Novo Mesto, SI", code: "NVO" },
	{ value: "Portoroz, SI", label: "Portoroz, SI", code: "POR" },
	{ value: "Postoina, SI", label: "Postoina, SI", code: "POS" },
	{ value: "Lasko, SI", label: "Lasko, SI", code: "LAS-1" },
	{ value: "Goriška Brda, SI", label: "Goriška Brda, SI", code: "LJU-3" },
	{ value: "Otocec, SI", label: "Otocec, SI", code: "NVO-2" },
	{ value: "Lipica, SI", label: "Lipica, SI", code: "SVN-2" },
	{ value: "Zrece, SI", label: "Zrece, SI", code: "ZRC-1" },
	{ value: "Idrija, SI", label: "Idrija, SI", code: "Idrija" },
	{
		value: "Rogaška Slatina, SI",
		label: "Rogaška Slatina, SI",
		code: "ROG123",
	},
	{ value: "Izola, SI", label: "Izola, SI", code: "fake-265" },
	{ value: "Velenje, SI", label: "Velenje, SI", code: "fake-266" },
	{ value: "Ptuj, SI", label: "Ptuj, SI", code: "fake-267" },
	{ value: "Bohinj, SI", label: "Bohinj, SI", code: "fake-515" },
	{ value: "Medvode, SI", label: "Medvode, SI", code: "MEDV-1" },
	{ value: "Strunjan, SI", label: "Strunjan, SI", code: "fake-794" },
	{ value: "Radenci, SI", label: "Radenci, SI", code: "RADENCI--6" },
	{ value: "Hargeisa, SO", label: "Hargeisa, SO", code: "HAR-71" },
	{ value: "Muqdisho, SO", label: "Muqdisho, SO", code: "MUQ-87" },
	{ value: "Adado, SO", label: "Adado, SO", code: "ADAD-1" },
	{ value: "Beledweyne, SO", label: "Beledweyne, SO", code: "BELE-1" },
	{ value: "Baidoa , SO", label: "Baidoa , SO", code: "Baidoa" },
	{ value: "Doolow , SO", label: "Doolow , SO", code: "Dollow" },
	{ value: "Galcaio, SO", label: "Galcaio, SO", code: "GALK-1" },
	{ value: "Garōwe  , SO", label: "Garōwe  , SO", code: "Garowe" },
	{ value: "Hudur, SO", label: "Hudur, SO", code: "HUDU-1" },
	{ value: "Jowhar, SO", label: "Jowhar, SO", code: "JOWH-1" },
	{ value: "Kismaayo, SO", label: "Kismaayo, SO", code: "KISM-1" },
	{ value: "Luuq, SO", label: "Luuq, SO", code: "LUUQ-1" },
	{ value: "Dhobley, SO", label: "Dhobley, SO", code: "Dhobley" },
	{ value: "Galcaio, SO", label: "Galcaio, SO", code: "GALKO-6" },
	{ value: "Guriel , SO", label: "Guriel , SO", code: "Guricel" },
	{ value: "Bosaso, SO", label: "Bosaso, SO", code: "BOSASO-6" },
	{ value: "Dusmareb , SO", label: "Dusmareb , SO", code: "Dhuusamareeb" },
	{ value: "Ciudad del Cabo, ZA", label: "Ciudad del Cabo, ZA", code: "CPT" },
	{ value: "Johannesburgo, ZA", label: "Johannesburgo, ZA", code: "JHG" },
	{ value: "Lesoto, ZA", label: "Lesoto, ZA", code: "LSO" },
	{
		value: "Pietermaritzburg, ZA",
		label: "Pietermaritzburg, ZA",
		code: "KZN-7",
	},
	{
		value: "Puerto Elizabeth, ZA",
		label: "Puerto Elizabeth, ZA",
		code: "PTE-1",
	},
	{ value: "Mthatha, ZA", label: "Mthatha, ZA", code: "UMT-1" },
	{ value: "Free State, ZA", label: "Free State, ZA", code: "BLF" },
	{ value: "Durban, ZA", label: "Durban, ZA", code: "KZN" },
	{
		value: "Provincia Septentrional del Cabo, ZA",
		label: "Provincia Septentrional del Cabo, ZA",
		code: "NTC",
	},
	{ value: "Bloemfontein, ZA", label: "Bloemfontein, ZA", code: "BLF-1" },
	{ value: "Knysna, ZA", label: "Knysna, ZA", code: "CPT_5" },
	{ value: "Randburg, ZA", label: "Randburg, ZA", code: "JHG-1" },
	{ value: "Centurion, ZA", label: "Centurion, ZA", code: "JHG-3" },
	{ value: "Vereeniging, ZA", label: "Vereeniging, ZA", code: "JHG-4" },
	{ value: "Magaliesburg, ZA", label: "Magaliesburg, ZA", code: "JHG-8" },
	{ value: "Pretoria, ZA", label: "Pretoria, ZA", code: "JHG_2" },
	{ value: "Umhlanga, ZA", label: "Umhlanga, ZA", code: "KZN-4" },
	{ value: "Nelspruit, ZA", label: "Nelspruit, ZA", code: "MPM-2" },
	{ value: "Kimberley, ZA", label: "Kimberley, ZA", code: "NTC-1" },
	{ value: "Somerset West, ZA", label: "Somerset West, ZA", code: "CPT-18" },
	{ value: "Caledon, ZA", label: "Caledon, ZA", code: "CPT-36" },
	{ value: "Barrydale, ZA", label: "Barrydale, ZA", code: "CPT_31" },
	{ value: "Hoedspruit, ZA", label: "Hoedspruit, ZA", code: "HDS-88" },
	{ value: "St. Lucía, ZA", label: "St. Lucía, ZA", code: "KZN-10" },
	{ value: "Margate, ZA", label: "Margate, ZA", code: "KZN-17" },
	{ value: "Port Shepstone, ZA", label: "Port Shepstone, ZA", code: "KZN-20" },
	{ value: "Skukuza, ZA", label: "Skukuza, ZA", code: "SHU-21" },
	{
		value: "Parque Nacional Tsitsikamma, ZA",
		label: "Parque Nacional Tsitsikamma, ZA",
		code: "fake-111",
	},
	{ value: "Roodepoort, ZA", label: "Roodepoort, ZA", code: "fake-175" },
	{ value: "Boksburg, ZA", label: "Boksburg, ZA", code: "fake-176" },
	{ value: "Germiston, ZA", label: "Germiston, ZA", code: "fake-177" },
	{ value: "Sandton, ZA", label: "Sandton, ZA", code: "fake-178" },
	{ value: "Kleinzee, ZA", label: "Kleinzee, ZA", code: "fake-188" },
	{ value: "Springbok, ZA", label: "Springbok, ZA", code: "fake-189" },
	{ value: "Bellville, ZA", label: "Bellville, ZA", code: "fake-191" },
	{
		value: "Bloubergstrand, ZA",
		label: "Bloubergstrand, ZA",
		code: "fake-192",
	},
	{
		value: "Brenton-on-Sea, ZA",
		label: "Brenton-on-Sea, ZA",
		code: "fake-194",
	},
	{ value: "Montagu, ZA", label: "Montagu, ZA", code: "fake-199" },
	{ value: "Graaff-Reinet, ZA", label: "Graaff-Reinet, ZA", code: "fake-207" },
	{ value: "Mkuze, ZA", label: "Mkuze, ZA", code: "fake-210" },
	{
		value: "Paulpietersburg, ZA",
		label: "Paulpietersburg, ZA",
		code: "fake-211",
	},
	{ value: "Clarens, ZA", label: "Clarens, ZA", code: "fake-227" },
	{
		value: "Limpopo (provincia), ZA",
		label: "Limpopo (provincia), ZA",
		code: "LMP",
	},
	{ value: "North West, ZA", label: "North West, ZA", code: "NWS" },
	{ value: "Phalaborwa, ZA", label: "Phalaborwa, ZA", code: "PHW" },
	{ value: "Sun city, ZA", label: "Sun city, ZA", code: "SCI" },
	{ value: "Stellenbosch, ZA", label: "Stellenbosch, ZA", code: "CPT3" },
	{ value: "Port Edward, ZA", label: "Port Edward, ZA", code: "KZN5" },
	{ value: "Swellendam, ZA", label: "Swellendam, ZA", code: "CPT17" },
	{ value: "Eshowe, ZA", label: "Eshowe, ZA", code: "KZN-6" },
	{
		value: "Hluhluwe National Park, ZA",
		label: "Hluhluwe National Park, ZA",
		code: "KZN-8",
	},
	{ value: "Underberg, ZA", label: "Underberg, ZA", code: "KZN-9" },
	{ value: "Tzaneen, ZA", label: "Tzaneen, ZA", code: "LMP-9" },
	{ value: "Mpumalanga, ZA", label: "Mpumalanga, ZA", code: "MPM-1" },
	{ value: "Vryburg, ZA", label: "Vryburg, ZA", code: "MWS-5" },
	{ value: "Mafikeng, ZA", label: "Mafikeng, ZA", code: "NWS-1" },
	{ value: "Rustenburg, ZA", label: "Rustenburg, ZA", code: "NWS-2" },
	{ value: "Klerksdorp, ZA", label: "Klerksdorp, ZA", code: "NWS-4" },
	{ value: "Humansdorp , ZA", label: "Humansdorp , ZA", code: "PTE-2" },
	{ value: "East London, ZA", label: "East London, ZA", code: "PTE-5" },
	{ value: "Port Alfred, ZA", label: "Port Alfred, ZA", code: "PTE-9" },
	{
		value: "Parque nacional de los Elefantes de Addo, ZA",
		label: "Parque nacional de los Elefantes de Addo, ZA",
		code: "PTE10",
	},
	{ value: "iBhayi , ZA", label: "iBhayi , ZA", code: "PTE_1" },
	{ value: "Franschoek, ZA", label: "Franschoek, ZA", code: "CPT-13" },
	{ value: "Tulbagh , ZA", label: "Tulbagh , ZA", code: "CPT-24" },
	{
		value: "Wellington, South Africa, ZA",
		label: "Wellington, South Africa, ZA",
		code: "CPT-26",
	},
	{ value: "Paternoster, ZA", label: "Paternoster, ZA", code: "CPT-28" },
	{ value: "Velddrif, ZA", label: "Velddrif, ZA", code: "CPT-35" },
	{ value: "Worcester, ZA", label: "Worcester, ZA", code: "CPT-37" },
	{ value: "Midrand, ZA", label: "Midrand, ZA", code: "JHG-11" },
	{ value: "Empangeni, ZA", label: "Empangeni, ZA", code: "KZN-16" },
	{ value: "Scottburgh, ZA", label: "Scottburgh, ZA", code: "KZN-18" },
	{
		value: "Louis Trichardt, ZA",
		label: "Louis Trichardt, ZA",
		code: "LMP-12",
	},
	{ value: "Waterval Boven, ZA", label: "Waterval Boven, ZA", code: "MPM-11" },
	{ value: "Benoni, ZA", label: "Benoni, ZA", code: "fake-179" },
	{ value: "Cullinan, ZA", label: "Cullinan, ZA", code: "fake-183" },
	{ value: "Edenvale, ZA", label: "Edenvale, ZA", code: "fake-184" },
	{ value: "Germiston, ZA", label: "Germiston, ZA", code: "fake-185" },
	{ value: "Kempton Park, ZA", label: "Kempton Park, ZA", code: "fake-186" },
	{ value: "Upington, ZA", label: "Upington, ZA", code: "fake-190" },
	{ value: "Dysselsdorp, ZA", label: "Dysselsdorp, ZA", code: "fake-195" },
	{ value: "Gansbaai, ZA", label: "Gansbaai, ZA", code: "fake-196" },
	{ value: "Khayelitsha, ZA", label: "Khayelitsha, ZA", code: "fake-197" },
	{ value: "Kraaifontein, ZA", label: "Kraaifontein, ZA", code: "fake-198" },
	{ value: "Muizenberg, ZA", label: "Muizenberg, ZA", code: "fake-200" },
	{ value: "Robertson, ZA", label: "Robertson, ZA", code: "fake-201" },
	{ value: "Rondebosch, ZA", label: "Rondebosch, ZA", code: "fake-202" },
	{ value: "Sedgefield, ZA", label: "Sedgefield, ZA", code: "fake-203" },
	{ value: "Stanford, ZA", label: "Stanford, ZA", code: "fake-204" },
	{ value: "Strand, ZA", label: "Strand, ZA", code: "fake-205" },
	{ value: "Fort Beaufort, ZA", label: "Fort Beaufort, ZA", code: "fake-206" },
	{ value: "Paterson, ZA", label: "Paterson, ZA", code: "fake-209" },
	{ value: "Pinetown, ZA", label: "Pinetown, ZA", code: "fake-212" },
	{ value: "Pongola , ZA", label: "Pongola , ZA", code: "fake-213" },
	{ value: "Bela-Bela, ZA", label: "Bela-Bela, ZA", code: "fake-216" },
	{ value: "Thohoyandou, ZA", label: "Thohoyandou, ZA", code: "fake-217" },
	{ value: "Komatipoort, ZA", label: "Komatipoort, ZA", code: "fake-222" },
	{ value: "Machadodorp, ZA", label: "Machadodorp, ZA", code: "fake-223" },
	{ value: "Marloth Park , ZA", label: "Marloth Park , ZA", code: "fake-224" },
	{ value: "Mbombela , ZA", label: "Mbombela , ZA", code: "fake-225" },
	{ value: "Sabie, ZA", label: "Sabie, ZA", code: "fake-226" },
	{ value: "Harrismith, ZA", label: "Harrismith, ZA", code: "fake-228" },
	{ value: "HDS, ZA", label: "HDS, ZA", code: "111" },
	{ value: "Montagu, ZA", label: "Montagu, ZA", code: "CPT1" },
	{ value: "Hermanus, ZA", label: "Hermanus, ZA", code: "CPT7" },
	{ value: "Brits, ZA", label: "Brits, ZA", code: "NWS6" },
	{ value: "Belén, ZA", label: "Belén, ZA", code: "BLF-2" },
	{ value: "Oudtshoorn, ZA", label: "Oudtshoorn, ZA", code: "CPT-4" },
	{ value: "Plettenberg Bay, ZA", label: "Plettenberg Bay, ZA", code: "CPT-6" },
	{ value: "Hermanus, ZA", label: "Hermanus, ZA", code: "CPT-7" },
	{ value: "Beacon Bay, ZA", label: "Beacon Bay, ZA", code: "CPT_6" },
	{ value: "Jozini, ZA", label: "Jozini, ZA", code: "KZN13" },
	{
		value: "Parque Nacional de Kruger, ZA",
		label: "Parque Nacional de Kruger, ZA",
		code: "LMP-1",
	},
	{ value: "Vaalwater, ZA", label: "Vaalwater, ZA", code: "LMP-5" },
	{ value: "Hazyview, ZA", label: "Hazyview, ZA", code: "MPM-3" },
	{ value: "Emalahleni, ZA", label: "Emalahleni, ZA", code: "MPM-8" },
	{ value: "Vaalkop, ZA", label: "Vaalkop, ZA", code: "NWS-6" },
	{ value: "Madikwe, ZA", label: "Madikwe, ZA", code: "SCI-3" },
	{ value: "Paarl, ZA", label: "Paarl, ZA", code: "CPT-14" },
	{ value: "Langebaan, ZA", label: "Langebaan, ZA", code: "CPT-16" },
	{ value: "Richards Bay, ZA", label: "Richards Bay, ZA", code: "RCB-41" },
	{ value: "Tembisa, ZA", label: "Tembisa, ZA", code: "TMBS-6" },
	{ value: "Drakensberg, ZA", label: "Drakensberg, ZA", code: "DRKNS-6" },
	{ value: "Bergville, ZA", label: "Bergville, ZA", code: "BRGVLL-6" },
	{ value: "Simons Town, ZA", label: "Simons Town, ZA", code: "fake-120" },
	{ value: "White River, ZA", label: "White River, ZA", code: "fake-121" },
	{ value: "Dullstroom, ZA", label: "Dullstroom, ZA", code: "fake-220" },
	{ value: "Shakaland, ZA", label: "Shakaland, ZA", code: "shak" },
	{ value: "Herbertsdale, ZA", label: "Herbertsdale, ZA", code: "HER-1" },
	{ value: "Musina, ZA", label: "Musina, ZA", code: "MSN-6" },
	{ value: "Nietverdient, ZA", label: "Nietverdient, ZA", code: "NWS-3" },
	{ value: "Saldanha, ZA", label: "Saldanha, ZA", code: "CPT-23" },
	{ value: "Cabo Oriental, ZA", label: "Cabo Oriental, ZA", code: "EACA-1" },
	{ value: "Gauteng, ZA", label: "Gauteng, ZA", code: "GAUT-1" },
	{ value: "Gariep Dam, ZA", label: "Gariep Dam, ZA", code: "GDAM-6" },
	{
		value: "George, El Cabo Occidental, ZA",
		label: "George, El Cabo Occidental, ZA",
		code: "GEWC-1",
	},
	{ value: "El Gran Karoo, ZA", label: "El Gran Karoo, ZA", code: "GRKA-1" },
	{
		value: "Parque del Humedal de iSimangaliso, ZA",
		label: "Parque del Humedal de iSimangaliso, ZA",
		code: "ISWP-1",
	},
	{ value: "Kirkwood, ZA", label: "Kirkwood, ZA", code: "KIRK-1" },
	{ value: "KwaZulu-Natal, ZA", label: "KwaZulu-Natal, ZA", code: "KWNA-1" },
	{ value: "Malelane, ZA", label: "Malelane, ZA", code: "LALA-6" },
	{
		value: "El Pequeño Karoo, ZA",
		label: "El Pequeño Karoo, ZA",
		code: "LIKA-1",
	},
	{ value: "Mosselbaai, ZA", label: "Mosselbaai, ZA", code: "MSB-81" },
	{
		value: "Cabo Occidental, ZA",
		label: "Cabo Occidental, ZA",
		code: "WECA-1",
	},
	{
		value: "Playa de Boulders, ZA",
		label: "Playa de Boulders, ZA",
		code: "BBH-002",
	},
	{
		value: "Cañón del Río Blyde, ZA",
		label: "Cañón del Río Blyde, ZA",
		code: "BCY-002",
	},
	{
		value: "Betty&apos;s Bay, ZA",
		label: "Betty&apos;s Bay, ZA",
		code: "BETTY-6",
	},
	{ value: "Ceres, ZA", label: "Ceres, ZA", code: "CERES-6" },
	{ value: "Clanwilliam, ZA", label: "Clanwilliam, ZA", code: "CLANW-6" },
	{ value: "Augrabies, ZA", label: "Augrabies, ZA", code: "GRABI-6" },
	{ value: "Harding , ZA", label: "Harding , ZA", code: "HDG-002" },
	{ value: "Kakamas, ZA", label: "Kakamas, ZA", code: "KAKAS-6" },
	{ value: "Kanoneiland, ZA", label: "Kanoneiland, ZA", code: "KANON-6" },
	{ value: "Kingsburgh, ZA", label: "Kingsburgh, ZA", code: "KNGBR-6" },
	{ value: "Kuboes, ZA", label: "Kuboes, ZA", code: "KUBOS-6" },
	{ value: "Lephalale, ZA", label: "Lephalale, ZA", code: "LEPHA-6" },
	{ value: "Mthatha, ZA", label: "Mthatha, ZA", code: "MTH-002" },
	{ value: "Ohrigstad, ZA", label: "Ohrigstad, ZA", code: "OHRIG-6" },
	{ value: "Kenton-on-Sea, ZA", label: "Kenton-on-Sea, ZA", code: "ONSEA-6" },
	{ value: "Soweto, ZA", label: "Soweto, ZA", code: "STO-002" },
	{ value: "Yzerfontein, ZA", label: "Yzerfontein, ZA", code: "YZERF-6" },
	{ value: "Barberton, ZA", label: "Barberton, ZA", code: "BARTON-6" },
	{ value: "Bahía Café, ZA", label: "Bahía Café, ZA", code: "COFFEE-6" },
	{ value: "Carnarvon, ZA", label: "Carnarvon, ZA", code: "CRNRVN-6" },
	{
		value: "Reserva Natural De Hoop, ZA",
		label: "Reserva Natural De Hoop, ZA",
		code: "DEHOOP-6",
	},
	{ value: "Ermelo, ZA", label: "Ermelo, ZA", code: "ERMELO-6" },
	{ value: "Grahamstown, ZA", label: "Grahamstown, ZA", code: "GRAHAM-6" },
	{ value: "Graskop, ZA", label: "Graskop, ZA", code: "GRASKP-6" },
	{ value: "Haga Haga, ZA", label: "Haga Haga, ZA", code: "HAGAx2-6" },
	{ value: "Hammanskraal, ZA", label: "Hammanskraal, ZA", code: "HMMKRA-6" },
	{ value: "Howick, ZA", label: "Howick, ZA", code: "HOWICK-6" },
	{ value: "Inanda, ZA", label: "Inanda, ZA", code: "INANDA-6" },
	{ value: "Joubertina, ZA", label: "Joubertina, ZA", code: "JBRTNA-6" },
	{ value: "Kiepersol, ZA", label: "Kiepersol, ZA", code: "KPRSOL-6" },
	{
		value: "Lambert&apos;s Bay, ZA",
		label: "Lambert&apos;s Bay, ZA",
		code: "LAMBAY-6",
	},
	{ value: "Lusikisiki, ZA", label: "Lusikisiki, ZA", code: "LUKIKI-6" },
	{ value: "Mokerong, ZA", label: "Mokerong, ZA", code: "Mokerong" },
	{
		value: "Oyster Bay, Eastern Cape, ZA",
		label: "Oyster Bay, Eastern Cape, ZA",
		code: "OYSTER-6",
	},
	{ value: "Vaal Marina, ZA", label: "Vaal Marina, ZA", code: "VAALMA-6" },
	{ value: "Hartbeespoort, ZA", label: "Hartbeespoort, ZA", code: "fake-219" },
	{ value: "Bredasdorp, ZA", label: "Bredasdorp, ZA", code: "BREDORP-6" },
	{ value: "Chintsa, ZA", label: "Chintsa, ZA", code: "CHINTSA-6" },
	{ value: "Cradock, ZA", label: "Cradock, ZA", code: "CRADOCK-6" },
	{
		value: "Cabo St. Francis, ZA",
		label: "Cabo St. Francis, ZA",
		code: "FRANCIS-6",
	},
	{ value: "Heidelberg, ZA", label: "Heidelberg, ZA", code: "HDLBERG-6" },
	{
		value: "Bahía de Jeffreys, ZA",
		label: "Bahía de Jeffreys, ZA",
		code: "JEFFREY-6",
	},
	{ value: "De Kelders, ZA", label: "De Kelders, ZA", code: "KELDERS-6" },
	{
		value: "Parque transfronterizo de Kgalagadi, ZA",
		label: "Parque transfronterizo de Kgalagadi, ZA",
		code: "Kgalagadi",
	},
	{
		value: "L&apos;Agulhas, ZA",
		label: "L&apos;Agulhas, ZA",
		code: "NONORTH-6",
	},
	{
		value: "Pilgrim&apos;s Rest, ZA",
		label: "Pilgrim&apos;s Rest, ZA",
		code: "PILREST-6",
	},
	{ value: "Prince Albert, ZA", label: "Prince Albert, ZA", code: "PRINCEA-6" },
	{
		value: "Puerto St. Johns, Eastern Cape, ZA",
		label: "Puerto St. Johns, Eastern Cape, ZA",
		code: "PSTJOHN-6",
	},
	{ value: "Thabazimbi, ZA", label: "Thabazimbi, ZA", code: "THZIMBI-6" },
	{
		value: "eMalahleni (Witbank), ZA",
		label: "eMalahleni (Witbank), ZA",
		code: "WITBANK-6",
	},
	{
		value: "Hogsback, Eastern Cape, ZA",
		label: "Hogsback, Eastern Cape, ZA",
		code: "HOGSBACK-6",
	},
	{ value: "Kamieskroon, ZA", label: "Kamieskroon, ZA", code: "KAMKROON-6" },
	{ value: "Piet Retief, ZA", label: "Piet Retief, ZA", code: "PietRetief" },
	{ value: "Ladysmith, ZA", label: "Ladysmith, ZA", code: "LADYSMITH-6" },
	{ value: "Seúl, KR", label: "Seúl, KR", code: "ICN" },
	{ value: "Muan, KR", label: "Muan, KR", code: "MAN-2" },
	{ value: "Busan, KR", label: "Busan, KR", code: "PUS" },
	{ value: "Daegu, KR", label: "Daegu, KR", code: "TAE" },
	{ value: "Jeju, KR", label: "Jeju, KR", code: "CJU" },
	{ value: "Hwaseong-si, KR", label: "Hwaseong-si, KR", code: "GGI" },
	{ value: "Gimhae, KR", label: "Gimhae, KR", code: "GIM" },
	{ value: "Gunsan, KR", label: "Gunsan, KR", code: "JJU" },
	{ value: "Pohang, KR", label: "Pohang, KR", code: "KR3" },
	{ value: "Gwangju, KR", label: "Gwangju, KR", code: "KWJ" },
	{ value: "Gumi, KR", label: "Gumi, KR", code: "UGM" },
	{ value: "Ulsan, KR", label: "Ulsan, KR", code: "ULS" },
	{ value: "Daejeon, KR", label: "Daejeon, KR", code: "KR1-1" },
	{ value: "Muju, KR", label: "Muju, KR", code: "MJU-1" },
	{ value: "Changwon, KR", label: "Changwon, KR", code: "PUS-2" },
	{ value: "Cheonan, KR", label: "Cheonan, KR", code: "CCH" },
	{ value: "Jeonju, KR", label: "Jeonju, KR", code: "JOE" },
	{ value: "Pyeongchang, KR", label: "Pyeongchang, KR", code: "KR0" },
	{ value: "Incheon, KR", label: "Incheon, KR", code: "KR6" },
	{ value: "Yongin, KR", label: "Yongin, KR", code: "YNO" },
	{ value: "Gyeongju, KR", label: "Gyeongju, KR", code: "10-3" },
	{ value: "Goyang, KR", label: "Goyang, KR", code: "GGI-2" },
	{ value: "Seongnam, KR", label: "Seongnam, KR", code: "ICN-6" },
	{ value: "Chuncheon, KR", label: "Chuncheon, KR", code: "KR9-1" },
	{ value: "Suwon, KR", label: "Suwon, KR", code: "SWU-1" },
	{ value: "Fuyuan, KR", label: "Fuyuan, KR", code: "FUN-34" },
	{ value: "Jinju, KR", label: "Jinju, KR", code: "JNJ-14" },
	{ value: "Andong, KR", label: "Andong, KR", code: "ANDONG-6" },
	{ value: "Anyang, KR", label: "Anyang, KR", code: "FAKE-259" },
	{ value: "Bucheon, KR", label: "Bucheon, KR", code: "fake-256" },
	{ value: "Cheongju, KR", label: "Cheongju, KR", code: "fake-258" },
	{ value: "Ansan, KR", label: "Ansan, KR", code: "A1N" },
	{
		value: "Rebelión Yeosu-Suncheon, KR",
		label: "Rebelión Yeosu-Suncheon, KR",
		code: "SCH-2",
	},
	{ value: "Barcelona, ES", label: "Barcelona, ES", code: "BCN" },
	{ value: "Benissa, ES", label: "Benissa, ES", code: "BEN" },
	{ value: "Madrid, ES", label: "Madrid, ES", code: "MAD" },
	{ value: "Ibiza (Isla), ES", label: "Ibiza (Isla), ES", code: "IBZ" },
	{ value: "La Gomera, ES", label: "La Gomera, ES", code: "QGZ" },
	{
		value: "La Palma (Islas Canarias), ES",
		label: "La Palma (Islas Canarias), ES",
		code: "SPC",
	},
	{ value: "Sevilla, ES", label: "Sevilla, ES", code: "SVQ" },
	{
		value: "Santa Cruz de Tenerife, ES",
		label: "Santa Cruz de Tenerife, ES",
		code: "TFS",
	},
	{ value: "El Hierro, ES", label: "El Hierro, ES", code: "VDE" },
	{ value: "Valencia, ES", label: "Valencia, ES", code: "VLC" },
	{ value: "Cartaya, ES", label: "Cartaya, ES", code: "CTY-1" },
	{ value: "Asturias, ES", label: "Asturias, ES", code: "AST-12" },
	{ value: "Bilbao, ES", label: "Bilbao, ES", code: "BIO-99" },
	{
		value: "Palma de Mallorca , ES",
		label: "Palma de Mallorca , ES",
		code: "PMI-10",
	},
	{ value: "Albacete, ES", label: "Albacete, ES", code: "ABC" },
	{ value: "Costa del Sol, ES", label: "Costa del Sol, ES", code: "AGP" },
	{ value: "Badajoz, ES", label: "Badajoz, ES", code: "BJZ" },
	{ value: "Cuenca, ES", label: "Cuenca, ES", code: "CCA" },
	{ value: "Ciudad Real, ES", label: "Ciudad Real, ES", code: "CRE" },
	{ value: "Cáceres‎, ES", label: "Cáceres‎, ES", code: "CRS" },
	{ value: "Formentera, ES", label: "Formentera, ES", code: "FOR" },
	{ value: "Fuerteventura, ES", label: "Fuerteventura, ES", code: "FUE" },
	{ value: "Gibraltar, ES", label: "Gibraltar, ES", code: "GIB" },
	{ value: "Huesca, ES", label: "Huesca, ES", code: "HUS" },
	{ value: "Jaén, ES", label: "Jaén, ES", code: "JEN" },
	{ value: "Costa de Almería, ES", label: "Costa de Almería, ES", code: "LEI" },
	{ value: "Lérida, ES", label: "Lérida, ES", code: "LER" },
	{ value: "La Manga, ES", label: "La Manga, ES", code: "LMN" },
	{ value: "Cádiz, ES", label: "Cádiz, ES", code: "LUZ" },
	{ value: "Menorca, ES", label: "Menorca, ES", code: "MAH" },
	{ value: "Córdoba, ES", label: "Córdoba, ES", code: "ODB" },
	{ value: "Ourense, ES", label: "Ourense, ES", code: "ORE" },
	{ value: "A Coruña, ES", label: "A Coruña, ES", code: "SCQ" },
	{ value: "Salamanca, ES", label: "Salamanca, ES", code: "SLM" },
	{ value: "Soria, ES", label: "Soria, ES", code: "SOR" },
	{ value: "Teruel, ES", label: "Teruel, ES", code: "TER" },
	{ value: "Toledo, ES", label: "Toledo, ES", code: "TOL" },
	{ value: "Valladolid, ES", label: "Valladolid, ES", code: "VLL" },
	{ value: "Zamora, ES", label: "Zamora, ES", code: "ZAM" },
	{ value: "Bizkaia, ES", label: "Bizkaia, ES", code: "bio8" },
	{ value: "Costa de Azahar, ES", label: "Costa de Azahar, ES", code: "CAZ-1" },
	{ value: "Costa Tropical, ES", label: "Costa Tropical, ES", code: "CTR-1" },
	{
		value: "Costa de Valencia, ES",
		label: "Costa de Valencia, ES",
		code: "CVA-1",
	},
	{ value: "Cantabria, ES", label: "Cantabria, ES", code: "Can11" },
	{ value: "Durango, ES", label: "Durango, ES", code: "DUR-1" },
	{ value: "Almería, ES", label: "Almería, ES", code: "LEI-1" },
	{ value: "Meloneras, ES", label: "Meloneras, ES", code: "LPA-6" },
	{ value: "Leiro, ES", label: "Leiro, ES", code: "ORE50" },
	{ value: "Baqueira, ES", label: "Baqueira, ES", code: "PIR-2" },
	{ value: "Solsona, ES", label: "Solsona, ES", code: "PIR53" },
	{ value: "La Seu D´Urgell, ES", label: "La Seu D´Urgell, ES", code: "PIR60" },
	{
		value: "Los Corrales de Buelna, ES",
		label: "Los Corrales de Buelna, ES",
		code: "SDR-3",
	},
	{
		value: "Puerto Naos, La Palma, ES",
		label: "Puerto Naos, La Palma, ES",
		code: "SPC-2",
	},
	{
		value: "Puerto de Tazacorte, Isla de la Palma, ES",
		label: "Puerto de Tazacorte, Isla de la Palma, ES",
		code: "SPC-6",
	},
	{
		value: "Costa del Silencio, ES",
		label: "Costa del Silencio, ES",
		code: "TFS24",
	},
	{ value: "Yuncos (Toledo), ES", label: "Yuncos (Toledo), ES", code: "TOL-9" },
	{
		value: "Vilanova i la Geltru, ES",
		label: "Vilanova i la Geltru, ES",
		code: "VYG-2",
	},
	{
		value: "Lanzarote (Islas Canarias), ES",
		label: "Lanzarote (Islas Canarias), ES",
		code: "ACE-10",
	},
	{ value: "Alicante, ES", label: "Alicante, ES", code: "ALC-99" },
	{ value: "Ávila, ES", label: "Ávila, ES", code: "AVA-99" },
	{ value: "Mérida, ES", label: "Mérida, ES", code: "BJZ-90" },
	{ value: "Burgos, ES", label: "Burgos, ES", code: "BRG-99" },
	{ value: "Comillas, ES", label: "Comillas, ES", code: "COM-11" },
	{ value: "San Sebastián, ES", label: "San Sebastián, ES", code: "EAS-99" },
	{
		value: "Costa de Antigua, Fuerteventura, ES",
		label: "Costa de Antigua, Fuerteventura, ES",
		code: "FUE-28",
	},
	{ value: "Gerona, ES", label: "Gerona, ES", code: "GRO-99" },
	{ value: "Granada, ES", label: "Granada, ES", code: "GRX-99" },
	{ value: "Huelva, ES", label: "Huelva, ES", code: "HUE-99" },
	{ value: "Ibiza, ES", label: "Ibiza, ES", code: "IBZ-10" },
	{
		value: "Playa de Talamanca, Ibiza, ES",
		label: "Playa de Talamanca, Ibiza, ES",
		code: "IBZ-45",
	},
	{ value: "Laredo, ES", label: "Laredo, ES", code: "LAR-11" },
	{ value: "León, ES", label: "León, ES", code: "LEO-99" },
	{ value: "Logroño, ES", label: "Logroño, ES", code: "LOG-99" },
	{
		value: "San Agustín, Gran Canaria, ES",
		label: "San Agustín, Gran Canaria, ES",
		code: "LPA-30",
	},
	{
		value: "Costa de la Luz, ES",
		label: "Costa de la Luz, ES",
		code: "LUZ-01",
	},
	{ value: "Tarifa, ES", label: "Tarifa, ES", code: "LUZ-48" },
	{
		value: "Ciudadela, Menorca, ES",
		label: "Ciudadela, Menorca, ES",
		code: "MAH-20",
	},
	{ value: "Málaga, ES", label: "Málaga, ES", code: "MAL-10" },
	{ value: "Murcia, ES", label: "Murcia, ES", code: "MJV-10" },
	{ value: "Oviedo, ES", label: "Oviedo, ES", code: "OVD-10" },
	{ value: "Vielha, ES", label: "Vielha, ES", code: "PIR-14" },
	{ value: "Sort, ES", label: "Sort, ES", code: "PIR-36" },
	{
		value: "Mallorca (Isla), ES",
		label: "Mallorca (Isla), ES",
		code: "PMI--1",
	},
	{
		value: "Playa de Palma, Mallorca, ES",
		label: "Playa de Palma, Mallorca, ES",
		code: "PMI-20",
	},
	{ value: "Pamplona, ES", label: "Pamplona, ES", code: "PNA-99" },
	{ value: "Tarragona, ES", label: "Tarragona, ES", code: "SAL-80" },
	{
		value: "Santiago de Compostela, ES",
		label: "Santiago de Compostela, ES",
		code: "SCQ-99",
	},
	{ value: "Santander, ES", label: "Santander, ES", code: "SDR-99" },
	{ value: "Segovia, ES", label: "Segovia, ES", code: "SEG-99" },
	{ value: "Vitoria, ES", label: "Vitoria, ES", code: "VIT-20" },
	{ value: "Xativa, ES", label: "Xativa, ES", code: "XAT-12" },
	{ value: "Zaragoza, ES", label: "Zaragoza, ES", code: "ZAZ-99" },
	{ value: "Muro, Mallorca, ES", label: "Muro, Mallorca, ES", code: "MUR-123" },
	{ value: "Costa Vasca, ES", label: "Costa Vasca, ES", code: "Fake-143" },
	{
		value: "Costa Cantábrica, ES",
		label: "Costa Cantábrica, ES",
		code: "Fake-144",
	},
	{ value: "Costa Verde, ES", label: "Costa Verde, ES", code: "Fake-145" },
	{ value: "Rías Altas, ES", label: "Rías Altas, ES", code: "Fake-146" },
	{ value: "Rías Baixas, ES", label: "Rías Baixas, ES", code: "Fake-147" },
	{ value: "Costa Blanca, ES", label: "Costa Blanca, ES", code: "ALC" },
	{ value: "Jerez, ES", label: "Jerez, ES", code: "CAD" },
	{ value: "Ceuta, ES", label: "Ceuta, ES", code: "CEU" },
	{ value: "Cuéllar, ES", label: "Cuéllar, ES", code: "CUE" },
	{ value: "Guadalajara, ES", label: "Guadalajara, ES", code: "GUJ" },
	{ value: "Lugo, ES", label: "Lugo, ES", code: "LGO" },
	{ value: "Costa Brava, ES", label: "Costa Brava, ES", code: "LLM" },
	{
		value: "Gran Canaria (Isla), ES",
		label: "Gran Canaria (Isla), ES",
		code: "LPA",
	},
	{ value: "Costa de la Luz, ES", label: "Costa de la Luz, ES", code: "LUS" },
	{ value: "Melilla, ES", label: "Melilla, ES", code: "MLN" },
	{ value: "Palencia, ES", label: "Palencia, ES", code: "PAL" },
	{ value: "Costa Dorada, ES", label: "Costa Dorada, ES", code: "SAL" },
	{ value: "Reinosa, ES", label: "Reinosa, ES", code: "SDR" },
	{ value: "Sierra Nevada, ES", label: "Sierra Nevada, ES", code: "SRN" },
	{ value: "Sitges, ES", label: "Sitges, ES", code: "STS" },
	{
		value: "Tenerife (Islas Canarias), ES",
		label: "Tenerife (Islas Canarias), ES",
		code: "TCI",
	},
	{ value: "Pontevedra, ES", label: "Pontevedra, ES", code: "VGO" },
	{
		value: "Guardiola de Berguedà, ES",
		label: "Guardiola de Berguedà, ES",
		code: "XX1",
	},
	{ value: "Barbastro, ES", label: "Barbastro, ES", code: "ARA6" },
	{ value: "Ascó, ES", label: "Ascó, ES", code: "ASCO" },
	{ value: "Ansó, ES", label: "Ansó, ES", code: "Anso" },
	{
		value: "Moncada y Reixach , ES",
		label: "Moncada y Reixach , ES",
		code: "CAD2",
	},
	{
		value: "Esplugues del Llobregat, ES",
		label: "Esplugues del Llobregat, ES",
		code: "ESPL",
	},
	{ value: "Granollers, ES", label: "Granollers, ES", code: "GRAL" },
	{
		value: "Monforte de Lemos, ES",
		label: "Monforte de Lemos, ES",
		code: "LGO1",
	},
	{ value: "Palafolls, ES", label: "Palafolls, ES", code: "LLM3" },
	{ value: "Tres cantos, ES", label: "Tres cantos, ES", code: "MAD6" },
	{ value: "Parla, ES", label: "Parla, ES", code: "MAD8" },
	{
		value: "Alcala de Henares, ES",
		label: "Alcala de Henares, ES",
		code: "MAD9",
	},
	{
		value: "Santo Estevo de Ribas de Sil, ES",
		label: "Santo Estevo de Ribas de Sil, ES",
		code: "ORE1",
	},
	{ value: "Écija, ES", label: "Écija, ES", code: "SVQ1" },
	{ value: "Los Realejos, ES", label: "Los Realejos, ES", code: "TFS5" },
	{ value: "Garachico, ES", label: "Garachico, ES", code: "TFS7" },
	{
		value: "Puerto de Sagunto, ES",
		label: "Puerto de Sagunto, ES",
		code: "VLC3",
	},
	{ value: "Almansa, ES", label: "Almansa, ES", code: "ABC-3" },
	{
		value: "Arrecife, Lanzarote, ES",
		label: "Arrecife, Lanzarote, ES",
		code: "ACE10",
	},
	{
		value: "Costa Teguise, Lanzarote, ES",
		label: "Costa Teguise, Lanzarote, ES",
		code: "ACE20",
	},
	{ value: "Calaceite, ES", label: "Calaceite, ES", code: "AGP-9" },
	{ value: "Altea, ES", label: "Altea, ES", code: "ALC13" },
	{ value: "Ordesa, ES", label: "Ordesa, ES", code: "ARA-2" },
	{ value: "Badalona, ES", label: "Badalona, ES", code: "BCN-3" },
	{
		value: "Sant Cugat del Valles , ES",
		label: "Sant Cugat del Valles , ES",
		code: "BCN-4",
	},
	{
		value: "Cerdanyola del valles, ES",
		label: "Cerdanyola del valles, ES",
		code: "BCN16",
	},
	{
		value: "Mollet del Valles, ES",
		label: "Mollet del Valles, ES",
		code: "BCN63",
	},
	{
		value: "Villanueva de la Serena, ES",
		label: "Villanueva de la Serena, ES",
		code: "BJZ-3",
	},
	{ value: "Zafra, ES", label: "Zafra, ES", code: "BJZ20" },
	{
		value: "Arcos de la Frontera, ES",
		label: "Arcos de la Frontera, ES",
		code: "CAD30",
	},
	{ value: "Oropesa del Mar, ES", label: "Oropesa del Mar, ES", code: "CAZ60" },
	{ value: "Benicarló, ES", label: "Benicarló, ES", code: "CAZ80" },
	{ value: "Belmonte, ES", label: "Belmonte, ES", code: "CCA-3" },
	{ value: "Cercos, ES", label: "Cercos, ES", code: "CERCS" },
	{ value: "Puertollano, ES", label: "Puertollano, ES", code: "CRE-7" },
	{ value: "Almagro, ES", label: "Almagro, ES", code: "CRE20" },
	{ value: "Manzanares, ES", label: "Manzanares, ES", code: "CRE30" },
	{ value: "Valdepeñas, ES", label: "Valdepeñas, ES", code: "CRE40" },
	{ value: "Brozas, ES", label: "Brozas, ES", code: "CRS-3" },
	{
		value: "Villanueva De La Vera - Caceres, ES",
		label: "Villanueva De La Vera - Caceres, ES",
		code: "CRS15",
	},
	{ value: "Guadalupe, ES", label: "Guadalupe, ES", code: "CRS40" },
	{ value: "Jarandilla, ES", label: "Jarandilla, ES", code: "CRS50" },
	{ value: "Irún, ES", label: "Irún, ES", code: "EAS10" },
	{
		value: "Playa de Esquinzo, ES",
		label: "Playa de Esquinzo, ES",
		code: "FUE60",
	},
	{
		value: "Santa Eulàlia del Rio, ES",
		label: "Santa Eulàlia del Rio, ES",
		code: "IBZ13",
	},
	{
		value: "Platja d&apos;en Bossa, Ibiza, ES",
		label: "Platja d&apos;en Bossa, Ibiza, ES",
		code: "IBZ23",
	},
	{ value: "Úbeda, ES", label: "Úbeda, ES", code: "JEN10" },
	{ value: "Carboneras, ES", label: "Carboneras, ES", code: "LEI-7" },
	{
		value: "Vilafranca del Bierzo, ES",
		label: "Vilafranca del Bierzo, ES",
		code: "LEO30",
	},
	{ value: "Vilalba, ES", label: "Vilalba, ES", code: "LGO30" },
	{ value: "Liria, ES", label: "Liria, ES", code: "LIA-1" },
	{ value: "Costa Maresme, ES", label: "Costa Maresme, ES", code: "LLM-1" },
	{ value: "L Estartit, ES", label: "L Estartit, ES", code: "LLM41" },
	{ value: "Costa Cálida, ES", label: "Costa Cálida, ES", code: "LMN-1" },
	{ value: "Calahorra, ES", label: "Calahorra, ES", code: "LOG10" },
	{ value: "Playa Taurito, ES", label: "Playa Taurito, ES", code: "LPA20" },
	{ value: "Patalavaca, ES", label: "Patalavaca, ES", code: "LPA50" },
	{ value: "El Rompido, ES", label: "El Rompido, ES", code: "LUS-6" },
	{ value: "Barbate, ES", label: "Barbate, ES", code: "LUZ-1" },
	{ value: "Algeciras, ES", label: "Algeciras, ES", code: "LUZ-5" },
	{
		value: "Sanlúcar de Barrameda, ES",
		label: "Sanlúcar de Barrameda, ES",
		code: "LUZ-9",
	},
	{ value: "Getafe, ES", label: "Getafe, ES", code: "MAD-7" },
	{ value: "Las Rozas , ES", label: "Las Rozas , ES", code: "MAD10" },
	{ value: "Alcobendas, ES", label: "Alcobendas, ES", code: "MAD11" },
	{
		value: "Torrejon de Ardoz, ES",
		label: "Torrejon de Ardoz, ES",
		code: "MAD12",
	},
	{ value: "Fuenlabrada, ES", label: "Fuenlabrada, ES", code: "MAD22" },
	{ value: "Pinto, ES", label: "Pinto, ES", code: "MAD32" },
	{
		value: "San Luis,Menorca, ES",
		label: "San Luis,Menorca, ES",
		code: "MAH-1",
	},
	{ value: "Cala en Forcat, ES", label: "Cala en Forcat, ES", code: "MAH26" },
	{ value: "Lorca, ES", label: "Lorca, ES", code: "MJV30" },
	{ value: "Mijas, ES", label: "Mijas, ES", code: "Mijas" },
	{ value: "Ondarroa, ES", label: "Ondarroa, ES", code: "OND-1" },
	{ value: "Verín, ES", label: "Verín, ES", code: "ORE20" },
	{ value: "Avilés, ES", label: "Avilés, ES", code: "OVD-2" },
	{ value: "La Molina, ES", label: "La Molina, ES", code: "PIR18" },
	{ value: "Pallars, ES", label: "Pallars, ES", code: "PIR36" },
	{
		value: "Norte de Mallorca, ES",
		label: "Norte de Mallorca, ES",
		code: "PMI-1",
	},
	{ value: "Mallorca Este, ES", label: "Mallorca Este, ES", code: "PMI-3" },
	{
		value: "Sometimes DON&apos;T USE, ES",
		label: "Sometimes DON&apos;T USE, ES",
		code: "PMI-5",
	},
	{ value: "Olite, ES", label: "Olite, ES", code: "PNA30" },
	{ value: "La Pineda, ES", label: "La Pineda, ES", code: "SAL-3" },
	{ value: "Cambrils, ES", label: "Cambrils, ES", code: "SAL30" },
	{ value: "Port Aventura, ES", label: "Port Aventura, ES", code: "SAL37" },
	{ value: "Tortosa, ES", label: "Tortosa, ES", code: "SAL90" },
	{ value: "Arteixo , ES", label: "Arteixo , ES", code: "SCQ-1" },
	{ value: "Arenas de Iguña, ES", label: "Arenas de Iguña, ES", code: "SDR-5" },
	{
		value: "Santillana del Mar, ES",
		label: "Santillana del Mar, ES",
		code: "SDR20",
	},
	{ value: "Béjar, ES", label: "Béjar, ES", code: "SLM-2" },
	{ value: "Cantavieja, ES", label: "Cantavieja, ES", code: "TER-3" },
	{
		value: "Rubielos de Mora, ES",
		label: "Rubielos de Mora, ES",
		code: "TER-6",
	},
	{ value: "Alcañiz, ES", label: "Alcañiz, ES", code: "TER30" },
	{ value: "Güimar, ES", label: "Güimar, ES", code: "TFS26" },
	{ value: "Playa Paraíso, ES", label: "Playa Paraíso, ES", code: "TFS32" },
	{
		value: "Playa de las Américas, ES",
		label: "Playa de las Américas, ES",
		code: "TFS80",
	},
	{ value: "Puerto Santiago, ES", label: "Puerto Santiago, ES", code: "TFS90" },
	{ value: "Carranque, ES", label: "Carranque, ES", code: "TOL-3" },
	{ value: "Tarancón, ES", label: "Tarancón, ES", code: "TRR-E" },
	{ value: "Baiona, ES", label: "Baiona, ES", code: "VGO70" },
	{ value: "Cambados, ES", label: "Cambados, ES", code: "VGO80" },
	{ value: "Tui, ES", label: "Tui, ES", code: "VGO85" },
	{
		value: "Puerto del Carmen, Lanzarote, ES",
		label: "Puerto del Carmen, Lanzarote, ES",
		code: "ACE-40",
	},
	{
		value: "Playa Blanca, Lanzarote, ES",
		label: "Playa Blanca, Lanzarote, ES",
		code: "ACE-50",
	},
	{ value: "Torremolinos, ES", label: "Torremolinos, ES", code: "AGP-10" },
	{ value: "Benalmádena, ES", label: "Benalmádena, ES", code: "AGP-15" },
	{ value: "Fuengirola, ES", label: "Fuengirola, ES", code: "AGP-20" },
	{ value: "Marbella, ES", label: "Marbella, ES", code: "AGP-30" },
	{ value: "Nerja, ES", label: "Nerja, ES", code: "AGP-45" },
	{ value: "Estepona, ES", label: "Estepona, ES", code: "AGP-50" },
	{ value: "Torrevieja, ES", label: "Torrevieja, ES", code: "ALC-10" },
	{ value: "Santa Pola, ES", label: "Santa Pola, ES", code: "ALC-15" },
	{ value: "Javea, ES", label: "Javea, ES", code: "ALC-20" },
	{ value: "Denia, ES", label: "Denia, ES", code: "ALC-24" },
	{ value: "Calpe, ES", label: "Calpe, ES", code: "ALC-57" },
	{ value: "Jaca , ES", label: "Jaca , ES", code: "ARA-10" },
	{ value: "Ainsa, ES", label: "Ainsa, ES", code: "ARA-26" },
	{ value: "Alquézar, ES", label: "Alquézar, ES", code: "ARA-39" },
	{
		value: "San Juan de Gredos, ES",
		label: "San Juan de Gredos, ES",
		code: "AVA-20",
	},
	{ value: "Anento, ES", label: "Anento, ES", code: "Anento" },
	{
		value: "Hospitalet de Llobregat, ES",
		label: "Hospitalet de Llobregat, ES",
		code: "BCN-11",
	},
	{ value: "La Garriga, ES", label: "La Garriga, ES", code: "BCN-15" },
	{
		value: "Barberá del Vallés, ES",
		label: "Barberá del Vallés, ES",
		code: "BCN-17",
	},
	{ value: "Terrasa, ES", label: "Terrasa, ES", code: "BCN-19" },
	{ value: "Manresa, ES", label: "Manresa, ES", code: "BCN-62" },
	{ value: "Benidorm, ES", label: "Benidorm, ES", code: "BEN-60" },
	{ value: "Portugalete, ES", label: "Portugalete, ES", code: "BIO-20" },
	{ value: "Don Benito, ES", label: "Don Benito, ES", code: "BJZ-36" },
	{
		value: "Aranda de Duero, ES",
		label: "Aranda de Duero, ES",
		code: "BRG-60",
	},
	{
		value: "Castellón de la Plana, ES",
		label: "Castellón de la Plana, ES",
		code: "CAS-99",
	},
	{ value: "Benicasim, ES", label: "Benicasim, ES", code: "CAZ-40" },
	{ value: "Vinaroz, ES", label: "Vinaroz, ES", code: "CAZ-70" },
	{ value: "Cala Morell, ES", label: "Cala Morell, ES", code: "CML-45" },
	{ value: "Coca, ES", label: "Coca, ES", code: "COC-17" },
	{ value: "Trujillo, ES", label: "Trujillo, ES", code: "CRS-10" },
	{ value: "Plasencia, ES", label: "Plasencia, ES", code: "CRS-20" },
	{ value: "Guadalupe, ES", label: "Guadalupe, ES", code: "CRS-40" },
	{ value: "Almuñécar, ES", label: "Almuñécar, ES", code: "CTR-10" },
	{
		value: "Castro Urdiales, ES",
		label: "Castro Urdiales, ES",
		code: "CUR-11",
	},
	{ value: "Gandía, ES", label: "Gandía, ES", code: "CVA-10" },
	{ value: "El Saler, ES", label: "El Saler, ES", code: "CVA-90" },
	{ value: "Deba, ES", label: "Deba, ES", code: "DBA-91" },
	{ value: "Zarauz, ES", label: "Zarauz, ES", code: "EAS-40" },
	{
		value: "Es Migjorn Gran, ES",
		label: "Es Migjorn Gran, ES",
		code: "EMG-23",
	},
	{ value: "Ferreries, ES", label: "Ferreries, ES", code: "FRR-18" },
	{ value: "Corralejo, ES", label: "Corralejo, ES", code: "FUE-10" },
	{
		value: "Caleta de Fuste, ES",
		label: "Caleta de Fuste, ES",
		code: "FUE-24",
	},
	{ value: "Jandia, ES", label: "Jandia, ES", code: "FUE-30" },
	{ value: "Tarajalejo, ES", label: "Tarajalejo, ES", code: "FUE-50" },
	{ value: "Playa Esquinzo, ES", label: "Playa Esquinzo, ES", code: "FUE-60" },
	{ value: "San Antonio, ES", label: "San Antonio, ES", code: "IBZ-25" },
	{ value: "Es canar, ES", label: "Es canar, ES", code: "IBZ-60" },
	{ value: "Portinatx, ES", label: "Portinatx, ES", code: "IBZ-75" },
	{
		value: "Puerto de San Miguel, ES",
		label: "Puerto de San Miguel, ES",
		code: "IBZ-80",
	},
	{ value: "Santa Elena, ES", label: "Santa Elena, ES", code: "JEN-13" },
	{ value: "Mojácar, ES", label: "Mojácar, ES", code: "LEI-30" },
	{
		value: "Roquetas de Mar, ES",
		label: "Roquetas de Mar, ES",
		code: "LEI-40",
	},
	{ value: "Ponferrada, ES", label: "Ponferrada, ES", code: "LEO-10" },
	{ value: "Ribadeo, ES", label: "Ribadeo, ES", code: "LGO-20" },
	{ value: "Malgrat de Mar, ES", label: "Malgrat de Mar, ES", code: "LLM-10" },
	{ value: "Santa Susanna, ES", label: "Santa Susanna, ES", code: "LLM-15" },
	{ value: "Calella, ES", label: "Calella, ES", code: "LLM-25" },
	{ value: "Llafranc, ES", label: "Llafranc, ES", code: "LLM-46" },
	{ value: "Roses, ES", label: "Roses, ES", code: "LLM-65" },
	{ value: "Lloret de Mar, ES", label: "Lloret de Mar, ES", code: "LLM-99" },
	{ value: "Mazarrón, ES", label: "Mazarrón, ES", code: "LMN-40" },
	{
		value: "Las Palmas de Gran Canaria, Gran Canaria, ES",
		label: "Las Palmas de Gran Canaria, Gran Canaria, ES",
		code: "LPA-10",
	},
	{
		value: "Playa del Ingles, Gran Canaria, ES",
		label: "Playa del Ingles, Gran Canaria, ES",
		code: "LPA-20",
	},
	{
		value: "Maspalomas, Gran Canaria, ES",
		label: "Maspalomas, Gran Canaria, ES",
		code: "LPA-25",
	},
	{ value: "Patalavaca, ES", label: "Patalavaca, ES", code: "LPA-50" },
	{
		value: "Puerto Rico , Gran Canaria, ES",
		label: "Puerto Rico , Gran Canaria, ES",
		code: "LPA-60",
	},
	{ value: "Isla Canela, ES", label: "Isla Canela, ES", code: "LUS-10" },
	{ value: "Isla Cristina, ES", label: "Isla Cristina, ES", code: "LUS-15" },
	{ value: "Matalascañas, ES", label: "Matalascañas, ES", code: "LUS-25" },
	{ value: "Punta Umbría, ES", label: "Punta Umbría, ES", code: "LUS-30" },
	{ value: "Chiclana, ES", label: "Chiclana, ES", code: "LUZ-15" },
	{ value: "Chipiona, ES", label: "Chipiona, ES", code: "LUZ-20" },
	{
		value: "Conil de la Frontera, ES",
		label: "Conil de la Frontera, ES",
		code: "LUZ-25",
	},
	{
		value: "El Puerto de Santa Maria, ES",
		label: "El Puerto de Santa Maria, ES",
		code: "LUZ-35",
	},
	{ value: "Rota, ES", label: "Rota, ES", code: "LUZ-40" },
	{ value: "San Fernando, ES", label: "San Fernando, ES", code: "LUZ-45" },
	{
		value: "Zahara de los Atunes, ES",
		label: "Zahara de los Atunes, ES",
		code: "LUZ-50",
	},
	{
		value: "Pozuelo de Alarcón, ES",
		label: "Pozuelo de Alarcón, ES",
		code: "MAD-31",
	},
	{ value: "Mahón,Menorca, ES", label: "Mahón,Menorca, ES", code: "MAH-10" },
	{
		value: "Alcaufar, Menorca, ES",
		label: "Alcaufar, Menorca, ES",
		code: "MAH-13",
	},
	{ value: "Binibeca, ES", label: "Binibeca, ES", code: "MAH-15" },
	{ value: "Cala en Porter, ES", label: "Cala en Porter, ES", code: "MAH-16" },
	{
		value: "Arenal d&apos;en Castell, ES",
		label: "Arenal d&apos;en Castell, ES",
		code: "MAH-17",
	},
	{ value: "Cala Blanca, ES", label: "Cala Blanca, ES", code: "MAH-21" },
	{
		value: "Cala Santandria, ES",
		label: "Cala Santandria, ES",
		code: "MAH-23",
	},
	{
		value: "Son Parc, Menorca, ES",
		label: "Son Parc, Menorca, ES",
		code: "MAH-24",
	},
	{ value: "Cala en Bosch, ES", label: "Cala en Bosch, ES", code: "MAH-25" },
	{
		value: "Fornells, Menorca, ES",
		label: "Fornells, Menorca, ES",
		code: "MAH-28",
	},
	{
		value: "Biniancolla, Menorca, ES",
		label: "Biniancolla, Menorca, ES",
		code: "MAH-44",
	},
	{
		value: "Son Bou,Menorca, ES",
		label: "Son Bou,Menorca, ES",
		code: "MAH-50",
	},
	{
		value: "Santo Tomas, Menorca, ES",
		label: "Santo Tomas, Menorca, ES",
		code: "MAH-60",
	},
	{ value: "Cala Galdana, ES", label: "Cala Galdana, ES", code: "MAH-70" },
	{ value: "Antequera, ES", label: "Antequera, ES", code: "MAL-40" },
	{ value: "Ronda, ES", label: "Ronda, ES", code: "MAL-60" },
	{ value: "Es Mercadal, ES", label: "Es Mercadal, ES", code: "MER-23" },
	{ value: "Cartagena, ES", label: "Cartagena, ES", code: "MJV-20" },
	{ value: "Puente Genil, ES", label: "Puente Genil, ES", code: "ODB-13" },
	{ value: "Verin, ES", label: "Verin, ES", code: "ORE-20" },
	{ value: "Nueva, ES", label: "Nueva, ES", code: "OVD-12" },
	{ value: "Gijón, ES", label: "Gijón, ES", code: "OVD-20" },
	{ value: "Cangas de Onís, ES", label: "Cangas de Onís, ES", code: "OVD-30" },
	{ value: "Llanes, ES", label: "Llanes, ES", code: "OVD-60" },
	{ value: "Perlora, ES", label: "Perlora, ES", code: "OVD-90" },
	{
		value: "La Pobla de Lillet, ES",
		label: "La Pobla de Lillet, ES",
		code: "PIR-15",
	},
	{ value: "Àreu, ES", label: "Àreu, ES", code: "PIR-31" },
	{ value: "Tavascán, ES", label: "Tavascán, ES", code: "PIR-32" },
	{
		value: "Puerto de Andratx, ES",
		label: "Puerto de Andratx, ES",
		code: "PTA-91",
	},
	{ value: "Salou, ES", label: "Salou, ES", code: "SAL-10" },
	{ value: "El Vendrell, ES", label: "El Vendrell, ES", code: "SAL-18" },
	{
		value: "Móra d&apos;Ebre, ES",
		label: "Móra d&apos;Ebre, ES",
		code: "SAL-35",
	},
	{
		value: "Hospitalet del Infant, ES",
		label: "Hospitalet del Infant, ES",
		code: "SAL-45",
	},
	{ value: "Reus , ES", label: "Reus , ES", code: "SAL-49" },
	{ value: "Tortosa, ES", label: "Tortosa, ES", code: "SAL-90" },
	{ value: "Ferrol, ES", label: "Ferrol, ES", code: "SCQ-30" },
	{ value: "Alceda, ES", label: "Alceda, ES", code: "SDR-28" },
	{ value: "Castelldefels, ES", label: "Castelldefels, ES", code: "STS-25" },
	{ value: "Albarracín, ES", label: "Albarracín, ES", code: "TER-40" },
	{
		value: "Puerto de la Cruz, ES",
		label: "Puerto de la Cruz, ES",
		code: "TFS-11",
	},
	{ value: "Las Caletillas, ES", label: "Las Caletillas, ES", code: "TFS-14" },
	{ value: "Callao Salvaje, ES", label: "Callao Salvaje, ES", code: "TFS-15" },
	{ value: "El Médano, ES", label: "El Médano, ES", code: "TFS-16" },
	{ value: "Los Cristianos, ES", label: "Los Cristianos, ES", code: "TFS-20" },
	{ value: "Golf del Sur, ES", label: "Golf del Sur, ES", code: "TFS-54" },
	{
		value: "San Miguel de Abona, ES",
		label: "San Miguel de Abona, ES",
		code: "TFS-56",
	},
	{
		value: "Buenavista del Norte, ES",
		label: "Buenavista del Norte, ES",
		code: "TFS-57",
	},
	{ value: "Costa Adeje, ES", label: "Costa Adeje, ES", code: "TFS-84" },
	{ value: "Guía de Isora, ES", label: "Guía de Isora, ES", code: "TFS-85" },
	{
		value: "Puerto de Santiago, ES",
		label: "Puerto de Santiago, ES",
		code: "TFS-90",
	},
	{
		value: "Talavera de la Reina, ES",
		label: "Talavera de la Reina, ES",
		code: "TOL-10",
	},
	{ value: "Sanxenxo, ES", label: "Sanxenxo, ES", code: "VGO-10" },
	{ value: "Vigo, ES", label: "Vigo, ES", code: "VIG-99" },
	{ value: "Requena, ES", label: "Requena, ES", code: "VLC-13" },
	{ value: "Tordesillas, ES", label: "Tordesillas, ES", code: "VLL-20" },
	{ value: "Benavente, ES", label: "Benavente, ES", code: "ZAM-20" },
	{
		value: "Puebla de Sanabria, ES",
		label: "Puebla de Sanabria, ES",
		code: "ZAM-30",
	},
	{ value: "Calatayud, ES", label: "Calatayud, ES", code: "ZAZ-10" },
	{ value: "Menorca (Isla), ES", label: "Menorca (Isla), ES", code: "fake44" },
	{ value: "Cardona, ES", label: "Cardona, ES", code: "Cardona" },
	{ value: "Morella, ES", label: "Morella, ES", code: "MOR-CAS" },
	{ value: "Morella, ES", label: "Morella, ES", code: "Morella" },
	{
		value: "Salinas de Pisuerga, ES",
		label: "Salinas de Pisuerga, ES",
		code: "fake-47",
	},
	{
		value: "Sant Sadurní d&apos;Anoia, ES",
		label: "Sant Sadurní d&apos;Anoia, ES",
		code: "sadurni",
	},
	{
		value: "Bellmunt del Priorat, ES",
		label: "Bellmunt del Priorat, ES",
		code: "Bellmont",
	},
	{
		value: "Puerto Calero, Lanzarote, ES",
		label: "Puerto Calero, Lanzarote, ES",
		code: "FAke-148",
	},
	{ value: "Igualada, ES", label: "Igualada, ES", code: "Igualada" },
	{
		value: "Sur de Mallorca, ES",
		label: "Sur de Mallorca, ES",
		code: "PMISOUTH",
	},
	{
		value: "Oeste de Mallorca, ES",
		label: "Oeste de Mallorca, ES",
		code: "PMIoeste",
	},
	{ value: "Cadaqués, ES", label: "Cadaqués, ES", code: "RESTELCA" },
	{
		value: "Caleta de Velez, ES",
		label: "Caleta de Velez, ES",
		code: "fake-598",
	},
	{ value: "Orihuela, ES", label: "Orihuela, ES", code: "fake-599" },
	{
		value: "Vandellós y Hospitalet del Infante, ES",
		label: "Vandellós y Hospitalet del Infante, ES",
		code: "Vandellos",
	},
	{
		value: "Castell de Guadalest, ES",
		label: "Castell de Guadalest, ES",
		code: "guadalest",
	},
	{ value: "Vilafamés, ES", label: "Vilafamés, ES", code: "vilafames" },
	{ value: "Capellades, ES", label: "Capellades, ES", code: "Capellades" },
	{ value: "Valderrobes, ES", label: "Valderrobes, ES", code: "Valderrobes" },
	{
		value: "Palau-solità i Plegamans, ES",
		label: "Palau-solità i Plegamans, ES",
		code: "palausolita",
	},
	{
		value: "Puertomingalvo, ES",
		label: "Puertomingalvo, ES",
		code: "Puertomingalvo",
	},
	{
		value: "El castell de Guadalest, ES",
		label: "El castell de Guadalest, ES",
		code: "CastellGuadalest",
	},
	{
		value: "Mataró, ES",
		label: "Mataró, ES",
		code: "____NEW____1464088330860",
	},
	{
		value: "Blanes, ES",
		label: "Blanes, ES",
		code: "____NEW____1465393980499",
	},
	{ value: "Caseres, ES", label: "Caseres, ES", code: "342" },
	{
		value: "Cuevas de Almanzora, ES",
		label: "Cuevas de Almanzora, ES",
		code: "567",
	},
	{ value: "Alfaro, ES", label: "Alfaro, ES", code: "ALF" },
	{ value: "Baeza, ES", label: "Baeza, ES", code: "BAE" },
	{ value: "Torre Del Mar, ES", label: "Torre Del Mar, ES", code: "TDM" },
	{ value: "Alalpardo, ES", label: "Alalpardo, ES", code: "tre" },
	{
		value: "Montroig del Camp, ES",
		label: "Montroig del Camp, ES",
		code: "tyr",
	},
	{ value: "Vallromanes, ES", label: "Vallromanes, ES", code: "ytr" },
	{ value: "Formigal, ES", label: "Formigal, ES", code: "ARA2" },
	{ value: "Candanchu, ES", label: "Candanchu, ES", code: "ARA3" },
	{ value: "Canfranc, ES", label: "Canfranc, ES", code: "ARA8" },
	{ value: "LLivia, ES", label: "LLivia, ES", code: "CAS2" },
	{ value: "Eibar, ES", label: "Eibar, ES", code: "EAS5" },
	{ value: "Lozoya, ES", label: "Lozoya, ES", code: "SEG4" },
	{ value: "Carmona, ES", label: "Carmona, ES", code: "SVQ5" },
	{
		value: "Vilagarcia de Arousa, ES",
		label: "Vilagarcia de Arousa, ES",
		code: "VGO2",
	},
	{ value: "Sástago, ES", label: "Sástago, ES", code: "ZAZ7" },
	{ value: "Villajoyosa, ES", label: "Villajoyosa, ES", code: "ALC-1" },
	{
		value: "Pirineo Aragones, ES",
		label: "Pirineo Aragones, ES",
		code: "ARA-1",
	},
	{ value: "Cerler, ES", label: "Cerler, ES", code: "ARA15" },
	{ value: "Biescas, ES", label: "Biescas, ES", code: "ARA19" },
	{ value: "Sabiñánigo , ES", label: "Sabiñánigo , ES", code: "ARA21" },
	{ value: "Panticosa, ES", label: "Panticosa, ES", code: "ARA25" },
	{ value: "Rubí, ES", label: "Rubí, ES", code: "BCN18" },
	{ value: "Getxo, ES", label: "Getxo, ES", code: "BIO-3" },
	{ value: "Mundaka, ES", label: "Mundaka, ES", code: "BIO-7" },
	{
		value: "Castelló d&apos;Empúries , ES",
		label: "Castelló d&apos;Empúries , ES",
		code: "CASTE",
	},
	{ value: "Alarcon, ES", label: "Alarcon, ES", code: "CCA50" },
	{ value: "Cudillero, ES", label: "Cudillero, ES", code: "CDI-2" },
	{ value: "Camp de Mar, ES", label: "Camp de Mar, ES", code: "CDM-1" },
	{
		value: "Costa de los Pinos, ES",
		label: "Costa de los Pinos, ES",
		code: "CDP-1",
	},
	{ value: "Cedeira, ES", label: "Cedeira, ES", code: "CDR-6" },
	{ value: "Cala Figuera, ES", label: "Cala Figuera, ES", code: "CLF-1" },
	{ value: "Daimiel, ES", label: "Daimiel, ES", code: "CRE50" },
	{
		value: "Cala San Vicente, ES",
		label: "Cala San Vicente, ES",
		code: "CSV-3",
	},
	{ value: "Estellencs, ES", label: "Estellencs, ES", code: "EST-1" },
	{ value: "Fuencaliente, ES", label: "Fuencaliente, ES", code: "FNC-2" },
	{ value: "Fornalutx, ES", label: "Fornalutx, ES", code: "FTX-1" },
	{ value: "El Cotillo, ES", label: "El Cotillo, ES", code: "FUE-1" },
	{ value: "La Pared, ES", label: "La Pared, ES", code: "FUE-2" },
	{ value: "Figueres, ES", label: "Figueres, ES", code: "GRO70" },
	{ value: "Guadix, ES", label: "Guadix, ES", code: "GRX10" },
	{ value: "Haría, ES", label: "Haría, ES", code: "HRI-2" },
	{ value: "Inca, ES", label: "Inca, ES", code: "INC-1" },
	{ value: "Cazorla, ES", label: "Cazorla, ES", code: "JEN-3" },
	{ value: "Los Cancajos, ES", label: "Los Cancajos, ES", code: "LCJ-2" },
	{ value: "Viveiro, ES", label: "Viveiro, ES", code: "LGO50" },
	{ value: "Palamos, ES", label: "Palamos, ES", code: "LLM-6" },
	{ value: "Areyns de Mar, ES", label: "Areyns de Mar, ES", code: "LLM21" },
	{ value: "San Pol de Mar, ES", label: "San Pol de Mar, ES", code: "LLM25" },
	{ value: "San Javier, ES", label: "San Javier, ES", code: "LMN80" },
	{ value: "Mazagon, ES", label: "Mazagon, ES", code: "LUS35" },
	{ value: "Sotogrande, ES", label: "Sotogrande, ES", code: "LUZ-8" },
	{ value: "Tres cantos, ES", label: "Tres cantos, ES", code: "MAD-6" },
	{
		value: "Collado Villalba, ES",
		label: "Collado Villalba, ES",
		code: "MAD45",
	},
	{ value: "Valdemoro, ES", label: "Valdemoro, ES", code: "MAD62" },
	{ value: "Archena, ES", label: "Archena, ES", code: "MJV70" },
	{ value: "Montilla, ES", label: "Montilla, ES", code: "ODB50" },
	{ value: "La Oliva, ES", label: "La Oliva, ES", code: "OLV-2" },
	{ value: "Paguera, ES", label: "Paguera, ES", code: "PGA-1" },
	{ value: "La Masella, ES", label: "La Masella, ES", code: "PIR21" },
	{ value: "Llavorsí , ES", label: "Llavorsí , ES", code: "PIR26" },
	{ value: "Port Ainé, ES", label: "Port Ainé, ES", code: "PIR27" },
	{ value: "Las Playitas, ES", label: "Las Playitas, ES", code: "PLY-1" },
	{ value: "Can Pastilla, ES", label: "Can Pastilla, ES", code: "PMI-4" },
	{ value: "Las Maravillas, ES", label: "Las Maravillas, ES", code: "PMI-6" },
	{ value: "Ses Cadenes, ES", label: "Ses Cadenes, ES", code: "PMI-7" },
	{ value: "Felanitx, ES", label: "Felanitx, ES", code: "PMI-9" },
	{ value: "Son Servera, ES", label: "Son Servera, ES", code: "PMI88" },
	{ value: "Porto Colom, ES", label: "Porto Colom, ES", code: "PRC-1" },
	{
		value: "Playa de Santiago, ES",
		label: "Playa de Santiago, ES",
		code: "PSC-2",
	},
	{
		value: "San Sebastián de La Gomera, ES",
		label: "San Sebastián de La Gomera, ES",
		code: "QGZ-1",
	},
	{ value: "Ojedo, ES", label: "Ojedo, ES", code: "SDR42" },
	{ value: "Sant Elm, ES", label: "Sant Elm, ES", code: "SLM-1" },
	{ value: "Santa Catalina, ES", label: "Santa Catalina, ES", code: "STC-2" },
	{
		value: "Iglesuela del Cid, ES",
		label: "Iglesuela del Cid, ES",
		code: "TER-4",
	},
	{ value: "Ocaña, ES", label: "Ocaña, ES", code: "TOL-1" },
	{ value: "Valle Gran Rey, ES", label: "Valle Gran Rey, ES", code: "VGY-2" },
	{ value: "Álora, ES", label: "Álora, ES", code: "AGP-52" },
	{
		value: "Guardamar del Segura, ES",
		label: "Guardamar del Segura, ES",
		code: "ALC-30",
	},
	{ value: "Elda, ES", label: "Elda, ES", code: "ALC-52" },
	{ value: "Elche , ES", label: "Elche , ES", code: "ALC-80" },
	{ value: "Manlleu, ES", label: "Manlleu, ES", code: "BCN-90" },
	{ value: "Santurce, ES", label: "Santurce, ES", code: "BIO-30" },
	{
		value: "Jerez de los Caballeros, ES",
		label: "Jerez de los Caballeros, ES",
		code: "BJZ-52",
	},
	{
		value: "Miranda de Ebro, ES",
		label: "Miranda de Ebro, ES",
		code: "BRG-40",
	},
	{ value: "San Roque, ES", label: "San Roque, ES", code: "CAD-11" },
	{ value: "Villarreal, ES", label: "Villarreal, ES", code: "CAS-52" },
	{ value: "Peñiscola, ES", label: "Peñiscola, ES", code: "CAZ-10" },
	{ value: "Canyamel, ES", label: "Canyamel, ES", code: "CNY-23" },
	{
		value: "Carmona, Cantabria, ES",
		label: "Carmona, Cantabria, ES",
		code: "CRM-11",
	},
	{ value: "Motril, ES", label: "Motril, ES", code: "CTR-20" },
	{ value: "Cullera, ES", label: "Cullera, ES", code: "CVA-50" },
	{ value: "Foz, ES", label: "Foz, ES", code: "FOZ-21" },
	{ value: "Costa Calma, ES", label: "Costa Calma, ES", code: "FUE-40" },
	{ value: "Humera, ES", label: "Humera, ES", code: "HUM-23" },
	{ value: "Linares, ES", label: "Linares, ES", code: "JEN-50" },
	{ value: "Andújar, ES", label: "Andújar, ES", code: "JEN-60" },
	{ value: "Bailén, ES", label: "Bailén, ES", code: "JEN-80" },
	{ value: "San José , ES", label: "San José , ES", code: "LEI-12" },
	{ value: "Vera, ES", label: "Vera, ES", code: "LEI-50" },
	{ value: "Astorga, ES", label: "Astorga, ES", code: "LEO-20" },
	{ value: "Tossa de Mar, ES", label: "Tossa de Mar, ES", code: "LLM-30" },
	{
		value: "Platja d&apos;Aro, ES",
		label: "Platja d&apos;Aro, ES",
		code: "LLM-40",
	},
	{
		value: "Torroella de Montgri, ES",
		label: "Torroella de Montgri, ES",
		code: "LLM41b",
	},
	{ value: "Los Alcázares, ES", label: "Los Alcázares, ES", code: "LMN-60" },
	{ value: "Lanjarón, ES", label: "Lanjarón, ES", code: "LNJ-23" },
	{ value: "Haro , ES", label: "Haro , ES", code: "LOG-20" },
	{ value: "Agaete, ES", label: "Agaete, ES", code: "LPA-11" },
	{
		value: "San Bartolomé de Tirajana, ES",
		label: "San Bartolomé de Tirajana, ES",
		code: "LPA-17",
	},
	{ value: "Mogán, ES", label: "Mogán, ES", code: "LPA-80" },
	{ value: "Islantilla, ES", label: "Islantilla, ES", code: "LUS-20" },
	{ value: "Aranjuez, ES", label: "Aranjuez, ES", code: "MAD-21" },
	{ value: "Ribadesella, ES", label: "Ribadesella, ES", code: "OVD-17" },
	{ value: "Launco, ES", label: "Launco, ES", code: "OVD-40" },
	{ value: "Mieres, ES", label: "Mieres, ES", code: "OVD-45" },
	{ value: "Boi, ES", label: "Boi, ES", code: "PIR-20" },
	{ value: "Las Salinas, ES", label: "Las Salinas, ES", code: "PMI-13" },
	{
		value: "Cala Mesquida, Mallorca, ES",
		label: "Cala Mesquida, Mallorca, ES",
		code: "PMI-23",
	},
	{
		value: "Palmanova, Mallorca, ES",
		label: "Palmanova, Mallorca, ES",
		code: "PMI-29",
	},
	{ value: "Magaluf, ES", label: "Magaluf, ES", code: "PMI-30" },
	{
		value: "Santa Ponsa, Mallorca, ES",
		label: "Santa Ponsa, Mallorca, ES",
		code: "PMI-31",
	},
	{ value: "Valldemosa , ES", label: "Valldemosa , ES", code: "PMI-34" },
	{
		value: "Porto cristo, Mallorca, ES",
		label: "Porto cristo, Mallorca, ES",
		code: "PMI-36",
	},
	{ value: "Portocolom, ES", label: "Portocolom, ES", code: "PMI-37" },
	{
		value: "Can Picafort, Mallorca, ES",
		label: "Can Picafort, Mallorca, ES",
		code: "PMI-39",
	},
	{ value: "Alcudia, ES", label: "Alcudia, ES", code: "PMI-40" },
	{ value: "Campos, ES", label: "Campos, ES", code: "PMI-42" },
	{
		value: "Andratx, Mallorca, ES",
		label: "Andratx, Mallorca, ES",
		code: "PMI-50",
	},
	{ value: "Portals Nous, ES", label: "Portals Nous, ES", code: "PMI-51" },
	{ value: "Bañalbufar , ES", label: "Bañalbufar , ES", code: "PMI-55" },
	{ value: "Santanyí, ES", label: "Santanyí, ES", code: "PMI-69" },
	{ value: "S&apos;Illot, ES", label: "S&apos;Illot, ES", code: "PMI-73" },
	{
		value: "El Arenal, Mallorca, ES",
		label: "El Arenal, Mallorca, ES",
		code: "PMI-75",
	},
	{
		value: "Playa de Muro, Mallorca, ES",
		label: "Playa de Muro, Mallorca, ES",
		code: "PMI-76",
	},
	{ value: "Pollensa, ES", label: "Pollensa, ES", code: "PMI-77" },
	{ value: "Deià, ES", label: "Deià, ES", code: "PMI-81" },
	{ value: "Sóller, ES", label: "Sóller, ES", code: "PMI-82" },
	{
		value: "Colonia de Sant Jordi, Mallorca, ES",
		label: "Colonia de Sant Jordi, Mallorca, ES",
		code: "PMI-83",
	},
	{
		value: "Calas de Mallorca, ES",
		label: "Calas de Mallorca, ES",
		code: "PMI-84",
	},
	{ value: "Cala Millor, ES", label: "Cala Millor, ES", code: "PMI-85" },
	{ value: "Cala Ratjada, ES", label: "Cala Ratjada, ES", code: "PMI-87" },
	{ value: "Cala d&apos;Or, ES", label: "Cala d&apos;Or, ES", code: "PMI-90" },
	{ value: "Camp de mar, ES", label: "Camp de mar, ES", code: "PMI-91" },
	{ value: "Porto Petro, ES", label: "Porto Petro, ES", code: "PMI-92" },
	{ value: "Tudela, ES", label: "Tudela, ES", code: "PNA-10" },
	{ value: "Valls, ES", label: "Valls, ES", code: "SAL-12" },
	{ value: "Cunit, ES", label: "Cunit, ES", code: "SAL-32" },
	{ value: "L&apos;Ampolla, ES", label: "L&apos;Ampolla, ES", code: "SAL-48" },
	{ value: "Noja, ES", label: "Noja, ES", code: "SDR-53" },
	{ value: "Covas, ES", label: "Covas, ES", code: "VIV-21" },
	{ value: "Oeste Mallorca, ES", label: "Oeste Mallorca, ES", code: "pmi-12" },
	{ value: "Larrede, ES", label: "Larrede, ES", code: "123-LAR" },
	{ value: "Bellaterra, ES", label: "Bellaterra, ES", code: "BEL-123" },
	{ value: "Finisterre, ES", label: "Finisterre, ES", code: "FNTRR-6" },
	{ value: "Lerma, ES", label: "Lerma, ES", code: "LER-123" },
	{ value: "manilva, ES", label: "manilva, ES", code: "MAN-123" },
	{
		value: "Puerto de Alcudia, Mallorca, ES",
		label: "Puerto de Alcudia, Mallorca, ES",
		code: "PUE-123",
	},
	{ value: "Salobreña, ES", label: "Salobreña, ES", code: "SALOB-6" },
	{ value: "Loja, ES", label: "Loja, ES", code: "fake-45" },
	{ value: "Los gigantes, ES", label: "Los gigantes, ES", code: "Fake-257" },
	{
		value: "Capdepera, Mallorca, ES",
		label: "Capdepera, Mallorca, ES",
		code: "fake-116",
	},
	{ value: "Alcocéber, ES", label: "Alcocéber, ES", code: "fake-117" },
	{ value: "Cabo de Palos, ES", label: "Cabo de Palos, ES", code: "fake-131" },
	{
		value: "Corbera de Llobregat, ES",
		label: "Corbera de Llobregat, ES",
		code: "fake-152",
	},
	{ value: "Miajadas, ES", label: "Miajadas, ES", code: "fake-153" },
	{ value: "Los Belones, ES", label: "Los Belones, ES", code: "fake-319" },
	{ value: "Vall de Nuria, ES", label: "Vall de Nuria, ES", code: "fake-379" },
	{ value: "Chinchón, ES", label: "Chinchón, ES", code: "fake-424" },
	{ value: "Mogro, ES", label: "Mogro, ES", code: "fake-596" },
	{
		value: "Monforte del Cid, ES",
		label: "Monforte del Cid, ES",
		code: "fake-597",
	},
	{ value: "Mutxamel, ES", label: "Mutxamel, ES", code: "fake-600" },
	{ value: "Navata, ES", label: "Navata, ES", code: "fake-601" },
	{ value: "Talayuela, ES", label: "Talayuela, ES", code: "fake-602" },
	{
		value: "Santa Cruz de La Palma, ES",
		label: "Santa Cruz de La Palma, ES",
		code: "fake-629",
	},
	{ value: "Morro Jable, ES", label: "Morro Jable, ES", code: "fake-630" },
	{
		value: "Sant Esteve Sesrovires, ES",
		label: "Sant Esteve Sesrovires, ES",
		code: "fake-641",
	},
	{ value: "El Brull, ES", label: "El Brull, ES", code: "fake-642" },
	{
		value: "Pilar de la Horadada, ES",
		label: "Pilar de la Horadada, ES",
		code: "fake-646",
	},
	{
		value: "San Vicente de la Barquera, ES",
		label: "San Vicente de la Barquera, ES",
		code: "fake-656",
	},
	{ value: "Astun, ES", label: "Astun, ES", code: "FAKEAstun" },
	{ value: "Culla, ES", label: "Culla, ES", code: "FAKEculla" },
	{
		value: "Medina de Rioseco, ES",
		label: "Medina de Rioseco, ES",
		code: "VLLmedina",
	},
	{
		value: "Sa Coma, Mallorca, ES",
		label: "Sa Coma, Mallorca, ES",
		code: "____NEW____1463570728162",
	},
	{
		value: "Palacios de la Valduerna, ES",
		label: "Palacios de la Valduerna, ES",
		code: "PV",
	},
	{ value: "Archidona, ES", label: "Archidona, ES", code: "ACN" },
	{ value: "Santa Margalida, ES", label: "Santa Margalida, ES", code: "MAR" },
	{
		value: "La Parra (Badajoz), ES",
		label: "La Parra (Badajoz), ES",
		code: "PRR",
	},
	{ value: "Sober (Lugo), ES", label: "Sober (Lugo), ES", code: "SBR" },
	{ value: "Sigüenza, ES", label: "Sigüenza, ES", code: "SIG" },
	{
		value: "Rincón de la Victoria, ES",
		label: "Rincón de la Victoria, ES",
		code: "AGP3",
	},
	{ value: "Torrox, ES", label: "Torrox, ES", code: "AGP9" },
	{
		value: "Albacete (Provincia), ES",
		label: "Albacete (Provincia), ES",
		code: "ALBC",
	},
	{
		value: "Cáceres (Provincia), ES",
		label: "Cáceres (Provincia), ES",
		code: "BJZ3",
	},
	{ value: "Besalú, ES", label: "Besalú, ES", code: "BSLU" },
	{
		value: "Castellón (Provincia), ES",
		label: "Castellón (Provincia), ES",
		code: "CDT2",
	},
	{
		value: "Ciudad Real (Provincia), ES",
		label: "Ciudad Real (Provincia), ES",
		code: "CQM2",
	},
	{
		value: "Gerona (Provincia), ES",
		label: "Gerona (Provincia), ES",
		code: "GRO3",
	},
	{
		value: "Almería (Provincia), ES",
		label: "Almería (Provincia), ES",
		code: "LEI2",
	},
	{
		value: "Córdoba (Provincia), ES",
		label: "Córdoba (Provincia), ES",
		code: "ODB3",
	},
	{ value: "Islas Baleares, ES", label: "Islas Baleares, ES", code: "PMI2" },
	{
		value: "Burgos (Provincia), ES",
		label: "Burgos (Provincia), ES",
		code: "RGS2",
	},
	{ value: "Chiva, ES", label: "Chiva, ES", code: "VLC5" },
	{
		value: "Cádiz (Provincia), ES",
		label: "Cádiz (Provincia), ES",
		code: "XRY3",
	},
	{
		value: "Barcelona (Provincia), ES",
		label: "Barcelona (Provincia), ES",
		code: "bcn6",
	},
	{
		value: "Badajoz (Provincia), ES",
		label: "Badajoz (Provincia), ES",
		code: "bjz2",
	},
	{ value: "test mallorca , ES", label: "test mallorca , ES", code: "test" },
	{ value: "Abanilla, ES", label: "Abanilla, ES", code: "ABN-1" },
	{ value: "Alcarrás, ES", label: "Alcarrás, ES", code: "ACR-1" },
	{
		value: "La Coruña (Provincia), ES",
		label: "La Coruña (Provincia), ES",
		code: "ACR-E",
	},
	{ value: "Aretxabaleta, ES", label: "Aretxabaleta, ES", code: "ACT-1" },
	{
		value: "Alcalá de Guadaíra, ES",
		label: "Alcalá de Guadaíra, ES",
		code: "ADG-1",
	},
	{
		value: "Azuqueca de Henares, ES",
		label: "Azuqueca de Henares, ES",
		code: "ADH-E",
	},
	{
		value: "Alhama de Murcia, ES",
		label: "Alhama de Murcia, ES",
		code: "ADM-E",
	},
	{ value: "Adra, ES", label: "Adra, ES", code: "ADR-1" },
	{ value: "Arganda del Rey, ES", label: "Arganda del Rey, ES", code: "ADR-E" },
	{
		value: "Alcázar de San Juan, ES",
		label: "Alcázar de San Juan, ES",
		code: "ADS-E",
	},
	{
		value: "Alhaurín de la Torre, ES",
		label: "Alhaurín de la Torre, ES",
		code: "ADT-1",
	},
	{ value: "Alfajarín, ES", label: "Alfajarín, ES", code: "AFJ-1" },
	{ value: "Aguadulce, ES", label: "Aguadulce, ES", code: "AGD-1" },
	{ value: "Argómaniz, ES", label: "Argómaniz, ES", code: "AGN-1" },
	{ value: "Algarrobo Costa, ES", label: "Algarrobo Costa, ES", code: "AGP14" },
	{ value: "Águilas, ES", label: "Águilas, ES", code: "AGU-E" },
	{ value: "Albox, ES", label: "Albox, ES", code: "ALB-2" },
	{ value: "Albatera, ES", label: "Albatera, ES", code: "ALB-E" },
	{ value: "Alcorcón, ES", label: "Alcorcón, ES", code: "ALC-E" },
	{ value: "Almendralejo, ES", label: "Almendralejo, ES", code: "ALM-E" },
	{ value: "Alcantarilla, ES", label: "Alcantarilla, ES", code: "ALR-E" },
	{ value: "Alcoy, ES", label: "Alcoy, ES", code: "ALY-E" },
	{ value: "Allariz, ES", label: "Allariz, ES", code: "ALZ-1" },
	{ value: "La Almunia, ES", label: "La Almunia, ES", code: "AMA-1" },
	{
		value: "Amorebieta-Echano, ES",
		label: "Amorebieta-Echano, ES",
		code: "AMB-1",
	},
	{ value: "Almoradí, ES", label: "Almoradí, ES", code: "AMI-1" },
	{ value: "Algemesí, ES", label: "Algemesí, ES", code: "AMI-E" },
	{ value: "Armilla, ES", label: "Armilla, ES", code: "AML-1" },
	{ value: "Amurrio, ES", label: "Amurrio, ES", code: "AMR-1" },
	{ value: "Almerimar, ES", label: "Almerimar, ES", code: "AMR-2" },
	{ value: "Ayamonte, ES", label: "Ayamonte, ES", code: "AMT-1" },
	{ value: "Arnedillo, ES", label: "Arnedillo, ES", code: "AND-1" },
	{ value: "Andújar, ES", label: "Andújar, ES", code: "AND-E" },
	{ value: "Andújar, ES", label: "Andújar, ES", code: "ANJ-E" },
	{ value: "Amposta, ES", label: "Amposta, ES", code: "APT-1" },
	{ value: "Azpeitia, ES", label: "Azpeitia, ES", code: "APT-2" },
	{ value: "Alcira, ES", label: "Alcira, ES", code: "ARA-E" },
	{ value: "Arévalo , ES", label: "Arévalo , ES", code: "ARE-E" },
	{ value: "Arahal, ES", label: "Arahal, ES", code: "ARH-1" },
	{ value: "Aracena , ES", label: "Aracena , ES", code: "ARN-1" },
	{
		value: "Arenas de San Pedro, ES",
		label: "Arenas de San Pedro, ES",
		code: "ASP-1",
	},
	{ value: "Aspe, ES", label: "Aspe, ES", code: "ASP-E" },
	{ value: "Atarfe, ES", label: "Atarfe, ES", code: "ATF-1" },
	{ value: "Alboraya, ES", label: "Alboraya, ES", code: "AYA-E" },
	{ value: "Beasain, ES", label: "Beasain, ES", code: "BAS-1" },
	{
		value: "Les Borges Blanques, ES",
		label: "Les Borges Blanques, ES",
		code: "BBL-1",
	},
	{
		value: "Boadilla del Monte, ES",
		label: "Boadilla del Monte, ES",
		code: "BDM-E",
	},
	{ value: "Benahavis, ES", label: "Benahavis, ES", code: "BHV-1" },
	{ value: "Bujalance, ES", label: "Bujalance, ES", code: "BJL-E" },
	{ value: "Balaguer, ES", label: "Balaguer, ES", code: "BLG-2" },
	{ value: "Bullas, ES", label: "Bullas, ES", code: "BLL-1" },
	{ value: "Benisa, ES", label: "Benisa, ES", code: "BNS-1" },
	{ value: "Baquio, ES", label: "Baquio, ES", code: "BQO-1" },
	{ value: "Briones, ES", label: "Briones, ES", code: "BRN-2" },
	{ value: "Basauri, ES", label: "Basauri, ES", code: "BSR-1" },
	{ value: "Betanzos, ES", label: "Betanzos, ES", code: "BTZ-1" },
	{ value: "Burjasot, ES", label: "Burjasot, ES", code: "BUJ-E" },
	{ value: "Buñol, ES", label: "Buñol, ES", code: "BUL-E" },
	{ value: "Cabra, ES", label: "Cabra, ES", code: "CAB-6" },
	{ value: "Calafell, ES", label: "Calafell, ES", code: "CAF-1" },
	{ value: "Calahonda, ES", label: "Calahonda, ES", code: "CAL-1" },
	{ value: "Campello, ES", label: "Campello, ES", code: "CAM-E" },
	{ value: "Casabermeja, ES", label: "Casabermeja, ES", code: "CBJ-1" },
	{ value: "Carballino, ES", label: "Carballino, ES", code: "CBL-1" },
	{
		value: "Carrascosa del Campo, ES",
		label: "Carrascosa del Campo, ES",
		code: "CDC-1",
	},
	{
		value: "Campo de Criptana, ES",
		label: "Campo de Criptana, ES",
		code: "CDC-E",
	},
	{ value: "Cudillero, ES", label: "Cudillero, ES", code: "CDL-1" },
	{ value: "Cervera, ES", label: "Cervera, ES", code: "CER-1" },
	{ value: "Cienpozuelos, ES", label: "Cienpozuelos, ES", code: "CIE-E" },
	{ value: "Cieza, ES", label: "Cieza, ES", code: "CIZ-E" },
	{ value: "Caldas , ES", label: "Caldas , ES", code: "CLD-1" },
	{
		value: "Cornellà de Llobregat, ES",
		label: "Cornellà de Llobregat, ES",
		code: "CLL-1",
	},
	{ value: "Calamocha, ES", label: "Calamocha, ES", code: "CLM-1" },
	{ value: "El Campello, ES", label: "El Campello, ES", code: "CMP-1" },
	{ value: "Cenicero, ES", label: "Cenicero, ES", code: "CNC-1" },
	{ value: "Coria, ES", label: "Coria, ES", code: "COR-E" },
	{ value: "Coslada, ES", label: "Coslada, ES", code: "COS-E" },
	{ value: "Campotéja, ES", label: "Campotéja, ES", code: "CPJ-1" },
	{ value: "Crevillente, ES", label: "Crevillente, ES", code: "CRV-1" },
	{ value: "Castalla, ES", label: "Castalla, ES", code: "CTA-E" },
	{ value: "Cártama, ES", label: "Cártama, ES", code: "CTM-1" },
	{ value: "Cocentaina, ES", label: "Cocentaina, ES", code: "CTN-1" },
	{ value: "Oliva, ES", label: "Oliva, ES", code: "CVA40" },
	{ value: "Dos Hermanas, ES", label: "Dos Hermanas, ES", code: "DHN-1" },
	{ value: "Dúrcal, ES", label: "Dúrcal, ES", code: "DRL-1" },
	{ value: "Elgoibar, ES", label: "Elgoibar, ES", code: "EGB-1" },
	{ value: "El Ejido, ES", label: "El Ejido, ES", code: "EGD-1" },
	{ value: "El Garrobo, ES", label: "El Garrobo, ES", code: "ELG-1" },
	{ value: "Elorriaga, ES", label: "Elorriaga, ES", code: "ELG-2" },
	{ value: "Elizondo, ES", label: "Elizondo, ES", code: "ELZ-1" },
	{ value: "Estella, ES", label: "Estella, ES", code: "ETA-1" },
	{ value: "Frigiliana , ES", label: "Frigiliana , ES", code: "FRG-1" },
	{ value: "Ginzo de Limia, ES", label: "Ginzo de Limia, ES", code: "GDL-1" },
	{ value: "Gandesa, ES", label: "Gandesa, ES", code: "GDS-1" },
	{ value: "Gijón, ES", label: "Gijón, ES", code: "GJN-1" },
	{ value: "Los Gallardos, ES", label: "Los Gallardos, ES", code: "GLD-1" },
	{ value: "Garrucha, ES", label: "Garrucha, ES", code: "GRC-1" },
	{ value: "Garralda, ES", label: "Garralda, ES", code: "GRRLD" },
	{ value: "Guevara, ES", label: "Guevara, ES", code: "GVR-1" },
	{
		value: "Huércal de Almería, ES",
		label: "Huércal de Almería, ES",
		code: "HDA-1",
	},
	{
		value: "Herrera del Duque , ES",
		label: "Herrera del Duque , ES",
		code: "HDD-E",
	},
	{ value: "Huétor Tájar, ES", label: "Huétor Tájar, ES", code: "HTJ-1" },
	{ value: "Ibi, ES", label: "Ibi, ES", code: "IBI-E" },
	{ value: "Ifach, ES", label: "Ifach, ES", code: "IFA-1" },
	{ value: "Irún, ES", label: "Irún, ES", code: "IRN-1" },
	{ value: "Irurzun, ES", label: "Irurzun, ES", code: "ITZ-1" },
	{ value: "Izarra, ES", label: "Izarra, ES", code: "IZR-1" },
	{ value: "Jávea, ES", label: "Jávea, ES", code: "JAV-E" },
	{ value: "Jabugo, ES", label: "Jabugo, ES", code: "JBG-1" },
	{ value: "Juneda, ES", label: "Juneda, ES", code: "JND-1" },
	{ value: "Jumilla, ES", label: "Jumilla, ES", code: "JUM-E" },
	{ value: "Luarca, ES", label: "Luarca, ES", code: "LAC-1" },
	{ value: "La Carolina , ES", label: "La Carolina , ES", code: "LAC-E" },
	{ value: "Luanco, ES", label: "Luanco, ES", code: "LAO-1" },
	{ value: "Lebrija, ES", label: "Lebrija, ES", code: "LBJ-1" },
	{ value: "La Cabrera, ES", label: "La Cabrera, ES", code: "LCB-1" },
	{ value: "Liérganes, ES", label: "Liérganes, ES", code: "LGN-1" },
	{ value: "Leganés , ES", label: "Leganés , ES", code: "LGN-E" },
	{ value: "Langreo, ES", label: "Langreo, ES", code: "LGO-1" },
	{ value: "La Herradura, ES", label: "La Herradura, ES", code: "LHR-1" },
	{
		value: "La Línea de la Concepción, ES",
		label: "La Línea de la Concepción, ES",
		code: "LLC-2",
	},
	{ value: "Lalín, ES", label: "Lalín, ES", code: "LLN-1" },
	{ value: "La Luisiana , ES", label: "La Luisiana , ES", code: "LLS-1" },
	{ value: "Lorca, ES", label: "Lorca, ES", code: "LOR-1" },
	{ value: "Lorca, ES", label: "Lorca, ES", code: "LOR-E" },
	{ value: "Lepe, ES", label: "Lepe, ES", code: "LPE-1" },
	{ value: "Lequeitio, ES", label: "Lequeitio, ES", code: "LQT-1" },
	{ value: "La Roda, ES", label: "La Roda, ES", code: "LRD-E" },
	{
		value: "Los Santos de Maimona, ES",
		label: "Los Santos de Maimona, ES",
		code: "LSM-E",
	},
	{
		value: "Lugo (Provincia), ES",
		label: "Lugo (Provincia), ES",
		code: "LUG-E",
	},
	{ value: "La Zubia , ES", label: "La Zubia , ES", code: "LZA-1" },
	{ value: "Lezama, ES", label: "Lezama, ES", code: "LZM-1" },
	{
		value: "San Lorenzo de El Escorial, ES",
		label: "San Lorenzo de El Escorial, ES",
		code: "MAD26",
	},
	{ value: "Vélez-Málaga, ES", label: "Vélez-Málaga, ES", code: "MAl11" },
	{ value: "Marchena, ES", label: "Marchena, ES", code: "MCN-1" },
	{ value: "Macarena, ES", label: "Macarena, ES", code: "MCR-2" },
	{ value: "Moncada, ES", label: "Moncada, ES", code: "MDA-E" },
	{
		value: "Medina del Campo, ES",
		label: "Medina del Campo, ES",
		code: "MDC-E",
	},
	{ value: "Mondragón, ES", label: "Mondragón, ES", code: "MDG-1" },
	{ value: "Madridejos, ES", label: "Madridejos, ES", code: "MDR-E" },
	{ value: "Medina Sidonia, ES", label: "Medina Sidonia, ES", code: "MDS-2" },
	{
		value: "Molina de Segura, ES",
		label: "Molina de Segura, ES",
		code: "MDV-E",
	},
	{ value: "Mungia, ES", label: "Mungia, ES", code: "MGA-1" },
	{ value: "Moguer, ES", label: "Moguer, ES", code: "MGR-1" },
	{ value: "Majadahonda, ES", label: "Majadahonda, ES", code: "MJD-E" },
	{ value: "Mollerusa, ES", label: "Mollerusa, ES", code: "MLR-1" },
	{
		value: "Monistrol de Montserrat, ES",
		label: "Monistrol de Montserrat, ES",
		code: "MNT-1",
	},
	{ value: "Mogente, ES", label: "Mogente, ES", code: "MOG-E" },
	{ value: "Montoro, ES", label: "Montoro, ES", code: "MON-E" },
	{ value: "Moratalla, ES", label: "Moratalla, ES", code: "MOR-E" },
	{ value: "Moraira, ES", label: "Moraira, ES", code: "MRR-1" },
	{ value: "Móstoles , ES", label: "Móstoles , ES", code: "MST-E" },
	{ value: "Montalbán, ES", label: "Montalbán, ES", code: "MTB-1" },
	{ value: "Montijo, ES", label: "Montijo, ES", code: "MTJ-E" },
	{ value: "Mataró, ES", label: "Mataró, ES", code: "MTR-1" },
	{ value: "La Muela, ES", label: "La Muela, ES", code: "MUA-1" },
	{ value: "Mula, ES", label: "Mula, ES", code: "MUL-E" },
	{ value: "Noáin, ES", label: "Noáin, ES", code: "NAI-1" },
	{
		value: "Navalmoral de la Mata, ES",
		label: "Navalmoral de la Mata, ES",
		code: "NDM-E",
	},
	{ value: "Níjar, ES", label: "Níjar, ES", code: "NIJ-1" },
	{ value: "Nájera, ES", label: "Nájera, ES", code: "NJR-1" },
	{ value: "Nules, ES", label: "Nules, ES", code: "NLS-1" },
	{ value: "Navia, ES", label: "Navia, ES", code: "NVA-1" },
	{ value: "Novelda, ES", label: "Novelda, ES", code: "NVD-1" },
	{ value: "Navarrete, ES", label: "Navarrete, ES", code: "NVT-1" },
	{ value: "Lucena, ES", label: "Lucena, ES", code: "ODB40" },
	{ value: "Ondarroa, ES", label: "Ondarroa, ES", code: "ODR-1" },
	{ value: "O Grove, ES", label: "O Grove, ES", code: "OGV-1" },
	{ value: "Oñate, ES", label: "Oñate, ES", code: "ONA-6" },
	{
		value: "Orense (Provincia), ES",
		label: "Orense (Provincia), ES",
		code: "ORE-E",
	},
	{ value: "Osuna, ES", label: "Osuna, ES", code: "OSN-1" },
	{ value: "Onteniente​, ES", label: "Onteniente​, ES", code: "OTE-1" },
	{ value: "Pájara, ES", label: "Pájara, ES", code: "PAJ-6" },
	{
		value: "Puerto José Banús, ES",
		label: "Puerto José Banús, ES",
		code: "PBN-1",
	},
	{
		value: "Peñaranda de Bracamonte , ES",
		label: "Peñaranda de Bracamonte , ES",
		code: "PDB-E",
	},
	{
		value: "La Puebla de Cazalla , ES",
		label: "La Puebla de Cazalla , ES",
		code: "PDC-1",
	},
	{
		value: "Puebla de la Calzada , ES",
		label: "Puebla de la Calzada , ES",
		code: "PDC-E",
	},
	{
		value: "Palos de la Frontera, ES",
		label: "Palos de la Frontera, ES",
		code: "PDF-1",
	},
	{ value: "Pola de Laviana, ES", label: "Pola de Laviana, ES", code: "PDL-1" },
	{ value: "Padrón, ES", label: "Padrón, ES", code: "PDN-1" },
	{ value: "Pego, ES", label: "Pego, ES", code: "PGO-1" },
	{ value: "Punta Umbría, ES", label: "Punta Umbría, ES", code: "PHB-1" },
	{ value: "Pliego, ES", label: "Pliego, ES", code: "PLI-E" },
	{
		value: "Puerto Lumbreras, ES",
		label: "Puerto Lumbreras, ES",
		code: "PLM-E",
	},
	{ value: "Pilas, ES", label: "Pilas, ES", code: "PLS-1" },
	{ value: "Pinos Puente, ES", label: "Pinos Puente, ES", code: "PNP-1" },
	{ value: "Pola de Lena, ES", label: "Pola de Lena, ES", code: "POL-1" },
	{
		value: "Pontevedra (Provincia), ES",
		label: "Pontevedra (Provincia), ES",
		code: "PON-E",
	},
	{
		value: "La villa de Puerto Real, ES",
		label: "La villa de Puerto Real, ES",
		code: "PRA-2",
	},
	{ value: "El Perelló, ES", label: "El Perelló, ES", code: "PRL-1" },
	{ value: "Porriño, ES", label: "Porriño, ES", code: "PRO-1" },
	{
		value: "Puerto Santa María, ES",
		label: "Puerto Santa María, ES",
		code: "PSM-2",
	},
	{ value: "Puenteareas, ES", label: "Puenteareas, ES", code: "PTA-1" },
	{ value: "Puentedeume, ES", label: "Puentedeume, ES", code: "PTM-1" },
	{ value: "Potes, ES", label: "Potes, ES", code: "PTS-1" },
	{ value: "Potes, ES", label: "Potes, ES", code: "PTS-2" },
	{ value: "La Rábida, ES", label: "La Rábida, ES", code: "RBD-1" },
	{ value: "Roncesvalles, ES", label: "Roncesvalles, ES", code: "RCL-1" },
	{ value: "Redondela, ES", label: "Redondela, ES", code: "RDL-1" },
	{ value: "Rentería, ES", label: "Rentería, ES", code: "RNT-1" },
	{ value: "Ribadesella, ES", label: "Ribadesella, ES", code: "RVS-1" },
	{ value: "Sax, ES", label: "Sax, ES", code: "SAX-E" },
	{
		value: "San Carles de la Rápita, ES",
		label: "San Carles de la Rápita, ES",
		code: "SCR-1",
	},
	{ value: "Sada, ES", label: "Sada, ES", code: "SDA-1" },
	{
		value: "Santo Domingo de la Calzada, ES",
		label: "Santo Domingo de la Calzada, ES",
		code: "SDC-1",
	},
	{ value: "Sangüesa, ES", label: "Sangüesa, ES", code: "SGA-1" },
	{ value: "Segrbe, ES", label: "Segrbe, ES", code: "SGB-1" },
	{
		value: "Sanlúcar de Barrameda, ES",
		label: "Sanlúcar de Barrameda, ES",
		code: "SLB-1",
	},
	{ value: "Silleda, ES", label: "Silleda, ES", code: "SLD-1" },
	{
		value: "Sanlúcar la Mayor, ES",
		label: "Sanlúcar la Mayor, ES",
		code: "SLM-3",
	},
	{ value: "Ciudad Rodrigo, ES", label: "Ciudad Rodrigo, ES", code: "SLM20" },
	{ value: "Solares, ES", label: "Solares, ES", code: "SLR-1" },
	{
		value: "San Martín de la Vega, ES",
		label: "San Martín de la Vega, ES",
		code: "SMV-E",
	},
	{ value: "Suances, ES", label: "Suances, ES", code: "SNC-1" },
	{ value: "Socuéllamos, ES", label: "Socuéllamos, ES", code: "SOM-E" },
	{ value: "Sopelana, ES", label: "Sopelana, ES", code: "SPL-1" },
	{ value: "Sorbas, ES", label: "Sorbas, ES", code: "SRB-1" },
	{
		value: "San Sebastián de los Reyes, ES",
		label: "San Sebastián de los Reyes, ES",
		code: "SSR-E",
	},
	{ value: "Santa Fe, ES", label: "Santa Fe, ES", code: "STF-1" },
	{ value: "Santomera, ES", label: "Santomera, ES", code: "STM-2" },
	{ value: "Santoña, ES", label: "Santoña, ES", code: "STO-1" },
	{ value: "Sagunto, ES", label: "Sagunto, ES", code: "STO-E" },
	{ value: "Sueca, ES", label: "Sueca, ES", code: "SUE-E" },
	{ value: "San Vicente, ES", label: "San Vicente, ES", code: "SVC-1" },
	{ value: "Salvatierra, ES", label: "Salvatierra, ES", code: "SVT-1" },
	{ value: "Tabernas , ES", label: "Tabernas , ES", code: "TBN-1" },
	{
		value: "Tabernes de Valldigna, ES",
		label: "Tabernes de Valldigna, ES",
		code: "TDV-E",
	},
	{ value: "Tejeda, ES", label: "Tejeda, ES", code: "TEJ-6" },
	{ value: "Tafalla, ES", label: "Tafalla, ES", code: "TFL-1" },
	{ value: "Trigueros, ES", label: "Trigueros, ES", code: "TGR-1" },
	{
		value: "Talavera la Real , ES",
		label: "Talavera la Real , ES",
		code: "TLR-E",
	},
	{ value: "Tolosa, ES", label: "Tolosa, ES", code: "TLS-1" },
	{ value: "Tomelloso, ES", label: "Tomelloso, ES", code: "TML-1" },
	{ value: "Torrijos, ES", label: "Torrijos, ES", code: "TOR-E" },
	{ value: "Tarracón, ES", label: "Tarracón, ES", code: "TRC-E" },
	{ value: "Torredembarra, ES", label: "Torredembarra, ES", code: "TRD-1" },
	{ value: "Tàrrega, ES", label: "Tàrrega, ES", code: "TRG-1" },
	{ value: "Torreblanca, ES", label: "Torreblanca, ES", code: "TRP-1" },
	{ value: "Torrelavega, ES", label: "Torrelavega, ES", code: "TRV-1" },
	{ value: "Totana, ES", label: "Totana, ES", code: "TTA-1" },
	{ value: "Torrente, ES", label: "Torrente, ES", code: "TTE-E" },
	{
		value: "Tavernes de la Valldigna, ES",
		label: "Tavernes de la Valldigna, ES",
		code: "TVS-1",
	},
	{ value: "Úbeda, ES", label: "Úbeda, ES", code: "UBE-E" },
	{
		value: "Ullívarri-Gamboa, ES",
		label: "Ullívarri-Gamboa, ES",
		code: "UBG-1",
	},
	{
		value: "Ullíbarri-Gamboa, ES",
		label: "Ullíbarri-Gamboa, ES",
		code: "UBG-2",
	},
	{ value: "Utebo, ES", label: "Utebo, ES", code: "UTB-1" },
	{ value: "Utiel, ES", label: "Utiel, ES", code: "UTI-E" },
	{ value: "Utrera, ES", label: "Utrera, ES", code: "UTR-1" },
	{ value: "Valsain, ES", label: "Valsain, ES", code: "VAL-1" },
	{
		value: "Villanueva de Arosa, ES",
		label: "Villanueva de Arosa, ES",
		code: "VDA-1",
	},
	{
		value: "El Viso del Alco, ES",
		label: "El Viso del Alco, ES",
		code: "VDA-2",
	},
	{
		value: "Villafranca de los Barros, ES",
		label: "Villafranca de los Barros, ES",
		code: "VDB-E",
	},
	{
		value: "Vejer de la Frontera , ES",
		label: "Vejer de la Frontera , ES",
		code: "VDF-1",
	},
	{
		value: "Villarejo de Salvanés, ES",
		label: "Villarejo de Salvanés, ES",
		code: "VDS-E",
	},
	{ value: "Vall de Uxó, ES", label: "Vall de Uxó, ES", code: "VDX-1" },
	{
		value: "Villafranca de Ebro, ES",
		label: "Villafranca de Ebro, ES",
		code: "VFE-1",
	},
	{
		value: "Villafranca del Panadés, ES",
		label: "Villafranca del Panadés, ES",
		code: "VFP-1",
	},
	{ value: "Vergara, ES", label: "Vergara, ES", code: "VGR-1" },
	{
		value: "Álava (Provincia), ES",
		label: "Álava (Provincia), ES",
		code: "VGZ-1",
	},
	{ value: "Vic, ES", label: "Vic, ES", code: "VIC-2" },
	{ value: "Villacastín, ES", label: "Villacastín, ES", code: "VIL-1" },
	{ value: "Villarrobledo, ES", label: "Villarrobledo, ES", code: "VIL-E" },
	{
		value: "Villanueva de la Serena , ES",
		label: "Villanueva de la Serena , ES",
		code: "VIS-E",
	},
	{ value: "Villena, ES", label: "Villena, ES", code: "VLN-1" },
	{ value: "Villaricos, ES", label: "Villaricos, ES", code: "VLR-1" },
	{ value: "Vélez-Rubio, ES", label: "Vélez-Rubio, ES", code: "VLR-2" },
	{ value: "Valmaseda, ES", label: "Valmaseda, ES", code: "VMD-1" },
	{
		value: "Villanueva del Trabuco, ES",
		label: "Villanueva del Trabuco, ES",
		code: "VNT-1",
	},
	{ value: "Villaviciosa, ES", label: "Villaviciosa, ES", code: "VSA-E" },
	{
		value: "Valverde del Camino, ES",
		label: "Valverde del Camino, ES",
		code: "VVC-1",
	},
	{ value: "Zumárraga, ES", label: "Zumárraga, ES", code: "ZMR-1" },
	{ value: "Zumaya, ES", label: "Zumaya, ES", code: "ZMY-1" },
	{ value: "Zarauz, ES", label: "Zarauz, ES", code: "ZRU-1" },
	{
		value: "Cuenca (Provincia), ES",
		label: "Cuenca (Provincia), ES",
		code: "cuenc",
	},
	{
		value: "test 2 mallorca , ES",
		label: "test 2 mallorca , ES",
		code: "test2",
	},
	{
		value: "Aldeanueva de la Vera, ES",
		label: "Aldeanueva de la Vera, ES",
		code: "ADLV-1",
	},
	{
		value: "Alicante (Provincia), ES",
		label: "Alicante (Provincia), ES",
		code: "ALC-42",
	},
	{
		value: "Playa de Amadores, ES",
		label: "Playa de Amadores, ES",
		code: "AMAD-6",
	},
	{ value: "Arzúa, ES", label: "Arzúa, ES", code: "ARZU-1" },
	{ value: "Baena, ES", label: "Baena, ES", code: "BAEN-6" },
	{ value: "Bagá, ES", label: "Bagá, ES", code: "BAGA-6" },
	{ value: "Sabadell, ES", label: "Sabadell, ES", code: "BCN-52" },
	{ value: "Mombuey, ES", label: "Mombuey, ES", code: "BUEY-6" },
	{ value: "Burriana, ES", label: "Burriana, ES", code: "CAS-53" },
	{ value: "Castro del Río, ES", label: "Castro del Río, ES", code: "CAST-6" },
	{ value: "Covadonga, ES", label: "Covadonga, ES", code: "COV-12" },
	{
		value: "Cuacos de Yuste, ES",
		label: "Cuacos de Yuste, ES",
		code: "CUDY-1",
	},
	{ value: "Empuriabrava, ES", label: "Empuriabrava, ES", code: "EMPU-1" },
	{ value: "Gata, ES", label: "Gata, ES", code: "GATA-1" },
	{
		value: "Guijo de Santa Bárbara, ES",
		label: "Guijo de Santa Bárbara, ES",
		code: "GDSB-1",
	},
	{ value: "Grazalema, ES", label: "Grazalema, ES", code: "GRAZ-6" },
	{
		value: "Jaraíz de la Vera, ES",
		label: "Jaraíz de la Vera, ES",
		code: "JDLV-1",
	},
	{
		value: "Jarandilla de la Vera, ES",
		label: "Jarandilla de la Vera, ES",
		code: "JDLV-2",
	},
	{ value: "Jerte, ES", label: "Jerte, ES", code: "JERT-1" },
	{ value: "Las Hurdes, ES", label: "Las Hurdes, ES", code: "LAHU-1" },
	{ value: "Lluchmayor, ES", label: "Lluchmayor, ES", code: "LLUC-1" },
	{ value: "Manacor, ES", label: "Manacor, ES", code: "MANA-1" },
	{ value: "Mellid, ES", label: "Mellid, ES", code: "MELI-1" },
	{ value: "Doña Mencía, ES", label: "Doña Mencía, ES", code: "MENC-6" },
	{ value: "Monfragüe, ES", label: "Monfragüe, ES", code: "MONF-2" },
	{ value: "Moya, ES", label: "Moya, ES", code: "MOYA-6" },
	{ value: "Mungia, ES", label: "Mungia, ES", code: "MUG-91" },
	{ value: "Mura, ES", label: "Mura, ES", code: "MURA-6" },
	{ value: "Onda, ES", label: "Onda, ES", code: "OND-01" },
	{ value: "O Pino, ES", label: "O Pino, ES", code: "OPED-1" },
	{ value: "Pravia, ES", label: "Pravia, ES", code: "OVD-75" },
	{ value: "Palas de Rey, ES", label: "Palas de Rey, ES", code: "PADR-1" },
	{ value: "Peralada, ES", label: "Peralada, ES", code: "PER-54" },
	{ value: "Pinofranqueado, ES", label: "Pinofranqueado, ES", code: "PINO-1" },
	{ value: "Puertomarín, ES", label: "Puertomarín, ES", code: "POMA-1" },
	{ value: "La Guardia, ES", label: "La Guardia, ES", code: "PON-52" },
	{ value: "Puente Viesgo, ES", label: "Puente Viesgo, ES", code: "PUVI-2" },
	{ value: "Rute, ES", label: "Rute, ES", code: "RUTE-1" },
	{ value: "Sarria, ES", label: "Sarria, ES", code: "SARR-1" },
	{
		value: "San Cristóbal de La Laguna, ES",
		label: "San Cristóbal de La Laguna, ES",
		code: "SCLL-6",
	},
	{ value: "Tarajalillo, ES", label: "Tarajalillo, ES", code: "TAJA-6" },
	{ value: "Montblanch, ES", label: "Montblanch, ES", code: "TAR-52" },
	{
		value: "Isla de La Toja, ES",
		label: "Isla de La Toja, ES",
		code: "TOJA-6",
	},
	{ value: "Torrelavega, ES", label: "Torrelavega, ES", code: "TRR-11" },
	{ value: "Tuineje, ES", label: "Tuineje, ES", code: "TUIN-6" },
	{ value: "Arguineguín, ES", label: "Arguineguín, ES", code: "AGN-002" },
	{ value: "Alfas del Pi, ES", label: "Alfas del Pi, ES", code: "ALS-002" },
	{
		value: "La Aldea de San Nicolás, ES",
		label: "La Aldea de San Nicolás, ES",
		code: "ASS-002",
	},
	{ value: "Benia de Onís, ES", label: "Benia de Onís, ES", code: "BENIA-6" },
	{ value: "Berga, ES", label: "Berga, ES", code: "BERGA-6" },
	{ value: "Camariñas, ES", label: "Camariñas, ES", code: "CMS-002" },
	{ value: "Espejo, ES", label: "Espejo, ES", code: "ESPEJ-6" },
	{ value: "Espinal, ES", label: "Espinal, ES", code: "ESPINAL" },
	{ value: "Firgas, ES", label: "Firgas, ES", code: "FGS-002" },
	{
		value: "Guadalajara (Provincia), ES",
		label: "Guadalajara (Provincia), ES",
		code: "GLJ-002",
	},
	{ value: "Gósol, ES", label: "Gósol, ES", code: "GOSOL-6" },
	{
		value: "Guipúzcoa (Provincia), ES",
		label: "Guipúzcoa (Provincia), ES",
		code: "GPZ-002",
	},
	{
		value: "Granada (Provincia), ES",
		label: "Granada (Provincia), ES",
		code: "GRD-002",
	},
	{
		value: "Huelva (Provincia), ES",
		label: "Huelva (Provincia), ES",
		code: "HLV-002",
	},
	{
		value: "Huesca (Provincia), ES",
		label: "Huesca (Provincia), ES",
		code: "HSC-002",
	},
	{
		value: "Jaén (Provincia), ES",
		label: "Jaén (Provincia), ES",
		code: "JEN-002",
	},
	{
		value: "Lérida (Provincia), ES",
		label: "Lérida (Provincia), ES",
		code: "LDA-002",
	},
	{
		value: "León (Provincia), ES",
		label: "León (Provincia), ES",
		code: "LEN-002",
	},
	{
		value: "Las Palmas (Provincia), ES",
		label: "Las Palmas (Provincia), ES",
		code: "LPS-002",
	},
	{ value: "La Rioja , ES", label: "La Rioja , ES", code: "LRJ-002" },
	{
		value: "Madrid (Provincia), ES",
		label: "Madrid (Provincia), ES",
		code: "MAD-002",
	},
	{
		value: "Motilla del Palancar, ES",
		label: "Motilla del Palancar, ES",
		code: "MDP-002",
	},
	{
		value: "Málaga (Provincia), ES",
		label: "Málaga (Provincia), ES",
		code: "MLG-002",
	},
	{
		value: "Murcia (Provincia), ES",
		label: "Murcia (Provincia), ES",
		code: "MRA-002",
	},
	{ value: "Muros de Nalón, ES", label: "Muros de Nalón, ES", code: "MUROS-6" },
	{ value: "Nueva Carteya, ES", label: "Nueva Carteya, ES", code: "NUEYA-6" },
	{
		value: "Navarra (Province), ES",
		label: "Navarra (Province), ES",
		code: "NVR-002",
	},
	{ value: "Puigcerdá, ES", label: "Puigcerdá, ES", code: "PCD-002" },
	{
		value: "Palencia (Provincia), ES",
		label: "Palencia (Provincia), ES",
		code: "PLC-002",
	},
	{ value: "Playa del Cura, ES", label: "Playa del Cura, ES", code: "PYC-002" },
	{
		value: "Puerto del Rosario, ES",
		label: "Puerto del Rosario, ES",
		code: "ROSAR-6",
	},
	{ value: "Rupit, ES", label: "Rupit, ES", code: "RUPIT-6" },
	{ value: "Salas, ES", label: "Salas, ES", code: "SALAS-6" },
	{
		value: "San Lorenzo del Cardezar, ES",
		label: "San Lorenzo del Cardezar, ES",
		code: "SCR-002",
	},
	{
		value: "Santa Cruz de Tenerife (Provincia), ES",
		label: "Santa Cruz de Tenerife (Provincia), ES",
		code: "SCT-002",
	},
	{
		value: "Segovia (Provincia), ES",
		label: "Segovia (Provincia), ES",
		code: "SGA-002",
	},
	{
		value: "Salamanca (Provincia), ES",
		label: "Salamanca (Provincia), ES",
		code: "SLA-002",
	},
	{ value: "Campodrón, ES", label: "Campodrón, ES", code: "CAMPOD-6" },
	{
		value: "Castellar de Nuch, ES",
		label: "Castellar de Nuch, ES",
		code: "CDNUCH-6",
	},
	{ value: "Chantada, ES", label: "Chantada, ES", code: "Chantada" },
	{
		value: "Castellfullit de la Roca, ES",
		label: "Castellfullit de la Roca, ES",
		code: "FULLIT-6",
	},
	{ value: "Ortuella , ES", label: "Ortuella , ES", code: "Ortuella" },
	{
		value: "Ávila (PROVINCIA), ES",
		label: "Ávila (PROVINCIA), ES",
		code: "PAVILA-6",
	},
	{
		value: "Soria (Provincia), ES",
		label: "Soria (Provincia), ES",
		code: "PSORIA-6",
	},
	{ value: "Viella, ES", label: "Viella, ES", code: "VIELLA-6" },
	{ value: "Costa Cálida, ES", label: "Costa Cálida, ES", code: "fake-768" },
	{
		value: "Costa del Garraf, ES",
		label: "Costa del Garraf, ES",
		code: "fake-769",
	},
	{
		value: "La Cala del Moral, ES",
		label: "La Cala del Moral, ES",
		code: "fake-777",
	},
	{ value: "Pedregalejo, ES", label: "Pedregalejo, ES", code: "fake-778" },
	{ value: "El Palo, ES", label: "El Palo, ES", code: "fake-779" },
	{
		value: "San Pedro Alcántara, ES",
		label: "San Pedro Alcántara, ES",
		code: "fake-780",
	},
	{
		value: "San Luis de Sabinillas, ES",
		label: "San Luis de Sabinillas, ES",
		code: "fake-781",
	},
	{
		value: "Alhama de Granada, ES",
		label: "Alhama de Granada, ES",
		code: "fake-782",
	},
	{
		value: "Caravaca de la Cruz, ES",
		label: "Caravaca de la Cruz, ES",
		code: "fake-783",
	},
	{ value: "Almonte, ES", label: "Almonte, ES", code: "fake-784" },
	{ value: "Calafat, ES", label: "Calafat, ES", code: "CALAFAT-6" },
	{
		value: "Castellfullit de la Roca, ES",
		label: "Castellfullit de la Roca, ES",
		code: "CDLROCA-6",
	},
	{
		value: "Castellar de n&apos;Hug, ES",
		label: "Castellar de n&apos;Hug, ES",
		code: "Castellar",
	},
	{
		value: "San Felices de Buelna, ES",
		label: "San Felices de Buelna, ES",
		code: "FELICES-6",
	},
	{ value: "Molins de Rei, ES", label: "Molins de Rei, ES", code: "MolinsRei" },
	{
		value: "Teruel (PROVINCIA), ES",
		label: "Teruel (PROVINCIA), ES",
		code: "PTERUEL-6",
	},
	{
		value: "Toledo (PROVINCIA), ES",
		label: "Toledo (PROVINCIA), ES",
		code: "PTOLEDO-6",
	},
	{
		value: "Zamora (PROVINCIA), ES",
		label: "Zamora (PROVINCIA), ES",
		code: "PZAMORA-6",
	},
	{ value: "Pineda de Mar, ES", label: "Pineda de Mar, ES", code: "PinedaMar" },
	{ value: "Monesterio, ES", label: "Monesterio, ES", code: "Monasterio" },
	{ value: "Palafrugell, ES", label: "Palafrugell, ES", code: "PALAGELL-6" },
	{
		value: "Sevilla (PROVINCIA), ES",
		label: "Sevilla (PROVINCIA), ES",
		code: "PSEVILLA-6",
	},
	{
		value: "Vizcaya (Provincia), ES",
		label: "Vizcaya (Provincia), ES",
		code: "PVIZCAYA-6",
	},
	{
		value: "Valencia (PROVINCIA), ES",
		label: "Valencia (PROVINCIA), ES",
		code: "PVALENCIA-6",
	},
	{
		value: "Zaragoza (PROVINCIA), ES",
		label: "Zaragoza (PROVINCIA), ES",
		code: "PZARAGOZA-6",
	},
	{ value: "Piedralaves , ES", label: "Piedralaves , ES", code: "Piedralaves" },
	{
		value: "Playa de Gandía, ES",
		label: "Playa de Gandía, ES",
		code: "gandiaplaya",
	},
	{
		value: "Tarragona (Provincia), ES",
		label: "Tarragona (Provincia), ES",
		code: "PTARRAGONA-6",
	},
	{
		value: "Valladolid (PROVINCIA), ES",
		label: "Valladolid (PROVINCIA), ES",
		code: "PVALLADOLID-6",
	},
	{ value: "Colombo, LK", label: "Colombo, LK", code: "CMB-1" },
	{ value: "Dambulla, LK", label: "Dambulla, LK", code: "DMB" },
	{ value: "Katunayake, LK", label: "Katunayake, LK", code: "KA2" },
	{ value: "Koggala, LK", label: "Koggala, LK", code: "KCT" },
	{ value: "Kandy , LK", label: "Kandy , LK", code: "KDY" },
	{ value: "Sigiriya , LK", label: "Sigiriya , LK", code: "LK1" },
	{ value: "Nuwara Eliya, LK", label: "Nuwara Eliya, LK", code: "NWE" },
	{ value: "Polonnaruwa, LK", label: "Polonnaruwa, LK", code: "PLR" },
	{ value: "Ratnapura, LK", label: "Ratnapura, LK", code: "RAT" },
	{ value: "Weligama, LK", label: "Weligama, LK", code: "WE3" },
	{ value: "Kosgoda, LK", label: "Kosgoda, LK", code: "fake-336" },
	{ value: "Monte Lavinia, LK", label: "Monte Lavinia, LK", code: "fake-337" },
	{ value: "Anuradhapura, LK", label: "Anuradhapura, LK", code: "ACJ" },
	{ value: "Ahungalla, LK", label: "Ahungalla, LK", code: "AHG" },
	{ value: "Bahía Arugam, LK", label: "Bahía Arugam, LK", code: "ARU" },
	{ value: "Bentota, LK", label: "Bentota, LK", code: "BJT" },
	{ value: "Beruwela, LK", label: "Beruwela, LK", code: "BWE" },
	{ value: "Galle, LK", label: "Galle, LK", code: "GA5" },
	{ value: "Habarana, LK", label: "Habarana, LK", code: "HBN" },
	{ value: "Hambantota, LK", label: "Hambantota, LK", code: "HBT" },
	{ value: "Hikkaduwa, LK", label: "Hikkaduwa, LK", code: "HKK" },
	{ value: "Kalutara, LK", label: "Kalutara, LK", code: "KL2" },
	{ value: "Trincomalee, LK", label: "Trincomalee, LK", code: "LK2" },
	{ value: "Minneriya, LK", label: "Minneriya, LK", code: "MIN" },
	{ value: "Matara, LK", label: "Matara, LK", code: "MT4" },
	{ value: "Negombo , LK", label: "Negombo , LK", code: "NBO" },
	{ value: "Passikudah, LK", label: "Passikudah, LK", code: "PSS" },
	{ value: "Sinharaja, LK", label: "Sinharaja, LK", code: "SNH" },
	{ value: "Jaffna, LK", label: "Jaffna, LK", code: "SR4" },
	{ value: "Tangalle, LK", label: "Tangalle, LK", code: "TGL" },
	{ value: "Tissamaharama, LK", label: "Tissamaharama, LK", code: "TSS" },
	{
		value: "Yala National Park, LK",
		label: "Yala National Park, LK",
		code: "YAL",
	},
	{ value: "Badulla, LK", label: "Badulla, LK", code: "BADU" },
	{ value: "Balapitiya, LK", label: "Balapitiya, LK", code: "BALA" },
	{ value: "Bandarawela, LK", label: "Bandarawela, LK", code: "BAND" },
	{ value: "Wadduwa, LK", label: "Wadduwa, LK", code: "KL2-2" },
	{ value: "Waikkal, LK", label: "Waikkal, LK", code: "fake-136" },
	{ value: "Avukana, LK", label: "Avukana, LK", code: "AVU" },
	{ value: "Ella , LK", label: "Ella , LK", code: "Ella" },
	{ value: "Kataragama, LK", label: "Kataragama, LK", code: "KTGR" },
	{ value: "Matale, LK", label: "Matale, LK", code: "Matale" },
	{ value: "Pasbage Korale, LK", label: "Pasbage Korale, LK", code: "PAKO-1" },
	{ value: "Pinnawala, LK", label: "Pinnawala, LK", code: "Pinnawala" },
	{ value: "Hiriwadunna, LK", label: "Hiriwadunna, LK", code: "Hiriwadunna" },
	{ value: "Palugaswewa, LK", label: "Palugaswewa, LK", code: "Palugaswewa" },
	{
		value: "Parque Nacional Wilpattu, LK",
		label: "Parque Nacional Wilpattu, LK",
		code: "WilpattuNationalPark",
	},
	{ value: "Jartum, SD", label: "Jartum, SD", code: "KRT" },
	{ value: "Port Sudan, SD", label: "Port Sudan, SD", code: "123_sud" },
	{ value: "Yuba, SD", label: "Yuba, SD", code: "JUB-77" },
	{ value: "Paramaribo, SR", label: "Paramaribo, SR", code: "WSO" },
	{ value: "Mbabane, SZ", label: "Mbabane, SZ", code: "SW-1" },
	{ value: "Lobamba, SZ", label: "Lobamba, SZ", code: "LBMB-6" },
	{ value: "Ezulwini, SZ", label: "Ezulwini, SZ", code: "ZLWN-6" },
	{ value: "Horw , SZ", label: "Horw , SZ", code: "Horw" },
	{ value: "Estocolmo, SE", label: "Estocolmo, SE", code: "STO" },
	{ value: "Östersund, SE", label: "Östersund, SE", code: "OSR" },
	{ value: "Karlstad, SE", label: "Karlstad, SE", code: "KSD" },
	{ value: "Linköping, SE", label: "Linköping, SE", code: "LPI" },
	{ value: "Mora, SE", label: "Mora, SE", code: "OAM" },
	{ value: "Örebro, SE", label: "Örebro, SE", code: "ORB" },
	{ value: "Vasteras, SE", label: "Vasteras, SE", code: "VAS" },
	{ value: "Gotemburgo, SE", label: "Gotemburgo, SE", code: "GOT-1" },
	{ value: "Malmo, SE", label: "Malmo, SE", code: "MMA-1" },
	{ value: "Skellefteå, SE", label: "Skellefteå, SE", code: "SKL-1" },
	{ value: "Jönköping, SE", label: "Jönköping, SE", code: "JON" },
	{ value: "Norrköping, SE", label: "Norrköping, SE", code: "NOK" },
	{ value: "Umea, SE", label: "Umea, SE", code: "UME" },
	{ value: "Helsinborg, SE", label: "Helsinborg, SE", code: "JHE3" },
	{ value: "Kalmar, SE", label: "Kalmar, SE", code: "KAM3" },
	{ value: "Halmstad, SE", label: "Halmstad, SE", code: "KAM10" },
	{ value: "Linkoping, SE", label: "Linkoping, SE", code: "LCG-75" },
	{ value: "Borlange, SE", label: "Borlange, SE", code: "BOA" },
	{ value: "Boras, SE", label: "Boras, SE", code: "BOR" },
	{ value: "Eskilstuna, SE", label: "Eskilstuna, SE", code: "ES1" },
	{ value: "Falun, SE", label: "Falun, SE", code: "FAL" },
	{ value: "Sundsvall, SE", label: "Sundsvall, SE", code: "SUD" },
	{ value: "Vaxjo, SE", label: "Vaxjo, SE", code: "VAX" },
	{ value: "Varberg, SE", label: "Varberg, SE", code: "VG1" },
	{ value: "Trollhättan, SE", label: "Trollhättan, SE", code: "RTL3" },
	{ value: "Ronneby, SE", label: "Ronneby, SE", code: "RNB-5" },
	{ value: "Sodertalje, SE", label: "Sodertalje, SE", code: "SDJ-1" },
	{ value: "Skovde, SE", label: "Skovde, SE", code: "SKO-1" },
	{ value: "Lund, SE", label: "Lund, SE", code: "LUD-22" },
	{ value: "Arvidsjaur, SE", label: "Arvidsjaur, SE", code: "fake-289" },
	{ value: "Varnamo, SE", label: "Varnamo, SE", code: "fake-290" },
	{ value: "Kiruna, SE", label: "Kiruna, SE", code: "KIR" },
	{ value: "Uppsala, SE", label: "Uppsala, SE", code: "UPP" },
	{ value: "Visby, SE", label: "Visby, SE", code: "VBY" },
	{ value: "Gavle, SE", label: "Gavle, SE", code: "GAV-1" },
	{ value: "Hudiksvall, SE", label: "Hudiksvall, SE", code: "HDU-1" },
	{ value: "Kristianstad, SE", label: "Kristianstad, SE", code: "KID-1" },
	{ value: "Karlskrona, SE", label: "Karlskrona, SE", code: "KSK-1" },
	{ value: "Lulea, SE", label: "Lulea, SE", code: "LUL-1" },
	{ value: "Nykoping, SE", label: "Nykoping, SE", code: "NY1-2" },
	{ value: "Nacka , SE", label: "Nacka , SE", code: "Nacka" },
	{ value: "Oskarshamn, SE", label: "Oskarshamn, SE", code: "OKS-1" },
	{ value: "Sigtuna, SE", label: "Sigtuna, SE", code: "SIG-1" },
	{ value: "Soderhamn, SE", label: "Soderhamn, SE", code: "SOD-1" },
	{ value: "Sunne, SE", label: "Sunne, SE", code: "SUN-1" },
	{ value: "Vanersborg, SE", label: "Vanersborg, SE", code: "VAN-3" },
	{ value: "Ängelholm, SE", label: "Ängelholm, SE", code: "ANG-23" },
	{ value: "Morbylanga, SE", label: "Morbylanga, SE", code: "MRBLNG" },
	{ value: "Olofström, SE", label: "Olofström, SE", code: "OLOF-6" },
	{ value: "Haparanda, SE", label: "Haparanda, SE", code: "HPRND-6" },
	{ value: "Nynäshamn, SE", label: "Nynäshamn, SE", code: "NYN-002" },
	{ value: "Örnsköldsvik, SE", label: "Örnsköldsvik, SE", code: "OSK-002" },
	{ value: "Gotland, SE", label: "Gotland, SE", code: "VIKING-6" },
	{ value: "Helsingborg, SE", label: "Helsingborg, SE", code: "HELBORG-6" },
	{ value: "Trelleborg, SE", label: "Trelleborg, SE", code: "Trelleborg" },
	{ value: "Nidwalden, CH", label: "Nidwalden, CH", code: "fake-70" },
	{ value: "Basilea, CH", label: "Basilea, CH", code: "BSL" },
	{ value: "Valais, CH", label: "Valais, CH", code: "WLS" },
	{ value: "Lausana, CH", label: "Lausana, CH", code: "LAU" },
	{ value: "St. Gallen, CH", label: "St. Gallen, CH", code: "SGA" },
	{ value: "Saint Moritz, CH", label: "Saint Moritz, CH", code: "SMZ" },
	{ value: "Zermatt, CH", label: "Zermatt, CH", code: "ZMT" },
	{ value: "Zurich, CH", label: "Zurich, CH", code: "ZRH" },
	{ value: "Graubünden, CH", label: "Graubünden, CH", code: "GRAU" },
	{ value: "Berna, CH", label: "Berna, CH", code: "BRN-1" },
	{ value: "Ginebra, CH", label: "Ginebra, CH", code: "GVA-1" },
	{ value: "Chur, CH", label: "Chur, CH", code: "CHU" },
	{ value: "Davos, CH", label: "Davos, CH", code: "DAV" },
	{ value: "Friburgo, CH", label: "Friburgo, CH", code: "FRG" },
	{ value: "Interlaken, CH", label: "Interlaken, CH", code: "INT" },
	{ value: "Locarno, CH", label: "Locarno, CH", code: "LOA" },
	{ value: "Lucerna, CH", label: "Lucerna, CH", code: "LUC" },
	{ value: "Lugano, CH", label: "Lugano, CH", code: "LUG" },
	{ value: "Neuchâtel, CH", label: "Neuchâtel, CH", code: "NEU" },
	{ value: "Schwyz, CH", label: "Schwyz, CH", code: "SCY" },
	{ value: "Montreux, CH", label: "Montreux, CH", code: "ZJP" },
	{ value: "Arosa, CH", label: "Arosa, CH", code: "DAV4" },
	{ value: "Meilen, CH", label: "Meilen, CH", code: "Mail" },
	{ value: "Biel, CH", label: "Biel, CH", code: "BRN-5" },
	{ value: "Klosters, CH", label: "Klosters, CH", code: "DAV-6" },
	{ value: "Savognin, CH", label: "Savognin, CH", code: "SMZ-2" },
	{ value: "Alpes Berneses, CH", label: "Alpes Berneses, CH", code: "SWS-3" },
	{ value: "Thun, CH", label: "Thun, CH", code: "SWS-6" },
	{ value: "Engelberg, CH", label: "Engelberg, CH", code: "SWS-9" },
	{ value: "Alpes Peninos, CH", label: "Alpes Peninos, CH", code: "ZMT-1" },
	{ value: "Horgen, CH", label: "Horgen, CH", code: "HOR-67" },
	{ value: "Obwalden, CH", label: "Obwalden, CH", code: "fake-71" },
	{ value: "Uri, CH", label: "Uri, CH", code: "fake-72" },
	{ value: "Kandersteg, CH", label: "Kandersteg, CH", code: "SWS3" },
	{ value: "Beatenberg, CH", label: "Beatenberg, CH", code: "INT-1" },
	{ value: "Adelboden, CH", label: "Adelboden, CH", code: "SWS-1" },
	{ value: "Grindelwald, CH", label: "Grindelwald, CH", code: "SWS-2" },
	{
		value: "Mürren - Schilthorn, CH",
		label: "Mürren - Schilthorn, CH",
		code: "SWS-4",
	},
	{ value: "Wengen, CH", label: "Wengen, CH", code: "SWS-7" },
	{ value: "Crans-Montana , CH", label: "Crans-Montana , CH", code: "SWS18" },
	{ value: "Verbier, CH", label: "Verbier, CH", code: "VLS-1" },
	{ value: "Tasch, CH", label: "Tasch, CH", code: "ZMT-3" },
	{ value: "Adliswil, CH", label: "Adliswil, CH", code: "ADL-18" },
	{ value: "Grimsel, CH", label: "Grimsel, CH", code: "GRI-20" },
	{ value: "Maienfeld, CH", label: "Maienfeld, CH", code: "MAI-24" },
	{ value: "Paradiso, CH", label: "Paradiso, CH", code: "PAR-98" },
	{ value: "Leukerbad, CH", label: "Leukerbad, CH", code: "SWS-11" },
	{ value: "Saas-Fee, CH", label: "Saas-Fee, CH", code: "SWS-12" },
	{ value: "Laax, CH", label: "Laax, CH", code: "SWS-37" },
	{
		value: "Nendaz (4 vallées), CH",
		label: "Nendaz (4 vallées), CH",
		code: "SWS-40",
	},
	{
		value: "Villars Gryon - Les Diablerets, CH",
		label: "Villars Gryon - Les Diablerets, CH",
		code: "SWS-43",
	},
	{ value: "Flims, CH", label: "Flims, CH", code: "fake-327" },
	{ value: "Gstaad, CH", label: "Gstaad, CH", code: "FAKEGstaad" },
	{ value: "Aarau, CH", label: "Aarau, CH", code: "AARU" },
	{ value: "Bellinzona, CH", label: "Bellinzona, CH", code: "BLLZ" },
	{ value: "Meyrin, CH", label: "Meyrin, CH", code: "MYRN" },
	{
		value: "Schaffhausen (Escafusa), CH",
		label: "Schaffhausen (Escafusa), CH",
		code: "ZRH6",
	},
	{ value: "Stein am Rhein, CH", label: "Stein am Rhein, CH", code: "ZRH7" },
	{ value: "Meiringen, CH", label: "Meiringen, CH", code: "MRNGn" },
	{ value: "Olten, CH", label: "Olten, CH", code: "OLT-1" },
	{ value: "Rumlang, CH", label: "Rumlang, CH", code: "RUM-1" },
	{ value: "Sion, CH", label: "Sion, CH", code: "SIO-1" },
	{ value: "Meggen, CH", label: "Meggen, CH", code: "MEGGEN" },
	{ value: "Sihlbrugg, CH", label: "Sihlbrugg, CH", code: "SIHL-6" },
	{ value: "Soleura, CH", label: "Soleura, CH", code: "SLTH-1" },
	{ value: "Alpes suizos, CH", label: "Alpes suizos, CH", code: "SWAL-1" },
	{ value: "Obersaxen, CH", label: "Obersaxen, CH", code: "OBXEN-6" },
	{ value: "Rothrist, CH", label: "Rothrist, CH", code: "RTT-002" },
	{
		value: "San Galo o San Gall, CH",
		label: "San Galo o San Gall, CH",
		code: "SanGalo",
	},
	{ value: "Saanen, CH", label: "Saanen, CH", code: "SAANEN-6" },
	{ value: "Winterthur, CH", label: "Winterthur, CH", code: "WINTER-6" },
	{ value: "Samedan, CH", label: "Samedan, CH", code: "SAMEDAN-6" },
	{ value: "Stansstad , CH", label: "Stansstad , CH", code: "Stansstad" },
	{
		value: "Rapperswil-Jona, CH",
		label: "Rapperswil-Jona, CH",
		code: "Rapperswil",
	},
	{ value: "Damasco, SY", label: "Damasco, SY", code: "DA1" },
	{ value: "Damasco, SY", label: "Damasco, SY", code: "DA1-1" },
	{ value: "Taipéi, TW", label: "Taipéi, TW", code: "TPE" },
	{ value: "Nuevo Taipéi, TW", label: "Nuevo Taipéi, TW", code: "TPE-14" },
	{ value: "Chiayi, TW", label: "Chiayi, TW", code: "CYI" },
	{ value: "Yilan, TW", label: "Yilan, TW", code: "TW3" },
	{ value: "Nantou, TW", label: "Nantou, TW", code: "TW-4" },
	{ value: "Hualien, TW", label: "Hualien, TW", code: "TW-5" },
	{ value: "Kaohsiung, TW", label: "Kaohsiung, TW", code: "KHH-1" },
	{ value: "Tainan, TW", label: "Tainan, TW", code: "TNN-1" },
	{ value: "Taichung , TW", label: "Taichung , TW", code: "TXG-1" },
	{ value: "Neiwan, TW", label: "Neiwan, TW", code: "NEI-1" },
	{ value: "Alishan, TW", label: "Alishan, TW", code: "ALIS-1" },
	{ value: "Hsinchu, TW", label: "Hsinchu, TW", code: "HSIN-1" },
	{ value: "Kenting, TW", label: "Kenting, TW", code: "KENT-1" },
	{ value: "Xincheng, TW", label: "Xincheng, TW", code: "Xincheng" },
	{ value: "Dusambé, TJ", label: "Dusambé, TJ", code: "DYU" },
	{ value: "Khujand, TJ", label: "Khujand, TJ", code: "KHJ-87" },
	{ value: "Arusha, TZ", label: "Arusha, TZ", code: "ARK" },
	{ value: "Lago Manyara, TZ", label: "Lago Manyara, TZ", code: "LTP" },
	{ value: "Zanzíbar, TZ", label: "Zanzíbar, TZ", code: "ZNZ" },
	{ value: "Ngorongoro, TZ", label: "Ngorongoro, TZ", code: "NGR" },
	{
		value: "Serengeti (Tanzania), TZ",
		label: "Serengeti (Tanzania), TZ",
		code: "SNP",
	},
	{ value: "Kilimanjaro, TZ", label: "Kilimanjaro, TZ", code: "QSI_1" },
	{ value: "Dar es Salaam, TZ", label: "Dar es Salaam, TZ", code: "DAE" },
	{ value: "Dodoma, TZ", label: "Dodoma, TZ", code: "DDM" },
	{ value: "Karatu, TZ", label: "Karatu, TZ", code: "KTU" },
	{ value: "Moshi, TZ", label: "Moshi, TZ", code: "QSI" },
	{ value: "Paje, TZ", label: "Paje, TZ", code: "PAJE" },
	{ value: "Uroa, TZ", label: "Uroa, TZ", code: "UROA" },
	{ value: "Kendwa, TZ", label: "Kendwa, TZ", code: "KNDW-6" },
	{ value: "Michamvi Kae, TZ", label: "Michamvi Kae, TZ", code: "MKAE-6" },
	{ value: "Nungwi, TZ", label: "Nungwi, TZ", code: "NNGW-6" },
	{ value: "Bagamoyo, TZ", label: "Bagamoyo, TZ", code: "bag-123" },
	{ value: "Lukuba Island, TZ", label: "Lukuba Island, TZ", code: "LKW" },
	{
		value: "Parque nacional Arusha, TZ",
		label: "Parque nacional Arusha, TZ",
		code: "ARSH",
	},
	{ value: "Empakai Crater, TZ", label: "Empakai Crater, TZ", code: "EMPK" },
	{ value: "Lamadai, TZ", label: "Lamadai, TZ", code: "JUV2" },
	{ value: "Musoma, TZ", label: "Musoma, TZ", code: "MUZ2" },
	{ value: "Olmoti Crater, TZ", label: "Olmoti Crater, TZ", code: "OLMT" },
	{ value: "Seronera, TZ", label: "Seronera, TZ", code: "SRNR" },
	{
		value: "Parque Nacional Tarangire, TZ",
		label: "Parque Nacional Tarangire, TZ",
		code: "TART",
	},
	{
		value: "Costa Este (Zanzibar), TZ",
		label: "Costa Este (Zanzibar), TZ",
		code: "ECZ-1",
	},
	{ value: "Fumba, TZ", label: "Fumba, TZ", code: "FMB-1" },
	{ value: "Kizimkazi, TZ", label: "Kizimkazi, TZ", code: "KZM-1" },
	{ value: "Makunduchi, TZ", label: "Makunduchi, TZ", code: "MKD-1" },
	{ value: "Matemwe, TZ", label: "Matemwe, TZ", code: "MTW-1" },
	{
		value: "Costa Norte (Zanzibar), TZ",
		label: "Costa Norte (Zanzibar), TZ",
		code: "NCZ-1",
	},
	{
		value: "Costa Noreste (Zanzibar), TZ",
		label: "Costa Noreste (Zanzibar), TZ",
		code: "NEC-1",
	},
	{
		value: "Costa Sur (Zanzibar), TZ",
		label: "Costa Sur (Zanzibar), TZ",
		code: "SCZ-1",
	},
	{
		value: "Costa Sureste (Zanzibar), TZ",
		label: "Costa Sureste (Zanzibar), TZ",
		code: "SEC-1",
	},
	{
		value: "Costa Oeste (Zanzibar), TZ",
		label: "Costa Oeste (Zanzibar), TZ",
		code: "WCZ-1",
	},
	{ value: "Lago Eyasi, TZ", label: "Lago Eyasi, TZ", code: "lkysi" },
	{ value: "Pangani, TZ", label: "Pangani, TZ", code: "panga" },
	{ value: "Kiwengwa, TZ", label: "Kiwengwa, TZ", code: "KWGW-6" },
	{
		value: "Parque nacional Ruaha, TZ",
		label: "Parque nacional Ruaha, TZ",
		code: "RYAGYA",
	},
	{
		value: " Parque nacional de Saelous, TZ",
		label: " Parque nacional de Saelous, TZ",
		code: "ertyui",
	},
	{ value: "Namibia, TZ", label: "Namibia, TZ", code: "Namibia" },
	{ value: "Lago Natron , TZ", label: "Lago Natron , TZ", code: "knatron" },
	{
		value: "Montañas Usambara, TZ",
		label: "Montañas Usambara, TZ",
		code: "usambara",
	},
	{
		value: "Isla de Pemba , TZ",
		label: "Isla de Pemba , TZ",
		code: "isladepemba",
	},
	{ value: "Bangkok, TH", label: "Bangkok, TH", code: "BKK" },
	{ value: "Hua hin, TH", label: "Hua hin, TH", code: "HHQ" },
	{ value: "Phuket, TH", label: "Phuket, TH", code: "HKT" },
	{ value: "Islas Phi Phi , TH", label: "Islas Phi Phi , TH", code: "PHZ" },
	{ value: "Koh Samui, TH", label: "Koh Samui, TH", code: "USM" },
	{ value: "Pai, TH", label: "Pai, TH", code: "XX6-1" },
	{ value: "Koh Samui Isla, TH", label: "Koh Samui Isla, TH", code: "USM-22" },
	{ value: "Mae Hong Son, TH", label: "Mae Hong Son, TH", code: "TH2" },
	{ value: "Chiang Rai , TH", label: "Chiang Rai , TH", code: "CEI" },
	{ value: "Chiang Mai, TH", label: "Chiang Mai, TH", code: "CNX" },
	{ value: "Phitsanulok, TH", label: "Phitsanulok, TH", code: "TH6" },
	{ value: "Krabi, TH", label: "Krabi, TH", code: "KBV" },
	{ value: "Phetchabun, TH", label: "Phetchabun, TH", code: "PHY" },
	{ value: "Pattaya, TH", label: "Pattaya, TH", code: "PYX" },
	{ value: "Kanchanaburi , TH", label: "Kanchanaburi , TH", code: "TH1" },
	{ value: "Koh Lanta , TH", label: "Koh Lanta , TH", code: "TH4" },
	{ value: "Khao Lak, TH", label: "Khao Lak, TH", code: "TH5" },
	{ value: "Koh Samet , TH", label: "Koh Samet , TH", code: "TH7" },
	{ value: "Ayutthaya, TH", label: "Ayutthaya, TH", code: "XT1" },
	{ value: "Cha Am , TH", label: "Cha Am , TH", code: "HHQ-1" },
	{ value: "Koh Yao Noi, TH", label: "Koh Yao Noi, TH", code: "HKT-18" },
	{
		value: "Samut Songkhram, TH",
		label: "Samut Songkhram, TH",
		code: "SSC-65",
	},
	{ value: "Ko Yao Yai, TH", label: "Ko Yao Yai, TH", code: "HKT-181" },
	{ value: "Nan, TH", label: "Nan, TH", code: "NAN-002" },
	{ value: "Buriram, TH", label: "Buriram, TH", code: "BFV" },
	{ value: "Chumphon, TH", label: "Chumphon, TH", code: "CJM" },
	{ value: "Koh Chang, TH", label: "Koh Chang, TH", code: "TDX" },
	{ value: "Koh Tao, TH", label: "Koh Tao, TH", code: "TX9" },
	{ value: "Sukhothai, TH", label: "Sukhothai, TH", code: "XX7" },
	{
		value: "Pak Chong (Khao Yai), TH",
		label: "Pak Chong (Khao Yai), TH",
		code: "XX8",
	},
	{ value: "Cha-Am, TH", label: "Cha-Am, TH", code: "1HHQ" },
	{ value: "Chiang Saen, TH", label: "Chiang Saen, TH", code: "CEI-2" },
	{ value: "Chiang Dao, TH", label: "Chiang Dao, TH", code: "CNX-3" },
	{ value: "Pranburi, TH", label: "Pranburi, TH", code: "HHQ-3" },
	{ value: "Patong, TH", label: "Patong, TH", code: "HKT-3" },
	{ value: "Karon, TH", label: "Karon, TH", code: "HKT-6" },
	{ value: "Hat Yai, TH", label: "Hat Yai, TH", code: "HYA-2" },
	{ value: " Koh Kho Khao, TH", label: " Koh Kho Khao, TH", code: "KKT-2" },
	{ value: "Lampang, TH", label: "Lampang, TH", code: "LPT-3" },
	{ value: "Jomtien, TH", label: "Jomtien, TH", code: "PYX-4" },
	{ value: "Koh Phangan, TH", label: "Koh Phangan, TH", code: "TX8-1" },
	{ value: "Surat Thani, TH", label: "Surat Thani, TH", code: "URT-1" },
	{ value: "Maret, TH", label: "Maret, TH", code: "USM-1" },
	{ value: "Bophut, TH", label: "Bophut, TH", code: "USM-4" },
	{ value: "Doi Inthanon, TH", label: "Doi Inthanon, TH", code: "DONON-6" },
	{ value: "Mae Chan, TH", label: "Mae Chan, TH", code: "MCHAN-6" },
	{ value: "Phayao, TH", label: "Phayao, TH", code: "PHYAO-6" },
	{ value: "Chiang Khong, TH", label: "Chiang Khong, TH", code: "CKHONG-6" },
	{ value: "Koh Lipe, TH", label: "Koh Lipe, TH", code: "fake-707" },
	{ value: "Lopburi, TH", label: "Lopburi, TH", code: "fake-823" },
	{ value: "Aranyaprathet, TH", label: "Aranyaprathet, TH", code: "NAK" },
	{ value: "Pai, TH", label: "Pai, TH", code: "PAI" },
	{ value: "Banpuggard, TH", label: "Banpuggard, TH", code: "BNGG" },
	{ value: "Mae Nam, TH", label: "Mae Nam, TH", code: "BNMM" },
	{ value: "Choeng Mon, TH", label: "Choeng Mon, TH", code: "CHOE" },
	{ value: "Koh Phayam, TH", label: "Koh Phayam, TH", code: "KAW1" },
	{ value: "Khon Kaen, TH", label: "Khon Kaen, TH", code: "KKC1" },
	{
		value: "Nakhon Ratchasima, TH",
		label: "Nakhon Ratchasima, TH",
		code: "NAK2",
	},
	{ value: "Taling Ngam, TH", label: "Taling Ngam, TH", code: "TLNG" },
	{ value: "Bo Put, TH", label: "Bo Put, TH", code: "BOP-6" },
	{ value: "Chaweng, TH", label: "Chaweng, TH", code: "CHWNG" },
	{
		value: "Don Mueang Aeropuerto Internacional, TH",
		label: "Don Mueang Aeropuerto Internacional, TH",
		code: "DMK-1",
	},
	{ value: "Donsak, TH", label: "Donsak, TH", code: "DON-6" },
	{ value: "Khok Kloi, TH", label: "Khok Kloi, TH", code: "KHK-1" },
	{ value: "Ko Bulon Le, TH", label: "Ko Bulon Le, TH", code: "KOB-6" },
	{ value: "Koh Mak, TH", label: "Koh Mak, TH", code: "KOM-1" },
	{ value: "Ko Muk, TH", label: "Ko Muk, TH", code: "KOM-6" },
	{ value: "Lipa Noi, TH", label: "Lipa Noi, TH", code: "LPNOI" },
	{ value: "Nathon Town, TH", label: "Nathon Town, TH", code: "NAT-6" },
	{ value: "Rayong, TH", label: "Rayong, TH", code: "RAYNG" },
	{ value: "Rawai, TH", label: "Rawai, TH", code: "Rawai" },
	{ value: "Koh Kood, TH", label: "Koh Kood, TH", code: "TDX-1" },
	{ value: "Trang, TH", label: "Trang, TH", code: "TRA-6" },
	{ value: "Bang Rak, TH", label: "Bang Rak, TH", code: "bngrk" },
	{ value: "Ban Phe, TH", label: "Ban Phe, TH", code: "BAPH-1" },
	{ value: "Khanom, TH", label: "Khanom, TH", code: "KHAN-1" },
	{ value: "Ko Kradan, TH", label: "Ko Kradan, TH", code: "KOHK-6" },
	{ value: "Koh Ngai, TH", label: "Koh Ngai, TH", code: "KOHN-6" },
	{ value: "Ko Libong, TH", label: "Ko Libong, TH", code: "KOLI-1" },
	{ value: "Ko Tarutao, TH", label: "Ko Tarutao, TH", code: "KOTA-1" },
	{
		value: "Parque nacional de Khao Sok, TH",
		label: "Parque nacional de Khao Sok, TH",
		code: "KSNP-1",
	},
	{ value: "Pa Daet , TH", label: "Pa Daet , TH", code: "PaDaet" },
	{
		value: "Suvarnabhumi Aeropuerto Internacional, TH",
		label: "Suvarnabhumi Aeropuerto Internacional, TH",
		code: "SUBA-1",
	},
	{ value: "Trat, TH", label: "Trat, TH", code: "TRAT-6" },
	{ value: "Lamai, TH", label: "Lamai, TH", code: "USMLAM" },
	{ value: "Loei, TH", label: "Loei, TH", code: "LOE-002" },
	{ value: "Mae Rim, TH", label: "Mae Rim, TH", code: "MRM-002" },
	{ value: "Nong Thale, TH", label: "Nong Thale, TH", code: "NGT-002" },
	{
		value: "Ubon Ratchathani, TH",
		label: "Ubon Ratchathani, TH",
		code: "UBR-002",
	},
	{ value: "Udon Thani, TH", label: "Udon Thani, TH", code: "UDT-002" },
	{ value: "Mueang Kao, TH", label: "Mueang Kao, TH", code: "MueangKao" },
	{ value: "Racha Thewa, TH", label: "Racha Thewa, TH", code: "RachaThewa" },
	{ value: "Amsterdam, NL", label: "Amsterdam, NL", code: "AMS" },
	{ value: "Epe, NL", label: "Epe, NL", code: "EPE-002" },
	{ value: "La Haya, NL", label: "La Haya, NL", code: "HAG-1" },
	{ value: "Eindhoven, NL", label: "Eindhoven, NL", code: "EIN" },
	{ value: "Breda, NL", label: "Breda, NL", code: "GLZ" },
	{ value: "Middelburg, NL", label: "Middelburg, NL", code: "MIB" },
	{ value: "Maastrich, NL", label: "Maastrich, NL", code: "MST" },
	{ value: "Nimega, NL", label: "Nimega, NL", code: "NIJ" },
	{ value: "Soest, NL", label: "Soest, NL", code: "UTC2" },
	{ value: "Scheveningen, NL", label: "Scheveningen, NL", code: "HAG-3" },
	{ value: "Delft, NL", label: "Delft, NL", code: "HAG-5" },
	{ value: "Rotterdam, NL", label: "Rotterdam, NL", code: "RTM-1" },
	{ value: "Utrecht, NL", label: "Utrecht, NL", code: "UTC-1" },
	{ value: "Groninga, NL", label: "Groninga, NL", code: "GNG" },
	{ value: "Hook of Holland, NL", label: "Hook of Holland, NL", code: "HOH-2" },
	{ value: "Bolduque, NL", label: "Bolduque, NL", code: "fake-233" },
	{ value: "Almere, NL", label: "Almere, NL", code: "fake-545" },
	{ value: "Volendam, NL", label: "Volendam, NL", code: "fake-844" },
	{ value: "Anjum, NL", label: "Anjum, NL", code: "ANJM" },
	{ value: "Callantsoog, NL", label: "Callantsoog, NL", code: "CLLN" },
	{ value: "De Bult, NL", label: "De Bult, NL", code: "DBLT" },
	{ value: "Delfzijl, NL", label: "Delfzijl, NL", code: "DELF" },
	{ value: "Giethoorn, NL", label: "Giethoorn, NL", code: "GTHN" },
	{ value: "Hellendoorn, NL", label: "Hellendoorn, NL", code: "HLLN" },
	{ value: "Medemblik, NL", label: "Medemblik, NL", code: "MDNL" },
	{ value: "Vlagtwedde, NL", label: "Vlagtwedde, NL", code: "VLAG" },
	{ value: "Arcen, NL", label: "Arcen, NL", code: "ARC-6" },
	{ value: "Assen, NL", label: "Assen, NL", code: "ASS-6" },
	{ value: "Borne, NL", label: "Borne, NL", code: "BOR-6" },
	{ value: "Deventer, NL", label: "Deventer, NL", code: "DEV-6" },
	{ value: "Eelde, NL", label: "Eelde, NL", code: "EEL-6" },
	{ value: "Gieten, NL", label: "Gieten, NL", code: "GIE-6" },
	{ value: "Hardenberg, NL", label: "Hardenberg, NL", code: "HAR-6" },
	{ value: "Hengelo, NL", label: "Hengelo, NL", code: "HEN-6" },
	{ value: "Hooghalen, NL", label: "Hooghalen, NL", code: "HOO-6" },
	{ value: "Lunteren, NL", label: "Lunteren, NL", code: "LUN-6" },
	{ value: "Nes, NL", label: "Nes, NL", code: "NES-1" },
	{ value: "Nijverdal, NL", label: "Nijverdal, NL", code: "NIJ-6" },
	{ value: "Nooitgedacht, NL", label: "Nooitgedacht, NL", code: "NOO-6" },
	{ value: "Norg, NL", label: "Norg, NL", code: "NOR-6" },
	{ value: "Oldenzaal, NL", label: "Oldenzaal, NL", code: "OLD-6" },
	{ value: "Ommen, NL", label: "Ommen, NL", code: "OMM-6" },
	{ value: "Raalte, NL", label: "Raalte, NL", code: "RAA-6" },
	{ value: "Roden, NL", label: "Roden, NL", code: "ROD-6" },
	{ value: "Veenhuizen, NL", label: "Veenhuizen, NL", code: "VEE-6" },
	{ value: "Vries, NL", label: "Vries, NL", code: "VRI-6" },
	{ value: "Wierden, NL", label: "Wierden, NL", code: "WIE-6" },
	{ value: "Zuidlaren, NL", label: "Zuidlaren, NL", code: "ZUI-6" },
	{ value: "Zwolle , NL", label: "Zwolle , NL", code: "ZWO-6" },
	{ value: "Tilburg, NL", label: "Tilburg, NL", code: "741258" },
	{
		value: "Alphen aan den Rijn, NL",
		label: "Alphen aan den Rijn, NL",
		code: "AADR-1",
	},
	{ value: "Akkrum, NL", label: "Akkrum, NL", code: "AKKR-1" },
	{ value: "Almelo, NL", label: "Almelo, NL", code: "ALM-64" },
	{ value: "Ameland, NL", label: "Ameland, NL", code: "AMEL-1" },
	{ value: "Andijk, NL", label: "Andijk, NL", code: "ANDI-6" },
	{ value: "Ballum, NL", label: "Ballum, NL", code: "BALL-1" },
	{ value: "Bolsward, NL", label: "Bolsward, NL", code: "BOLS-1" },
	{ value: "Burgum, NL", label: "Burgum, NL", code: "BURG-1" },
	{ value: "Dalfsen, NL", label: "Dalfsen, NL", code: "DALF-6" },
	{ value: "Den Burg, NL", label: "Den Burg, NL", code: "DEBU-1" },
	{ value: "De Koog, NL", label: "De Koog, NL", code: "DEKO-1" },
	{ value: "Delfstrahuizen, NL", label: "Delfstrahuizen, NL", code: "DELF-1" },
	{ value: "Dokkum, NL", label: "Dokkum, NL", code: "DOKK-1" },
	{ value: "Drachten, NL", label: "Drachten, NL", code: "DRAC-1" },
	{ value: "Drente, NL", label: "Drente, NL", code: "DREN-1" },
	{ value: "Enschede, NL", label: "Enschede, NL", code: "ENSC-1" },
	{ value: "Frisia, NL", label: "Frisia, NL", code: "FRIE-1" },
	{ value: "Gaastmeer, NL", label: "Gaastmeer, NL", code: "GAAS-1" },
	{ value: "Güeldres, NL", label: "Güeldres, NL", code: "GELD-1" },
	{ value: "Genemuiden, NL", label: "Genemuiden, NL", code: "GENE-6" },
	{ value: "Goor, NL", label: "Goor, NL", code: "GOOR-6" },
	{ value: "Gorcum, NL", label: "Gorcum, NL", code: "GORI-1" },
	{ value: "Gouda, NL", label: "Gouda, NL", code: "GOUD-1" },
	{ value: "Grou, NL", label: "Grou, NL", code: "GROU-1" },
	{ value: "Haaksbergen, NL", label: "Haaksbergen, NL", code: "HAAK-6" },
	{ value: "Heerenveen, NL", label: "Heerenveen, NL", code: "HEER-1" },
	{ value: "Hollum, NL", label: "Hollum, NL", code: "HOLL-2" },
	{ value: "Holwerd, NL", label: "Holwerd, NL", code: "HOLW-1" },
	{ value: "Hof van Twente, NL", label: "Hof van Twente, NL", code: "HVTW-6" },
	{ value: "Kaatsheuvel, NL", label: "Kaatsheuvel, NL", code: "KAAT-1" },
	{
		value: "Katwijk aan Zee, NL",
		label: "Katwijk aan Zee, NL",
		code: "KAAZ-1",
	},
	{ value: "Kampen, NL", label: "Kampen, NL", code: "KAMP-6" },
	{ value: "Kloosterzande, NL", label: "Kloosterzande, NL", code: "KLOO-1" },
	{ value: "Krabbendijke, NL", label: "Krabbendijke, NL", code: "KRAB-1" },
	{ value: "Leerdam, NL", label: "Leerdam, NL", code: "LEER-1" },
	{ value: "Leeuwarden, NL", label: "Leeuwarden, NL", code: "LEEU-1" },
	{ value: "Leiden, NL", label: "Leiden, NL", code: "LEID-1" },
	{ value: "Lemmer, NL", label: "Lemmer, NL", code: "LEMM-1" },
	{ value: "Lisse, NL", label: "Lisse, NL", code: "LISS-1" },
	{ value: "Makkum, NL", label: "Makkum, NL", code: "MAKK-1" },
	{ value: "Midsland, NL", label: "Midsland, NL", code: "MIDS-1" },
	{ value: "Nieuwerkerk, NL", label: "Nieuwerkerk, NL", code: "NIEU-1" },
	{ value: "Nieuwkoop, NL", label: "Nieuwkoop, NL", code: "NIEU-2" },
	{ value: "Noordwijk, NL", label: "Noordwijk, NL", code: "NOOR-1" },
	{ value: "Oosterend, NL", label: "Oosterend, NL", code: "OOST-1" },
	{ value: "Oost-Vlieland, NL", label: "Oost-Vlieland, NL", code: "OOVL-1" },
	{ value: "Ouwerkerk, NL", label: "Ouwerkerk, NL", code: "OUWE-1" },
	{ value: "Overijssel, NL", label: "Overijssel, NL", code: "OVER-1" },
	{ value: "Rijssen, NL", label: "Rijssen, NL", code: "RIJS-6" },
	{ value: "Schiedam, NL", label: "Schiedam, NL", code: "SCHI-1" },
	{
		value: "Schiermonnikoog, NL",
		label: "Schiermonnikoog, NL",
		code: "SCHI-2",
	},
	{
		value: "&apos;s-Gravenzande, NL",
		label: "&apos;s-Gravenzande, NL",
		code: "SGRA-1",
	},
	{
		value: "Sint Philipsland, NL",
		label: "Sint Philipsland, NL",
		code: "SIPH-1",
	},
	{ value: "Sneek, NL", label: "Sneek, NL", code: "SNEE-1" },
	{
		value: "Holanda Meridional, NL",
		label: "Holanda Meridional, NL",
		code: "SOHO-1",
	},
	{ value: "Spijkenisse, NL", label: "Spijkenisse, NL", code: "SPIJ-1" },
	{ value: "Staphorst, NL", label: "Staphorst, NL", code: "STAP-6" },
	{ value: "Ter Aar, NL", label: "Ter Aar, NL", code: "TEAA-1" },
	{ value: "Terschelling, NL", label: "Terschelling, NL", code: "TERS-1" },
	{ value: "Texel, NL", label: "Texel, NL", code: "TEXE-1" },
	{ value: "Valkenburg , NL", label: "Valkenburg , NL", code: "VALK-6" },
	{ value: "Vinkeveen, NL", label: "Vinkeveen, NL", code: "VINK-6" },
	{ value: "Vlieland, NL", label: "Vlieland, NL", code: "VLIE-1" },
	{
		value: "West-Terschelling, NL",
		label: "West-Terschelling, NL",
		code: "WETE-1",
	},
	{ value: "Winterswijk, NL", label: "Winterswijk, NL", code: "WINT-1" },
	{ value: "Workum, NL", label: "Workum, NL", code: "WORK-1" },
	{ value: "Zelanda, NL", label: "Zelanda, NL", code: "ZEEL-1" },
	{ value: "Zoetermeer, NL", label: "Zoetermeer, NL", code: "ZOET-1" },
	{ value: "Arnhem, NL", label: "Arnhem, NL", code: "ANM-002" },
	{ value: "Apeldoorn, NL", label: "Apeldoorn, NL", code: "APL-002" },
	{ value: "Borger, NL", label: "Borger, NL", code: "BGR-002" },
	{ value: "Brouwershaven, NL", label: "Brouwershaven, NL", code: "BHN-002" },
	{ value: "Beekbergen, NL", label: "Beekbergen, NL", code: "BKN-002" },
	{ value: "Breskens, NL", label: "Breskens, NL", code: "BKS-002" },
	{ value: "Beilen, NL", label: "Beilen, NL", code: "BLN-002" },
	{ value: "Barneveld, NL", label: "Barneveld, NL", code: "BND-002" },
	{ value: "Borssele, NL", label: "Borssele, NL", code: "BRL-002" },
	{ value: "Buren, NL", label: "Buren, NL", code: "BRN-002" },
	{ value: "Cadzand, NL", label: "Cadzand, NL", code: "CDZ-002" },
	{ value: "De Cocksdorp, NL", label: "De Cocksdorp, NL", code: "DCP-002" },
	{ value: "Den Hoorn, NL", label: "Den Hoorn, NL", code: "DHN-002" },
	{ value: "Dalen, NL", label: "Dalen, NL", code: "DLN-002" },
	{ value: "Domburg, NL", label: "Domburg, NL", code: "DMG-002" },
	{ value: "Dieren, NL", label: "Dieren, NL", code: "DRN-002" },
	{ value: "Duiven, NL", label: "Duiven, NL", code: "DVN-002" },
	{ value: "Diever, NL", label: "Diever, NL", code: "DVR-002" },
	{ value: "Emmen, NL", label: "Emmen, NL", code: "EMN-002" },
	{ value: "Emst, NL", label: "Emst, NL", code: "EST-002" },
	{ value: "Exloo, NL", label: "Exloo, NL", code: "EXO-002" },
	{ value: "Geldermalsen, NL", label: "Geldermalsen, NL", code: "GLD-002" },
	{ value: "Hoogeveen, NL", label: "Hoogeveen, NL", code: "HGN-002" },
	{ value: "Hoenderloo, NL", label: "Hoenderloo, NL", code: "HLO-002" },
	{ value: "Hulshorst, NL", label: "Hulshorst, NL", code: "HSH-002" },
	{ value: "Hulst, NL", label: "Hulst, NL", code: "HUT-002" },
	{ value: "Harderwijk, NL", label: "Harderwijk, NL", code: "HWK-002" },
	{ value: "Heerlen, NL", label: "Heerlen, NL", code: "Heerlen" },
	{ value: "Kamperland, NL", label: "Kamperland, NL", code: "KLD-002" },
	{ value: "Lochem, NL", label: "Lochem, NL", code: "LCM-002" },
	{ value: "Nieuwvliet, NL", label: "Nieuwvliet, NL", code: "NLT-002" },
	{ value: "Nunspeet, NL", label: "Nunspeet, NL", code: "NSP-002" },
	{ value: "Oostburg, NL", label: "Oostburg, NL", code: "OBG-002" },
	{ value: "Ossenzijl, NL", label: "Ossenzijl, NL", code: "OSSEN-6" },
	{ value: "Philippine, NL", label: "Philippine, NL", code: "PHE-002" },
	{ value: "Pesse, NL", label: "Pesse, NL", code: "PSE-002" },
	{ value: "Rozendaal, NL", label: "Rozendaal, NL", code: "RDL-002" },
	{ value: "Ruinen, NL", label: "Ruinen, NL", code: "RNN-002" },
	{ value: "Smilde, NL", label: "Smilde, NL", code: "SDE-002" },
	{ value: "Scharendijke, NL", label: "Scharendijke, NL", code: "SKE-002" },
	{ value: "Sluis, NL", label: "Sluis, NL", code: "SUS-002" },
	{ value: "Tiel, NL", label: "Tiel, NL", code: "TEL-002" },
	{
		value: "Sint-Maartensdijk, NL",
		label: "Sint-Maartensdijk, NL",
		code: "TMK-002",
	},
	{ value: "Terneuzen, NL", label: "Terneuzen, NL", code: "TZN-002" },
	{ value: "Veenendaal, NL", label: "Veenendaal, NL", code: "VDL-002" },
	{ value: "Flesinga, NL", label: "Flesinga, NL", code: "VSN-002" },
	{ value: "Waardenburg, NL", label: "Waardenburg, NL", code: "WDG-002" },
	{ value: "Wageningen, NL", label: "Wageningen, NL", code: "WGN-002" },
	{ value: "Westkapelle, NL", label: "Westkapelle, NL", code: "WPL-002" },
	{ value: "Westenschouwen, NL", label: "Westenschouwen, NL", code: "WWN-002" },
	{ value: "Zuidwolde, NL", label: "Zuidwolde, NL", code: "ZDE-002" },
	{ value: "Zoutelande, NL", label: "Zoutelande, NL", code: "ZOE-002" },
	{ value: "Zutphen, NL", label: "Zutphen, NL", code: "ZPN-002" },
	{ value: "Zaanstad, NL", label: "Zaanstad, NL", code: "ZTD-002" },
	{ value: "Zurich NL, NL", label: "Zurich NL, NL", code: "ZURNL-1" },
	{ value: "Dordrecht, NL", label: "Dordrecht, NL", code: "DDRECH-6" },
	{ value: "Gooise Meren, NL", label: "Gooise Meren, NL", code: "GOOISE-6" },
	{ value: "IJmuiden, NL", label: "IJmuiden, NL", code: "Ijmuiden" },
	{ value: "Haarlem, NL", label: "Haarlem, NL", code: "HAARLEM-6" },
	{ value: "Harlingen, NL", label: "Harlingen, NL", code: "Harlingen" },
	{ value: "Dili, TL", label: "Dili, TL", code: "DILI-6" },
	{ value: "Lomé, TG", label: "Lomé, TG", code: "LFW" },
	{
		value: "Fua&apos;amotu  International , TO",
		label: "Fua&apos;amotu  International , TO",
		code: "294",
	},
	{ value: "Isla Foa, TO", label: "Isla Foa, TO", code: "FOA" },
	{
		value: "Vava&apos;u Islands, TO",
		label: "Vava&apos;u Islands, TO",
		code: "VAV",
	},
	{ value: "Lifuka, TO", label: "Lifuka, TO", code: "LIFK" },
	{ value: "Neiafu, TO", label: "Neiafu, TO", code: "TNGA" },
	{ value: "Nukualofa, TO", label: "Nukualofa, TO", code: "TONGA" },
	{ value: "Isla Tongatapu, TO", label: "Isla Tongatapu, TO", code: "TONGA1" },
	{ value: "Trinidad, TT", label: "Trinidad, TT", code: "TIN-1" },
	{ value: "Tobago, TT", label: "Tobago, TT", code: "TOB" },
	{ value: "Túnez, TN", label: "Túnez, TN", code: "TOE" },
	{ value: "Djerba , TN", label: "Djerba , TN", code: "DJE" },
	{ value: "Hammamet, TN", label: "Hammamet, TN", code: "HMM" },
	{ value: "Monastir, TN", label: "Monastir, TN", code: "MIR" },
	{
		value: "Port El Kantaoui , TN",
		label: "Port El Kantaoui , TN",
		code: "TN1",
	},
	{ value: "Tozeur, TN", label: "Tozeur, TN", code: "TO1" },
	{ value: "Susa, TN", label: "Susa, TN", code: "TN1-2" },
	{ value: "Bizerta, TN", label: "Bizerta, TN", code: "BZT-76" },
	{ value: "Mahdía, TN", label: "Mahdía, TN", code: "MH1" },
	{ value: "Sfax, TN", label: "Sfax, TN", code: "SFA" },
	{ value: "Zarzis, TN", label: "Zarzis, TN", code: "DJE-2" },
	{ value: "La Marsa, TN", label: "La Marsa, TN", code: "TOE-2" },
	{ value: "Cap Bon, TN", label: "Cap Bon, TN", code: "CPB-67" },
	{ value: "Gabés, TN", label: "Gabés, TN", code: "GAB-56" },
	{ value: "Sangho, TN", label: "Sangho, TN", code: "SGH-71" },
	{ value: "Tabarka, TN", label: "Tabarka, TN", code: "TBK-77" },
	{ value: "Tataouine, TN", label: "Tataouine, TN", code: "TTO-88" },
	{ value: "Cairuán, TN", label: "Cairuán, TN", code: "KAI-6" },
	{ value: "Matmata, TN", label: "Matmata, TN", code: "MAT-6" },
	{ value: "Sbeitla, TN", label: "Sbeitla, TN", code: "SBE-6" },
	{ value: "Douz, TN", label: "Douz, TN", code: "DOUZ-6" },
	{ value: "Medinine, TN", label: "Medinine, TN", code: "MEDI-6" },
	{ value: "Hammam Sousse, TN", label: "Hammam Sousse, TN", code: "SOUSSE-6" },
	{ value: "Estambul, TR", label: "Estambul, TR", code: "IST" },
	{ value: "Marmaris, TR", label: "Marmaris, TR", code: "DLM" },
	{ value: "Ankara, TR", label: "Ankara, TR", code: "ANK" },
	{ value: "Bodrum, TR", label: "Bodrum, TR", code: "BJV" },
	{ value: "Eskisehir, TR", label: "Eskisehir, TR", code: "ESK" },
	{ value: "Kahramanmaraş, TR", label: "Kahramanmaraş, TR", code: "KCM" },
	{
		value: "Hakkari Yuksekova, TR",
		label: "Hakkari Yuksekova, TR",
		code: "HYS-2",
	},
	{ value: "Kemer, TR", label: "Kemer, TR", code: "KEM-3" },
	{ value: "Mersin, TR", label: "Mersin, TR", code: "MRI-1" },
	{ value: "Beyoglu, TR", label: "Beyoglu, TR", code: "BYG-002" },
	{ value: "Adana, TR", label: "Adana, TR", code: "ADA" },
	{ value: "Kusadasi, TR", label: "Kusadasi, TR", code: "ADB" },
	{ value: "Adiyaman, TR", label: "Adiyaman, TR", code: "ADF" },
	{ value: "Afyonkarahisar, TR", label: "Afyonkarahisar, TR", code: "AFY" },
	{ value: "Aydin, TR", label: "Aydin, TR", code: "AYD" },
	{ value: "Antalya, TR", label: "Antalya, TR", code: "AYT" },
	{ value: "Burdur, TR", label: "Burdur, TR", code: "BDR" },
	{ value: "Balikesir, TR", label: "Balikesir, TR", code: "BKR" },
	{ value: "Bitlis, TR", label: "Bitlis, TR", code: "BTI" },
	{ value: "Bursa, TR", label: "Bursa, TR", code: "BTZ" },
	{ value: "Diyarbakir, TR", label: "Diyarbakir, TR", code: "DIY" },
	{ value: "Edirne, TR", label: "Edirne, TR", code: "EDR" },
	{ value: "Elazig, TR", label: "Elazig, TR", code: "EZS" },
	{ value: "Fethiye, TR", label: "Fethiye, TR", code: "FET" },
	{ value: "Hatay, TR", label: "Hatay, TR", code: "HTY" },
	{ value: "Izmir, TR", label: "Izmir, TR", code: "IGL" },
	{ value: "Konya, TR", label: "Konya, TR", code: "KYA" },
	{ value: "Bolu, TR", label: "Bolu, TR", code: "OLU" },
	{ value: "Rize, TR", label: "Rize, TR", code: "RIZ" },
	{ value: "Safranbolu, TR", label: "Safranbolu, TR", code: "SBO" },
	{ value: "Sakarya, TR", label: "Sakarya, TR", code: "SRY" },
	{ value: "Samsun, TR", label: "Samsun, TR", code: "SSX" },
	{ value: "Canakkale, TR", label: "Canakkale, TR", code: "TR1" },
	{ value: "Gaziantep, TR", label: "Gaziantep, TR", code: "TR2" },
	{ value: "Trebisonda, TR", label: "Trebisonda, TR", code: "TZX" },
	{ value: "Alanya, TR", label: "Alanya, TR", code: "ADN-3" },
	{ value: "Didim, TR", label: "Didim, TR", code: "ALK-3" },
	{ value: "Kayseri, TR", label: "Kayseri, TR", code: "ASR-1" },
	{ value: "Göreme, TR", label: "Göreme, TR", code: "CAP-1" },
	{ value: "Dalyan, TR", label: "Dalyan, TR", code: "DLM-3" },
	{ value: "Sarigerme, TR", label: "Sarigerme, TR", code: "DLM-4" },
	{ value: "Gocek, TR", label: "Gocek, TR", code: "FET-3" },
	{ value: "Cesme, TR", label: "Cesme, TR", code: "IGL-3" },
	{ value: "Ozdere, TR", label: "Ozdere, TR", code: "IGL-4" },
	{ value: "Belek, TR", label: "Belek, TR", code: "IZM-3" },
	{ value: "Patara, TR", label: "Patara, TR", code: "KAL-3" },
	{ value: "Kalkan, TR", label: "Kalkan, TR", code: "KAL-4" },
	{ value: "Olympos, TR", label: "Olympos, TR", code: "OLP-3" },
	{ value: "Mudurnu, TR", label: "Mudurnu, TR", code: "OLU-1" },
	{ value: "Ordu, TR", label: "Ordu, TR", code: "RDU-1" },
	{ value: "Side, TR", label: "Side, TR", code: "SID-3" },
	{ value: "Mardin, TR", label: "Mardin, TR", code: "msa-1" },
	{ value: "Kas, TR", label: "Kas, TR", code: "KAS1-3" },
	{ value: "Nevsehir, TR", label: "Nevsehir, TR", code: "NAV-30" },
	{ value: "Batman, TR", label: "Batman, TR", code: "fake-635" },
	{ value: "Dalaman, TR", label: "Dalaman, TR", code: "fake-636" },
	{ value: "Usak, TR", label: "Usak, TR", code: "fake-637" },
	{ value: "Sivas, TR", label: "Sivas, TR", code: "fake-638" },
	{ value: "Amasya, TR", label: "Amasya, TR", code: "fake-639" },
	{ value: "Cesme, TR", label: "Cesme, TR", code: "610" },
	{ value: "Demre, TR", label: "Demre, TR", code: "DRE" },
	{ value: "Erzurum, TR", label: "Erzurum, TR", code: "ERZ" },
	{ value: "Kocaeli, TR", label: "Kocaeli, TR", code: "KCO" },
	{ value: "Kastamonu, TR", label: "Kastamonu, TR", code: "KFS" },
	{ value: "Kars, TR", label: "Kars, TR", code: "KSY" },
	{ value: "Kutahya, TR", label: "Kutahya, TR", code: "KUT" },
	{ value: "Malatya, TR", label: "Malatya, TR", code: "MLX" },
	{ value: "Manisa, TR", label: "Manisa, TR", code: "MSA" },
	{ value: "Zonguldak, TR", label: "Zonguldak, TR", code: "ONQ" },
	{ value: "Sanliurfa, TR", label: "Sanliurfa, TR", code: "SFQ" },
	{ value: "Tekirdag, TR", label: "Tekirdag, TR", code: "TEQ" },
	{ value: "Pamukkale, TR", label: "Pamukkale, TR", code: "TR3" },
	{ value: "Van, TR", label: "Van, TR", code: "VAN" },
	{ value: "Yalova, TR", label: "Yalova, TR", code: "YAV" },
	{ value: "Denizli, TR", label: "Denizli, TR", code: "TR-3" },
	{ value: "Turgutreis, TR", label: "Turgutreis, TR", code: "BJV-1" },
	{ value: "Ayvalik, TR", label: "Ayvalik, TR", code: "BKR-2" },
	{ value: "Avanos, TR", label: "Avanos, TR", code: "CAP-3" },
	{ value: "Kirsehir, TR", label: "Kirsehir, TR", code: "CAP-6" },
	{ value: "Mugla, TR", label: "Mugla, TR", code: "DLM-2" },
	{ value: "Oludeniz, TR", label: "Oludeniz, TR", code: "FET-2" },
	{ value: "Isparta, TR", label: "Isparta, TR", code: "ISP-3" },
	{ value: "Aksaray, TR", label: "Aksaray, TR", code: "IST-3" },
	{ value: "Agri, TR", label: "Agri, TR", code: "AGR-56" },
	{ value: "Capadocia, TR", label: "Capadocia, TR", code: "CAP-10" },
	{ value: "Ürgüp , TR", label: "Ürgüp , TR", code: "CAP-20" },
	{ value: "Edremit, TR", label: "Edremit, TR", code: "EDR-33" },
	{ value: "Gazipasa, TR", label: "Gazipasa, TR", code: "GAZ-10" },
	{ value: "Giresun, TR", label: "Giresun, TR", code: "GRS-17" },
	{ value: "Mus, TR", label: "Mus, TR", code: "MUS-10" },
	{ value: "Sirnak, TR", label: "Sirnak, TR", code: "SRK-45" },
	{ value: "Yuksekova, TR", label: "Yuksekova, TR", code: "YKSKV-6" },
	{ value: "Erzincan, TR", label: "Erzincan, TR", code: "Erzincan" },
	{ value: "Karaada, TR", label: "Karaada, TR", code: "KRD" },
	{ value: "Köyceğiz, TR", label: "Köyceğiz, TR", code: "koy" },
	{ value: "Cirali, TR", label: "Cirali, TR", code: "AYT1" },
	{ value: "Anamur, TR", label: "Anamur, TR", code: "GZP1" },
	{ value: "Iskenderun, TR", label: "Iskenderun, TR", code: "HTY1" },
	{ value: "Alacatı, TR", label: "Alacatı, TR", code: "IGL1" },
	{ value: "Kuyucak, TR", label: "Kuyucak, TR", code: "KuMe" },
	{ value: "Manavgat, TR", label: "Manavgat, TR", code: "MNVQ" },
	{ value: "Palamutbuku, TR", label: "Palamutbuku, TR", code: "PLBK" },
	{ value: "Sapanca, TR", label: "Sapanca, TR", code: "YEI3" },
	{ value: "Güzelçamlı, TR", label: "Güzelçamlı, TR", code: "guz3" },
	{ value: "Datça, TR", label: "Datça, TR", code: "Datca" },
	{ value: "Iğdır, TR", label: "Iğdır, TR", code: "IGR-1" },
	{ value: "Karaburun, TR", label: "Karaburun, TR", code: "KRA12" },
	{ value: "Pérgamo, TR", label: "Pérgamo, TR", code: "PER-1" },
	{ value: "Selcuk, TR", label: "Selcuk, TR", code: "SEL-6" },
	{ value: "Sultanhani, TR", label: "Sultanhani, TR", code: "SLT-1" },
	{ value: "Lago Tuz, TR", label: "Lago Tuz, TR", code: "TUZ-6" },
	{ value: "Uchisar, TR", label: "Uchisar, TR", code: "UCH-1" },
	{ value: "Ushisar, TR", label: "Ushisar, TR", code: "UCH-6" },
	{ value: "Akyaka, TR", label: "Akyaka, TR", code: "Akyaka" },
	{ value: "Bayindir, TR", label: "Bayindir, TR", code: "BAYI-1" },
	{ value: "Bingöl , TR", label: "Bingöl , TR", code: "BNG-99" },
	{ value: "Cokertme, TR", label: "Cokertme, TR", code: "COKE-1" },
	{ value: "Doğubayazıt , TR", label: "Doğubayazıt , TR", code: "DOGU-6" },
	{ value: "Ekincik, TR", label: "Ekincik, TR", code: "EKIN-1" },
	{ value: "Gökova, TR", label: "Gökova, TR", code: "GOKO-1" },
	{ value: "Güllük, TR", label: "Güllük, TR", code: "Guluck" },
	{ value: "Kulu, TR", label: "Kulu, TR", code: "KULU-6" },
	{ value: "Lara, TR", label: "Lara, TR", code: "LRABCH" },
	{ value: "Maziköy, TR", label: "Maziköy, TR", code: "MAZI-1" },
	{ value: "Ortaca, TR", label: "Ortaca, TR", code: "Ortaca" },
	{ value: "Selçuklu, TR", label: "Selçuklu, TR", code: "SELC-1" },
	{ value: "Sirince, TR", label: "Sirince, TR", code: "SIRI-6" },
	{ value: "Troya, TR", label: "Troya, TR", code: "TROY-1" },
	{ value: "Monte Ararat, TR", label: "Monte Ararat, TR", code: "ART-002" },
	{ value: "Monte Nemrut, TR", label: "Monte Nemrut, TR", code: "NMT-002" },
	{ value: "Salda (Lago), TR", label: "Salda (Lago), TR", code: "SALDA-6" },
	{ value: "Bahía Sarsala, TR", label: "Bahía Sarsala, TR", code: "SARSA-6" },
	{ value: "Sedir Island, TR", label: "Sedir Island, TR", code: "SEDIR-6" },
	{ value: "Serik, TR", label: "Serik, TR", code: "SERIK-6" },
	{ value: "Sinope, TR", label: "Sinope, TR", code: "SPE-002" },
	{ value: "Tuzla, TR", label: "Tuzla, TR", code: "TUZLA-6" },
	{ value: "Yesilkoy, TR", label: "Yesilkoy, TR", code: "YKY-002" },
	{ value: "Adaburnu, TR", label: "Adaburnu, TR", code: "Adaburnu" },
	{ value: "Bozburun, TR", label: "Bozburun, TR", code: "Bozburun" },
	{ value: "Hisarönü, TR", label: "Hisarönü, TR", code: "Hisaronu" },
	{ value: "Longoz, TR", label: "Longoz, TR", code: "LONGOZ-6" },
	{ value: "Uzungol, TR", label: "Uzungol, TR", code: "UZUNGOL-6" },
	{
		value: "Yassica Ada Isla, TR",
		label: "Yassica Ada Isla, TR",
		code: "YASSICA-6",
	},
	{ value: "Ashgabat, TM", label: "Ashgabat, TM", code: "ASB" },
	{
		value: "Islas Turcas y Caicos, TC",
		label: "Islas Turcas y Caicos, TC",
		code: "PLS",
	},
	{ value: "Providenciales, TC", label: "Providenciales, TC", code: "PVN-56" },
	{ value: "North Caicos, TC", label: "North Caicos, TC", code: "NRTHCAICOS" },
	{ value: "Kampala, UG", label: "Kampala, UG", code: "UG1" },
	{
		value: "Lake Bunyonyi (Kabale), UG",
		label: "Lake Bunyonyi (Kabale), UG",
		code: "UAG",
	},
	{ value: "Entebbe, UG", label: "Entebbe, UG", code: "UG-2" },
	{ value: "Jinja, UG", label: "Jinja, UG", code: "Jinja" },
	{ value: "Fort Portal, UG", label: "Fort Portal, UG", code: "UAG-5" },
	{ value: "Lago Alberto, UG", label: "Lago Alberto, UG", code: "LAAL-1" },
	{ value: "Masindi, UG", label: "Masindi, UG", code: "MASI-1" },
	{ value: "Canal Kazinga, UG", label: "Canal Kazinga, UG", code: "KZG-002" },
	{
		value: "Parque nacional de las Cataratas Murchison, UG",
		label: "Parque nacional de las Cataratas Murchison, UG",
		code: "MFN-002",
	},
	{
		value: "Parque nacional de la Reina Isabel, UG",
		label: "Parque nacional de la Reina Isabel, UG",
		code: "QEN-002",
	},
	{ value: "Kiev, UA", label: "Kiev, UA", code: "KBP-1" },
	{ value: "Lviv, UA", label: "Lviv, UA", code: "LWO" },
	{ value: "Járkov, UA", label: "Járkov, UA", code: "HRK-1" },
	{ value: "Mariupol, UA", label: "Mariupol, UA", code: "MPW" },
	{ value: "Cherkasy, UA", label: "Cherkasy, UA", code: "CKC" },
	{ value: "Dnipro, UA", label: "Dnipro, UA", code: "DNK" },
	{ value: "Ivano-Frankovsk, UA", label: "Ivano-Frankovsk, UA", code: "IFO" },
	{ value: "Nicolaev, UA", label: "Nicolaev, UA", code: "MK2" },
	{ value: "Odessa, UA", label: "Odessa, UA", code: "ODS" },
	{ value: "Zaporiyia, UA", label: "Zaporiyia, UA", code: "OZH" },
	{ value: "Poltava, UA", label: "Poltava, UA", code: "PLV" },
	{ value: "Rivne, UA", label: "Rivne, UA", code: "VNE" },
	{ value: "Zhytomir, UA", label: "Zhytomir, UA", code: "ZTR" },
	{ value: "Boryspil, UA", label: "Boryspil, UA", code: "KBP-3" },
	{ value: "Obukhov, UA", label: "Obukhov, UA", code: "KBP-4" },
	{ value: "Kherson, UA", label: "Kherson, UA", code: "UA1-1" },
	{ value: "Vinnytsia, UA", label: "Vinnytsia, UA", code: "VNI-1" },
	{ value: "Pochaiv, UA", label: "Pochaiv, UA", code: "CYV-22" },
	{ value: "Krivoy Rog, UA", label: "Krivoy Rog, UA", code: "KYR-19" },
	{ value: "Sumy, UA", label: "Sumy, UA", code: "UMY-27" },
	{ value: "Zaporiyia, UA", label: "Zaporiyia, UA", code: "ZPY-10" },
	{ value: "Odesa, UA", label: "Odesa, UA", code: "ODS-14" },
	{
		value: "Kamianets-Podilskyi, UA",
		label: "Kamianets-Podilskyi, UA",
		code: "KPI-002",
	},
	{ value: "Chernobyl, UA", label: "Chernobyl, UA", code: "CHERNOBYL-6" },
	{
		value: "Dnipropetrovsk, UA",
		label: "Dnipropetrovsk, UA",
		code: "Dnipropetrovsk",
	},
	{ value: "Abu Dhabi, AE", label: "Abu Dhabi, AE", code: "AUH" },
	{ value: "Dubai, AE", label: "Dubai, AE", code: "DXB" },
	{ value: "Ajman, AE", label: "Ajman, AE", code: "AE1" },
	{ value: "Ras al-Khaimah, AE", label: "Ras al-Khaimah, AE", code: "RKT-1" },
	{ value: "Sarja, AE", label: "Sarja, AE", code: "SHJ-1" },
	{ value: "Al Ain, AE", label: "Al Ain, AE", code: "AAN" },
	{ value: "LEGOLAND® Dubai, AE", label: "LEGOLAND® Dubai, AE", code: "734" },
	{ value: "Fujairah, AE", label: "Fujairah, AE", code: "FJR" },
	{ value: "Umm al-Qaywayn, AE", label: "Umm al-Qaywayn, AE", code: "UMM" },
	{ value: "Tilal Liwa, AE", label: "Tilal Liwa, AE", code: "TL-D" },
	{ value: "Desierto Dubai, AE", label: "Desierto Dubai, AE", code: "DBD-6" },
	{ value: "Jumeirah Beach, AE", label: "Jumeirah Beach, AE", code: "DXB-2" },
	{ value: "Jebel Ali, AE", label: "Jebel Ali, AE", code: "DXB-4" },
	{ value: "Deira, AE", label: "Deira, AE", code: "DXB-5" },
	{ value: "Hatta, AE", label: "Hatta, AE", code: "DXB-7" },
	{ value: "Marina (Dubai), AE", label: "Marina (Dubai), AE", code: "MRN-6" },
	{ value: "Um al Quwain, AE", label: "Um al Quwain, AE", code: "UMM-1" },
	{ value: "Khor Fakkan, AE", label: "Khor Fakkan, AE", code: "KHOR-6" },
	{ value: "Madinat Zayed, AE", label: "Madinat Zayed, AE", code: "MDNZ-6" },
	{ value: "Al Barsha, AE", label: "Al Barsha, AE", code: "ALBAR-6" },
	{ value: "Al Maha, AE", label: "Al Maha, AE", code: "fake-124" },
	{ value: "Liwa, AE", label: "Liwa, AE", code: "fake-126" },
	{
		value: "Sir Bani Yas Island, AE",
		label: "Sir Bani Yas Island, AE",
		code: "fake-127",
	},
	{ value: "Jebel Dhanna, AE", label: "Jebel Dhanna, AE", code: "fake-128" },
	{ value: "Qasr Al Sarab, AE", label: "Qasr Al Sarab, AE", code: "fake-130" },
	{ value: "Bab al Sham, AE", label: "Bab al Sham, AE", code: "BAS-D" },
	{ value: "Bur Dubai, AE", label: "Bur Dubai, AE", code: "BUR-D" },
	{ value: "Marine Dubai, AE", label: "Marine Dubai, AE", code: "MARINA-D" },
	{ value: "Palm Islands, AE", label: "Palm Islands, AE", code: "PALMIS-D" },
	{
		value: "Dubai Downtown, AE",
		label: "Dubai Downtown, AE",
		code: "DOWNTOWNK-D",
	},
	{ value: "Londres, GB", label: "Londres, GB", code: "LON" },
	{ value: "Edimburgo, GB", label: "Edimburgo, GB", code: "EDI-1" },
	{ value: "Cardiff, GB", label: "Cardiff, GB", code: "CWL" },
	{ value: "Manchester, GB", label: "Manchester, GB", code: "MAC" },
	{ value: "Nottingham , GB", label: "Nottingham , GB", code: "NOT" },
	{ value: "Rye, GB", label: "Rye, GB", code: "EA2-11" },
	{ value: "Aberdeen, GB", label: "Aberdeen, GB", code: "ABZ" },
	{ value: "Stirling, GB", label: "Stirling, GB", code: "AIR" },
	{ value: "Belfast, GB", label: "Belfast, GB", code: "BFS" },
	{ value: "Birmingham, GB", label: "Birmingham, GB", code: "BHX" },
	{ value: "Cambridge, GB", label: "Cambridge, GB", code: "CBG" },
	{ value: "Dundee, GB", label: "Dundee, GB", code: "DDE" },
	{ value: "Glasgow, GB", label: "Glasgow, GB", code: "GLA" },
	{ value: "Leicester, GB", label: "Leicester, GB", code: "LIC" },
	{ value: "Norwich, GB", label: "Norwich, GB", code: "NWH" },
	{ value: "Islas Orcadas, GB", label: "Islas Orcadas, GB", code: "ORN" },
	{ value: "Oxford, GB", label: "Oxford, GB", code: "OXF" },
	{ value: "York, GB", label: "York, GB", code: "QQY" },
	{ value: "Islas Shetland, GB", label: "Islas Shetland, GB", code: "SHT" },
	{ value: "Inverness, GB", label: "Inverness, GB", code: "INV-1" },
	{ value: "Liverpool, GB", label: "Liverpool, GB", code: "LPL-1" },
	{ value: "Perth, GB", label: "Perth, GB", code: "AUC" },
	{ value: "Blackpool, GB", label: "Blackpool, GB", code: "BKP" },
	{ value: "Bournemouth, GB", label: "Bournemouth, GB", code: "BOU" },
	{ value: "Bristol, GB", label: "Bristol, GB", code: "BRI" },
	{ value: "Brighton, GB", label: "Brighton, GB", code: "BSH" },
	{ value: "Carlisle, GB", label: "Carlisle, GB", code: "CAR" },
	{ value: "Chester, GB", label: "Chester, GB", code: "CEG" },
	{ value: "Canterbury, GB", label: "Canterbury, GB", code: "CNT" },
	{
		value: "El Distrito de los Lagos, GB",
		label: "El Distrito de los Lagos, GB",
		code: "CU2",
	},
	{ value: "Devon, GB", label: "Devon, GB", code: "DE3" },
	{ value: "Dover, GB", label: "Dover, GB", code: "DOV" },
	{ value: "Dumfries, GB", label: "Dumfries, GB", code: "DUM" },
	{ value: "Fort Williams, GB", label: "Fort Williams, GB", code: "FOT" },
	{ value: "Gloucester, GB", label: "Gloucester, GB", code: "GLC" },
	{ value: "Skye, GB", label: "Skye, GB", code: "ISA" },
	{ value: "Lancaster, GB", label: "Lancaster, GB", code: "LNC" },
	{ value: "Middlesbrough, GB", label: "Middlesbrough, GB", code: "MID" },
	{ value: "Newcastle, GB", label: "Newcastle, GB", code: "NCL" },
	{ value: "Oban, GB", label: "Oban, GB", code: "OBA" },
	{ value: "Plymouth, GB", label: "Plymouth, GB", code: "PLY" },
	{ value: "Portsmouth, GB", label: "Portsmouth, GB", code: "PSM" },
	{ value: "Bath, GB", label: "Bath, GB", code: "QQX" },
	{ value: "Reading, GB", label: "Reading, GB", code: "RAG" },
	{ value: "Sheffield, GB", label: "Sheffield, GB", code: "SFL" },
	{ value: "Conwy , GB", label: "Conwy , GB", code: "SNO" },
	{ value: "Saint Andrews, GB", label: "Saint Andrews, GB", code: "STA" },
	{ value: "Southampton, GB", label: "Southampton, GB", code: "STH" },
	{ value: "Swansea, GB", label: "Swansea, GB", code: "SWA" },
	{ value: "Thurso, GB", label: "Thurso, GB", code: "THU" },
	{ value: "Worcester, GB", label: "Worcester, GB", code: "WOR" },
	{ value: "Falmouth, GB", label: "Falmouth, GB", code: "CRN-3" },
	{ value: "Dorchester, GB", label: "Dorchester, GB", code: "DO2-3" },
	{ value: "Aviemore, GB", label: "Aviemore, GB", code: "INV-3" },
	{ value: "Isla de Wight, GB", label: "Isla de Wight, GB", code: "IS1-3" },
	{ value: "Portree, GB", label: "Portree, GB", code: "ISA-6" },
	{ value: "Aberfoyle, GB", label: "Aberfoyle, GB", code: "LOC-3" },
	{ value: "Newquay, GB", label: "Newquay, GB", code: "NEQ-3" },
	{ value: "Torquay, GB", label: "Torquay, GB", code: "TOQ-3" },
	{ value: "Windsor, GB", label: "Windsor, GB", code: "WID-1" },
	{
		value: "Barrow in Furness, GB",
		label: "Barrow in Furness, GB",
		code: "BIF-33",
	},
	{ value: "Leeds, GB", label: "Leeds, GB", code: "LBA-10" },
	{ value: "Workington, GB", label: "Workington, GB", code: "WRK-10" },
	{ value: "North Berwick, GB", label: "North Berwick, GB", code: "NOR-213" },
	{ value: "Blackburn, GB", label: "Blackburn, GB", code: "fake-632" },
	{
		value: "Folkestone, GB",
		label: "Folkestone, GB",
		code: "____NEW____1446135962749",
	},
	{
		value: "LEGOLAND® Windsor, GB",
		label: "LEGOLAND® Windsor, GB",
		code: "581",
	},
	{ value: "Saint Albans, GB", label: "Saint Albans, GB", code: "ALB" },
	{ value: "Ascot, GB", label: "Ascot, GB", code: "ASC" },
	{ value: "Aylesbury, GB", label: "Aylesbury, GB", code: "AYL" },
	{ value: "Bolton, GB", label: "Bolton, GB", code: "BOL" },
	{ value: "Bradford, GB", label: "Bradford, GB", code: "BRF" },
	{ value: "Coventry, GB", label: "Coventry, GB", code: "CVT" },
	{ value: "Derby, GB", label: "Derby, GB", code: "DBY" },
	{ value: "Doncaster, GB", label: "Doncaster, GB", code: "DON" },
	{ value: "Durham, GB", label: "Durham, GB", code: "DU3" },
	{ value: "Exeter, GB", label: "Exeter, GB", code: "EXE" },
	{ value: "Guildford, GB", label: "Guildford, GB", code: "GUI" },
	{ value: "Hull, GB", label: "Hull, GB", code: "HUL" },
	{ value: "Inveraray, GB", label: "Inveraray, GB", code: "INE" },
	{ value: "Lenham, GB", label: "Lenham, GB", code: "KE1" },
	{ value: "Londonderry, GB", label: "Londonderry, GB", code: "LDY" },
	{ value: "Maidstone, GB", label: "Maidstone, GB", code: "MAI" },
	{ value: "Newport, GB", label: "Newport, GB", code: "NEP" },
	{ value: "Northampton, GB", label: "Northampton, GB", code: "NTH" },
	{ value: "Weymouth, GB", label: "Weymouth, GB", code: "NZW" },
	{ value: "Peterborough, GB", label: "Peterborough, GB", code: "PBH" },
	{ value: "Peebles, GB", label: "Peebles, GB", code: "PEE" },
	{ value: "Preston, GB", label: "Preston, GB", code: "PRS" },
	{ value: "Salisbury, GB", label: "Salisbury, GB", code: "SLB" },
	{ value: "Stoke On Trent, GB", label: "Stoke On Trent, GB", code: "STK" },
	{ value: "Sunderland, GB", label: "Sunderland, GB", code: "SU3" },
	{ value: "Swindon, GB", label: "Swindon, GB", code: "SWI" },
	{ value: "Telford, GB", label: "Telford, GB", code: "TLF" },
	{ value: "Caernarfon, GB", label: "Caernarfon, GB", code: "UK4" },
	{ value: "Ullapool, GB", label: "Ullapool, GB", code: "ULA" },
	{ value: "Wakefield, GB", label: "Wakefield, GB", code: "WAD" },
	{ value: "Walsall, GB", label: "Walsall, GB", code: "WAL" },
	{ value: "Winchester, GB", label: "Winchester, GB", code: "WCH" },
	{ value: "Wells, GB", label: "Wells, GB", code: "WEL" },
	{ value: "Wigan, GB", label: "Wigan, GB", code: "WIG" },
	{ value: "Wolverhampton, GB", label: "Wolverhampton, GB", code: "WLV" },
	{ value: "Ayr, GB", label: "Ayr, GB", code: "AYS-3" },
	{ value: "Troon, GB", label: "Troon, GB", code: "AYS-4" },
	{ value: "Cumnock, GB", label: "Cumnock, GB", code: "AYS-6" },
	{ value: "Tamworth, GB", label: "Tamworth, GB", code: "BHX-3" },
	{ value: "Redditch, GB", label: "Redditch, GB", code: "BHX-4" },
	{ value: "Falkirk, GB", label: "Falkirk, GB", code: "EDI-7" },
	{ value: "Clacton-on-sea, GB", label: "Clacton-on-sea, GB", code: "ES3-3" },
	{ value: "Grays, GB", label: "Grays, GB", code: "ES3-8" },
	{ value: "Farnborough, GB", label: "Farnborough, GB", code: "FAR-3" },
	{ value: "Fishbourne, GB", label: "Fishbourne, GB", code: "FBU-2" },
	{ value: "Fishguard, GB", label: "Fishguard, GB", code: "FSH-2" },
	{ value: "Cumbernauld, GB", label: "Cumbernauld, GB", code: "GLA-5" },
	{ value: "Woking, GB", label: "Woking, GB", code: "GUI-2" },
	{ value: "Hereford, GB", label: "Hereford, GB", code: "HE3-2" },
	{ value: "Nairn, GB", label: "Nairn, GB", code: "INV-7" },
	{ value: "Croydon, GB", label: "Croydon, GB", code: "LON-7" },
	{ value: "Birkenhead, GB", label: "Birkenhead, GB", code: "LPL-7" },
	{ value: "Larne, GB", label: "Larne, GB", code: "LRN-2" },
	{ value: "Newhaven, GB", label: "Newhaven, GB", code: "NHV-2" },
	{ value: "Kings Lynn, GB", label: "Kings Lynn, GB", code: "NO1-9" },
	{ value: "Great Yarmouth, GB", label: "Great Yarmouth, GB", code: "NWH-4" },
	{ value: "Laugharne, GB", label: "Laugharne, GB", code: "PE3-5" },
	{ value: "Kelso, GB", label: "Kelso, GB", code: "PEE-2" },
	{ value: "Biggar, GB", label: "Biggar, GB", code: "PEE-5" },
	{ value: "Melrose, GB", label: "Melrose, GB", code: "PEE-6" },
	{ value: "Pembroke, GB", label: "Pembroke, GB", code: "PMB-2" },
	{ value: "Slough, GB", label: "Slough, GB", code: "WID-3" },
	{ value: "Brentwood, GB", label: "Brentwood, GB", code: "ES3-11" },
	{ value: "Purfleet, GB", label: "Purfleet, GB", code: "ES3-21" },
	{ value: "Ingatestone, GB", label: "Ingatestone, GB", code: "ES3-24" },
	{
		value: "Thorpe le soken, GB",
		label: "Thorpe le soken, GB",
		code: "ES3-25",
	},
	{
		value: "Southend-on-Sea, GB",
		label: "Southend-on-Sea, GB",
		code: "ES3-26",
	},
	{ value: "Chelmsford, GB", label: "Chelmsford, GB", code: "ESE3-2" },
	{
		value: "Grantown on Spey, GB",
		label: "Grantown on Spey, GB",
		code: "INV-16",
	},
	{ value: "Hythe, GB", label: "Hythe, GB", code: "KE1-18" },
	{ value: "Coleraine, GB", label: "Coleraine, GB", code: "LDY-10" },
	{
		value: "Kingston Upon Thames, GB",
		label: "Kingston Upon Thames, GB",
		code: "LOM-86",
	},
	{ value: "Basildon, GB", label: "Basildon, GB", code: "LON-66" },
	{ value: "Sutton, GB", label: "Sutton, GB", code: "LON-69" },
	{ value: "Watford, GB", label: "Watford, GB", code: "LON-70" },
	{ value: "Glenrothes, GB", label: "Glenrothes, GB", code: "STA-10" },
	{ value: "Chichester, GB", label: "Chichester, GB", code: "WE1-11" },
	{ value: "Bathgate, GB", label: "Bathgate, GB", code: "fake-295" },
	{ value: "Bellshill, GB", label: "Bellshill, GB", code: "fake-296" },
	{
		value: "Cairngorms National Park, GB",
		label: "Cairngorms National Park, GB",
		code: "fake-297",
	},
	{ value: "Dumbarton, GB", label: "Dumbarton, GB", code: "fake-298" },
	{ value: "East Kilbride, GB", label: "East Kilbride, GB", code: "fake-299" },
	{ value: "Elgin, GB", label: "Elgin, GB", code: "fake-300" },
	{ value: "Greenock, GB", label: "Greenock, GB", code: "fake-301" },
	{ value: "Kilmarnock, GB", label: "Kilmarnock, GB", code: "fake-302" },
	{ value: "Larbert, GB", label: "Larbert, GB", code: "fake-303" },
	{ value: "Livingston, GB", label: "Livingston, GB", code: "fake-304" },
	{ value: "Motherwell, GB", label: "Motherwell, GB", code: "fake-305" },
	{ value: "Paisley, GB", label: "Paisley, GB", code: "fake-306" },
	{ value: "Glencoe, GB", label: "Glencoe, GB", code: "fake-328" },
	{ value: "Crawley, GB", label: "Crawley, GB", code: "CRA" },
	{ value: "Horsham, GB", label: "Horsham, GB", code: "HOR" },
	{ value: "Surrey, GB", label: "Surrey, GB", code: "KRH" },
	{ value: "Lincoln, GB", label: "Lincoln, GB", code: "LIN" },
	{
		value: "Stansted (Aeropuerto), GB",
		label: "Stansted (Aeropuerto), GB",
		code: "STN",
	},
	{ value: "Staffordshire, GB", label: "Staffordshire, GB", code: "BHX1" },
	{
		value: "Northamptonshire, GB",
		label: "Northamptonshire, GB",
		code: "CVT1",
	},
	{ value: "Warwickshire, GB", label: "Warwickshire, GB", code: "CVT2" },
	{ value: "North Yorkshire, GB", label: "North Yorkshire, GB", code: "LBA1" },
	{ value: "Nottinghamshire, GB", label: "Nottinghamshire, GB", code: "NQT1" },
	{ value: "Oxfordshire, GB", label: "Oxfordshire, GB", code: "OXF1" },
	{ value: "Suffolk, GB", label: "Suffolk, GB", code: "SUFF" },
	{ value: "Angus, GB", label: "Angus, GB", code: "ANG-1" },
	{ value: "EAST GRINSTEAD, GB", label: "EAST GRINSTEAD, GB", code: "EGR-2" },
	{ value: "Largs, GB", label: "Largs, GB", code: "LAR-6" },
	{ value: "Leyburn, GB", label: "Leyburn, GB", code: "LEY-6" },
	{ value: "Gatwick, GB", label: "Gatwick, GB", code: "LGW-1" },
	{ value: "Linlithgow, GB", label: "Linlithgow, GB", code: "LIN-6" },
	{ value: "The Lizard, GB", label: "The Lizard, GB", code: "LIZ-6" },
	{ value: "Louth, GB", label: "Louth, GB", code: "LOU-6" },
	{ value: "Lowestoft, GB", label: "Lowestoft, GB", code: "LOW-6" },
	{ value: "Ludlow, GB", label: "Ludlow, GB", code: "LUD-6" },
	{ value: "Lyme Regis, GB", label: "Lyme Regis, GB", code: "LYM-6" },
	{ value: "Lynmouth, GB", label: "Lynmouth, GB", code: "LYN-6" },
	{ value: "Lytham St Annes, GB", label: "Lytham St Annes, GB", code: "LYT-6" },
	{ value: "Moray, GB", label: "Moray, GB", code: "MOR-1" },
	{ value: "Morpeth, GB", label: "Morpeth, GB", code: "NO2-3" },
	{ value: "Northumberland, GB", label: "Northumberland, GB", code: "NTHBL" },
	{ value: "Padstow, GB", label: "Padstow, GB", code: "PAD-6" },
	{ value: "Polruan, GB", label: "Polruan, GB", code: "POL-6" },
	{ value: "Poole, GB", label: "Poole, GB", code: "Poole" },
	{
		value: "Stratford-upon-Avon, GB",
		label: "Stratford-upon-Avon, GB",
		code: "SAV-2",
	},
	{
		value: "Royal Leamington Spa, GB",
		label: "Royal Leamington Spa, GB",
		code: "SPA-6",
	},
	{ value: "Usk, GB", label: "Usk, GB", code: "USK-1" },
	{ value: "North Walsham, GB", label: "North Walsham, GB", code: "WAL-6" },
	{ value: "Worthing , GB", label: "Worthing , GB", code: "WOR-1" },
	{ value: "Wymondham, GB", label: "Wymondham, GB", code: "WYM-6" },
	{
		value: "Yorkshire (Condado), GB",
		label: "Yorkshire (Condado), GB",
		code: "YOK-6",
	},
	{ value: "Argyll y Bute, GB", label: "Argyll y Bute, GB", code: "AANB-1" },
	{
		value: "Antrim (Condado), GB",
		label: "Antrim (Condado), GB",
		code: "ANTC-1",
	},
	{
		value: "Armagh (Condado), GB",
		label: "Armagh (Condado), GB",
		code: "ARMA-1",
	},
	{ value: "Barnstaple, GB", label: "Barnstaple, GB", code: "BARN-1" },
	{
		value: "Berwick-upon-Tweed, GB",
		label: "Berwick-upon-Tweed, GB",
		code: "BEUT-1",
	},
	{ value: "Buxton, GB", label: "Buxton, GB", code: "BUXT-1" },
	{ value: "Chatham, GB", label: "Chatham, GB", code: "CHAT-2" },
	{ value: "Cheltenham, GB", label: "Cheltenham, GB", code: "CHEL-1" },
	{ value: "Cirencester, GB", label: "Cirencester, GB", code: "CIRE-1" },
	{
		value: "Clackmannanshire, GB",
		label: "Clackmannanshire, GB",
		code: "CLCK-1",
	},
	{ value: "Corbridge, GB", label: "Corbridge, GB", code: "CORB-1" },
	{ value: "Cromarty, GB", label: "Cromarty, GB", code: "CROM-1" },
	{ value: "Culloden, GB", label: "Culloden, GB", code: "CULL-1" },
	{ value: "Down (Condado), GB", label: "Down (Condado), GB", code: "DOWN-1" },
	{ value: "Derry, GB", label: "Derry, GB", code: "DRRY-1" },
	{ value: "East Ayrshire, GB", label: "East Ayrshire, GB", code: "EAAY-1" },
	{ value: "East Lothian, GB", label: "East Lothian, GB", code: "EALO-1" },
	{
		value: "East Renfrewshire, GB",
		label: "East Renfrewshire, GB",
		code: "EARE-1",
	},
	{
		value: "East Dunbartonshire, GB",
		label: "East Dunbartonshire, GB",
		code: "EASD-1",
	},
	{
		value: "Sussex Oriental, GB",
		label: "Sussex Oriental, GB",
		code: "EASU-1",
	},
	{
		value: "Fermanagh (Condado), GB",
		label: "Fermanagh (Condado), GB",
		code: "FERM-1",
	},
	{ value: "Fife, GB", label: "Fife, GB", code: "FIFE-1" },
	{ value: "Glastonbury, GB", label: "Glastonbury, GB", code: "GLAS-1" },
	{
		value: "Goring-on-Thames, GB",
		label: "Goring-on-Thames, GB",
		code: "GOOT-1",
	},
	{ value: "Grassington, GB", label: "Grassington, GB", code: "GRAS-1" },
	{ value: "Grimsby, GB", label: "Grimsby, GB", code: "GRIM-1" },
	{ value: "Harrogate, GB", label: "Harrogate, GB", code: "HATE-6" },
	{ value: "Hatfield, GB", label: "Hatfield, GB", code: "HATF-1" },
	{ value: "Hawes, GB", label: "Hawes, GB", code: "HAWE-1" },
	{ value: "Helston, GB", label: "Helston, GB", code: "HELS-1" },
	{ value: "Hintlesham, GB", label: "Hintlesham, GB", code: "HINT-1" },
	{ value: "Hope, GB", label: "Hope, GB", code: "HOPE-1" },
	{ value: "Horringer, GB", label: "Horringer, GB", code: "HORR-1" },
	{ value: "Hovingham, GB", label: "Hovingham, GB", code: "HOVI-1" },
	{ value: "Huddersfield, GB", label: "Huddersfield, GB", code: "HUDD-1" },
	{ value: "Hutton-le-Hole, GB", label: "Hutton-le-Hole, GB", code: "HULH-1" },
	{ value: "Humberside, GB", label: "Humberside, GB", code: "HUMB-1" },
	{ value: "Huntingdon, GB", label: "Huntingdon, GB", code: "HUNT-1" },
	{ value: "Husthwaite, GB", label: "Husthwaite, GB", code: "HUST-1" },
	{ value: "Ilkley, GB", label: "Ilkley, GB", code: "ILKL-1" },
	{ value: "Ilmington, GB", label: "Ilmington, GB", code: "ILMI-1" },
	{ value: "Inversnaid, GB", label: "Inversnaid, GB", code: "INVE-1" },
	{ value: "Inverclyde, GB", label: "Inverclyde, GB", code: "INVR-1" },
	{ value: "Iona, GB", label: "Iona, GB", code: "IONA-1" },
	{ value: "Islay, GB", label: "Islay, GB", code: "ISLA-1" },
	{
		value: "Isla de Purbeck, GB",
		label: "Isla de Purbeck, GB",
		code: "ISOP-1",
	},
	{
		value: "Islas Sorlingas, GB",
		label: "Islas Sorlingas, GB",
		code: "ISOS-1",
	},
	{ value: "Lacock, GB", label: "Lacock, GB", code: "LACO-1" },
	{
		value: "Lechlade-on-Thames, GB",
		label: "Lechlade-on-Thames, GB",
		code: "LECH-1",
	},
	{ value: "Llanberis, GB", label: "Llanberis, GB", code: "LLAM-6" },
	{ value: "Llangollen, GB", label: "Llangollen, GB", code: "LLAN-1" },
	{
		value: "Londonderry (Condado), GB",
		label: "Londonderry (Condado), GB",
		code: "LOCO-1",
	},
	{ value: "Looe, GB", label: "Looe, GB", code: "LOOE-6" },
	{ value: "Lostwithiel, GB", label: "Lostwithiel, GB", code: "LOST-6" },
	{ value: "Luton, GB", label: "Luton, GB", code: "LUTN-1" },
	{ value: "Maldon, GB", label: "Maldon, GB", code: "MALD-6" },
	{ value: "Malton, GB", label: "Malton, GB", code: "MALT-1" },
	{ value: "Matlock, GB", label: "Matlock, GB", code: "MATL-6" },
	{ value: "Portmeirion, GB", label: "Portmeirion, GB", code: "MEIR-6" },
	{ value: "Midlothian, GB", label: "Midlothian, GB", code: "MIDL-1" },
	{
		value: "Moray (Condado), GB",
		label: "Moray (Condado), GB",
		code: "MORC-1",
	},
	{ value: "Newbury, GB", label: "Newbury, GB", code: "NEWB-6" },
	{ value: "Newmarket, GB", label: "Newmarket, GB", code: "NEWM-6" },
	{ value: "North Ayrshire, GB", label: "North Ayrshire, GB", code: "NOAY-1" },
	{ value: "Paignton, GB", label: "Paignton, GB", code: "PAIG-6" },
	{ value: "Penally, GB", label: "Penally, GB", code: "PENA-6" },
	{ value: "Pensford, GB", label: "Pensford, GB", code: "PENS-6" },
	{ value: "Penzance, GB", label: "Penzance, GB", code: "PENZ-6" },
	{ value: "Petworth, GB", label: "Petworth, GB", code: "PETW-6" },
	{ value: "Pitlochry, GB", label: "Pitlochry, GB", code: "PICHRY" },
	{ value: "Pickering, GB", label: "Pickering, GB", code: "PICK-6" },
	{ value: "Porlock, GB", label: "Porlock, GB", code: "PORL-6" },
	{ value: "Rosemarkie, GB", label: "Rosemarkie, GB", code: "ROSE-1" },
	{ value: "Saffron Walden, GB", label: "Saffron Walden, GB", code: "SAFF-6" },
	{ value: "Skeabost, GB", label: "Skeabost, GB", code: "SKEB-1" },
	{ value: "Somerset, GB", label: "Somerset, GB", code: "SMST-1" },
	{ value: "South Ayrshire, GB", label: "South Ayrshire, GB", code: "SOAY-1" },
	{ value: "Solva, GB", label: "Solva, GB", code: "SOLV-1" },
	{ value: "Stoke-on-Trent, GB", label: "Stoke-on-Trent, GB", code: "SONT-6" },
	{ value: "Southwold, GB", label: "Southwold, GB", code: "SOTH-1" },
	{
		value: "Stow-on-the-Wold, GB",
		label: "Stow-on-the-Wold, GB",
		code: "SOTW-1",
	},
	{ value: "St Austell, GB", label: "St Austell, GB", code: "STAU-1" },
	{ value: "St Blazey, GB", label: "St Blazey, GB", code: "STBL-1" },
	{ value: "St Boswells, GB", label: "St Boswells, GB", code: "STBO-1" },
	{
		value: "Stockbridge (Hampshire), GB",
		label: "Stockbridge (Hampshire), GB",
		code: "STCK-1",
	},
	{
		value: "Saint David&apos;s, GB",
		label: "Saint David&apos;s, GB",
		code: "STDA-1",
	},
	{ value: "Steyning, GB", label: "Steyning, GB", code: "STEY-1" },
	{ value: "St Fillans, GB", label: "St Fillans, GB", code: "STFI-1" },
	{ value: "St Mawes, GB", label: "St Mawes, GB", code: "STMA-1" },
	{
		value: "Stamford (Lincolnshire), GB",
		label: "Stamford (Lincolnshire), GB",
		code: "STMF-1",
	},
	{ value: "St Monans, GB", label: "St Monans, GB", code: "STMO-1" },
	{ value: "Stornoway, GB", label: "Stornoway, GB", code: "STRN-1" },
	{ value: "Strathpeffer, GB", label: "Strathpeffer, GB", code: "STRT-1" },
	{ value: "Swadlincote, GB", label: "Swadlincote, GB", code: "SWAD-6" },
	{ value: "Taunton, GB", label: "Taunton, GB", code: "TAUN-1" },
	{
		value: "Tyrone (Condado), GB",
		label: "Tyrone (Condado), GB",
		code: "TYRO-1",
	},
	{ value: "Tarbet, GB", label: "Tarbet, GB", code: "Tarbet" },
	{ value: "Ventnor, GB", label: "Ventnor, GB", code: "VENT-1" },
	{
		value: "Wareham, Dorset, GB",
		label: "Wareham, Dorset, GB",
		code: "WARE-1",
	},
	{ value: "Warminster, GB", label: "Warminster, GB", code: "WARM-1" },
	{ value: "Warwick, GB", label: "Warwick, GB", code: "WARW-1" },
	{ value: "Worcestershire, GB", label: "Worcestershire, GB", code: "WCTY-6" },
	{
		value: "West Bay, Dorset, GB",
		label: "West Bay, Dorset, GB",
		code: "WEBA-1",
	},
	{ value: "West Bexington, GB", label: "West Bexington, GB", code: "WEBE-1" },
	{
		value: "West Dunbartonshire, GB",
		label: "West Dunbartonshire, GB",
		code: "WEDU-1",
	},
	{
		value: "Welwyn Garden City, GB",
		label: "Welwyn Garden City, GB",
		code: "WEGC-1",
	},
	{ value: "Whitstable, GB", label: "Whitstable, GB", code: "WHIT-1" },
	{
		value: "Whitchurch, Shropshire, GB",
		label: "Whitchurch, Shropshire, GB",
		code: "WICH-1",
	},
	{ value: "Painswick, GB", label: "Painswick, GB", code: "WICK-6" },
	{ value: "Wilmcote, GB", label: "Wilmcote, GB", code: "WILM-6" },
	{ value: "Wiltshire, GB", label: "Wiltshire, GB", code: "WILT-1" },
	{
		value: "Wells-next-the-Sea, GB",
		label: "Wells-next-the-Sea, GB",
		code: "WNTS-1",
	},
	{
		value: "Wolf&apos;s Castle, GB",
		label: "Wolf&apos;s Castle, GB",
		code: "WOLF-6",
	},
	{ value: "Woodbridge, GB", label: "Woodbridge, GB", code: "WOOD-6" },
	{ value: "Worksop, GB", label: "Worksop, GB", code: "WORK-6" },
	{ value: "Wrexham, GB", label: "Wrexham, GB", code: "WREX-6" },
	{ value: "Yeovil, GB", label: "Yeovil, GB", code: "YEOV-1" },
	{ value: "Newton Abbot, GB", label: "Newton Abbot, GB", code: "ABBOT-6" },
	{ value: "Aberystwyth, GB", label: "Aberystwyth, GB", code: "ABH-002" },
	{ value: "Ashford, GB", label: "Ashford, GB", code: "AFD-002" },
	{ value: "Port Appin, GB", label: "Port Appin, GB", code: "APPIN-6" },
	{ value: "Barnard Castle, GB", label: "Barnard Castle, GB", code: "BDC-002" },
	{ value: "Bodmin, GB", label: "Bodmin, GB", code: "BDM-002" },
	{ value: "Bala, GB", label: "Bala, GB", code: "BLK-002" },
	{
		value: "Buckinghamshire, GB",
		label: "Buckinghamshire, GB",
		code: "BUCTY-6",
	},
	{ value: "Banbury , GB", label: "Banbury , GB", code: "Banbury" },
	{ value: "Winchcombe, GB", label: "Winchcombe, GB", code: "COMBE-6" },
	{ value: "Colchester, GB", label: "Colchester, GB", code: "CTR-002" },
	{ value: "Dunfermline, GB", label: "Dunfermline, GB", code: "DFE-002" },
	{
		value: "Dumfries Y Galloway, GB",
		label: "Dumfries Y Galloway, GB",
		code: "DGCTY-6",
	},
	{ value: "Hawkshead, GB", label: "Hawkshead, GB", code: "HSD-002" },
	{ value: "Harwich , GB", label: "Harwich , GB", code: "Harwich" },
	{ value: "Heysham , GB", label: "Heysham , GB", code: "Heysham" },
	{ value: "Port Isaac, GB", label: "Port Isaac, GB", code: "ISAAC-6" },
	{ value: "Jura, GB", label: "Jura, GB", code: "JRA-002" },
	{ value: "Kendal, GB", label: "Kendal, GB", code: "KDL-002" },
	{ value: "Keighley, GB", label: "Keighley, GB", code: "KGL-002" },
	{ value: "Kinross, GB", label: "Kinross, GB", code: "KINROSS" },
	{ value: "Keswick, GB", label: "Keswick, GB", code: "KSK-002" },
	{ value: "Lichfield, GB", label: "Lichfield, GB", code: "LCF-002" },
	{ value: "Ledbury, GB", label: "Ledbury, GB", code: "LDY-002" },
	{ value: "Midhurst, GB", label: "Midhurst, GB", code: "MDH-002" },
	{ value: "Moffat, GB", label: "Moffat, GB", code: "MFT-002" },
	{ value: "Minehead, GB", label: "Minehead, GB", code: "MHD-002" },
	{
		value: "Mayfield (Midlothian), GB",
		label: "Mayfield (Midlothian), GB",
		code: "MIDLO-6",
	},
	{ value: "Mullion, GB", label: "Mullion, GB", code: "MLL-002" },
	{ value: "Muir of Ord, GB", label: "Muir of Ord, GB", code: "MOD-002" },
	{ value: "Morar, GB", label: "Morar, GB", code: "MRR-002" },
	{ value: "Mousehole, GB", label: "Mousehole, GB", code: "MSH-002" },
	{
		value: "Middleton-in-Teesdale, GB",
		label: "Middleton-in-Teesdale, GB",
		code: "MTD-002",
	},
	{ value: "Mortehoe, GB", label: "Mortehoe, GB", code: "MTE-002" },
	{ value: "Milton Keynes, GB", label: "Milton Keynes, GB", code: "MTK-002" },
	{ value: "Melton Mowbray, GB", label: "Melton Mowbray, GB", code: "MTM-002" },
	{
		value: "North Lanarkshire, GB",
		label: "North Lanarkshire, GB",
		code: "NLCTY-6",
	},
	{ value: "North Ayshire, GB", label: "North Ayshire, GB", code: "NRTHASH" },
	{ value: "Polperro, GB", label: "Polperro, GB", code: "PERRO-6" },
	{
		value: "Perth Y Kinross, GB",
		label: "Perth Y Kinross, GB",
		code: "PKCTY-6",
	},
	{ value: "Plockton, GB", label: "Plockton, GB", code: "PLOCK-6" },
	{ value: "Porthleven, GB", label: "Porthleven, GB", code: "PORTH-6" },
	{ value: "Swaffham, GB", label: "Swaffham, GB", code: "SFM-002" },
	{ value: "Swanage, GB", label: "Swanage, GB", code: "SGE-002" },
	{ value: "Shropshire, GB", label: "Shropshire, GB", code: "SHCTY-6" },
	{
		value: "South Lanarkshire, GB",
		label: "South Lanarkshire, GB",
		code: "SLCTY-6",
	},
	{
		value: "Stockton-on-Tees, GB",
		label: "Stockton-on-Tees, GB",
		code: "STOCK-6",
	},
	{ value: "St Ives, GB", label: "St Ives, GB", code: "STV-002" },
	{
		value: "Tarbert, Kintyre, GB",
		label: "Tarbert, Kintyre, GB",
		code: "TBE-002",
	},
	{ value: "Teignmouth, GB", label: "Teignmouth, GB", code: "TGH-002" },
	{
		value: "Talybont-on-Usk, GB",
		label: "Talybont-on-Usk, GB",
		code: "TOK-002",
	},
	{ value: "Tavistock, GB", label: "Tavistock, GB", code: "TSK-002" },
	{ value: "Talsarnau, GB", label: "Talsarnau, GB", code: "TSU-002" },
	{
		value: "Talybont, Barmouth, GB",
		label: "Talybont, Barmouth, GB",
		code: "TYB-002",
	},
	{ value: "Ulverston, GB", label: "Ulverston, GB", code: "ULVER-1" },
	{ value: "Weybridge, GB", label: "Weybridge, GB", code: "WEYGE-6" },
	{ value: "Wilmington, GB", label: "Wilmington, GB", code: "WILMI-6" },
	{ value: "Woodstock, GB", label: "Woodstock, GB", code: "WOODS-6" },
	{ value: "Whitby, GB", label: "Whitby, GB", code: "WTB-002" },
	{ value: "Cumbria, GB", label: "Cumbria, GB", code: "CUMCTY-6" },
	{ value: "Dorset, GB", label: "Dorset, GB", code: "DORCTY-6" },
	{ value: "Essex , GB", label: "Essex , GB", code: "ESSCTY-6" },
	{ value: "Gairloch , GB", label: "Gairloch , GB", code: "Gairloch" },
	{ value: "Hampshire, GB", label: "Hampshire, GB", code: "HAMCTY-6" },
	{ value: "Holyhead, GB", label: "Holyhead, GB", code: "Holyhead" },
	{
		value: "Islas Hébridas Interiores, GB",
		label: "Islas Hébridas Interiores, GB",
		code: "INNERH-6",
	},
	{ value: "Lancashire, GB", label: "Lancashire, GB", code: "LANCTY-6" },
	{
		value: "North Lincolnshire, GB",
		label: "North Lincolnshire, GB",
		code: "LCNSHRIE",
	},
	{
		value: "Lothian Occidental, GB",
		label: "Lothian Occidental, GB",
		code: "LOTCTY-6",
	},
	{ value: "Medway, GB", label: "Medway, GB", code: "MEDWAY-6" },
	{ value: "Norfolk, GB", label: "Norfolk, GB", code: "NORCTY-6" },
	{
		value: "Islas Hébridas Exteriores, GB",
		label: "Islas Hébridas Exteriores, GB",
		code: "OUTERH-6",
	},
	{ value: "Renfrewshire, GB", label: "Renfrewshire, GB", code: "REFCTY-6" },
	{ value: "Wilton, GB", label: "Wilton, GB", code: "WILTON-6" },
	{ value: "Sussex Oeste, GB", label: "Sussex Oeste, GB", code: "WSUCTY-6" },
	{ value: "Yarmouth, GB", label: "Yarmouth, GB", code: "Yarmouth" },
	{ value: "Tintagel, GB", label: "Tintagel, GB", code: "fake-742" },
	{ value: "Aberdeenshire, GB", label: "Aberdeenshire, GB", code: "ABSHIRE-6" },
	{
		value: "Cambridgeshire, GB",
		label: "Cambridgeshire, GB",
		code: "CAMBCTY-6",
	},
	{ value: "Cairnryan, GB", label: "Cairnryan, GB", code: "Cairnryan" },
	{
		value: "Gloucestershire, GB",
		label: "Gloucestershire, GB",
		code: "GLOUCTY-6",
	},
	{ value: "Hertfordshire, GB", label: "Hertfordshire, GB", code: "HERTCTY-6" },
	{ value: "Highland, GB", label: "Highland, GB", code: "HIGHCTY-6" },
	{ value: "Kent, GB", label: "Kent, GB", code: "KENTCTY-6" },
	{
		value: "Leicestershire, GB",
		label: "Leicestershire, GB",
		code: "LEICCTY-6",
	},
	{ value: "Lincolnshire , GB", label: "Lincolnshire , GB", code: "LINCCTY-6" },
	{ value: "Lymington, GB", label: "Lymington, GB", code: "Lymington" },
	{
		value: "North Somerset, GB",
		label: "North Somerset, GB",
		code: "NRTHSMRST",
	},
	{ value: "Portpatrick, GB", label: "Portpatrick, GB", code: "PATRICK-6" },
	{ value: "Portsoy, GB", label: "Portsoy, GB", code: "PORTSOY-6" },
	{ value: "Winchelsea, GB", label: "Winchelsea, GB", code: "WINCHEL-6" },
	{ value: "Winster, GB", label: "Winster, GB", code: "WINSTER-6" },
	{ value: "Cornualles, GB", label: "Cornualles, GB", code: "Cornualles" },
	{ value: "Derbyshire, GB", label: "Derbyshire, GB", code: "DERBYCTY-6" },
	{ value: "Isla de Mull, GB", label: "Isla de Mull, GB", code: "IsleofMull" },
	{
		value: "Northallerton, GB",
		label: "Northallerton, GB",
		code: "NORTHTON-6",
	},
	{
		value: "Steamboat Springs, GB",
		label: "Steamboat Springs, GB",
		code: "steamboatsprings",
	},
	{
		value: "Anchorage (Alaska), US",
		label: "Anchorage (Alaska), US",
		code: "ANC",
	},
	{
		value: "Atlanta (Georgia), US",
		label: "Atlanta (Georgia), US",
		code: "ATL",
	},
	{ value: "Austin (Texas), US", label: "Austin (Texas), US", code: "AUS" },
	{ value: "Columbus (Ohio), US", label: "Columbus (Ohio), US", code: "COU" },
	{
		value: "Denver (Colorado), US",
		label: "Denver (Colorado), US",
		code: "DEN",
	},
	{ value: "Indianapolis, US", label: "Indianapolis, US", code: "INA" },
	{
		value: "Jackson (Mississippi), US",
		label: "Jackson (Mississippi), US",
		code: "JAC",
	},
	{
		value: "La Isla Grande (Hawaii), US",
		label: "La Isla Grande (Hawaii), US",
		code: "KAI",
	},
	{ value: "Kauai, US", label: "Kauai, US", code: "LIH" },
	{ value: "Maui, US", label: "Maui, US", code: "MAU" },
	{
		value: "Montgomery (Alabama), US",
		label: "Montgomery (Alabama), US",
		code: "MGM",
	},
	{
		value: "Madison (Winsconsin), US",
		label: "Madison (Winsconsin), US",
		code: "MSN",
	},
	{
		value: "Nashville (Tennessee), US",
		label: "Nashville (Tennessee), US",
		code: "NSV",
	},
	{
		value: "Nueva York (NuevaYork), US",
		label: "Nueva York (NuevaYork), US",
		code: "NYC",
	},
	{
		value: "Ciudad de Oklahoma (Oklahoma), US",
		label: "Ciudad de Oklahoma (Oklahoma), US",
		code: "OKL",
	},
	{
		value: "Phoenix (Arizona), US",
		label: "Phoenix (Arizona), US",
		code: "PHX",
	},
	{
		value: "Sacramento (California), US",
		label: "Sacramento (California), US",
		code: "SAC",
	},
	{
		value: "Salt lake City (Utah), US",
		label: "Salt lake City (Utah), US",
		code: "SLC",
	},
	{ value: "Washington DC, US", label: "Washington DC, US", code: "WAS" },
	{ value: "Honolulu, US", label: "Honolulu, US", code: "KAE-4" },
	{ value: "Spriengfield, US", label: "Spriengfield, US", code: "SGH-1" },
	{
		value: "Bismarck (Dakota del Norte), US",
		label: "Bismarck (Dakota del Norte), US",
		code: "BIS",
	},
	{ value: "Boise (Idaho), US", label: "Boise (Idaho), US", code: "BOI" },
	{
		value: "Boston (Massachusetts), US",
		label: "Boston (Massachusetts), US",
		code: "BOS",
	},
	{
		value: "Charlotte (Carolina del Norte), US",
		label: "Charlotte (Carolina del Norte), US",
		code: "CLT",
	},
	{
		value: "Frankfort (Kentucky), US",
		label: "Frankfort (Kentucky), US",
		code: "FRF",
	},
	{ value: "Helena (Montana), US", label: "Helena (Montana), US", code: "HLN" },
	{
		value: "Los Ángeles (California), US",
		label: "Los Ángeles (California), US",
		code: "LAX",
	},
	{
		value: "Las Vegas (Nevada), US",
		label: "Las Vegas (Nevada), US",
		code: "LVS",
	},
	{
		value: "Kansas City (Missouri), US",
		label: "Kansas City (Missouri), US",
		code: "MCI",
	},
	{ value: "Miami (Florida), US", label: "Miami (Florida), US", code: "MIA" },
	{
		value: "Minneapolis (Minnesota), US",
		label: "Minneapolis (Minnesota), US",
		code: "MSP",
	},
	{
		value: "Nueva Orleans (Louisiana), US",
		label: "Nueva Orleans (Louisiana), US",
		code: "MSY",
	},
	{ value: "Omaha (Nebraska), US", label: "Omaha (Nebraska), US", code: "OMA" },
	{ value: "Chicago IL, US", label: "Chicago IL, US", code: "ORD" },
	{
		value: "Portland (Oregon), US",
		label: "Portland (Oregon), US",
		code: "PDX",
	},
	{ value: "Filadelfia, US", label: "Filadelfia, US", code: "PHL" },
	{
		value: "Pierre (Dakota del Sur), US",
		label: "Pierre (Dakota del Sur), US",
		code: "PI3",
	},
	{
		value: "Raleigh (Carolina del Norte), US",
		label: "Raleigh (Carolina del Norte), US",
		code: "RAL",
	},
	{ value: "Vail, US", label: "Vail, US", code: "ROI" },
	{
		value: "Seattle (Washington), US",
		label: "Seattle (Washington), US",
		code: "SEA",
	},
	{
		value: "San Francisco (California), US",
		label: "San Francisco (California), US",
		code: "SFO",
	},
	{
		value: "Springfield (Missouri), US",
		label: "Springfield (Missouri), US",
		code: "SGF",
	},
	{
		value: "Saint Louis (Missouri), US",
		label: "Saint Louis (Missouri), US",
		code: "STL",
	},
	{ value: "Wichita (Kansas), US", label: "Wichita (Kansas), US", code: "WIC" },
	{
		value: "Richmond (Virginia), US",
		label: "Richmond (Virginia), US",
		code: "ZRD",
	},
	{ value: "Columbia SC, US", label: "Columbia SC, US", code: "COL-3" },
	{
		value: "San Luis Obispo CA, US",
		label: "San Luis Obispo CA, US",
		code: "CSL-1",
	},
	{ value: "Clinton, US", label: "Clinton, US", code: "CSM-2" },
	{ value: "Hancock MI, US", label: "Hancock MI, US", code: "HCK-1" },
	{ value: "Salt Lake City, US", label: "Salt Lake City, US", code: "SLC-1" },
	{ value: "Santa Ana CA, US", label: "Santa Ana CA, US", code: "STA-1" },
	{ value: "Saint Cloud, US", label: "Saint Cloud, US", code: "STC-1" },
	{ value: "Tulsa, US", label: "Tulsa, US", code: "TUL-1" },
	{
		value: "West Yellowstone MT, US",
		label: "West Yellowstone MT, US",
		code: "WYS-1",
	},
	{
		value: "Durham (Carolina del Norte), US",
		label: "Durham (Carolina del Norte), US",
		code: "NC",
	},
	{ value: "Fargo, US", label: "Fargo, US", code: "ND" },
	{
		value: "Albuquerque (Nuevo México), US",
		label: "Albuquerque (Nuevo México), US",
		code: "ABQ",
	},
	{
		value: "Atlantic City (Nueva Jersey), US",
		label: "Atlantic City (Nueva Jersey), US",
		code: "ACY",
	},
	{ value: "Augusta (Maine), US", label: "Augusta (Maine), US", code: "AG1" },
	{
		value: "Bryce Canyon: Parque Nacional, US",
		label: "Bryce Canyon: Parque Nacional, US",
		code: "BCE",
	},
	{ value: "Bakersfield, US", label: "Bakersfield, US", code: "BFL" },
	{
		value: "Birmingham (Alabama), US",
		label: "Birmingham (Alabama), US",
		code: "BIR",
	},
	{
		value: "Bowling Green (Kentucky), US",
		label: "Bowling Green (Kentucky), US",
		code: "BWG",
	},
	{
		value: "Charleston (Carolina del Sur), US",
		label: "Charleston (Carolina del Sur), US",
		code: "CHS",
	},
	{
		value: "Arcos: Parque Nacional, US",
		label: "Arcos: Parque Nacional, US",
		code: "CNY",
	},
	{
		value: "Columbia (Missouri), US",
		label: "Columbia (Missouri), US",
		code: "CO5",
	},
	{
		value: "Las Cruces (Nuevo México), US",
		label: "Las Cruces (Nuevo México), US",
		code: "CRU",
	},
	{
		value: "Cheyenne (Wyoming), US",
		label: "Cheyenne (Wyoming), US",
		code: "CYS",
	},
	{
		value: "Des Moines (Iowa), US",
		label: "Des Moines (Iowa), US",
		code: "DEM",
	},
	{ value: "Dallas (Texas), US", label: "Dallas (Texas), US", code: "DFW" },
	{
		value: "Duluth (Minnesota), US",
		label: "Duluth (Minnesota), US",
		code: "DLH",
	},
	{ value: "Death Valley, US", label: "Death Valley, US", code: "DTH" },
	{
		value: "Detroit (Michigan), US",
		label: "Detroit (Michigan), US",
		code: "DTW",
	},
	{
		value: "Elizabethtown (Kentucky), US",
		label: "Elizabethtown (Kentucky), US",
		code: "EKX",
	},
	{ value: "El Paso (Texas), US", label: "El Paso (Texas), US", code: "EPA" },
	{
		value: "Fresno (California), US",
		label: "Fresno (California), US",
		code: "FAT",
	},
	{
		value: "Sioux Falls (Dakota del Sur), US",
		label: "Sioux Falls (Dakota del Sur), US",
		code: "FSD",
	},
	{
		value: "Fort Smith (Arkansas), US",
		label: "Fort Smith (Arkansas), US",
		code: "FSM",
	},
	{
		value: "Gallup (Nuevo México), US",
		label: "Gallup (Nuevo México), US",
		code: "GAL",
	},
	{ value: "Gran Cañón, US", label: "Gran Cañón, US", code: "GCN" },
	{
		value: "Georgetown (Kentucky), US",
		label: "Georgetown (Kentucky), US",
		code: "GE1",
	},
	{
		value: "Monument Valley: Parque Nacional, US",
		label: "Monument Valley: Parque Nacional, US",
		code: "GMV",
	},
	{ value: "Houston (Texas), US", label: "Houston (Texas), US", code: "HOU" },
	{
		value: "New Haven (Connecticut), US",
		label: "New Haven (Connecticut), US",
		code: "HVN",
	},
	{
		value: "Las Cataratas del Niágara (Nueva York), US",
		label: "Las Cataratas del Niágara (Nueva York), US",
		code: "IAG",
	},
	{
		value: "Jacksonville (Florida), US",
		label: "Jacksonville (Florida), US",
		code: "JAX",
	},
	{
		value: "Lassen Volcanic: Parque Nacional, US",
		label: "Lassen Volcanic: Parque Nacional, US",
		code: "LN1",
	},
	{
		value: "Orlando (Florida), US",
		label: "Orlando (Florida), US",
		code: "MCO",
	},
	{
		value: "Memphis (Tennessee), US",
		label: "Memphis (Tennessee), US",
		code: "MEM",
	},
	{
		value: "Manchester  (Nuevo Hampshire), US",
		label: "Manchester  (Nuevo Hampshire), US",
		code: "MHT",
	},
	{
		value: "Milwaukee (Winsconsin), US",
		label: "Milwaukee (Winsconsin), US",
		code: "MIW",
	},
	{ value: "Mammoth Lakes, US", label: "Mammoth Lakes, US", code: "MMH" },
	{
		value: "Baltimore (Maryland), US",
		label: "Baltimore (Maryland), US",
		code: "MTN",
	},
	{
		value: "Yosemite: Parque Nacional, US",
		label: "Yosemite: Parque Nacional, US",
		code: "OYS",
	},
	{
		value: "Pensacola (Florida), US",
		label: "Pensacola (Florida), US",
		code: "PEB",
	},
	{ value: "Pittsburgh, US", label: "Pittsburgh, US", code: "PIT" },
	{
		value: "Providence (Rhode Island), US",
		label: "Providence (Rhode Island), US",
		code: "PRO",
	},
	{
		value: "Petrified Forest: Parque Nacional, US",
		label: "Petrified Forest: Parque Nacional, US",
		code: "PTR",
	},
	{ value: "Reno (Nevada), US", label: "Reno (Nevada), US", code: "RNO" },
	{
		value: "San Antonio (Texas), US",
		label: "San Antonio (Texas), US",
		code: "SAA",
	},
	{
		value: "San Diego (California), US",
		label: "San Diego (California), US",
		code: "SAD",
	},
	{ value: "Santa Barbara, US", label: "Santa Barbara, US", code: "SBA" },
	{
		value: "Zion: Parque Nacional, US",
		label: "Zion: Parque Nacional, US",
		code: "SGU",
	},
	{
		value: "Santa Fe (Nuevo México), US",
		label: "Santa Fe (Nuevo México), US",
		code: "STF",
	},
	{ value: "Tampa (Florida), US", label: "Tampa (Florida), US", code: "TPA" },
	{ value: "Capitol Reef, US", label: "Capitol Reef, US", code: "TRR" },
	{
		value: "Tupelo (Mississippi), US",
		label: "Tupelo (Mississippi), US",
		code: "TUP",
	},
	{
		value: "Kings Canyon y Sequoia: Parques Nacionales, US",
		label: "Kings Canyon y Sequoia: Parques Nacionales, US",
		code: "VIS",
	},
	{
		value: "Yellowstone: Parque Nacional, US",
		label: "Yellowstone: Parque Nacional, US",
		code: "WYS",
	},
	{
		value: "Cayos de la Florida (Florida), US",
		label: "Cayos de la Florida (Florida), US",
		code: "YSP",
	},
	{ value: "Kodiak (Alaska), US", label: "Kodiak (Alaska), US", code: "ADQ1" },
	{
		value: "Flathead County (Montana), US",
		label: "Flathead County (Montana), US",
		code: "FCA1",
	},
	{ value: "Oahu, US", label: "Oahu, US", code: "KAE1" },
	{ value: "Beverly Hills, US", label: "Beverly Hills, US", code: "LAX3" },
	{ value: "Salinas, US", label: "Salinas, US", code: "MRY8" },
	{ value: "Clearwater, US", label: "Clearwater, US", code: "CLE-1" },
	{
		value: "Canyonlands: Parque Nacional, US",
		label: "Canyonlands: Parque Nacional, US",
		code: "CNY-1",
	},
	{ value: "Cape May NJ, US", label: "Cape May NJ, US", code: "CPE-1" },
	{
		value: "Dover (Delaware), US",
		label: "Dover (Delaware), US",
		code: "DO1-1",
	},
	{ value: "Kalispell MT, US", label: "Kalispell MT, US", code: "FCA-1" },
	{ value: "Laughlin , US", label: "Laughlin , US", code: "GAL-1" },
	{ value: "Laguna beach CA, US", label: "Laguna beach CA, US", code: "LAX-6" },
	{
		value: "Everglades City FL, US",
		label: "Everglades City FL, US",
		code: "NAL-4",
	},
	{ value: "Rolla, US", label: "Rolla, US", code: "OLL-1" },
	{
		value: "Panama City Beach, US",
		label: "Panama City Beach, US",
		code: "PAN-2",
	},
	{
		value: "Conway (Arkansas), US",
		label: "Conway (Arkansas), US",
		code: "ROK-5",
	},
	{
		value: "Saguaro: Parque Nacional, US",
		label: "Saguaro: Parque Nacional, US",
		code: "TUS-1",
	},
	{ value: "Whitefish MT, US", label: "Whitefish MT, US", code: "WH1-1" },
	{ value: "Woodbridge VA, US", label: "Woodbridge VA, US", code: "WOD-1" },
	{ value: "Brunswick, US", label: "Brunswick, US", code: "BRK-11" },
	{ value: "Glens Falls, US", label: "Glens Falls, US", code: "GLF-11" },
	{
		value: "Los Pináculos: Parque Nacional, US",
		label: "Los Pináculos: Parque Nacional, US",
		code: "MRY-20",
	},
	{ value: "Port Henry NY, US", label: "Port Henry NY, US", code: "PTH-11" },
	{
		value: "Redwood: Parque Nacional CA, US",
		label: "Redwood: Parque Nacional CA, US",
		code: "SFO-15",
	},
	{ value: "Casper WY, US", label: "Casper WY, US", code: "WY__CPR" },
	{ value: "Corolla NC, US", label: "Corolla NC, US", code: "fake-236" },
	{
		value: "Emerald Isle NC, US",
		label: "Emerald Isle NC, US",
		code: "fake-238",
	},
	{ value: "Weatherford TX, US", label: "Weatherford TX, US", code: "TX" },
	{ value: "Albany GA, US", label: "Albany GA, US", code: "ABY" },
	{ value: "Athens GA, US", label: "Athens GA, US", code: "AHN" },
	{ value: "Amarillo TX, US", label: "Amarillo TX, US", code: "AMA" },
	{ value: "Aspen CO, US", label: "Aspen CO, US", code: "ASN" },
	{ value: "Astoria OR, US", label: "Astoria OR, US", code: "AST" },
	{ value: "Appleton WI, US", label: "Appleton WI, US", code: "ATW" },
	{ value: "Bend OR, US", label: "Bend OR, US", code: "BE7" },
	{ value: "Bellingham WA, US", label: "Bellingham WA, US", code: "BLI" },
	{ value: "Beaumont TX, US", label: "Beaumont TX, US", code: "BMT" },
	{ value: "Butte MT, US", label: "Butte MT, US", code: "BTM" },
	{ value: "Búfalo NY, US", label: "Búfalo NY, US", code: "BUF" },
	{ value: "Crescent City CA, US", label: "Crescent City CA, US", code: "CE1" },
	{ value: "Chico CA, US", label: "Chico CA, US", code: "CIC" },
	{ value: "Cleveland OH, US", label: "Cleveland OH, US", code: "CLV" },
	{
		value: "Corpus Christi TX, US",
		label: "Corpus Christi TX, US",
		code: "CRP",
	},
	{ value: "Charleston WV, US", label: "Charleston WV, US", code: "CRW" },
	{ value: "Crested Butte CO, US", label: "Crested Butte CO, US", code: "CSE" },
	{ value: "Columbus GA, US", label: "Columbus GA, US", code: "CSG" },
	{ value: "Clarksville TN, US", label: "Clarksville TN, US", code: "CVK" },
	{ value: "Caldwell ID, US", label: "Caldwell ID, US", code: "CW3" },
	{ value: "Deadwood SD, US", label: "Deadwood SD, US", code: "DEA" },
	{ value: "Greenville SC, US", label: "Greenville SC, US", code: "DUC" },
	{ value: "El Monte CA, US", label: "El Monte CA, US", code: "EMO" },
	{ value: "Eureka CA, US", label: "Eureka CA, US", code: "EUE" },
	{ value: "Fayetteville NC, US", label: "Fayetteville NC, US", code: "FAY" },
	{
		value: "Colorado Springs CO, US",
		label: "Colorado Springs CO, US",
		code: "FCS",
	},
	{
		value: "Fort Lauderdale FL, US",
		label: "Fort Lauderdale FL, US",
		code: "FLL",
	},
	{ value: "Fort Collins CO, US", label: "Fort Collins CO, US", code: "FNL" },
	{ value: "Fort Myers FL, US", label: "Fort Myers FL, US", code: "FOM" },
	{ value: "Spokane WA, US", label: "Spokane WA, US", code: "GEG" },
	{ value: "Gainsville FL, US", label: "Gainsville FL, US", code: "GNV" },
	{ value: "Grand rapids MI, US", label: "Grand rapids MI, US", code: "GRD" },
	{ value: "Great falls MT, US", label: "Great falls MT, US", code: "GRF" },
	{ value: "Homestead FL, US", label: "Homestead FL, US", code: "HO5" },
	{ value: "Wilmington DE, US", label: "Wilmington DE, US", code: "ILM" },
	{ value: "Iowa City IA, US", label: "Iowa City IA, US", code: "IOW" },
	{ value: "Oahu HI, US", label: "Oahu HI, US", code: "KAE" },
	{ value: "Kennewick WA, US", label: "Kennewick WA, US", code: "KW1" },
	{
		value: "Lansing (Michigan), US",
		label: "Lansing (Michigan), US",
		code: "LA2",
	},
	{ value: "Long Beach CA, US", label: "Long Beach CA, US", code: "LG2" },
	{ value: "Lone Pine CA, US", label: "Lone Pine CA, US", code: "LOP" },
	{ value: "Macon GA, US", label: "Macon GA, US", code: "MGA" },
	{ value: "Manhattan KS, US", label: "Manhattan KS, US", code: "MHK" },
	{ value: "Mobile AL, US", label: "Mobile AL, US", code: "MOB" },
	{ value: "Missoula MT, US", label: "Missoula MT, US", code: "MSO" },
	{ value: "Moses Lake WA, US", label: "Moses Lake WA, US", code: "MWH" },
	{ value: "Myrtle Beach SC, US", label: "Myrtle Beach SC, US", code: "MYR" },
	{ value: "Naples FL, US", label: "Naples FL, US", code: "NAL" },
	{ value: "Nampa ID, US", label: "Nampa ID, US", code: "NM1" },
	{ value: "Norfolk VA, US", label: "Norfolk VA, US", code: "NOF" },
	{ value: "Oakland CA, US", label: "Oakland CA, US", code: "OAK" },
	{ value: "Ogden UT, US", label: "Ogden UT, US", code: "OGD" },
	{ value: "Ontario CA, US", label: "Ontario CA, US", code: "ONT" },
	{ value: "Coos Bay OR, US", label: "Coos Bay OR, US", code: "OTH" },
	{ value: " Hattiesburg MS, US", label: " Hattiesburg MS, US", code: "PIB" },
	{ value: "Pocatello ID, US", label: "Pocatello ID, US", code: "PIH" },
	{
		value: "Prescott Valley AZ, US",
		label: "Prescott Valley AZ, US",
		code: "PRC",
	},
	{ value: "Provo UT, US", label: "Provo UT, US", code: "PRV" },
	{
		value: "Port St. Lucie FL, US",
		label: "Port St. Lucie FL, US",
		code: "PS1",
	},
	{ value: "Palm Springs CA, US", label: "Palm Springs CA, US", code: "PSP" },
	{ value: "Redding CA, US", label: "Redding CA, US", code: "REI" },
	{ value: "Roseburg OR, US", label: "Roseburg OR, US", code: "RGB" },
	{ value: "San José CA, US", label: "San José CA, US", code: "SAJ" },
	{ value: "Sarasota FL, US", label: "Sarasota FL, US", code: "SAS" },
	{ value: "Savannah GA, US", label: "Savannah GA, US", code: "SAV" },
	{ value: "Shreveport LA, US", label: "Shreveport LA, US", code: "SHV" },
	{ value: "Salina KS, US", label: "Salina KS, US", code: "SLN" },
	{ value: "Brownsville TX, US", label: "Brownsville TX, US", code: "SOP" },
	{ value: "statesboro GA, US", label: "statesboro GA, US", code: "TBR" },
	{ value: "Tifton GA, US", label: "Tifton GA, US", code: "TMA" },
	{ value: "Lago Tahoe NV, US", label: "Lago Tahoe NV, US", code: "TVL" },
	{ value: "Valdosta GA, US", label: "Valdosta GA, US", code: "VAL" },
	{ value: "Yakima WA, US", label: "Yakima WA, US", code: "YKM" },
	{ value: "Ocean Shores WA, US", label: "Ocean Shores WA, US", code: "APG1" },
	{ value: "Redmond OR, US", label: "Redmond OR, US", code: "BE71" },
	{ value: "Binghamton NY, US", label: "Binghamton NY, US", code: "BGM3" },
	{ value: "Cocoa Beach FL, US", label: "Cocoa Beach FL, US", code: "COI1" },
	{
		value: "Daytona Beach FL, US",
		label: "Daytona Beach FL, US",
		code: "FL-1",
	},
	{ value: "Flagstaff AZ, US", label: "Flagstaff AZ, US", code: "GCN2" },
	{ value: "Kahului HI, US", label: "Kahului HI, US", code: "MAU1" },
	{ value: "Hialeah FL, US", label: "Hialeah FL, US", code: "MIA2" },
	{
		value: "San Luis Obispo CA, US",
		label: "San Luis Obispo CA, US",
		code: "MRY5",
	},
	{ value: "Boca Raton FL, US", label: "Boca Raton FL, US", code: "PBI4" },
	{ value: "Mesa AZ, US", label: "Mesa AZ, US", code: "PHX1" },
	{ value: "Rapid City SD, US", label: "Rapid City SD, US", code: "RAP1" },
	{ value: "Tucsón AZ, US", label: "Tucsón AZ, US", code: "TUS1" },
	{ value: "Absecon NJ, US", label: "Absecon NJ, US", code: "AB5-1" },
	{ value: "Albany NY, US", label: "Albany NY, US", code: "ALN-3" },
	{ value: "Annapolis MD, US", label: "Annapolis MD, US", code: "ANP-1" },
	{ value: "Ashland OR, US", label: "Ashland OR, US", code: "AX2-1" },
	{ value: "Victorville CA, US", label: "Victorville CA, US", code: "BAW-3" },
	{ value: "Scottsbluf NE, US", label: "Scottsbluf NE, US", code: "BFF-1" },
	{ value: "Biloxi MS, US", label: "Biloxi MS, US", code: "BIX-3" },
	{ value: "Gulfport MS, US", label: "Gulfport MS, US", code: "BIX-6" },
	{ value: "Boone CA, US", label: "Boone CA, US", code: "BN1-1" },
	{ value: "Boulder CO, US", label: "Boulder CO, US", code: "BOE-1" },
	{ value: "Bottineau ND, US", label: "Bottineau ND, US", code: "BOT-1" },
	{
		value: "Bretton Woods NH, US",
		label: "Bretton Woods NH, US",
		code: "BRT-1",
	},
	{ value: "North Conway NH, US", label: "North Conway NH, US", code: "BRT-5" },
	{ value: "Burlington VT, US", label: "Burlington VT, US", code: "BTV-3" },
	{ value: "Canton OH, US", label: "Canton OH, US", code: "CA5-3" },
	{
		value: "New Philadelphia OH, US",
		label: "New Philadelphia OH, US",
		code: "CA5_3",
	},
	{ value: "Akron OH, US", label: "Akron OH, US", code: "CAK-1" },
	{ value: "Cedar Rapids IA, US", label: "Cedar Rapids IA, US", code: "CED-1" },
	{
		value: "Cape Girardeau MO, US",
		label: "Cape Girardeau MO, US",
		code: "CGI-1",
	},
	{ value: "West Chester OH, US", label: "West Chester OH, US", code: "CIN-3" },
	{ value: "Mason OH, US", label: "Mason OH, US", code: "CIN-9" },
	{ value: "Asheville NC, US", label: "Asheville NC, US", code: "CKK-3" },
	{ value: "Cherokee NC, US", label: "Cherokee NC, US", code: "CKK-6" },
	{ value: "Clinton MD, US", label: "Clinton MD, US", code: "CLI-1" },
	{ value: "Waco TX, US", label: "Waco TX, US", code: "CNW-3" },
	{ value: "Concord NH, US", label: "Concord NH, US", code: "COC-1" },
	{ value: "Concord, US", label: "Concord, US", code: "COC-3" },
	{
		value: "Cabo Cañaveral FL, US",
		label: "Cabo Cañaveral FL, US",
		code: "COI-1",
	},
	{ value: "Wildwood NJ, US", label: "Wildwood NJ, US", code: "CPE-2" },
	{ value: "Clinton OK, US", label: "Clinton OK, US", code: "CSM-1" },
	{ value: "Aurora CO, US", label: "Aurora CO, US", code: "DEN-5" },
	{ value: "Dothan AL, US", label: "Dothan AL, US", code: "DHN-3" },
	{ value: "Kearney NE, US", label: "Kearney NE, US", code: "EAR-1" },
	{ value: "Keene NH, US", label: "Keene NH, US", code: "EEN-1" },
	{ value: "Wahpeton ND, US", label: "Wahpeton ND, US", code: "FA4-2" },
	{ value: "Fairbanks AK, US", label: "Fairbanks AK, US", code: "FAI-1" },
	{
		value: "Morehead city NC, US",
		label: "Morehead city NC, US",
		code: "FFA-6",
	},
	{ value: "Greeley CO, US", label: "Greeley CO, US", code: "FNL-3" },
	{ value: "Fort Worth TX, US", label: "Fort Worth TX, US", code: "FOH-1" },
	{ value: "Williams AZ, US", label: "Williams AZ, US", code: "GCN-5" },
	{ value: "Tusayan AZ, US", label: "Tusayan AZ, US", code: "GCN-6" },
	{ value: "North Rim AZ, US", label: "North Rim AZ, US", code: "GCN-9" },
	{ value: "Gatlingburg TN, US", label: "Gatlingburg TN, US", code: "GKT-1" },
	{ value: "Greenville MS, US", label: "Greenville MS, US", code: "GLH-1" },
	{ value: "Galveston TX, US", label: "Galveston TX, US", code: "GLS-1" },
	{ value: "Grand Island NE, US", label: "Grand Island NE, US", code: "GRI-1" },
	{ value: "Hastings NE, US", label: "Hastings NE, US", code: "GRI-2" },
	{ value: "Greensboro NC, US", label: "Greensboro NC, US", code: "GRR-3" },
	{ value: "Harrisburg PA, US", label: "Harrisburg PA, US", code: "HAI-1" },
	{ value: "Natchez MS, US", label: "Natchez MS, US", code: "HEZ-1" },
	{ value: "Homer AK, US", label: "Homer AK, US", code: "HMR-1" },
	{ value: "Wilmington NC, US", label: "Wilmington NC, US", code: "ILM-3" },
	{ value: "Jamestown ND, US", label: "Jamestown ND, US", code: "JMS-1" },
	{ value: "Valley City ND, US", label: "Valley City ND, US", code: "JMS-2" },
	{ value: "Tuba city AZ, US", label: "Tuba city AZ, US", code: "KAY-3" },
	{ value: "Riverside CA, US", label: "Riverside CA, US", code: "LAG-1" },
	{ value: "Lawton OK, US", label: "Lawton OK, US", code: "LAW-3" },
	{ value: "Santa Mónica CA, US", label: "Santa Mónica CA, US", code: "LAX12" },
	{ value: "Anaheim CA, US", label: "Anaheim CA, US", code: "LAX49" },
	{ value: "North Platte NE, US", label: "North Platte NE, US", code: "LBF-3" },
	{ value: "Lebanon MO, US", label: "Lebanon MO, US", code: "LBN-1" },
	{ value: "Lexington KY, US", label: "Lexington KY, US", code: "LEX-1" },
	{ value: "Lufkin TX, US", label: "Lufkin TX, US", code: "LFK-3" },
	{ value: "Lafayette LA, US", label: "Lafayette LA, US", code: "LFT-3" },
	{ value: "Lincoln NE, US", label: "Lincoln NE, US", code: "LNK-1" },
	{ value: "Lincoln NE, US", label: "Lincoln NE, US", code: "LNK-3" },
	{ value: "Red Bank NJ, US", label: "Red Bank NJ, US", code: "LOB-3" },
	{ value: "Lawrence KS, US", label: "Lawrence KS, US", code: "LWC-1" },
	{ value: "Meridian MS, US", label: "Meridian MS, US", code: "MEI-1" },
	{ value: "Mansfield OH, US", label: "Mansfield OH, US", code: "MFD-1" },
	{ value: "Medford OR, US", label: "Medford OR, US", code: "MFR-1" },
	{ value: "Bedford NH, US", label: "Bedford NH, US", code: "MHT-6" },
	{ value: "Miles City MT, US", label: "Miles City MT, US", code: "MIC-1" },
	{
		value: "Carmel- by-the -Sea CA, US",
		label: "Carmel- by-the -Sea CA, US",
		code: "MRY-1",
	},
	{ value: "Monterey CA, US", label: "Monterey CA, US", code: "MRY-3" },
	{ value: "Nashua NH, US", label: "Nashua NH, US", code: "NAH-1" },
	{ value: "Ogallala NE, US", label: "Ogallala NE, US", code: "OGA-1" },
	{ value: "Olive Branch MS, US", label: "Olive Branch MS, US", code: "OLB-1" },
	{ value: "Southaven MS, US", label: "Southaven MS, US", code: "OLB-2" },
	{ value: "Olympia WA, US", label: "Olympia WA, US", code: "OLM-1" },
	{ value: "Mariposa CA, US", label: "Mariposa CA, US", code: "OYS-5" },
	{ value: "Paducah IL, US", label: "Paducah IL, US", code: "PAH-3" },
	{
		value: "West Palm Beach FL, US",
		label: "West Palm Beach FL, US",
		code: "PBI-7",
	},
	{ value: "Beaverton OR, US", label: "Beaverton OR, US", code: "PDX-4" },
	{ value: "Greenville NC, US", label: "Greenville NC, US", code: "PGB-1" },
	{ value: "Parsippany NJ, US", label: "Parsippany NJ, US", code: "PNJ-1" },
	{ value: "Dover NH, US", label: "Dover NH, US", code: "POM-2" },
	{ value: "Portsmouth NH, US", label: "Portsmouth NH, US", code: "PSM-1" },
	{ value: "Keystone CO, US", label: "Keystone CO, US", code: "QKS-1" },
	{ value: "Cary NC, US", label: "Cary NC, US", code: "RAL-9" },
	{ value: "Red Lodge MT, US", label: "Red Lodge MT, US", code: "RED-1" },
	{ value: "Roanoke VA, US", label: "Roanoke VA, US", code: "ROA-1" },
	{ value: "Roswell NM, US", label: "Roswell NM, US", code: "ROW-3" },
	{ value: "Santa Rosa CA, US", label: "Santa Rosa CA, US", code: "SA9-1" },
	{ value: "Tacoma WA, US", label: "Tacoma WA, US", code: "SEA-4" },
	{ value: "Sheridan CO, US", label: "Sheridan CO, US", code: "SHR-1" },
	{ value: "Sandusky OH, US", label: "Sandusky OH, US", code: "SKY-1" },
	{ value: "Fort Wayne IN, US", label: "Fort Wayne IN, US", code: "SMD-3" },
	{ value: "San marcos TX, US", label: "San marcos TX, US", code: "SNM_1" },
	{ value: "St. Louis IL, US", label: "St. Louis IL, US", code: "STL-1" },
	{ value: "Stamford CT, US", label: "Stamford CT, US", code: "STM-1" },
	{ value: "Taos NM, US", label: "Taos NM, US", code: "TAO-1" },
	{ value: "Tuscaloosa AL, US", label: "Tuscaloosa AL, US", code: "TCL-1" },
	{ value: "Telluride CO, US", label: "Telluride CO, US", code: "TEL-1" },
	{ value: "Texas City TX, US", label: "Texas City TX, US", code: "TXS-3" },
	{ value: "Texarcana AR, US", label: "Texarcana AR, US", code: "TXk-3" },
	{ value: "Tyler TX, US", label: "Tyler TX, US", code: "TY1-3" },
	{ value: "Utica NY, US", label: "Utica NY, US", code: "UCA-3" },
	{ value: "Victoria TX, US", label: "Victoria TX, US", code: "VCT-3" },
	{ value: "Valdez AK, US", label: "Valdez AK, US", code: "VDZ-1" },
	{ value: "Vicksburg MS, US", label: "Vicksburg MS, US", code: "VIK-1" },
	{ value: "Visalia CA, US", label: "Visalia CA, US", code: "VIS-5" },
	{ value: "Dulles VA, US", label: "Dulles VA, US", code: "WAS-6" },
	{
		value: "WInston-Salem NC, US",
		label: "WInston-Salem NC, US",
		code: "WIS-1",
	},
	{ value: "West Orange NJ, US", label: "West Orange NJ, US", code: "WOG-1" },
	{ value: "Youngstown OH, US", label: "Youngstown OH, US", code: "YNG-1" },
	{ value: "Zanesville OH, US", label: "Zanesville OH, US", code: "ZZV-1" },
	{ value: "Breckenridge CO, US", label: "Breckenridge CO, US", code: "brk-1" },
	{ value: "Salem OR, US", label: "Salem OR, US", code: "sle-1" },
	{ value: "Round Rock TX, US", label: "Round Rock TX, US", code: "AUS_11" },
	{ value: "Bakersfield CA, US", label: "Bakersfield CA, US", code: "BKF-10" },
	{ value: "Monterey CA, US", label: "Monterey CA, US", code: "CAL-33" },
	{
		value: "Charlottesville VA, US",
		label: "Charlottesville VA, US",
		code: "CHV-65",
	},
	{ value: "Moab UT, US", label: "Moab UT, US", code: "CNY-18" },
	{ value: "Eau Claire WI, US", label: "Eau Claire WI, US", code: "EAC-54" },
	{ value: "Hilo HI, US", label: "Hilo HI, US", code: "HLO-56" },
	{ value: "Holbrook AZ, US", label: "Holbrook AZ, US", code: "HOL-23" },
	{
		value: "Universal Parks & Resorts FL, US",
		label: "Universal Parks & Resorts FL, US",
		code: "MCO-11",
	},
	{
		value: "Walt Disney World Resort FL, US",
		label: "Walt Disney World Resort FL, US",
		code: "MCO-12",
	},
	{ value: "Pismo Beach CA, US", label: "Pismo Beach CA, US", code: "MRY-11" },
	{ value: "Cambria CA, US", label: "Cambria CA, US", code: "MRY-15" },
	{ value: "Billings MT, US", label: "Billings MT, US", code: "MT_BIL" },
	{ value: "Bozeman MT, US", label: "Bozeman MT, US", code: "MT_BZN" },
	{ value: "Everett WA, US", label: "Everett WA, US", code: "SEA-14" },
	{ value: "San Angelo TX, US", label: "San Angelo TX, US", code: "SsJT-3" },
	{
		value: "Wilkes-Barre PA, US",
		label: "Wilkes-Barre PA, US",
		code: "WBR-98",
	},
	{ value: "Westport NY, US", label: "Westport NY, US", code: "WPT-11" },
	{ value: "Twin falls ID, US", label: "Twin falls ID, US", code: "ID__TWF" },
	{ value: "Laramie WY, US", label: "Laramie WY, US", code: "WY__IE1" },
	{ value: "Riverton WY, US", label: "Riverton WY, US", code: "WY__RIV" },
	{ value: "Athens OH, US", label: "Athens OH, US", code: "fake-229" },
	{ value: "Logan OH, US", label: "Logan OH, US", code: "fake-230" },
	{
		value: "Springfield OH, US",
		label: "Springfield OH, US",
		code: "fake-231",
	},
	{
		value: "Devils Lake ND, US",
		label: "Devils Lake ND, US",
		code: "fake-232",
	},
	{
		value: "Jacksonville NC, US",
		label: "Jacksonville NC, US",
		code: "fake-235",
	},
	{
		value: "Ocean Isle Beach NC, US",
		label: "Ocean Isle Beach NC, US",
		code: "fake-237",
	},
	{ value: "Columbus MS, US", label: "Columbus MS, US", code: "fake-239" },
	{ value: "Oxford MS, US", label: "Oxford MS, US", code: "fake-240" },
	{ value: "Pearl MS, US", label: "Pearl MS, US", code: "fake-241" },
	{ value: "Polson MT, US", label: "Polson MT, US", code: "fake-242" },
	{ value: "York NE, US", label: "York NE, US", code: "fake-244" },
	{
		value: "Copper Mountain CO, US",
		label: "Copper Mountain CO, US",
		code: "fake-314",
	},
	{ value: "Needles CA, US", label: "Needles CA, US", code: "fake-573" },
	{ value: "Cortez CO, US", label: "Cortez CO, US", code: "fake-574" },
	{ value: "Mesa Verde CO, US", label: "Mesa Verde CO, US", code: "fake-576" },
	{ value: "Springdale UT, US", label: "Springdale UT, US", code: "fake-594" },
	{ value: "Montclair NJ, US", label: "Montclair NJ, US", code: "fake-788" },
	{
		value: "LEGOLAND® New York NY, US",
		label: "LEGOLAND® New York NY, US",
		code: "098",
	},
	{
		value: "LEGOLAND® California CA, US",
		label: "LEGOLAND® California CA, US",
		code: "198",
	},
	{ value: "Santa Cruz CA, US", label: "Santa Cruz CA, US", code: "897" },
	{ value: "Santa Cruz CA, US", label: "Santa Cruz CA, US", code: "963" },
	{ value: "Alamogordo NM, US", label: "Alamogordo NM, US", code: "ALA" },
	{ value: "Waterloo IA, US", label: "Waterloo IA, US", code: "ALO" },
	{ value: "Augusta GA, US", label: "Augusta GA, US", code: "AUG" },
	{ value: "Alexandria LA, US", label: "Alexandria LA, US", code: "AXN" },
	{ value: "Bar Harbor ME, US", label: "Bar Harbor ME, US", code: "BAB" },
	{ value: "Bangor ME, US", label: "Bangor ME, US", code: "BAO" },
	{ value: "Bethany Beach DE, US", label: "Bethany Beach DE, US", code: "BEB" },
	{
		value: "Boothbay Harbor ME, US",
		label: "Boothbay Harbor ME, US",
		code: "BH2",
	},
	{ value: "Brian Head UT, US", label: "Brian Head UT, US", code: "BH3" },
	{ value: "Big Sky MT, US", label: "Big Sky MT, US", code: "BI3" },
	{ value: "Bloomington IN, US", label: "Bloomington IN, US", code: "BMG" },
	{ value: "Bonners Ferry ID, US", label: "Bonners Ferry ID, US", code: "BOF" },
	{
		value: "Council Bluffs IA, US",
		label: "Council Bluffs IA, US",
		code: "CBF",
	},
	{ value: "Chesterfield MO, US", label: "Chesterfield MO, US", code: "CH2" },
	{ value: "Carlsbad NM, US", label: "Carlsbad NM, US", code: "CLD" },
	{ value: "Cartersville GA, US", label: "Cartersville GA, US", code: "CTV" },
	{ value: "Dubuque IA, US", label: "Dubuque IA, US", code: "DBQ" },
	{ value: "Cambridge MD, US", label: "Cambridge MD, US", code: "DO3" },
	{ value: "El Dorado AR, US", label: "El Dorado AR, US", code: "ED3" },
	{ value: "Elkhart IN, US", label: "Elkhart IN, US", code: "EKI" },
	{ value: "Elko NV, US", label: "Elko NV, US", code: "EKO" },
	{
		value: "Eureka Springs AR, US",
		label: "Eureka Springs AR, US",
		code: "EKS",
	},
	{ value: "White Plains NY, US", label: "White Plains NY, US", code: "EL2" },
	{ value: "Ely NV, US", label: "Ely NV, US", code: "ELY" },
	{ value: "Forrest City AR, US", label: "Forrest City AR, US", code: "FCY" },
	{ value: "Frederick MD, US", label: "Frederick MD, US", code: "FD1" },
	{ value: "Farmington CT, US", label: "Farmington CT, US", code: "FMN" },
	{ value: "Cumberland MD, US", label: "Cumberland MD, US", code: "FR6" },
	{ value: "Henderson NV, US", label: "Henderson NV, US", code: "HD1" },
	{ value: "Hagerstown MD, US", label: "Hagerstown MD, US", code: "HGR" },
	{ value: "Hammond LA, US", label: "Hammond LA, US", code: "HM2" },
	{ value: "Hobbs NM, US", label: "Hobbs NM, US", code: "HOB" },
	{ value: "Hopkinsville, US", label: "Hopkinsville, US", code: "HOP" },
	{ value: "Hot Springs, US", label: "Hot Springs, US", code: "HOT" },
	{ value: "Terre Haute IN, US", label: "Terre Haute IN, US", code: "HUF" },
	{ value: "Houma LA, US", label: "Houma LA, US", code: "HUM" },
	{ value: "Idaho falls, US", label: "Idaho falls, US", code: "IDA" },
	{ value: "Teton Village WY, US", label: "Teton Village WY, US", code: "JAN" },
	{ value: "Jonesboro, US", label: "Jonesboro, US", code: "JBR" },
	{ value: "Juneau (Alaska), US", label: "Juneau (Alaska), US", code: "JNU" },
	{ value: "Killeen, US", label: "Killeen, US", code: "KLL" },
	{ value: "Lafayette IN, US", label: "Lafayette IN, US", code: "LA5" },
	{ value: "Lake Charles LA, US", label: "Lake Charles LA, US", code: "LCH" },
	{ value: "Leominster MA, US", label: "Leominster MA, US", code: "LMR" },
	{ value: "Livingston MT, US", label: "Livingston MT, US", code: "LVG" },
	{ value: "Marshalltown IA, US", label: "Marshalltown IA, US", code: "MA4" },
	{ value: "Meriden CT, US", label: "Meriden CT, US", code: "ME3" },
	{ value: "Mesquite NV, US", label: "Mesquite NV, US", code: "MEQ" },
	{ value: "Thomasville GA, US", label: "Thomasville GA, US", code: "MGR" },
	{ value: "Mount Laurel NJ, US", label: "Mount Laurel NJ, US", code: "ML1" },
	{ value: "Monroe LA, US", label: "Monroe LA, US", code: "MLU" },
	{ value: "Gary IN, US", label: "Gary IN, US", code: "MRV" },
	{ value: "Merrillville IN, US", label: "Merrillville IN, US", code: "MVL" },
	{ value: "Newport, US", label: "Newport, US", code: "NE2" },
	{ value: "Ogunquit ME, US", label: "Ogunquit ME, US", code: "OG1" },
	{ value: "Worcester MA, US", label: "Worcester MA, US", code: "ORH" },
	{ value: "Lake Ozark MO, US", label: "Lake Ozark MO, US", code: "OSB" },
	{ value: "Ottumwa IA, US", label: "Ottumwa IA, US", code: "OTM" },
	{ value: "Overland Park, US", label: "Overland Park, US", code: "OVE" },
	{ value: "Deer Valley, US", label: "Deer Valley, US", code: "PAK" },
	{ value: "Princeton NJ, US", label: "Princeton NJ, US", code: "PCT" },
	{ value: "Page, US", label: "Page, US", code: "PGA" },
	{ value: "Pahrump NV, US", label: "Pahrump NV, US", code: "PHM" },
	{
		value: "Presque Isle (Maine), US",
		label: "Presque Isle (Maine), US",
		code: "PQI",
	},
	{ value: "Portland ME, US", label: "Portland ME, US", code: "PWN" },
	{ value: "Winter park CO, US", label: "Winter park CO, US", code: "QWP" },
	{ value: "Russellville, US", label: "Russellville, US", code: "REV" },
	{
		value: "Little Rocks (Arkansas), US",
		label: "Little Rocks (Arkansas), US",
		code: "ROK",
	},
	{ value: "Ruidoso NM, US", label: "Ruidoso NM, US", code: "RUD" },
	{ value: "South Bend IN, US", label: "South Bend IN, US", code: "SBE" },
	{ value: "Sedona AZ, US", label: "Sedona AZ, US", code: "SDX" },
	{ value: "Sioux City IA, US", label: "Sioux City IA, US", code: "SIO" },
	{ value: "Soldotna, US", label: "Soldotna, US", code: "SL3" },
	{ value: "Springfield MA, US", label: "Springfield MA, US", code: "SPI" },
	{ value: "St. Robert MO, US", label: "St. Robert MO, US", code: "SR1" },
	{ value: "Searcy, US", label: "Searcy, US", code: "SRC" },
	{ value: "Salisbury MD, US", label: "Salisbury MD, US", code: "SRW" },
	{ value: "St. Michaels MD, US", label: "St. Michaels MD, US", code: "ST6" },
	{ value: "St. Pete Beach, US", label: "St. Pete Beach, US", code: "STP" },
	{ value: "Sturbridge MA, US", label: "Sturbridge MA, US", code: "STU" },
	{ value: "Kenai (Alaska), US", label: "Kenai (Alaska), US", code: "SWD" },
	{ value: "Syracuse NY, US", label: "Syracuse NY, US", code: "SYR" },
	{ value: "Tucumcari NM, US", label: "Tucumcari NM, US", code: "TCC" },
	{ value: "St. Augustine, US", label: "St. Augustine, US", code: "UST" },
	{ value: "Waldorf MD, US", label: "Waldorf MD, US", code: "WA2" },
	{ value: "Yarmouth MA, US", label: "Yarmouth MA, US", code: "WY2" },
	{ value: "Bentonville, US", label: "Bentonville, US", code: "XNA" },
	{ value: "Yuma AZ, US", label: "Yuma AZ, US", code: "YUM" },
	{ value: "Independence MO, US", label: "Independence MO, US", code: "idp" },
	{ value: "LEGOLAND® FL, US", label: "LEGOLAND® FL, US", code: "tyu" },
	{ value: "New Port Richey, US", label: "New Port Richey, US", code: "CLE2" },
	{ value: "Corvallis, US", label: "Corvallis, US", code: "CVO2" },
	{ value: "Bandon, US", label: "Bandon, US", code: "OTH2" },
	{
		value: "St George,Zion National Park, US",
		label: "St George,Zion National Park, US",
		code: "SGU1",
	},
	{
		value: "Coeur D&apos;Alene ID, US",
		label: "Coeur D&apos;Alene ID, US",
		code: "USD2",
	},
	{ value: "Rogers, US", label: "Rogers, US", code: "XNA5" },
	{ value: "Ellensburg , US", label: "Ellensburg , US", code: "ykm4" },
	{ value: "Abilene,Texas, US", label: "Abilene,Texas, US", code: "ABI-1" },
	{ value: "Rio Rancho NM, US", label: "Rio Rancho NM, US", code: "ABQ-9" },
	{ value: "Albany NY, US", label: "Albany NY, US", code: "ALB-1" },
	{ value: "Muncie IN, US", label: "Muncie IN, US", code: "AN6-2" },
	{ value: "Ann Arbor, US", label: "Ann Arbor, US", code: "ANN-1" },
	{ value: "Napa, US", label: "Napa, US", code: "APC-1" },
	{ value: "Auburn, US", label: "Auburn, US", code: "AUO-1" },
	{ value: "Aurora, US", label: "Aurora, US", code: "AUZ-1" },
	{
		value: "Benton Harbor MI, US",
		label: "Benton Harbor MI, US",
		code: "AZ1-2",
	},
	{ value: "Baton Rouge LA, US", label: "Baton Rouge LA, US", code: "BAT-3" },
	{ value: "Mandan ND, US", label: "Mandan ND, US", code: "BIS-2" },
	{ value: "Bemidji, US", label: "Bemidji, US", code: "BJI-1" },
	{ value: "Bloomington, US", label: "Bloomington, US", code: "BMI-1" },
	{ value: "Hyannis MA, US", label: "Hyannis MA, US", code: "CAC-1" },
	{ value: "Colby, US", label: "Colby, US", code: "CBK-1" },
	{ value: "Clarksdale, US", label: "Clarksdale, US", code: "CDK-1" },
	{ value: "Cincinnati, US", label: "Cincinnati, US", code: "CIN-1" },
	{ value: "Champaign IL, US", label: "Champaign IL, US", code: "CMI-1" },
	{ value: "Lewiston NY, US", label: "Lewiston NY, US", code: "CNF-5" },
	{ value: "Corbin, US", label: "Corbin, US", code: "CO7-1" },
	{ value: "Covington, US", label: "Covington, US", code: "CO8-1" },
	{ value: "Titusville FL, US", label: "Titusville FL, US", code: "COI-5" },
	{ value: "Mystic CT, US", label: "Mystic CT, US", code: "CON-1" },
	{ value: "Cave City, US", label: "Cave City, US", code: "CVC-1" },
	{ value: "Crystal River, US", label: "Crystal River, US", code: "CYR-2" },
	{ value: "Dayton OH, US", label: "Dayton OH, US", code: "DAY-1" },
	{ value: "Decatur, US", label: "Decatur, US", code: "DCR-1" },
	{ value: "Dodge City, US", label: "Dodge City, US", code: "DDC-1" },
	{ value: "Decatur, US", label: "Decatur, US", code: "DEC-1" },
	{ value: "Dickinson, US", label: "Dickinson, US", code: "DIK-1" },
	{ value: "Seaford DE, US", label: "Seaford DE, US", code: "DO1-8" },
	{ value: "Burlington IA, US", label: "Burlington IA, US", code: "DVN-2" },
	{ value: "Vero Beach, US", label: "Vero Beach, US", code: "ECF-1" },
	{ value: "Elmira NY, US", label: "Elmira NY, US", code: "ELM-1" },
	{ value: "Emporia, US", label: "Emporia, US", code: "EMP-1" },
	{
		value: "Evansville,Indiana, US",
		label: "Evansville,Indiana, US",
		code: "EVA-1",
	},
	{ value: "Florence, US", label: "Florence, US", code: "FL1-1" },
	{ value: "Florence, US", label: "Florence, US", code: "FLC-1" },
	{ value: "Pompano Beach, US", label: "Pompano Beach, US", code: "FLL-4" },
	{ value: "Farmington NM, US", label: "Farmington NM, US", code: "FMN-1" },
	{ value: "Ssnibel Island, US", label: "Ssnibel Island, US", code: "FOM-2" },
	{ value: "Captiva, US", label: "Captiva, US", code: "FOM-3" },
	{ value: "Galena, US", label: "Galena, US", code: "FRP-4" },
	{ value: "Flagstaff, US", label: "Flagstaff, US", code: "GCN-2" },
	{ value: "Grand Forks, US", label: "Grand Forks, US", code: "GFK-1" },
	{
		value: "Donaldsonville LA, US",
		label: "Donaldsonville LA, US",
		code: "GN1-2",
	},
	{ value: "Gunnison, US", label: "Gunnison, US", code: "GNN-9" },
	{ value: "Helen GA, US", label: "Helen GA, US", code: "GNV-2" },
	{ value: "Norwich CT, US", label: "Norwich CT, US", code: "GON-2" },
	{ value: "Green Bay, US", label: "Green Bay, US", code: "GRB-1" },
	{ value: "Hartford, US", label: "Hartford, US", code: "HAD-1" },
	{ value: "Holland MI, US", label: "Holland MI, US", code: "HLM-1" },
	{ value: "Huntsville, US", label: "Huntsville, US", code: "HSV-1" },
	{ value: "Hutchinson, US", label: "Hutchinson, US", code: "HUT-1" },
	{ value: "Milford CT, US", label: "Milford CT, US", code: "HVN-3" },
	{ value: "Hayden CO, US", label: "Hayden CO, US", code: "HYD-2" },
	{ value: "Hays, US", label: "Hays, US", code: "HYS-1" },
	{ value: "Rexburg ID, US", label: "Rexburg ID, US", code: "IDA-4" },
	{ value: "Ithaca NY, US", label: "Ithaca NY, US", code: "ITH-1" },
	{ value: "French Lick IN, US", label: "French Lick IN, US", code: "JA1-2" },
	{ value: "Amelia Island, US", label: "Amelia Island, US", code: "JAX-5" },
	{ value: "Schaumburg, US", label: "Schaumburg, US", code: "JMH-1" },
	{ value: "Joplin MO, US", label: "Joplin MO, US", code: "JOP-1" },
	{ value: "Joliet, US", label: "Joliet, US", code: "JOT-1" },
	{ value: "Kanab (Utah), US", label: "Kanab (Utah), US", code: "KAN-1" },
	{ value: "Knoxville TN, US", label: "Knoxville TN, US", code: "KVL-1" },
	{ value: "Lubbock, US", label: "Lubbock, US", code: "LBB-1" },
	{ value: "Liberal, US", label: "Liberal, US", code: "LBL-1" },
	{ value: "Berea, US", label: "Berea, US", code: "LEX-5" },
	{ value: "Lake George NY, US", label: "Lake George NY, US", code: "LG3-1" },
	{ value: "Lake Placid NY, US", label: "Lake Placid NY, US", code: "LKP-1" },
	{
		value: "Lincoln City,Oregon, US",
		label: "Lincoln City,Oregon, US",
		code: "LLC-1",
	},
	{ value: "Louisville, US", label: "Louisville, US", code: "LOU-1" },
	{ value: "Primm NV, US", label: "Primm NV, US", code: "LVS-6" },
	{ value: "Midland,Texas, US", label: "Midland,Texas, US", code: "MAF-1" },
	{ value: "Saginaw MI, US", label: "Saginaw MI, US", code: "MBS-1" },
	{ value: "Kissimmee, US", label: "Kissimmee, US", code: "MCO-9" },
	{ value: "Winter Haven, US", label: "Winter Haven, US", code: "MCO34" },
	{ value: "Carbondale, US", label: "Carbondale, US", code: "MDH-1" },
	{ value: "Junction City, US", label: "Junction City, US", code: "MHK-2" },
	{ value: "Miami Beach FL, US", label: "Miami Beach FL, US", code: "MIA-9" },
	{ value: "Ruston LA, US", label: "Ruston LA, US", code: "MLU-2" },
	{ value: "Minot, US", label: "Minot, US", code: "MN1-1" },
	{ value: "Morro Bay CA, US", label: "Morro Bay CA, US", code: "MRB-1" },
	{
		value: "Nebraska City NE, US",
		label: "Nebraska City NE, US",
		code: "NBC-1",
	},
	{
		value: "North Bend (Washintong), US",
		label: "North Bend (Washintong), US",
		code: "NBD-6",
	},
	{ value: "Brunswick, US", label: "Brunswick, US", code: "NEA-1" },
	{ value: "Newport RI, US", label: "Newport RI, US", code: "NPT-1" },
	{ value: "Fort Lee NJ, US", label: "Fort Lee NJ, US", code: "NYC-2" },
	{ value: "Odessa,Texas, US", label: "Odessa,Texas, US", code: "OD1-1" },
	{ value: "Collinsville, US", label: "Collinsville, US", code: "OFL-1" },
	{ value: "Bellevue NE, US", label: "Bellevue NE, US", code: "OMA-5" },
	{ value: "Evanston, US", label: "Evanston, US", code: "ORD-7" },
	{ value: "Naperville, US", label: "Naperville, US", code: "ORD20" },
	{ value: "Osage Beach MO, US", label: "Osage Beach MO, US", code: "OSB-1" },
	{ value: "Oshkosh, US", label: "Oshkosh, US", code: "OSH-1" },
	{ value: "Norman, US", label: "Norman, US", code: "OUN-1" },
	{ value: "Olathe, US", label: "Olathe, US", code: "OVE-2" },
	{ value: "Owensboro, US", label: "Owensboro, US", code: "OWB-1" },
	{ value: "Peoria, US", label: "Peoria, US", code: "PEO-1" },
	{ value: "Glendale AZ, US", label: "Glendale AZ, US", code: "PHX-6" },
	{ value: "Chandler AZ, US", label: "Chandler AZ, US", code: "PHX-9" },
	{ value: "Poughkeepsie NY, US", label: "Poughkeepsie NY, US", code: "POU-1" },
	{ value: "POIPU HI, US", label: "POIPU HI, US", code: "PPU-2" },
	{ value: "Bethel PA, US", label: "Bethel PA, US", code: "PV2-3" },
	{ value: "Red Wing MN, US", label: "Red Wing MN, US", code: "REW-1" },
	{
		value: "Rockford,Illinois, US",
		label: "Rockford,Illinois, US",
		code: "RFO-1",
	},
	{ value: "Carson City NV, US", label: "Carson City NV, US", code: "RNO-3" },
	{
		value: "Rochester,Nueva York, US",
		label: "Rochester,Nueva York, US",
		code: "ROC-1",
	},
	{ value: "Beaver creek MN, US", label: "Beaver creek MN, US", code: "ROI-2" },
	{ value: "Rochester, US", label: "Rochester, US", code: "RST-1" },
	{ value: "Longboat Key, US", label: "Longboat Key, US", code: "SAS-2" },
	{ value: "Bradenton, US", label: "Bradenton, US", code: "SAS-3" },
	{
		value: "Parque Nacional Zion, US",
		label: "Parque Nacional Zion, US",
		code: "SGU-1",
	},
	{ value: "Sikeston MO, US", label: "Sikeston MO, US", code: "SIK-2" },
	{ value: "St Joseph MO, US", label: "St Joseph MO, US", code: "SJ1-1" },
	{ value: "Stockbridge, US", label: "Stockbridge, US", code: "SKB-1" },
	{
		value: "Saratoga Springs NY, US",
		label: "Saratoga Springs NY, US",
		code: "SS1-1",
	},
	{ value: "St. Charles MO, US", label: "St. Charles MO, US", code: "STL-7" },
	{ value: "Norwalk CT, US", label: "Norwalk CT, US", code: "STM-3" },
	{
		value: "St. Petersburg	FL, US",
		label: "St. Petersburg	FL, US",
		code: "STP-1",
	},
	{ value: "Madeira Beach, US", label: "Madeira Beach, US", code: "STP-5" },
	{
		value: "State College PA, US",
		label: "State College PA, US",
		code: "STT-1",
	},
	{ value: "Toledo,Ohio, US", label: "Toledo,Ohio, US", code: "TO2-1" },
	{ value: "Topeka KS, US", label: "Topeka KS, US", code: "TOK-3" },
	{ value: "Turtle Bay, US", label: "Turtle Bay, US", code: "TRB-2" },
	{
		value: "Traverse City MI, US",
		label: "Traverse City MI, US",
		code: "TVC-1",
	},
	{
		value: "Incline Village NV, US",
		label: "Incline Village NV, US",
		code: "TVL-5",
	},
	{ value: "Squaw Valley CA, US", label: "Squaw Valley CA, US", code: "TVL-6" },
	{ value: "Bethesda MD, US", label: "Bethesda MD, US", code: "WAS-7" },
	{ value: "Waikiki, US", label: "Waikiki, US", code: "WKI-2" },
	{ value: "Williston, US", label: "Williston, US", code: "WL1-1" },
	{ value: "Wailea, US", label: "Wailea, US", code: "WLE-2" },
	{ value: "WAIMEA HI, US", label: "WAIMEA HI, US", code: "WMA-2" },
	{ value: "Fayetteville, US", label: "Fayetteville, US", code: "XNA-1" },
	{ value: "Duck Key FL, US", label: "Duck Key FL, US", code: "YSP-1" },
	{ value: "Islamorada, US", label: "Islamorada, US", code: "YSP-2" },
	{ value: "Cayo Largo, US", label: "Cayo Largo, US", code: "YSP-3" },
	{ value: "Cayo Hueso, US", label: "Cayo Hueso, US", code: "YSP-4" },
	{ value: "Marathon FL, US", label: "Marathon FL, US", code: "YSP-5" },
	{ value: "Walla Walla , US", label: "Walla Walla , US", code: "alw-1" },
	{ value: "Sisters, US", label: "Sisters, US", code: "be7-3" },
	{ value: "Baker City, US", label: "Baker City, US", code: "bk3-1" },
	{ value: "Burns,Oregon, US", label: "Burns,Oregon, US", code: "bn2-1" },
	{ value: "Wenatchee, US", label: "Wenatchee, US", code: "eat-1" },
	{ value: "Eugene, US", label: "Eugene, US", code: "eug-1" },
	{ value: "La Grande OR, US", label: "La Grande OR, US", code: "lgd-1" },
	{ value: "Richland WA, US", label: "Richland WA, US", code: "rih-1" },
	{ value: "Joseph OR, US", label: "Joseph OR, US", code: "sju-1" },
	{ value: "Alpharetta GA, US", label: "Alpharetta GA, US", code: "ATL-34" },
	{ value: "Marietta GA, US", label: "Marietta GA, US", code: "ATL-35" },
	{ value: "Norcross GA, US", label: "Norcross GA, US", code: "ATL-37" },
	{ value: "Alexandria MN, US", label: "Alexandria MN, US", code: "AX3-12" },
	{ value: "Kalamazoo MI, US", label: "Kalamazoo MI, US", code: "AZ1-12" },
	{ value: "Bristol, ME-US, US", label: "Bristol, ME-US, US", code: "BSTL-6" },
	{ value: "Brighton CO, US", label: "Brighton CO, US", code: "DEN-11" },
	{ value: "Erie PA, US", label: "Erie PA, US", code: "ERI-86" },
	{ value: "Flint MI, US", label: "Flint MI, US", code: "FLI-76" },
	{ value: "Gillette WY, US", label: "Gillette WY, US", code: "GLL-76" },
	{ value: "Geneva, NY, US", label: "Geneva, NY, US", code: "GNVA-6" },
	{
		value: "Grand Junction CO, US",
		label: "Grand Junction CO, US",
		code: "GRJ-12",
	},
	{ value: "Harlingen TX, US", label: "Harlingen TX, US", code: "HAR-99" },
	{ value: "Hayden AL, US", label: "Hayden AL, US", code: "HAY-87" },
	{ value: "Kingman AZ, US", label: "Kingman AZ, US", code: "KIN123" },
	{
		value: "Marina del Rey CA, US",
		label: "Marina del Rey CA, US",
		code: "LAX-19",
	},
	{ value: "Lihue HI, US", label: "Lihue HI, US", code: "LIH-87" },
	{
		value: "Lake Buena Vista FL, US",
		label: "Lake Buena Vista FL, US",
		code: "MCO-10",
	},
	{ value: "Davenport FL, US", label: "Davenport FL, US", code: "MCO-17" },
	{ value: "Mankato MN, US", label: "Mankato MN, US", code: "MKT-12" },
	{ value: "Moline IL, US", label: "Moline IL, US", code: "MLN-56" },
	{ value: "Monticello MN, US", label: "Monticello MN, US", code: "MOR-21" },
	{ value: "Kenner LA, US", label: "Kenner LA, US", code: "MSY-13" },
	{ value: "Long Island NJ, US", label: "Long Island NJ, US", code: "NYC-26" },
	{ value: "Pueblo, CA, US", label: "Pueblo, CA, US", code: "PBLO-6" },
	{ value: "Scottsdale AZ, US", label: "Scottsdale AZ, US", code: "PHX-10" },
	{ value: "Scottsdale AZ, US", label: "Scottsdale AZ, US", code: "PHX-21" },
	{ value: "Petoskey MI, US", label: "Petoskey MI, US", code: "PT1-11" },
	{
		value: "Rock Springs WY, US",
		label: "Rock Springs WY, US",
		code: "RKS-98",
	},
	{ value: "Spartanburg SC, US", label: "Spartanburg SC, US", code: "SPB-34" },
	{
		value: "Southwest Harbor, ME, US",
		label: "Southwest Harbor, ME, US",
		code: "STHW-6",
	},
	{
		value: "Treasure Island CA, US",
		label: "Treasure Island CA, US",
		code: "TSR-12",
	},
	{
		value: "College Park MD, US",
		label: "College Park MD, US",
		code: "WAS-10",
	},
	{ value: "Kaanapali HI, US", label: "Kaanapali HI, US", code: "fak-51" },
	{
		value: "Kailua - Kona HI, US",
		label: "Kailua - Kona HI, US",
		code: "fak-52",
	},
	{ value: "Montrose, US", label: "Montrose, US", code: "mts-76" },
	{ value: "Brainerd MN, US", label: "Brainerd MN, US", code: "BRA-121" },
	{ value: "Buchanan MI, US", label: "Buchanan MI, US", code: "BUC-123" },
	{ value: "Elgin, IL, US", label: "Elgin, IL, US", code: "ELGIN-6" },
	{ value: "Salem MA, US", label: "Salem MA, US", code: "SALEM-6" },
	{
		value: "St. George, ME-US, US",
		label: "St. George, ME-US, US",
		code: "STGRG-6",
	},
	{ value: "Blue Hill, US", label: "Blue Hill, US", code: "BLHILL-6" },
	{
		value: "Mount Desert Island, ME, US",
		label: "Mount Desert Island, ME, US",
		code: "DESERT-6",
	},
	{
		value: "Lagos Finger, NY, US",
		label: "Lagos Finger, NY, US",
		code: "FLAKES-6",
	},
	{ value: "Hailey, ID, US", label: "Hailey, ID, US", code: "HAILEY-6" },
	{ value: "Hannibal MO, US", label: "Hannibal MO, US", code: "Hannibal" },
	{ value: "Valparaiso, US", label: "Valparaiso, US", code: "VALPAR-6" },
	{ value: "Middletown CT, US", label: "Middletown CT, US", code: "fake-165" },
	{ value: "Litchfield CT, US", label: "Litchfield CT, US", code: "fake-166" },
	{
		value: "Old Saybrook CT, US",
		label: "Old Saybrook CT, US",
		code: "fake-167",
	},
	{ value: "Bridgeport CT, US", label: "Bridgeport CT, US", code: "fake-168" },
	{ value: "Waterbury CT, US", label: "Waterbury CT, US", code: "fake-169" },
	{ value: "New London CT, US", label: "New London CT, US", code: "fake-170" },
	{ value: "Torrington CT, US", label: "Torrington CT, US", code: "fake-171" },
	{
		value: "Hallandale Beach FL, US",
		label: "Hallandale Beach FL, US",
		code: "fake-172",
	},
	{ value: "Griffin GA, US", label: "Griffin GA, US", code: "fake-173" },
	{ value: "Sanford FL, US", label: "Sanford FL, US", code: "fake-174" },
	{ value: "Ottawa IL, US", label: "Ottawa IL, US", code: "fake-180" },
	{ value: "Kankakee IL, US", label: "Kankakee IL, US", code: "fake-181" },
	{ value: "Naperville IL, US", label: "Naperville IL, US", code: "fake-182" },
	{ value: "Lenexa KS, US", label: "Lenexa KS, US", code: "fake-187" },
	{
		value: "Williamstown KY, US",
		label: "Williamstown KY, US",
		code: "fake-193",
	},
	{ value: "Erlanger KY, US", label: "Erlanger KY, US", code: "fake-218" },
	{ value: "West Fargo ND, US", label: "West Fargo ND, US", code: "fake-234" },
	{ value: "Stateline CA, US", label: "Stateline CA, US", code: "fake-246" },
	{
		value: "Silver City NM, US",
		label: "Silver City NM, US",
		code: "fake-247",
	},
	{
		value: "Windsor Locks CT, US",
		label: "Windsor Locks CT, US",
		code: "fake-248",
	},
	{
		value: "Blue Springs MO, US",
		label: "Blue Springs MO, US",
		code: "fake-249",
	},
	{
		value: "Saint Augustine FL, US",
		label: "Saint Augustine FL, US",
		code: "fake-255",
	},
	{ value: "Angel fire NM, US", label: "Angel fire NM, US", code: "fake-309" },
	{
		value: "Black Mountain NC, US",
		label: "Black Mountain NC, US",
		code: "fake-310",
	},
	{
		value: "Cannon mountain NH, US",
		label: "Cannon mountain NH, US",
		code: "fake-313",
	},
	{
		value: "Mammoth Mountain CA, US",
		label: "Mammoth Mountain CA, US",
		code: "fake-315",
	},
	{
		value: "Amargosa Valley NV, US",
		label: "Amargosa Valley NV, US",
		code: "fake-578",
	},
	{ value: "Palm Bay FL, US", label: "Palm Bay FL, US", code: "fake-689" },
	{ value: "Damariscotta, US", label: "Damariscotta, US", code: "DMRSCTT-6" },
	{ value: "Ellsworth, US", label: "Ellsworth, US", code: "LLSWRTH-6" },
	{
		value: "Parque nacional del Monte Rainier, US",
		label: "Parque nacional del Monte Rainier, US",
		code: "55S",
	},
	{ value: "Ames IA, US", label: "Ames IA, US", code: "AMW" },
	{ value: "Auburn CA, US", label: "Auburn CA, US", code: "AUB" },
	{
		value: "Buffalo (Wyoming), US",
		label: "Buffalo (Wyoming), US",
		code: "BFF",
	},
	{ value: "Chattanooga , US", label: "Chattanooga , US", code: "CHA" },
	{ value: "Emigrant Gap CA, US", label: "Emigrant Gap CA, US", code: "EMI" },
	{ value: "Hawthorne NV, US", label: "Hawthorne NV, US", code: "HAW" },
	{ value: "Lancaster CA, US", label: "Lancaster CA, US", code: "LAN" },
	{ value: "Los Banos CA, US", label: "Los Banos CA, US", code: "LBA" },
	{ value: "Maple Grove, US", label: "Maple Grove, US", code: "MAP" },
	{ value: "Placerville CA, US", label: "Placerville CA, US", code: "PLA" },
	{ value: "Pontiac, US", label: "Pontiac, US", code: "PON" },
	{
		value: "Santa María (California), US",
		label: "Santa María (California), US",
		code: "SMX",
	},
	{ value: "Tallahassee FL, US", label: "Tallahassee FL, US", code: "TLH" },
	{ value: "Key Biscayne FL, US", label: "Key Biscayne FL, US", code: "US5" },
	{ value: "WAILUA HI, US", label: "WAILUA HI, US", code: "WAI" },
	{
		value: "Camden (Nueva Jersey), US",
		label: "Camden (Nueva Jersey), US",
		code: "CMDN",
	},
	{ value: "Castine ME, US", label: "Castine ME, US", code: "CSTN" },
	{ value: "Cabo Coral, US", label: "Cabo Coral, US", code: "FMY2" },
	{
		value: "Bosque nacional Green Mountain, US",
		label: "Bosque nacional Green Mountain, US",
		code: "GMNF",
	},
	{ value: "Eatonville WA, US", label: "Eatonville WA, US", code: "GRF2" },
	{ value: "Jackson Hole WY, US", label: "Jackson Hole WY, US", code: "JACK" },
	{ value: "Canton MS, US", label: "Canton MS, US", code: "JAN2" },
	{
		value: "Península de Kenai, US",
		label: "Península de Kenai, US",
		code: "KNAI",
	},
	{ value: "Mc Carthy AK, US", label: "Mc Carthy AK, US", code: "MC23" },
	{
		value: "Sea World Orlando FL, US",
		label: "Sea World Orlando FL, US",
		code: "MCO3",
	},
	{ value: "Mineola, US", label: "Mineola, US", code: "MNNL" },
	{
		value: "Parque nacional Badlands, US",
		label: "Parque nacional Badlands, US",
		code: "RAP2",
	},
	{ value: "Ashford WA, US", label: "Ashford WA, US", code: "TCM1" },
	{ value: "Thomson GA, US", label: "Thomson GA, US", code: "THMS" },
	{ value: "Talkeetna AK, US", label: "Talkeetna AK, US", code: "TLKN" },
	{
		value: "Rocky Mountain National Park CO, US",
		label: "Rocky Mountain National Park CO, US",
		code: "WBU1",
	},
	{
		value: "Parque nacional Acadia, US",
		label: "Parque nacional Acadia, US",
		code: "ANPME",
	},
	{ value: "Bayonne NJ, US", label: "Bayonne NJ, US", code: "BAYNJ" },
	{
		value: "Bahía de Chesapeake, US",
		label: "Bahía de Chesapeake, US",
		code: "CPB-1",
	},
	{ value: "Dayton, TN, US", label: "Dayton, TN, US", code: "DAY-6" },
	{
		value: "Aeropuerto Internacional de Detroit MI, US",
		label: "Aeropuerto Internacional de Detroit MI, US",
		code: "DMA-1",
	},
	{ value: "Effingham IL, US", label: "Effingham IL, US", code: "EFF-1" },
	{ value: "Marquette MI, US", label: "Marquette MI, US", code: "ES2-2" },
	{ value: "Grand Teton, US", label: "Grand Teton, US", code: "GRT-1" },
	{ value: "Healy AK, US", label: "Healy AK, US", code: "Healy" },
	{
		value: "College Station TX, US",
		label: "College Station TX, US",
		code: "IAHcl",
	},
	{
		value: "Jefferson City MO, US",
		label: "Jefferson City MO, US",
		code: "JEF-3",
	},
	{ value: "Jackson NH, US", label: "Jackson NH, US", code: "JNH-1" },
	{ value: "Keauhou HI, US", label: "Keauhou HI, US", code: "KEA-1" },
	{
		value: "Lancaster, Pensilvania, US",
		label: "Lancaster, Pensilvania, US",
		code: "LAN-1",
	},
	{ value: "Lincolnville ME, US", label: "Lincolnville ME, US", code: "LCNVL" },
	{ value: "Loa UT, US", label: "Loa UT, US", code: "LOA-1" },
	{
		value: "Lewisburg (Virginia Occidental), US",
		label: "Lewisburg (Virginia Occidental), US",
		code: "LWV-1",
	},
	{
		value: "Grande Lakes Orlando FL, US",
		label: "Grande Lakes Orlando FL, US",
		code: "MCO-4",
	},
	{
		value: "Aeropuerto Internacional de Miami, US",
		label: "Aeropuerto Internacional de Miami, US",
		code: "MIA-2",
	},
	{ value: "Muskegon MI, US", label: "Muskegon MI, US", code: "MKG-1" },
	{ value: "Modesto CA, US", label: "Modesto CA, US", code: "MOD-1" },
	{ value: "Neosho MO, US", label: "Neosho MO, US", code: "NEO-1" },
	{ value: "Nogales AZ, US", label: "Nogales AZ, US", code: "OLS-2" },
	{ value: "Park City, US", label: "Park City, US", code: "PAR-6" },
	{ value: "Saint Paul MN, US", label: "Saint Paul MN, US", code: "PAU-3" },
	{ value: "Princeville, US", label: "Princeville, US", code: "PRC-2" },
	{ value: "Redmond, WA, US", label: "Redmond, WA, US", code: "RED-6" },
	{ value: "Richfield, US", label: "Richfield, US", code: "RIC-1" },
	{ value: "Rome, New York, US", label: "Rome, New York, US", code: "ROM-2" },
	{ value: "San Bernardino, US", label: "San Bernardino, US", code: "SAN-1" },
	{ value: "Searsport ME, US", label: "Searsport ME, US", code: "SEARS" },
	{ value: "Tulare CA, US", label: "Tulare CA, US", code: "TLA-1" },
	{
		value: "Tallahassee(Florida), US",
		label: "Tallahassee(Florida), US",
		code: "TLH-1",
	},
	{ value: "Tok AK, US", label: "Tok AK, US", code: "TOK-1" },
	{ value: "Vernal, US", label: "Vernal, US", code: "VEL-2" },
	{
		value: "Waikoloa, (Hawaii), US",
		label: "Waikoloa, (Hawaii), US",
		code: "WAI-1",
	},
	{ value: "Durango CO, US", label: "Durango CO, US", code: "456741" },
	{ value: "Allentown, US", label: "Allentown, US", code: "ALE-81" },
	{ value: "Batesville MS, US", label: "Batesville MS, US", code: "BATS-1" },
	{ value: "Bishop, US", label: "Bishop, US", code: "BISUSA" },
	{ value: "Branson MO, US", label: "Branson MO, US", code: "BRA-11" },
	{ value: "Burbank, US", label: "Burbank, US", code: "BUR-88" },
	{
		value: "Cannon Beach OR, US",
		label: "Cannon Beach OR, US",
		code: "CABE-1",
	},
	{ value: "Chatham, US", label: "Chatham, US", code: "CHAT-1" },
	{
		value: "Copper Center AK, US",
		label: "Copper Center AK, US",
		code: "COCE-1",
	},
	{ value: "Crossville TN, US", label: "Crossville TN, US", code: "CROS-1" },
	{ value: "Dublin OH, US", label: "Dublin OH, US", code: "DUOH-1" },
	{ value: "Eatonton GA, US", label: "Eatonton GA, US", code: "EATO-1" },
	{ value: "Gold Beach, OR, US", label: "Gold Beach, OR, US", code: "GOLD-6" },
	{ value: "Grantville, US", label: "Grantville, US", code: "GRNT-1" },
	{ value: "Goshen, US", label: "Goshen, US", code: "Goshen" },
	{
		value: "Huntington Beach, US",
		label: "Huntington Beach, US",
		code: "HUN-22",
	},
	{ value: "Kalaoa, US", label: "Kalaoa, US", code: "Kalaoa" },
	{ value: "Lockport, US", label: "Lockport, US", code: "LOCK-6" },
	{ value: "Lompoc, US", label: "Lompoc, US", code: "LOMP-6" },
	{ value: "Laredo, US", label: "Laredo, US", code: "Laredo" },
	{ value: "Marion OH, US", label: "Marion OH, US", code: "MARI-1" },
	{ value: "McAllen, US", label: "McAllen, US", code: "MATX-1" },
	{
		value: "Martha&apos;s Vineyard MA, US",
		label: "Martha&apos;s Vineyard MA, US",
		code: "MAVI-1",
	},
	{ value: "Milaca, US", label: "Milaca, US", code: "MILACA" },
	{ value: "Mauna Kea, US", label: "Mauna Kea, US", code: "MKEA-1" },
	{ value: "Nantucket MA, US", label: "Nantucket MA, US", code: "NANT-1" },
	{
		value: "Nuevo Brunswick NJ, US",
		label: "Nuevo Brunswick NJ, US",
		code: "NEBR-1",
	},
	{ value: "Newfane NY, US", label: "Newfane NY, US", code: "NEWF-1" },
	{ value: "Newark NJ, US", label: "Newark NJ, US", code: "NWAR-1" },
	{ value: "Okemos MI, US", label: "Okemos MI, US", code: "OKEM-1" },
	{
		value: "Condado de Orange CA, US",
		label: "Condado de Orange CA, US",
		code: "ORCO-1",
	},
	{ value: "Ossipee NH, US", label: "Ossipee NH, US", code: "OSSI-1" },
	{ value: "Pima, AZ, US", label: "Pima, AZ, US", code: "PIMA-6" },
	{
		value: "Provincetown MA, US",
		label: "Provincetown MA, US",
		code: "PRTO-1",
	},
	{
		value: "Rio Grande City TX, US",
		label: "Rio Grande City TX, US",
		code: "RGCT-1",
	},
	{ value: "Riverhead NY, US", label: "Riverhead NY, US", code: "RIVE-1" },
	{ value: "Redwood City, US", label: "Redwood City, US", code: "RWDC-1" },
	{
		value: "St. Francisville LA, US",
		label: "St. Francisville LA, US",
		code: "SAFR-1",
	},
	{ value: "Sevierville TN, US", label: "Sevierville TN, US", code: "SETN-1" },
	{ value: "Skagway AK, US", label: "Skagway AK, US", code: "SKAG-1" },
	{
		value: "Southampton (Nueva York), US",
		label: "Southampton (Nueva York), US",
		code: "SOUT-1",
	},
	{ value: "Springfield TN, US", label: "Springfield TN, US", code: "SPTN-1" },
	{ value: "Wausau, US", label: "Wausau, US", code: "WAU-82" },
	{ value: " Seward, US", label: " Seward, US", code: "fgeesd" },
	{ value: "Altoona, US", label: "Altoona, US", code: "ALT-002" },
	{ value: "Anderson SC, US", label: "Anderson SC, US", code: "ASN-002" },
	{ value: "Blythe CA, US", label: "Blythe CA, US", code: "BYH-002" },
	{ value: "Cody, US", label: "Cody, US", code: "CDY-002" },
	{ value: "Clearfield, US", label: "Clearfield, US", code: "CFI-002" },
	{ value: "Chautauqua, US", label: "Chautauqua, US", code: "CHA-002" },
	{ value: "Columbus OH, US", label: "Columbus OH, US", code: "CLS-USA" },
	{ value: "Dublin VA, US", label: "Dublin VA, US", code: "DBV-002" },
	{ value: "Filadelfia PA, US", label: "Filadelfia PA, US", code: "FLA-USA" },
	{ value: "Freeport, US", label: "Freeport, US", code: "FPT-002" },
	{ value: "Fremont, US", label: "Fremont, US", code: "FRMNT-6" },
	{ value: "Foxborough MA, US", label: "Foxborough MA, US", code: "FXH-USA" },
	{ value: "Harrison NJ, US", label: "Harrison NJ, US", code: "HRN-USA" },
	{ value: "Hickory, US", label: "Hickory, US", code: "Hickory" },
	{ value: "Inverness IL, US", label: "Inverness IL, US", code: "INV-002" },
	{ value: "Lincoln IL, US", label: "Lincoln IL, US", code: "LINIL-1" },
	{ value: "Lowell MA, US", label: "Lowell MA, US", code: "LWL-002" },
	{
		value: "Morristown (Nueva Jersey), US",
		label: "Morristown (Nueva Jersey), US",
		code: "MSN-002",
	},
	{ value: "Oglesby IL, US", label: "Oglesby IL, US", code: "OGL-123" },
	{ value: "Ooltewah, US", label: "Ooltewah, US", code: "OOH-002" },
	{
		value: "Port Angeles WA, US",
		label: "Port Angeles WA, US",
		code: "PGS-002",
	},
	{
		value: "Puerto de Miami, US",
		label: "Puerto de Miami, US",
		code: "POM-002",
	},
	{
		value: "Pleasantville (Ohio), US",
		label: "Pleasantville (Ohio), US",
		code: "PVE-002",
	},
	{ value: "Parowan UT, US", label: "Parowan UT, US", code: "PWN-002" },
	{ value: "Redwood City, US", label: "Redwood City, US", code: "REDCI-6" },
	{ value: "Isla Rota, US", label: "Isla Rota, US", code: "RTA-002" },
	{ value: "San José CA, US", label: "San José CA, US", code: "SJC-USA" },
	{ value: "San Simeón, US", label: "San Simeón, US", code: "SMN-002" },
	{
		value: "Troy (Míchigan), US",
		label: "Troy (Míchigan), US",
		code: "TRY-002",
	},
	{ value: "Wiscasset, US", label: "Wiscasset, US", code: "WCT-002" },
	{ value: "Williamsport, US", label: "Williamsport, US", code: "WMS-002" },
	{
		value: "Weyers Cave (Virginia), US",
		label: "Weyers Cave (Virginia), US",
		code: "WYC-002",
	},
	{ value: "Wasilla , US", label: "Wasilla , US", code: "Was-123" },
	{
		value: "Yucca Valley, CA, US",
		label: "Yucca Valley, CA, US",
		code: "YUCCA-6",
	},
	{
		value: "Bridgeview, IL, US",
		label: "Bridgeview, IL, US",
		code: "BDGW-USA",
	},
	{ value: "Fremont, CA, US", label: "Fremont, CA, US", code: "FRMNT-66" },
	{ value: "Gallatin, US", label: "Gallatin, US", code: "GALLATIN" },
	{ value: "Merced, US", label: "Merced, US", code: "MERCED-6" },
	{ value: "Martinez, US", label: "Martinez, US", code: "Martinez" },
	{ value: "Monroe , MI, US", label: "Monroe , MI, US", code: "MonroeMI" },
	{
		value: "Parker City, IN, US",
		label: "Parker City, IN, US",
		code: "PARKER-6",
	},
	{ value: "Plymouth , US", label: "Plymouth , US", code: "Plymouth" },
	{
		value: "San Mateo (California), US",
		label: "San Mateo (California), US",
		code: "SanMateo",
	},
	{
		value: "St. George (Utah), US",
		label: "St. George (Utah), US",
		code: "StGeorge",
	},
	{
		value: "Glennallen (Alaska), US",
		label: "Glennallen (Alaska), US",
		code: "fghnvcds",
	},
	{ value: "San Andreas, US", label: "San Andreas, US", code: "ANDREAS-6" },
	{ value: "Auburn Hills, US", label: "Auburn Hills, US", code: "AUBRNHLLS" },
	{ value: "Clarkdale, US", label: "Clarkdale, US", code: "Clarkdale" },
	{
		value: "Fort Myers Beach, US",
		label: "Fort Myers Beach, US",
		code: "fortMyers",
	},
	{ value: "Sugar Land, US", label: "Sugar Land, US", code: "sugarland" },
	{ value: "Foster City, US", label: "Foster City, US", code: "FosterCity" },
	{
		value: "Gettysburg , PA , US",
		label: "Gettysburg , PA , US",
		code: "Gettysburg",
	},
	{ value: "Green River, US", label: "Green River, US", code: "GreenRiver" },
	{
		value: "Lake Havasu, AZ, US",
		label: "Lake Havasu, AZ, US",
		code: "LakeHavasu",
	},
	{ value: "Paso Robles, US", label: "Paso Robles, US", code: "PasoRobles" },
	{
		value: "Sheridan , WY, US",
		label: "Sheridan , WY, US",
		code: "SheridanWY",
	},
	{
		value: "Camden (Maine), US",
		label: "Camden (Maine), US",
		code: "CamdenMaine",
	},
	{ value: "Rockville MD, US", label: "Rockville MD, US", code: "ROCKVILLE-6" },
	{
		value: "Belfast (Maine), US",
		label: "Belfast (Maine), US",
		code: "BelfastMaine",
	},
	{ value: "Charlemont , US", label: "Charlemont , US", code: "CharlemontMA" },
	{
		value: "Lago del Cráter OR, US",
		label: "Lago del Cráter OR, US",
		code: "CraterLakeOR",
	},
	{
		value: "Fort Pierce, MI, US",
		label: "Fort Pierce, MI, US",
		code: "FORTPIERCE-6",
	},
	{ value: "Greenfield, US", label: "Greenfield, US", code: "GreenfieldMA" },
	{
		value: "Moncks Corner, US",
		label: "Moncks Corner, US",
		code: "MoncksCorner",
	},
	{
		value: "Monte Washington, US",
		label: "Monte Washington, US",
		code: "MtWashington",
	},
	{
		value: "New Braunfels, US",
		label: "New Braunfels, US",
		code: "NewBraunfels",
	},
	{
		value: "The Woodlands, US",
		label: "The Woodlands, US",
		code: "TheWoodlands",
	},
	{
		value: "Kennebunkport, US",
		label: "Kennebunkport, US",
		code: "Kennebunkport",
	},
	{
		value: "Rockland, ME, US",
		label: "Rockland, ME, US",
		code: "RocklandMaine",
	},
	{
		value: "Parque Nacional Denali , US",
		label: "Parque Nacional Denali , US",
		code: "DenaliNationalPark",
	},
	{
		value: "Colonia del Sacramento, UY",
		label: "Colonia del Sacramento, UY",
		code: "CDT",
	},
	{ value: "Montevideo, UY", label: "Montevideo, UY", code: "MVD" },
	{ value: "Punta del Este, UY", label: "Punta del Este, UY", code: "PDP" },
	{ value: "La Paloma, UY", label: "La Paloma, UY", code: "PDP3" },
	{ value: "Cabo Polonio, UY", label: "Cabo Polonio, UY", code: "CAPO-6" },
	{ value: "Dolores, UY", label: "Dolores, UY", code: "DOLO-6" },
	{ value: "Fray Bentos, UY", label: "Fray Bentos, UY", code: "FRBE-1" },
	{ value: "Paysandú, UY", label: "Paysandú, UY", code: "PAYS-1" },
	{ value: "Salto, UY", label: "Salto, UY", code: "SALT-1" },
	{
		value: "Termas del Arapey, UY",
		label: "Termas del Arapey, UY",
		code: "TEDA-1",
	},
	{ value: "Manantiales, UY", label: "Manantiales, UY", code: "MANAN-6" },
	{
		value: "Punta del Diablo, UY",
		label: "Punta del Diablo, UY",
		code: "DIABLO-6",
	},
	{ value: "José Ignacio, UY", label: "José Ignacio, UY", code: "NACHO--6" },
	{ value: "Zagarzazu, UY", label: "Zagarzazu, UY", code: "ZAGARZ-6" },
	{ value: "Carmelo, UY", label: "Carmelo, UY", code: "CARMELO-6" },
	{ value: "Nueva Palmira, UY", label: "Nueva Palmira, UY", code: "PALMIRA-6" },
	{
		value: "San Gregorio de Polanco, UY",
		label: "San Gregorio de Polanco, UY",
		code: "SANGREGO-6",
	},
	{ value: "Maldonado, UY", label: "Maldonado, UY", code: "MALDONADO-6" },
	{ value: "Piriápolis, UY", label: "Piriápolis, UY", code: "PIRIPOLIS-6" },
	{ value: "Bujara, UZ", label: "Bujara, UZ", code: "BKH" },
	{ value: "Jiva, UZ", label: "Jiva, UZ", code: "HIV" },
	{ value: "Samarcanda, UZ", label: "Samarcanda, UZ", code: "SMK" },
	{ value: "Tashkent, UZ", label: "Tashkent, UZ", code: "TAS" },
	{ value: "Urgench, UZ", label: "Urgench, UZ", code: "URG" },
	{ value: "Andijan, UZ", label: "Andijan, UZ", code: "AZN" },
	{ value: "Fergana, UZ", label: "Fergana, UZ", code: "FEG" },
	{ value: "Navoi, UZ", label: "Navoi, UZ", code: "NVI" },
	{ value: "Oqdarya, UZ", label: "Oqdarya, UZ", code: "OQA-002" },
	{ value: "Port Vila, VU", label: "Port Vila, VU", code: "VU2" },
	{ value: "Caracas, VE", label: "Caracas, VE", code: "CCS" },
	{ value: "Mérida, VE", label: "Mérida, VE", code: "RIM" },
	{ value: "Isla Margarita, VE", label: "Isla Margarita, VE", code: "IMA_1" },
	{ value: "Maracaibo, VE", label: "Maracaibo, VE", code: "MCB" },
	{ value: "La Guaira, VE", label: "La Guaira, VE", code: "CCS3" },
	{ value: "Palmira, VE", label: "Palmira, VE", code: "CSR3" },
	{ value: "Loma Linda, VE", label: "Loma Linda, VE", code: "RIM3" },
	{ value: "Naguanagua, VE", label: "Naguanagua, VE", code: "VNC3" },
	{ value: "Porlamar , VE", label: "Porlamar , VE", code: "IMA-3" },
	{ value: "Maracay, VE", label: "Maracay, VE", code: "MYC-1" },
	{ value: "Punto Fijo, VE", label: "Punto Fijo, VE", code: "OLS-1" },
	{ value: "Mérida, VE", label: "Mérida, VE", code: "RIM-1" },
	{ value: "Mérida, VE", label: "Mérida, VE", code: "RIM-132" },
	{ value: "El Tigre, VE", label: "El Tigre, VE", code: "elt3" },
	{ value: "Los Roques, VE", label: "Los Roques, VE", code: "LRO-1" },
	{ value: "Cumana, VE", label: "Cumana, VE", code: "SCU-1" },
	{ value: "Valencia , VE", label: "Valencia , VE", code: "VNC-11" },
	{ value: "Maracay, VE", label: "Maracay, VE", code: "MYC-156" },
	{ value: "Hanoi, VN", label: "Hanoi, VN", code: "HAN-1" },
	{ value: "Ninh Binh, VN", label: "Ninh Binh, VN", code: "HAN-7" },
	{
		value: "Ho Chi Minh (Saigón), VN",
		label: "Ho Chi Minh (Saigón), VN",
		code: "SGN-1",
	},
	{ value: "Nha Trang, VN", label: "Nha Trang, VN", code: "CXR" },
	{ value: "Da Nang, VN", label: "Da Nang, VN", code: "DAD" },
	{ value: "Hai Phong, VN", label: "Hai Phong, VN", code: "HAN-3" },
	{ value: "Dalat, VN", label: "Dalat, VN", code: "CRX" },
	{ value: "Hoi An, VN", label: "Hoi An, VN", code: "DAD-1" },
	{ value: "Hue, VN", label: "Hue, VN", code: "DAD-3" },
	{ value: "Sa Pa, VN", label: "Sa Pa, VN", code: "HAN-2" },
	{ value: "Ha Long Bay, VN", label: "Ha Long Bay, VN", code: "HAN-4" },
	{ value: "Phu Quoc, VN", label: "Phu Quoc, VN", code: "SGN-2" },
	{ value: "Phan Thiet, VN", label: "Phan Thiet, VN", code: "SGN-3" },
	{ value: "Vũng Tàu, VN", label: "Vũng Tàu, VN", code: "SGN-7" },
	{ value: "Quy Nhon, VN", label: "Quy Nhon, VN", code: "UIH" },
	{ value: "Cat Ba, VN", label: "Cat Ba, VN", code: "HAN-5" },
	{ value: "Can Tho, VN", label: "Can Tho, VN", code: "SGN-4" },
	{ value: "Con Dao, VN", label: "Con Dao, VN", code: "SGN-8" },
	{ value: "Long Hai, VN", label: "Long Hai, VN", code: "fake-139" },
	{ value: "Da Lat, VN", label: "Da Lat, VN", code: "fake-285" },
	{ value: "Mui Ne, VN", label: "Mui Ne, VN", code: "fake-286" },
	{ value: "Buon Ma Thuot, VN", label: "Buon Ma Thuot, VN", code: "fake-287" },
	{ value: "Dong Hoi, VN", label: "Dong Hoi, VN", code: "fake-288" },
	{ value: "Cao Bang, VN", label: "Cao Bang, VN", code: "CAO-6" },
	{ value: "Ho Tram, VN", label: "Ho Tram, VN", code: "HOT-6" },
	{ value: "Lao Cai, VN", label: "Lao Cai, VN", code: "LAO-6" },
	{ value: "My Tho, VN", label: "My Tho, VN", code: "MYT-6" },
	{ value: "Rach Gia, VN", label: "Rach Gia, VN", code: "RAC-6" },
	{ value: "Vinh, VN", label: "Vinh, VN", code: "VIN-6" },
	{ value: "Chau Doc, VN", label: "Chau Doc, VN", code: "CHAN-6" },
	{ value: "Dien Bien Phu, VN", label: "Dien Bien Phu, VN", code: "DIEN-6" },
	{ value: "Duong To, VN", label: "Duong To, VN", code: "DUTO-6" },
	{ value: "Ben Tre, VN", label: "Ben Tre, VN", code: "BNT-002" },
	{ value: "Fansipan, VN", label: "Fansipan, VN", code: "FPN-002" },
	{ value: "Phong Nha, VN", label: "Phong Nha, VN", code: "PHONG-6" },
	{
		value: "Islas Vírgenes Británicas, VG",
		label: "Islas Vírgenes Británicas, VG",
		code: "TOV",
	},
	{ value: "Tortola, VG", label: "Tortola, VG", code: "TOV-1" },
	{ value: "Virgin Gorda, VG", label: "Virgin Gorda, VG", code: "TOV-2" },
	{ value: "Anegada, VG", label: "Anegada, VG", code: "TOV-3" },
	{ value: "Jost van Dyke, VG", label: "Jost van Dyke, VG", code: "fake-665" },
	{
		value: "Isla Saint Croix, VI",
		label: "Isla Saint Croix, VI",
		code: "STX-1",
	},
	{
		value: "Isla Saint Thomas, VI",
		label: "Isla Saint Thomas, VI",
		code: "S-1",
	},
	{ value: "Isla Saint John, VI", label: "Isla Saint John, VI", code: "STJ-1" },
	{ value: "El Aaiún, EH", label: "El Aaiún, EH", code: "102" },
	{ value: "Adén, YE", label: "Adén, YE", code: "ADEN-1" },
	{ value: "Lusaka, ZM", label: "Lusaka, ZM", code: "LUN" },
	{ value: "Livingstone, ZM", label: "Livingstone, ZM", code: "LIG" },
	{ value: "Ndola, ZM", label: "Ndola, ZM", code: "NDL-87" },
	{ value: "Mwandi, ZM", label: "Mwandi, ZM", code: "Mwandi" },
	{ value: "Harare, ZW", label: "Harare, ZW", code: "HRE" },
	{
		value: "Cataratas Victoria, ZW",
		label: "Cataratas Victoria, ZW",
		code: "VF-N",
	},
	{ value: "Bulawayo, ZW", label: "Bulawayo, ZW", code: "BLW-34" },
	{ value: "Hwange, ZW", label: "Hwange, ZW", code: "HWNG-1" },
	{ value: "Matobo, ZW", label: "Matobo, ZW", code: "MATOB-6" },
];

export const groupedOptions = [
	{
		label: "Los paises",
		options: countryOptions,
	},
	{
		label: "Ciudad o estado",
		options: cityOptions,
	},
];

export const groupedOptionsCity = [
	{
		label: "Ciudad o estado",
		options: cityOptions,
	},
];
